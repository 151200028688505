import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import { startLoading, finishLoading } from '../Loader/loading_actions';
import { signOut } from '../login/login_actions';
import createError from '../Fetch_error/fetch_error_action';
import { resetItemToDelete, getItems } from '../My_items/my_items_actions';

import {
  requestDeleteEvent,
  requestDeleteVenue,
  requestDeleteTeacher,
  requestDeleteLesson,
  requestDeletePass,
} from '../../api';

import styles from './modal.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

import {
  EVENT, VENUE, TEACHER, LESSON, PASS,
} from '../../config';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const INITIAL_STATE = {
  error: null,
  deleteIsSuccessful: false,
  title: '',
  isLoading: false,
};

export class RemoveModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    itemToRemove: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    dispatch: PropTypes.func.isRequired,
    token: PropTypes.string,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    token: null,
    itemToRemove: null,
    history: null,
  }

  state = { ...INITIAL_STATE };

  handleDeleteItem = () => {
    const { isLoading } = this.state;
    if (isLoading) return;
    const { dispatch, token, itemToRemove, history } = this.props;
    const id = itemToRemove && itemToRemove.id;
    const title = itemToRemove && (itemToRemove.title || itemToRemove.name);
    const type = (itemToRemove && itemToRemove.type) || PASS;

    const deleteRequest = this.setRequestForDelete();
    this.setState({ isLoading: true });
    dispatch(startLoading());
    deleteRequest(id, token)
      .then((response) => {
        if (response.status === 204) {
          this.setState({
            deleteIsSuccessful: true,
            title,
            isLoading: false,
          });
          dispatch(getItems(token));
          if (history.location && history.location.pathname !== '/account') {
            history.push(this.setRedirectPath(type));
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.setState({
            deleteIsSuccessful: false,
            error: error && error.response && error.response.data && error.response.data.error,
            title,
          });
          dispatch(signOut());
        }
        if (error.response && error.response.status === 403) {
          this.setState({
            deleteIsSuccessful: false,
            error: error && error.response && error.response.data && error.response.data.error,
            title,
          });
        }
        dispatch(createError(error));
      })
      .finally(() => dispatch(finishLoading()));
  }

  setRequestForDelete = () => {
    const { itemToRemove } = this.props;
    const { type } = itemToRemove;

    switch (type) {
      case EVENT:
        return requestDeleteEvent;

      case VENUE:
        return requestDeleteVenue;

      case TEACHER:
        return requestDeleteTeacher;

      case LESSON:
        return requestDeleteLesson;

      default:
        return requestDeletePass;
    }
  }

  setRedirectPath = (type) => {
    switch (type) {
      case EVENT:
      case VENUE:
      case TEACHER:
        return `/${type}s`;

      case LESSON:
      case PASS:
        return '/teachers';

      default:
        return '/';
    }
  }

  handleClose = () => {
    const { closeModal, dispatch } = this.props;
    closeModal();
    this.setState({ ...INITIAL_STATE });
    dispatch(resetItemToDelete());
  }

  render() {
    const { error, deleteIsSuccessful, title } = this.state;
    const { isModalOpen, itemToRemove } = this.props;
    const type = (itemToRemove && itemToRemove.type) || PASS;

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={this.handleClose}
        className={styles.content}
        overlayClassName={styles.overlay}
      >
        <div className={styles.content_box}>
          {!deleteIsSuccessful && !error
          && (
          <>
            <div className={styles.title}>
              {`Do you really want to remove ${type}`}
              <br />
              {`"${itemToRemove.title || itemToRemove.name}"?`}
            </div>
            <div className={buttonStyles.buttons_row}>
              <button
                type="button"
                className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                onClick={this.handleDeleteItem}
              >
                Delete
              </button>
              <button
                type="button"
                className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                onClick={this.handleClose}
              >
                Cancel
              </button>
            </div>
          </>
          )}
          {deleteIsSuccessful
          && (
            <>
              <div className={styles.title}>
                {type.charAt(0).toUpperCase()}{type.slice(1)}
                <br />
                {`"${title}"`}
                <br />
                was deleted successfully.
              </div>
              <button
                type="button"
                className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                onClick={this.handleClose}
              >
                close
              </button>
            </>
          )}
          {!deleteIsSuccessful && error
          && (
            <>
              <div className={styles.title}>
                {type.charAt(0).toUpperCase()}{type.slice(1)}
                <br />
                {`"${title}"`}
                <br />
                can not be deleted.
              </div>
              <div className={styles.title}>{error}</div>
              <button
                type="button"
                className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                onClick={this.handleClose}
              >
                close
              </button>
            </>
          )}
        </div>
      </Modal>
    );
  }
}

const mSTP = state => ({
  itemToRemove: state.myItems.itemToDelete,
  token: state.session.token,
});

export default withRouter(connect(mSTP)(RemoveModal));
