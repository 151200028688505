import React from 'react';
import PropTypes from 'prop-types';

import {
  formatDateForEventDetails,
  formatTimeForEventDetails,
  getCorrectDate,
  checkDateHappen,
} from '../../../utils/formatDate';
import { capitalizeStr } from '../../../utils/parse';
import { getRecurrentString } from '../../../utils/getRecurrentString';

import styles from './date_info.module.scss';

import {
  DAILY, WEEKLY, BI_WEEKLY, MONTHLY, BI_WEEKLY_LABEL,
  WEEK,
} from '../../../config';

const TEXT_EMPTY = '...';

const DateInfo = ({ item, ticketView, forPasses, serverTime }) => {
  const {
    start_date: startDate,
    end_date: endDate,
    recurrent,
    validity,
    total_visits: totalVisits,
    time_zone_abbr: itemTimezone,
  } = item;

  const dateToShow = startDate && endDate
    ? formatDateForEventDetails(startDate, endDate)
    : TEXT_EMPTY;

  let timeWithDays = startDate && endDate && !forPasses
    ? formatTimeForEventDetails(startDate, endDate)
    : null;

  if (forPasses && validity) {
    timeWithDays = validity === 1
      ? `Valid for ${validity} day.`
      : `Valid for ${validity} days.`;
  }

  if (forPasses) {
    let totalVisitsLabel = totalVisits === 1
      ? `${totalVisits} entry`
      : `${totalVisits} entries`;

    if (!totalVisits) totalVisitsLabel = '\u221E entries';
    timeWithDays = timeWithDays
      ? `${timeWithDays} ${totalVisitsLabel}.`
      : `${totalVisitsLabel}.`;
  }


  const freq = recurrent && recurrent.freq;
  const isWeekViewShown = freq
      && (freq === WEEKLY || freq === BI_WEEKLY || freq === DAILY);
  const selectedDays = (isWeekViewShown && freq !== DAILY && recurrent.byday)
    ? recurrent.byday.map(day => capitalizeStr(day))
    : null;
  const timeFrom = startDate && getCorrectDate(startDate).format('h:mmA');
  const timeTo = endDate && getCorrectDate(endDate).format('h:mmA');
  const time = (timeFrom && timeTo)
    ? `${timeFrom} - ${timeTo}`
    : TEXT_EMPTY;
  const isMonthlyViewShowm = freq && freq === MONTHLY;
  const monthlyOption = isMonthlyViewShowm ? getRecurrentString(recurrent) : TEXT_EMPTY;

  let currentDateOption;

  if (!forPasses && !ticketView) {
    currentDateOption = checkDateHappen({ serverTime, startDate, endDate });
  }

  return (
    <div className={ticketView ? styles.info_ticket : styles.info}>
      <time className={styles.text_dark}>{
        currentDateOption
          ? `${currentDateOption}, ${dateToShow}`
          : dateToShow}
      </time>
      {isWeekViewShown
        ? (
          <>
            <div className={styles.text_dark}>
              {WEEK.map((day) => {
                const isDaySelected = freq === DAILY
                  || (selectedDays && selectedDays.indexOf(day) !== -1);
                return (
                  <span
                    key={day}
                    className={isDaySelected
                      ? styles.day_selected
                      : styles.day}
                  >
                    {day}
                  </span>
                );
              })}
            </div>
            <div>
              <span className={styles.text_light}>
                {freq === BI_WEEKLY ? `${BI_WEEKLY_LABEL},` : `${capitalizeStr(freq)},`}&nbsp;
              </span>
              <time className={styles.text_light}>
                {time} {itemTimezone}
              </time>
            </div>
          </>
        ) : (
          <time className={styles.text_light}>
            {timeWithDays} {forPasses ? null : itemTimezone}
          </time>
        )
      }
      {isMonthlyViewShowm && (
        <div className={styles.text_light}>
            {`${capitalizeStr(freq)}, ${monthlyOption}`}
        </div>
      )}
    </div>
  );
};

DateInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    end_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
  ticketView: PropTypes.bool,
  forPasses: PropTypes.bool,
  serverTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DateInfo.defaultProps = {
  ticketView: false,
  item: null,
  forPasses: false,
  serverTime: null,
};

export default DateInfo;
