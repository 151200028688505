import React from 'react';

const LoginIcon = () => (
  <svg width="27" viewBox="0 0 88 98" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M52.63 94.86C53.9636 95.6115 55.4693 96.0043 57 96C58.6826 96.0005 60.3317 95.5294 61.76 94.64L82.36 81.74C84.0896 80.6668 85.5159 79.1689 86.5031 77.3887C87.4903 75.6086 88.0056 73.6055 88 71.57V24.43C88.0066 22.3943 87.4917 20.3908 86.5044 18.6105C85.5171 16.8303 84.0903 15.3325 82.36 14.26L61.74 1.37C60.3784 0.52638 58.8162 0.0614479 57.2149 0.023197C55.6136 -0.015054 54.031 0.374762 52.6306 1.1524C51.2303 1.93005 50.0628 3.06734 49.2488 4.44687C48.4348 5.82641 48.0037 7.3982 48 9V12H20C16.8174 12 13.7652 13.2643 11.5147 15.5147C9.26428 17.7652 8 20.8174 8 24V32C8 33.0609 8.42143 34.0783 9.17157 34.8284C9.92172 35.5786 10.9391 36 12 36C13.0609 36 14.0783 35.5786 14.8284 34.8284C15.5786 34.0783 16 33.0609 16 32V24C16 22.9391 16.4214 21.9217 17.1716 21.1716C17.9217 20.4214 18.9391 20 20 20H48V76H20C18.9391 76 17.9217 75.5786 17.1716 74.8284C16.4214 74.0783 16 73.0609 16 72V64C16 62.9391 15.5786 61.9217 14.8284 61.1716C14.0783 60.4214 13.0609 60 12 60C10.9391 60 9.92172 60.4214 9.17157 61.1716C8.42143 61.9217 8 62.9391 8 64V72C8 75.1826 9.26428 78.2348 11.5147 80.4853C13.7652 82.7357 16.8174 84 20 84H48V87C48.0014 88.6038 48.4314 90.1782 49.2454 91.5601C50.0594 92.942 51.2279 94.0812 52.63 94.86V94.86ZM56 9C55.9955 8.82319 56.041 8.64869 56.1312 8.49657C56.2214 8.34444 56.3527 8.22085 56.51 8.14C56.6585 8.0518 56.8273 8.00357 57 8C57.1821 8.00168 57.3596 8.05737 57.51 8.16L78.12 21C78.7008 21.363 79.1787 21.869 79.5078 22.4695C79.837 23.0701 80.0065 23.7452 80 24.43V71.57C80.0065 72.2548 79.837 72.9299 79.5078 73.5305C79.1787 74.131 78.7008 74.637 78.12 75L57.5 87.84C57.3515 87.9376 57.1777 87.9897 57 87.9897C56.8223 87.9897 56.6485 87.9376 56.5 87.84C56.3476 87.7593 56.2204 87.6383 56.1322 87.4901C56.044 87.342 55.9983 87.1724 56 87V9Z" />
      <path d="M68 56C69.0609 56 70.0783 55.5786 70.8284 54.8284C71.5786 54.0783 72 53.0609 72 52V44C72 42.9391 71.5786 41.9217 70.8284 41.1716C70.0783 40.4214 69.0609 40 68 40C66.9391 40 65.9217 40.4214 65.1716 41.1716C64.4214 41.9217 64 42.9391 64 44V52C64 53.0609 64.4214 54.0783 65.1716 54.8284C65.9217 55.5786 66.9391 56 68 56Z" />
      <path d="M26.47 59.69C26.9537 59.8959 27.4743 60.0014 28 60C28.5254 60.0004 29.0458 59.8973 29.5314 59.6965C30.017 59.4958 30.4583 59.2013 30.83 58.83L38.83 50.83C39.2019 50.4585 39.4969 50.0173 39.6982 49.5317C39.8995 49.0461 40.0031 48.5256 40.0031 48C40.0031 47.4743 39.8995 46.9538 39.6982 46.4682C39.4969 45.9826 39.2019 45.5415 38.83 45.17L30.83 37.17C30.2706 36.6099 29.5576 36.2285 28.7812 36.0739C28.0049 35.9193 27.2001 35.9985 26.4688 36.3015C25.7375 36.6045 25.1126 37.1177 24.6731 37.776C24.2336 38.4344 23.9994 39.2084 24 40V44H4C2.93913 44 1.92172 44.4214 1.17157 45.1715C0.421427 45.9217 0 46.9391 0 48C0 49.0608 0.421427 50.0783 1.17157 50.8284C1.92172 51.5785 2.93913 52 4 52H24V56C24.0012 56.7902 24.2364 57.5625 24.676 58.2192C25.1156 58.8759 25.7398 59.3877 26.47 59.69Z" />
    </g>
  </svg>
);

export default LoginIcon;
