import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { INITIAL_STATE_FIELD } from '../../config';
import { validateText, checkUserCreatAbilyti } from '../../utils/validation';
import styles from './modal.module.scss';
import TextInput from '../shared/Text_input/Text_input';
import buttonStyles from '../../styles/buttons.module.scss';
import AvatarEditor from '../Avatar_editor/avatar_editor_container';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export default class ProfileModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
      nickname: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      profession: PropTypes.string,
    }).isRequired,
    token: PropTypes.string.isRequired,
    editUser: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const { user } = props;

    this.state = {
      nickname: {
        ...INITIAL_STATE_FIELD,
        value: user.nickname,
        isValid: validateText(user.nickname),
        isActive: !!user.nickname,
      },
      first_name: {
        ...INITIAL_STATE_FIELD,
        value: user.first_name,
        isValid: validateText(user.first_name),
        isActive: !!user.first_name,
      },
      last_name: {
        ...INITIAL_STATE_FIELD,
        value: user.last_name,
        isValid: validateText(user.last_name),
        isActive: !!user.last_name,
      },
      profession: {
        ...INITIAL_STATE_FIELD,
        value: user.profession,
        isValid: validateText(user.profession),
        isActive: !!user.profession,
      },
      error: null,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = validateText(value);
    this.setState({
      [name]: {
        isActive: true,
        isValid,
        value,
        error: null,
      },
    });
  };

  activateAllFields = () => {
    this.activateField('first_name');
    this.activateField('last_name');
    this.activateField('nickname');
    this.activateField('profession');
  }

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  handleSubmit = (e) => {
    e.preventDefault();
    const { user, token, editUser, closeModal } = this.props;
    this.activateAllFields();
    const {
      nickname,
      first_name: firstName,
      last_name: lastName,
      profession,
    } = this.state;

    const avatar = user && user.avatar;

    if (!avatar) {
      this.setState({ error: 'Please provide avatar image.' });
    } else {
      this.setState({ error: null });
    }

    const updatedUser = {
      nickname: nickname.value,
      first_name: firstName.value,
      last_name: lastName.value,
      profession: profession.value,
    };

    const isUserReady = checkUserCreatAbilyti({ ...updatedUser, avatar });

    if (isUserReady) {
      updatedUser.id = user.id;
      editUser(updatedUser, token).then((data) => {
        if (data) closeModal();
      });
    }
  }


  render() {
    const { isModalOpen, closeModal } = this.props;
    const {
      nickname,
      first_name: firstName,
      last_name: lastName,
      profession,
      error,
    } = this.state;

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className={styles.content}
        overlayClassName={styles.overlay}
      >
        <div className={styles.content_box}>
          <h4 className={styles.title}>Almost there!</h4>
          <div className={styles.message_box}>
            Please provide a few more details to get you started
          </div>

          <div className={styles.avatar_editor_box}>
            <AvatarEditor renderInModal />
          </div>
          {error && <div className={styles.error}>Please provide avatar image.</div>}
          <form onSubmit={this.handleSubmit}>
            <TextInput
              inputName="first_name"
              stateField={firstName}
              onChange={this.handleInputChange}
              placeholder="First name"
            />
            <TextInput
              inputName="last_name"
              stateField={lastName}
              onChange={this.handleInputChange}
              placeholder="Last name"
            />
            <TextInput
              inputName="nickname"
              stateField={nickname}
              onChange={this.handleInputChange}
              placeholder="Username"
            />
            <TextInput
              inputName="profession"
              stateField={profession}
              onChange={this.handleInputChange}
              placeholder="Profession"
            />

            <div className={`${buttonStyles.buttons_row} ${styles.wrapper}`}>
              <button
                type="button"
                className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}
