import React from 'react';
import PropTypes from 'prop-types';

const Ticket = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 15.631 15.631"
    xmlSpace="preserve"
  >
    <g>
      <path d="M14.371,3.547c-0.044,0.085-0.098,0.165-0.167,0.234c-0.478,0.478-1.393,0.338-2.043-0.312
        c-0.65-0.65-0.79-1.565-0.312-2.043c0.069-0.069,0.148-0.124,0.233-0.167L10.822,0L8.964,1.859l0.465,0.465L9.14,2.613L8.675,2.148
        l-8.674,8.675l1.226,1.227c0.479-0.479,1.393-0.34,2.043,0.311s0.79,1.564,0.312,2.043l1.226,1.227L15.63,4.807L14.371,3.547z
        M10.567,4.038L9.854,3.325l0.288-0.289l0.713,0.713L10.567,4.038z M11.993,5.464L11.28,4.751l0.288-0.288l0.714,0.713
        L11.993,5.464z M13.42,6.891l-0.714-0.713l0.289-0.288l0.713,0.713L13.42,6.891z"
      />
    </g>
  </svg>
);

Ticket.propTypes = {
  className: PropTypes.string,
};

Ticket.defaultProps = {
  className: '',
};

export default Ticket;
