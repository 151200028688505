import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import styles from './read_more.module.scss';

export default class ReadMore extends Component {
  state = {
    expanded: false,
    truncated: false,
  };

  handleTruncate = (truncated) => {
    if (this.state.truncated !== truncated) {
      this.setState({ truncated });
    }
  }

  toggleLines = (event) => {
    event.preventDefault();
    this.setState(prevState => ({ expanded: !prevState.expanded }));
  }

  render() {
    const { children, more, less, lines, className } = this.props;

    const { expanded, truncated } = this.state;

    return (
      <div className={className}>
        <Truncate
          lines={!expanded && lines}
          ellipsis={(
            <span>... <button type="button" className={`${styles.button} ${styles.down}`} onClick={this.toggleLines}>{more}</button></span>
          )}
          onTruncate={this.handleTruncate}
        >
          {children}
        </Truncate>
        {!truncated && expanded && (
          <span> <button type="button" className={`${styles.button} ${styles.up}`} onClick={this.toggleLines}>{less}</button></span>
        )}
      </div>
    );
  }
}

ReadMore.defaultProps = {
  lines: 3,
  more: 'More',
  less: 'Less',
  className: '',
};

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
  className: PropTypes.string,
};
