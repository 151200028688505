import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './progress_bar.module.scss';

const ProgressBar = ({ completed }) => (
  <div className={styles.progress_bar_wrapper}>
    <div className={classNames({
      [styles.progress_count]: completed >= 30,
      [styles.progress_count__right]: completed < 30,
    })}
    >
      {completed} %
    </div>
    <div className={styles.progress_bar} style={{ width: `${completed}%` }} />
  </div>
);

ProgressBar.propTypes = {
  completed: PropTypes.number,
};

ProgressBar.defaultProps = {
  completed: 0,
};

export default ProgressBar;
