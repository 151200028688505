import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Input from '../../shared/Text_input/Text_input';
import SelectPaymentMethod from '../../shared/Select_payment_method/Select_payment_method_container';

import Checkmark from '../../icons/Checkmark';

import { validateTitle, validateEmail } from '../../../utils/validation';
import getNestedValue from '../../../utils/getNestedValue';
import { formatDate } from '../../../utils/formatDate';
import { getDollarsFromCents } from '../../../utils/parse';

import buttonStyles from '../../../styles/buttons.module.scss';
import cardStyles from '../../shared/Card_section/card_section.module.scss';
import styles from '../checkout.module.scss';

import { DAILY, MONTHLY } from '../../../config';

const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

const INITIAL_STATE = {
  name: { ...INITIAL_STATE_FIELD },
  email: { ...INITIAL_STATE_FIELD },
  confirmation: {
    ...INITIAL_STATE_FIELD,
    value: false,
  },
  isLoading: false,
  cardError: '',
  cardId: '',
};

class CheckoutForm extends React.Component {
  static propTypes = {
    stripe: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    openModal: PropTypes.func.isRequired,
    checkoutOrder: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    createError: PropTypes.func.isRequired,
    token: PropTypes.string,
    email: PropTypes.string,
    order: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      additional_fee: PropTypes.number,
    }),
    price: PropTypes.number,
    recurrency: PropTypes.oneOf([DAILY, MONTHLY, '']),
    isSubscription: PropTypes.bool,
    resetSubscriptionToCheckout: PropTypes.func.isRequired,
    checkoutSubscription: PropTypes.func.isRequired,
    methods: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    token: '',
    email: '',
    order: null,
    price: null,
    recurrency: '',
    isSubscription: false,
    onClose: () => {},
  }

  state = { ...INITIAL_STATE };

  componentDidMount() {
    this.setInitialState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      if (this.props.token) {
        this.setInitialState();
      } else {
        const { history } = this.props;
        const to = {
          pathname: '/login',
          state: { from: getNestedValue(history, 'location', 'pathname') },
        };
        history.push(to);
      }
    }
  }

  setInitialState = () => {
    this.setEmailState();
    const { resetSubscriptionToCheckout } = this.props;
    resetSubscriptionToCheckout();
  }

  setEmailState = () => {
    const { email } = this.props;
    if (email) {
      this.setState({
        email: {
          value: email,
          isActive: true,
          isValid: true,
          error: null,
        },
      });
    } else {
      this.setState({ email: { ...INITIAL_STATE_FIELD } });
    }
  }

  setDefaultCard = () => {
    const { methods } = this.props;
    if (methods && Array.isArray(methods) && methods.length > 0) {
      const defaultCard = methods.find(card => card.default_method === true);
      this.setState({ cardId: defaultCard.id });
    }
  }

  handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    let isValid = false;
    switch (name) {
      case 'name': {
        isValid = validateTitle(value);
        break;
      }
      case 'email': {
        isValid = validateEmail(value) || value === '';
        break;
      }
      case 'confirmation': {
        isValid = !!checked;
        break;
      }
      default: return;
    }
    this.setState({
      [name]: {
        isActive: true,
        isValid,
        value: name === 'confirmation' ? checked : value,
        error: null,
      },
    });
  };

  validateForm = () => {
    const { name, email } = this.state;
    return name.isValid && (email.isValid || email.value === '') && this.validateConfirmation();
  }

  validateConfirmation = () => {
    const { confirmation } = this.state;

    if (confirmation.value !== true) {
      this.setState(prevState => ({
        confirmation: {
          ...prevState.confirmation,
          isActive: true,
          isValid: false,
        },
      }));
    }

    return confirmation && confirmation.value;
  }

  activateAllFields = () => {
    this.activateField('name');
    this.activateField('confirmation');
  }

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  resetLoading = () => this.setState({ isLoading: false });

  handleSelectMethod = (e) => {
    const { value } = e.target;
    this.setState({ cardId: value });
  }

  handleCheckout = (e) => {
    e.preventDefault();
    const { cardId } = this.state;
    if (cardId === 'new' || !cardId) this.handleCheckoutByNewCard();
    else this.handleCheckoutByCardId();
  }

  handleCheckoutByNewCard = () => {
    const { stripe, token, price, openModal, createError } = this.props;

    this.activateAllFields();
    this.setState({ cardError: '' });
    const { name, email, isLoading } = this.state;
    if (!this.validateForm() || isLoading) return;
    if (token) {
      this.setState({ isLoading: true });
      stripe.createToken({ name: name.value })
        .then((stripeData) => {
          const { token: stripeToken } = stripeData;
          if (stripeToken && stripeToken.id) {
            const paymentData = {
              card_token: stripeToken.id,
              email: email.value,
              amount: price,
            };
            this.sendRequest(paymentData);
          } else {
            this.resetLoading();
            createError();
          }
        });
    } else {
      openModal('SIGN_IN_MODAL');
      this.resetLoading();
    }
  };

  handleCheckoutByCardId = () => {
    const { token, price, openModal } = this.props;
    const { cardId, email } = this.state;
    this.setState({ cardError: '' });
    const { isLoading } = this.state;
    if (isLoading || !this.validateConfirmation()) return;

    if (token) {
      this.setState({ isLoading: true });
      const paymentData = {
        card_id: cardId,
        email: email.value,
        amount: price,
      };
      this.sendRequest(paymentData);
    } else {
      openModal('SIGN_IN_MODAL');
      this.resetLoading();
    }
  };

  sendRequest = (paymentData) => {
    const { order, checkoutSubscription, checkoutOrder, isSubscription } = this.props;
    const { id: orderId, additional_fee: additionalFee } = order;

    const paymentDataWithFee = paymentData;
    if (additionalFee) {
      if (!Number.isNaN(parseFloat(additionalFee)) && Number.isFinite(additionalFee)) {
        paymentDataWithFee.amount = paymentData.amount + additionalFee;
      }
    }

    if (isSubscription) {
      checkoutSubscription(paymentData, orderId)
        .then(responseData => this.processResponse(responseData));
    } else {
      checkoutOrder(paymentDataWithFee, orderId)
        .then(responseData => this.processResponse(responseData));
    }
  }

  processResponse = (responseData) => {
    const { onClose, createError } = this.props;
    const status = getNestedValue(responseData, 'status');
    const data = getNestedValue(responseData, 'data', 'data');
    const validationErrors = getNestedValue(responseData, 'data', 'errors');

    if (status === 201 && data && !validationErrors) {
      this.setState({ ...INITIAL_STATE });
      onClose();
    } else {
      this.resetLoading();
      if (status === 422 && validationErrors) {
        this.setErrors(validationErrors);
      } else if (status === 403) {
        createError('The order is invalid. Please try checkout again...');
        onClose();
      }
    }
  }

  setErrors = (errors) => {
    const { createError } = this.props;
    let errorString = '';
    if (errors) {
      Object.keys(errors).forEach((item) => {
        const errorTitle = item === 'base' ? '' : `${item}:`;
        const errorText = errors[item].join(', ');
        errorString = errorString.length > 0
          ? `${errorString}; ${errorTitle} ${errorText}`
          : `${errorTitle} ${errorText}`;
        this.setState(prevState => ({
          ...prevState,
          cardError: errorText,
        }));
      });
    }
    if (errorString.length > 0) createError(errorString);
  };

  render() {
    const { name, email, confirmation, cardError, cardId } = this.state;
    const { isSubscription, price, recurrency } = this.props;

    let confirmationStyle = styles.checkmark;
    if (confirmation.isActive) {
      confirmationStyle = confirmation.isValid
        ? styles.checkmark_valid
        : styles.checkmark_invalid;
    }

    const nameStyle = name.isValid || !name.isActive
      ? cardStyles.card_input
      : cardStyles.card_input_invalid;

    const date = formatDate(new Date());

    const recurrencyLabel = recurrency === DAILY
      ? 'day'
      : 'month';
    const nextPaymentLable = recurrency === DAILY
      ? 'every day'
      : 'on the same date next month';

    return (
      <form onSubmit={this.handleCheckout}>
        <p className={styles.text}>Your email</p>
        <Input
          inputName="email"
          placeholder="Type your e-mail..."
          onChange={this.handleInputChange}
          stateField={email}
        />
        <SelectPaymentMethod
          nameStyle={nameStyle}
          handleInputChange={this.handleInputChange}
          handleSelectMethod={this.handleSelectMethod}
          cardId={cardId}
        />
        <label className={styles.checkbox_container}>
          <input
            type="checkbox"
            name="confirmation"
            checked={confirmation.value}
            onChange={this.handleInputChange}
          />
          <div className={confirmationStyle}>
            {confirmation.isActive && confirmation.isValid
              && <Checkmark className={styles.checkmark_icon} />}
          </div>
          <div className={styles.checkbox_text}>
            I confirm that I agree to the&nbsp;
            <Link
              to="/terms_of_use"
              target="_blank"
              className={styles.checkbox_link}
            >
              Terms and Conditions
            </Link>
          </div>
        </label>
        <p className={styles.error}>{cardError}</p>
        {isSubscription
          && (
            <>
              <p className={styles.text_light}>
                Starting {date}, we’ll charge
                you {getDollarsFromCents(price)} each {recurrencyLabel} until you cancel.
                You’ll be notified before any future price changes.
                We’ll charge you for the next subscription period {nextPaymentLable}.
                You must cancel before then to avoid charges.
                Cancel any time on your account page by clicking
                on the subscription you want to cancel.
              </p>
              <p className={styles.text_light}>This is a recurring charge.</p>
            </>
          )}
        <div className={buttonStyles.extra_wide_container}>
          <button
            type="submit"
            className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}

export default CheckoutForm;
