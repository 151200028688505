import qs from 'qs';

import {
  SET_MAP_CENTER,
  SET_MAP_ZOOM,
  RESET_MAP_ZOOM,
  SET_MAP_MARKERS,
  SET_FIT_MAP_FLAG,
  RESET_FIT_MAP_FLAG,
  SET_USER_LOCATION,
  ADD_MARKER,
  CENTER_PIN_MODE_ON,
  CENTER_PIN_MODE_OFF,
  GET_MAP_CENTER_ADDRESS,
  RESET_MAP_CENTER_ADDRESS,
  SEARCH_ON_MOVE_MAP,
  SET_HOVER_ID,
} from '../../redux/action_types';

import createMarkers from '../../utils/createMarkers';
import history from '../../utils/history';
import getNestedValue from '../../utils/getNestedValue';

const setMapCenter = center => ({
  type: SET_MAP_CENTER,
  payload: center,
});


const zoomMap = () => ({
  type: SET_MAP_ZOOM,
});


export const resetZoomMap = () => ({
  type: RESET_MAP_ZOOM,
});


export const moveMapCenterTo = center => (dispatch) => {
  dispatch(setMapCenter(center));
  dispatch(zoomMap());
};

export const fitMapBoundsToMarkers = () => ({
  type: SET_FIT_MAP_FLAG,
});


export const resetFitMapFlag = () => ({
  type: RESET_FIT_MAP_FLAG,
});


export const showMarkers = markers => (dispatch) => {
  dispatch({
    type: SET_MAP_MARKERS,
    payload: createMarkers(markers),
  });
};

export const setUserLocation = coords => ({
  type: SET_USER_LOCATION,
  payload: coords,
});


export const addMarker = coords => ({
  type: ADD_MARKER,
  payload: coords,
});


export const setOnPinMode = () => ({ type: CENTER_PIN_MODE_ON });

export const setOffPinMode = () => ({ type: CENTER_PIN_MODE_OFF });

export const setMapCenterAddress = address => ({
  type: GET_MAP_CENTER_ADDRESS,
  payload: address,
});

export const resetMapCenterAddress = () => ({
  type: RESET_MAP_CENTER_ADDRESS,
});

export const setOnMoveMapSearch = () => ({
  type: SEARCH_ON_MOVE_MAP,
  payload: true,
});

export const setOffMoveMapSearch = () => {
  const search = getNestedValue(history, 'location', 'search');
  const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
  delete queryObject.geo_bounds;
  history.push({ search: qs.stringify(queryObject) });

  return {
    type: SEARCH_ON_MOVE_MAP,
    payload: false,
  };
};

export const toggleMoveMapSearch = () => (dispatch, getState) => {
  const currentSetOn = getState().map.searchOnMoveMap;
  dispatch({
    type: SEARCH_ON_MOVE_MAP,
    payload: !currentSetOn,
  });

  if (currentSetOn) {
    const search = getNestedValue(history, 'location', 'search');
    const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
    delete queryObject.geo_bounds;
    history.push({ search: qs.stringify(queryObject) });
  }
};

export const showNewMarker = coords => (dispatch) => {
  dispatch(addMarker(coords));
  dispatch(moveMapCenterTo(coords));
};

export const setHoverId = id => ({
  type: SET_HOVER_ID,
  payload: id,
});
