import { connect } from 'react-redux';
import TicketsList from './Tickets_list';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import createError from '../Fetch_error/fetch_error_action';
import { setCurrentTicket } from './user_tickets_actions';

const mSTP = state => ({
  token: state.session.token,
});

const mDTP = dispatch => ({
  startLoading: () => dispatch(startLoading()),
  finishLoading: () => dispatch(finishLoading()),
  openTicketModal: (order) => {
    dispatch(setCurrentTicket(order));
    dispatch(setCurrentModal('USER_TICKET_MODAL'));
    dispatch(openModal());
  },
  openPassModal: (order) => {
    dispatch(setCurrentTicket(order));
    dispatch(setCurrentModal('USER_PASS_MODAL'));
    dispatch(openModal());
  },
  createError: err => dispatch(createError(err)),
});

export default connect(mSTP, mDTP)(TicketsList);
