import React from 'react';
import Tabs from '../components/shared/Tabs/Tabs';
import LoginForm from '../components/login/Login_form';
import SignInForm from '../components/login/Sign_in_form';
import styles from './login_page.module.scss';


const LoginPage = props => (
  <div className={styles.backdrop}>
    <div className={styles.wrapper}>
      <Tabs initialActiveTab={1}>
        <div label="Sign up">
          <div className={styles.content_box}>
            <SignInForm {...props} />
          </div>
        </div>
        <div label="Log in">
          <div className={styles.content_box}>
            <LoginForm {...props} />
          </div>
        </div>
      </Tabs>
    </div>
  </div>
);

export default LoginPage;
