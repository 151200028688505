import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import { signOut } from '../login/login_actions';
import { setAndOpenModal } from '../Modal/modal_actions';
import {
  requestUserInfo,
  requestSavedItems,
  requestAddLike,
  requestRemoveLike,
} from '../../api';

export const getUserInfo = userId => (dispatch, getState) => {
  const { token } = getState().session;

  dispatch(startLoading());
  return requestUserInfo({ userId, token })
    .catch(err => dispatch(createError(err)))
    .finally(() => dispatch(finishLoading()));
};

const processReject = (rej, dispatch) => {
  if (rej.response && rej.response.status === 401) {
    dispatch(signOut());
    dispatch(setAndOpenModal('SIGN_IN_MODAL'));
  }
  if (rej.response && rej.response.status === 422) {
    dispatch(createError(rej));
    return rej.response.data;
  }
  dispatch(createError(rej));
  return rej.response.data;
};

export const getSavedItems = (userId, params) => (dispatch, getState) => {
  const { token } = getState().session;

  dispatch(startLoading());
  return requestSavedItems({ userId, token, params })
    .catch(err => dispatch(createError(err)))
    .finally(() => dispatch(finishLoading()));
};

export const addLike = ({ id, type }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;

  return requestAddLike({ id, token, type })
    .catch(rej => processReject(rej, dispatch))
    .finally(() => dispatch(finishLoading()));
};

export const removeLike = ({ id, type }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;

  return requestRemoveLike({ id, token, type })
    .catch(rej => processReject(rej, dispatch))
    .finally(() => dispatch(finishLoading()));
};
