import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { requestUpdateSubscription } from '../../api';
import getNestedValue from '../../utils/getNestedValue';
import { getDateStringWithDots } from '../../utils/formatDate';

import styles from './modal.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const INITIAL_STATE = {
  deleteIsSuccessful: false,
  isLoading: false,
};

class CancelSubscriptionModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    token: PropTypes.string,
    subscriptionToUpdate: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    startLoading: PropTypes.func.isRequired,
    finishLoading: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    createError: PropTypes.func.isRequired,
    resetSubscriptionToUpdate: PropTypes.func.isRequired,
    getMySubscriptions: PropTypes.func.isRequired,
  };

  static defaultProps = {
    token: null,
  }

  state = { ...INITIAL_STATE };

  handleCancelSubscription = () => {
    const { isLoading } = this.state;
    if (isLoading) return;
    const {
      token, subscriptionToUpdate, startLoading, finishLoading,
      signOut, createError, getMySubscriptions,
    } = this.props;
    const id = getNestedValue(subscriptionToUpdate, 'id');

    this.setState({ isLoading: true });
    startLoading();
    const data = {
      cancel: true,
    };
    requestUpdateSubscription(id, token, data)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            deleteIsSuccessful: true,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        const status = getNestedValue(error, 'response', 'status');
        if (status === 401) {
          this.setState({ deleteIsSuccessful: false });
          signOut();
        }
        const errorMessage = getNestedValue(error, 'response', 'data', 'error') || 'Subscription cancellation failed. Please try again later...';
        createError(errorMessage);
      })
      .finally(() => {
        getMySubscriptions();
        finishLoading();
      });
  }

  handleClose = () => {
    const { closeModal, resetSubscriptionToUpdate } = this.props;
    resetSubscriptionToUpdate();
    closeModal();
  }

  render() {
    const { deleteIsSuccessful } = this.state;
    const { isModalOpen, subscriptionToUpdate } = this.props;
    const type = getNestedValue(subscriptionToUpdate, 'owner', 'type');
    const title = getNestedValue(subscriptionToUpdate, 'owner', 'title');
    const endDate = getDateStringWithDots(new Date(getNestedValue(subscriptionToUpdate, 'end_date')));

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={this.handleClose}
        className={styles.content}
        overlayClassName={styles.overlay}
      >
        <div className={styles.content_box}>
          {deleteIsSuccessful
            ? (
              <>
                <div className={styles.title}>
                  Subscription for {type.charAt(0).toUpperCase()}{type.slice(1)}
                  <br />
                  {`"${title}"`}
                  <br />
                  was cancelled succesfully.
                </div>
                <button
                  type="button"
                  className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                  onClick={this.handleClose}
                >
                  close
                </button>
              </>
            ) : (
              <>
                {title && type
                  ? (
                    <div className={styles.title_with_text}>
                      {`Do you really want to cancel your subscription to ${type}`}
                      <br />
                      {`"${title}"?`}
                    </div>
                  )
                  : (
                    <div className={styles.title_with_text}>
                      Do you really want to cancel your subscription?
                    </div>
                  )
                }
                {(endDate && endDate !== 'invalid date') && (
                  <div className={styles.text_grey}>
                    {`If you confirm and end your subscription now, it will still be listed on Dancity until ${endDate}.`}
                  </div>
                )}
                <div className={buttonStyles.buttons_row}>
                  <button
                    type="button"
                    className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                    onClick={this.handleCancelSubscription}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                    onClick={this.handleClose}
                  >
                    Not now
                  </button>
                </div>
              </>
            )
          }
        </div>
      </Modal>
    );
  }
}

export default CancelSubscriptionModal;
