import React from 'react';
import Select from 'react-select';
import Scrollbars from 'react-custom-scrollbars';

const menuRenderer = (params) => {
  const menu = Select.defaultProps.menuRenderer(params);

  const scrollBarProps = {
    autoHeight: true,
    autoHeightMin: 0,
    autoHeightMax: 200,
  };

  return (
    <Scrollbars
      {...scrollBarProps}
      style={{ width: 280, height: 250 }}
    >
      {menu}
    </Scrollbars>
  );
};

const CustomSelect = props => (
  <Select menuRenderer={menuRenderer} {...props} />
);

export default CustomSelect;
