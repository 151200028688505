import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select/Select_scrollbar';
import styles from './time_picker.module.scss';
import { getTimeObject, timeObjectToString } from '../../../utils/formatTime';
import inputStyles from '../../../styles/date_picker.module.scss';
import selectStyles from './time_select_styles';
import ClockIcon from '../../icons/Clock';
import CheckmarkIcon from '../../icons/Checkmark';

const currentTime = new Date();
currentTime.setHours(currentTime.getHours() + 1);
currentTime.setMinutes(0);
const nowFrom = getTimeObject(currentTime);
currentTime.setHours(currentTime.getHours() + 1);
const defaultTime = new Date();
defaultTime.setHours(0, 0);
const defaultTimeTo = getTimeObject(defaultTime);

const INITIAL_STATE = {
  isTimePickerOpen: false,
};

const HOURS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map(item => ({
  value: item,
  label: item,
}));

const MINUTES = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'].map(item => ({
  value: item,
  label: item,
}));

const AM_PM = [
  { value: 'am', label: 'am' },
  { value: 'pm', label: 'pm' },
];

const TIME_FROM = 'time from';
const TIME_TO = 'time to';

const timeType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});


export default class TimePicker extends Component {
  static propTypes = {
    onTimeChange: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    isValid: PropTypes.bool,
    timeFrom: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        amPm: timeType,
        hours: timeType,
        minutes: timeType,
      }),
    ]),
    timeTo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        amPm: timeType,
        hours: timeType,
        minutes: timeType,
      }),
    ]),
  };

  static defaultProps = {
    isActive: false,
    isValid: true,
    timeFrom: { ...nowFrom },
    timeTo: { ...defaultTimeTo },
  }

  state = { ...INITIAL_STATE };

  handleOpenTimePicker = (e) => {
    const { name } = e.target;
    const { onTimeChange, timeFrom } = this.props;
    if (!timeFrom) {
      onTimeChange({
        timeFrom: { ...nowFrom },
        timeTo: { ...defaultTimeTo },
      });
    }
    this.setState({
      isTimePickerOpen: true,
      timeSelected: name,
    });
  }

  handleCloseTimePicker = () => {
    this.setState({
      isTimePickerOpen: false,
      timeSelected: null,
    });
  }

  handleSelectTime = (time, selectedField) => {
    const { timeSelected } = this.state;
    const { timeFrom, timeTo, onTimeChange } = this.props;
    if (timeSelected === TIME_FROM) {
      const newTimeFrom = {
        ...timeFrom,
        [selectedField]: time,
      };
      onTimeChange({ timeFrom: newTimeFrom, timeTo });
    } else if (timeSelected === TIME_TO) {
      const newTimeTo = {
        ...timeTo,
        [selectedField]: time,
      };
      onTimeChange({ timeFrom, timeTo: newTimeTo });
    }
  }

  handleSelectHours = (time) => {
    this.handleSelectTime(time, 'hours');
  }

  handleSelectMinutes = (time) => {
    this.handleSelectTime(time, 'minutes');
  }

  handleSelectAmPm = (time) => {
    this.handleSelectTime(time, 'amPm');
  }

  render() {
    const {
      isTimePickerOpen,
      timeSelected,
    } = this.state;

    const {
      timeFrom,
      timeTo,
      isValid,
      isActive,
    } = this.props;
    let clockStyles = isActive ? styles.icon_active : styles.icon;
    if (!isValid) clockStyles = styles.icon_error;

    return (
      <>
        <div className={isValid ? inputStyles.container : inputStyles.container_error}>
          <div className={inputStyles.date_box}>
            <label
              htmlFor={TIME_FROM}
              className={timeSelected === TIME_FROM ? inputStyles.label__active : inputStyles.label}
            >
              {TIME_FROM}
            </label>
            <input
              readOnly
              className={isActive ? inputStyles.input_active : inputStyles.input}
              name={TIME_FROM}
              value={isActive ? timeObjectToString(timeFrom) : ''}
              onClick={this.handleOpenTimePicker}
            />
            <div className={inputStyles.icon}>
              <ClockIcon className={clockStyles} />
            </div>
          </div>
          <div className={isActive ? inputStyles.dash_active : inputStyles.dash} />
          <div className={inputStyles.date_box}>
            <label
              htmlFor={TIME_TO}
              className={timeSelected === TIME_TO ? inputStyles.label__active : inputStyles.label}
            >
              {TIME_TO}
            </label>
            <input
              readOnly
              className={isActive ? inputStyles.input_active : inputStyles.input}
              name={TIME_TO}
              value={isActive ? timeObjectToString(timeTo) : ''}
              onClick={this.handleOpenTimePicker}
            />
            <div className={inputStyles.icon}>
              <ClockIcon className={clockStyles} />
            </div>
          </div>
        </div>
        {isTimePickerOpen && (
          <div className={styles.picker_wrapper}>
            <Select
              options={HOURS}
              styles={selectStyles}
              value={timeSelected === TIME_FROM ? timeFrom.hours : timeTo.hours}
              onChange={this.handleSelectHours}
              isSearchable={false}
            />
            <Select
              options={MINUTES}
              styles={selectStyles}
              value={timeSelected === TIME_FROM ? timeFrom.minutes : timeTo.minutes}
              onChange={this.handleSelectMinutes}
              isSearchable={false}
            />
            <Select
              options={AM_PM}
              styles={selectStyles}
              value={timeSelected === TIME_FROM ? timeFrom.amPm : timeTo.amPm}
              onChange={this.handleSelectAmPm}
              isSearchable={false}
            />
            <button onClick={this.handleCloseTimePicker} type="button">
              <CheckmarkIcon className={styles.confirm} />
            </button>
          </div>
        )}
      </>
    );
  }
}
