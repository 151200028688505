import { requestMyEvents, requestMyVenues, requestMyTeachers } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { signOut } from '../login/login_actions';
import createError from '../Fetch_error/fetch_error_action';
import { showMarkers, fitMapBoundsToMarkers } from '../Map/map_actions';

import {
  GET_MY_EVENTS_SUCCESS,
  GET_MY_VENUES_SUCCESS,
  GET_MY_TEACHERS_SUCCESS,
  SET_ITEM_TO_DELETE,
  RESET_ITEM_TO_DELETE,
} from '../../redux/action_types';

export const getEventsSuccess = items => ({
  type: GET_MY_EVENTS_SUCCESS,
  payload: items,
});

export const getVenuesSuccess = items => ({
  type: GET_MY_VENUES_SUCCESS,
  payload: items,
});

export const getTeachersSuccess = items => ({
  type: GET_MY_TEACHERS_SUCCESS,
  payload: items,
});

export const getItems = token => (dispatch) => {
  dispatch(startLoading());
  const itemsForMapMarkers = [];
  Promise.all([
    requestMyEvents(token)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getEventsSuccess(response.data.data));
          itemsForMapMarkers.push(...response.data.data);
        } else dispatch(createError());
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) dispatch(signOut());
        dispatch(createError(error));
      }),

    requestMyVenues(token)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getVenuesSuccess(response.data.data));
          itemsForMapMarkers.push(...response.data.data);
        } else dispatch(createError());
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) dispatch(signOut());
        dispatch(createError(error));
      }),

    requestMyTeachers(token)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getTeachersSuccess(response.data.data));
          const teachers = response.data.data;
          teachers.forEach((teacher) => {
            const { lessons } = teacher;
            if (lessons && lessons.length > 0) {
              itemsForMapMarkers.push(...lessons);
            }
            const teacherForMarker = { ...teacher };
            delete teacherForMarker.lessons;
            delete teacherForMarker.tickets;
            itemsForMapMarkers.push(teacherForMarker);
          });
        } else dispatch(createError());
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) dispatch(signOut());
        dispatch(createError(error));
      }),

  ]).finally(() => {
    dispatch(finishLoading());
    dispatch(showMarkers(itemsForMapMarkers));
    dispatch(fitMapBoundsToMarkers());
  });
};

export const setItemToDelete = item => ({
  type: SET_ITEM_TO_DELETE,
  payload: item,
});

export const resetItemToDelete = () => ({
  type: RESET_ITEM_TO_DELETE,
});
