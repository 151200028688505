import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { validateText } from 'utils/validation';
import MarkerIcon from '../icons/Marker';
import CancelIcon from '../icons/Cancel_icon';

import inputStyles from '../../styles/inputs.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import styles from '../../styles/create_form.module.scss';
import AddressInput from '../Address_input/Address_input';

const PLACEHOLDER = {
  location: 'Location',
  venue: 'Venue',
  address: 'Address',
};


class VenueSelector extends Component {
  static propTypes = {
    showNewMarker: PropTypes.func.isRequired,
    getVenueAutoComplete: PropTypes.func.isRequired,
    componentSetState: PropTypes.func.isRequired,
    location: PropTypes.shape({
      value: PropTypes.string,
      venueList: PropTypes.array,
    }).isRequired,
    address: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
    setOnPinMode: PropTypes.func.isRequired,
    setOffPinMode: PropTypes.func.isRequired,
    mapCenterAddress: PropTypes.shape({
      center: PropTypes.object,
      address: PropTypes.string,
    }),
    resetMapCenterAddress: PropTypes.func.isRequired,
  }

  static defaultProps = {
    mapCenterAddress: null,
  }

  componentWillUnmount() {
    const { setOffPinMode } = this.props;
    setOffPinMode();
  }

  handleSelectVenue = (e) => {
    const { componentSetState } = this.props;
    const { id } = e.currentTarget && e.currentTarget.dataset;
    const selectedVenue = this.props.location.venueList.find(venue => venue.id === id);
    if (selectedVenue) {
      const { title, address, coordinates } = selectedVenue;
      componentSetState(prevState => (
        {
          address: {
            ...prevState.address,
            isAddressShown: false,
            isActive: false,
            isValid: true,
            value: address,
          },
          location: {
            ...prevState.location,
            value: title,
            isActive: true,
            isValid: true,
            isVenueListShown: false,
            venueId: id,
          },
          coordinates,
        }
      ));
      const { showNewMarker } = this.props;
      showNewMarker(coordinates);
    }
  }

  handleVenueChange = (e) => {
    const { componentSetState } = this.props;
    const { value } = e.target;
    componentSetState(prevState => ({
      location: {
        ...prevState.location,
        value,
        isVenueListShown: true,
        venueId: null,
        isValid: validateText(value),
        isActive: true,
        error: null,
      },
    }));
    const { getVenueAutoComplete } = this.props;
    if (value.trim().length > 0) {
      getVenueAutoComplete(value)
        .then((data) => {
          if (data && data.data) {
            if (data.data.length > 0) {
              componentSetState(prevState => ({
                location: {
                  ...prevState.location,
                  venueList: data.data,
                  isVenueListShown: true,
                },
                address: {
                  ...prevState.address,
                  isAddressShown: true,
                  value: '',
                },
              }));
            } else {
              componentSetState(prevState => ({
                location: {
                  ...prevState.location,
                  venueList: [],
                  isVenueListShown: false,
                },
                address: {
                  ...prevState.address,
                  isAddressShown: true,
                  value: '',
                },
                coordinates: null,
              }));
            }
          }
        });
    } else {
      componentSetState(prevState => ({
        location: {
          ...prevState.location,
          venueList: [],
          isVenueListShown: false,
        },
      }));
    }
  };

  handleVenueCancel = () => {
    const { componentSetState } = this.props;

    componentSetState(prevState => ({
      location: {
        ...prevState.location,
        value: '',
        venueId: null,
        isValid: false,
      },
    }));
  }

  handleVenueBlur = () => {
    const { componentSetState } = this.props;

    componentSetState(prevState => ({ location: {
      ...prevState.location,
      isVenueListShown: false,
    } }));
  }

  handleAddCustomLocation = () => {
    const { componentSetState } = this.props;

    componentSetState(prevState => ({
      address: {
        ...prevState.address,
        isActive: false,
        isValid: false,
        value: '',
        isAddressShown: true,
      },
      location: {
        ...prevState.location,
        isVenueListShown: false,
        venueId: null,
      },
      coordinates: null,
    }));
  }

  handleAddressChange = (address) => {
    const { componentSetState } = this.props;

    componentSetState(prevState => ({
      address: {
        ...prevState.address,
        ...address,
      },
    }));
  }

  handleCoordsChange = (coordinates) => {
    const { componentSetState } = this.props;

    componentSetState(prevState => ({
      coordinates: {
        ...prevState.coordinates,
        ...coordinates,
      },
    }));
  }

  renderVenueInput = () => {
    const valueFromState = this.props.location;
    let fieldStyle;
    if (!valueFromState.isActive) fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    else fieldStyle = valueFromState.isValid ? inputStyles.input_box_create : `${inputStyles.input_box_create} ${inputStyles.is_error}`;
    return (
      <div className={fieldStyle}>
        <input
          className={inputStyles.input}
          type="text"
          name="location"
          value={valueFromState.value}
          onChange={this.handleVenueChange}
          autoComplete="off"
          onBlur={this.handleVenueBlur}
        />
        <label
          htmlFor="location"
          className={valueFromState.value ? inputStyles.label__with_value : inputStyles.label}
        >
          {valueFromState.error ? valueFromState.error : PLACEHOLDER.location}
        </label>
        {valueFromState.isActive && (
          <button
            className={`${buttonStyles.close_round} ${styles.cancel}`}
            type="button"
            onClick={this.handleVenueCancel}
            onKeyPress={this.handleVenueCancel}
          >
            <CancelIcon className={buttonStyles.close_round_svg} />
          </button>
        )}
      </div>
    );
  };

  render() {
    const { location: { isVenueListShown, venueList, venueId } } = this.props;
    const { address: { value } } = this.props;

    return (
      <>
        <div className={inputStyles.venue_container}>
          {this.renderVenueInput()}
          {isVenueListShown && (
            <div className={inputStyles.autocomplete_venue}>
              {venueList && venueList.map(({ id, title, address }) => (
                <div
                  key={id}
                  role="button"
                  tabIndex="0"
                  className={inputStyles.autocomplete_item}
                  onMouseDown={this.handleSelectVenue}
                  data-id={id}
                >
                  <MarkerIcon className={inputStyles.autocomplete_icon} />
                  <div className={inputStyles.autocomplete_info}>
                    <p className={inputStyles.autocomplete_link}>{title}</p>
                    <p className={inputStyles.autocomplete_subtext}>{address}</p>
                  </div>
                </div>
              ))}
            </div>
          )
          }
          {venueId && (
            <>
              <div className={inputStyles.venue_title}>Address:</div>
              <div className={inputStyles.venue}>{value}</div>
            </>
          )}
        </div>
        <AddressInput
          {...this.props}
          onAddressChange={this.handleAddressChange}
          onCoordsChange={this.handleCoordsChange}
        />
      </>
    );
  }
}

export default VenueSelector;
