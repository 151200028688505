import { connect } from 'react-redux';
import Header from './header';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import { signOut, getUser } from '../login/login_actions';
import { getNotificationsCount } from '../Notifications/notifications_actions';

const mSTP = state => ({
  isAuth: state.session.authenticated,
  user: state.session.user,
  token: state.session.token,
  notificationsCount: state.notificationsCount,
});

const mDTP = dispatch => ({
  openModal: (modalName) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  signOut: (token) => { dispatch(signOut(token)); },
  getUser: ({ id, token }) => dispatch(getUser({ id, token })),
  getNotificationsCount: () => dispatch(getNotificationsCount()),
});

export default connect(mSTP, mDTP)(Header);
