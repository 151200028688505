import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import makeItemLink from '../../../utils/makeItemLink';
import getNestedValue from '../../../utils/getNestedValue';

import Dots from '../../icons/Dots';
import bottomBarStyles from '../Bottom_bar/bottom_bar.module.scss';
import styles from './dots_submenu.module.scss';

import {
  EVENT, LESSON, LESSON_LABEL, TEACHER, PASS, VENUE,
  UNPAID, ACTIVE, INACTIVE,
} from '../../../config';

export class DotsMenu extends Component {
  static propTypes = {
    item: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      status: PropTypes.oneOf([ACTIVE, INACTIVE]),
    }).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    bottomView: PropTypes.bool,
    setItemToDelete: PropTypes.func,
    setAndOpenModal: PropTypes.func,
    setTransferItem: PropTypes.func,
  };

  static defaultProps = {
    bottomView: false,
    setAndOpenModal: () => {},
    setItemToDelete: () => {},
    setTransferItem: () => {},
    // setReportData: () => {},
  }

  state = { isSubmenuOpened: false };

  handleSubmenuOpen = () => this.setState({ isSubmenuOpened: true })

  handleClickOutside = () => this.setState({ isSubmenuOpened: false });

  handleEdit = () => {
    const { item, history, bottomView } = this.props;
    const id = item && item.id;
    let type = (item && item.type) || PASS;
    this.handleClickOutside();
    const list = (type === LESSON || type === PASS) ? TEACHER : type;
    if (type === LESSON) type = LESSON_LABEL;
    history.push({
      pathname: `/account/edit_${type}/${id}`,
      state: {
        from: history.location.pathname,
        list: bottomView ? null : list,
      },
    });
  }

  goToAnalytics = () => {
    const { item, history } = this.props;
    const id = item && item.id;
    let type = (item && item.type) || PASS;
    this.handleClickOutside();
    if (type === LESSON) type = LESSON_LABEL;
    history.push({
      pathname: `/analytics/${type}s/${id}`,
      state: {
        from: history.location.pathname,
      },
    });
  }

  handleRemove = () => {
    const { setItemToDelete, setAndOpenModal, item } = this.props;

    this.handleClickOutside();
    setItemToDelete(item);
    setAndOpenModal('REMOVE_ITEM_MODAL');
  }

  handleCheckIn = () => {
    const { item, history, bottomView } = this.props;
    const id = item && item.id;
    const type = item && item.type;
    this.handleClickOutside();
    const list = type === LESSON ? TEACHER : type;
    const pathname = makeItemLink({ type, id });
    if (type === EVENT || type === LESSON) {
      history.push({
        pathname: `/check-in${pathname}`,
        state: {
          from: history.location.pathname,
          list: bottomView ? null : list,
        },
      });
    }
  }

  handleCheckout = () => {
    const { item, history } = this.props;
    const type = item && item.type;
    const subscriptionId = getNestedValue(item, 'subscription', 'id');
    this.handleClickOutside();
    if (subscriptionId && (type === VENUE || type === TEACHER)) {
      history.push({
        pathname: '/checkout',
        search: `?subscription_id=${subscriptionId}`,
        state: {
          from: history.location.pathname,
        },
      });
    }
  }

  // handleReport = () => {
  //   const { setAndOpenModal, setReportData, item } = this.props;
  //   const title = getNestedValue(item, 'title');
  //   const type = getNestedValue(item, 'type');
  //   setReportData({
  //     title,
  //     type,
  //     link: window.location.href,
  //   });
  //   setAndOpenModal('REPORT_MODAL');
  // }

  handleReport = () => {
    const { item, history } = this.props;
    history.push({
      pathname: '/feedback',
      state: {
        from: history.location.pathname,
        report: item,
        reportLink: window.location.href,
      },
    });
  }

  handleStartTransfer = () => {
    const { setTransferItem, setAndOpenModal, item } = this.props;

    this.handleClickOutside();
    setTransferItem(item);
    setAndOpenModal('TRANSFER_ITEM_MODAL');
  }

  render() {
    const { item, bottomView } = this.props;
    const {
      type,
      permissions,
    } = item;
    const { isSubmenuOpened } = this.state;
    const isCheckinAvailable = type === EVENT || type === LESSON;

    const subscription = item && item.subscription;
    const isCheckoutAvailable = item && item.type && (item.type === VENUE || item.type === TEACHER)
      && subscription && (subscription.status === UNPAID);

    let dotsStyle = bottomView ? bottomBarStyles.icon : styles.dots;
    dotsStyle = `${dotsStyle} ${styles.dots_active}`;

    const checkinPermission = isCheckinAvailable
      && (permissions && permissions.checkin);

    const editPermission = permissions
      && (permissions.update || permissions.update_tickets);

    const analyticsPermission = type === EVENT && permissions && permissions.analytics;
    const deletePermission = permissions && permissions.delete;
    const transferPermission = permissions && permissions.transfer;

    return (
      <div>
        <button
          type="button"
          onClick={this.handleSubmenuOpen}
          className={dotsStyle}
        >
          <Dots className={bottomView ? bottomBarStyles.dots : null} />
          {bottomView && (<div className={bottomBarStyles.title}>More</div>)}
        </button>
        {isSubmenuOpened && (
          <ul className={bottomView ? styles.submenu_bottom : styles.submenu}>
            {editPermission && (
            <li className={styles.submenu_item}>
              <button className={styles.button} type="button" onClick={this.handleEdit}>
                Edit
              </button>
            </li>
            )}
            {deletePermission && (
            <li className={styles.submenu_item}>
              <button className={styles.button} type="button" onClick={this.handleRemove}>
                Remove
              </button>
            </li>
            )}
            {checkinPermission && (
            <li className={styles.submenu_item}>
              <button className={styles.button} type="button" onClick={this.handleCheckIn}>
                Check-in
              </button>
            </li>
            )}
            {isCheckoutAvailable && (
            <li className={styles.submenu_item}>
              <button className={styles.button} type="button" onClick={this.handleCheckout}>
                Checkout
              </button>
            </li>
            )}
            {analyticsPermission && (
              <li className={styles.submenu_item}>
                <button className={styles.button} type="button" onClick={this.goToAnalytics}>
                  Reports
                </button>
              </li>
            )}
            {transferPermission && (
              <li className={styles.submenu_item}>
                <button className={styles.button} type="button" onClick={this.handleStartTransfer}>
                  Transfer
                </button>
              </li>
            )}
            {!analyticsPermission && bottomView && (
              <li className={styles.submenu_item}>
                <button className={styles.button} type="button" onClick={this.handleReport}>
                  Report
                </button>
              </li>
            )}
          </ul>
        )}
      </div>
    );
  }
}

export default onClickOutside(DotsMenu);
