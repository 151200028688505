import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles/payment_methods.module.scss';

const cardLogoStyle = {
  Visa: styles.visa,
  MasterCard: styles.master_card,
  'American Express': styles.american_express,
  Discover: styles.discover,
};

const MethodItem = ({ method, handleSelectMethod, checked }) => {
  const { id, brand, last4, funding, exp_month: expMonth, exp_year: expYear } = method;
  const logoStyle = cardLogoStyle[brand] ? cardLogoStyle[brand] : styles.no_logo;
  const currentDate = new Date();
  const expiryDate = new Date();
  expiryDate.setFullYear(expYear, expMonth, 1);
  expiryDate.setHours(0, 0, 0);
  const isMethodExpired = currentDate > expiryDate;

  return (
    <label className={styles.method_item}>
      <input
        type="radio"
        value={id}
        checked={checked}
        onChange={handleSelectMethod}
        className={styles.radio}
        name="payment_method"
        disabled={isMethodExpired}
      />
      <div className={`${styles.card_logo} ${logoStyle}`}>
        {isMethodExpired && <div className={styles.expired}>expired</div>}
      </div>
      <div className={styles.funding}>{funding}</div>
      <div className={styles.last4}>***{last4}</div>
    </label>
  );
};

MethodItem.propTypes = {
  method: PropTypes.shape({
    id: PropTypes.string,
    brand: PropTypes.string,
    last4: PropTypes.string,
    funding: PropTypes.string,
  }).isRequired,
  handleSelectMethod: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

MethodItem.defaultProps = {
  checked: false,
};

export default MethodItem;
