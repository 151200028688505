import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Comment from './Comment';
import CommentForm from './Comment_form';
import styles from './comments_block.module.scss';

const VISIBLE_COUNT = 6;

const CommentsBlock = (props) => {
  const { comments, scrollToComment, item, ...rest } = props;
  const [count, setCount] = useState(VISIBLE_COUNT);

  const handleShowMore = () => { setCount(prevCount => (prevCount + VISIBLE_COUNT)); };
  const showMoreButton = comments.length > count;

  useEffect(() => {
    setCount(VISIBLE_COUNT);
  }, [item]);

  return (
    <>
      <h5 className={styles.title}>Comments</h5>
      <CommentForm scrollToTop={scrollToComment} item={item} {...rest} />

      {comments.map((comment, i) => (
        i < count ? (
          <Comment key={comment.id} comment={comment} {...rest} item={item} />
        ) : null
      ))}

      {showMoreButton && (
        <div className={styles.more_container}>
          <button
            type="button"
            onClick={handleShowMore}
            className={styles.more_btn}
          >
            More comments
          </button>
        </div>
      )}
    </>
  );
};

CommentsBlock.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
    }),
  ),
  item: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  scrollToComment: PropTypes.func,
};

CommentsBlock.defaultProps = {
  comments: [],
  scrollToComment: () => {},
};

export default CommentsBlock;
