import { CURRENT_TICKET_INFO, UPDATE_CURRENT_TICKET_INFO } from '../../redux/action_types';

const userTicketsReducer = (state = null, action) => {
  switch (action.type) {
    case CURRENT_TICKET_INFO:
      return action.payload;

    case UPDATE_CURRENT_TICKET_INFO:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default userTicketsReducer;
