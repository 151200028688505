import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import getNestedValue from '../../../utils/getNestedValue';
import getUserName from '../../../utils/getUserName';
import Checkbox from '../../shared/Checkbox/Checkbox';

import buttonStyles from '../../../styles/buttons.module.scss';
import styles from '../modal.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const INITIAL_STATE = {
  checkin: false,
  editItem: false,
  editTickets: false,
};

const labels = {
  event: 'event',
  lesson: 'class',
};

export default class CohostPermissionsModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    inviteUserToCohost: PropTypes.func.isRequired,
    cohostingData: PropTypes.shape({
      userToCohost: PropTypes.shape({
        id: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        hosting_status: PropTypes.string,
        permissions: PropTypes.shape({
          checkin: PropTypes.bool,
          update: PropTypes.bool,
          update_tickets: PropTypes.bool,
        }),
      }),
      hostingItem: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };

  state = { ...INITIAL_STATE }

  componentDidMount() {
    const { cohostingData } = this.props;
    const permissions = getNestedValue(cohostingData, 'userToCohost', 'permissions');
    const checkin = !!getNestedValue(permissions, 'checkin');
    const editItem = !!getNestedValue(permissions, 'update');
    const editTickets = !!getNestedValue(permissions, 'update_tickets');

    this.setState({
      checkin,
      editItem,
      editTickets,
    });
  }

  inviteUser = () => {
    const { inviteUserToCohost, cohostingData, closeModal } = this.props;
    const {
      checkin,
      editItem,
      editTickets,
    } = this.state;

    if (!checkin && !editItem && !editTickets) {
      return;
    }

    const permissions = {
      checkin,
      update: editItem,
      update_tickets: editTickets,
    };

    const userId = getNestedValue(cohostingData, 'userToCohost', 'id');
    const hostingItem = getNestedValue(cohostingData, 'hostingItem');
    const itemType = hostingItem && hostingItem.type;
    const itemId = hostingItem && hostingItem.id;
    if (itemType && itemId && inviteUserToCohost) {
      inviteUserToCohost({ type: itemType, itemId, userId, permissions })
        .then((res) => {
          if (res.status === 201) {
            closeModal();
          }
        });
    }
  };

  toggleCheckbox = (e) => {
    const { name } = e.target;

    this.setState(prevState => ({ [name]: !prevState[name] }));
  }

  render() {
    const { cohostingData, isModalOpen, closeModal } = this.props;
    const { checkin, editItem, editTickets } = this.state;

    const title = getNestedValue(cohostingData, 'hostingItem', 'title');
    const userToCohost = getNestedValue(cohostingData, 'userToCohost');
    const userName = getUserName(userToCohost);
    const hostingItem = getNestedValue(cohostingData, 'hostingItem');
    const itemType = hostingItem && hostingItem.type;

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className={styles.content}
        overlayClassName={styles.overlay}
      >
        <div className={styles.content_box}>
          <div className={styles.title}>
            User {userName} permissions for &quot;{title}&quot;
          </div>
          <div className={styles.checkbox_wrapper}>
            <Checkbox value={checkin} name="checkin" onChange={this.toggleCheckbox} label="Сheckin guests" />
            <Checkbox value={editItem} name="editItem" onChange={this.toggleCheckbox} label={`Edit ${labels[itemType] || ''}`} />
            <Checkbox value={editTickets} name="editTickets" onChange={this.toggleCheckbox} label="Edit tickets" />
          </div>
          <div className={buttonStyles.buttons_row}>
            <button
              type="button"
              className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
              onClick={this.inviteUser}
            >
              Apply
            </button>
            <button
              type="button"
              className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
