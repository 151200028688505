import React from 'react';
import PropTypes from 'prop-types';

const Careers = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 58">
    <path d="M54.4 54.7l-5-5s-.1 0-.1-.1c1-1.5 1.6-3.3 1.6-5.2 0-5.2-4.2-9.5-9.5-9.5S32 39.2 32 44.5c0 5.2 4.2 9.5 9.5 9.5 1.9 0 3.7-.6 5.2-1.6 0 0 0 .1.1.1l5 5c.8.8 2 .8 2.7 0 .7-.8.7-2-.1-2.8zm-12.9-4.1c-3.4 0-6.2-2.8-6.2-6.2 0-3.4 2.8-6.2 6.2-6.2 3.4 0 6.2 2.8 6.2 6.2-.1 3.5-2.8 6.2-6.2 6.2z" />
    <path d="M34.6 31.1c-.5-.2-1-.3-1.5-.4-.8-.2-2-.6-2.8-1.4-.4-.4-.6-1-.6-1.5 0-.6.4-1.2.9-1.9l.2-.3.9-1.8c.1-.2.2-.5.2-.8 0-.2.1-.4.2-.5l.4-.4.2-.2c.1-.1.2-.1.3-.2l.5-.6.1-.1c.4-.6.6-1.3.7-2.1l.1-.7c0-.3.1-.6.1-.8v-.8c0-.9-.4-2.1-1.4-2.7v-.1c0-.2.1-.5.1-.7.2-.9.4-1.9.4-2.8 0-1.5-.3-3-1-4.1-.8-1.3-1.9-2.4-3.5-3-.3-.1-.6-.3-.9-.3-1.7-.6-3.4-.9-5.2-.9h-1.2c-1.2.1-2.6.2-3.9.8-.1.1-.3.2-.4.2-.5.3-1 .8-1.4 1.3l-.2.4c-.1.2-.1.2-.2.2-.2.2-.5.3-.7.4-.7.3-1.2.7-1.5 1.3-.4.8-.7 1.7-.7 2.8 0 .9 0 1.8.1 2.9 0 .4.1.8.2 1.2v.3c-1 .5-1.5 1.7-1.5 2.6v.9c0 .3.1.6.1.9l.1.4v.2c.1.7.3 1.4.7 2 .2.3.4.6.6.8.3.3.6.5.9.7.3 1.3.9 2.5 1.5 3.5l.3.5.2.2c.1.1.1.2.2.3l.1.1c.1.1.1.2.1.2.2.9.5 2.5-3.3 3.5-3.8 1.1-8.7 3.2-10.5 7.1-.5 1.2-.6 2.5-.6 3.4v2.5c.1 1.4 1.2 2.6 2.6 2.6h22.7c.7 0 1.3-.6 1.3-1.3 0-.7-.6-1.3-1.3-1.3H4.6v-2.5c0-.6 0-1.6.4-2.3 1.4-3 5.6-4.7 8.9-5.7 3.9-1.1 5.6-3.2 5.1-6.4-.1-.4-.2-.7-.5-1.2l-.1-.2c-.1-.2-.3-.4-.5-.6l-.2-.3c-.5-.8-.9-1.8-1.2-2.8l-.3-.8-.8-.5c-.1-.1-.3-.2-.4-.4 0 0-.1-.1-.2-.3-.2-.3-.3-.7-.3-1.1 0-.1 0-.3-.1-.3v-.3c0-.2-.1-.4-.1-.7V16.3c0-.1.1-.1 1.2-.6l.6-.2-.5-2.5c-.1-.3-.1-.6-.1-.8-.1-1-.1-1.8-.1-2.6 0-.7.2-1.3.4-1.7 0-.1.1-.2.3-.2.5-.2.9-.5 1.3-.8.4-.3.6-.6.7-.8l.2-.3c.2-.3.4-.5.6-.6l.2-.1c.9-.4 2-.5 3-.6h1c1.5 0 3 .3 4.4.8.2.1.5.2.7.3 1 .4 1.8 1.1 2.3 2 .4.7.7 1.6.6 2.7 0 .7-.1 1.5-.3 2.2-.1.4-.2.8-.2 1.1l-.2 1.9 1.4.7s.1.2.1.5v.6c0 .2 0 .4-.1.6l-.1.7c-.1.5-.2.8-.3 1l-.4.4-.1.1-.2.1c-.2.2-.5.4-.7.7-.4.5-.5 1-.6 1.5 0 .1-.1.3-.1.4-.2.6-.5 1-.7 1.5l-.2.2c-.5.9-1.2 1.9-1.3 3.2 0 1.2.5 2.5 1.3 3.4 1.2 1.2 2.8 1.7 3.9 2 .5.1.9.3 1.4.4.7.2 1.4-.2 1.6-.8.3-.6-.1-1.4-.7-1.6z" />
  </svg>
);

Careers.propTypes = {
  className: PropTypes.string,
};

Careers.defaultProps = {
  className: '',
};

export default Careers;
