import React from 'react';
import PropTypes from 'prop-types';

const Plus = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 533.333 533.333"
    xmlSpace="preserve"
  >
    <g>
      <path d="M516.667,200H333.333V16.667C333.333,7.462,325.871,0,316.667,0h-100C207.462,0,200,7.462,200,16.667V200H16.667
        C7.462,200,0,207.462,0,216.667v100c0,9.204,7.462,16.666,16.667,16.666H200v183.334c0,9.204,7.462,16.666,16.667,16.666h100
        c9.204,0,16.667-7.462,16.667-16.666V333.333h183.333c9.204,0,16.667-7.462,16.667-16.666v-100
        C533.333,207.462,525.871,200,516.667,200z"
      />
    </g>
  </svg>
);


Plus.propTypes = {
  className: PropTypes.string,
};

Plus.defaultProps = {
  className: '',
};

export default Plus;
