import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import { connectStripe } from '../../api';
import styles from '../../styles/common.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

export default class StripeRedirectPage extends Component {
  state = {
    isStripeConnected: false,
    error: null,
    isLoading: false,
  }

  static propTypes = {
    token: PropTypes.string,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
      push: PropTypes.func,
    }).isRequired,
    startLoading: PropTypes.func.isRequired,
    finishLoading: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    setAndOpenModal: PropTypes.func.isRequired,
    createError: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    token: null,
  }

  componentDidMount() {
    const { token, signOut, setAndOpenModal } = this.props;
    if (!token) {
      signOut();
      setAndOpenModal('SIGN_IN_MODAL');
      return;
    }
    this.redirectConnectCode();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) this.redirectConnectCode();
  }

  redirectConnectCode = () => {
    const { startLoading, finishLoading, createError, getUser, user } = this.props;
    const { history, token } = this.props;
    const { search } = history && history.location;
    const { code } = qs.parse(search, { ignoreQueryPrefix: true });

    if (!code) {
      this.setState({ error: 'Sorry you have no code.' });
      return;
    }

    startLoading();
    this.setState({ isLoading: true });
    connectStripe(code, token)
      .then(
        (res) => {
          finishLoading();
          this.setState({ isLoading: false });
          if (res.status === 200) {
            this.setState({
              isStripeConnected: true,
              error: null,
            });
            if (token && user && user.id && getUser) {
              getUser({ token, id: user.id });
            }
          }
        },
        (rej) => {
          finishLoading();
          this.setState({ isLoading: false });
          if (rej.response.status === 422) {
            this.setState({
              error: rej.response.data.error,
            });
          }
          createError(rej);
        },
      )
      .catch((error) => {
        finishLoading();
        this.setState({ isLoading: false });
        createError(error);
      });
  }

  goToMainPage = () => {
    const { history } = this.props;
    if (history) history.push('/');
  }

  render() {
    const { isStripeConnected, error, isLoading } = this.state;

    return (
      <main className={styles.page}>
        <h1 className={styles.page_title_centered}>Connect Stripe account</h1>
        {isLoading && <p>Loading. Please wait...</p>}
        {isStripeConnected && (
          <>
            <p>Congratulations! Your stripe account has been connected</p>
          </>
        )}
        {error && (
          <p>{error}</p>
        )}
        {(isStripeConnected || error) && (
          <div className={buttonStyles.buttons_container}>
            <button type="button" className={buttonStyles.btn_blue} onClick={this.goToMainPage}>
              Continue
            </button>
          </div>
        )}
      </main>
    );
  }
}
