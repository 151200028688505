import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import { formatDateForEventDetails } from 'utils/formatDate';

import DateInfo from '../Date_info/Date_info';
import ReadMore from '../Read_more/Read_more';

import CancelIcon from '../../icons/Cancel_icon';
import Arrow from '../../icons/Arrow';

import buttonStyles from '../../../styles/buttons.module.scss';
import styles from './simple_item_preview.module.scss';
import { LESSON } from '../../../config';

const INITIAL_STATE = {
  isOpened: false,
  isDestroyableErrorShown: false,
};

const PASS_PLACEHOLDERS = {
  limitText1: 'pass',
  limitText2: 'passes',
  name: 'Pass name:',
  description: 'Pass description:',
  limit: 'Passes limit:',
  price: 'Price:',
  refunds: 'Refunds:',
  date: 'Start Sale/ End Sale:',
  validity: 'Expires after:',
  visits: 'Includes entries:',
  feeInclude: 'Fee:',
};

const TICKET_PLACEHOLDERS = {
  limitText1: 'ticket',
  limitText2: 'tickets',
  name: 'Ticket name:',
  description: 'Ticket description:',
  limit: 'Ticket limit:',
  price: 'Price:',
  refunds: 'Refunds:',
  date: 'Start Sale/ End Sale:',
  validity: 'Expires after:',
  visits: 'Includes entries:',
  feeInclude: 'Fee:',
};

const LESSON_PLACEHOLDERS = {
  limitText1: 'class',
  limitText2: 'classes',
  name: 'Class name:',
  description: 'Class description:',
  date: 'Start/ End:',
  location: 'Location',
};

class SimpleItemPreview extends Component {
  static propTypes = {
    item: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      quantity: PropTypes.number,
      price: PropTypes.number,
      date: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
      refunds: PropTypes.bool,
      start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      end_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      validity: PropTypes.number,
      total_visits: PropTypes.number,
      address: PropTypes.string,
      location: PropTypes.string,
    }).isRequired,
    forPasses: PropTypes.bool,
    detailsView: PropTypes.bool,
    isPassDestroyable: PropTypes.bool,
    onUnlinkItem: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    draggable: PropTypes.bool,
    index: PropTypes.number,
  };

  static defaultProps = {
    forPasses: false,
    detailsView: false,
    isPassDestroyable: false,
    onUnlinkItem: () => {},
    match: {},
    draggable: false,
    index: 0,
  }

  state = { ...INITIAL_STATE };

  handleToggleItem = () => this.setState(prevState => ({
    isOpened: !prevState.isOpened,
    isDestroyableErrorShown: false,
  }));

  handleUnlinkItem = () => {
    const { onUnlinkItem, item, isPassDestroyable } = this.props;
    const { destroyable, justLinkedItem, type } = item;
    const isLesson = type && type === LESSON;
    if (isLesson) {
      if (isPassDestroyable || justLinkedItem) {
        onUnlinkItem(item);
      } else {
        this.setState({ isDestroyableErrorShown: true });
      }
      return;
    }
    if ((destroyable && destroyable === true) || justLinkedItem) {
      onUnlinkItem(item);
    } else {
      this.setState({ isDestroyableErrorShown: true });
    }
  }

  render() {
    const { isOpened, isDestroyableErrorShown } = this.state;
    const { item, forPasses, detailsView, draggable, index } = this.props;
    const {
      name, title, description, quantity, price,
      refunds, validity, location, address,
      start_date: startDate,
      end_date: endDate,
      total_visits: visits,
      fee_include: feeInclude,
      id,
    } = item;

    const isLesson = item && item.type && item.type === LESSON;
    let placeholders = forPasses ? PASS_PLACEHOLDERS : TICKET_PLACEHOLDERS;
    if (isLesson) placeholders = LESSON_PLACEHOLDERS;
    const limitText = quantity === 1 ? placeholders.limitText1 : placeholders.limitText2;

    return (
      <div className={styles.container}>
        {draggable ? (
          <Draggable draggableId={id} index={index}>
            {provided => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                className={styles.header}
              >
                <h5
                  className={styles.title_withArrow}
                  onClick={this.handleToggleItem}
                  role="presentation"
                >
                  {isLesson ? title : name}
                </h5>
                <button
                  className={styles.arrow_wrapper}
                  type="button"
                  onClick={this.handleToggleItem}
                  onKeyPress={this.handleToggleItem}
                >
                  <Arrow direction={isOpened ? 'up' : 'down'} className={styles.arrow} />
                </button>
                {!detailsView && (
                <button
                  className={buttonStyles.close_round}
                  type="button"
                  onClick={this.handleUnlinkItem}
                  onKeyPress={this.handleUnlinkItem}
                >
                  <CancelIcon className={buttonStyles.close_round_svg} />
                </button>
                )}
              </div>
            )}
          </Draggable>
        ) : (
          <div className={styles.header}>
            <h5
              className={styles.title_withArrow}
              onClick={this.handleToggleItem}
              role="presentation"
            >
              {isLesson ? title : name}
            </h5>
            <button
              className={styles.arrow_wrapper}
              type="button"
              onClick={this.handleToggleItem}
              onKeyPress={this.handleToggleItem}
            >
              <Arrow direction={isOpened ? 'up' : 'down'} className={styles.arrow} />
            </button>
            {!detailsView && (
            <button
              className={buttonStyles.close_round}
              type="button"
              onClick={this.handleUnlinkItem}
              onKeyPress={this.handleUnlinkItem}
            >
              <CancelIcon className={buttonStyles.close_round_svg} />
            </button>
            )}
          </div>
        )}
        {isDestroyableErrorShown
          && (
          <div className={styles.text_small}>
            {isLesson
              ? 'The class cannot be removed as the pass has been purchased'
              : 'The pass cannot be removed as it has been purchased'
            }
          </div>
          )
        }
        {isOpened
          && (
            <>
              <p className={styles.text_light}>{placeholders.name}</p>
              <p className={styles.text_dark}>{isLesson ? title : name}</p>
              <p className={styles.text_light}>{placeholders.description}</p>
              <ReadMore className={styles.text_dark}>
                <p className={styles.text_dark}>{description}</p>
              </ReadMore>
              {isLesson
                ? (
                  <>
                    <p className={styles.text_light}>{placeholders.location}</p>
                    <p className={styles.text_dark}>{location}</p>
                    <p className={styles.text_dark}>{address}</p>
                  </>
                )
                : (
                  <>
                    <p className={styles.text_light}>{placeholders.limit}</p>
                    <p className={styles.text_dark}>{`${quantity} ${limitText}`}</p>
                    <p className={styles.text_light}>{placeholders.price}</p>
                    <p className={styles.text_dark}>{`$${price / 100}`}</p>
                    <p className={styles.text_light}>{placeholders.refunds}</p>
                    <p className={styles.text_dark}>{refunds ? 'With refund' : 'No refund'}</p>
                    <p className={styles.text_light}>{placeholders.feeInclude}</p>
                    <p className={styles.text_dark}>{feeInclude ? 'Retain' : 'Pass on to buyer'}</p>
                  </>
                )}
              {validity ? (
                <>
                  <p className={styles.text_light}>{placeholders.validity}</p>
                  <p className={styles.text_dark}>{validity} days</p>
                </>
              ) : null}
              {visits ? (
                <>
                  <p className={styles.text_light}>{placeholders.visits}</p>
                  <p className={styles.text_dark}>{visits}</p>
                </>
              ) : null}
              <p className={styles.text_light}>{placeholders.date}</p>
              <div className={styles.text_dark}>
                {isLesson
                  ? <DateInfo item={item} />
                  : formatDateForEventDetails(startDate, endDate)
                }
              </div>
            </>
          )
        }
      </div>
    );
  }
}

export default SimpleItemPreview;
