import React from 'react';
import PropTypes from 'prop-types';

const QRscan = ({ className }) => (
  className ? (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
    >
      <path d="M0 31v-5a1 1 0 1 1 2 0v4h4a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1zm31 1h-5a1 1 0 1 1 0-2h4v-4a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1zm1-31v5a1 1 0 1 1-2 0V2h-4a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1zM1 0h5a1 1 0 1 1 0 2H2v4a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1zm6 6h18a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm1 2v16h16V8H8zm2 2h12v12H10V10z" />
    </svg>
  ) : (
    <svg
      width="32"
      height="32"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
    >
      <path d="M0 31v-5a1 1 0 1 1 2 0v4h4a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1zm31 1h-5a1 1 0 1 1 0-2h4v-4a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1zm1-31v5a1 1 0 1 1-2 0V2h-4a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1zM1 0h5a1 1 0 1 1 0 2H2v4a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1zm6 6h18a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm1 2v16h16V8H8zm2 2h12v12H10V10z" />
    </svg>
  )
);

QRscan.propTypes = {
  className: PropTypes.string,
};

QRscan.defaultProps = {
  className: '',
};

export default QRscan;
