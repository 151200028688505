import { requestFeedback } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { setAndOpenModal, setModalMessage } from '../Modal/modal_actions';
import createError from '../Fetch_error/fetch_error_action';
import {
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAILURE,
} from '../../redux/action_types';


export const sendFeedbackSuccess = () => ({ type: SEND_FEEDBACK_SUCCESS });

export const sendFeedbackFailure = () => ({ type: SEND_FEEDBACK_FAILURE });

export const sendFeedback = feedback => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestFeedback(feedback, token)
    .then((response) => {
      if (response.status === 204) {
        dispatch(sendFeedbackSuccess());
        switch (feedback.feedback_type) {
          case 'feedback': {
            dispatch(setModalMessage('Your feedback has been sent.'));
            break;
          }
          case 'careers': {
            dispatch(setModalMessage('Your application has been sent.'));
            break;
          }
          case 'contact': {
            dispatch(setModalMessage('Your query has been sent.'));
            break;
          }
          default: return response.data;
        }
        dispatch(setAndOpenModal('FEEDBACK_SUCCESS_MODAL'));
      }
      return response.data;
    })
    .catch((rej) => {
      if (rej.response && rej.response.status === 422) {
        dispatch(sendFeedbackFailure(rej.response.data.errors));
        return rej.response.data;
      }
      dispatch(createError(rej));
      return rej.response.data;
    })
    .finally(() => dispatch(finishLoading()));
};
