import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import styles from '../modal.module.scss';
import buttonStyles from '../../../styles/buttons.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const INITIAL_STATE = {
  isLoading: false,
};

export default class RemoveCommentModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    commentToRemove: PropTypes.shape({
      id: PropTypes.string,
      body: PropTypes.string,
    }),
    resetCommentToDelete: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    createError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    commentToRemove: null,
  }

  state = { ...INITIAL_STATE };

  handleDeleteItem = () => {
    const { isLoading } = this.state;
    if (isLoading) return;
    const {
      commentToRemove, deleteComment, closeModal,
      resetCommentToDelete, createError,
    } = this.props;
    const comment = commentToRemove && commentToRemove.comment;
    const idToDelete = comment && comment.id;
    const item = commentToRemove && commentToRemove.item;

    if (idToDelete && item && deleteComment) {
      const { id, type } = item;
      this.setState({ isLoading: true });

      deleteComment({ idToDelete, itemId: id, type })
        .finally(() => {
          this.setState({ isLoading: false });
          closeModal();
          resetCommentToDelete();
        });
    } else {
      createError();
    }
  }

  handleClose = () => {
    const { closeModal, resetCommentToDelete } = this.props;
    closeModal();
    resetCommentToDelete();
  }

  render() {
    const { isModalOpen, commentToRemove } = this.props;
    const comment = commentToRemove && commentToRemove.comment;
    const text = comment && comment.body;

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={this.handleClose}
        className={styles.content}
        overlayClassName={styles.overlay}
      >
        <div className={styles.content_box}>
          <div className={styles.title}>
            Do you really want to remove your comment?
          </div>
          <p className={styles.message_box}>
            {text}
          </p>
          <div className={buttonStyles.buttons_row}>
            <button
              type="button"
              className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
              onClick={this.handleDeleteItem}
            >
              Delete
            </button>
            <button
              type="button"
              className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
              onClick={this.handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
