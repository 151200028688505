import { connect } from 'react-redux';
import TicketOrder from './Ticket_order';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import orderTickets from './ticket_order_actions';

const mSTP = (state) => {
  const { token } = state.session;
  return ({ token });
};

const mDTP = dispatch => ({
  orderTickets: (tickets, token) => dispatch(orderTickets(tickets, token)),
  openModal: (modalName) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
});

export default connect(mSTP, mDTP)(TicketOrder);
