import { SET_FS_PHOTOS } from '../../../redux/action_types';

export const setFSphotos = photos => ({
  type: SET_FS_PHOTOS,
  payload: photos,
});

export const resetPhotos = () => ({
  type: SET_FS_PHOTOS,
  payload: null,
});
