import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import FriendItem from './Friend_item';
import styles from './friends_interested.module.scss';

const MAX_INLINE = 11;
const CELL_WIDTH = 55;

const FriendsInterestedRow = (props) => {
  const wrapperRef = useRef(null);

  const wrapperWidth = (wrapperRef.current && wrapperRef.current.clientWidth) || 250;
  const { friends, totalFriendsCount, openAllFriends } = props;

  if (!friends || friends.length < 1) return null;

  const inlineCount = Math.floor(wrapperWidth / CELL_WIDTH) < MAX_INLINE
    ? Math.floor(wrapperWidth / CELL_WIDTH)
    : MAX_INLINE;
  const allFriendsInline = totalFriendsCount > 0
  && totalFriendsCount <= inlineCount;
  const hasMoreFriends = totalFriendsCount > inlineCount;

  return (
    <div className={styles.friends_wrapper} ref={wrapperRef}>
      <div className={styles.title}>Friends interested</div>
      {allFriendsInline
      && friends.map(friend => <FriendItem friend={friend} key={friend.id} />)}
      {hasMoreFriends && (
        <div className={styles.row}>
          {friends.map((friend, i) => {
            if (i > (inlineCount - 2)) return null;
            return <FriendItem friend={friend} key={friend.id} />;
          })}
          <button className={styles.toggle_button} onClick={openAllFriends} type="button">
            + {totalFriendsCount - inlineCount + 1}
          </button>
        </div>
      )}
    </div>
  );
};


FriendsInterestedRow.propTypes = {
  friends: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
    }),
  })),
  totalFriendsCount: PropTypes.number,
  openAllFriends: PropTypes.func,
};


FriendsInterestedRow.defaultProps = {
  friends: [],
  totalFriendsCount: 0,
  openAllFriends: () => {},
};

export default FriendsInterestedRow;
