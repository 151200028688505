import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import CancelIcon from '../../icons/Cancel_icon';

import gridStyles from '../../../styles/main_grid.module.scss';
import buttonStyles from '../../../styles/buttons.module.scss';
import commonStyles from '../../../styles/common.module.scss';
import getNestedValue from '../../../utils/getNestedValue';
import UserLink from '../../shared/User_link/User_link';
import styles from '../friends.module.scss';

const FriendsInterestedSection = (props) => {
  const { handleClose, itemInterested, getFriendsInterested } = props;
  if (!itemInterested || !getFriendsInterested) {
    return (
      <div className={gridStyles.side_bar}>
        Invalid props
      </div>
    );
  }

  const wrapperRef = useRef(null);
  const titleRef = useRef(null);
  const listRef = useRef(null);
  const [friends, setFriends] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const { type, id } = itemInterested;

  const getPageOfFriends = (nextPage) => {
    setLoading(true);

    return getFriendsInterested({ id, type, query: { page: nextPage } })
      .then((res) => {
        const data = getNestedValue(res, 'data');
        if (data && Array.isArray(data)) {
          if (nextPage) {
            setFriends(prevState => ([...prevState, ...data]));
          } else {
            setFriends(data);
          }
        }
        const totalItems = getNestedValue(res, 'headers', 'total') || 0;
        setTotal(totalItems);
        setPage(nextPage || 1);
      })
      .finally(() => { setLoading(false); });
  };

  useEffect(() => {
    getPageOfFriends();
  }, []);

  useEffect(() => {
    const listHeight = listRef && listRef.current && listRef.current.scrollHeight;
    const wrapperHeight = wrapperRef && wrapperRef.current && wrapperRef.current.clientHeight;
    const titleHeight = titleRef && titleRef.current && titleRef.current.scrollHeight;

    const needOneMorePage = listHeight < wrapperHeight - titleHeight - 60;

    if (needOneMorePage && friends.length < total) {
      getPageOfFriends(page + 1);
    }
  }, [total]);

  const handleScroll = (e) => {
    if (isLoading) return;
    const visibleHeight = e.srcElement.clientHeight;
    const scrolled = e.srcElement.scrollTop;
    const totalHeight = e.srcElement.scrollHeight;
    const timeToFetch = (totalHeight - visibleHeight - scrolled) <= 50;

    if (timeToFetch && friends.length < total) {
      setLoading(true);
      getPageOfFriends(page + 1);
    }
  };

  const handleMobileScroll = () => {
    const visibleHeight = window.innerHeight;
    const scrolled = window.scrollY;
    const totalHeight = document.body.scrollHeight;
    const timeToFetch = (totalHeight - visibleHeight - scrolled) <= 50;

    if (timeToFetch && friends.length < total) {
      getPageOfFriends(page + 1);
    }
  };

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.addEventListener('scroll', handleScroll);
    }

    return wrapperRef && wrapperRef.current ? (
      () => {
        wrapperRef.current.removeEventListener('scroll', handleScroll);
      }
    ) : null;
  });

  useEffect(() => {
    window.addEventListener('scroll', handleMobileScroll);

    return () => {
      window.removeEventListener('scroll', handleMobileScroll);
    };
  });

  return (
    <div className={gridStyles.side_bar} ref={wrapperRef}>
      <button
        type="button"
        className={buttonStyles.cancel_button}
        onClick={handleClose}
      >
        <CancelIcon />
      </button>
      <h2 className={commonStyles.title} ref={titleRef}>Friends interested</h2>
      <ul ref={listRef}>
        {friends.map(friend => (
          <li key={friend.id} className={styles.user_wrapper}>
            <UserLink user={friend}>
              {friend.first_name}
            </UserLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

FriendsInterestedSection.propTypes = {
  handleClose: PropTypes.func.isRequired,
  itemInterested: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  getFriendsInterested: PropTypes.func.isRequired,
};

export default FriendsInterestedSection;
