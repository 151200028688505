import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import {
  FRIEND, REQUEST_SENT, REQUEST_RECEIVED, NOT_FRIEND,
} from '../../config';
import AddFriendIcon from '../icons/Add_friend';
import AddedFriendIcon from '../icons/Added_friend';
import PendingFriendIcon from '../icons/Pending_friend';
import styles from './public_profile.module.scss';
import submenuStyles from '../shared/Dots_submenu/dots_submenu.module.scss';

class FriendSubMenu extends Component {
  static propTypes = {
    user: PropTypes.shape({
      friend_status: PropTypes.string,
    }).isRequired,
    authenticated: PropTypes.bool.isRequired,
    setAndOpenModal: PropTypes.func.isRequired,
    inviteToFriends: PropTypes.func.isRequired,
    acceptFriend: PropTypes.func.isRequired,
    declineFriend: PropTypes.func.isRequired,
    openDeleteFriendModal: PropTypes.func.isRequired,
    cancelRequest: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
  };

  state ={
    isOpen: false,
  }

  toogleOpenMenu = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleClickOutside = () => {
    this.setState({
      isOpen: false,
    });
  }

  updateUser = (res) => {
    const { updateUser } = this.props;
    if (res && res.status === 204) {
      updateUser();
      this.handleClickOutside();
    }
  }

  handleAddFriend = () => {
    const {
      authenticated,
      setAndOpenModal,
      inviteToFriends,
      user,
    } = this.props;
    const userId = user && user.id;

    if (!authenticated) {
      setAndOpenModal('SIGN_IN_MODAL');
      return;
    }
    inviteToFriends(userId).then(this.updateUser);
  }

  deleteFriend = () => {
    const { user, openDeleteFriendModal } = this.props;
    openDeleteFriendModal(user);
  };

  cancelRequest = () => {
    const { user, cancelRequest } = this.props;
    const userId = user && user.id;

    cancelRequest(userId).then(this.updateUser);
  };

  acceptRequest = () => {
    const { user, acceptFriend } = this.props;
    const userId = user && user.id;

    acceptFriend(userId).then(this.updateUser);
  };

  declineRequest = () => {
    const { user, declineFriend } = this.props;
    const userId = user && user.id;

    declineFriend(userId).then(this.updateUser);
  };

  render() {
    const { user, authenticated } = this.props;
    const { isOpen } = this.state;

    if (!user) return null;

    if (!authenticated) {
      return (
        <div className={styles.sub_wrapper}>
          <button type="button" className={styles.friend_button} onClick={this.handleAddFriend}>
            <AddFriendIcon className={styles.add_icon} />
          </button>
        </div>
      );
    }

    const friendStatus = user.friend_status;

    switch (friendStatus) {
      case REQUEST_SENT:
        return (
          <div className={styles.sub_wrapper}>
            <button type="button" className={styles.friend_button} onClick={this.toogleOpenMenu}>
              <PendingFriendIcon className={styles.pending_icon} />
            </button>

            {isOpen && (
            <ul className={submenuStyles.submenu}>
              <li className={submenuStyles.submenu_item}>
                <button
                  type="button"
                  className={submenuStyles.button}
                  onClick={this.cancelRequest}
                >
                  Cancel
                </button>
              </li>
            </ul>
            )}
          </div>
        );

      case REQUEST_RECEIVED:
        return (
          <div className={styles.sub_wrapper}>
            <button type="button" className={styles.friend_button} onClick={this.toogleOpenMenu}>
              <PendingFriendIcon className={styles.pending_icon} />
            </button>

            {isOpen && (
            <ul className={submenuStyles.submenu}>
              <li className={submenuStyles.submenu_item}>
                <button
                  type="button"
                  className={submenuStyles.button}
                  onClick={this.acceptRequest}
                >
                  Accept
                </button>
              </li>
              <li className={submenuStyles.submenu_item}>
                <button
                  type="button"
                  className={submenuStyles.button}
                  onClick={this.declineRequest}
                >
                  Decline
                </button>
              </li>
            </ul>
            )}
          </div>
        );

      case FRIEND:
        return (
          <div className={styles.sub_wrapper}>
            <button type="button" className={styles.friend_button} onClick={this.toogleOpenMenu}>
              <AddedFriendIcon className={styles.added_icon} />
            </button>
            {isOpen && (
              <ul className={submenuStyles.submenu}>
                <li className={submenuStyles.submenu_item}>
                  <button
                    type="button"
                    className={submenuStyles.button}
                    onClick={this.deleteFriend}
                  >
                    Unfriend
                  </button>
                </li>
              </ul>
            )}
          </div>
        );

      case NOT_FRIEND:
        return (
          <div className={styles.sub_wrapper}>
            <button type="button" className={styles.friend_button} onClick={this.handleAddFriend}>
              <AddFriendIcon className={styles.add_icon} />
            </button>
          </div>
        );

      default:
        return null;
    }
  }
}

export default onClickOutside(FriendSubMenu);
