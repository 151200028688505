import { connect } from 'react-redux';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import { editUser, getUser } from '../login/login_actions';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import Profile from './Profile';
import { getDanceStyles } from '../shared/Dance_styles/dance_styles_actions';
import { addMarker } from '../Map/map_actions';

const mSTP = state => ({
  user: state.session.user,
  token: state.session.token,
  danceStyles: state.danceStyles.styles,
  pinMode: state.map.pinMode,
});
const mDTP = dispatch => ({
  editUser: (user, token) => dispatch(editUser(user, token)),
  getUser: ({ id, token }) => dispatch(getUser({ id, token })),
  openModal: (modalName) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  startLoading: () => dispatch(startLoading()),
  finishLoading: () => dispatch(finishLoading()),
  handleError: err => dispatch(createError(err)),
  getDanceStyles: () => dispatch(getDanceStyles()),
  addMarker: coords => dispatch(addMarker(coords)),
});

export default connect(mSTP, mDTP)(Profile);
