import { connect } from 'react-redux';
import Hosting from './Hosting';
import {
  getHostingEvents,
  getHostingClasses,
  getPastHostEvents,
  getPastHostClasses,
} from './hosting_actions';

import { handleToggleListView, saveScrollPosition } from '../List/list_actions';
import { setHoverId } from '../Map/map_actions';

const mSTP = state => ({
  user: state.session && state.session.user,
  token: state.session.token,
  isListViewActive: state.itemsList.isListViewActive,
  scrollPosition: state.itemsList.scrollPosition,
});

const mDTP = {
  getHostingEvents,
  getHostingClasses,
  handleToggleListView,
  saveScrollPosition,
  getPastHostEvents,
  getPastHostClasses,
  setHoverId,
};

export default connect(mSTP, mDTP)(Hosting);
