import { combineReducers } from 'redux';
import {
  SET_MAP_CENTER,
  SET_MAP_ZOOM,
  RESET_MAP_ZOOM,
  SET_MAP_MARKERS,
  SET_FIT_MAP_FLAG,
  RESET_FIT_MAP_FLAG,
  SET_USER_LOCATION,
  ADD_MARKER,
  CENTER_PIN_MODE_ON,
  CENTER_PIN_MODE_OFF,
  GET_MAP_CENTER_ADDRESS,
  RESET_MAP_CENTER_ADDRESS,
  SEARCH_ON_MOVE_MAP,
  SET_HOVER_ID,
} from '../../redux/action_types';

const INITIAL_STATE = {
  center: null,
  markers: [],
  userLocation: null,
  newMarker: null,
  mapCenterAddress: null,
  mapFitFlag: false,
  zoomFlag: false,
  pinMode: false,
  searchOnMoveMap: false,
  hoverId: null,
};

export const centerReducer = (state = INITIAL_STATE.center, action) => {
  switch (action.type) {
    case SET_MAP_CENTER:
      return action.payload;
    default:
      return state;
  }
};

export const markersReducer = (state = INITIAL_STATE.markers, action) => {
  switch (action.type) {
    case SET_MAP_MARKERS:
      return action.payload;
    default:
      return state;
  }
};

export const userLocationReducer = (state = INITIAL_STATE.userLocation, action) => {
  switch (action.type) {
    case SET_USER_LOCATION:
      return action.payload;
    default:
      return state;
  }
};

export const newMarkerReducer = (state = INITIAL_STATE.newMarker, action) => {
  switch (action.type) {
    case ADD_MARKER:
      return action.payload;
    default:
      return state;
  }
};

export const mapFitReducer = (state = INITIAL_STATE.mapFitFlag, action) => {
  switch (action.type) {
    case SET_FIT_MAP_FLAG:
      return true;
    case RESET_FIT_MAP_FLAG:
      return false;
    default:
      return state;
  }
};

export const zoomReducer = (state = INITIAL_STATE.zoomFlag, action) => {
  switch (action.type) {
    case SET_MAP_ZOOM:
      return true;
    case RESET_MAP_ZOOM:
      return false;
    default:
      return state;
  }
};

export const pinModeReducer = (state = INITIAL_STATE.pinMode, action) => {
  switch (action.type) {
    case CENTER_PIN_MODE_ON:
      return true;
    case CENTER_PIN_MODE_OFF:
      return false;
    default:
      return state;
  }
};

export const mapAddressReducer = (state = INITIAL_STATE.mapCenterAddress, action) => {
  switch (action.type) {
    case GET_MAP_CENTER_ADDRESS:
      return action.payload;
    case RESET_MAP_CENTER_ADDRESS:
      return null;
    default:
      return state;
  }
};

export const searchOnMoveMapReducer = (state = INITIAL_STATE.searchOnMoveMap, action) => {
  switch (action.type) {
    case SEARCH_ON_MOVE_MAP:
      return action.payload;
    default:
      return state;
  }
};

export const hoverReducer = (state = INITIAL_STATE.hoverId, action) => {
  switch (action.type) {
    case SET_HOVER_ID:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  center: centerReducer,
  zoomFlag: zoomReducer,
  markers: markersReducer,
  userLocation: userLocationReducer,
  newMarker: newMarkerReducer,
  mapFitFlag: mapFitReducer,
  pinMode: pinModeReducer,
  mapCenterAddress: mapAddressReducer,
  searchOnMoveMap: searchOnMoveMapReducer,
  hoverId: hoverReducer,
});
