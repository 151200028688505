import { requestLessonDetails } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import { moveMapCenterTo, addMarker } from '../Map/map_actions';
import {
  GET_LESSON_DETAILS_SUCCESS,
  GET_LESSON_DETAILS_FAILURE,
} from '../../redux/action_types';
import { getItemsList } from '../List/list_actions';


export const getLessonDetailsSuccess = lesson => ({
  type: GET_LESSON_DETAILS_SUCCESS,
  payload: lesson,
});

export const getLessonDetailsFailure = error => ({
  type: GET_LESSON_DETAILS_FAILURE,
  payload: { error },
});

export const getLessonDetails = ({ id, moveMap, filter, startDate }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestLessonDetails({ id, token, startDate })
    .then((response) => {
      const lesson = response.data.data;
      dispatch(getLessonDetailsSuccess(lesson));
      dispatch(getItemsList({
        list: 'teachers',
        token,
        filter,
      }))
        .then(() => {
          if (lesson.coordinates && moveMap) {
            dispatch(moveMapCenterTo(lesson.coordinates));
            dispatch(addMarker(lesson.coordinates));
          }
        });

      return response;
    })
    .catch((error) => {
      dispatch(createError(error));
      dispatch(getLessonDetailsFailure(error));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};
