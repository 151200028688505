import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import inputStyles from '../../styles/inputs.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import styles from './comments_block.module.scss';

const INITIAL_STATE = {
  body: '',
  isValid: false,
  isActive: false,
  error: null,
  rows: 1,
};

const MAX_ROWS = 10;

class CommentsForm extends Component {
  static propTypes = {
    error: PropTypes.string,
    token: PropTypes.string,
    item: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
    createComment: PropTypes.func,
    openModal: PropTypes.func.isRequired,
    editingMode: PropTypes.bool,
    comment: PropTypes.shape({
      body: PropTypes.string,
      id: PropTypes.number,
    }),
    onCancel: PropTypes.func,
    editComment: PropTypes.func,
    scrollToTop: PropTypes.func,
  }

  static defaultProps = {
    error: null,
    token: null,
    editingMode: false,
    comment: null,
    onCancel: () => {},
    editComment: () => {},
    scrollToTop: () => {},
    createComment: () => {},
  }

  constructor() {
    super();

    this.textareaRef = createRef();
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const { comment } = this.props;
    const textarea = this.textareaRef && this.textareaRef.current;
    if (textarea && comment && comment.body) {
      const width = textarea.clientWidth;
      const symbolsInRow = Math.trunc(width / 10);
      let rows = Math.round(comment.body.length / symbolsInRow);
      const lineBreaks = (comment.body.match(/\n/g) || []).length;
      rows += lineBreaks;
      if (rows < 1) rows = 1;

      this.setState({
        rows: rows > MAX_ROWS ? MAX_ROWS : rows,
      });
    }

    if (comment && comment.body) {
      this.setState({
        body: comment.body,
        isValid: false,
        isActive: false,
        error: null,
      });
    }
  }

  componentDidUpdate = (nextProps) => {
    if (this.props.error !== nextProps.error) {
      this.setState({ error: nextProps.error });
    }
    if (this.props.item.id !== nextProps.item.id) {
      this.setState({ ...INITIAL_STATE });
    }
  }

  handleCreateComment = () => {
    const { isValid, body } = this.state;
    const { token, item, createComment, openModal, scrollToTop } = this.props;
    if (!isValid) return;
    if (!token) {
      openModal('SIGN_IN_MODAL');
      return;
    }
    const comment = { body };
    if (item && item.id && item.type) {
      createComment({
        comment,
        id: item.id,
        type: item.type,
      }).then((data) => {
        if (data && data.errors && data.errors.body) {
          this.setState({
            error: data.errors.body,
            isValid: false,
          });
        } else {
          this.setState({ ...INITIAL_STATE });
          scrollToTop();
        }
      });
    }
  };

  handleEditComment = () => {
    const { editComment, comment, item, onCancel } = this.props;
    const { body, isValid } = this.state;

    if (!isValid) return;

    if (item && item.type && item.id && body && comment.id) {
      const newComment = { body };
      const { id: commentId } = comment;
      const { type, id: itemId } = item;

      editComment({ comment: newComment, commentId, itemId, type })
        .then(() => onCancel());
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { editingMode } = this.props;
    if (editingMode) this.handleEditComment();
    else this.handleCreateComment();
  }

  handleInputChange = (e) => {
    const { value } = e.target;
    const lineHeight = 21;
    const previousRows = e.target.rows;
    e.target.rows = 1;
    const currentRows = Math.trunc(e.target.scrollHeight / lineHeight);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }
    if (currentRows > MAX_ROWS) {
      e.target.rows = MAX_ROWS;
    }

    this.setState({
      isActive: true,
      isValid: value.trim().length > 0,
      body: value,
      error: null,
      rows: currentRows > MAX_ROWS ? MAX_ROWS : currentRows,
    });
  };

  render() {
    const { body, isValid, isActive, error, rows } = this.state;
    const { editingMode, onCancel } = this.props;

    let fieldStyle;
    if (!isActive) fieldStyle = inputStyles.textarea_box_extensible_not_active;
    else {
      fieldStyle = isValid
        ? inputStyles.textarea_box_extensible
        : inputStyles.textarea_box_extensible_is_error;
    }

    return (
      <form
        onSubmit={this.handleSubmit}
        className={styles.comment_form}
      >
        <div className={fieldStyle}>
          <textarea
            className={inputStyles.textarea_autoheight}
            rows={rows}
            name="body"
            value={body}
            onChange={this.handleInputChange}
            ref={this.textareaRef}
          />
          <label
            htmlFor="body"
            className={body ? inputStyles.label__with_value : inputStyles.label}
          >
            {error || 'Write a comment here...'}
          </label>
        </div>
        {editingMode ? (
          <div className={buttonStyles.buttons_row_with_margin}>
            <button
              className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
              type="button"
              onClick={onCancel}
            >
              cancel
            </button>
            <button
              className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
              type="submit"
              onClick={this.handleEditComment}
            >
              edit
            </button>
          </div>
        ) : (
          <div className={buttonStyles.extra_wide_container}>
            {isValid && (
              <button
                className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                type="submit"
              >
                submit
              </button>
            )}
          </div>
        )}
      </form>
    );
  }
}

export default CommentsForm;
