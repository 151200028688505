import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import Dots from '../icons/Dots';
import defaultAvatar from '../../images/default_avatar.jpg';
import styles from './hosting.module.scss';
import submenuStyles from '../shared/Dots_submenu/dots_submenu.module.scss';
import { HOST, COHOST, PENDING } from '../../config';

export class CohosterItem extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    cohoster: PropTypes.shape({
      id: PropTypes.string,
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      nickname: PropTypes.string,
    }).isRequired,
    isMainHosterView: PropTypes.bool,
    acceptInvitation: PropTypes.func.isRequired,
    cancelCohosting: PropTypes.func.isRequired,
    removeCohoster: PropTypes.func.isRequired,
    openPermissionsModal: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
  };

  static defaultProps = {
    isMainHosterView: false,
  }

  constructor() {
    super();
    this.menuRef = createRef();
  }

  state = {
    isSubMenuOpen: false,
  }

  toggleSubmenu = () => this.setState(prevState => ({
    isSubMenuOpen: !prevState.isSubMenuOpen,
  }), this.switchListener);

  handleSubMenuClose = () => this.setState({
    isSubMenuOpen: false,
  });

  removeCohoster = () => {
    const { cohoster, removeCohoster } = this.props;
    const id = cohoster && cohoster.id;

    removeCohoster(id);
  };

  openPermissions = () => {
    const { openPermissionsModal, cohoster } = this.props;
    openPermissionsModal(cohoster);
  }

  switchListener = () => {
    const { isSubMenuOpen } = this.state;
    if (isSubMenuOpen) {
      document.addEventListener('click', this.clickOutside);
    } else {
      document.removeEventListener('click', this.clickOutside);
    }
  }

  clickOutside = (e) => {
    if (e.target !== this.menuRef.current) {
      this.handleSubMenuClose();
      document.removeEventListener('click', this.clickOutside);
    }
  }

  renderSubMenu = () => {
    const {
      cohoster, isMainHosterView, acceptInvitation,
      cancelCohosting,
    } = this.props;
    const hostingStatus = cohoster && cohoster.hosting_status;

    if (isMainHosterView) {
      return (
        <ul className={submenuStyles.submenu} ref={this.menuRef}>
          <li className={submenuStyles.submenu_item}>
            <button
              type="button"
              className={submenuStyles.button}
              onClick={this.openPermissions}
            >
              Permissions
            </button>
          </li>
          <li className={submenuStyles.submenu_item}>
            <button
              type="button"
              className={submenuStyles.button}
              onClick={this.removeCohoster}
            >
              Remove
            </button>
          </li>
        </ul>
      );
    }

    switch (hostingStatus) {
      case PENDING:
        return (
          <ul className={submenuStyles.submenu} ref={this.menuRef}>
            <li className={submenuStyles.submenu_item}>
              <button
                type="button"
                className={submenuStyles.button}
                onClick={acceptInvitation}
              >
                Accept
              </button>
            </li>
            <li className={submenuStyles.submenu_item}>
              <button
                type="button"
                className={submenuStyles.button}
                onClick={cancelCohosting}
              >
                Decline
              </button>
            </li>
          </ul>
        );
      case COHOST:
        return (
          <ul className={submenuStyles.submenu} ref={this.menuRef}>
            <li className={submenuStyles.submenu_item}>
              <button
                type="button"
                className={submenuStyles.button}
                onClick={cancelCohosting}
              >
                Remove
              </button>
            </li>
          </ul>
        );
      default:
        return null;
    }
  }

  render() {
    const { isSubMenuOpen } = this.state;
    const { cohoster, currentUser, isMainHosterView, history } = this.props;

    if (!cohoster) return null;

    const {
      first_name: firstName,
      last_name: lastName,
      nickname,
      hosting_status: hostingStatus,
      id,
    } = cohoster;
    let secondaryName = '';
    if (firstName) secondaryName += firstName;
    if (lastName) secondaryName += ` ${lastName}`;
    const mainName = nickname || secondaryName || 'Anonymous';

    const avatarPath = cohoster.avatar && cohoster.avatar.thumb
      ? cohoster.avatar.thumb : defaultAvatar;
    const currentUserId = currentUser && currentUser.id;
    const isDotsVisible = hostingStatus !== HOST
      && (cohoster.id === currentUserId || isMainHosterView);

    const path = {
      pathname: `/users/${id}`,
      state: { from: history.location.pathname },
    };

    return (
      <div className={styles.cohoster_wrapper}>
        <Link to={path}>
          <div
            className={styles.avatar}
            style={{ backgroundImage: `url(${avatarPath})` }}
          />
        </Link>
        <div className={styles.name_box}>
          <Link to={path}>
            <p>{mainName}</p>
            {nickname ? (
              <p className={styles.secondary_name}>{secondaryName}</p>
            ) : null}
          </Link>
        </div>
        <div className={styles.hoster_status}>
          {hostingStatus === COHOST ? 'co-host' : hostingStatus}
        </div>
        {isDotsVisible && (
          <button
            type="button"
            onClick={this.toggleSubmenu}
            className={styles.dots_button}
          >
            <Dots className={styles.dots} />
          </button>
        )}
        {isSubMenuOpen && this.renderSubMenu()}
      </div>
    );
  }
}

export default withRouter(CohosterItem);
