import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './u_ticket_modal.module.scss';

const LastCheckin = ({ item }) => {
  const date = item && item.created_at && moment(item.created_at).format('MMM DD, YYYY, HH:mm');
  return (
    <div className={styles.last_checkin}>
      <p className={styles.location}>{item.item_title}</p>
      <p className={styles.address}>{date}</p>
    </div>
  );
};

LastCheckin.propTypes = {
  item: PropTypes.shape({
    item_title: PropTypes.string,
    created_at: PropTypes.string,
  }).isRequired,
};

export default LastCheckin;
