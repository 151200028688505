import { connect } from 'react-redux';
import VenueSelector from './Venue_selector';
import {
  moveMapCenterTo, addMarker, setOnPinMode,
  resetMapCenterAddress, setOffPinMode,
} from '../Map/map_actions';
import getVenueAutoComplete from './venue_selector_actions';
import createError from '../Fetch_error/fetch_error_action';

const mSTP = state => ({
  mapCenterAddress: state.map.mapCenterAddress,
  pinMode: state.map.pinMode,
});

const mDTP = dispatch => ({
  showNewMarker: (coords) => {
    dispatch(moveMapCenterTo(coords));
    dispatch(addMarker(coords));
  },
  getVenueAutoComplete: query => dispatch(getVenueAutoComplete(query)),
  setOnPinMode: () => dispatch(setOnPinMode()),
  setOffPinMode: () => dispatch(setOffPinMode()),
  resetMapCenterAddress: () => dispatch(resetMapCenterAddress()),
  createError: error => dispatch(createError(error)),
});

export default connect(mSTP, mDTP)(VenueSelector);
