import { LOADING_START, LOADING_FINISH } from '../../redux/action_types';

export const startLoading = () => (dispatch) => {
  clearTimeout(window.isLoading);
  window.isLoading = setTimeout(() => {
    dispatch({ type: LOADING_START });
  }, 500);
};

export const finishLoading = () => {
  clearTimeout(window.isLoading);
  return ({
    type: LOADING_FINISH,
  });
};
