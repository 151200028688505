import { connect } from 'react-redux';
import { getPassDetails } from './pass_details_actions';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import { setItemToDelete } from '../My_items/my_items_actions';
import PassDetails from './Pass_details';

const mSTP = (state) => {
  const { token } = state.session;
  const { pass, error } = state.passDetails;

  return ({
    pass,
    error,
    token,
  });
};

const mDTP = dispatch => ({
  getPassDetails: ({ id }) => dispatch(getPassDetails({ id })),
  openModal: (modalName) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  deletePass: (pass) => {
    dispatch(setItemToDelete(pass));
    dispatch(setCurrentModal('REMOVE_ITEM_MODAL'));
    dispatch(openModal());
  },
});

export default connect(mSTP, mDTP)(PassDetails);
