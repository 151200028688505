import React from 'react';
import PropTypes from 'prop-types';

const DancerIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="27"
    viewBox="0 0 72 137"
    className={className}
  >
    <path d="M35.464 86.046l10.538-3.63c.47-1.01.934-1.962 1.387-2.864.448-.057.897-.124 1.348-.198-1.023 2-2.113 4.294-3.209 6.87-4.353 10.236-8.793 24.93-9.215 42.91-.09 3.853 2.967 7.05 6.828 7.139.055.002.11.002.166.002 3.786 0 6.898-3.018 6.986-6.817.368-15.847 4.292-28.814 8.104-37.781 1.907-4.48 3.78-7.955 5.15-10.264a56.172 56.172 0 0 1 1.612-2.573c.185-.278.323-.477.407-.597l.087-.119h-.002c.408-.55.717-1.14.941-1.75.438-.611.78-1.333.974-2.142l.422-1.75c3.426-14.202 5.593-23.137 2.582-37.899-.022-.106-.062-.209-.096-.314.662-1.427 1.025-2.96 1.006-4.486-.005-2.542-.88-4.818-2.076-6.79-.897-1.458-1.987-2.803-3.203-4.076-1.663-1.74-3.57-3.34-5.608-4.872-5.297-3.94-11.518-7.308-16.474-9.755C39.17 1.85 35.502.36 35.432.33c-2.289-.93-4.9.17-5.831 2.454a4.465 4.465 0 0 0 2.458 5.82l.33.138c1.712.71 9.797 4.188 17.219 8.7 3.704 2.244 7.237 4.768 9.63 7.135l.077.078c1.158 1.148 2.032 2.25 2.546 3.153.536.933.675 1.59.67 1.975l-.004.056c-.043.008-.092.01-.132.02l-9.453 2.098a13.682 13.682 0 0 1-14.203 4.028c-2.555 1.604-10.166 5.646-22.053 5.643-3.416 0-7.195-.332-11.333-1.159a4.473 4.473 0 0 0-5.265 3.504 4.467 4.467 0 0 0 3.51 5.256c4.714.94 9.09 1.33 13.087 1.33 10.76-.004 18.701-2.812 23.44-5.142 3.937 6.85 5.798 12.946 2.949 23.24l-24.1 8.304a6.973 6.973 0 1 0-2.112 12.025l19.388 15.632c.574.463 1.2.814 1.853 1.069 1.21-5.34 2.67-10.097 4.146-14.174l-6.785-5.468z" />
    <path d="M42.693 35.453c6.95 0 12.584-5.623 12.584-12.561 0-.087-.005-.172-.007-.259-1.773-1.37-3.873-2.8-6.244-4.235-4.414-2.684-9.03-4.981-12.362-6.534-3.906 2.132-6.556 6.27-6.556 11.028 0 6.938 5.636 12.561 12.585 12.561z" />
  </svg>
);

DancerIcon.propTypes = {
  className: PropTypes.string,
};

DancerIcon.defaultProps = {
  className: '',
};

export default DancerIcon;
