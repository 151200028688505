import { combineReducers } from 'redux';
import {
  SIGN_IN_SUCCES,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  EDIT_USER,
  EDIT_USER_AVATAR,
  CLOSE_MODAL,
  EDIT_USER_ERROR,
  GET_USER,
} from '../../redux/action_types';

const initialState = {
  user: null,
  authenticated: false,
  token: null,
  error: null,
};

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCES:
      return action.payload.user;

    case SIGN_OUT:
      return initialState.user;

    case EDIT_USER:
      return {
        ...action.payload,
      };

    case EDIT_USER_AVATAR:
      return {
        ...state,
        avatar: action.payload,
      };

    case GET_USER:
      return action.payload;

    default:
      return state;
  }
};

const authenticatedReducer = (state = initialState.authenticated, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCES:
      return true;

    case SIGN_OUT:
      return false;

    default:
      return state;
  }
};

const tokenReducer = (state = initialState.token, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCES:
      return action.payload.token;

    case SIGN_OUT:
      return initialState.token;

    default:
      return state;
  }
};

const errorReducer = (state = initialState.error, action) => {
  switch (action.type) {
    case SIGN_IN_FAILURE:
    case EDIT_USER_ERROR:
      return action.payload;

    case SIGN_IN_SUCCES:
    case SIGN_OUT:
    case CLOSE_MODAL:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  user: userReducer,
  authenticated: authenticatedReducer,
  token: tokenReducer,
  error: errorReducer,
});
