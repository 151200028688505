import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import CardSection from '../Card_section/Card_section';
import MethodItem from './Method_item';

import getNestedValue from '../../../utils/getNestedValue';

import paymentStyles from '../../../styles/payment_methods.module.scss';

export class SelectPayment extends React.Component {
  static propTypes = {
    token: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    methods: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleSelectMethod: PropTypes.func.isRequired,
    cardId: PropTypes.string,
    nameStyle: PropTypes.string.isRequired,
    getPaymentMethods: PropTypes.func.isRequired,
  };

  static defaultProps = {
    token: '',
    cardId: '',
  }

  state = {
    isLoading: false,
  }

  componentDidMount() {
    this.getPaymentMethods();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      if (this.props.token) {
        this.getPaymentMethods();
      } else {
        const { history } = this.props;
        const to = {
          pathname: '/login',
          state: { from: getNestedValue(history, 'location', 'pathname') },
        };
        history.push(to);
      }
    }
  }

  getPaymentMethods = async () => {
    const { getPaymentMethods } = this.props;

    try {
      this.setState({ isLoading: true });
      await getPaymentMethods();
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading } = this.state;
    const { methods, nameStyle, handleSelectMethod, handleInputChange, cardId } = this.props;
    const isNewCardOpen = cardId === 'new';

    if (isLoading) return <p>Loading your payment methods...</p>;

    return (
      <>
        {methods && Array.isArray(methods) && methods.length > 0
          ? (
            <>
              <p>Choose your payment method:</p>
              <ul>
                {methods.map(card => (
                  <li key={card.id}>
                    <MethodItem
                      method={card}
                      handleSelectMethod={handleSelectMethod}
                      checked={card.id === cardId}
                    />
                  </li>
                ))}
                <li>
                  <label className={paymentStyles.method_item_text}>
                    <input
                      type="radio"
                      value="new"
                      checked={isNewCardOpen}
                      onChange={handleSelectMethod}
                      className={paymentStyles.radio}
                      name="payment_method"
                    />
                    <div>Use a new payment method</div>
                  </label>
                </li>
              </ul>
              {isNewCardOpen && (
                <CardSection onNameChange={handleInputChange} nameStyle={nameStyle} />
              )}
            </>
          )
          : <CardSection onNameChange={handleInputChange} nameStyle={nameStyle} />
        }
      </>
    );
  }
}

export default withRouter(SelectPayment);
