import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import { withRouter } from 'react-router-dom';
import { checkoutOrder, checkoutSubscription } from './checkout_form_actions';
import { resetSubscriptionToCheckout } from '../checkout_actions';
import { openModal, setCurrentModal } from '../../Modal/modal_actions';
import createError from '../../Fetch_error/fetch_error_action';

import CheckoutForm from './Checkout_form';

const mSTP = state => ({
  token: state.session.token,
  email: state.session.user && state.session.user.email,
  methods: state.paymentMethods,
});

const mDTP = dispatch => ({
  checkoutOrder: (data, id) => dispatch(checkoutOrder(data, id)),
  checkoutSubscription: (data, id) => dispatch(checkoutSubscription(data, id)),
  openModal: (modalName) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  createError: error => dispatch(createError(error)),
  resetSubscriptionToCheckout: () => dispatch(resetSubscriptionToCheckout()),
});

export default injectStripe(withRouter(connect(mSTP, mDTP)(CheckoutForm)));
