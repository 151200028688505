import React from 'react';
import PropTypes from 'prop-types';

const LikesIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 41" width="24" height="22">
    <path fill="#2e76ea" stroke="#07e" strokeMiterlimit="10" d="M41.3 24.6l-6.1-1.3v-1.9c4.1-.4 5.8-1.2 5.8-2.8 0-3.1-2-10.2-2.4-11.5.1-1.4-.4-2.8-1.3-3.9-.6-.6-1.4-.9-2.2-.9-.2 0-.5-.1-.7-.2-1.2-.7-2.6-1-4-1C28 1.3 25.7 2.3 24 4c-.5.6-.5 1.4.1 2 .5.5 1.4.5 1.9 0 1.2-1.3 2.7-2 4.4-2 .9 0 1.9.3 2.7.7.5.2 1 .4 1.6.5.3 0 .6.1.7.1.3.3.5.6.5 2.2 0 .1 0 .2.1.4 1 3.4 1.8 6.8 2.3 10.3-1.5.4-2.9.6-4.5.7-.7.1-1.3.7-1.3 1.4v4.2c0 .6.5 1.2 1.1 1.3l7.1 1.6c1.3.3 2.1 1.4 2.1 2.7v1.5h-7.2c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4h8.6c.8 0 1.4-.6 1.4-1.4v-3c0-2.6-1.8-4.8-4.3-5.4zM2.8 39.8h27.7c.8 0 1.4-.6 1.4-1.4v-3.3c0-2.6-1.8-4.8-4.4-5.4l-6.1-1.3v-1.8c.3-.5.7-1.1.9-1.7.2-.4.5-.9.7-1.3 1.1-1 2-2.3 2.4-3.7.3-.9.2-1.9-.3-2.7 0-1.5-.1-3.1-.4-4.6.1-1.4-.3-2.7-1.2-3.8-.6-.6-1.4-.9-2.2-1-.2 0-.5-.1-.7-.1-1.2-.7-2.6-1-4-1-3.1.1-6.8 2.1-8.1 5.6-.3 1.4-.4 2.8-.3 4.2v.7c-.6.8-.7 1.8-.4 2.7.4 1.5 1.2 2.8 2.4 3.7.3.4.5.9.7 1.3.3.6.6 1.1.9 1.6v1.8l-6.1 1.3c-2.5.6-4.3 2.8-4.4 5.4v3.3c.1.9.7 1.5 1.5 1.5zm1.3-4.7c0-1.3.9-2.4 2.2-2.7l7.2-1.5c.6-.1 1.1-.7 1.1-1.3v-3.4c0-.4-.2-.7-.4-1-.3-.5-.6-.9-.8-1.4-.3-.7-.7-1.4-1.3-2-.7-.6-1.3-1.4-1.6-2.3-.1-.3-.1-.7.1-1 .2-.3.3-.7.3-1.1v-.8c-.1-1.1 0-2.1.2-3.2.8-2.3 3.5-3.7 5.5-3.7.9 0 1.8.2 2.6.7.5.2 1 .4 1.6.5.2 0 .6.1.7.1.2.2.5.5.5 2.1 0 .1 0 .2.1.4.3 1.3.4 2.7.3 4 0 .4.1.8.3 1.1.2.3.2.7.1 1-.3.9-.8 1.7-1.6 2.3-.5.6-.9 1.3-1.3 2-.2.5-.5 1-.8 1.4-.3.3-.4.6-.4 1v3.4c0 .6.5 1.2 1.1 1.3l7.2 1.5c1.3.3 2.2 1.4 2.2 2.7V37H4.1v-1.9z" />
  </svg>
);

LikesIcon.propTypes = {
  className: PropTypes.string,
};

LikesIcon.defaultProps = {
  className: '',
};

export default LikesIcon;
