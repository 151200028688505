import {
  signInUser,
  requestSignOut,
  createUser,
  requestEditUser,
  requestGetUser,
  editUserAvatar,
  loginWithGoogle,
  loginWithFacebook,
} from '../../api';

import {
  SIGN_IN_SUCCES,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  EDIT_USER,
  EDIT_USER_AVATAR,
  EDIT_USER_ERROR,
  GET_USER,
} from '../../redux/action_types';
import { closeModal, openModal, setCurrentModal } from '../Modal/modal_actions';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import { errorsToString } from '../../utils/parse';
import getNestedValue from '../../utils/getNestedValue';
import history from '../../utils/history';

export const loginUser = credentials => (dispatch) => {
  dispatch(startLoading());
  signInUser(credentials)
    .then(
      (res) => {
        if (res.status === 201) {
          dispatch({
            type: SIGN_IN_SUCCES,
            payload: {
              user: res.data.data,
              token: res.data.access_token,
            },
          });
          dispatch(closeModal());
        }
      },
      (rej) => {
        const status = getNestedValue(rej, 'response', 'status');
        if (status === 422) {
          dispatch({
            type: SIGN_IN_FAILURE,
            payload: {
              login_error: {
                message: getNestedValue(rej, 'response', 'data', 'errors', 'email'),
                email: credentials.email,
              },
            },
          });
        } else if (status === 403) {
          dispatch({
            type: SIGN_IN_FAILURE,
            payload: {
              login_error: {
                message: getNestedValue(rej, 'response', 'data', 'error'),
                email: credentials.email,
                showResendButton: true,
              },
            },
          });
        } else {
          dispatch(createError());
        }
      },
    )
    .catch((error) => {
      dispatch(createError(error));
    })
    .finally(() => { dispatch(finishLoading()); });
};

export const signOut = token => (dispatch) => {
  dispatch(startLoading());
  requestSignOut(token).finally(
    () => {
      dispatch(finishLoading());
      history.replace('/');
      dispatch({ type: SIGN_OUT });
    },
  );
  try {
    if (window.gapi) {
      window.gapi.load('auth2', () => {
        const googleAuth = window.gapi.auth2;

        if (googleAuth) {
          googleAuth.init({ client_id: '113113078497-qvjgojvg78mlhgd00smtjsvqvdci39iv.apps.googleusercontent.com' })
            .then(() => {
              googleAuth.getAuthInstance().signOut().then(
                googleAuth.getAuthInstance().disconnect(),
              );
            });
        }
      });
    }
    // for webView app
    if (window.android && window.android.googleLogOut) {
      window.android.googleLogOut();
    }
    if (window.android && window.android.facebookLogOut) {
      window.android.facebookLogOut();
    }
    // end webWiev app code
  } catch {
    createError('Google logout failed');
  }
};

export const registerUser = credentials => (dispatch) => {
  dispatch(startLoading());

  createUser(credentials)
    .then(
      (res) => {
        if (res.status === 201) {
          dispatch(closeModal());
          dispatch(setCurrentModal('SUCCES_REGISTRATION_MODAL'));
          dispatch(openModal());
        }
      },

      (rej) => {
        if (rej.response.status === 422) {
          dispatch({
            type: SIGN_IN_FAILURE,
            payload: {
              sign_in_error: {
                email: credentials.email,
                error: getNestedValue(rej, 'response', 'data', 'errors'),
              },
            },
          });
        }
      },
    )
    .catch((error) => {
      dispatch(createError(error));
    })
    .finally(() => { dispatch(finishLoading()); });
};

export const editUser = (user, token) => (dispatch, getState) => {
  dispatch(startLoading());
  const { session } = getState();
  const loggedUserId = getNestedValue(session, 'user', 'id');


  return (requestEditUser(user, token)
    .then(
      (res) => {
        if (res.status === 200 && user.id === loggedUserId) {
          dispatch({
            type: EDIT_USER,
            payload: getNestedValue(res, 'data', 'data'),
          });
        }
        return res;
      },
    )
    .catch((err) => {
      const status = getNestedValue(err, 'response', 'status');
      if (status === 422) {
        dispatch({
          type: EDIT_USER_ERROR,
          payload: getNestedValue(err, 'response', 'data'),
        });
      } else if (status === 401) {
        dispatch(createError(getNestedValue(err, 'response', 'data', 'error')));
        dispatch({ type: SIGN_OUT });
      } else if (status === 403) {
        dispatch(createError(getNestedValue(err, 'response', 'data', 'error')));
      } else {
        dispatch(createError(err));
      }

      return getNestedValue(err, 'response', 'data');
    }))
    .finally(() => {
      dispatch(finishLoading());
    });
};

export const editAvatar = ({ id, avatar }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { session } = getState();
  const token = getNestedValue(session, 'token');
  const loggedUserId = getNestedValue(session, 'user', 'id');

  return (
    editUserAvatar({ id, avatar, token })
      .then((res) => {
        if (loggedUserId === id) {
          dispatch({
            type: EDIT_USER_AVATAR,
            payload: getNestedValue(res, 'data', 'data', 'avatar'),
          });
        }
      })
      .catch((rej) => {
        if (rej.response && rej.response.status === 401) dispatch(signOut());
        if (rej.response && rej.response.status === 422) {
          dispatch(createError(errorsToString(rej.response.data.errors)));
        } else {
          dispatch(createError(rej));
        }
        return rej.response && rej.response.data;
      })
      .finally(() => { dispatch(finishLoading()); })
  );
};

export const getUser = ({ id, token }) => (dispatch) => {
  dispatch(startLoading());

  return requestGetUser({ id, token })
    .then((res) => {
      if (res.status === 200 || res.status === 304) {
        dispatch({
          type: GET_USER,
          payload: getNestedValue(res, 'data', 'data'),
        });
      }
    })
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 422) {
        dispatch(createError(getNestedValue(rej, 'response', 'data', 'errors')));
      }
      return rej && rej.response && rej.response.data;
    })
    .finally(() => { dispatch(finishLoading()); });
};

export const loginUserWithGoogle = token => (dispatch) => {
  dispatch(startLoading());

  loginWithGoogle(token)
    .then((res) => {
      if (res && res.status === 201) {
        dispatch({
          type: SIGN_IN_SUCCES,
          payload: {
            user: res && res.data && res.data.data,
            token: res && res.data && res.data.access_token,
          },
        });
      }
    })
    .catch((error) => { dispatch(createError(error)); })
    .finally(() => { dispatch(finishLoading()); });

  dispatch(closeModal());
};

export const loginUserWithFacebook = token => (dispatch) => {
  dispatch(startLoading());

  loginWithFacebook(token)
    .then((res) => {
      if (res && res.status === 201) {
        dispatch({
          type: SIGN_IN_SUCCES,
          payload: {
            user: res && res.data && res.data.data,
            token: res && res.data && res.data.access_token,
          },
        });
      }
    })
    .catch((error) => { dispatch(createError(error)); })
    .finally(() => { dispatch(finishLoading()); });

  dispatch(closeModal());
};

export const getUserAfterAppleId = ({ id, token, from }) => (dispatch) => {
  dispatch(startLoading());

  return requestGetUser({ id, token })
    .then((res) => {
      if (res && (res.status === 200 || res.status === 304)) {
        const payload = {
          user: res.data && res.data.data,
          token,
        };

        dispatch({
          type: SIGN_IN_SUCCES,
          payload,
        });

        history.replace(from || '/');
      }
    })
    .catch((rej) => {
      if (rej && rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej && rej.response && rej.response.status === 422) {
        dispatch(createError(rej.response.data.errors));
      }
      return rej && rej.response && rej.response.data;
    })
    .finally(() => { dispatch(finishLoading()); });
};
