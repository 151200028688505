import { GOOGLE_ANALYTICS_ID } from '../config';

export const connectGoogleAnalytics = () => {
  const googleScript = document.createElement('script');
  googleScript.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`;
  document.head.appendChild(googleScript);

  window.dataLayer = window.dataLayer || [];
  function gtag() { window.dataLayer.push(arguments); } // eslint-disable-line
  gtag('js', new Date());

  gtag('config', GOOGLE_ANALYTICS_ID);
};

export const sendAnalytics = (location) => {
  const { ga } = window;

  if (!ga || typeof ga !== 'function' || !location) return;

  ga('set', 'page', location.pathname + location.serch);
  ga('send', 'pageview');
};
