const getGoogleDirectionLink = (item) => {
  const lat = item && item.coordinates && item.coordinates.lat;
  const lng = item && item.coordinates && item.coordinates.lng;

  if (lat && lng) {
    return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
  }

  return 'https://www.google.com/maps/dir/?api=1';
};

export default getGoogleDirectionLink;
