const getFullDayName = (day) => {
  switch (day) {
    case 'mo':
      return 'Monday';
    case 'tu':
      return 'Tuesday';
    case 'we':
      return 'Wednesday';
    case 'th':
      return 'Thursday';
    case 'fr':
      return 'Friday';
    case 'sa':
      return 'Saturday';
    case 'su':
      return 'Sunday';
    default: return null;
  }
};

export const getNumberEnding = (day) => {
  let dayWithEnding = '';
  switch (day) {
    case 1:
    case 21: {
      dayWithEnding = `${day}st`;
      break;
    }
    case 2:
    case 22: {
      dayWithEnding = `${day}nd`;
      break;
    }
    case 3:
    case 23: {
      dayWithEnding = `${day}rd`;
      break;
    }
    case -1: {
      dayWithEnding = 'last';
      break;
    }
    default: {
      dayWithEnding = `${day}th`;
      break;
    }
  }
  return dayWithEnding;
};

const getDayString = ({ bymonthday }) => {
  let result = '';

  if (bymonthday && bymonthday.length > 0) {
    result = `every ${getNumberEnding(bymonthday[0])} day of month`;
  }
  return result;
};

const getWeekDayString = ({ byday, week }) => {
  let weekWithEnding;
  switch (week) {
    case 1: {
      weekWithEnding = '1st';
      break;
    }
    case 2: {
      weekWithEnding = '2nd';
      break;
    }
    case 3: {
      weekWithEnding = '3rd';
      break;
    }
    case 4: {
      weekWithEnding = '4th';
      break;
    }
    case -1: {
      weekWithEnding = 'last';
      break;
    }
    default: {
      break;
    }
  }
  let result = '';
  if (weekWithEnding && byday.length > 0) {
    result = `every ${weekWithEnding} ${getFullDayName(byday[0])}`;
  }
  return result;
};

export const getRecurrentString = (recurrentObj) => {
  const dayString = getDayString(recurrentObj);
  const weekDayString = getWeekDayString(recurrentObj);
  let result = '';
  if (dayString !== '') result = dayString;
  if (weekDayString !== '') result = weekDayString;
  return result;
};

export default getRecurrentString;
