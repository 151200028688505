import {
  GET_VENUE_DETAILS_SUCCESS,
  GET_VENUE_DETAILS_FAILURE,
} from '../../redux/action_types';

const initialState = {
  venue: {},
  error: null,
};

const getVenueDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_VENUE_DETAILS_SUCCESS:
      return {
        ...initialState,
        venue: action.payload,
      };

    case GET_VENUE_DETAILS_FAILURE:
      return {
        ...initialState,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default getVenueDetails;
