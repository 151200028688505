import {
  GET_TEACHER_DETAILS_SUCCESS,
  GET_TEACHER_DETAILS_FAILURE,
} from '../../redux/action_types';

const initialState = {
  teacher: {},
  error: null,
};

const getTeacherDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEACHER_DETAILS_SUCCESS:
      return {
        ...initialState,
        teacher: action.payload,
      };

    case GET_TEACHER_DETAILS_FAILURE:
      return {
        ...initialState,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default getTeacherDetails;
