import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';
import MediaQuery from 'react-responsive';
import qs from 'qs';
import moment from 'moment';

import CancelIcon from '../icons/Cancel_icon';
import CheckinItem from './Check-in_item';
import SearchUserForm from '../Search_user_form/Search_user_form';

import getNestedValue from '../../utils/getNestedValue';
import { formatDateForEventDetails } from '../../utils/formatDate';
import gridStyles from '../../styles/main_grid.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import styles from './check-in.module.scss';
import Navigation from '../CheckinsHostsReportsNav/CheckinsHostSReportsNav';
import { EVENTS, LESSONS, LESSONS_LABEL } from '../../config';

const TEXT_EMPTY = '...';
const INITIAL_STATE = {
  guestList: [],
  isScanOpened: false,
  searchText: '',
  title: '',
  startDate: '',
  endDate: '',
  isPastHosting: true,
  hostingStatus: '',
};

class CheckIn extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    getSoldTickets: PropTypes.func.isRequired,
    verifyTicket: PropTypes.func.isRequired,
    createError: PropTypes.func.isRequired,
    getLessonDetails: PropTypes.func.isRequired,
    getEventDetails: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      host_code: PropTypes.string,
    }).isRequired,
  }

  state = { ...INITIAL_STATE };

  componentDidMount() {
    window.scanFromWrapper = this.handleScan; // for webview
    this.getSoldTicketsList();
  }

  getSoldTicketsList = () => {
    const { match, getSoldTickets, history } = this.props;
    let type;
    if (match.url.indexOf(`/${LESSONS_LABEL}/`) !== -1) type = LESSONS;
    if (match.url.indexOf(`/${EVENTS}/`) !== -1) type = EVENTS;
    const id = match && match.params && match.params.id;
    const queryObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const startDate = queryObject && queryObject.start_date;

    if (type && id) {
      getSoldTickets(id, type, startDate)
        .then((data) => {
          if (data && data.status === 200 && data.data && data.data.data) {
            this.setState({ guestList: data.data.data });
          }
        });
      this.getItemInfo(id, type);
    }
  }

  getItemInfo = async (id, type) => {
    const { getEventDetails, getLessonDetails, history } = this.props;
    const queryObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const queryDate = queryObject && queryObject.start_date;
    let getItemDetails;

    if (type === EVENTS && getEventDetails) {
      getItemDetails = getEventDetails;
    } else if (type === LESSONS && getLessonDetails) {
      getItemDetails = getLessonDetails;
    }

    if (getItemDetails) {
      const response = await getItemDetails({ id, startDate: queryDate });
      const title = getNestedValue(response, 'data', 'data', 'title');
      const startDate = getNestedValue(response, 'data', 'data', 'start_date');
      const endDate = getNestedValue(response, 'data', 'data', 'end_date');
      const hostingStatus = getNestedValue(response, 'data', 'data', 'hosting_status');
      if (title) this.setState({ title });
      const isPastHosting = endDate && moment(endDate).isBefore(moment().subtract(1, 'hours'));
      this.setState({
        startDate,
        endDate,
        isPastHosting,
        hostingStatus,
      });
    }
  }

  handleScan = (ticketId) => {
    const { match, verifyTicket } = this.props;
    const itemId = match.params.id;
    if (ticketId && !this.isVerifying) {
      this.isVerifying = true;

      verifyTicket(ticketId, itemId)
        .then(() => {
          this.setState({ isScanOpened: false });
          this.getSoldTicketsList();
        })
        .finally(() => {
          this.isVerifying = false;
        });
    }
  }

  handleError = (error) => {
    const { createError } = this.props;
    createError(error);
  }

  handleToggleScan = () => {
    if (window.android && window.android.requestQRCode) {
      window.android.requestQRCode();
    } else if (window.requestQRCode) {
      window.requestQRCode();
    } else {
      this.setState(prevState => ({ isScanOpened: !prevState.isScanOpened }));
    }
  }

  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
  };

  handleCloseCheckIn = () => {
    this.setState({ ...INITIAL_STATE });
    const { history } = this.props;
    const path = history && history.location && history.location.state
      && history.location.state.from
      ? history.location.state.from
      : '/';
    history.push(path);
  }

  resetSearch = () => this.setState({ searchText: '' });

  renderScanner = () => ReactDOM.createPortal((
    <div
      className={styles.overlay}
    >
      <QrReader
        onError={this.handleError}
        onScan={this.handleScan}
        maxImageSize={1000}
        delay={300}
        className={styles.scanner}
      />
      <button type="button" className={styles.close_white} onClick={this.handleToggleScan}><CancelIcon /></button>
    </div>
  ), document.querySelector('#root'));

  preventSubmit = (e) => {
    e.preventDefault();
  }

  filterByName = (item) => {
    const { searchText } = this.state;

    const nickName = item && item.buyer && item.buyer.nickname;
    const firstName = item && item.buyer && item.buyer.first_name;
    const lastName = item && item.buyer && item.buyer.last_name;
    const userNames = [nickName, firstName, lastName];
    const userPassed = userNames.some(
      name => name && name.toLowerCase().includes(searchText.toLowerCase()),
    );

    return userPassed;
  };

  render() {
    const {
      guestList,
      isScanOpened,
      searchText,
      title,
      startDate,
      endDate,
      isPastHosting,
      hostingStatus,
    } = this.state;
    const { match, user, history } = this.props;

    const hostCode = user && user.host_code;

    const filteredList = searchText === ''
      ? guestList
      : guestList.filter(this.filterByName);

    const dateToShow = startDate && endDate
      ? formatDateForEventDetails(startDate, endDate)
      : TEXT_EMPTY;

    const checkedInCount = Array.isArray(guestList)
      ? guestList.filter(guest => guest.consumed === true).length
      : 0;

    return (
      <div className={`${gridStyles.wrapper_fixed_bottom} ${styles.checkin_wrapper}`}>
        <div className={isPastHosting ? styles.container_longer : styles.container}>
          <Navigation
            history={history}
            match={match}
            hostCode={hostCode}
            title={title}
            isItemPassed={isPastHosting}
            hostingStatus={hostingStatus}
            permissions={{ checkin: true }}
          />
          <div className={styles.flex_row}>
            {dateToShow ? (
              <h4 className={styles.grey_text}>{dateToShow}</h4>
            ) : null}
            <div className={styles.grey_text}>Checkins: {checkedInCount}</div>
          </div>
          <SearchUserForm
            searchText={searchText}
            handleInputChange={this.handleInputChange}
            resetSearch={this.resetSearch}
          />
          {isPastHosting
            ? null
            : (
              // values window.testMediaQueryValues for test only
              <MediaQuery maxWidth={767} values={window.testMediaQueryValues}>
                <button
                  className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                  onClick={this.handleToggleScan}
                  data-test="scan_button"
                  type="button"
                >
                  Scan
                </button>
              </MediaQuery>
            )
          }
          <ul>
            {Array.isArray(filteredList)
              && filteredList.map(item => <CheckinItem key={item.id} item={item} />)
            }
          </ul>
        </div>
        {isPastHosting
          ? null
          : (
            <MediaQuery minWidth={768}>
              <div className={styles.scan_btn_container}>
                <button
                  className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                  onClick={this.handleToggleScan}
                  type="button"
                  data-test="scan_button"
                >
                  Scan
                </button>
              </div>
            </MediaQuery>
          )
        }
        {isScanOpened && this.renderScanner()}
      </div>
    );
  }
}

export default CheckIn;
