import {
  requestHostingEvents, requestHostingClasses,
  searchHostersForEvent, searchHostersForLesson,
  inviteUserToCohostEvent, inviteUserToCohostLesson,
  acceptCohostingEvent, acceptCohostingLesson,
  cancelCohostingEvent, cancelCohostingLesson,
  removeCohosterFromEvent, removeCohosterFromLesson,
  requestPastHostEvents, requestPastHostClasses,
} from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import { showMarkers, fitMapBoundsToMarkers } from '../Map/map_actions';
import { SET_COHOSTING_DATA } from '../../redux/action_types';

import getNestedValue from '../../utils/getNestedValue';

import { EVENT, LESSON } from '../../config';


export const getHostingEvents = () => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());
  return requestHostingEvents(token)
    .then((res) => {
      const data = getNestedValue(res, 'data', 'data');
      if (res.status === 200 && data) {
        dispatch(showMarkers(data));
        dispatch(fitMapBoundsToMarkers());
        return data;
      }

      return null;
    })
    .catch((err) => {
      dispatch(createError(err));
    })
    .finally(() => dispatch(finishLoading()));
};

export const getPastHostEvents = () => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());
  return requestPastHostEvents(token)
    .then((res) => {
      const data = getNestedValue(res, 'data', 'data');
      if (res.status === 200 && data) {
        return data;
      }

      return null;
    })
    .catch((err) => {
      dispatch(createError(err));
    })
    .finally(() => dispatch(finishLoading()));
};

export const getHostingClasses = () => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());
  return requestHostingClasses(token)
    .then((res) => {
      const data = getNestedValue(res, 'data', 'data');
      if (res.status === 200 && data) {
        dispatch(showMarkers(data));
        dispatch(fitMapBoundsToMarkers());
        return data;
      }

      return null;
    })
    .catch((err) => {
      dispatch(createError(err));
    })
    .finally(() => dispatch(finishLoading()));
};

export const getPastHostClasses = () => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());
  return requestPastHostClasses(token)
    .then((res) => {
      const data = getNestedValue(res, 'data', 'data');
      if (res.status === 200 && data) {
        return data;
      }

      return null;
    })
    .catch((err) => {
      dispatch(createError(err));
    })
    .finally(() => dispatch(finishLoading()));
};

export const searchUsers = (type, itemId, query) => (dispatch, getState) => {
  const { token } = getState().session;
  let requestSearchUsers;
  if (type === EVENT) requestSearchUsers = searchHostersForEvent;
  if (type === LESSON) requestSearchUsers = searchHostersForLesson;

  if (!requestSearchUsers || !token || !itemId) return null;

  dispatch(startLoading());
  return requestSearchUsers(itemId, query, token)
    .then((res) => {
      const data = getNestedValue(res, 'data', 'data');

      if (res.status === 200 && data) {
        return data;
      }

      return null;
    })
    .catch((err) => {
      dispatch(createError(err));
    })
    .finally(() => dispatch(finishLoading()));
};

export const inviteUserToCohost = (
  { type, itemId, userId, permissions },
) => (dispatch, getState) => {
  const { token } = getState().session;
  let requestInviteUser;
  if (type === EVENT) requestInviteUser = inviteUserToCohostEvent;
  if (type === LESSON) requestInviteUser = inviteUserToCohostLesson;

  if (!requestInviteUser || !token || !itemId || !userId) return null;

  dispatch(startLoading());
  return requestInviteUser({ itemId, userId, token, permissions })
    .then((res) => {
      if (res.status === 201) {
        return res;
      }

      return null;
    })
    .catch((err) => {
      const errorText = getNestedValue(err, 'response', 'data', 'error');

      dispatch(createError(errorText));
      return err;
    })
    .finally(() => dispatch(finishLoading()));
};

export const removeUserFromCohost = ({ type, itemId, userId }) => (dispatch, getState) => {
  const { token } = getState().session;
  let requestRemoveUser;
  if (type === EVENT) requestRemoveUser = removeCohosterFromEvent;
  if (type === LESSON) requestRemoveUser = removeCohosterFromLesson;

  if (!requestRemoveUser || !token || !itemId || !userId) return null;

  dispatch(startLoading());
  return requestRemoveUser({ itemId, userId, token })
    .then((res) => {
      if (res.status === 204) {
        return res;
      }

      return null;
    })
    .catch((err) => {
      dispatch(createError(err));
      return err;
    })
    .finally(() => dispatch(finishLoading()));
};

export const acceptUserCohost = ({ type, itemId }) => (dispatch, getState) => {
  const { token } = getState().session;
  let requestAccept;
  if (type === EVENT) requestAccept = acceptCohostingEvent;
  if (type === LESSON) requestAccept = acceptCohostingLesson;

  if (!requestAccept || !token || !itemId) return null;

  dispatch(startLoading());
  return requestAccept({ itemId, token })
    .then((res) => {
      if (res.status === 204) {
        return res;
      }

      return null;
    })
    .catch((err) => {
      dispatch(createError(err));
      return err;
    })
    .finally(() => dispatch(finishLoading()));
};

export const cancelUserCohost = ({ type, itemId }) => (dispatch, getState) => {
  const { token } = getState().session;
  let requestCancel;
  if (type === EVENT) requestCancel = cancelCohostingEvent;
  if (type === LESSON) requestCancel = cancelCohostingLesson;

  if (!requestCancel || !token || !itemId) return null;

  dispatch(startLoading());
  return requestCancel({ itemId, token })
    .then((res) => {
      if (res.status === 204) {
        return res;
      }

      return null;
    })
    .catch((err) => {
      dispatch(createError(err));
      return err;
    })
    .finally(() => dispatch(finishLoading()));
};

export const setCohostingData = data => ({
  type: SET_COHOSTING_DATA,
  payload: data,
});
