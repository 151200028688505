import { connect } from 'react-redux';

import { getUser } from '../login/login_actions';

import MyPayout from './My_payout';

const mSTP = state => ({
  user: state.session.user,
  token: state.session.token,
});

const mDTP = dispatch => ({
  getUser: ({ id, token }) => dispatch(getUser({ id, token })),
});

export default connect(mSTP, mDTP)(MyPayout);
