import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

export const requestGetUser = ({ id, token }) => (
  axios.get(
    `/api/users/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
  )
);

export const createUser = user => (
  axios.post(
    '/api/users',
    { data: user },
  )
);

export const signInUser = credentials => (
  axios.post(
    '/api/login',
    { data: credentials },
  )
);

export const requestEditUser = (user, token) => {
  const { id } = user;
  const userForBackEnd = { ...user };
  delete userForBackEnd.id;

  return (axios
    .patch(
      `/api/users/${id}`,
      { data: userForBackEnd },
      { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
    )
  );
};

export const changeUserEmail = ({ id, email, password }, token) => (
  axios.patch(
    `/api/users/${id}`,
    { data: { email, current_password: password } },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
  )
);

export const changePassword = ({ id, newPassword, currentPassword }, token) => (
  axios.patch(
    `/api/users/${id}`,
    { data: {
      password: newPassword,
      current_password: currentPassword,
    } },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
  )
);

export const requestPasswordReset = email => (
  axios.post(
    '/api/password',
    { data: {
      email_nickname: email,
    } },
  )
);

export const passwordReset = (password, token) => (
  axios.patch(
    `/api/password/${token}`,
    { data: {
      password,
    } },
  )
);

export const requestSignOut = token => (
  axios.delete(
    '/api/logout',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
  )
);
