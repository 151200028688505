import { requestReport } from '../../../api';
import { startLoading, finishLoading } from '../../Loader/loading_actions';
import createError from '../../Fetch_error/fetch_error_action';
import { setAndOpenModal, setModalMessage, setModalTitle } from '../modal_actions';

import { SET_REPORT_DATA, RESET_REPORT_DATA } from '../../../redux/action_types';

export const setReportData = data => ({
  type: SET_REPORT_DATA,
  payload: data,
});

export const resetReportData = () => ({
  type: RESET_REPORT_DATA,
});

export const sendReport = report => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());
  return requestReport(report, token)
    .then((response) => {
      if (response.status === 204) {
        dispatch(setModalTitle('Thanks!'));
        dispatch(setModalMessage('Your report has been sent.'));
        dispatch(setAndOpenModal('SUCCES_MODAL_MESSAGE'));
      }
      return response.data;
    })
    .catch((err) => {
      dispatch(createError(err));
      return err;
    })
    .finally(() => dispatch(finishLoading()));
};
