import { GET_DANCE_STYLES_SUCCESS } from '../../../redux/action_types';
import createError from '../../Fetch_error/fetch_error_action';
import { requestDanceStyles } from '../../../api';
import fallBackStyles from './fallback_styles';

const getDanceStylesSuccess = danceStyles => ({
  type: GET_DANCE_STYLES_SUCCESS,
  payload: danceStyles,
});

// eslint-disable-next-line
export const getDanceStyles = () => (dispatch) => requestDanceStyles()
  .then((response) => {
    if (response.data && response.data.data && response.data.data.length > 0) {
      dispatch(getDanceStylesSuccess(response.data.data));

      return response.data && response.data.data;
    }

    dispatch(getDanceStylesSuccess(fallBackStyles));
    return fallBackStyles;
  })
  .catch(error => dispatch(createError(error)));
