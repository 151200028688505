import React, { Component } from 'react';
import PropTypes from 'prop-types';

import getNestedValue from '../../utils/getNestedValue';

import StripeButton from '../shared/Stripe_info/Stripe_button';
import StripeText from '../shared/Stripe_info/Stripe_text';

import styles from '../../styles/common.module.scss';

class MyPayout extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      connect: PropTypes.shape({
        charges_enabled: PropTypes.bool.isRequired,
        payouts_enabled: PropTypes.bool.isRequired,
      }),
    }).isRequired,
    getUser: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.getMyPayoutStatus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) this.getMyPayoutStatus();
  }

  getMyPayoutStatus = () => {
    const { getUser, token, user } = this.props;
    const id = getNestedValue(user, 'id');
    if (id && token) getUser({ id, token });
  }

  render() {
    const { user } = this.props;
    const connectStatus = !!user.connect;
    const chargesStatus = getNestedValue(user, 'connect', 'charges_enabled');
    const payoutsStatus = getNestedValue(user, 'connect', 'payouts_enabled');

    return (
      <div>
        <h5 className={styles.secondary_title}>My Payout</h5>
        <div className={styles.wrapper}>
          <StripeText
            connectStatus={connectStatus}
            chargesStatus={chargesStatus}
            payoutsStatus={payoutsStatus}
          />
        </div>
        <StripeButton isStripeConnected={connectStatus} />
      </div>
    );
  }
}

export default MyPayout;
