import { connect } from 'react-redux';
import ConfirmEmail from './confirm_email_page';
import { openModal, setCurrentModal } from '../../components/Modal/modal_actions';
import { startLoading, finishLoading } from '../../components/Loader/loading_actions';

const mDTP = dispatch => ({
  openModal: (modalName) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  startRequest: () => { dispatch(startLoading()); },
  finishRequest: () => { dispatch(finishLoading()); },
});

export default connect(null, mDTP)(ConfirmEmail);
