import { combineReducers } from 'redux';
import { SET_TRANSFER_ITEM, RESET_TRANSFER_INFO, SET_TRANSFER_USER } from '../../../redux/action_types';

export const transferItemReducer = (state = null, action) => {
  switch (action.type) {
    case SET_TRANSFER_ITEM:
      return action.payload;

    case RESET_TRANSFER_INFO:
      return null;

    default:
      return state;
  }
};

export const transferUserReducer = (state = null, action) => {
  switch (action.type) {
    case SET_TRANSFER_USER:
      return action.payload;

    case RESET_TRANSFER_INFO:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  transferItem: transferItemReducer,
  transferUser: transferUserReducer,
});
