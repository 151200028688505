import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { validateText } from 'utils/validation';
import CenterPin from '../icons/CenterPin';
import CancelIcon from '../icons/Cancel_icon';

import styles from '../../styles/create_form.module.scss';
import inputStyles from '../../styles/inputs.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import getNestedValue from '../../utils/getNestedValue';

const PLACEHOLDER = {
  address: 'Address',
};

export default class AddressInput extends Component {
  static propTypes = {
    mapCenterAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    resetMapCenterAddress: PropTypes.func.isRequired,
    pinMode: PropTypes.bool.isRequired,
    setOffPinMode: PropTypes.func.isRequired,
    showNewMarker: PropTypes.func.isRequired,
    createError: PropTypes.func.isRequired,
    location: PropTypes.shape({
      venueId: PropTypes.string,
    }),
    setOnPinMode: PropTypes.func.isRequired,
    address: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
    onAddressChange: PropTypes.func.isRequired,
    onCoordsChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    mapCenterAddress: null,
    location: null,
  }

  state = {
    isDropDownOpen: false,
  }

  componentDidUpdate() {
    const { mapCenterAddress, resetMapCenterAddress } = this.props;
    if (mapCenterAddress) {
      this.handleSetAddressFromMap();
      resetMapCenterAddress();
    }
  }

  handleAdressChange = (address) => {
    const venueId = getNestedValue(this.props, 'location', 'venueId');
    if (venueId) return;

    const { pinMode, setOffPinMode, onAddressChange, onCoordsChange } = this.props;

    const isValid = validateText(address);

    onAddressChange({
      isActive: true,
      value: address,
      isValid,
    });
    onCoordsChange(null);

    if (pinMode) setOffPinMode();
  }

  handleAddressSelect = (address) => {
    const { showNewMarker, createError, onCoordsChange } = this.props;

    this.handleAdressChange(address);
    this.handleHidePinButton();

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then((coordinates) => {
        onCoordsChange(coordinates);
        showNewMarker(coordinates);
      })
      .catch(error => createError(error));
  }

  handleShowPinButton = () => {
    this.setState({ isDropDownOpen: true }, () => {
    });
  }

  handleHidePinButton = () => {
    this.setState({ isDropDownOpen: false });
  }

  handleAddressCancel = () => {
    const { onAddressChange, showNewMarker } = this.props;

    onAddressChange({
      value: '',
      isValid: false,
    });

    showNewMarker(null);
  }

  startGettingAddresFromMap = () => {
    const { setOnPinMode, onAddressChange } = this.props;
    setOnPinMode();
    window.scrollTo(0, 0);
    onAddressChange({ value: '' });
  }

  handleSetAddressFromMap = () => {
    const { mapCenterAddress, showNewMarker, onCoordsChange } = this.props;
    const { coordinates, address } = mapCenterAddress;
    if (coordinates && address) {
      this.handleAdressChange(address);
      onCoordsChange(coordinates);
      showNewMarker(coordinates);
    }
  }

  render() {
    const { address: valueFromState, location } = this.props;
    const { isDropDownOpen } = this.state;

    let fieldStyle;
    if (!valueFromState.isActive) fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    else {
      fieldStyle = valueFromState.isValid
        ? inputStyles.input_box_create
        : `${inputStyles.input_box_create} ${inputStyles.is_error}`;
    }
    fieldStyle += ` ${inputStyles.z_top}`;

    const venueId = location && location.venueId;

    return (valueFromState.isAddressShown ? (
      <PlacesAutocomplete
        value={valueFromState.value}
        onChange={this.handleAdressChange}
        onSelect={this.handleAddressSelect}
        name="address"
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={fieldStyle}>
            <input
              {...getInputProps({
                className: inputStyles.input,
                name: 'address',
              })}
              onFocus={this.handleShowPinButton}
              onBlur={this.handleHidePinButton}
            />
            <label
              htmlFor="address"
              className={valueFromState.value ? inputStyles.label__with_value : inputStyles.label}
            >
              {valueFromState.error ? valueFromState.error : PLACEHOLDER.address}
            </label>
            {valueFromState.isActive && (
              <button
                className={`${buttonStyles.close_round} ${styles.cancel}`}
                type="button"
                onClick={this.handleAddressCancel}
                onKeyPress={this.handleAddressCancel}
              >
                <CancelIcon className={buttonStyles.close_round_svg} />
              </button>
            )}
            {!venueId && (
              <div className={inputStyles.google_dropdown}>
                {isDropDownOpen && (
                  <button
                    type="button"
                    className={inputStyles.pin_button}
                    onMouseDown={this.startGettingAddresFromMap}
                  >
                    <CenterPin className={styles.pin_icon} /> Drop a pin on the map
                  </button>
                )}
                {loading && <div className={inputStyles.autocomplete_loading}>Loading...</div>}
                {isDropDownOpen && suggestions.map(suggestion => (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: inputStyles.autocomplete_item,
                    })}
                  >
                    <span className={inputStyles.autocomplete_link}>{suggestion.description}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    ) : null);
  }
}
