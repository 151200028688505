import {
  EVENT, TEACHER, VENUE, LESSON, PASS,
  EVENTS, TEACHERS, VENUES, LESSONS_LABEL, PASSES,
} from '../config';

const makeItemLink = ({ type, id }) => {
  let category;
  switch (type) {
    case EVENT:
      category = EVENTS;
      break;
    case VENUE:
      category = VENUES;
      break;
    case TEACHER:
      category = TEACHERS;
      break;
    case LESSON:
      category = LESSONS_LABEL;
      break;
    case PASS:
      category = PASSES;
      break;
    default:
      category = null;
      break;
  }

  return `/${category}/${id}`;
};

export default makeItemLink;
