import React from 'react';
import PropTypes from 'prop-types';

const ViewThumbnails = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15" height="13">
    <path d="M14.343 8.516H1.15A1.138 1.138 0 0 1-.001 7.39V1.125c0-.622.515-1.126 1.151-1.126h13.193c.635 0 1.15.504 1.15 1.126V7.39c0 .622-.515 1.126-1.15 1.126zM1.146 10.75h.032c.633 0 1.148.503 1.148 1.125 0 .62-.515 1.124-1.148 1.124h-.032a1.136 1.136 0 0 1-1.147-1.124c0-.622.514-1.125 1.147-1.125zm4.35 0h8.85c.633 0 1.147.503 1.147 1.125 0 .62-.514 1.124-1.147 1.124h-8.85a1.136 1.136 0 0 1-1.147-1.124c0-.622.514-1.125 1.147-1.125z" />
  </svg>
);

ViewThumbnails.propTypes = {
  className: PropTypes.string,
};

ViewThumbnails.defaultProps = {
  className: '',
};

export default ViewThumbnails;
