import { connect } from 'react-redux';
import NotificationsList from './Notifications_list';
import {
  updateAllNotifications,
  deleteNotification,
  markNotificationRead,
  getNotifications,
} from './notifications_actions';

const mSTP = state => ({
  notifications: state && state.notifications,
  notificationsCount: state && state.notificationsCount,
});

const mDTP = {
  updateAllNotifications,
  deleteNotification,
  markNotificationRead,
  getNotifications,
};

export default connect(mSTP, mDTP)(NotificationsList);
