import { connect } from 'react-redux';

import { getPaymentMethods } from '../../Payment_methods/payment_methods_actions';

import SelectPaymentMethod from './Select_payment_method';

const mSTP = state => ({
  token: state.session.token,
  methods: state.paymentMethods,
});

const mDTP = dispatch => ({
  getPaymentMethods: () => dispatch(getPaymentMethods()),
});

export default connect(mSTP, mDTP)(SelectPaymentMethod);
