import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import buttonsStyles from '../../styles/buttons.module.scss';
import defaultAvatar from '../../images/default_avatar.jpg';
import MarkerIcon from '../icons/Marker';
import FriendSubMenu from './FriendSubMenu';
import CancelIcon from '../icons/Cancel_icon';
import ListItem from '../List/List_item';
import ProfileForm from './Public_profile_form_container';
import AvatarEditor from '../Avatar_editor/Public_avatar_editor_container';
import Pencil from '../icons/Pencil';

import styles from './public_profile.module.scss';
import getNestedValue from '../../utils/getNestedValue';
import { FRIEND, FRIEND_SAVED_LIST } from '../../config';

const INITIAL_STATE = {
  user: {
    first_name: '',
    last_name: '',
    nickname: '',
    avatar: null,
    address: '',
    dance_styles: [],
  },
  savedItems: [],
  serverTime: null,
  page: 1,
  total: 0,
  isListLoading: false,
  isEditing: false,
};

export default class PublicProfile extends Component {
  static propTypes = {
    getUserInfo: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    authenticated: PropTypes.bool,
    isModalOpen: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    authenticated: false,
  }

  constructor() {
    super();
    this.wrapperRef = createRef();
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.getUser();
    window.scrollTo(0, 0);

    if (this.wrapperRef && this.wrapperRef.current) {
      this.wrapperRef.current.addEventListener('scroll', this.handleScroll);
    }
    window.addEventListener('scroll', this.handleMobileScroll);
  }

  componentDidUpdate(prevProps) {
    const prevId = getNestedValue(prevProps, 'match', 'params', 'id');
    const nextId = getNestedValue(this.props, 'match', 'params', 'id');

    const prevOpen = prevProps.isModalOpen;
    const nextOpen = this.props.isModalOpen;

    const prevToken = prevProps.token;
    const nextToken = this.props.token;

    if (prevId !== nextId || prevOpen !== nextOpen || prevToken !== nextToken) {
      this.getUser();
    }
  }

  componentWillUnmount() {
    if (this.wrapperRef && this.wrapperRef.current) {
      this.wrapperRef.current.removeEventListener('scroll', this.handleScroll);
    }
    window.removeEventListener('scroll', this.handleMobileScroll);
  }

  getUser = () => {
    const { match, getUserInfo } = this.props;
    const { id } = match.params;
    if (id && getUserInfo) {
      getUserInfo(id).then((res) => {
        const data = res && res.data && res.data.data;
        if (data) {
          this.setState({ user: data }, this.getSavedItems);
        }
      });
    }
  }

  getSavedItems = (page) => {
    const { user, isListLoading } = this.state;
    const { getSavedItems } = this.props;
    if (!user || isListLoading) return;
    const { id, friend_status: friendStatus } = user;

    const showSaved = friendStatus === FRIEND;

    if (id && getSavedItems && showSaved) {
      this.setState({ isListLoading: true });
      getSavedItems(id, { page })
        .then((res) => {
          const data = getNestedValue(res, 'data');
          const total = getNestedValue(res, 'headers', 'total');
          const serverTime = getNestedValue(res, 'headers', 'date');

          if (data) {
            this.setState(prevState => ({
              savedItems: page ? [...prevState.savedItems, ...data] : data,
              total,
              page: page || INITIAL_STATE.page,
            }));
          }

          if (serverTime) {
            this.setState({ serverTime });
          }
        })
        .finally(() => { this.setState({ isListLoading: false }); });
    }
  }

  likeSuccess = (id, favorite) => {
    this.setState(prevState => ({
      ...prevState,
      savedItems: Array.isArray(prevState.savedItems)
        ? prevState.savedItems.map(el => (el.id === id
          ? { ...el, favorite }
          : el
        ))
        : prevState.savedItems,
    }));
  }

  toggleFavorite = ({ id, type, willbeFavorite }) => {
    const { addLike, removeLike } = this.props;
    if (willbeFavorite) {
      addLike({ id, type }).then((res) => {
        if (res && res.status === 201) this.likeSuccess(id, true);
      });
    } else {
      removeLike({ id, type }).then((res) => {
        if (res && res.status === 204) this.likeSuccess(id, false);
      });
    }
  }

  handleClose = () => {
    const { history } = this.props;
    const path = history && history.location && history.location.state
      && history.location.state.from
      ? history.location.state.from
      : '/';
    history.push(path);
  }

  handleScroll = (e) => {
    const { isEditing } = this.state;
    if (isEditing) return;

    const visibleHeight = e.srcElement.clientHeight;
    const scrolled = e.srcElement.scrollTop;
    const totalHeight = e.srcElement.scrollHeight;

    const timeToFetch = (totalHeight - visibleHeight - scrolled) <= 400;
    if (timeToFetch) {
      this.getNewPage();
    }
  };

  handleMobileScroll = () => {
    const { isEditing } = this.state;
    if (isEditing) return;

    const visibleHeight = window.innerHeight;
    const scrolled = window.scrollY;
    const totalHeight = this.wrapperRef.current.clientHeight;
    const timeToFetch = (totalHeight - visibleHeight - scrolled) <= 400;

    if (timeToFetch) {
      this.getNewPage();
    }
  };

  getNewPage = () => {
    const { savedItems, total, page, isListLoading } = this.state;
    const hasMoreItems = savedItems.length < total;

    if (!isListLoading && hasMoreItems) {
      this.getSavedItems(page + 1);
    }
  }

  handleEditStart = () => { this.setState({ isEditing: true }); }

  handleEditStop = () => { this.setState({ isEditing: false }, this.getUser); }

  render() {
    const { setHoverId, token, history } = this.props;
    const { user, savedItems, serverTime, isEditing } = this.state;
    const {
      avatar,
      first_name: firstName,
      last_name: lastName,
      profession,
      address,
      about,
      dance_styles: danceStyles,
      permissions,
    } = user;

    const avatarPath = avatar && avatar.full
      ? avatar.full : defaultAvatar;

    const editPermission = (permissions && permissions.update);

    return isEditing ? (
      <div className={`${styles.wrapper} ${styles.info_section}`}>
        <button
          type="button"
          className={styles.cancel_button}
          onClick={this.handleClose}
        >
          <CancelIcon className={buttonsStyles.cancel_icon} />
        </button>
        <div className={styles.avatar_row}>
          <AvatarEditor image={avatar} id={user.id} updateUser={this.getUser} />
        </div>
        <ProfileForm
          user={user}
          handleEditStop={this.handleEditStop}
          hideFields={{ email: true, changePassword: true }}
        />
      </div>
    ) : (
      <div className={styles.wrapper} ref={this.wrapperRef}>
        <div className={styles.info_section}>
          <button
            type="button"
            className={styles.cancel_button}
            onClick={this.handleClose}
          >
            <CancelIcon className={buttonsStyles.cancel_icon} />
          </button>
          <div className={styles.avatar_box}>
            <div className={styles.avatar} style={{ backgroundImage: `url(${avatarPath})` }}>
              <FriendSubMenu {...this.props} user={user} updateUser={this.getUser} />
            </div>
            {editPermission && (
              <button
                className={styles.edit_button}
                type="button"
                onClick={this.handleEditStart}
              >
                <Pencil className={styles.pencil_icon} />
              </button>
            )}
          </div>

          <h3 className={styles.title}>{firstName} {lastName}</h3>
          <div className={styles.sub_title}>{profession}</div>
          {address && (
            <div>
              <MarkerIcon className={styles.pin_icon} />
              <span className={styles.grey_text}>{address}</span>
            </div>
          )}

          {(danceStyles && danceStyles.length > 0) || about
            ? <div className={styles.divider} />
            : null
          }

          {danceStyles && danceStyles.length > 0 && (
            <div className={styles.dance_styles}>{danceStyles.join(', ')}</div>
          )}
          {about && (
            <div>{about}</div>
          )}
        </div>
        {savedItems && Array.isArray(savedItems) && savedItems.length > 0 ? (
          <div className={styles.saved_section}>
            <h5 className={styles.saved_title}>Saved:</h5>
            <ul>
              {savedItems.map(item => (
                <ListItem
                  item={item}
                  isListViewActive
                  history={history}
                  key={item.id}
                  token={token}
                  serverTime={serverTime}
                  setHoverId={setHoverId}
                  toggleFavorite={this.toggleFavorite}
                  list={FRIEND_SAVED_LIST}
                />
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}
