import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import loginReducer from '../components/login/login_reducer';
import modalReducer from '../components/Modal/modal_reducer';
import loadingReducer from '../components/Loader/loading_reducer';
import itemsListReducer from '../components/List/list_reducer';
import eventDetailsReducer from '../components/Event_details/event_details_reducer';
import myItemsReducer from '../components/My_items/my_items_reducer';
import fetchErrorReducer from '../components/Fetch_error/fetch_error_reducer';
import mapReducer from '../components/Map/map_reducer';
import commentsReducer from '../components/Comments_block/comments_reducer';
import { saveStateToLS, getStateFromLS } from '../utils/local_storage';
import userTicketsReducer from '../components/User_tickets/user_tickets_reducer';
import danceStylesReducer from '../components/shared/Dance_styles/dance_styles_reducer';
import venueDetailsReducer from '../components/Venue_details/venue_details_reducer';
import teacherDetailsReducer from '../components/Teacher_details/teacher_details_reducer';
import fullScreenPhotosReducer from '../components/Modal/Full_screen_photos_modal/full_screen_reducer';
import lessonDetailsReducer from '../components/Lesson_details/lesson_details_reducer';
import checkInReducer from '../components/Check-in/check-in_reducer';
import passDetailsReducer from '../components/Pass_details/pass_details_reducer';
import methodsReducer from '../components/Payment_methods/payment_methods_reducer';
import checkoutReducer from '../components/Checkout/checkout_reducer';
import subscriptionReducer from '../components/My_subscriptions/my_subscription_reducer';
import { notificationsReducer, counterReducer } from '../components/Notifications/notifications_reducer';
import cohostingReducer from '../components/Hosting/hosting_reducer';
import reportDataReducer from '../components/Modal/Report_modal/report_reducer';
import friendsReducer from '../components/Friends/friends_reducer';
import transferReducer from '../components/Modal/Transfer_modal/transfer_reducer';

const rootReducer = combineReducers({
  session: loginReducer,
  modal: modalReducer,
  isLoading: loadingReducer,
  itemsList: itemsListReducer,
  eventDetails: eventDetailsReducer,
  comments: commentsReducer,
  map: mapReducer,
  myItems: myItemsReducer,
  fetchError: fetchErrorReducer,
  currentTicket: userTicketsReducer,
  danceStyles: danceStylesReducer,
  venueDetails: venueDetailsReducer,
  teacherDetails: teacherDetailsReducer,
  fullScreenPhotos: fullScreenPhotosReducer,
  lessonDetails: lessonDetailsReducer,
  checkIn: checkInReducer,
  passDetails: passDetailsReducer,
  paymentMethods: methodsReducer,
  checkout: checkoutReducer,
  subscriptions: subscriptionReducer,
  notifications: notificationsReducer,
  notificationsCount: counterReducer,
  cohostingData: cohostingReducer,
  reportData: reportDataReducer,
  friends: friendsReducer,
  transferData: transferReducer,
});

const enhancer = composeWithDevTools(applyMiddleware(thunk));
const persistedState = getStateFromLS();


const store = createStore(
  rootReducer,
  persistedState,
  enhancer,
);

store.subscribe(throttle(() => {
  const { session } = store.getState();
  saveStateToLS({
    ...session,
    error: null,
  });
}, 1000));

export default store;
