const getUserName = (user) => {
  const firstName = user && user.first_name;
  const lastName = user && user.last_name;
  const nickName = user && user.nickname;
  let userName = '';
  if (firstName) userName += firstName;
  if (firstName && lastName) {
    userName += ` ${lastName}`;
  } else if (lastName) {
    userName = lastName;
  }
  if (!userName && nickName) userName = nickName;
  if (!userName) userName = 'Anonymous User';

  return userName;
};

export default getUserName;
