import React from 'react';
import PropTypes from 'prop-types';

const Chat = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60.015 60.015"
    xmlSpace="preserve"
  >
    <path d="M42.008,0h-24c-9.925,0-18,8.075-18,18v14c0,9.59,7.538,17.452,17,17.973v8.344c0,0.937,0.764,1.699,1.702,1.699
    c0.449,0,0.873-0.177,1.195-0.499l1.876-1.876C26.709,52.713,33.26,50,40.227,50h1.78c9.925,0,18-8.075,18-18V18
    C60.008,8.075,51.932,0,42.008,0z"
    />
  </svg>
);

Chat.propTypes = {
  className: PropTypes.string,
};

Chat.defaultProps = {
  className: '',
};

export default Chat;
