import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/common.module.scss';
import ResetPasswordForm from '../../components/login/Reset_password_form';
import getNestedValue from '../../utils/getNestedValue';

export default class ResetPasswordPage extends Component {
  state = {
    token: null,
  }

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
  }

  componentDidMount() {
    const { match } = this.props;
    const token = getNestedValue(match, 'params', 'token');

    if (token) {
      this.setState({
        token,
      });
    }
  }

  render() {
    const { token } = this.state;

    return (
      <main className={styles.reset_page}>
        <h1 className={styles.page_title_centered}>Change password</h1>
        {token ? (
          <ResetPasswordForm token={token} />
        ) : (
          <p>
            You have no token
          </p>
        )}
      </main>
    );
  }
}
