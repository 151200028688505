import { connect } from 'react-redux';
import RemoveCommentModal from './RemoveCommentModal';
import { resetCommentToDelete, deleteComment } from '../../Comments_block/comments_actions';
import { startLoading, finishLoading } from '../../Loader/loading_actions';
import createError from '../../Fetch_error/fetch_error_action';
import { signOut } from '../../login/login_actions';

const mSTP = state => ({
  commentToRemove: state.comments.commentToDelete,
});

const mDTP = {
  resetCommentToDelete,
  startLoading,
  finishLoading,
  createError,
  signOut,
  deleteComment,
};

export default connect(mSTP, mDTP)(RemoveCommentModal);
