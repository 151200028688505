import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';

import LikesIcon from '../../icons/Likes';

import defaultPhoto from '../../../images/default_event_photo.jpg';

import styles from '../../../styles/view_details.module.scss';

import slider from '../../../styles/image_slider.scss'; //eslint-disable-line

const PhotosBlock = (props) => {
  const { photos, handleOpenFullScreen, fanciersNumber, previewFromForm, forLessons } = props;
  return (
    <div className={styles.photos_container}>
      {photos && photos.length > 1 && (
        <Carousel
          wrapAround
          autoplay
          swiping
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
          defaultControlsConfig={{
            pagingDotsStyle: {
              fill: '#fff',
            },
          }}
        >
          {photos.map(photo => (
            <div
              className={styles.slide}
              style={{ backgroundImage: `url(${photo.full || photo.url})` }}
              key={photo.full || photo.url}
              onClick={handleOpenFullScreen}
              role="button"
              tabIndex={0}
              onKeyDown={handleOpenFullScreen}
            />
          ))}
        </Carousel>
      )}
      {photos && photos.length === 1 && (
      <div
        className={styles.slide_single}
        style={{ backgroundImage: `url(${photos[0].full || photos[0].url})` }}
        onClick={handleOpenFullScreen}
        role="button"
        tabIndex={0}
        onKeyPress={handleOpenFullScreen}
      />
      )}
      {photos && photos.length === 0 && (
      <div
        className={styles.slide_single}
        style={{ backgroundImage: `url(${defaultPhoto})` }}
      />
      )}
      {!previewFromForm && !forLessons
        && (
          <div className={styles.likes}>
            <LikesIcon className={styles.likes_icon} />
            <span className={styles.likes_quantity}>{fanciersNumber}</span>
          </div>
        )}
    </div>
  );
};

PhotosBlock.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      sort: PropTypes.number,
    }),
  ),
  handleOpenFullScreen: PropTypes.func.isRequired,
  fanciersNumber: PropTypes.number,
  previewFromForm: PropTypes.bool.isRequired,
  forLessons: PropTypes.bool,
};

PhotosBlock.defaultProps = {
  photos: [],
  forLessons: false,
  fanciersNumber: 0,
};

export default PhotosBlock;
