import { requestEventDetails } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import { moveMapCenterTo, addMarker } from '../Map/map_actions';
import {
  GET_EVENT_DETAILS_SUCCESS,
  GET_EVENT_DETAILS_FAILURE,
} from '../../redux/action_types';
import { getItemsList } from '../List/list_actions';

export const getEventDetailsSuccess = event => ({
  type: GET_EVENT_DETAILS_SUCCESS,
  payload: event,
});

export const getEventDetailsFailure = error => ({
  type: GET_EVENT_DETAILS_FAILURE,
  payload: { error },
});

export const getEventDetails = ({ id, moveMap, filter, startDate }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestEventDetails({ id, token, startDate })
    .then((response) => {
      const event = response.data.data;
      dispatch(getEventDetailsSuccess(event));
      const { coordinates } = event;
      dispatch(getItemsList({
        list: 'events',
        token,
        filter,
      }))
        .then(() => {
          if (coordinates && moveMap) {
            dispatch(moveMapCenterTo(coordinates));
            const { markers } = getState().map;
            const itemHasMarker = markers.some(item => item.id === id);
            if (!itemHasMarker) dispatch(addMarker(coordinates));
          }
        });
      return response;
    })
    .catch((error) => {
      dispatch(createError(error));
      dispatch(getEventDetailsFailure(error));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};
