import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setAndOpenModal } from '../Modal/modal_actions';
import { getUserInfo } from './public_profile_actions';
import { editUser } from '../login/login_actions';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import ProfileForm from '../Profile/Profile_form';
import { getDanceStyles } from '../shared/Dance_styles/dance_styles_actions';
import { addMarker } from '../Map/map_actions';

const mSTP = state => ({
  token: state.session.token,
  danceStyles: state.danceStyles.styles,
  pinMode: state.map.pinMode,
});

const mDTP = {
  editUser,
  getUser: getUserInfo,
  openModal: setAndOpenModal,
  startLoading,
  finishLoading,
  handleError: createError,
  getDanceStyles,
  addMarker,
};

export default withRouter(connect(mSTP, mDTP)(ProfileForm));
