import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const BreakLineText = ({ children }) => {
  if (!children || typeof children !== 'string') return null;

  return children.split('\n').map(
    (item, key) => <Fragment key={key.toString()}>{item}<br /></Fragment>,
  );
};

BreakLineText.propTypes = {
  children: PropTypes.string,
};

export default BreakLineText;
