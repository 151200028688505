import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getCorrectDate } from 'utils/formatDate';
import makeItemLink from '../../utils/makeItemLink';
import getNestedValue from '../../utils/getNestedValue';

import IncludesBlock from '../shared/Includes_block/Includes_block';

import QRWhite from '../icons/QR_white';

import styles from './user_ticket.module.scss';
import defaultBg from '../../images/default_event_photo.jpg';

import { TEACHER } from '../../config';

const UserTicket = ({ order, openModal, forPasses }) => {
  if (!order) {
    return (
      <div className={styles.ticket_wrapper}>
        Error. Did not receive order.
      </div>
    );
  }

  const event = order.items && order.items[0] ? order.items[0] : null;
  const teacher = getNestedValue(order, 'ticket', 'teacher');

  const photos = forPasses
    ? (teacher && teacher.photos) || []
    : (event && event.photos) || [];

  let bgPhoto = photos.find(photo => photo && photo.sort === 0) || photos[0];
  bgPhoto = bgPhoto && bgPhoto.full ? bgPhoto.full : defaultBg;

  const teacherName = getNestedValue(teacher, 'title') || 'Teacher name';

  const title = forPasses
    ? getNestedValue(order, 'name') || 'Pass name'
    : getNestedValue(event, 'title') || 'Event name';

  const displayId = getNestedValue(order, 'display_id');

  const location = (event && event.location) || 'No name location';
  const address = (event && event.address) || 'Without address';

  const { start_date: startDate } = event;

  const day = startDate
    ? getCorrectDate(startDate).format('D')
    : '';

  const month = startDate
    ? getCorrectDate(startDate).format('MMM')
    : '';

  const itemLink = (event && event.type && event.id)
    ? makeItemLink({ type: event.type, id: event.id })
    : '/';
  const teacherLink = (teacher && teacher.id)
    ? makeItemLink({ type: TEACHER, id: teacher.id })
    : '/teachers';

  const ticketStyle = order.usable ? styles.ticket_wrapper : styles.ticket_wrapper_consumed;
  const handleOpenModal = () => openModal(order);

  return (
    <div className={ticketStyle}>
      <div
        role="button"
        tabIndex={0}
        className={styles.img_box}
        style={{ backgroundImage: `url(${bgPhoto})` }}
        onClick={handleOpenModal}
        onKeyDown={handleOpenModal}
      >
        <QRWhite className={styles.qr_white} />
      </div>
      <div className={styles.info}>
        {forPasses
          ? (
            <>
              <Link className={styles.title_pass} to={teacherLink}>
                {teacherName}
              </Link>
              <p className={styles.title_pass}>
                {title}
              </p>
            </>
          )
          : (
            <Link className={styles.title} to={itemLink}>
              {title}
            </Link>
          )
        }
        <div className={styles.display_id}>
          #{displayId}
        </div>
        {forPasses
          ? (
            <>
              <div className={styles.sub_title}>Pass includes: </div>
              <IncludesBlock items={order.items} />
            </>
          )
          : (
            <div className={styles.info_row}>
              <div className={styles.date}>
                <div className={styles.date_number}>{day}</div>
                <div className={styles.date_day}>{month}</div>
              </div>
              <div className={styles.location_box}>
                <div className={styles.location}>{location}</div>
                <div className={styles.address}>{address}</div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

UserTicket.propTypes = {
  order: PropTypes.shape({
    ticket: PropTypes.shape({
      id: PropTypes.string,
    }),
    item: PropTypes.shape({
      photos: PropTypes.arrayOf(PropTypes.object),
    }),
    usable: PropTypes.bool,
  }).isRequired,
  openModal: PropTypes.func.isRequired,
  forPasses: PropTypes.bool.isRequired,
};

export default UserTicket;
