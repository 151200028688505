import React from 'react';
import PropTypes from 'prop-types';
import LocationIcon from '../icons/Location';
import styles from '../../styles/buttons.module.scss';

const LocationButton = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={styles.location_button}
  >
    <LocationIcon className={styles.location_icon} />
  </button>
);

LocationButton.propTypes = {
  onClick: PropTypes.func,
};

LocationButton.defaultProps = {
  onClick: () => {},
};

export default LocationButton;
