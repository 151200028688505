import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import base64url from 'base64url';

import { closeModal } from '../Modal/modal_actions';
import { registerUser, loginUserWithGoogle, loginUserWithFacebook } from './login_actions';
import createError from '../Fetch_error/fetch_error_action';
import { validateEmail, validatePassword, validateUserName } from '../../utils/validation';
import getNestedValue from '../../utils/getNestedValue';

import styles from './forms.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

import Eye from '../icons/Eye';
import EyeHidden from '../icons/Eye_hidden';
import GoogleLogo from '../icons/Google_logo';
import FacebookLogo from '../icons/Facebook_logo';
import AppleLogo from '../icons/Apple_logo';

import { loadAppleScript } from '../../utils/appleScriptLoad';
import { GOOGLE_CLIENT_ID, FACEBOOK_CLIENT_ID, APPLE_CLIENT_ID } from '../../config';

const initialState = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  showPassword: false,
  isValid: {
    firstName: true,
    lastName: true,
  },
};

export class SignInFormComponent extends Component {
  static propTypes = {
    createUser: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    closeModal: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    handleError: PropTypes.func.isRequired,
    googleLogin: PropTypes.func.isRequired,
    facebookLogin: PropTypes.func.isRequired,
  }

  static defaultProps = {
    error: null,
  }

  state = { ...initialState }

  componentDidMount() {
    // creating global variable for using in webview app
    const { googleLogin, facebookLogin, history } = this.props;
    window.loginUserWithGoogle = googleLogin;
    window.facebookLogin = facebookLogin;
    // end of webview variables

    const { origin } = window.location;
    const from = getNestedValue(history, 'location', 'pathname');
    const STATE = base64url(JSON.stringify({
      redirect_url: `${origin}/apple_id_verify`,
      from_url: from,
    }));

    loadAppleScript({
      CLIENT_ID: APPLE_CLIENT_ID,
      SCOPES: 'name email',
      // REDIRECT_URI: 'https://dancity.devopsready.tools/api/apple_id_verify',
      REDIRECT_URI: `${origin}/api/apple_id_verify`,
      STATE,
    });
  }

  validateInput = (name, value) => {
    switch (name) {
      case 'firstName':
      case 'lastName':
        return validateUserName(value);

      case 'email':
        return validateEmail(value);

      default:
        return true;
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      [name]: value,
      isValid: {
        ...prevState.isValid,
        [name]: this.validateInput(name, value),
      },
    }));
  }

  handleShowPassword = () => {
    this.setState(prevState => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  }

  resetState = () => {
    this.setState(initialState);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, firstName, lastName, isValid } = this.state;
    const { createUser } = this.props;
    const isReadyToSubmit = email !== ''
      && password !== ''
      && validateEmail(email)
      && validatePassword(password)
      && isValid.firstName
      && isValid.lastName
      && firstName !== ''
      && lastName !== '';

    if (isReadyToSubmit) {
      createUser({ email, password, first_name: firstName, last_name: lastName });
    }
  }

  goToTerms = () => {
    this.props.closeModal();
    this.props.history.push('/terms_of_use');
  }

  handleGoogleLogin = (res) => {
    const { googleLogin } = this.props;
    const googleToken = res && res.tokenId;
    if (googleToken) {
      googleLogin(googleToken);
    }
  }

  handleGoogleLoginFailure = () => {
    const { handleError } = this.props;
    handleError('Login with google failed.');
  }

  responseFacebook = (res) => {
    const { facebookLogin } = this.props;
    const facebookToken = res && res.accessToken;

    if (facebookToken) {
      facebookLogin(facebookToken);
    }
  }

  androidGoogleLogin = () => {
    const androidGLogin = window.android && window.android.googleLogin;
    if (androidGLogin) window.android.googleLogin();
  }

  androidFacebookLogin = () => {
    const androidFBLogin = window.android && window.android.facebookLogin;
    if (androidFBLogin) window.android.facebookLogin();
  }

  startAppleLogin = () => {
    if (window.AppleID) {
      window.AppleID.auth.signIn();
    }
  }

  render() {
    const { error } = this.props;
    const { email, password, showPassword, firstName, lastName, isValid } = this.state;
    let emailClassList = styles.input_box;
    let lastNameClassList = classNames(
      styles.input_box,
      { [styles.is_error]: lastName !== '' && !isValid.lastName },
    );
    let firstNameClassList = classNames(
      styles.input_box,
      { [styles.is_error]: firstName !== '' && !isValid.firstName },
    );
    const passwordClassList = classNames(
      styles.input_box,
      { [styles.is_error]: password !== '' && !validatePassword(password) },
    );
    let labelEmail = 'Email';
    let labelPassword = 'Password';
    let labelFirstName = 'First name';
    let labelLastName = 'Last name';

    const emailError = getNestedValue(error, 'sign_in_error', 'error', 'email');
    const firstNameError = getNestedValue(error, 'sign_in_error', 'error', 'first_name');
    const lastNameError = getNestedValue(error, 'sign_in_error', 'error', 'last_name');

    if (email !== '' && !validateEmail(email)) {
      emailClassList = classNames(styles.input_box, styles.is_error);
      labelEmail = 'Please enter valid email';
    } else if (email !== ''
      && emailError) {
      emailClassList = classNames(styles.input_box, styles.is_error);
      labelEmail = `Email: ${Array.isArray(emailError) && emailError.join(',')}`;
    }

    if (firstNameError) {
      labelFirstName = `First name: ${Array.isArray(firstNameError) && firstNameError.join(',')}`;
      firstNameClassList = classNames(styles.input_box, styles.is_error);
    }

    if (lastNameError) {
      labelLastName = `Last name: ${Array.isArray(lastNameError) && lastNameError.join(',')}`;
      lastNameClassList = classNames(styles.input_box, styles.is_error);
    }

    const fetchError = error && error.sign_in_error && error.sign_in_error.fetchError;

    if (password !== '' && !validatePassword(password)) {
      labelPassword = 'Password must be from 6 to 72 characters';
    }

    const webView = !!window.android;

    return (
      <Fragment>
        <h4 className={styles.title}>Create your account</h4>
        <form onSubmit={this.handleSubmit} noValidate autoComplete="on">
          <div className={firstNameClassList}>
            <input
              type="text"
              name="firstName"
              value={firstName}
              onChange={this.handleInputChange}
              autoComplete="given-name"
            />
            <label
              htmlFor="firstName"
              className={firstName !== '' ? styles.with_value : undefined}
            >
              {labelFirstName}
            </label>
          </div>

          <div className={lastNameClassList}>
            <input
              type="text"
              name="lastName"
              value={lastName}
              onChange={this.handleInputChange}
              autoComplete="family-name"
            />
            <label
              htmlFor="lastName"
              className={lastName !== '' ? styles.with_value : undefined}
            >
              {labelLastName}
            </label>
          </div>

          <div className={emailClassList}>
            <input
              type="email"
              name="email"
              value={email}
              onChange={this.handleInputChange}
              autoComplete="email"
            />
            <label
              htmlFor="email"
              className={classNames({ [styles.with_value]: email !== '' })}
            >
              {labelEmail}
            </label>
          </div>
          <div className={passwordClassList}>
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={password}
              onChange={this.handleInputChange}
              className={styles.autocomlete_off}
              autoComplete="new-password"
            />
            <label
              htmlFor="password"
              className={password !== '' ? styles.with_value : undefined}
            >
              {labelPassword}
            </label>
            <button className={styles.eye_box} onClick={this.handleShowPassword} type="button">
              {showPassword ? <EyeHidden /> : <Eye />}
            </button>
          </div>
          {fetchError ? (
            <p className={styles.error_message}>{fetchError}</p>
          ) : null
          }
          <button type="submit" className={`${buttonStyles.btn_blue} ${buttonStyles.btn_in_modal} ${buttonStyles.btn_uppercase}`}>
            Sign up
          </button>
        </form>
        <p className={styles.form_text}>
          By clicking Sign Up, you agree to our&nbsp;
          <button onClick={this.goToTerms} className={styles.terms_link} type="button">
            Terms of Use
          </button>
        </p>
        <div className={styles.divider}>
          or
        </div>
        <div className={styles.btn_margin_box}>
          {webView ? (
            <button
              type="button"
              className={`${buttonStyles.btn_google} ${buttonStyles.btn_uppercase}`}
              onClick={this.androidGoogleLogin}
            >
              <GoogleLogo />
              Sign up with Google
            </button>
          ) : (
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={this.handleGoogleLogin}
              onFailure={this.handleGoogleLoginFailure}
              buttonText="Login"
              render={renderProps => (
                <button
                  type="button"
                  className={`${buttonStyles.btn_google} ${buttonStyles.btn_uppercase}`}
                  onClick={renderProps.onClick}
                >
                  <GoogleLogo />
                  Sign up with Google
                </button>
              )}
            />
          )}
        </div>
        <div className={styles.btn_margin_box}>
          {webView ? (
            <button
              type="button"
              className={`${buttonStyles.btn_in_modal} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_fb}`}
              onClick={this.androidFacebookLogin}
            >
              <FacebookLogo />
              Login with facebook
            </button>
          ) : (
            <FacebookLogin
              appId={FACEBOOK_CLIENT_ID}
              callback={this.responseFacebook}
              disableMobileRedirect
              textButton="Sign up with facebook"
              cssClass={`${buttonStyles.btn_in_modal} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_fb}`}
              icon={<FacebookLogo />}
            />
          )}
        </div>
        {webView ? null : (
          <button
            className={`${buttonStyles.btn_uppercase} ${buttonStyles.btn_apple}`}
            onClick={this.startAppleLogin}
            type="button"
          >
            <AppleLogo className={buttonStyles.apple_logo} /> Sign up with Apple Id
          </button>
        )}
      </Fragment>
    );
  }
}

const mSTP = state => ({
  error: state.session.error,
});

const mDTP = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  createUser: credentials => dispatch(registerUser(credentials)),
  handleError: err => dispatch(createError(err)),
  googleLogin: googleToken => dispatch(loginUserWithGoogle(googleToken)),
  facebookLogin: googleToken => dispatch(loginUserWithFacebook(googleToken)),
});

export default withRouter(connect(mSTP, mDTP)(SignInFormComponent));
