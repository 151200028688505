// session types

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCES = 'SIGN_IN_SUCCES';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT = 'SIGN_OUT';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_AVATAR = 'EDIT_USER_AVATAR';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';
export const GET_USER = 'GET_USER';

// modal types

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CURRENT_MODAL = 'CURRENT_MODAL';
export const MODAL_TEXT = 'MODAL_TEXT';
export const MODAL_TITLE = 'MODAL_TITLE';

// loading

export const LOADING_START = 'LOADING_START';
export const LOADING_FINISH = 'LOADING_FINISH';

// get items list

export const GET_ITEMS_FAILURE = 'GET_ITEMS_FAILURE';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const TOGGLE_LIST_VIEW = 'TOGGLE_LIST_VIEW';

// get event details

export const GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS';
export const GET_EVENT_DETAILS_FAILURE = 'GET_EVENT_DETAILS_FAILURE';


// get venue details

export const GET_VENUE_DETAILS_SUCCESS = 'GET_VENUE_DETAILS_SUCCESS';
export const GET_VENUE_DETAILS_FAILURE = 'GET_VENUE_DETAILS_FAILURE';

// get teacher details

export const GET_TEACHER_DETAILS_SUCCESS = 'GET_TEACHER_DETAILS_SUCCESS';
export const GET_TEACHER_DETAILS_FAILURE = 'GET_TEACHER_DETAILS_FAILURE';

// comments

export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE';
export const SET_COMMENT_TO_DELETE = 'SET_COMMENT_TO_DELETE';

// get dance styles

export const GET_DANCE_STYLES_SUCCESS = 'GET_DANCE_STYLES_SUCCESS';

// get items list for account menu

export const SET_ITEM_TO_DELETE = 'SET_ITEM_TO_DELETE';
export const RESET_ITEM_TO_DELETE = 'RESET_ITEM_TO_DELETE';
export const GET_MY_EVENTS_SUCCESS = 'GET_MY_EVENTS_SUCCESS';
export const GET_MY_VENUES_SUCCESS = 'GET_MY_VENUES_SUCCESS';
export const GET_MY_TEACHERS_SUCCESS = 'GET_MY_TEACHERS_SUCCESS';

// fetch error

export const FETCH_ERROR = 'FETCH_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

// map

export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM';
export const RESET_MAP_ZOOM = 'RESET_MAP_ZOOM';
export const SET_MAP_MARKERS = 'SET_MAP_MARKERS';
export const SET_FIT_MAP_FLAG = 'SET_FIT_MAP_FLAG';
export const RESET_FIT_MAP_FLAG = 'RESET_FIT_MAP_FLAG';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const ADD_MARKER = 'ADD_MARKER';
export const CENTER_PIN_MODE_ON = 'CENTER_PIN_MODE_ON';
export const CENTER_PIN_MODE_OFF = 'CENTER_PIN_MODE_OFF';
export const GET_MAP_CENTER_ADDRESS = 'GET_MAP_CENTER_ADDRESS';
export const RESET_MAP_CENTER_ADDRESS = 'RESET_MAP_CENTER_ADDRESS';
export const SEARCH_ON_MOVE_MAP = 'SEARCH_ON_MOVE_MAP';
export const SET_HOVER_ID = 'SET_HOVER_ID';

// purchased tickets

export const CURRENT_TICKET_INFO = 'CURRENT_TICKET_INFO';
export const UPDATE_CURRENT_TICKET_INFO = 'UPDATE_CURRENT_TICKET_INFO';

// send feedback

export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';

// search

export const GET_SEARCH_SUCCESS = 'GET_SEARCH_SUCCESS';
export const RESET_LIST = 'RESET_LIST';

// save scroll position

export const SAVE_SCROLL_POSITION = 'SAVE_SCROLL_POSITION';

// set photos for fullscreen modal

export const SET_FS_PHOTOS = 'SET_FS_PHOTOS';

// get lesson details

export const GET_LESSON_DETAILS_SUCCESS = 'GET_LESSON_DETAILS_SUCCESS';
export const GET_LESSON_DETAILS_FAILURE = 'GET_LESSON_DETAILS_FAILURE';

// get pass details

export const GET_PASS_DETAILS_SUCCESS = 'GET_PASS_DETAILS_SUCCESS';
export const GET_PASS_DETAILS_FAILURE = 'GET_PASS_DETAILS_FAILURE';

// check-in

export const CHECK_IN_SUCCESS = 'CHECK_IN_SUCCESS';
export const CHECK_IN_FAILURE = 'CHECK_IN_FAILURE';

// subscription

export const SET_SUBSCRIPTION_TO_CHECKOUT = 'SET_SUBSCRIPTION_TO_CHECKOUT';
export const RESET_SUBSCRIPTION_TO_CHECKOUT = 'RESET_SUBSCRIPTION_TO_CHECKOUT';
export const SET_SUBSCRIPTION_TO_UPDATE = 'SET_SUBSCRIPTION_TO_UPDATE';
export const RESET_SUBSCRIPTION_TO_UPDATE = 'RESET_SUBSCRIPTION_TO_UPDATE';
export const GET_MY_SUBSCRIPTIONS_SUCCESS = 'GET_MY_SUBSCRIPTIONS_SUCCESS';

// payment methods

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';

// hosting

export const GET_HOSTINGS_LIST_SUCCESS = 'GET_HOSTINGS_SUCCESS';
export const SET_COHOSTING_DATA = 'SET_COHOSTING_DATA';

// notifications

export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATIONS_COUNT = 'UPDATE_NOTIFICATIONS_COUNT';

// reports

export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const RESET_REPORT_DATA = 'RESET_REPORT_DATA';

// friends

export const SET_FRIEND_TO_DELETE = 'SET_FRIEND_TO_DELETE';
export const RESET_FRIEND_TO_DELETE = 'RESET_FRIEND_TO_DELETE';

// transfer item

export const SET_TRANSFER_ITEM = 'SET_TRANSFER_ITEM';
export const SET_TRANSFER_USER = 'SET_TRANSFER_USER';
export const RESET_TRANSFER_INFO = 'RESET_TRANSFER_INFO';
