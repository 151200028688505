import React from 'react';
import PropTypes from 'prop-types';

const Pencil = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M18.308 0l-16.87 16.873-1.436 7.127 7.125-1.437 16.872-16.875-5.691-5.688zm-15.751 21.444l.723-3.585 12.239-12.241 2.861 2.862-12.239 12.241-3.584.723zm17.237-14.378l-2.861-2.862 1.377-1.377 2.861 2.861-1.377 1.378z" />
  </svg>
);

Pencil.propTypes = {
  className: PropTypes.string,
};

Pencil.defaultProps = {
  className: '',
};

export default Pencil;
