import React from 'react';
import PropTypes from 'prop-types';

const Burger = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="26" height="18" viewBox="0 0 26 18">
    <rect width="26" height="4" rx="2" ry="2" />
    <rect y="7" width="26" height="4" rx="2" ry="2" />
    <rect y="14" width="26" height="4" rx="2" ry="2" />
  </svg>
);

Burger.propTypes = {
  className: PropTypes.string,
};

Burger.defaultProps = {
  className: '',
};

export default Burger;
