import { connect } from 'react-redux';

import PaymentMethods from './Payment_methods';
import { getPaymentMethods, deletePaymentMethod, setDefaultMethod } from './payment_methods_actions';


const mSTP = state => ({
  token: state.session.token,
  methods: state.paymentMethods,
});

const mDTP = { getPaymentMethods, deletePaymentMethod, setDefaultMethod };

export default connect(mSTP, mDTP)(PaymentMethods);
