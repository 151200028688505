import { connect } from 'react-redux';
import { resetError } from './fetch_error_action';
import FetchError from './Fetch_error';


const mSTP = state => ({
  error: state.fetchError,
});

const mDTP = { resetError };

export default connect(mSTP, mDTP)(FetchError);
