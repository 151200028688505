import { connect } from 'react-redux';
import { getLessonDetails } from './lesson_details_actions';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import { setFSphotos } from '../Modal/Full_screen_photos_modal/full_screen_actions';
import { addMarker } from '../Map/map_actions';
import LessonDetails from './Lesson_details';

const mSTP = (state) => {
  const { user: userLogged, token } = state.session;
  const { lesson, error } = state.lessonDetails;

  return ({
    lesson,
    error,
    userLogged,
    token,
  });
};

const mDTP = dispatch => ({
  getLessonDetails: ({ id, moveMap }) => dispatch(getLessonDetails(
    { id, moveMap },
  )),
  openModal: (modalName) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  setFSphotos: (photos) => {
    dispatch(setFSphotos(photos));
  },
  resetMarker: () => dispatch(addMarker(null)),
});

export default connect(mSTP, mDTP)(LessonDetails);
