import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ItemsList from './Items_list';


import { TEACHER, EVENT, VENUE } from '../../config';


export default class MyItems extends Component {
  static propTypes = {
    getItems: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.string,
      }),
    }),
    events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
      }),
    ).isRequired,
    venues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
      }),
    ).isRequired,
    teachers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
      }),
    ).isRequired,
    isModalOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    history: null,
    location: null,
  }


  componentDidMount() {
    const { getItems, token } = this.props;
    if (token) getItems(token);
  }

  componentDidUpdate(prevProps) {
    const { isModalOpen, token, getItems } = this.props;
    if (prevProps.isModalOpen === true && isModalOpen === false && token) {
      getItems(token);
    }
  }

  render() {
    const { events, location, venues, teachers } = this.props;
    const list = location && location.state && location.state.list;

    return (
      <div>
        <ItemsList items={events} title="Events" isOpened={list === EVENT} />
        <ItemsList items={venues} title="Venues" isOpened={list === VENUE} />
        <ItemsList items={teachers} title="Teachers" isOpened={list === TEACHER} />
      </div>
    );
  }
}
