import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { closeModal, openModal, setCurrentModal } from '../Modal/modal_actions';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import styles from './forms.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import { passwordReset } from '../../api';
import Eye from '../icons/Eye';
import EyeHidden from '../icons/Eye_hidden';
import { validatePassword } from '../../utils/validation';

const INITIAL_STATE_FIELD = {
  value: '',
  isActive: false,
  error: null,
};

const INITIAL_STATE = {
  password: {
    ...INITIAL_STATE_FIELD,
    showPassword: false,
  },
  passwordWasChanged: false,
};

export class ResetPasswordFormComp extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    beginLoading: PropTypes.func.isRequired,
    endLoading: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    isAuth: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  }

  state = { ...INITIAL_STATE }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        error: null,
      },
    }));
  }

  handleShowPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      ...prevState,
      password: {
        ...prevState.password,
        showPassword: !prevState.password.showPassword,
      },
    }));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      ...prevState,
      successPasswordCange: false,
    }));

    const { password: { value: password } } = this.state;
    const {
      token,
      beginLoading,
      endLoading,
      handleError,
      isAuth,
      history,
    } = this.props;

    if (password !== '' && password.length >= 6) {
      beginLoading();
      passwordReset(password, token)
        .then(
          (res) => {
            if (res.status === 204) {
              this.setState({
                ...INITIAL_STATE,
                passwordWasChanged: true,
              });

              if (isAuth) {
                history.push('/profile');
              }
            }
          },
          (rej) => {
            const { status, data } = rej.response;
            if (status === 422 && data.errors) {
              Object.keys(data.errors).forEach((item) => {
                if (item === 'password') {
                  this.setState(prevState => ({
                    ...prevState,
                    password: {
                      ...prevState.newPassword,
                      error: data.errors[item].join(', '),
                    },
                  }));
                }
              });
            } else if (status === 422 && data.error) {
              handleError(data.error);
            } else if (status === 401 && data.error) {
              handleError(data.error);
            }
          },
        )
        .catch(() => {
          handleError();
        })
        .finally(() => { endLoading(); });
    }
  }

  render() {
    const {
      password: {
        value: password,
        showPassword,
      },
      passwordWasChanged,
    } = this.state;

    const { handleOpenModal } = this.props;

    let passwordClassList = styles.input_box;
    let labelPassword = 'New password';

    if (password !== '' && !validatePassword(password)) {
      passwordClassList = `${styles.input_box} ${styles.is_error}`;
      labelPassword = 'Password must be from 6 to 72 characters';
    }

    return (
      passwordWasChanged ? (
        <>
          <div className={styles.message}>
            Your password was changed succesfully!
          </div>
          <button
            type="button"
            onClick={() => handleOpenModal('SIGN_IN_MODAL')}
            className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
          >
            Login
          </button>
        </>
      ) : (
        <>
          <form onSubmit={this.handleSubmit}>
            <div className={passwordClassList}>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={this.handleInputChange}
                autoComplete="off"
                className={styles.autocomlete_off}
              />
              <label
                htmlFor="newPassword"
                className={password !== '' ? styles.with_value : undefined}
              >
                {labelPassword}
              </label>
              <button
                className={styles.eye_box}
                onClick={(e) => { this.handleShowPassword(e, 'newPassword'); }}
                type="button"
              >
                {showPassword ? <EyeHidden /> : <Eye />}
              </button>
            </div>
            <div className={styles.btns_row}>
              <button
                type="submit"
                className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
              >
                Save changes
              </button>
            </div>
          </form>
        </>
      )
    );
  }
}

const mSTP = state => ({
  isAuth: state.session.authenticated,
});

const mDTP = dispatch => ({
  beginLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(finishLoading()),
  handleOpenModal: (modalName) => {
    dispatch(closeModal());
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  handleError: err => dispatch(createError(err)),
});

export default withRouter(connect(mSTP, mDTP)(ResetPasswordFormComp));
