import React from 'react';
import PropTypes from 'prop-types';

const TwitterIcon = ({ className }) => (
  className ? (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 502.548 502.54"
      xmlSpace="preserve"
    >
      <path d="M490.932,91.474c1.133-3.4,1.7-7.083,1.7-10.767c0-18.417-14.733-33.717-33.15-34c-0.283,0-0.567,0-0.85,0
        c-0.567,0-1.417,0-1.983,0c-5.1,0.283-9.917,1.7-14.167,3.967c-1.417,0.567-2.55,1.417-3.683,1.983
        c-0.567,0.283-1.133,0.567-1.7,0.85c-8.783,3.4-17.85,6.8-27.2,10.2c-15.583-11.333-34-19.833-55.817-24.65
        c-2.55-0.567-5.1-1.417-7.65-1.417h-24.083c-2.833,0-5.95,1.133-8.783,1.7c-18.983,5.1-38.817,12.183-56.95,27.2
        c-24.65,20.683-39.667,47.033-44.483,77.35c-49.583-10.483-90.667-34.567-124.383-75.083c-6.517-7.65-15.867-11.333-26.067-11.333
        l0,0c-9.917,0-19.55,3.967-26.067,11.617c-18.7,22.383-25.783,51.85-19.833,83.867c1.7,8.783,4.25,17.567,7.65,26.35
        c-4.533,5.1-7.367,11.333-8.217,18.133c-3.967,32.3,7.65,62.617,32.583,87.833c-1.133,4.817-1.417,9.633-0.283,14.45
        c4.25,22.1,15.583,41.083,32.867,55.817c-10.483,2.267-21.817,3.683-33.717,3.683c-3.4,0-6.8,0-10.2-0.283c-0.85,0-1.7,0-2.55,0
        c-14.733,0-28.05,9.633-32.3,23.8c-4.817,15.017,1.417,31.45,15.017,39.383c1.133,0.567,2.267,1.417,3.4,1.983
        c2.833,1.7,6.233,3.683,9.633,5.667c43.633,23.233,90.1,35.133,137.7,35.133c17.567,0,35.417-1.7,53.267-4.817
        c58.083-10.2,109.933-36.833,150.167-76.217s68.283-91.517,80.75-150.167c2.833-13.6,3.967-26.917,4.817-39.667
        c0.283-4.817,0.567-9.35,1.133-13.883c14.733-13.033,27.483-26.917,38.533-42.783c3.967-5.667,6.517-12.467,6.517-19.833
        C502.548,107.058,498.015,97.708,490.932,91.474z M430.015,158.908c-2.833,2.55-5.1,7.367-5.667,11.333
        c-2.267,18.7-2.267,37.967-6.233,56.1c-22.95,106.533-99.45,181.617-203.717,200.317c-15.867,2.833-31.733,4.25-47.317,4.25
        c-42.217,0-82.733-10.483-121.55-31.167c-3.967-1.983-7.65-4.533-11.617-6.8c4.25,0.283,8.5,0.567,12.75,0.567
        c50.717,0,96.9-21.533,114.183-39.1c-44.483-6.8-73.95-29.75-79.9-60.633c11.617-0.567,23.233-1.417,35.133-1.983
        c-47.317-22.667-71.117-54.4-66.867-90.1c11.333,2.833,22.95,5.667,34.283,8.5c-37.4-39.95-47.033-89.817-21.817-119.567
        c47.883,56.95,109.367,87.267,184.45,92.367c-0.283-2.267-0.567-3.683-0.567-5.1c-3.683-34,6.233-63.183,33.15-85.567
        c12.75-10.767,28.05-15.017,43.917-20.683c7.933,0,16.15,0,24.083,0c20.4,5.667,38.817,13.6,54.4,28.05
        c0.85,0.567,2.267,1.417,3.683,1.417c0.85,0,1.983,0,2.55-0.283c14.167-5.1,28.333-10.2,42.5-15.583
        c2.55-1.133,5.1-2.55,9.067-4.533c-7.933,20.967-20.683,35.7-37.4,47.317c0.283,0.567,0.567,1.417,1.133,1.983
        c15.017-4.25,30.317-8.5,46.183-13.033C457.498,133.124,444.748,146.724,430.015,158.908z"
      />
    </svg>

  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 502.548 502.54"
      xmlSpace="preserve"
      width="16"
    >
      <path d="M490.932,91.474c1.133-3.4,1.7-7.083,1.7-10.767c0-18.417-14.733-33.717-33.15-34c-0.283,0-0.567,0-0.85,0
          c-0.567,0-1.417,0-1.983,0c-5.1,0.283-9.917,1.7-14.167,3.967c-1.417,0.567-2.55,1.417-3.683,1.983
          c-0.567,0.283-1.133,0.567-1.7,0.85c-8.783,3.4-17.85,6.8-27.2,10.2c-15.583-11.333-34-19.833-55.817-24.65
          c-2.55-0.567-5.1-1.417-7.65-1.417h-24.083c-2.833,0-5.95,1.133-8.783,1.7c-18.983,5.1-38.817,12.183-56.95,27.2
          c-24.65,20.683-39.667,47.033-44.483,77.35c-49.583-10.483-90.667-34.567-124.383-75.083c-6.517-7.65-15.867-11.333-26.067-11.333
          l0,0c-9.917,0-19.55,3.967-26.067,11.617c-18.7,22.383-25.783,51.85-19.833,83.867c1.7,8.783,4.25,17.567,7.65,26.35
          c-4.533,5.1-7.367,11.333-8.217,18.133c-3.967,32.3,7.65,62.617,32.583,87.833c-1.133,4.817-1.417,9.633-0.283,14.45
          c4.25,22.1,15.583,41.083,32.867,55.817c-10.483,2.267-21.817,3.683-33.717,3.683c-3.4,0-6.8,0-10.2-0.283c-0.85,0-1.7,0-2.55,0
          c-14.733,0-28.05,9.633-32.3,23.8c-4.817,15.017,1.417,31.45,15.017,39.383c1.133,0.567,2.267,1.417,3.4,1.983
          c2.833,1.7,6.233,3.683,9.633,5.667c43.633,23.233,90.1,35.133,137.7,35.133c17.567,0,35.417-1.7,53.267-4.817
          c58.083-10.2,109.933-36.833,150.167-76.217s68.283-91.517,80.75-150.167c2.833-13.6,3.967-26.917,4.817-39.667
          c0.283-4.817,0.567-9.35,1.133-13.883c14.733-13.033,27.483-26.917,38.533-42.783c3.967-5.667,6.517-12.467,6.517-19.833
          C502.548,107.058,498.015,97.708,490.932,91.474z M430.015,158.908c-2.833,2.55-5.1,7.367-5.667,11.333
          c-2.267,18.7-2.267,37.967-6.233,56.1c-22.95,106.533-99.45,181.617-203.717,200.317c-15.867,2.833-31.733,4.25-47.317,4.25
          c-42.217,0-82.733-10.483-121.55-31.167c-3.967-1.983-7.65-4.533-11.617-6.8c4.25,0.283,8.5,0.567,12.75,0.567
          c50.717,0,96.9-21.533,114.183-39.1c-44.483-6.8-73.95-29.75-79.9-60.633c11.617-0.567,23.233-1.417,35.133-1.983
          c-47.317-22.667-71.117-54.4-66.867-90.1c11.333,2.833,22.95,5.667,34.283,8.5c-37.4-39.95-47.033-89.817-21.817-119.567
          c47.883,56.95,109.367,87.267,184.45,92.367c-0.283-2.267-0.567-3.683-0.567-5.1c-3.683-34,6.233-63.183,33.15-85.567
          c12.75-10.767,28.05-15.017,43.917-20.683c7.933,0,16.15,0,24.083,0c20.4,5.667,38.817,13.6,54.4,28.05
          c0.85,0.567,2.267,1.417,3.683,1.417c0.85,0,1.983,0,2.55-0.283c14.167-5.1,28.333-10.2,42.5-15.583
          c2.55-1.133,5.1-2.55,9.067-4.533c-7.933,20.967-20.683,35.7-37.4,47.317c0.283,0.567,0.567,1.417,1.133,1.983
          c15.017-4.25,30.317-8.5,46.183-13.033C457.498,133.124,444.748,146.724,430.015,158.908z"
      />
    </svg>
  )
);

TwitterIcon.propTypes = {
  className: PropTypes.string,
};

TwitterIcon.defaultProps = {
  className: '',
};

export default TwitterIcon;
