import React from 'react';
import PropTypes from 'prop-types';

const Camera = ({ customClass }) => (
  <svg
    className={customClass}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 490.667 490.667"
  >
    <g>
      <g>
        <g>
          <path
            d="M448,128h-67.627l-39.04-42.667H192v64h-64v64H64v213.333c0,23.467,19.2,42.667,42.667,42.667H448
            c23.467,0,42.667-19.2,42.667-42.667v-256C490.667,147.2,471.467,128,448,128z M277.333,405.333
            c-58.88,0-106.667-47.787-106.667-106.667S218.453,192,277.333,192S384,239.787,384,298.667S336.213,405.333,277.333,405.333z"
          />
          <polygon
            points="64,192 106.667,192 106.667,128 170.667,128 170.667,85.333 106.667,85.333 106.667,21.333 64,21.333 64,85.333
            0,85.333 0,128 64,128"
          />
          <path
            d="M277.333,230.4c-37.76,0-68.267,30.507-68.267,68.267h0c0,37.76,30.507,68.267,68.267,68.267
            c37.76,0,68.267-30.507,68.267-68.267S315.093,230.4,277.333,230.4z"
          />
        </g>
      </g>
    </g>
  </svg>
);

Camera.propTypes = {
  customClass: PropTypes.string,
};

Camera.defaultProps = {
  customClass: '',
};

export default Camera;
