import React from 'react';
import PropTypes from 'prop-types';

const TicketIcon = ({ className }) => (
  className ? (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 23 27">
      <path d="M21.661 26.156a2.502 2.502 0 0 1-1.807.776h-3.577a1.25 1.25 0 0 1-1.233-1.085c-.24-1.746-1.771-3.077-3.562-3.099-1.748-.023-3.268 1.316-3.534 3.112a1.25 1.25 0 0 1-1.231 1.071H3.162a2.362 2.362 0 0 1-1.733-.676 2.553 2.553 0 0 1-.765-1.829V2.669C.664 1.264 1.751.164 3.139.164h16.693c.646-.02 1.26.218 1.731.676.487.471.767 1.137.765 1.829l.002 21.736a2.416 2.416 0 0 1-.669 1.751zM1.909 2.669h1.234c.004-.008.01-.016.012-.022l-1.246.022zm17.932 7.439l-3.115.019-.016-2.516 3.131-.022V2.68H3.153v4.912l3.192.02-.017 2.517-3.175-.019v14.305h2.591c.83-2.478 3.147-4.216 5.768-4.184 2.632.032 4.932 1.766 5.743 4.184h2.586V10.108zM9.239 7.582h4.574V10.1H9.239V7.582z" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="27">
      <path d="M21.661 26.156a2.502 2.502 0 0 1-1.807.776h-3.577a1.25 1.25 0 0 1-1.233-1.085c-.24-1.746-1.771-3.077-3.562-3.099-1.748-.023-3.268 1.316-3.534 3.112a1.25 1.25 0 0 1-1.231 1.071H3.162a2.362 2.362 0 0 1-1.733-.676 2.553 2.553 0 0 1-.765-1.829V2.669C.664 1.264 1.751.164 3.139.164h16.693c.646-.02 1.26.218 1.731.676.487.471.767 1.137.765 1.829l.002 21.736a2.416 2.416 0 0 1-.669 1.751zM1.909 2.669h1.234c.004-.008.01-.016.012-.022l-1.246.022zm17.932 7.439l-3.115.019-.016-2.516 3.131-.022V2.68H3.153v4.912l3.192.02-.017 2.517-3.175-.019v14.305h2.591c.83-2.478 3.147-4.216 5.768-4.184 2.632.032 4.932 1.766 5.743 4.184h2.586V10.108zM9.239 7.582h4.574V10.1H9.239V7.582z" />
    </svg>
  )
);

TicketIcon.propTypes = {
  className: PropTypes.string,
};

TicketIcon.defaultProps = {
  className: '',
};

export default TicketIcon;
