import React from 'react';
import PropTypes from 'prop-types';
import styles from './analytics.module.scss';
import { getDollarsFromCents } from '../../utils/parse';

const AnalyticsRow = ({ ticket }) => {
  const {
    name,
    sold,
    checkins,
    revenue,
    available,
  } = ticket;
  return (
    <tr className={styles.analytics_row}>
      <td>
        <div className={styles.ticket_name}>{name}</div>
        <div className={styles.text_grey}>{available === null ? 'unlimited tickets' : `${available} tickets left`}</div>
      </td>
      <td className={styles.green_center}>
        {checkins}
      </td>
      <td className={styles.blue_center}>
        {sold}
      </td>
      <td className={styles.td_right}>
        {getDollarsFromCents(revenue)}
      </td>
    </tr>
  );
};

AnalyticsRow.propTypes = {
  ticket: PropTypes.shape({
    name: PropTypes.string,
    sold: PropTypes.number,
    checkins: PropTypes.number,
  }).isRequired,
};

export default AnalyticsRow;
