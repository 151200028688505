import moment from 'moment';

const format = date => (date >= 10 ? date : `0${date}`);

export const formatDate = (date) => {
  const day = format(date.getDate());
  const month = format(date.getMonth() + 1);
  const year = date.getFullYear().toString().substring(2);
  const formatedDate = `${month}/${day}/${year}`;
  return formatedDate;
};

const getMonthName = (date) => {
  switch (date.getMonth()) {
    case 0:
      return 'January';
    case 1:
      return 'February';
    case 2:
      return 'March';
    case 3:
      return 'April';
    case 4:
      return 'May';
    case 5:
      return 'June';
    case 6:
      return 'July';
    case 7:
      return 'August';
    case 8:
      return 'September';
    case 9:
      return 'October';
    case 10:
      return 'November';
    case 11:
      return 'December';
    default: return null;
  }
};

export const getDayName = (date) => {
  switch (date.getDay()) {
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    case 0:
      return 'Sunday';
    default: return null;
  }
};

export const getShortDayName = (date) => {
  const dayIndex = date.getDay();

  return ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'][dayIndex];
};

export const formatDateForList = (dateAsString) => {
  const date = new Date(dateAsString);
  const month = getMonthName(date);
  const formatedDate = `${date.getDate()} ${month}`;
  return formatedDate;
};

export const formatDateForListReverse = (dateAsString) => {
  const date = moment(dateAsString);
  const formatedDate = date.isSame(moment(), 'year')
    ? date.format('MMMM D')
    : date.format('MMMM D, Y');

  return formatedDate;
};

export const getCorrectDate = (dateFromApi) => {
  const userOffset = moment.parseZone().utcOffset();
  const dateOffset = moment.parseZone(dateFromApi).utcOffset();
  const correctionMinutes = userOffset - dateOffset;
  const date = moment.utc(dateFromApi);
  date.utcOffset(userOffset);
  date.subtract(correctionMinutes, 'minute');
  return date;
};

export const formatDateForEventDetails = (startDateAsString, endDateAsString) => {
  const startDate = getCorrectDate(startDateAsString);
  const endDate = getCorrectDate(endDateAsString);

  const startMonth = startDate.format('MMM');
  const endMonth = endDate.format('MMM');
  const endYear = endDate.format('YYYY');
  const startYear = startDate.format('YYYY') !== endYear ? `, ${startDate.format('YYYY')}` : '';
  const formatedDate = startDate.format('MM DD YYYY') === endDate.format('MM DD YYYY')
    ? `${startMonth} ${startDate.format('D')}, ${endYear}`
    : `${startMonth} ${startDate.format('D')}${startYear} - ${endMonth} ${endDate.format('D')}, ${endYear}`;
  return formatedDate;
};

export const formatTimeForEventDetails = (startDateAsString, endDateAsString) => {
  const startDate = getCorrectDate(startDateAsString);
  const endDate = getCorrectDate(endDateAsString);

  const startTime = startDate.format('h:mmA');
  const endTime = endDate.format('h:mmA');
  const formatedTime = (startDate.format('MM DD YYYY') === endDate.format('MM DD YYYY'))
    ? `${startDate.format('dddd')}, ${startTime} - ${endTime}`
    : `${startDate.format('dddd')}, ${startTime} - ${endDate.format('dddd')}, ${endTime}`;
  return formatedTime;
};

export const stringToDashDate = str => (
  str.replace(/([\d]{2})([\d]{2})([\d]{4})/, '$3-$1-$2')
);

export const dashDateOrder = str => (
  str.replace(/-/g, '').replace(/([\d]{4})([\d]{2})([\d]{2})/, '$2/$3/$1')
);
// 2000-04-12 => 12-04-2000

export const dashDateToString = str => (
  str.replace(/-/g, '').replace(/([\d]{4})([\d]{2})([\d]{2})/, '$2$3$1')
);
// 2000-04-12 => 12042000

export const formatDateToDashString = (date) => {
  let month = date.getMonth() + 1;
  if (month < 10) month = `0${month}`;
  const day = date.getDate() > 10 ? date.getDate() : `0${date.getDate()}`;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const formatDashStringToDateString = (dashString) => {
  const date = new Date();
  date.setDate(dashString.substring(dashString.length - 2, '01'.length));
  date.setMonth(dashString.substring('2019'.length, '01'.length) - 1);
  date.setYear(dashString.substring(0, '2019'.length));

  return date;
};

export const getDateStringWithDots = (dateString) => {
  const date = new Date(dateString);
  if (Number.isNaN(date.getDate())) {
    return 'invalid date';
  }

  const day = format(date.getDate());
  const month = format(date.getMonth() + 1);
  const formatedDate = `${month}.${day}.${date.getFullYear()}`;
  return formatedDate;
};

export const formatDateForItemCard = (startDateAsString, endDateAsString) => {
  const startDate = getCorrectDate(startDateAsString);
  const endDate = getCorrectDate(endDateAsString);

  const formatedDate = (startDate.format('MM DD YYYY') === endDate.format('MM DD YYYY'))
    ? startDate.format('ddd, MMM D')
    : `${startDate.format('ddd, MMM D')} - ${endDate.format('ddd, MMM D')}`;

  return formatedDate;
};

export const isValidDate = date => date instanceof Date && !Number.isNaN(date.getMonth());

export const checkDateHappen = ({ serverTime, startDate, endDate }) => {
  let currentDateOption;
  const now = serverTime ? moment(serverTime) : moment();
  const tomorrow = now
    .clone()
    .add(1, 'days')
    .hours(0)
    .minutes(0)
    .seconds(0);

  const dateAfterTomorrow = tomorrow.clone().add(1, 'days');

  const start = moment(startDate);
  const end = moment(endDate);
  if (end > now || !endDate) {
    if (start < now) {
      currentDateOption = 'Happening now';
    } else if (start < tomorrow) {
      currentDateOption = 'Today';
    } else if (start < dateAfterTomorrow) {
      currentDateOption = 'Tomorrow';
    }
  }
  return currentDateOption;
};

const FOUR_DAYS_IN_MS = 4 * 24 * 60 * 60 * 1000;

export const formatDateFromNow = (dateString, serverTime) => {
  const now = moment(serverTime).isValid() ? moment(serverTime) : moment();
  const dateToShow = now - moment(dateString) < FOUR_DAYS_IN_MS
    ? moment(dateString).from(now)
    : moment(dateString).format('MM-DD-YYYY');

  return dateToShow;
};

export default formatDate;
