import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import buttonStyles from '../../styles/buttons.module.scss';

import styles from './modal.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const buttonStyle = `${buttonStyles.btn_ok} ${buttonStyles.btn_uppercase}`;

const SuccessModal = ({ isModalOpen, closeModal, text, title }) => (
  <Modal
    isOpen={isModalOpen}
    onRequestClose={closeModal}
    className={styles.content}
    overlayClassName={styles.overlay}
  >
    <div className={styles.content_box}>
      <h4 className={styles.title}>
        {title}
      </h4>
      <div className={styles.message_box}>
        {text}
      </div>
      <div className={styles.close_button_box}>
        <button
          type="button"
          onClick={closeModal}
          className={buttonStyle}
        >
          Ok
        </button>
      </div>
    </div>
  </Modal>
);

SuccessModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
};

SuccessModal.defaultProps = {
  title: 'Well done!',
};

export default SuccessModal;
