import React from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '../icons/Cancel_icon';

import styles from './filter.module.scss';

const SelectedFilter = ({ value, label, onClick }) => {
  const handleClick = () => {
    onClick(value);
  };

  return (
    <div className={styles.selected_item}>
      {label || value}
      <button type="button" onClick={handleClick} className={styles.cancel_button}>
        <CancelIcon className={styles.cancel_icon} />
      </button>
    </div>
  );
};

SelectedFilter.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

SelectedFilter.defaultProps = {
  label: '',
};

export default SelectedFilter;
