import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import Select from 'react-select';
import moment from 'moment';

import { getCorrectDate } from '../../utils/formatDate';
import { requestPassDetails } from '../../api';
import {
  validateTitle, validateText,
  validateDescription, checkUserCreatAbilyti,
} from '../../utils/validation';

import PassDetails from '../Pass_details/pass_details_container';
import LinkSelector from '../shared/Link_selector/Link_selector';
import TextArea from '../shared/Textarea/Textarea';
import DatePicker from '../shared/Date_picker/Date_picker';
import SimpleItemPreview from '../shared/Simple_item_preview/Simple_item_preview';
import Checkmark from '../icons/Checkmark';

import inputStyles from '../../styles/inputs.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import customSelectStyles, { selectErrorStyles, selectActiveStyles } from '../../styles/select_styles';
import ticketStyles from '../../styles/create_ticket_form.module.scss';
import styles from '../../styles/create_form.module.scss';

const PLACEHOLDER = {
  name: 'Pass name',
  description: 'Pass description',
  quantity: 'Maximum',
  price: 'Price, USD',
  refunds: 'Refunds',
  total_visits: '# of Entries / Pass',
  validity: 'Days',
  feeInclude: 'Fees',
};

const REFUNDS_OPTIONS = [
  {
    value: false,
    label: 'No refund',
  },
  {
    value: true,
    label: 'With refund',
  },
];

const FEE_OPTIONS = [
  {
    value: true,
    label: 'Retain',
  },
  {
    value: false,
    label: 'Pass on to buyer',
  },
];

const NUMBER_INPUTS = ['quantity', 'price', 'total_visits', 'validity'];

const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

const INITIAL_STATE = {
  isLoading: false,
  previewView: false,
  name: { ...INITIAL_STATE_FIELD },
  description: { ...INITIAL_STATE_FIELD },
  quantity: { ...INITIAL_STATE_FIELD },
  price: { ...INITIAL_STATE_FIELD },
  refunds: {
    ...INITIAL_STATE_FIELD,
    isValid: true,
  },
  feeInclude: {
    ...INITIAL_STATE_FIELD,
    isValid: true,
  },
  date: {
    ...INITIAL_STATE_FIELD,
    isValid: true,
    isDateSelecting: false,
  },
  showDaysInput: false,
  showPassesLimit: false,
  total_visits: {
    ...INITIAL_STATE_FIELD,
    isTotalVistsShow: false,
  },
  validity: { ...INITIAL_STATE_FIELD },
  items: [],
  itemsToRemove: [],
  itemsError: false,
  teacher: null,
};

class PassForm extends Component {
  static propTypes = {
    createPass: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    editPass: PropTypes.func.isRequired,
    onPassSubmitSuccess: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    getTeacherClasses: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
      nickname: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      profession: PropTypes.string,
    }).isRequired,
    getTeacherDetails: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  state = { ...INITIAL_STATE };

  async componentDidMount() {
    const {
      history, match, handleError,
      token, getTeacherDetails, user,
    } = this.props;

    const idToEdit = match && match.params && match.params.pass_id;
    let passToEdit;

    const userId = user && user.id;

    if (idToEdit) {
      const passResponse = await requestPassDetails(idToEdit, token)
        .catch((err) => {
          const errMessage = err && err.response && err.response.data.error;
          handleError(errMessage);
          history.push('/');
        });
      passToEdit = passResponse && passResponse.data && passResponse.data.data;
    } else {
      const teacherId = match && match.params && match.params.id;
      if (teacherId) {
        getTeacherDetails(teacherId)
          .then(data => this.setState({ teacher: data && data.data && data.data.data }));
      }
    }
    if (!passToEdit && history.location.pathname.indexOf('/account/edit_pass/') !== -1) history.push('/');
    if (passToEdit && history.location.pathname !== '/create_pass') {
      const passUserId = passToEdit.user && passToEdit.user.id;
      if (passUserId === userId) {
        const startDate = passToEdit.start_date && getCorrectDate(passToEdit.start_date).toDate();
        const endDate = passToEdit.end_date && getCorrectDate(passToEdit.end_date).toDate();
        this.setState({
          ...INITIAL_STATE,
          name: {
            isActive: true,
            isValid: true,
            value: passToEdit.name,
          },
          description: {
            isActive: true,
            isValid: true,
            value: passToEdit.description,
          },
          date: {
            isActive: true,
            isValid: true,
            value: [startDate, endDate],
            isDateSelecting: !!passToEdit.start_date,
          },
          total_visits: {
            isActive: true,
            isValid: true,
            value: passToEdit.total_visits === 0 ? '' : passToEdit.total_visits,
            isTotalVistsShow: passToEdit.total_visits !== '' && passToEdit.total_visits !== 0,
          },
          validity: {
            isActive: true,
            isValid: true,
            value: passToEdit.validity,
          },
          quantity: {
            isActive: true,
            isValid: true,
            value: passToEdit.quantity,
          },
          showDaysInput: !!passToEdit.validity,
          showPassesLimit: !!passToEdit.quantity,
          price: {
            isActive: true,
            isValid: true,
            value: passToEdit.price / 100,
          },
          refunds: {
            isActive: true,
            isValid: true,
            value: REFUNDS_OPTIONS.find(option => option.value === passToEdit.refunds),
          },
          feeInclude: {
            isActive: true,
            isValid: true,
            value: FEE_OPTIONS.find(option => option.value === passToEdit.fee_include),
          },
          destroyable: passToEdit.destroyable,
          items: passToEdit.items,
          itemsError: !passToEdit.items,
          teacherId: passToEdit.teacher_id,
          teacher: passToEdit.teacher,
        });
      } else { history.push('/'); }
    }
  }

  componentSetState = prevState => this.setState(prevState);

  setErrors = (error) => {
    const { handleError } = this.props;
    if (error === null) return;
    Object.keys(error).forEach((item) => {
      handleError(`${item}: ${error[item].join(', ')}`);
      if (item === 'start_date' || item === 'end_date') {
        this.setState(prevState => ({
          ...prevState,
          date: {
            ...prevState.date,
            isValid: false,
            error: `${item} ${error[item].join(', ')}`,
          },
        }));
      } else if (this.state[item]) {
        this.setState(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isValid: false,
            error: error[item].join(', '),
          },
        }));
      }
    });
  };

  handleInputChange = (e) => {
    let { value } = e.target;
    const { name } = e.target;
    const isInputNumber = NUMBER_INPUTS.indexOf(name) !== -1;
    if (isInputNumber) {
      value = e.target.rawValue;
    }

    let isValid;
    switch (name) {
      case 'name': {
        isValid = validateTitle(value);
        break;
      }
      case 'description': {
        isValid = validateDescription(value);
        break;
      }
      case 'price': {
        isValid = this.validatePrice({ price: value });
        break;
      }
      case 'quantity': {
        isValid = value >= 0;
        break;
      }
      case 'total_visits':
      case 'validity': {
        isValid = true;
        break;
      }
      default: isValid = validateText(value);
    }

    this.setState((prevState => ({
      [name]: {
        ...prevState[name],
        isActive: true,
        isValid,
        value,
      },
    })));
  };

  handleInputDateChange = date => this.setState({
    date: {
      isActive: true,
      isValid: true,
      value: date,
      isDateSelecting: true,
    },
  });

  handleSelectRefund = (selectedRefund) => {
    this.setState({
      refunds: {
        value: selectedRefund,
        isValid: true,
        isActive: true,
      },
    });
  }

  handleSelectFeeIncludes = (selectedFee) => {
    this.setState({
      feeInclude: {
        value: selectedFee,
        isValid: true,
      },
    }, () => {
      this.validatePrice({ setInputState: true });
    });
  }

  handleShowInput = (e) => {
    const { name } = e.target;
    const stateField = this.state[name];
    if (!stateField.value) {
      if (name === 'quantity') {
        this.setState(prevState => ({
          showPassesLimit: !prevState.showPassesLimit,
          quantity: {
            ...prevState.quantity,
            isValid: true,
          },
        }));
      } else if (name === 'validity') {
        this.setState(prevState => ({
          showDaysInput: !prevState.showDaysInput,
          validity: {
            ...prevState.validity,
            isValid: true,
          },
        }));
      }
    }
  }

  handleShowDateSelecting = () => {
    this.setState((prevState) => {
      if (prevState.date.isDateSelecting) {
        return {
          date: {
            ...INITIAL_STATE_FIELD,
            isValid: true,
            value: '',
            isDateSelecting: false,
          },
        };
      }

      return {
        date: {
          ...prevState.date,
          isDateSelecting: true,
        },
      };
    });
  }

  toggleShowTotalVisits = () => {
    this.setState((prevState) => {
      if (prevState.total_visits.isTotalVistsShow) {
        return {
          total_visits: {
            ...INITIAL_STATE_FIELD,
            isValid: true,
            value: '',
            isTotalVistsShow: false,
          },
        };
      }

      return {
        total_visits: {
          ...prevState.total_visits,
          isTotalVistsShow: true,
        },
      };
    });
  }

  activateAllFields = () => {
    this.activateField('name');
    this.activateField('description');
    this.activateField('quantity');
    this.activateField('price');
    this.activateField('feeInclude');
  }

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  validateDate = () => {
    const { date } = this.state;
    const { isDateSelecting } = date;

    if (isDateSelecting && date.value.length === 0) {
      this.setState(prevState => ({
        date: {
          ...prevState.date,
          isValid: false,
          isActive: false,
        },
      }));
    }
  };

  validateRefunds = () => {
    const { refunds } = this.state;
    const isValid = refunds && refunds.value;
    if (isValid) {
      this.setState(prevState => ({
        refunds: {
          ...prevState.refunds,
          isValid,
        },
      }));
    } else {
      this.setState(prevState => ({
        refunds: {
          ...prevState.refunds,
          isValid,
          isActive: false,
        },
      }));
    }
    return isValid;
  }

  validateFeeIncludes = () => {
    const { feeInclude, price } = this.state;
    const isValid = (feeInclude && feeInclude.value) || parseFloat(price.value, 10) === 0;
    if (isValid) {
      this.setState(prevState => ({
        feeInclude: {
          ...prevState.feeInclude,
          isValid,
        },
      }));
    } else {
      this.setState(prevState => ({
        feeInclude: {
          ...prevState.feeInclude,
          isValid,
          isActive: false,
        },
      }));
    }
    return isValid;
  }

  validatePrice = ({ price, setInputState, callBack }) => {
    const { feeInclude } = this.state;
    const priceValue = price
      ? parseFloat(price, 10)
      : parseFloat(this.state.price.value, 10);
    let isPriceValid = priceValue >= 0 && priceValue !== '';

    const feeIncludeValue = feeInclude && feeInclude.value && feeInclude.value.value;

    if (feeIncludeValue === true) {
      isPriceValid = priceValue === 0 || priceValue >= 0.5;
    }

    if (setInputState) {
      if (callBack) {
        this.setState(prevState => ({
          price: {
            ...prevState.price,
            isValid: isPriceValid,
          },
        }), callBack);
      } else {
        this.setState(prevState => ({
          price: {
            ...prevState.price,
            isValid: isPriceValid,
          },
        }));
      }
    }

    return isPriceValid;
  }

  isValidationPassed = () => {
    const { name, date, description } = this.state;
    const validDate = date.isValid;
    const validTextInputs = name.isValid && description.isValid;
    const validPrice = this.validatePrice({ setInputState: true });
    const validRefunds = this.validateRefunds();
    const validIncludesFee = this.validateFeeIncludes();
    return validTextInputs && validDate && validRefunds
      && validIncludesFee && validPrice;
  };

  validateForm = () => {
    this.activateAllFields();
    this.validateDate();
    if (!this.isValidationPassed()) return false;
    const { items } = this.state;
    if (items.length === 0) {
      this.setState({ itemsError: true });
      return false;
    }
    return true;
  }

  composePass = () => {
    const {
      name,
      date,
      description,
      quantity,
      price,
      refunds,
      validity,
      total_visits: totalVisits,
      teacher,
      items,
    } = this.state;

    let { feeInclude } = this.state;

    if (parseFloat(price.value, 10) === 0) feeInclude = true;

    this.activateAllFields();
    this.validateDate();
    this.validateRefunds();
    this.validateFeeIncludes();
    if (!this.isValidationPassed()) return null;
    let startDate = date.value[0] && moment(date.value[0])
      .hours(0)
      .minutes(0)
      .seconds(0);

    const endDate = date.value[1] && moment(date.value[1])
      .hours(23)
      .minutes(59)
      .seconds(59);

    const YESTERDAY = moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .subtract(1, 'days');

    if (startDate) {
      startDate = startDate < YESTERDAY
        ? YESTERDAY.format()
        : startDate.format();
    }

    const pass = {
      name: name.value,
      start_date: startDate || '',
      end_date: (endDate && endDate.format()) || '',
      description: description.value,
      price: parseFloat(price.value, 10) * 100,
      refunds: refunds.value && refunds.value.value,
      fee_include: feeInclude.value && feeInclude.value.value,
      validity: parseInt(validity.value, 10),
      total_visits: parseInt(totalVisits.value, 10),
      teacher,
      items,
    };
    if (quantity.value) {
      pass.quantity = parseInt(quantity.value, 10);
    } else {
      pass.quantity = 0;
    }

    this.setState({ pass });
    return pass;
  }

  handleCreatePass = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const { handleError, user, openModal } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }

    if (!checkUserCreatAbilyti(user)) {
      openModal('PROFILE_MODAL');
      return;
    }

    if (this.state.isLoading) return;
    const passForRequest = this.composePass();
    if (!passForRequest) return;
    delete passForRequest.destroyable;
    delete passForRequest.teacher;
    passForRequest.items = passForRequest.items.map(element => ({ id: element.id }));

    const {
      createPass,
      onPassSubmitSuccess,
      history,
    } = this.props;
    const { price } = this.state;
    const isItemFree = price.value === 0;

    this.setState({ isLoading: true });
    createPass(passForRequest)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data && data.id) {
          onPassSubmitSuccess({
            id: data.id,
            action: 'create',
            isItemFree,
          });
          history.push(`/passes/${data.id}`);
        }
        if (data && data.errors) {
          this.setErrors(data.errors);
          this.setState({
            previewView: false,
          });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleEditPass = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const { handleError } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }
    if (this.state.isLoading) return;

    const pass = this.composePass();
    if (!pass) return;
    delete pass.destroyable;
    delete pass.teacher;
    pass.items = pass.items.map(element => ({ id: element.id }));
    pass.items = [...pass.items, ...this.state.itemsToRemove];

    const {
      editPass,
      history,
      onPassSubmitSuccess,
      match,
    } = this.props;
    pass.id = match && match.params && match.params.pass_id;
    const { price } = this.state;
    const isItemFree = price.value === 0;
    this.setState({ isLoading: true });

    editPass(pass)
      .then((data) => {
        if (data && data.id) {
          history.push(`/passes/${data.id}`);
          onPassSubmitSuccess({ action: 'edit', isItemFree });
          return;
        }
        if (data && data.errors) {
          this.setErrors(data.errors);
          this.setState({
            previewView: false,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleLinkLesson = (lessonToAdd) => {
    // if (!passToAdd.destroyable) {
    //   // open modal with attention message here
    // }

    this.setState(prevState => ({
      items: [
        ...prevState.items,
        {
          ...lessonToAdd,
          justLinkedItem: true,
        }],
      itemsError: false,
    }));
  }

  handleUnlinkLesson = (lessonToDelete) => {
    this.setState(prevState => ({
      ...prevState,
      itemsToRemove: lessonToDelete.justLinkedItem
        ? [...prevState.itemsToRemove]
        : [...prevState.itemsToRemove, {
          id: lessonToDelete.id,
          to_remove: true,
        }],
      items: prevState.items.filter(lesson => lesson.id !== lessonToDelete.id),
    }));
  }

  handleCloseForm = () => {
    if (this.state.isLoading) return;
    this.setState({ ...INITIAL_STATE });
    const { history, location } = this.props;
    const from = location.state && location.state.from;
    if ((from && from.indexOf('/teachers/') === -1
      && from.indexOf('/classes') === -1
      && from.indexOf('/account') === -1
      && from.indexOf('/passes') === -1) || !from) {
      history.push('/');
      return;
    }
    const list = location.state && location.state.list;
    history.push({
      pathname: from,
      state: { list },
    });
  }

  handleOpenPreview = () => {
    const { handleError } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }
    if (this.state.isLoading) return;
    const pass = this.composePass();
    if (!pass) return;
    this.setState({ previewView: true });
  }

  handleClosePreview = () => {
    if (this.state.isLoading) return;
    this.setState({ previewView: false });
  }

  preventSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { match } = this.props;
    const isEditing = match.path.indexOf('edit_pass') !== -1;

    if (isEditing) {
      this.handleEditPass();
    } else this.handleCreatePass();
  }

  renderInput = (inputName) => {
    const valueFromState = this.state[inputName];
    const baseStyle = inputStyles.input_box_create;
    let customStyle;
    if (!valueFromState.isActive) customStyle = inputStyles.not_active;
    else if (!valueFromState.isValid) customStyle = inputStyles.is_error;

    const isInputNumber = NUMBER_INPUTS.indexOf(inputName) !== -1;
    return (
      <div className={`${baseStyle} ${customStyle}`}>
        {isInputNumber
          ? (
            <Cleave
              className={inputStyles.input}
              options={inputName === 'price'
                ? {
                  numeral: true,
                  numeralDecimalScale: 2,
                  numeralPositiveOnly: true,
                }
                : {
                  numeral: true,
                  numeralDecimalScale: 0,
                  numeralPositiveOnly: true,
                }
          }
              name={inputName}
              value={valueFromState.value}
              onChange={this.handleInputChange}
              onBlur={this.handleInputBlur}
            />
          )
          : (
            <input
              className={inputStyles.input}
              type="text"
              name={inputName}
              value={valueFromState.value}
              onChange={this.handleInputChange}
            />
          )
      }
        <label
          htmlFor={inputName}
          className={(valueFromState.value || valueFromState.value === 0)
            ? inputStyles.label__with_value
            : inputStyles.label
          }
        >
          {valueFromState.error ? valueFromState.error : PLACEHOLDER[inputName]}
        </label>
      </div>
    );
  };

  render() {
    const {
      match,
      history,
      getTeacherClasses,
      handleError,
    } = this.props;

    const {
      previewView,
      description,
      date,
      refunds,
      showDaysInput,
      showPassesLimit,
      teacher,
      items,
      pass,
      destroyable,
      itemsError,
      feeInclude,
      total_visits: totalVisits,
    } = this.state;

    const isEditing = match.path.indexOf('edit_pass') !== -1;
    let refundsSelectStyles = refunds.isActive ? selectActiveStyles : customSelectStyles;
    if (!refunds.isValid) refundsSelectStyles = selectErrorStyles;

    let feeSelectStyles = feeInclude.isActive ? selectActiveStyles : customSelectStyles;
    if (!feeInclude.isValid) feeSelectStyles = selectErrorStyles;
    const showPriceMessage = feeInclude.value && feeInclude.value.value === true;

    const isDateSelecting = date && date.isDateSelecting;
    const isTotalVistsShow = totalVisits && totalVisits.isTotalVistsShow;

    return (
      <div className={`${gridStyles.mobile_on_top} ${gridStyles.wrapper_fixed_bottom}`}>
        <form onSubmit={this.preventSubmit}>
          {previewView
            ? (<PassDetails passForPreview={pass} previewFromForm history={history} />)
            : (
              <div className={gridStyles.scroll_container}>
                <h3 className={styles.title}>{isEditing ? 'Edit Pass' : 'Create Pass'}</h3>
                {this.renderInput('name')}
                <TextArea
                  handleInputChange={this.handleInputChange}
                  stateObject={description}
                  placeholder={PLACEHOLDER.description}
                />

                <Select
                  value={feeInclude.value}
                  onChange={this.handleSelectFeeIncludes}
                  options={FEE_OPTIONS}
                  className={inputStyles.safari_input}
                  styles={feeSelectStyles}
                  placeholder={PLACEHOLDER.feeInclude}
                  isSearchable={false}
                />

                {showPriceMessage && (
                  <p className={styles.price_message}>
                    If “Retain” is selected, the ticket price can be $0.00 (free) or more than $0.50
                  </p>
                )}
                {this.renderInput('price')}

                <Select
                  value={refunds.value}
                  onChange={this.handleSelectRefund}
                  options={REFUNDS_OPTIONS}
                  className={inputStyles.safari_input}
                  styles={refundsSelectStyles}
                  placeholder={PLACEHOLDER.refunds}
                  isSearchable={false}
                />

                <div className={ticketStyles.checkbox_row}>
                  <div className={ticketStyles.checkbox_item}>
                    <label className={ticketStyles.checkbox_container}>
                      <input type="checkbox" name="validity" checked={showDaysInput} onChange={this.handleShowInput} />
                      <div className={styles.checkmark_valid}>
                        {showDaysInput && <Checkmark className={styles.checkmark_icon} />}
                      </div>
                      Pass Expires
                    </label>
                    {showDaysInput && this.renderInput('validity')}
                  </div>
                  <div className={ticketStyles.checkbox_item}>
                    <label className={ticketStyles.checkbox_container}>
                      <input type="checkbox" name="quantity" checked={showPassesLimit} onChange={this.handleShowInput} />
                      <div className={styles.checkmark_valid}>
                        {showPassesLimit && <Checkmark className={styles.checkmark_icon} />}
                      </div>Passes Limit
                    </label>
                    {showPassesLimit && this.renderInput('quantity')}
                  </div>
                </div>
                {refunds.error
                  && <div className={ticketStyles.error}>{refunds.error}</div>
                }
                <div>
                  <label className={ticketStyles.checkbox_container}>
                    <input type="checkbox" name="validity" checked={isDateSelecting} onChange={this.handleShowDateSelecting} />
                    <div className={styles.checkmark_valid}>
                      {isDateSelecting && <Checkmark className={styles.checkmark_icon} />}
                    </div>
                    <h5>
                      Start Sale / End Sale
                    </h5>
                  </label>
                  {isDateSelecting ? (
                    <DatePicker
                      onDateChange={this.handleInputDateChange}
                      isActive={date.isActive}
                      isValid={date.isValid}
                      value={date.value}
                    />
                  ) : null}
                </div>
                {date.error
                  && <div className={styles.error}>{date.error}</div>
                }

                <div>
                  <label className={ticketStyles.checkbox_container}>
                    <input type="checkbox" name="total_visits" checked={isTotalVistsShow} onChange={this.toggleShowTotalVisits} />
                    <div className={styles.checkmark_valid}>
                      {isTotalVistsShow && <Checkmark className={styles.checkmark_icon} />}
                    </div>
                    <h5>
                      Multiple Entires
                    </h5>
                  </label>
                  {isTotalVistsShow ? this.renderInput('total_visits') : null}
                </div>

                <h4 className={styles.subtitle}>Classes</h4>
                {items.map((lesson, index) => (
                  <SimpleItemPreview
                    item={lesson}
                    key={`${lesson.title}_${index.toString()}`}
                    onUnlinkItem={this.handleUnlinkLesson}
                    match={match}
                    handleError={handleError}
                    isPassDestroyable={destroyable}
                  />
                ))}
                <LinkSelector
                  onLinkItem={this.handleLinkLesson}
                  getTeacherClasses={getTeacherClasses}
                  teacher={teacher}
                  items={items}
                  forClasses
                />
                {itemsError
                  && (
                    <div className={styles.error}>
                      Please add classes to pass.
                    </div>
                  )
                }
              </div>
            )}
          <div className={buttonStyles.fixed_buttons}>
            {isEditing
              ? (
                <button
                  type="submit"
                  className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                  onClick={this.handleEditPass}
                >
                  save
                </button>
              )
              : (
                <button
                  type="submit"
                  className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                  onClick={this.handleCreatePass}
                  data-test="create_button"
                >
                  create
                </button>
              )
            }
            <div className={buttonStyles.buttons_row}>
              <button
                type="button"
                className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                onClick={previewView
                  ? this.handleClosePreview
                  : this.handleOpenPreview}
              >
                {previewView ? 'edit' : 'preview'}
              </button>
              <button
                type="button"
                className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                onClick={this.handleCloseForm}
              >
                cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default PassForm;
