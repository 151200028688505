import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Filter from './Filter';

import { moveMapCenterTo } from '../Map/map_actions';
import createError from '../Fetch_error/fetch_error_action';
import { getDanceStyles } from '../shared/Dance_styles/dance_styles_actions';

const mSTP = state => ({
  danceStyles: state.danceStyles.styles,
});

const mDTP = dispatch => ({
  handleError: err => dispatch(createError(err)),
  handleMoveMapCenterTo: coords => dispatch(moveMapCenterTo(coords)),
  getDanceStyles: () => dispatch(getDanceStyles()),
});

export default withRouter(connect(mSTP, mDTP)(Filter));
