import { CHECK_IN_SUCCESS, CHECK_IN_FAILURE } from '../../redux/action_types';

const initialState = {
  error: '',
  buyer: null,
};

const checkInReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_IN_SUCCESS:
      return {
        buyer: action.payload,
        error: '',
      };

    case CHECK_IN_FAILURE:
      return {
        error: action.payload,
        buyer: null,
      };

    default:
      return state;
  }
};

export default checkInReducer;
