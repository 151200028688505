import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TicketIcon from '../../icons/Ticket';
import DancerIcon from '../../icons/Dancer';
import ChainIcon from '../../icons/Chain';

import inputStyles from '../../../styles/inputs.module.scss';
import buttonStyles from '../../../styles/buttons.module.scss';

const INITIAL_STATE = {
  itemsList: [],
  isItemsListShown: false,
};

export default class LinkSelector extends Component {
  static propTypes = {
    onLinkItem: PropTypes.func,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    forClasses: PropTypes.bool,
    getTeacherPasses: PropTypes.func,
    getTeacherClasses: PropTypes.func,
    teacher: PropTypes.shape({
      id: PropTypes.string,
    }),
  }

  static defaultProps = {
    items: [],
    forClasses: false,
    getTeacherPasses: () => {},
    getTeacherClasses: () => {},
    onLinkItem: () => {},
    teacher: null,
  }

  constructor() {
    super();
    this.getAllItems = this.getAllItems.bind(this);
    this.listRef = React.createRef();
    this.state = { ...INITIAL_STATE };
  }

  async getAllItems() {
    const { getTeacherPasses, getTeacherClasses, teacher, forClasses } = this.props;
    const teacherId = teacher && teacher.id;
    if (teacherId) {
      const teacherItems = forClasses
        ? await getTeacherClasses(teacherId)
        : await getTeacherPasses(teacherId);
      if (teacherItems) this.setState({ itemsList: teacherItems });
    }
  }

  handleOpenItemsList = () => {
    const { itemsList } = this.state;
    if (itemsList.length === 0) {
      this.getAllItems();
    }
    document.addEventListener('click', this.handleClickOutsideList);
    this.setState(prevState => ({
      isItemsListShown: !prevState.isItemsListShown,
    }));
  }

  handleCloseItemsList = () => {
    this.setState({ isItemsListShown: false });
  }

  handleClickOutsideList = (e) => {
    if (e.target !== this.listRef.current) {
      this.handleCloseItemsList();
      document.removeEventListener('click', this.handleClickOutsideList);
    }
  }

  handleLinkItem = (e) => {
    const { id } = e.currentTarget && e.currentTarget.dataset;
    const { itemsList } = this.state;
    const item = itemsList.find(itemFromState => itemFromState.id === id);
    const { onLinkItem, items } = this.props;
    const alreadyLinkedItem = items.find(itemFromProp => itemFromProp.id === item.id);
    if (alreadyLinkedItem) return;

    const itemToAdd = {
      ...item,
      start_date: new Date(item.start_date),
      end_date: new Date(item.end_date),
      isValid: true,
    };
    delete itemToAdd.sold;
    delete itemToAdd.items;
    delete itemToAdd.available;
    delete itemToAdd.total_visits;

    onLinkItem(itemToAdd);
    this.handleCloseItemsList();
  }

  setItemsToShow = () => {
    const { itemsList } = this.state;
    const { items } = this.props;
    const itemsIds = items && items.map(item => item.id);

    if (itemsIds) {
      return itemsList.filter(item => itemsIds.indexOf(item.id) === -1);
    }

    return itemsList;
  }

  render() {
    const { isItemsListShown } = this.state;
    const { forClasses } = this.props;
    const itemsToShow = this.setItemsToShow();

    return (
      <div className={inputStyles.venue_container}>
        <button
          type="button"
          className={buttonStyles.link_pass_btn}
          onClick={this.handleOpenItemsList}
        >
          {forClasses ? 'Link class' : 'Link pass'}
          <ChainIcon className={buttonStyles.link_pass_icon} />
        </button>
        {isItemsListShown && (
          <div className={inputStyles.autocomplete_venue} ref={this.listRef}>
            {(itemsToShow && itemsToShow.length > 0)
              ? itemsToShow.map(item => (
                <div
                  key={item.id}
                  role="button"
                  tabIndex="0"
                  className={inputStyles.autocomplete_item}
                  onMouseDown={this.handleLinkItem}
                  data-id={item.id}
                >
                  {forClasses
                    ? (
                      <>
                        <DancerIcon className={inputStyles.autocomplete_icon} />
                        <div className={inputStyles.autocomplete_info}>
                          <p className={inputStyles.autocomplete_link}>{item.title}</p>
                          <p className={inputStyles.autocomplete_subtext}>{item.location}</p>
                        </div>
                      </>
                    )
                    : (
                      <>
                        <TicketIcon className={inputStyles.autocomplete_icon} />
                        <div className={inputStyles.autocomplete_info}>
                          <p className={inputStyles.autocomplete_link}>{item.name}</p>
                          <p className={inputStyles.autocomplete_subtext}>${item.price / 100}</p>
                        </div>
                      </>
                    )}
                </div>
              ))
              : (
                <div className={inputStyles.autocomplete_item}>
                  {forClasses ? 'Classes...' : 'Passes...'}
                </div>
              )
          }
          </div>
        )}
      </div>
    );
  }
}
