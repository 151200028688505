import { requestCreateComment, requestDeleteComment, requestEditComment } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { signOut } from '../login/login_actions';
import { setAndOpenModal } from '../Modal/modal_actions';
import { getEventDetails } from '../Event_details/event_details_actions';
import { getVenueDetails } from '../Venue_details/venue_details_actions';
import { getTeacherDetails } from '../Teacher_details/teacher_details_actions';
import { getLessonDetails } from '../Lesson_details/lesson_details_actions';
import createError from '../Fetch_error/fetch_error_action';
import {
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAILURE,
  SET_COMMENT_TO_DELETE,
} from '../../redux/action_types';


export const createCommentSuccess = () => ({
  type: CREATE_COMMENT_SUCCESS,
});

export const createCommentFailure = error => ({
  type: CREATE_COMMENT_FAILURE,
  payload: { error },
});

const updateItem = ({ type, dispatch, id }) => {
  switch (type) {
    case 'event':
      dispatch(getEventDetails({ id, moveMap: false }));
      break;
    case 'venue':
      dispatch(getVenueDetails({ id, moveMap: false }));
      break;
    case 'teacher':
      dispatch(getTeacherDetails({ id, moveMap: false }));
      break;
    case 'lesson':
      dispatch(getLessonDetails({ id, moveMap: false }));
      break;
    default:
      break;
  }
};

export const createComment = ({ comment, id, type }) => (dispatch, getState) => {
  const { token } = getState().session;

  dispatch(startLoading());
  return requestCreateComment({ comment, id, token, type })
    .then((response) => {
      if (response.status === 201) {
        dispatch(createCommentSuccess());
        updateItem({ type, dispatch, id });
      }
      return response.data;
    })
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) {
        dispatch(signOut());
        dispatch(setAndOpenModal('SIGN_IN_MODAL'));
      }
      if (rej.response && rej.response.status === 422) {
        dispatch(createCommentFailure(rej.response.data.errors));
        return rej.response.data;
      }
      dispatch(createError(rej));
      return rej.response.data;
    })
    .finally(() => dispatch(finishLoading()));
};

export const setCommentToDelete = comment => ({
  type: SET_COMMENT_TO_DELETE,
  payload: comment,
});

export const resetCommentToDelete = () => ({
  type: SET_COMMENT_TO_DELETE,
  payload: null,
});

export const deleteComment = ({ idToDelete, itemId, type }) => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());

  return requestDeleteComment({ id: idToDelete, token })
    .then(() => updateItem({ type, dispatch, id: itemId }))
    .catch(rej => dispatch(createError(rej)))
    .finally(() => { dispatch(finishLoading()); });
};

export const editComment = ({ comment, commentId, itemId, type }) => (dispatch, getState) => {
  const { token } = getState().session;

  dispatch(startLoading());
  return requestEditComment({ comment, id: commentId, token })
    .then((res) => {
      if (res.status === 200) {
        updateItem({ type, dispatch, id: itemId });
        return res;
      }
      return res;
    })
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) {
        dispatch(signOut());
        dispatch(setAndOpenModal('SIGN_IN_MODAL'));
      }
      if (rej.response && rej.response.status === 422) {
        dispatch(createCommentFailure(rej.response.data.errors));
        return rej.response.data;
      }
      dispatch(createError(rej));
      return rej.response && rej.response.data;
    })
    .finally(() => { dispatch(finishLoading()); });
};
