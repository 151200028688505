import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import defaultPhoto from '../../images/default_event_photo.jpg';
import CancelIcon from '../icons/Cancel_icon';
import ChatIcon from '../icons/Chat_icon';
import TicketIcon from '../icons/Ticked_filled';
import ClockIcon from '../icons/Clock_filled';
import LockIcon from '../icons/Lock';
import StarIcon from '../icons/Star';
import ChainIcon from '../icons/Chain';
import QRScanIcon from '../icons/QR_scan';
import twoPhones from '../../images/2phones.jpg';
import PendingFriendIcon from '../icons/Pending_friend';
import AddedFriendIcon from '../icons/Added_friend';
import TransferIcon from '../icons/Transfer';

import { formatDateFromNow } from '../../utils/formatDate';
import {
  TYPE_COMMENT,
  TYPE_REMINDER,
  TYPE_PURCHASE,
  TYPE_VERIFIED,
  TYPE_SAVED,
  TYPE_CHECKED_IN,
  TYPE_INVITE,
  TYPE_PURCHASE_REMINDER,
  READ,
  TYPE_FRIEND_RECEIVED,
  TYPE_FRIEND_ACCEPTED,
  TYPE_TRANSFER_COMPLETED,
} from '../../config';
import styles from './notifications.module.scss';

const typeIcons = {};

typeIcons[TYPE_COMMENT] = (
  <div className={styles.type_icon_wrapper}>
    <ChatIcon className={styles.chat_icon} />
  </div>
);

typeIcons[TYPE_PURCHASE] = (
  <div className={styles.type_icon_wrapper}>
    <TicketIcon className={styles.notification_icon} />
  </div>
);

typeIcons[TYPE_CHECKED_IN] = (
  <div className={styles.type_icon_wrapper}>
    <QRScanIcon className={styles.notification_icon} />
  </div>
);

typeIcons[TYPE_REMINDER] = (
  <div className={styles.type_icon_wrapper}>
    <ClockIcon className={styles.notification_icon} />
  </div>
);

typeIcons[TYPE_VERIFIED] = (
  <div className={styles.type_icon_wrapper}>
    <LockIcon className={styles.notification_icon} />
  </div>
);

typeIcons[TYPE_SAVED] = (
  <div className={styles.type_icon_wrapper}>
    <StarIcon className={styles.notification_icon} transparent={false} />
  </div>
);


typeIcons[TYPE_PURCHASE_REMINDER] = (
  <div className={styles.type_icon_wrapper}>
    <img className={styles.notification_icon} src={twoPhones} alt="phones icon" />
  </div>
);

typeIcons[TYPE_INVITE] = (
  <div className={styles.type_icon_wrapper}>
    <ChainIcon className={styles.notification_icon} transparent={false} />
  </div>
);

typeIcons[TYPE_FRIEND_RECEIVED] = (
  <div className={styles.type_icon_wrapper}>
    <PendingFriendIcon className={styles.received_friend_icon} />
  </div>
);

typeIcons[TYPE_FRIEND_ACCEPTED] = (
  <div className={styles.type_icon_wrapper}>
    <AddedFriendIcon className={styles.accepted_friend_icon} />
  </div>
);

typeIcons[TYPE_TRANSFER_COMPLETED] = (
  <div className={styles.type_icon_wrapper}>
    <TransferIcon className={styles.transfer_icon} />
  </div>
);

const NotificationWrapper = (props) => {
  const {
    notification,
    children,
    history,
    markNotificationRead,
  } = props;

  const handleMarkRead = () => {
    if (markNotificationRead && notification.status !== READ) {
      const { id } = notification;
      markNotificationRead(id);
    }
  };

  if (notification.url) {
    const url = notification.url.replace('/api', '').replace('lessons', 'classes');
    const urlArr = url.split('#');
    const hash = urlArr.length > 1 ? urlArr.pop() : '';
    const pathname = urlArr.shift();

    return (
      <Link
        to={{
          pathname,
          state: { from: history.location.pathname },
          hash,
        }}
        className={styles.notification_wrapper}
        onClick={handleMarkRead}
      >
        {children}
      </Link>
    );
  }

  return (
    <div
      className={styles.notification_wrapper}
      onClick={handleMarkRead}
      onKeyPress={handleMarkRead}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

NotificationWrapper.propTypes = {
  notification: PropTypes.shape({
    text: PropTypes.string,
    icon_url: PropTypes.string,
    created_at: PropTypes.string,
    notification_type: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  markNotificationRead: PropTypes.func.isRequired,
};


const NotificationItem = (props) => {
  const { notification, serverTime } = props;
  if (!notification) return null;

  const {
    text,
    icon_url: image = defaultPhoto,
    created_at: createdAt,
    notification_type: type,
    status,
  } = notification;

  const dateToShow = createdAt ? formatDateFromNow(createdAt, serverTime) : null;
  const isRead = status === READ;

  const handleDeleteNotification = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { deleteNotification } = props;

    if (deleteNotification) {
      const { id } = notification;
      deleteNotification(id);
    }
  };

  return (
    <NotificationWrapper {...props}>
      <div className={styles.thumbnail} style={{ backgroundImage: `url(${image})` }}>
        {typeIcons[type]}
      </div>
      <div className={styles.info}>
        <div className={isRead ? '' : styles.text_bold}>{text}</div>
        <div className={styles.date}>{dateToShow}</div>
      </div>
      <button type="button" className={styles.cancel_button} onClick={handleDeleteNotification}>
        <CancelIcon className={styles.cancel_icon} />
      </button>
    </NotificationWrapper>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    text: PropTypes.string,
    image: PropTypes.string,
    created_at: PropTypes.string,
    notification_type: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  deleteNotification: PropTypes.func.isRequired,
  markNotificationRead: PropTypes.func.isRequired,
  serverTime: PropTypes.string,
};

NotificationItem.defaultProps = {
  serverTime: null,
};

export default NotificationItem;
