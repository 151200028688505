import { requestSearchUsers, requestTransferItem } from '../../../api';
import { startLoading, finishLoading } from '../../Loader/loading_actions';
import createError from '../../Fetch_error/fetch_error_action';
import { setAndOpenModal, setModalMessage, setModalTitle } from '../modal_actions';

import {
  SET_TRANSFER_ITEM,
  SET_TRANSFER_USER,
  RESET_TRANSFER_INFO,
} from '../../../redux/action_types';

export const setTransferItem = data => ({
  type: SET_TRANSFER_ITEM,
  payload: data,
});

export const setTransferUser = data => ({
  type: SET_TRANSFER_USER,
  payload: data,
});

export const resetTransferInfo = () => ({
  type: RESET_TRANSFER_INFO,
});

export const searchUsers = query => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());
  return requestSearchUsers({ query, token })
    .catch((err) => {
      dispatch(createError(err));
      return err;
    })
    .finally(() => dispatch(finishLoading()));
};

export const transferItem = ({ itemId, userId, itemType }) => (dispatch, getState) => {
  if (!itemId || !userId || !itemType) return null;

  const transferData = {
    user_id: userId,
    item_id: itemId,
    item_type: itemType,
  };

  const { token } = getState().session;
  dispatch(startLoading());
  return requestTransferItem({ transferData, token })
    .then((response) => {
      if (response.status === 201) {
        dispatch(setModalTitle('Congratulations!'));
        dispatch(setModalMessage('The ownership transfer has been successful.'));
        dispatch(setAndOpenModal('SUCCES_MODAL_MESSAGE'));
      }
      return response.data;
    })
    .catch((err) => {
      dispatch(createError(err));
      return err;
    })
    .finally(() => dispatch(finishLoading()));
};
