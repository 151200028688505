import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Dots from '../icons/Dots';
import styles from '../../styles/payment_methods.module.scss';

const cardLogoStyle = {
  Visa: styles.visa,
  MasterCard: styles.master_card,
  'American Express': styles.american_express,
  Discover: styles.discover,
};

class MethodItem extends Component {
  static propTypes = {
    method: PropTypes.shape({
      id: PropTypes.string,
      brand: PropTypes.string,
      last4: PropTypes.string,
      funding: PropTypes.string,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
    onSet: PropTypes.func.isRequired,
  }

  constructor() {
    super();

    this.menuRef = createRef();
  }

  state = {
    isOpen: false,
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickOutside);
  }

  handleToggle = () => {
    this.setState((prevState) => {
      if (prevState.isOpen) {
        document.removeEventListener('click', this.clickOutside);
      } else {
        document.addEventListener('click', this.clickOutside);
      }

      return { isOpen: !prevState.isOpen };
    });
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
    document.addEventListener('click', this.clickOutside);
  }

  handleClose = () => {
    this.setState({ isOpen: false });
    document.removeEventListener('click', this.clickOutside);
  }

  handleDelete = () => {
    const { method, onDelete } = this.props;
    this.handleClose();
    onDelete(method.id);
  }

  handleSetDefault = () => {
    const { method, onSet } = this.props;
    this.handleClose();
    onSet(method.id);
  }

  clickOutside = (e) => {
    if (e.target !== this.menuRef.current) this.handleClose();
    document.removeEventListener('click', this.clickOutside);
  }

  render() {
    const { isOpen } = this.state;
    const { method } = this.props;
    const {
      brand, last4, funding, default_method: defaultMethod,
      exp_month: expMonth, exp_year: expYear,
    } = method;
    const logoStyle = cardLogoStyle[brand] ? cardLogoStyle[brand] : styles.no_logo;

    const currentDate = new Date();
    const expiryDate = new Date();
    expiryDate.setFullYear(expYear, expMonth, 1);
    expiryDate.setHours(0, 0, 0);
    const isMethodExpired = currentDate > expiryDate;

    return (
      <div className={styles.method_item}>
        <div className={`${styles.card_logo} ${logoStyle}`}>
          {isMethodExpired && <div className={styles.expired}>expired</div>}
        </div>
        <div className={styles.funding}>
          {funding} {defaultMethod && <span className={styles.small_text}>(default)</span>}
        </div>
        <div className={styles.last4}>***{last4}</div>
        <div className={styles.dropdown_wrapper}>
          <button type="button" onClick={this.handleToggle}><Dots /></button>
          {isOpen && (
            <div className={styles.dropdown} ref={this.menuRef}>
              <button type="button" onClick={this.handleDelete} className={styles.drop_btn}>
                Delete
              </button>
              {!defaultMethod && !isMethodExpired && (
                <button type="button" onClick={this.handleSetDefault} className={styles.drop_btn}>
                  Set as default
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MethodItem;
