import { connect } from 'react-redux';
import { resetReportData, sendReport } from './report_actions';
import createError from '../../Fetch_error/fetch_error_action';

import ReportModal from './Report_modal';

const mSTP = state => ({
  reportData: state.reportData,
  user: state.session.user,
});

const mDTP = {
  resetReportData,
  sendReport,
  createError,
};

export default connect(mSTP, mDTP)(ReportModal);
