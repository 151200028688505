import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({
  component: Component,
  authenticated,
  redirectTo,
  additionalProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      authenticated ? (
        <Component {...additionalProps} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { from: props.location },
          }}
        />
      )
    )
    }
  />
);

ProtectedRoute.propTypes = {
  // component: PropTypes.oneOfType([
  //   PropTypes.node,
  //   PropTypes.func,
  //   PropTypes.string,
  //   PropTypes.element,
  //   PropTypes.shape({ render: PropTypes.func.isRequired }),
  // ]).isRequired,

  // temporary fix:
  component: PropTypes.any.isRequired, // eslint-disable-line
  authenticated: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string.isRequired,
  additionalProps: PropTypes.shape({}),
};

ProtectedRoute.defaultProps = {
  additionalProps: {},
};

export default ProtectedRoute;
