import { capitalizeStr } from './utils/parse';

const LIVE_STRIPE_KEY = 'pk_live_PvU4PoYQ4v8omRHptbaCX3By';
const TEST_STRIPE_KEY = 'pk_test_j3SnsHqpRbxs6rEX9FBm9AXI';
const STRIPE_CLIENT_ID_TEST = 'ca_EmXIdP0qfe7xszUbUE3Mx97IdK0TtftP';
const STRIPE_CLIENT_ID_LIVE = 'ca_EmXIE8pAbeMMX3QWLQrvnY8WuDN9XTAD';

let stripeKey;

if (process.env.REACT_APP_BETA_MODE) {
  stripeKey = LIVE_STRIPE_KEY;
} else {
  stripeKey = TEST_STRIPE_KEY;
}

let stripeLink;

if (process.env.NODE_ENV === 'production') {
  stripeLink = `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID_TEST}&scope=read_write`;
} else {
  stripeLink = `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID_TEST}&scope=read_write&redirect_uri=https://localhost:3000/connect`;
}

if (process.env.REACT_APP_BETA_MODE) {
  stripeLink = `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID_LIVE}&scope=read_write`;
}

export const STRIPE_KEY = stripeKey;
export const STRIPE_LINK = stripeLink;

// api-key from https://console.developers.google.com
export const MAPS_API_KEY = 'AIzaSyCj_j4hKYeQ4kjCAFjUQrAzUKDkbSqPaX4';

export const DEFAULT_CENTER = { lat: 32.715738, lng: -117.16108380000003 };

export const DEFAULT_ZOOM = 12;
export const DETAILED_ZOOM = 13;

export const MAX_DURATION_DAYS = 14;
export const MAX_DURATION_YEARS = 30;

// list types

export const EVENTS = 'events';
export const VENUES = 'venues';
export const TEACHERS = 'teachers';
export const SEARCH_LIST = 'searchList';
export const LESSONS = 'lessons';
export const LESSONS_LABEL = 'classes';
export const HOSTING_LIST = 'hostingList';
export const FRIEND_SAVED_LIST = 'friendSaved';

// item types

export const EVENT = 'event';
export const VENUE = 'venue';
export const TEACHER = 'teacher';
export const LESSON = 'lesson';
export const LESSON_LABEL = 'class';
export const PASS = 'pass';
export const PASSES = 'passes';

// recurrent options
export const ONCE = 'once';
export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const BI_WEEKLY = 'biweekly';
export const BI_WEEKLY_LABEL = 'Every other week';
export const MONTHLY = 'monthly';

export const RECURRENT_OPTIONS = [ONCE, DAILY, WEEKLY, BI_WEEKLY, MONTHLY];

// week days
export const SU = 'Su';
export const MO = 'Mo';
export const TU = 'Tu';
export const WE = 'We';
export const TH = 'Th';
export const FR = 'Fr';
export const SA = 'Sa';

export const WEEK = [SU, MO, TU, WE, TH, FR, SA];

// event types

export const SOCIAL = 'social';
export const WORKSHOP = 'workshop';
export const RETREAT = 'retreat';
export const WEEKENDER = 'weekender';
export const HOUSE_PARTY = 'house_party';
export const HOUSE_PARTY_LABEL = 'House party';
export const FESTIVAL = 'festival';
export const FESTIVAL_LABEL = 'Festival/Congress';
export const SOCIAL_WORKSHOP = 'social_workshop';
export const SOCIAL_WORKSHOP_LABEL = 'Social/Workshop';
export const CRUISE = 'cruise';
export const PROGRESSIVE_SERIES = 'progressive_series';
export const PROGRESSIVE_SERIES_LABEL = 'Progressive Series';
export const EVENT_TYPE_OPTIONS = [
  {
    value: SOCIAL,
    label: capitalizeStr(SOCIAL),
  },
  {
    value: WORKSHOP,
    label: capitalizeStr(WORKSHOP),
  },
  {
    value: SOCIAL_WORKSHOP,
    label: SOCIAL_WORKSHOP_LABEL,
  },
  {
    value: RETREAT,
    label: capitalizeStr(RETREAT),
  },
  {
    value: WEEKENDER,
    label: capitalizeStr(WEEKENDER),
  },
  {
    value: HOUSE_PARTY,
    label: HOUSE_PARTY_LABEL,
  },
  {
    value: FESTIVAL,
    label: FESTIVAL_LABEL,
  },
  {
    value: CRUISE,
    label: capitalizeStr(CRUISE),
  },
  {
    value: PROGRESSIVE_SERIES,
    label: PROGRESSIVE_SERIES_LABEL,
  },
];

// payment statuses

export const PAID = 'paid';
export const FREE = 'free';
export const UNPAID = 'unpaid';
export const EXPIRED = 'expired';
export const CANCELED = 'canceled';

// items status

export const INACTIVE = 'inactive';
export const ACTIVE = 'active';

// filters for list

export const LIST_FILTERS = [
  'geo_centre',
  'radius',
  'category',
  'style',
  'sort',
  'start_date',
  'end_date',
  'weekday',
  's',
  'saved',
  'event_type',
  'geo_bounds',
];

let googleAnalyticsId;

if (process.env.REACT_APP_BETA_MODE) {
  googleAnalyticsId = 'UA-142447694-1'; // production account
} else {
  googleAnalyticsId = 'UA-142466459-1'; // staging account
}

export const GOOGLE_ANALYTICS_ID = googleAnalyticsId;

export const FACEBOOK_APP_ID = '351908472082473';

export const GOOGLE_CLIENT_ID = '113113078497-qvjgojvg78mlhgd00smtjsvqvdci39iv.apps.googleusercontent.com';
export const FACEBOOK_CLIENT_ID = '351908472082473';


// notifications type
export const TYPE_COMMENT = 'comment';
export const TYPE_REMINDER = 'reminder';
export const TYPE_PURCHASE = 'purchase';
export const TYPE_VERIFIED = 'verified';
export const TYPE_SAVED = 'save';
export const TYPE_CHECKED_IN = 'checkin';
export const TYPE_INVITE = 'invite';
export const TYPE_PURCHASE_REMINDER = 'purchased_reminder';
export const TYPE_FRIEND_RECEIVED = 'friend_received';
export const TYPE_FRIEND_ACCEPTED = 'friend_accepted';
export const TYPE_TRANSFER_COMPLETED = 'transfer_completed';

// notifications statuses
export const CREATED = 'created';
export const DISPLAYED = 'displayed';
export const READ = 'read';
export const DELETED = 'deleted';

// input state
export const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

// hosting statuses

export const PENDING = 'pending';
export const HOST = 'host';
export const COHOST = 'cohost';

// friendship statuses
export const FRIEND = 'friend';
export const NOT_FRIEND = 'not_friend';
export const REQUEST_SENT = 'request_sent';
export const REQUEST_RECEIVED = 'request_received';

// max values
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10Mb

// apple auth
export const APPLE_CLIENT_ID = 'com.dancity';

// user
export const USER_FIELDS = [
  'first_name',
  'last_name',
  'birthday',
  'gender',
  'dancer_role',
  'about',
  'address',
  'nickname',
  'avatar',
  'dance_styles',
  'profession',
  'height',
  'skill',
  'goal',
  'languages',
  'email',
];
