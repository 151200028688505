import {
  requestSoldTickets, requestVerifyTicket, requestEventManualCheckin,
  requestLessonManualCheckin, requestEventDetails, requestLessonDetails,
} from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { signOut } from '../login/login_actions';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import createError from '../Fetch_error/fetch_error_action';
import getNestedValue from '../../utils/getNestedValue';

import { CHECK_IN_SUCCESS, CHECK_IN_FAILURE } from '../../redux/action_types';
import { EVENT, LESSON } from '../../config';

export const checkInSuccess = buyer => ({
  type: CHECK_IN_SUCCESS,
  payload: buyer,
});

export const checkInFailure = error => ({
  type: CHECK_IN_FAILURE,
  payload: error,
});

export const getSoldTickets = (id, type, startDate) => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());
  return requestSoldTickets({ id, token, type, startDate })
    .then(response => response)
    .catch((error) => {
      if (error.response && error.response.status === 401) dispatch(signOut());
      if (error.response && error.response.status === 403) {
        dispatch(createError('Check-in is forbidden.'));
        return error;
      }
      dispatch(createError(error));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};

export const verifyTicket = (ticketId, itemId) => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());
  return requestVerifyTicket({ ticketId, token, itemId })
    .then((response) => {
      const buyer = response.data && response.data.data && response.data.data.buyer;
      dispatch(checkInSuccess(buyer));
      dispatch(setCurrentModal('CHECK-IN_SUCCESS_MODAL'));
      dispatch(openModal());
      return response;
    })
    .catch((error) => {
      const { response } = error;
      if (response && response.status === 401) dispatch(signOut());
      const errorText = response && response.data && response.data.errors
        && response.data.errors.ticket && response.data.errors.ticket[0]
        ? response.data.errors.ticket[0]
        : 'Verifying tickets failed. Please try again later...';
      dispatch(createError(errorText));
      dispatch(checkInFailure(errorText));
      if (response && response.status === 422) {
        dispatch(setCurrentModal('CHECK-IN_FAILURE_MODAL'));
        dispatch(openModal());
      }
      return response;
    })
    .finally(() => dispatch(finishLoading()));
};


export const manualCheckin = ({ ticketId, itemId, hostCode, type }) => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());
  if (!token) return null;
  let requestManualCheckin;
  if (type === EVENT) requestManualCheckin = requestEventManualCheckin;
  if (type === LESSON) requestManualCheckin = requestLessonManualCheckin;

  if (!requestManualCheckin) return null;

  return requestManualCheckin({ ticketId, token, itemId, hostCode })
    .then(response => response)
    .catch((error) => {
      const { response } = error;
      if (response && response.status === 401) dispatch(signOut());
      let errorText = getNestedValue(error, 'response', 'data', 'errors', 'ticket');
      errorText = errorText ? errorText.join() : 'Checkin failed. Please try again later...';
      dispatch(createError(errorText));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};

export const getEventDetails = ({ id, startDate }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestEventDetails({ id, token, startDate })
    .then(response => response)
    .catch((error) => {
      dispatch(createError(error));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};

export const getLessonDetails = ({ id, startDate }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestLessonDetails({ id, token, startDate })
    .then(response => response)
    .catch((error) => {
      dispatch(createError(error));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};
