import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import styles from '../modal.module.scss';
import buttonStyles from '../../../styles/buttons.module.scss';
import getNestedValue from '../../../utils/getNestedValue';
import getUserName from '../../../utils/getUserName';

const ConfirmTransferModal = (props) => {
  const {
    isModalOpen,
    closeModal,
    itemForTransfer,
    resetTransferInfo,
    transferUser,
  } = props;


  useEffect(() => () => {
    resetTransferInfo();
  }, []);

  const handleTransferItem = () => {
    const { createError, transferItem } = props;
    if (!transferUser) {
      createError('Please find and select user for transfer');
    } else if (!itemForTransfer) {
      createError('No item for transfer');
    } else {
      const itemId = getNestedValue(itemForTransfer, 'id');
      const itemType = getNestedValue(itemForTransfer, 'type');
      const userId = getNestedValue(transferUser, 'id');

      transferItem({ itemId, userId, itemType });
    }
  };

  const title = getNestedValue(itemForTransfer, 'title');
  const userName = getUserName(transferUser);
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className={styles.content}
      overlayClassName={styles.overlay}
    >
      <div className={styles.content_box}>
        <div className={styles.title}>
          Are you sure?
        </div>
        <div className={styles.message_box}>
          You are about to transfer ownership {title && `of "${title}"`} to {userName}. <br />
        </div>
        <div className={styles.message_box}>
          This action can not be undone!
        </div>
        <div className={buttonStyles.buttons_row}>
          <button
            type="button"
            className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
            onClick={handleTransferItem}
          >
            Transfer
          </button>
          <button
            type="button"
            className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmTransferModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  itemForTransfer: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  transferUser: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    nickname: PropTypes.string,
  }).isRequired,
  createError: PropTypes.func.isRequired,
  resetTransferInfo: PropTypes.func.isRequired,
  transferItem: PropTypes.func.isRequired,
};

export default ConfirmTransferModal;
