import React from 'react';
import PropTypes from 'prop-types';

const ViewList = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15" height="13">
    <path d="M13.922 7.624H5.625c-.593 0-1.075-.503-1.075-1.126 0-.62.482-1.123 1.075-1.123h8.297c.594 0 1.076.503 1.076 1.123 0 .623-.482 1.126-1.076 1.126zm0-5.377H5.625c-.593 0-1.075-.503-1.075-1.123 0-.622.482-1.125 1.075-1.125h8.297c.594 0 1.076.503 1.076 1.125 0 .62-.482 1.123-1.076 1.123zM1.577 12.998h-.03c-.593 0-1.075-.503-1.075-1.123 0-.622.482-1.126 1.075-1.126h.03c.594 0 1.076.504 1.076 1.126 0 .62-.482 1.123-1.076 1.123zm0-5.374h-.03c-.593 0-1.075-.503-1.075-1.126 0-.62.482-1.123 1.075-1.123h.03c.594 0 1.076.503 1.076 1.123 0 .623-.482 1.126-1.076 1.126zm0-5.377h-.03c-.593 0-1.075-.503-1.075-1.123 0-.622.482-1.125 1.075-1.125h.03c.594 0 1.076.503 1.076 1.125 0 .62-.482 1.123-1.076 1.123zm4.048 8.502h8.297c.594 0 1.076.504 1.076 1.126 0 .62-.482 1.123-1.076 1.123H5.625c-.593 0-1.075-.503-1.075-1.123 0-.622.482-1.126 1.075-1.126z" />
  </svg>
);

ViewList.propTypes = {
  className: PropTypes.string,
};

ViewList.defaultProps = {
  className: '',
};

export default ViewList;
