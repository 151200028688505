import {
  SIGN_OUT, SET_SUBSCRIPTION_TO_UPDATE, RESET_SUBSCRIPTION_TO_UPDATE, GET_MY_SUBSCRIPTIONS_SUCCESS,
} from '../../redux/action_types';

const initialState = {
  subscriptionToUpdate: null,
  mySubscriptions: [],
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_TO_UPDATE:
      return {
        ...state,
        subscriptionToUpdate: action.payload,
      };

    case RESET_SUBSCRIPTION_TO_UPDATE:
      return {
        ...state,
        subscriptionToUpdate: initialState.subscriptionToUpdate,
      };

    case GET_MY_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        mySubscriptions: action.payload,
      };

    case SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};

export default subscriptionReducer;
