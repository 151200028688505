import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import { CSSTransition } from 'react-transition-group';

import Burger from '../icons/Burger';
import CancelIcon from '../icons/Cancel_icon';
import TicketIcon from '../icons/Ticket';
import QRscanIcon from '../icons/QR_scan';
// import Smartphone from '../icons/Smartphone';
import FeedbackIcon from '../icons/Feedback';
import ContactIcon from '../icons/Contact';
import CareersIcon from '../icons/Careers';
// import ApparelIcon from '../icons/Apparel';
import FriendsIcon from '../icons/Friends';

import styles from './burger_menu.module.scss';

class BurgerMenu extends Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    history: null,
  };

  state ={
    isOpen: false,
  }

  toogleOpenMenu = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleClickOutside = () => {
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const { isOpen } = this.state;
    const { history } = this.props;

    return (
      <div>
        <button type="button" onClick={this.toogleOpenMenu} data-test="open_button">
          {isOpen
            ? <CancelIcon className={styles.cancel_icon} />
            : <Burger className={styles.burger} />
          }
        </button>
        <CSSTransition
          in={isOpen}
          timeout={300}
          classNames="burger_menu_wrapper"
          unmountOnExit
        >
          <div className={styles.wrapper}>
            <div className={styles.menu}>
              <ul>
                <li className={styles.menu_item}>
                  <Link
                    to={{
                      pathname: '/friends',
                      state: { from: history.location.pathname },
                    }}
                    className={styles.link}
                    onClick={this.handleClickOutside}
                  >
                    <span className={styles.icon_box}>
                      <FriendsIcon className={styles.friends_icon} />
                    </span>
                    Friends
                  </Link>
                </li>
                <li className={styles.menu_item}>
                  <Link
                    to={{
                      pathname: '/my_tickets',
                      state: { from: history.location.pathname },
                    }}
                    className={styles.link}
                    onClick={this.handleClickOutside}
                  >
                    <span className={styles.icon_box}>
                      <TicketIcon className={styles.ticket_icon} />
                    </span>
                    My Tickets
                  </Link>
                </li>
                <li className={styles.menu_item}>
                  <Link
                    to={{
                      pathname: '/my_hostings/events',
                      state: { from: history.location.pathname },
                    }}
                    className={styles.link}
                    onClick={this.handleClickOutside}
                  >
                    <span className={styles.icon_box}>
                      <QRscanIcon className={styles.qr_scan_icon} />
                    </span>
                    Hosting
                  </Link>
                </li>
                <div className={styles.separator} />
                <li className={styles.menu_item}>
                  <Link
                    to={{
                      pathname: '/careers',
                      state: { from: history.location.pathname },
                    }}
                    className={styles.link}
                    onClick={this.handleClickOutside}
                  >
                    <span className={styles.icon_box}>
                      <CareersIcon className={styles.careers_icon} />
                    </span>
                    Careers
                  </Link>
                </li>
                <li className={styles.menu_item}>
                  <Link
                    to={{
                      pathname: '/feedback',
                      state: { from: history.location.pathname },
                    }}
                    className={styles.link}
                    onClick={this.handleClickOutside}
                  >
                    <span className={styles.icon_box}>
                      <FeedbackIcon className={styles.feedback_icon} />
                    </span>
                    Feedback
                  </Link>
                </li>
                <li className={styles.menu_item}>
                  <Link
                    to={{
                      pathname: '/contact',
                      state: { from: history.location.pathname },
                    }}
                    className={styles.link}
                    onClick={this.handleClickOutside}
                  >
                    <span className={styles.icon_box}>
                      <ContactIcon className={styles.contact_icon} />
                    </span>
                    Contact
                  </Link>
                </li>
                {/* temporary hide buttons */}
                {/* <div className={styles.separator} />
                <li className={styles.menu_item}>
                  <a
                    href="https://dancity.shop/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.link}
                    onClick={this.handleClickOutside}
                  >
                    <span className={styles.icon_box}>
                      <ApparelIcon className={styles.apparel_icon} />
                    </span>
                    Apparel
                  </a>
                </li> */}
                {/* <li className={styles.menu_item}>
                  <Link
                    to={{
                      pathname: '/',
                      state: { from: history.location.pathname },
                    }}
                    className={styles.link}
                    onClick={this.handleClickOutside}
                  >
                    <span className={styles.icon_box}>
                      <Smartphone className={styles.smartphone} />
                    </span>
                    Our App
                  </Link>
                </li> */}
              </ul>
              <div className={styles.sub_menu}>
                <Link
                  className={styles.terms_link}
                  to={{
                    pathname: '/terms_of_use',
                    state: { from: history.location.pathname },
                  }}
                  onClick={this.handleClickOutside}
                >
                  Terms and Conditions
                </Link>
                |
                <Link
                  className={styles.terms_link}
                  to={{
                    pathname: '/privacy_policy',
                    state: { from: history.location.pathname },
                  }}
                  onClick={this.handleClickOutside}
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
            <div
              className={styles.overlay}
              onClick={this.handleClickOutside}
              role="button"
              tabIndex={0}
              onKeyDown={this.handleClickOutside}
            />
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default withRouter(onClickOutside(BurgerMenu));
