import { combineReducers } from 'redux';
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  CURRENT_MODAL,
  MODAL_TEXT,
  MODAL_TITLE,
} from '../../redux/action_types';

const initialState = {
  isModalOpen: false,
  currentModal: null,
  text: 'Success!',
  title: '',
};

export const modalOpenReducer = (state = initialState.isModalOpen, action) => {
  switch (action.type) {
    case CLOSE_MODAL:
      return false;

    case OPEN_MODAL:
      return true;

    default:
      return state;
  }
};

export const currentModalReducer = (state = initialState.currentModal, action) => {
  switch (action.type) {
    case CURRENT_MODAL:
      return action.payload;

    case CLOSE_MODAL:
      return null;

    default:
      return state;
  }
};

export const modalTextReducer = (state = initialState.text, action) => {
  switch (action.type) {
    case MODAL_TEXT:
      return action.payload;

    case CLOSE_MODAL:
      return initialState.text;

    default:
      return state;
  }
};

export const modalTitleReducer = (state = initialState.title, action) => {
  switch (action.type) {
    case MODAL_TITLE:
      return action.payload;

    case CLOSE_MODAL:
      return initialState.title;

    default:
      return state;
  }
};

export default combineReducers({
  isModalOpen: modalOpenReducer,
  currentModal: currentModalReducer,
  text: modalTextReducer,
  title: modalTitleReducer,
});
