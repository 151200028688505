import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  resetTransferInfo,
  searchUsers,
  setTransferUser,
} from './transfer_actions';
import createError from '../../Fetch_error/fetch_error_action';
import { setAndOpenModal } from '../modal_actions';

import TransferModal from './Transfer_modal';
import getNestedValue from '../../../utils/getNestedValue';

const mSTP = state => ({
  itemForTransfer: getNestedValue(state, 'transferData', 'transferItem'),
});

const mDTP = {
  resetTransferInfo,
  searchUsers,
  createError,
  setTransferUser,
  setAndOpenModal,
};

export default withRouter(connect(mSTP, mDTP)(TransferModal));
