import React from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '../icons/Cancel_icon';
import styles from './fetch_error.module.scss';

const FetchError = ({ error, resetError }) => {
  let text;
  if (typeof error === 'string') text = error;
  else {
    text = (error && error.message && error.message === 'Network Error')
      ? 'No connection'
      : 'Sorry, something went wrong. Please try again later...';
  }

  return (
    error ? (
      <div className={styles.error_container}>
        {text}
        <button type="button" className={styles.cancel_button} onClick={resetError}>
          <CancelIcon className={styles.cancel_icon} />
        </button>
      </div>
    ) : null
  );
};

FetchError.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  resetError: PropTypes.func.isRequired,
};

FetchError.defaultProps = {
  error: '',
};


export default FetchError;
