const customStyles = {
  container: provided => ({
    ...provided,
    marginRight: 15,
  }),

  control: (provided, state) => ({
    ...provided,
    background: state.isFocused ? '#0077ee' : '#fff',
    borderRadius: '10px',
    boxSizing: 'border-box',
    userSelect: 'none',
    width: '50px',
    height: '50px',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  valueContainer: provided => ({
    ...provided,
    justifyContent: 'center',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#fff' : '#000',
    fontFamily: 'SFUIDisplay-Semibold',
    fontSize: '20px',
    margin: 0,
  }),

  menu: (provided, state) => ({
    ...provided,
    maxWidth: '50px',
    border: state.isFocused ? '1px solid #0077ee' : '1px solid #909bb1',
    borderRadius: '10px',
    overflow: 'auto',
    zIndex: 20,
  }),

  dropdownIndicator: () => ({
    display: 'none',
  }),

  clearIndicator: () => ({
    display: 'none',
  }),

  placeholder: provided => ({
    ...provided,
    color: '#909bb1',
    fontSize: 18,
    margin: 0,
  }),
};

export default customStyles;
