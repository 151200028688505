import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link, Route } from 'react-router-dom';
import SearchUserForm from '../Search_user_form/Search_user_form';
import UserItem from './User_item';
import FriendItem from './Friend_item';
import getNestedValue from '../../utils/getNestedValue';
import CancelIcon from '../icons/Cancel_icon';

import gridStyles from '../../styles/main_grid.module.scss';
import commonStyles from '../../styles/common.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import navStyles from '../../styles/navigation.module.scss';
import styles from './friends.module.scss';

const INITIAL_STATE = {
  searchText: '',
  searchResult: [],
  friends: [],
};

export default class Friends extends Component {
  static propTypes = {
    getFriends: PropTypes.func.isRequired,
    searchUsers: PropTypes.func.isRequired,
    inviteToFriends: PropTypes.func.isRequired,
    acceptFriend: PropTypes.func.isRequired,
    declineFriend: PropTypes.func.isRequired,
    openDeleteFriendModal: PropTypes.func.isRequired,
    cancelRequest: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        list: PropTypes.string,
      }),
    }).isRequired,
    isModalOpen: PropTypes.bool.isRequired,
  }

  state = { ...INITIAL_STATE }

  componentDidMount() {
    this.getFriendList();
  }

  componentDidUpdate(prevProps) {
    const currentList = getNestedValue(this.props.match, 'params', 'list');
    const prevList = getNestedValue(prevProps.match, 'params', 'list');

    const prevOpen = prevProps.isModalOpen;
    const nextOpen = this.props.isModalOpen;

    const shouldUpdate = currentList !== prevList || prevOpen !== nextOpen;

    if (shouldUpdate) {
      this.getFriendList();
    }
  }

  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value }, this.searchUsers);
  };

  getFriendList = async () => {
    const { getFriends } = this.props;
    const list = this.getCurrentList();

    const res = await getFriends(list);
    const friends = getNestedValue(res, 'data', 'data');
    if (friends) {
      this.setState({ friends });
    }
  }

  getCurrentList = () => {
    const { match } = this.props;
    const list = getNestedValue(match, 'params', 'list');
    return list;
  }

  searchUsers = async () => {
    const { searchText } = this.state;
    const { searchUsers } = this.props;

    if (searchText === '') {
      this.setState({ searchResult: [] });
    } else {
      const res = await searchUsers({ q: searchText });
      const searchResult = getNestedValue(res, 'data');

      if (searchResult && Array.isArray(searchResult) && searchResult.length > 0) {
        this.setState({ searchResult });
      } else {
        this.setState({ searchResult: [] });
      }
    }
  }

  inviteUser = (user) => {
    const id = getNestedValue(user, 'id');
    const { inviteToFriends } = this.props;

    inviteToFriends(id).then((res) => {
      if (res && res.status === 204) {
        const list = this.getCurrentList();
        if (list === 'requests') {
          this.getFriendList();
        }
        this.searchUsers();
      }
    });
  }

  acceptFriend = (user) => {
    const id = getNestedValue(user, 'id');
    const { acceptFriend } = this.props;

    acceptFriend(id).then((res) => {
      if (res && res.status === 204) {
        const list = this.getCurrentList();
        if (list === 'invites') {
          this.getFriendList();
        }
        this.searchUsers();
      }
    });
  }

  declineFriend = (user) => {
    const id = getNestedValue(user, 'id');
    const { declineFriend } = this.props;

    declineFriend(id).then((res) => {
      if (res && res.status === 204) {
        const list = this.getCurrentList();
        if (list === 'requests' || list === 'invites') {
          this.getFriendList();
        }
        this.searchUsers();
      }
    });
  }

  cancelRequest = (user) => {
    const id = getNestedValue(user, 'id');
    const { cancelRequest } = this.props;

    cancelRequest(id).then((res) => {
      if (res && res.status === 204) {
        const list = this.getCurrentList();
        if (list === 'requests') {
          this.getFriendList();
        }
        this.searchUsers();
      }
    });
  }

  resetSearch = () => this.setState({
    searchText: '',
    searchResult: [],
  });

  handleClose = () => {
    const { history, location } = this.props;
    const from = getNestedValue(location, 'state', 'from') || '/';
    history.push(from);
  }

  checkRequestActive = () => {
    const { match } = this.props;
    const list = getNestedValue(match, 'params', 'list');
    return list === 'requests' || list === 'invites';
  }

  render() {
    const { searchText, searchResult, friends } = this.state;
    const { openDeleteFriendModal } = this.props;

    const showSearchResult = searchText && searchText.length > 0;

    return (
      <div className={`${gridStyles.side_bar} ${gridStyles.mobile_on_top}`}>
        <h2 className={commonStyles.title}>
          Friends
        </h2>
        <button
          type="button"
          className={buttonStyles.cancel_button}
          onClick={this.handleClose}
        >
          <CancelIcon />
        </button>
        <SearchUserForm
          searchText={searchText}
          handleInputChange={this.handleInputChange}
          resetSearch={this.resetSearch}
        />

        {showSearchResult && (
          <>
            <h3 className={commonStyles.secondary_title}>
              Search results:
            </h3>
            {searchResult && searchResult.length > 0 ? (
              <ul className={styles.search_list}>
                {searchResult.map(item => (
                  <UserItem
                    key={item.id}
                    user={item}
                    inviteUser={this.inviteUser}
                  />
                ))}
              </ul>
            ) : (
              <div className={styles.search_list}>nothing found</div>
            )}
          </>
        )}
        <ul className={navStyles.container}>
          <li className={navStyles.block_double}>
            <NavLink
              className={navStyles.item}
              activeClassName={navStyles.item_active}
              exact
              to="/friends"
            >
              Friends
            </NavLink>
          </li>
          <li className={navStyles.block_double}>
            <NavLink
              className={navStyles.item}
              activeClassName={navStyles.item_active}
              isActive={this.checkRequestActive}
              exact
              to="/friends/invites"
            >
              Requests
            </NavLink>
          </li>
        </ul>
        <Route
          path="/friends/invites"
          render={() => (
            <div className={styles.right_align_box}>
              <Link
                to="/friends/requests"
                className={styles.underline_link}
              >
                View sent requests
              </Link>
            </div>
          )}
        />
        <Route
          path="/friends/requests"
          render={() => (
            <div className={styles.right_align_box}>
              <Link
                to="/friends/invites"
                className={styles.underline_link}
              >
                View received requests
              </Link>
            </div>
          )}
        />

        {friends && friends.length > 0
          ? friends.map(friend => (
            <FriendItem
              key={friend.id}
              friend={friend}
              acceptFriend={this.acceptFriend}
              openDeleteFriendModal={openDeleteFriendModal}
              declineFriend={this.declineFriend}
              cancelRequest={this.cancelRequest}
            />
          ))
          : <div>Empty list</div>
        }
      </div>
    );
  }
}
