import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from '../../../styles/view_details.module.scss';

const TEXT_EMPTY = '...';

const VenueInfo = ({ venueId, location, address }) => (
  <>
    {venueId
      ? (
        <>
          <Link to={`/venues/${venueId}`} className={styles.venue}>
            <address className={styles.text_dark}>{location}</address>
          </Link>
          <address className={styles.text_light}>{address}</address>
        </>
      )
      : (
        <>
          <address className={styles.text_dark}>{location}</address>
          <address className={styles.text_light}>{address}</address>
        </>
      )
    }
  </>
);

VenueInfo.propTypes = {
  venueId: PropTypes.string,
  address: PropTypes.string,
  location: PropTypes.string,
};

VenueInfo.defaultProps = {
  venueId: null,
  address: TEXT_EMPTY,
  location: TEXT_EMPTY,
};

export default VenueInfo;
