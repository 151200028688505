import React from 'react';
import PropTypes from 'prop-types';

import ReadMore from '../shared/Read_more/Read_more';
import DateInfo from '../shared/Date_info/Date_info';
import VenueInfo from '../shared/Venue_info/Venue_info';
import PhotosBlock from '../shared/Photos_block/Photos_block';
import BreakLineText from '../shared/Break_line_text/BreakLineText';

import { capitalizeStr, getDollarsFromCents } from '../../utils/parse';
import getUserName from '../../utils/getUserName';
import detectSafari from '../../utils/detectSafari';


import CalendarIcon from '../icons/Calendar_thin';
import PinIcon from '../icons/Pin';
import InfoIcon from '../icons/Info';
import TicketIcon from '../icons/Ticket';
import defaultAvatar from '../../images/default_avatar.jpg';

import styles from '../../styles/view_details.module.scss';

import {
  ACTIVE, INACTIVE, HOUSE_PARTY, HOUSE_PARTY_LABEL, FESTIVAL, FESTIVAL_LABEL,
  SOCIAL_WORKSHOP, SOCIAL_WORKSHOP_LABEL, PROGRESSIVE_SERIES,
  PROGRESSIVE_SERIES_LABEL,
} from '../../config';

const TEXT_EMPTY = '...';

const EventDetailsCutted = (props) => {
  const handleOpenFullScreen = () => {
    const { setFSphotos, event, setAndOpenModal } = props;
    const photos = event && event.photos;

    setFSphotos(photos);
    setAndOpenModal('FULL_SCREEN_PHOTOS_MODAL');
  };

  const { event } = props;
  if (!event) return null;

  const {
    title,
    description,
    dance_styles: danceStyles,
    location,
    address,
    tickets,
    photos,
    item_type: itemType,
    venue_id: venueId,
    user,
  } = event;

  let fanciersNumber = event.fanciers_number ? event.fanciers_number : 0;

  const userName = getUserName(user);
  const profession = user && user.profession ? user.profession : '';
  const userAvatar = user && user.avatar && user.avatar.thumb
    ? user.avatar.thumb
    : defaultAvatar;

  let price = TEXT_EMPTY;
  let refunds = TEXT_EMPTY;
  if (tickets && tickets.length > 0) {
    const minPrice = Math.min.apply(null,
      tickets.map(ticket => parseFloat(ticket.price, 10)));
    const maxPrice = Math.max.apply(null,
      tickets.map(ticket => parseFloat(ticket.price, 10)));
    price = minPrice === maxPrice
      ? getDollarsFromCents(minPrice)
      : `${getDollarsFromCents(minPrice)} - ${getDollarsFromCents(maxPrice)}`;
    refunds = tickets.some(ticket => !ticket.refunds) ? 'No refund' : 'With refund';
  }


  fanciersNumber = fanciersNumber < 1000
    ? fanciersNumber
    : `${Math.floor(fanciersNumber / 100) / 10}k`;

  let itemTypeLabel = 'Event info';
  switch (itemType) {
    case HOUSE_PARTY: {
      itemTypeLabel = HOUSE_PARTY_LABEL;
      break;
    }
    case FESTIVAL: {
      itemTypeLabel = FESTIVAL_LABEL;
      break;
    }
    case SOCIAL_WORKSHOP: {
      itemTypeLabel = SOCIAL_WORKSHOP_LABEL;
      break;
    }
    case PROGRESSIVE_SERIES: {
      itemTypeLabel = PROGRESSIVE_SERIES_LABEL;
      break;
    }
    default: {
      if (itemType) itemTypeLabel = capitalizeStr(itemType);
      break;
    }
  }

  return (
    <div className={styles.pad_bottom_20}>
      <h1 className={detectSafari() ? styles.title_safari : styles.title}>
        {title}
      </h1>
      <div className={detectSafari()
        ? styles.styles_container_safari
        : styles.styles_container}
      >
        {danceStyles
          && danceStyles.map(
            style => <span key={style} className={styles.style}>{style}</span>,
          )
        }
      </div>
      <PhotosBlock
        photos={photos}
        handleOpenFullScreen={handleOpenFullScreen}
        fanciersNumber={fanciersNumber}
        previewFromForm
      />
      <div className={styles.info_container}>
        <CalendarIcon className={styles.calendar_icon} />
        <DateInfo
          item={event}
        />
      </div>
      <div className={styles.info_container}>
        <PinIcon />
        <div className={styles.info}>
          <VenueInfo venueId={venueId} location={location} address={address} />
        </div>
      </div>
      <div className={styles.info_container}>
        <TicketIcon />
        <div className={styles.info}>
          <div className={styles.text_dark}>{price}</div>
          <div className={styles.text_light}>{refunds}</div>
        </div>
      </div>
      <div className={styles.info_container}>
        <InfoIcon className={styles.info_icon} />
        <div className={styles.info}>
          <p className={styles.text_dark}>{itemTypeLabel}</p>
          <ReadMore className={styles.text_light}>
            {description ? (
              <BreakLineText>{description}</BreakLineText>
            ) : TEXT_EMPTY}
          </ReadMore>
        </div>
      </div>
      <div className={styles.author_container}>
        <div className={styles.avatar} style={{ backgroundImage: `url(${userAvatar})` }} />
        <div className={styles.author}>
          <p className={`${styles.text_light} ${styles.text_small}`}>Event by</p>
          <p className={styles.text_dark}>{userName}</p>
          <p className={`${styles.text_dark} ${styles.text_extrasmall}`}>{profession}</p>
        </div>
      </div>
    </div>
  );
};

EventDetailsCutted.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    favorite: PropTypes.bool,
    status: PropTypes.oneOf([ACTIVE, INACTIVE]),
  }),
  setFSphotos: PropTypes.func.isRequired,
  setAndOpenModal: PropTypes.func.isRequired,
};

EventDetailsCutted.defaultProps = {
  event: null,
};

export default EventDetailsCutted;
