import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import onClickOutside from 'react-onclickoutside';
import styles from './user_menu.module.scss';
import defaultAvatar from '../../images/default_avatar.jpg';

class UserMenu extends Component {
  static propTypes = {
    user: PropTypes.shape({
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    signOut: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    token: PropTypes.string,
    notificationsCount: PropTypes.number.isRequired,
    getNotificationsCount: PropTypes.func.isRequired,
  }

  static defaultProps = {
    user: {
      avatar: null,
    },
    token: '',
  }

  state = {
    isOpen: false,
  }

  componentDidMount() {
    const { getNotificationsCount } = this.props;

    if (getNotificationsCount) {
      getNotificationsCount();
      this.notificationsInterval = setInterval(getNotificationsCount, 15000);
    }
  }

  handleOpen = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleClickOutside = () => {
    this.setState({
      isOpen: false,
    });
  }

  logoutUser = () => {
    const { token, signOut } = this.props;

    signOut(token);
  }

  render() {
    const {
      user: {
        avatar,
        first_name: firstName,
        last_name: lastName,
      },
      history,
      notificationsCount,
    } = this.props;
    const { isOpen } = this.state;
    const avatarPath = avatar ? avatar.thumb : defaultAvatar;
    let userName;
    if (firstName) {
      userName = (`${firstName}`).substring(0, 16);
    } else if (lastName) {
      userName = (`${lastName}`).substring(0, 16);
    }
    const menuClassname = classNames(styles.dropdown_wrapper, { [styles.is_open]: isOpen });

    return (
      <div
        className={menuClassname}
        onClick={this.handleOpen}
        onKeyPress={this.handleOpen}
        role="presentation"
      >
        <div className={styles.dropdown_title}>
          {userName ? (
            <MediaQuery minWidth={1200}>
              {userName}
            </MediaQuery>
          ) : null
          }
          <div
            className={styles.avatar}
            style={{ backgroundImage: `url(${avatarPath})` }}
          >
            {notificationsCount ? (
              <div className={styles.notification_counter}>
                {notificationsCount}
              </div>
            ) : null}
          </div>
        </div>
        { isOpen ? (
          <ul className={styles.dropdown_menu}>
            <li>
              <Link
                to={{
                  pathname: '/',
                  search: '?saved=true',
                  state: { from: history.location.pathname },
                }}
                className={styles.menu_item}
              >
                Saved
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: '/profile',
                  state: { from: history.location.pathname },
                }}
                className={styles.menu_item}
              >
                Profile
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: '/account',
                  state: { from: history.location.pathname },
                }}
                className={styles.menu_item}
              >
                Account
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: '/payment_history',
                  state: { from: history.location.pathname },
                }}
                className={styles.menu_item}
              >
                Payments
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: '/notifications',
                  state: { from: history.location.pathname },
                }}
                className={`${styles.menu_item} ${styles.dropdown_wrapper}`}
              >
                Notifications

                {notificationsCount ? (
                  <div className={styles.notification_counter_in_menu}>
                    {notificationsCount}
                  </div>
                ) : null}
              </Link>
            </li>
            <li>
              <button onClick={this.logoutUser} className={styles.menu_item} type="button">Logout</button>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

export default withRouter(onClickOutside(UserMenu));
