import { requestMySubscriptions } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { signOut } from '../login/login_actions';
import createError from '../Fetch_error/fetch_error_action';
import { showMarkers, fitMapBoundsToMarkers } from '../Map/map_actions';

import { SET_SUBSCRIPTION_TO_UPDATE, RESET_SUBSCRIPTION_TO_UPDATE, GET_MY_SUBSCRIPTIONS_SUCCESS } from '../../redux/action_types';


export const getSubscriptionsSuccess = items => ({
  type: GET_MY_SUBSCRIPTIONS_SUCCESS,
  payload: items,
});

export const getMySubscriptions = () => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());

  return requestMySubscriptions(token)
    .then((response) => {
      if (response.status === 200) {
        const subscriptions = response.data && response.data.data;
        dispatch(getSubscriptionsSuccess(subscriptions));
        const itemsForMapMarkers = [];
        if (subscriptions && subscriptions.length > 0) {
          subscriptions.forEach((subscription) => {
            const { owner } = subscription;
            if (owner) itemsForMapMarkers.push(owner);
          });
          dispatch(showMarkers(itemsForMapMarkers));
          dispatch(fitMapBoundsToMarkers());
        }
        return subscriptions;
      }
      dispatch(createError());
      return null;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) dispatch(signOut());
      dispatch(createError(error));
    })
    .finally(() => dispatch(finishLoading()));
};

export const setSubscriptionToUpdate = subscription => ({
  type: SET_SUBSCRIPTION_TO_UPDATE,
  payload: subscription,
});

export const resetSubscriptionToUpdate = () => ({
  type: RESET_SUBSCRIPTION_TO_UPDATE,
});
