import React from 'react';
import PropTypes from 'prop-types';

const FacebookLogo = ({ className }) => (
  className
    ? (
      <svg className={className} viewBox="0 -1 11 21" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.19034 19.9999V10.8769H10.2526L10.711 7.32157H7.19034V5.05157C7.19034 4.02223 7.47622 3.32068 8.95243 3.32068L10.8351 3.31981V0.139943C10.5094 0.0967723 9.39186 0 8.09172 0C5.37726 0 3.51889 1.65682 3.51889 4.69964V7.32167H0.44873V10.877H3.51879V20L7.19034 19.9999Z" />
      </svg>
    ) : (
      <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.19034 19.9999V10.8769H10.2526L10.711 7.32157H7.19034V5.05157C7.19034 4.02223 7.47622 3.32068 8.95243 3.32068L10.8351 3.31981V0.139943C10.5094 0.0967723 9.39186 0 8.09172 0C5.37726 0 3.51889 1.65682 3.51889 4.69964V7.32167H0.44873V10.877H3.51879V20L7.19034 19.9999Z" fill="#fff" />
      </svg>
    )
);

FacebookLogo.propTypes = {
  className: PropTypes.string,
};

FacebookLogo.defaultProps = {
  className: '',
};

export default FacebookLogo;
