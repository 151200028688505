import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import base64url from 'base64url';

import getNestedValue from '../../utils/getNestedValue';

export default class AppleIdVerify extends Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
    }).isRequired,
    getUserAfterAppleId: PropTypes.func.isRequired,
  };

  state = { error: null }

  componentDidMount() {
    const { history, getUserAfterAppleId } = this.props;

    const search = getNestedValue(history, 'location', 'search');
    if (search) {
      const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
      const {
        user_id: id,
        user_jwt: token,
        from_url: fromUrl64,
      } = queryObject;

      const from = base64url.decode(fromUrl64);

      if (id && token) {
        getUserAfterAppleId({ id, token, from });
      } else {
        this.setState({
          error: 'Sorry, somethin went wrong. Please try again later.',
        });
      }
    }
  }

  render() {
    const { error } = this.state;

    return (
      <div>
        {error || 'Now you will redirect...'}
      </div>
    );
  }
}
