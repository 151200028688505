import React from 'react';
import PropTypes from 'prop-types';

const NavigationIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 90" width="25" height="25" fill="#07E">
    <path fill="none" d="M0 0h88.17v89.585H0z" />
    <path fill="#07E" d="M86.713 1.455a5 5 0 0 0-5.61-1l-78.21 36.36a5 5 0 0 0 1.14 9.44l31.76 6.27 7.5 33.19a5 5 0 0 0 4.4 3.87h.48a5 5 0 0 0 4.57-3l35-79.58a5 5 0 0 0-1.03-5.55zm-37.61 73.62l-5.95-26.26a5 5 0 0 0-3.91-3.81l-24.63-4.84 62.65-29.07-28.16 63.98z" />
  </svg>
);

NavigationIcon.propTypes = {
  className: PropTypes.string,
};

NavigationIcon.defaultProps = {
  className: '',
};

export default NavigationIcon;
