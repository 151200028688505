import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import getUserName from '../../../utils/getUserName';

import styles from '../modal.module.scss';
import buttonStyles from '../../../styles/buttons.module.scss';


if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const DeleteFriendModal = (props) => {
  const { isModalOpen, closeModal, friendToDelete, deleteFriend } = props;
  const friendName = getUserName(friendToDelete);
  const handleDeleteFriend = () => {
    const friendId = friendToDelete && friendToDelete.id;
    deleteFriend(friendId).then(() => { closeModal(); });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className={styles.content}
      overlayClassName={styles.overlay}
    >
      <div className={styles.content_box}>
        <h4 className={styles.title}>Are you sure?</h4>
        <div className={styles.message_box}>
          Remove {friendName} as a friend?
        </div>
        <div className={buttonStyles.buttons_row}>
          <button
            type="button"
            className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
            onClick={handleDeleteFriend}
          >
            Remove
          </button>
          <button
            type="button"
            className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

DeleteFriendModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  friendToDelete: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.shape({
      full: PropTypes.string,
      thumb: PropTypes.string,
    }),
    nickname: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    profession: PropTypes.string,
  }).isRequired,
  deleteFriend: PropTypes.func.isRequired,
};

export default DeleteFriendModal;
