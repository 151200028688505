import { connect } from 'react-redux';
import { editAvatar } from '../login/login_actions';
import { setModalMessage, setModalTitle, setAndOpenModal } from '../Modal/modal_actions';

import AvatarEditor from './Avatar_editor';

const mSTP = state => ({
  image: state.session.user.avatar,
  id: state.session.user.id,
});

const mDTP = {
  editAvatar,
  setModalMessage,
  setModalTitle,
  setAndOpenModal,
};

export default connect(mSTP, mDTP)(AvatarEditor);
