import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as loadImage from 'blueimp-load-image';
import PhotoCrop from '../shared/Photo_crop/Photo_crop';

import styles from './avatar_editor.module.scss';
import Camera from '../icons/Camera';
import CropIcon from '../icons/Crop_icon';
import defaultAvatar from '../../images/default_avatar.jpg';
import { MAX_FILE_SIZE } from '../../config';

const acceptedTypes = ['image/png', 'image/jpg', 'image/jpeg'];

const INITIAL_STATE = {
  file: null,
  url: '',
  isCropOpened: false,
  srcToCrop: null,
  error: '',
};

class AvatarEditor extends Component {
  static propTypes = {
    image: PropTypes.shape({
      full: PropTypes.string,
      thumb: PropTypes.string,
    }),
    editAvatar: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    setModalMessage: PropTypes.func.isRequired,
    setModalTitle: PropTypes.func.isRequired,
    setAndOpenModal: PropTypes.func.isRequired,
    renderInModal: PropTypes.bool,
    updateUser: PropTypes.func,
  }

  static defaultProps = {
    image: null,
    renderInModal: false,
    updateUser: null,
  }

  constructor() {
    super();
    this.avatarRef = React.createRef();
  }

  state = { ...INITIAL_STATE };

  handleFileUpload = (e) => {
    e.preventDefault();
    this.avatarRef.current.click();
  }

  handleFileChange = (e) => {
    const { files } = e.target;
    if (files.length === 0) return;
    const fileType = files[0].type;
    if (acceptedTypes.includes(fileType)) {
      if (files[0].size > MAX_FILE_SIZE) {
        const { setModalMessage, setModalTitle, setAndOpenModal, renderInModal } = this.props;
        if (renderInModal) {
          this.setState({ error: 'Max file size 10Mb' });
        } else {
          setModalTitle('Ups!');
          setModalMessage('Max file size 10Mb');
          setAndOpenModal('SUCCES_MODAL_MESSAGE');
        }
      } else {
        const loadImageOptions = { canvas: true };

        loadImage.parseMetaData(files[0], (data) => {
          if (data.exif && data.exif.get('Orientation')) {
            loadImageOptions.orientation = data.exif.get('Orientation');
          }
          loadImage(files[0], (canvas) => {
            const preview = canvas.toDataURL(files[0].type);
            this.setState({
              url: preview,
              file: files[0],
              error: '',
            });
          }, loadImageOptions);
        });
      }
    }
  }

  handleOpenCrop = () => {
    const { image } = this.props;
    const { url } = this.state;
    const src = url || (image && image.full);
    this.setState({
      isCropOpened: true,
      srcToCrop: src,
    });
  }

  handleCancelCrop = () => {
    const { srcToCrop } = this.state;
    this.setState({
      isCropOpened: false,
      url: srcToCrop,
    });
  }

  handlePreviewCrop = (previewImage) => {
    if (window.innerWidth > 767) this.setState({ url: previewImage });
  }

  handleApplyCrop = ({ croppedImage }) => {
    this.setState({
      file: croppedImage.file,
      isCropOpened: false,
    });
    if (window.innerWidth <= 767) this.setState({ url: croppedImage.url });
  }

  handleResetState = () => {
    this.avatarRef.current.value = '';
    this.setState({ ...INITIAL_STATE });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();

    const {
      editAvatar,
      id,
      updateUser,
    } = this.props;
    const { file } = this.state;
    const avatarData = new FormData();
    if (file && file.name) {
      avatarData.append('data[avatar]', file, file.name);
    } else avatarData.append('data[avatar]', file);

    editAvatar({ id, avatar: avatarData })
      .then(() => {
        if (updateUser && typeof updateUser === 'function') {
          updateUser();
        }
      })
      .finally(() => { this.handleResetState(); });
  }

  render() {
    const { url, isCropOpened, srcToCrop, error } = this.state;
    const { image } = this.props;
    let userAvatar = image ? image.full : defaultAvatar;
    if (url) userAvatar = url;

    return (
      <>
        <form onSubmit={this.updateAvatar} className={styles.form}>
          {url && (
            <div
              className={styles.crop_wrapper}
              onClick={this.handleOpenCrop}
              onKeyPress={this.handleOpenCrop}
              role="presentation"
            >
              <CropIcon className={styles.crop} />
            </div>
          )}
          <div className={styles.avatar} style={{ backgroundImage: `url(${userAvatar}` }}>
            <input
              type="file"
              name="avatar"
              onChange={this.handleFileChange}
              ref={this.avatarRef}
              style={{ display: 'none' }}
              accept="image/*"
            />
            {url ? (
              <div className={styles.button_wrapper}>
                <div
                  className={styles.btn_apply}
                  onClick={this.handleFormSubmit}
                  onKeyPress={this.handleFormSubmit}
                  role="button"
                  tabIndex={0}
                >
                  Apply
                </div>
                <div
                  className={styles.btn_cancel}
                  onClick={this.handleResetState}
                  onKeyPress={this.handleResetState}
                  role="button"
                  tabIndex={0}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <button className={styles.edit_photo} onClick={this.handleFileUpload} type="button">
                <Camera />
              </button>
            )}
          </div>
          {error && <div className={styles.error}>{error}</div>}
        </form>
        {isCropOpened && (
          <PhotoCrop
            src={srcToCrop}
            onPreviewCrop={this.handlePreviewCrop}
            onCancelCrop={this.handleCancelCrop}
            onApplyCrop={this.handleApplyCrop}
            aspect={1}
          />
        )}
      </>
    );
  }
}

export default AvatarEditor;
