import {
  requestUserNotifications,
  requestDeleteNotification,
  requestUpdateAllNotifications,
  requestMarkAsRead,
  requestNotificationsCount,
} from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';

import {
  GET_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATIONS_COUNT,
} from '../../redux/action_types';

export const getNotificationsCount = () => async (dispatch, getState) => {
  const state = getState();
  const token = state && state.session && state.session.token;
  const prevCount = state && state.notificationsCount;

  if (token) {
    const res = await requestNotificationsCount(token)
      .catch(err => createError(err));

    const count = res && res.data && res.data.amount;

    if ((count || count === 0) && count !== prevCount) {
      dispatch({
        type: UPDATE_NOTIFICATIONS_COUNT,
        payload: count,
      });
    }
  }
};

export const getNotifications = () => async (dispatch, getState) => {
  const { token } = getState().session;

  if (token) {
    const res = await requestUserNotifications(token)
      .catch(err => createError(err));


    if (res && res.status === 200 && res.data) {
      dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: res.data.data,
      });
    }

    return res;
  }

  return null;
};

export const deleteNotification = id => async (dispatch, getState) => {
  const { token } = getState().session;

  if (token) {
    dispatch(startLoading());
    const result = await requestDeleteNotification({ token, id })
      .catch(err => createError(err))
      .finally(() => dispatch(finishLoading()));
    if (result && result.status === 204) {
      dispatch({
        type: DELETE_NOTIFICATION,
        payload: id,
      });
    }
  }
};

export const updateAllNotifications = status => async (dispatch, getState) => {
  const { token } = getState().session;

  if (token) {
    dispatch(startLoading());
    const res = await requestUpdateAllNotifications({ token, status })
      .catch(err => createError(err))
      .finally(() => dispatch(finishLoading()));

    if (res && res.status === 204) {
      getNotifications()(dispatch, getState);
      getNotificationsCount()(dispatch, getState);
    }

    return res;
  }

  return null;
};

export const markNotificationRead = id => async (dispatch, getState) => {
  const { token } = getState().session;

  if (token) {
    dispatch(startLoading());
    const result = await requestMarkAsRead({ token, id })
      .catch(err => createError(err))
      .finally(() => dispatch(finishLoading()));
    if (result && result.status === 204) {
      dispatch({
        type: UPDATE_NOTIFICATION,
        payload: { status: 'read' },
      });
    }
  }
};
