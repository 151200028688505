import { requestAutoComplete, requestSearch } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { showMarkers, fitMapBoundsToMarkers } from '../Map/map_actions';
import createError from '../Fetch_error/fetch_error_action';
import { GET_SEARCH_SUCCESS, RESET_LIST } from '../../redux/action_types';

export const getAutoComplete = query => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestAutoComplete(query, token)
    .then(response => response.data)
    .catch(error => dispatch(createError(error)))
    .finally(() => dispatch(finishLoading()));
};

export const getSearchSuccess = items => ({
  type: GET_SEARCH_SUCCESS,
  payload: items,
});

export const getSearch = query => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestSearch(query, token)
    .then((response) => {
      const receivedItems = response && response.data && response.data.data;
      dispatch(getSearchSuccess(receivedItems));
      dispatch(showMarkers(receivedItems));
      dispatch(fitMapBoundsToMarkers());
      return receivedItems;
    })
    .catch(error => dispatch(createError(error)))
    .finally(() => dispatch(finishLoading()));
};

export const resetSearchList = () => ({
  type: RESET_LIST,
});
