import { getOrdersHistory } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';


const getPaymentHistory = () => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());

  return getOrdersHistory(token)
    .then(res => res.data && res.data.data)
    .catch(err => createError(err))
    .finally(() => dispatch((finishLoading())));
};

export default getPaymentHistory;
