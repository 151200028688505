import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import defaultAvatar from '../../images/default_avatar.jpg';
import styles from './hosting.module.scss';
import PlusIcon from '../icons/Plus_simple';
import { HOST, COHOST } from '../../config';

const UserItem = ({ user, inviteUser, history }) => {
  if (!user) return null;
  const {
    first_name: firstName,
    last_name: lastName,
    nickname,
    hosting_status: hostingStatus,
    id,
  } = user;

  const avatarPath = user.avatar && user.avatar.thumb
    ? user.avatar.thumb : defaultAvatar;

  let secondaryName = '';
  if (firstName) secondaryName += firstName;
  if (lastName) secondaryName += ` ${lastName}`;

  const mainName = nickname || secondaryName || 'Anonymous';

  const inviteCurrentUser = () => {
    inviteUser(user);
  };

  const path = {
    pathname: `/users/${id}`,
    state: { from: history.location.pathname },
  };

  return (
    <div className={styles.cohoster_wrapper}>
      <Link to={path}>
        <div
          className={styles.avatar}
          style={{ backgroundImage: `url(${avatarPath})` }}
        />
      </Link>
      <div>
        <Link to={path}>
          <p>{mainName}</p>
          {nickname ? (
            <p className={styles.secondary_name}>{secondaryName}</p>
          ) : null}
        </Link>
        {hostingStatus !== HOST && hostingStatus !== COHOST && (
          <div className={styles.hoster_status}>
            {hostingStatus}
          </div>
        )}
      </div>

      <button
        type="button"
        className={styles.plus_button}
        onClick={inviteCurrentUser}
      >
        <PlusIcon className={styles.plus_icon} />
      </button>
    </div>
  );
};

UserItem.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    nickname: PropTypes.string,
  }).isRequired,
  inviteUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(UserItem);
