import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { withRouter } from 'react-router-dom';
import buttonStyles from '../../styles/buttons.module.scss';
import styles from './create_menu.module.scss';
import CancelIcon from '../icons/Cancel_icon';


class CreateMenu extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  }

  state = {
    isOpen: false,
  }

  handleToggleOpen = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  }

  handleClickOutside = () => {
    this.setState({
      isOpen: false,
    });
  }

  goToLink = (e) => {
    const link = e.target.getAttribute('to');

    this.handleClose();
    const { history } = this.props;
    history.push(link);
  }

  render() {
    const { isOpen } = this.state;
    const buttonClass = classNames(
      buttonStyles.button,
      buttonStyles.btn_black,
      buttonStyles.btn_min_170,
    );
    const { handleToggleOpen, handleClose, goToLink } = this;

    return (
      <div className={styles.menu_wrapper}>
        <div
          className={styles.create_btn}
          onClick={handleToggleOpen}
          onKeyPress={handleToggleOpen}
          role="button"
          tabIndex="0"
        >
          <span className={styles.plus_icon}>+</span>&nbsp;
          <MediaQuery minWidth={768}>Create</MediaQuery>
        </div>
        {isOpen ? (
          <>
            <ul className={styles.drop_down}>
              <li className={styles.dropdown_item}>
                <button className={buttonClass} to="/create_event" onClick={goToLink} type="button">
                  + Create Event
                </button>
              </li>
              <li className={styles.dropdown_item}>
                <button className={buttonClass} to="/create_venue" onClick={goToLink} type="button">
                  + Create Venue
                </button>
              </li>
              <li className={styles.dropdown_item}>
                <button className={buttonClass} to="/create_teacher" onClick={goToLink} type="button">
                  + Create Teacher
                </button>
              </li>
              <MediaQuery maxWidth={767}>
                <li className={styles.dropdown_item}>
                  <button onClick={handleClose} type="button">
                    <CancelIcon />
                  </button>
                </li>
              </MediaQuery>
            </ul>
            <div className={styles.overlay} />
          </>
        ) : null
        }
      </div>
    );
  }
}

export { CreateMenu as Menu };

export default withRouter(onClickOutside(CreateMenu));
