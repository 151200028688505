import { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

class Dropdown extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  }

  state = {
    isOpen: false,
  }

  handleClickOutside = () => {
    this.setState({ isOpen: false });
  }

  handleToggle = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;

    return (
      this.props.children(isOpen, this.handleToggle, this.handleOpen, this.handleClose)
    );
  }
}

export default onClickOutside(Dropdown);
