import axios from 'axios';

export const requestHostingEvents = token => (
  axios.get(
    '/api/events/hostings',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestPastHostEvents = token => (
  axios.get(
    '/api/events/hostings?past=true',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestHostingClasses = token => (
  axios.get(
    '/api/lessons/hostings',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestPastHostClasses = token => (
  axios.get(
    '/api/lessons/hostings?past=true',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestEventHostingList = (token, id) => (
  axios.get(
    `/api/events/${id}/hostings`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestClassHostingList = (token, id) => (
  axios.get(
    `/api/lessons/${id}/hostings`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const searchHostersForEvent = (eventId, query, token) => (
  axios.get(
    `/api/events/${eventId}/hostings/search?q=${query}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const searchHostersForLesson = (lessonId, query, token) => (
  axios.get(
    `/api/lessons/${lessonId}/hostings/search?q=${query}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const inviteUserToCohostEvent = ({ itemId, userId, token, permissions }) => (
  axios.post(
    `/api/events/${itemId}/hostings`,
    { data: { user_id: userId, permissions } },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const inviteUserToCohostLesson = ({ itemId, userId, token, permissions }) => (
  axios.post(
    `/api/lessons/${itemId}/hostings`,
    { data: { user_id: userId, permissions } },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const acceptCohostingEvent = ({ itemId, token }) => (
  axios.patch(
    `/api/events/${itemId}/hostings/accept`,
    null,
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const acceptCohostingLesson = ({ itemId, token }) => (
  axios.patch(
    `/api/lessons/${itemId}/hostings/accept`,
    null,
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const cancelCohostingEvent = ({ itemId, token }) => (
  axios.patch(
    `/api/events/${itemId}/hostings/cancel`,
    null,
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const cancelCohostingLesson = ({ itemId, token }) => (
  axios.patch(
    `/api/lessons/${itemId}/hostings/cancel`,
    null,
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const removeCohosterFromEvent = ({ userId, itemId, token }) => (
  axios.patch(
    `/api/events/${itemId}/hostings/remove`,
    { data: { user_id: userId } },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const removeCohosterFromLesson = ({ userId, itemId, token }) => (
  axios.patch(
    `/api/lessons/${itemId}/hostings/remove`,
    { data: { user_id: userId } },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);
