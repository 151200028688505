export const appleAuthInit = (options) => {
  if (!options) return;
  const { CLIENT_ID, SCOPES, REDIRECT_URI } = options;
  if (CLIENT_ID && SCOPES && REDIRECT_URI && window.AppleID) {
    window.AppleID.auth.init({
      clientId: options.CLIENT_ID,
      scope: options.SCOPES,
      redirectURI: options.REDIRECT_URI,
      state: options.STATE,
    });
  }
};

export const loadAppleScript = (options) => {
  if (window.AppleID) return false;
  const appleScript = document.createElement('script');
  appleScript.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  document.head.appendChild(appleScript);

  if (appleScript.readyState) { // only required for IE <9
    appleScript.onreadystatechange = () => {
      if (appleScript.readyState === 'loaded' || appleScript.readyState === 'complete') {
        appleScript.onreadystatechange = null;
        appleAuthInit(options);
      }
    };
  } else { // Others
    appleScript.addEventListener('load', () => {
      appleAuthInit(options);
    });
  }
  return true;
};
