import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import qs from 'qs';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import { animateScroll } from 'react-scroll';

import ListItem from './List_item';
import ViewList from '../icons/View_list';
import Filter from '../Filter/filter_container';

import ViewThumbnails from '../icons/View_thumbnails';
import StarIcon from '../icons/Star';
import CancelIcon from '../icons/Cancel_icon';
import ArrowUp from '../icons/Arrow_up';

import styles from './list.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import navStyles from '../../styles/navigation.module.scss';
import typographyStyles from '../../styles/common.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

import {
  EVENTS, VENUES, TEACHERS, SEARCH_LIST, LIST_FILTERS,
} from '../../config';

const EVENTS_PATHS = ['/events', '/account/edit_event/'];
const VENUES_PATHS = ['/venues', '/account/edit_venue/'];
const TEACHERS_PATHS = [
  '/teachers', '/account/edit_teacher/', '/classes', '/edit_class',
  '/passes', '/edit_pass',
];

const checkEventsListIsActive = location => (location.pathname === '/create_event'
|| location.pathname === '/' || EVENTS_PATHS.some(path => location.pathname.indexOf(path) !== -1));

const checkVenuesListIsActive = location => (location.pathname === '/create_venue'
|| VENUES_PATHS.some(path => location.pathname.indexOf(path) !== -1));

const checkTeachersListIsActive = location => (location.pathname === '/create_teacher'
|| TEACHERS_PATHS.some(path => location.pathname.indexOf(path) !== -1));

const activateEventsList = (match, location) => checkEventsListIsActive(location);

const activateVenuesList = (match, location) => checkVenuesListIsActive(location);

const activateTeachersList = (match, location) => checkTeachersListIsActive(location);

class List extends Component {
  static propTypes = {
    list: PropTypes.oneOf([EVENTS, VENUES, TEACHERS, SEARCH_LIST]),
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    getItemsList: PropTypes.func.isRequired,
    saveScrollPosition: PropTypes.func.isRequired,
    token: PropTypes.string,
    scrollPosition: PropTypes.number.isRequired,
    isListViewActive: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
    handleToggleListView: PropTypes.func.isRequired,
    toggleFavorite: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    setHoverId: PropTypes.func.isRequired,
  };

  static defaultProps = {
    history: null,
    location: null,
    token: '',
    list: '',
  }

  state = {
    isSavedViewActive: false,
    isLoading: false,
    showArrowUp: false,
    serverTime: null,
  }

  checkArrowShow = throttle(() => {
    const showArrowUp = (this.wrapperRef.current && this.wrapperRef.current.scrollTop > 20)
    || window.scrollY > 20;

    this.setState({ showArrowUp });
  }, 500);

  constructor() {
    super();

    this.wrapperRef = createRef();
  }

  componentDidMount() {
    this.getList();
    const { scrollPosition } = this.props;
    window.scrollTo(0, scrollPosition);
    window.addEventListener('scroll', this.checkArrowShow);
    if (this.wrapperRef && this.wrapperRef.current) {
      this.wrapperRef.current.addEventListener('scroll', this.checkArrowShow);
    }
  }

  componentDidUpdate(prevProps) {
    const { token, location } = this.props;
    const search = location && location.search;
    const searchObject = location && location.search
      && qs.parse(location.search, { ignoreQueryPrefix: true });
    const searchKeys = searchObject && Object.keys(searchObject);
    const relevantSearch = (searchKeys && searchKeys.some(key => LIST_FILTERS.includes(key)))
      || search === '';
    const currentlist = this.getListName(location);
    const prevlist = this.getListName(prevProps.location);

    if ((token !== prevProps.token) || (prevlist !== currentlist)) {
      this.getList();
      window.scrollTo(0, 0);
    }

    if (search !== prevProps.location.search && relevantSearch) {
      this.getList();
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    const { saveScrollPosition } = this.props;
    saveScrollPosition(window.pageYOffset);

    window.removeEventListener('scroll', this.checkArrowShow);
    if (this.wrapperRef && this.wrapperRef.current) {
      this.wrapperRef.current.removeEventListener('scroll', this.checkArrowShow);
    }
  }

  getListName = (location) => {
    let list;
    if (checkEventsListIsActive(location)) list = EVENTS;
    if (checkVenuesListIsActive(location)) list = VENUES;
    if (checkTeachersListIsActive(location)) list = TEACHERS;
    if (location.pathname === '/search') list = SEARCH_LIST;
    return list;
  }

  getList = () => {
    const { getItemsList, history } = this.props;
    const { location } = history;
    if (!location) return;

    const list = this.getListName(location);
    if (!list) return;

    this.setState({ isLoading: true });

    const queryObject = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryObject.saved === 'true') {
      this.setState({ isSavedViewActive: true });
    } else this.setState({ isSavedViewActive: false });

    getItemsList({ list, filter: location.search })
      .then((res) => {
        if (res && res.headers && res.headers.date) {
          this.setState({ serverTime: res.headers.date });
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  handleToggleSavedView = () => {
    const { history } = this.props;
    const { isSavedViewActive } = this.state;
    this.setState({ isSavedViewActive: !isSavedViewActive });
    const to = {
      pathname: history.location.pathname,
      search: isSavedViewActive ? '' : '?saved=true',
      state: { from: history.location.pathname },
    };
    history.push(to);
  }

  scrollToTop = () => {
    if (this.wrapperRef.current) this.wrapperRef.current.scrollTop = 0;

    animateScroll.scrollTo(
      0, { duration: 1000, smooth: true },
    );
  }

  renderNav = () => {
    const { history } = this.props;
    let queryString = history && history.location && history.location.search
      ? history.location.search
      : '';
    let eventQueryString = queryString;

    if (queryString) {
      const queryObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });

      queryString = qs.stringify({
        ...queryObject,
        day: undefined,
        sort: queryObject.sort === 'date' ? undefined : queryObject.sort,
        event_type: undefined,
        start_date: undefined,
        end_date: undefined,
      });

      eventQueryString = qs.stringify({
        ...queryObject,
        day: undefined,
        sort: queryObject.sort === 'date' ? undefined : queryObject.sort,
        weekday: undefined,
      });
    }
    return (
      <nav>
        <ul className={navStyles.container}>
          <li className={navStyles.block}>
            <NavLink
              className={navStyles.item}
              activeClassName={navStyles.item_active}
              isActive={activateEventsList}
              exact
              to={{
                pathname: '/',
                search: eventQueryString,
              }}
            >
              Events
            </NavLink>
          </li>
          <li className={navStyles.block}>
            <NavLink
              className={navStyles.item}
              activeClassName={navStyles.item_active}
              isActive={activateVenuesList}
              exact
              to={{
                pathname: '/venues',
                search: queryString,
              }}
            >
              Venues
            </NavLink>
          </li>
          <li className={navStyles.block}>
            <NavLink
              className={navStyles.item}
              activeClassName={navStyles.item_active}
              isActive={activateTeachersList}
              exact
              to={{
                pathname: '/teachers',
                search: queryString,
              }}
            >
              Teachers
            </NavLink>
          </li>
        </ul>
      </nav>
    );
  };

  render() {
    const {
      history,
      token,
      list,
      handleToggleListView,
      isListViewActive,
      items,
      toggleFavorite,
      openModal,
      setHoverId,
    } = this.props;
    const {
      isSavedViewActive,
      isLoading,
      showArrowUp,
      serverTime,
    } = this.state;
    const listIconClass = isListViewActive ? styles.light_grey : styles.dark_grey;
    const thumbnailsIconClass = isListViewActive ? styles.dark_grey : styles.light_grey;

    const isListNotNested = list !== SEARCH_LIST && !history.location.pathname.includes('/my_hostings');

    const wrapperClass = classNames({
      [gridStyles.side_bar]: isListNotNested,
      [styles.wrapper]: isListNotNested,
      [gridStyles.mobile_on_top]: isSavedViewActive,
    });

    return (
      <div className={wrapperClass} ref={this.wrapperRef}>
        {(isSavedViewActive && token)
        && (
          <>
            <h3 className={typographyStyles.title}>Saved</h3>
            <button
              type="button"
              className={buttonStyles.cancel_button}
              onClick={this.handleToggleSavedView}
            >
              <CancelIcon />
            </button>
          </>
        )}
        {isListNotNested && this.renderNav()}
        <div className={(isSavedViewActive || list === SEARCH_LIST)
          ? styles.controllers_container_without_filter
          : styles.controllers_container}
        >
          {isListNotNested && !isSavedViewActive
            && <Filter />}
          <div className={styles.controllers_view}>
            {list !== SEARCH_LIST && token && (
            <div
              onClick={this.handleToggleSavedView}
              role="presentation"
              className={styles.controllers_item}
            >
              <StarIcon
                className={isSavedViewActive ? styles.star_active : styles.star}
                transparent={!isSavedViewActive}
              />
            </div>
            )}
            <button
              type="button"
              onClick={handleToggleListView}
              className={styles.controllers_item}
            >
              <ViewThumbnails className={listIconClass} />
            </button>
            <button
              type="button"
              onClick={handleToggleListView}
              className={styles.controllers_item}
            >
              <ViewList className={thumbnailsIconClass} />
            </button>
          </div>
        </div>
        {isLoading ? null : (
          <ul>
            {items && items.length > 0
              ? items.map(item => (
                <ListItem
                  item={item}
                  isListViewActive={isListViewActive}
                  history={history}
                  list={list}
                  key={item.id}
                  token={token}
                  toggleFavorite={toggleFavorite}
                  openModal={openModal}
                  serverTime={serverTime}
                  setHoverId={setHoverId}
                />
              ))
              : <li>Nothing found</li>
            }
          </ul>
        )}
        {showArrowUp ? (
          <button type="button" className={buttonStyles.arrow_up_btn} onClick={this.scrollToTop}>
            <ArrowUp className={buttonStyles.arrow_up} />
          </button>
        ) : null}
      </div>
    );
  }
}

export default List;
