import React from 'react';

const EyeHidden = () => (
  <svg width="25" height="20" viewBox="0 0 93 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path fillRule="evenodd" clipRule="evenodd" d="M46 48.7095C41.6891 48.7095 38.1821 45.2023 38.1821 40.8916C38.1821 36.5809 41.6891 33.0737 46 33.0737C50.3109 33.0737 53.8179 36.5809 53.8179 40.8916C53.8179 45.2023 50.3109 48.7095 46 48.7095ZM89 32C88.1804 31.2565 70.6445 15 46 15C21.3555 15 -0.5 34 2.50002 33L9.00002 40C9.00002 40.5 19.8104 29.9821 35.6136 26.3203C31.0668 29.5705 28.0945 34.889 28.0945 40.8916C28.0945 50.7646 36.127 58.7971 46 58.7971C55.873 58.7971 63.9055 50.7646 63.9055 40.8916C63.9055 34.889 60.9332 29.5705 56.3865 26.3203C72.1897 29.9818 83 40 83 40L89 32Z" fill="#0077EE" />
      <circle cx="86.0711" cy="36.0711" r="5" transform="rotate(45 86.0711 36.0711)" fill="#0077EE" />
      <path d="M9.60661 39.6066C7.65399 41.5592 4.48816 41.5592 2.53554 39.6066C0.58292 37.654 0.58292 34.4882 2.53554 32.5355C4.48816 30.5829 7.65399 30.5829 9.60661 32.5355C11.5592 34.4882 11.5592 37.654 9.60661 39.6066Z" fill="#0077EE" />
      <rect x="14.4142" y="66.5686" width="88" height="8" rx="4" transform="rotate(-45 14.4142 66.5686)" fill="#0077EE" stroke="white" strokeWidth="2" />
    </g>
  </svg>
);

export default EyeHidden;
