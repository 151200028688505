import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import List from '../List/list_container';

import { setItemUrl } from '../../utils/parse';

import CancelIcon from '../icons/Cancel_icon';
import SearchIcon from '../icons/Search_icon';
import DancerIcon from '../icons/Dancer';
import MarkerIcon from '../icons/Marker';
import PersonIcon from '../icons/Person';

import styles from './search.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import typographyStyles from '../../styles/common.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import inputStyles from '../../styles/inputs.module.scss';

import {
  EVENT, VENUE, LESSON, TEACHER,
} from '../../config';

const INITIAL_STATE = {
  searchText: '',
  autoCompleteList: [],
  queryText: '',
  areResultsShown: false,
  areAutoCompleteResultsShown: false,
};

class Search extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.string,
      }),
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string,
    }).isRequired,
    getAutoComplete: PropTypes.func.isRequired,
    getSearch: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
    resetSearchList: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.setAutoFocus();
    const { location } = this.props;
    if (location && location.state && location.state.query) {
      const { query } = location.state;
      this.setState({ searchText: query });
      this.searchRequest(query);
      return;
    }
    if (location && location.search) {
      const queryObject = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (queryObject.s) {
        this.searchRequest(queryObject.s);
        this.setState({ searchText: queryObject.s });
      }
    }
  }

  componentWillUnmount() {
    const { resetSearchList } = this.props;
    resetSearchList();
  }

  setAutoFocus = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  closeSearch = () => {
    const { history, location } = this.props;
    const from = location.state && location.state.from ? location.state.from : '/';
    history.push(from);
  }

  resetSearch = () => {
    this.setState({
      searchText: '',
      autoCompleteList: [],
    });
    this.setAutoFocus();
  }

  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value });
    const { getAutoComplete } = this.props;
    if (value.trim().length > 0) {
      getAutoComplete(value)
        .then((data) => {
          if (data && data.data) {
            this.setState({
              autoCompleteList: data.data,
              areAutoCompleteResultsShown: true,
            });
          }
        });
    } else {
      this.setState({
        autoCompleteList: [],
        areAutoCompleteResultsShown: false,
      });
    }
  };

  handleBLur = (e) => {
    if (e && e.relatedTarget && e.relatedTarget.className.indexOf('inputs_autocomplete_link') !== -1) {
      return;
    }
    this.setState({ areAutoCompleteResultsShown: false });
  }

  searchRequest = (query) => {
    const { getSearch, history, match } = this.props;
    getSearch(query)
      .then((data) => {
        if (data) {
          history.push(`${match.path}?s=${query}`);
          this.setState({
            areResultsShown: true,
            queryText: query,
            autoCompleteList: [],
            areAutoCompleteResultsShown: false,
          });
          this.handleBLur();
        }
      });
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    const query = this.state.searchText;
    if (query.trim().length > 0) {
      this.searchRequest(query);
      if (this.inputRef && this.inputRef.current) {
        this.inputRef.current.blur();
      }
    }
  }

  handleAutoCompleteSelect = (e) => {
    const { history } = this.props;
    const { searchText } = this.state;
    const { id, type } = e.currentTarget && e.currentTarget.dataset;
    const to = {
      pathname: (id && type) ? setItemUrl({ id, type }) : '/search',
      state: {
        from: history.location.pathname,
        query: searchText,
      },
    };
    history.push(to);
  }

  render() {
    const {
      searchText,
      queryText,
      autoCompleteList,
      areResultsShown,
      areAutoCompleteResultsShown,
    } = this.state;
    const { history, location, items } = this.props;

    const showCancelButton = searchText.length > 0;

    return (
      <div className={`${gridStyles.side_bar} ${styles.container}`}>
        <h3 className={typographyStyles.page_title}>Search</h3>
        <button
          type="button"
          className={buttonStyles.cancel_button}
          onClick={this.closeSearch}
        >
          <CancelIcon />
        </button>
        <div className={styles.search_wrapper}>
          <form className={styles.search_form} onSubmit={this.handleSearchSubmit}>
            <input
              className={styles.input}
              name="searchText"
              value={searchText}
              onChange={this.handleInputChange}
              onBlur={this.handleBLur}
              autoComplete="off"
              type="text"
              ref={this.inputRef}
            />
            {showCancelButton && (
              <button
                type="button"
                className={buttonStyles.close_round_wrapper}
                onClick={this.resetSearch}
              >
                <div className={buttonStyles.close_round}>
                  <CancelIcon className={buttonStyles.close_round_svg} />
                </div>
              </button>
            )}
            <SearchIcon className={styles.search} onClick={this.handleSearchSubmit} />
          </form>
          {areAutoCompleteResultsShown
              && (
              <ul className={inputStyles.autocomplete}>
                {autoCompleteList.map(({ id, title, type }) => (
                  <li key={id} className={inputStyles.autocomplete_item}>
                    {(type === EVENT || type === LESSON)
                      && <DancerIcon className={inputStyles.autocomplete_icon} />}
                    {type === VENUE
                      && <MarkerIcon className={inputStyles.autocomplete_icon} />}
                    {type === TEACHER
                      && <PersonIcon className={inputStyles.autocomplete_icon_person} />}
                    <button
                      className={inputStyles.autocomplete_link}
                      type="button"
                      onMouseDown={this.handleAutoCompleteSelect}
                      data-id={id}
                      data-type={type}
                    >
                      {title}
                    </button>
                  </li>
                ))}
              </ul>
              )
          }
        </div>
        {areResultsShown && (
          <>
            <div className={styles.text_grey}>
              {items.length === 1
                ? '1 result found for'
                : `${items.length} results found for`
              }
            </div>
            <div className={styles.text_black}>
              {queryText}
            </div>
            {items.length > 0
              && <List history={history} location={location} />
            }
          </>
        )}
      </div>
    );
  }
}

export default Search;
