import React from 'react';
import PropTypes from 'prop-types';
import UserLink from '../../shared/User_link/User_link';
import styles from './transfer.module.scss';

const UserItem = ({ user, selectUser, checked }) => {
  if (!user) return null;

  const handleSelectUser = () => {
    selectUser(user);
  };

  return (
    <div className={styles.user_item}>
      <UserLink user={user} />
      <input
        type="radio"
        checked={checked}
        onChange={handleSelectUser}
        // className={styles.radio}
        name="new_owner"
        value={user.id}
      />
    </div>
  );
};

UserItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  selectUser: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

UserItem.defaultProps = {
  checked: false,
};

export default UserItem;
