import { SET_SUBSCRIPTION_TO_CHECKOUT, RESET_SUBSCRIPTION_TO_CHECKOUT } from '../../redux/action_types';

export const setSubscriptionToCheckout = subscription => ({
  type: SET_SUBSCRIPTION_TO_CHECKOUT,
  payload: subscription,
});

export const resetSubscriptionToCheckout = () => ({
  type: RESET_SUBSCRIPTION_TO_CHECKOUT,
});
