import React from 'react';
import PropTypes from 'prop-types';

const AddFriend = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 42 37">
    <path fillRule="evenodd" d="M29.25 31.228h-7.11c.043-.315.098-.627.098-.954 0-3.242-2.188-5.952-5.18-6.865.305-.127.609-.261.914-.387l-.014-.001c.088-.036.176-.068.263-.104.204-.084.406-.173.61-.258.812-.335 1.638-.65 2.446-.998a.69.69 0 0 0 .334-.342c.183-.452.333-.902.478-1.366.112-.349.178-.69.636-.787.041-.008.084-.054.127-.097a.463.463 0 0 0 .096-.245c-.019-1.122.25-2.307-.596-3.281-.033-.033-.039-.077-.059-.117-.062-.132-.105-.272-.155-.408l.086-.011a5.027 5.027 0 0 1-.253-.928c-.065-.439-.315-.845-.447-1.276-.169-.574-.306-1.161-.444-1.747-.047-.213-.02-.439-.054-.657-.052-.317-.046-.561.341-.683.106-.033.198-.284.205-.439.011-.936 0-1.877.005-2.817.006-.574 0-1.154.046-1.728.08-1.096.735-1.876 1.56-2.54C24.384 1.218 25.827.761 27.31.42c.772-.182 1.565-.291 2.353-.42.21-.033.434 0 .65 0 .031.051.072.103.104.16-.165.117-.354.22-.492.355-.321.317-.295.536.052.825.715.595 1.423 1.187 2.125 1.799.61.542.847 1.251.847 2.043 0 1.369.006 2.735 0 4.095 0 .284-.021.561.333.683.072.026.151.18.145.264-.059.703-.138 1.4-.21 2.103a.151.151 0 0 1-.02.076c-.643.915-.72 2.044-1.18 3.023-.02.04-.025.084-.059.117-.846.974-.577 2.159-.596 3.281 0 .078.043.171.096.245.042.043.085.089.127.097.458.097.524.438.636.787.144.464.296.914.479 1.366a.684.684 0 0 0 .333.342c.808.348 1.634.663 2.446.998.203.085.404.173.607.257.088.036.177.068.265.105l-.013.001c.579.239 1.161.471 1.732.733.866.413 1.712.872 2.565 1.322.504.264.977.561 1.37.922v5.229H29.25zm-14.613-.583H9.9v4.657c0 .935-.771 1.693-1.723 1.693-.951 0-1.723-.758-1.723-1.693v-4.657H1.717c-.951 0-1.722-.758-1.722-1.693 0-.936.771-1.694 1.722-1.694h4.737v-4.657c0-.935.772-1.693 1.723-1.693.952 0 1.723.758 1.723 1.693v4.657h4.737c.951 0 1.723.758 1.723 1.694 0 .935-.772 1.693-1.723 1.693z" />
  </svg>
);

AddFriend.propTypes = {
  className: PropTypes.string,
};

AddFriend.defaultProps = {
  className: '',
};

export default AddFriend;
