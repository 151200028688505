import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styles from './modal.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const EmailChangeModal = ({ isModalOpen, closeModal }) => (
  <Modal
    isOpen={isModalOpen}
    onRequestClose={closeModal}
    className={styles.content}
    overlayClassName={styles.overlay}
  >
    <div>
      <h4 className={styles.title}>Well done!</h4>
      <div className={styles.message_box}>
        Please, check your email to confirm new email.
      </div>
      <div className={styles.close_button_box}>
        <button
          type="button"
          onClick={closeModal}
          className={`${buttonStyles.btn_ok} ${buttonStyles.btn_uppercase}`}
        >
          Ok
        </button>
      </div>
    </div>
  </Modal>
);

EmailChangeModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default EmailChangeModal;
