import React from 'react';
import PropTypes from 'prop-types';

const Dots = ({ className }) => (
  className ? (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="150 0 110 408" xmlSpace="preserve">
      <g>
        <g>
          <path d="M204,102c28.05,0,51-22.95,51-51S232.05,0,204,0s-51,22.95-51,51S175.95,102,204,102z M204,153c-28.05,0-51,22.95-51,51    s22.95,51,51,51s51-22.95,51-51S232.05,153,204,153z M204,306c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51    S232.05,306,204,306z" />
        </g>
      </g>
    </svg>
  ) : (
    <svg width="5" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="150 0 110 408" xmlSpace="preserve">
      <g>
        <g>
          <path d="M204,102c28.05,0,51-22.95,51-51S232.05,0,204,0s-51,22.95-51,51S175.95,102,204,102z M204,153c-28.05,0-51,22.95-51,51    s22.95,51,51,51s51-22.95,51-51S232.05,153,204,153z M204,306c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51    S232.05,306,204,306z" />
        </g>
      </g>
    </svg>
  )
);

Dots.propTypes = {
  className: PropTypes.string,
};

Dots.defaultProps = {
  className: '',
};

export default Dots;
