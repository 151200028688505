import React from 'react';
import PropTypes from 'prop-types';

const FriendsIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 92 88">
    <path d="M81.68 57.197l-12.94-6.47a.71.71 0 0 1-.4-.65v-3.6l.45-.62a33.649 33.649 0 0 0 4-7.54 8.25 8.25 0 0 0 3.68-6.86v-5.45a8.19 8.19 0 0 0-1.35-4.51v-2.85a15 15 0 0 0-3.54-10.88C68.22 3.947 63 2.007 56 2.007a25.38 25.38 0 0 0-10 1.81 25.27 25.27 0 0 0-10-1.81c-7 0-12.22 1.94-15.56 5.75a15.05 15.05 0 0 0-3.54 10.84v2.92a8.2 8.2 0 0 0-1.36 4.49v5.42a8.19 8.19 0 0 0 2.34 5.75 31.269 31.269 0 0 0 4.43 9.2v3.55a.73.73 0 0 1-.38.64l-12.08 6.61A15.06 15.06 0 0 0 2 70.407v12.6a3 3 0 0 0 3 3h82a3 3 0 0 0 3-3v-12.33a15 15 0 0 0-8.32-13.48zM64 80.007H8v-9.6a9.06 9.06 0 0 1 4.73-8l12.07-6.55a6.73 6.73 0 0 0 3.51-5.91v-4.53a3 3 0 0 0-.62-1.83 25.19 25.19 0 0 1-4.23-8.74 3 3 0 0 0-1.05-1.63 2.21 2.21 0 0 1-.87-1.77v-5.44a2.2 2.2 0 0 1 .58-1.49 3 3 0 0 0 .77-2v-4.06a3.405 3.405 0 0 0 0-.35 9.08 9.08 0 0 1 2.07-6.39c2.14-2.46 5.86-3.71 11.04-3.71a18.12 18.12 0 0 1 8.42 1.7l.27.15a8.92 8.92 0 0 1 2.36 1.86 9 9 0 0 1 2.07 6.45c-.006.11-.006.22 0 .33v4a3 3 0 0 0 .77 2 2.2 2.2 0 0 1 .57 1.51v5.42a2.22 2.22 0 0 1-1.34 2 3 3 0 0 0-1.62 1.77 27.879 27.879 0 0 1-3.61 7.11c-.29.42-.59.81-.87 1.14a3 3 0 0 0-.69 1.91v4.63a6.69 6.69 0 0 0 3.72 6L59 62.567a9.001 9.001 0 0 1 5 8.11v9.33zm20 0H70v-9.33a15 15 0 0 0-8.33-13.48l-12.93-6.47a.71.71 0 0 1-.4-.65v-3.6l.45-.62a33.649 33.649 0 0 0 4-7.54 8.249 8.249 0 0 0 3.68-6.86v-5.45a8.19 8.19 0 0 0-1.35-4.51v-2.85A15.05 15.05 0 0 0 52 8.307a25.26 25.26 0 0 1 4-.3c5.17 0 8.89 1.25 11.05 3.71a9 9 0 0 1 2.07 6.45c-.006.11-.006.22 0 .33v4a3 3 0 0 0 .77 2 2.2 2.2 0 0 1 .57 1.51v5.42a2.22 2.22 0 0 1-1.34 2 3 3 0 0 0-1.62 1.77 27.879 27.879 0 0 1-3.61 7.11c-.29.42-.59.81-.87 1.14a3 3 0 0 0-.69 1.91v4.63a6.69 6.69 0 0 0 3.72 6L79 62.567a9.001 9.001 0 0 1 5 8.11v9.33z" />
  </svg>
);

FriendsIcon.propTypes = {
  className: PropTypes.string,
};

FriendsIcon.defaultProps = {
  className: '',
};

export default FriendsIcon;
