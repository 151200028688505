import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Element } from 'react-scroll';
import ReadMore from 'components/shared/Read_more/Read_more';
import defaultAvatar from '../../images/default_avatar.jpg';
import styles from './comments_block.module.scss';
import getUserName from '../../utils/getUserName';
import { formatDateFromNow } from '../../utils/formatDate';
import dotStyles from '../shared/Dots_submenu/dots_submenu.module.scss';
import Dots from '../icons/Dots';
import CommentForm from './Comment_form';
import BreakLineText from '../shared/Break_line_text/BreakLineText';


export class CommentItem extends Component {
  static propTypes = {
    comment: PropTypes.shape({
      body: PropTypes.string.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string,
      }).isRequired,
    }).isRequired,
    setCommentToDelete: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    item: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor() {
    super();

    this.menuRef = createRef();
  }

  state = {
    isSubmenuOpened: false,
    isEditing: false,
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clickOutside);
  }

  handleSubmenuOpen = () => this.setState({ isSubmenuOpened: true })

  handleSubmenuClose = () => this.setState({ isSubmenuOpened: false });

  toggleSubmenu = () => this.setState(prevState => ({
    isSubmenuOpened: !prevState.isSubmenuOpened,
  }), this.switchListener);

  switchListener = () => {
    const { isSubmenuOpened } = this.state;
    if (isSubmenuOpened) {
      document.addEventListener('click', this.clickOutside);
    } else {
      document.removeEventListener('click', this.clickOutside);
    }
  }

  clickOutside = (e) => {
    if (e.target !== this.menuRef.current) {
      this.handleSubmenuClose();
      document.removeEventListener('click', this.clickOutside);
    }
  }

  handleStartDelete = () => {
    const { comment, setCommentToDelete, openModal, item } = this.props;
    setCommentToDelete({ comment, item });
    openModal('REMOVE_COMMENT_MODAL');
  }

  handleEditStart = () => {
    this.setState({ isEditing: true });
  }

  handleEditFinish = () => {
    this.setState({ isEditing: false });
  }

  render() {
    const { isSubmenuOpened, isEditing } = this.state;
    const { comment, history } = this.props;
    const {
      body, user, permissions,
      created_at: createdAt, updated_at: updatedAt, id,
    } = comment;
    const userAvatar = user && user.avatar && user.avatar.thumb ? user.avatar.thumb : defaultAvatar;
    const userName = getUserName(user);
    const userId = user && user.id;

    const userUrl = {
      pathname: `/users/${userId}`,
      state: { from: history.location.pathname },
    };
    const editPermission = permissions && permissions.update;
    const deletePermission = permissions && permissions.delete;

    const isMenuAvailable = editPermission || deletePermission;
    const edited = createdAt !== updatedAt;

    const dateToShow = formatDateFromNow(createdAt);

    return (
      <Element name={String(id)}>
        <div className={styles.item}>
          <Link to={userUrl} className={styles.avatar} style={{ backgroundImage: `url(${userAvatar})` }} />
          <div className={styles.body}>
            <Link to={userUrl} className={styles.text_dark}>{userName}</Link>
            <ReadMore>
              <p className={styles.text_light}>
                <BreakLineText>
                  {body}
                </BreakLineText>
              </p>
            </ReadMore>
            <div className={styles.comment_info}>
              {dateToShow} {edited && '(edited)'}
            </div>
          </div>
          {isMenuAvailable && (
          <button
            type="button"
            onClick={this.toggleSubmenu}
            className={styles.menu_button}
          >
            <Dots />
          </button>
          )}
          {isSubmenuOpened && (
            <ul className={dotStyles.submenu} ref={this.menuRef}>
              {deletePermission && (
                <li className={dotStyles.submenu_item}>
                  <button className={dotStyles.button} type="button" onClick={this.handleStartDelete} data-test="remove_btn">
                    Remove
                  </button>
                </li>
              )}
              {editPermission && (
                <li className={dotStyles.submenu_item}>
                  <button className={dotStyles.button} type="button" onClick={this.handleEditStart}>
                    Edit
                  </button>
                </li>
              )}
            </ul>
          )}
        </div>
        {isEditing
          && <CommentForm {... this.props} editingMode onCancel={this.handleEditFinish} />
        }
      </Element>
    );
  }
}

export default withRouter(CommentItem);
