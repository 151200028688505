import {
  GET_MY_EVENTS_SUCCESS,
  GET_MY_VENUES_SUCCESS,
  GET_MY_TEACHERS_SUCCESS,
  SIGN_OUT,
  SET_ITEM_TO_DELETE,
  RESET_ITEM_TO_DELETE,
} from '../../redux/action_types';

const initialState = {
  events: [],
  venues: [],
  teachers: [],
  itemToDelete: null,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
      };

    case GET_MY_VENUES_SUCCESS:
      return {
        ...state,
        venues: action.payload,
      };

    case GET_MY_TEACHERS_SUCCESS:
      return {
        ...state,
        teachers: action.payload,
      };

    case SET_ITEM_TO_DELETE:
      return {
        ...state,
        itemToDelete: action.payload,
      };

    case RESET_ITEM_TO_DELETE:
      return {
        ...state,
        itemToDelete: null,
      };

    case SIGN_OUT:
      return initialState;

    default:
      return state;
  }
};

export default accountReducer;
