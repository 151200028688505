import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';

import StarIcon from '../icons/Star';
import CalendarIcon from '../icons/Calendar_thin';
import PinIcon from '../icons/Pin';
import defaultPhoto from '../../images/default_event_photo.jpg';

import { getCorrectDate, checkDateHappen } from '../../utils/formatDate';
import { setItemUrl, capitalizeStr } from '../../utils/parse';

import styles from './list.module.scss';
import {
  LESSON, WEEK, HOSTING_LIST, COHOST, FRIEND_SAVED_LIST,
} from '../../config';

const EMPTY_TEXT = '';
const EMPTY_DATE = '...';

const ListItem = (props) => {
  const handleToggleFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { toggleFavorite, item, history } = props;
    if (!toggleFavorite) return;

    const wasFavorite = item.favorite;
    const { id, type } = item;

    toggleFavorite({
      id,
      willbeFavorite: !wasFavorite,
      filter: history.location.search,
      type,
    });
  };

  const handleMouseEnter = () => {
    const { setHoverId, item } = props;
    const id = item && item.id;

    if (setHoverId && id) setHoverId(id);
  };

  const handleMouseLeave = () => {
    const { setHoverId } = props;
    if (setHoverId) setHoverId(null);
  };

  const getUrlTo = () => {
    const { history, item, past } = props;
    const queryObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const pathname = history && history.location && history.location.pathname;

    let url = setItemUrl(item);

    if (pathname.includes('/my_hostings')) {
      if (past && item.start_date) {
        const timestamp = moment(item.start_date).unix();
        queryObject.start_date = timestamp;
      } else {
        url = `/hosts${url}`;
      }
    }

    const search = qs.stringify({ ...queryObject, day: undefined });

    const to = {
      pathname: url,
      state: { from: history.location.pathname },
      search,
    };

    return to;
  };

  const { item, isListViewActive, serverTime, list } = props;
  const {
    photos,
    title,
    location,
    address,
    weekdays,
    dance_styles: danceStyles,
    hosting_status: hostingStatus,
  } = item;

  const image = isListViewActive
    ? (photos && photos[0] && photos[0].thumb) || defaultPhoto
    : (photos && photos[0] && photos[0].full) || defaultPhoto;

  let day;
  let month;
  let dateToShow;
  const startDate = item && item.start_date && getCorrectDate(item.start_date);

  const userStartDate = item && item.start_date && moment(item.start_date);
  const CURRENT_DATE = serverTime ? moment(serverTime) : moment();

  if (userStartDate) {
    if (userStartDate < CURRENT_DATE && list !== HOSTING_LIST) {
      dateToShow = CURRENT_DATE
        .clone()
        .hours(startDate.hours())
        .minutes(startDate.minutes());
    } else dateToShow = startDate;

    if (!isListViewActive) {
      day = dateToShow.format('D');
      month = dateToShow.format('MMM');
    } else {
      day = dateToShow.format('ddd, MMM D, h:mmA');

      const happening = checkDateHappen({
        serverTime,
        startDate: item.start_date,
        endDate: item.end_date,
      });

      if (happening && happening === 'Happening now') {
        day = happening;
      } else if (happening) {
        day = `${happening}, ${dateToShow.format('h:mmA')}`;
      }
    }
  } else day = EMPTY_DATE;

  const linkClass = classNames(
    { [styles.list]: isListViewActive },
    { [styles.thumbnails]: !isListViewActive },
    { [styles.narrowing]: list === FRIEND_SAVED_LIST },
  );

  const activeDays = weekdays
    ? weekdays.map(weekDay => capitalizeStr(weekDay))
    : null;

  const renderInfo = (type) => {
    switch (type) {
      case 'teacher':
        return (
          <div className={styles.info}>
            <div className={styles.style_container}>
              {danceStyles && danceStyles.map(styleItem => (
                <span key={styleItem} className={styles.style}>{styleItem}</span>
              ))}
            </div>
            <p className={styles.title}>
              <Truncate lines={2}>{title}</Truncate>
            </p>
            <div className={styles.place_venue}>
              <div className={styles.info_row}>
                {!isListViewActive && <PinIcon className={styles.info_icon} />}
                <div className={styles.calc_width}>
                  <address className={styles.location}>{location || EMPTY_TEXT}</address>
                  <address className={styles.address}>{address || EMPTY_TEXT}</address>
                </div>
              </div>
            </div>
          </div>
        );

      case 'event':
        return (
          <div className={styles.info}>
            <time className={styles.date}>
              <span className={styles.day}>{day}</span>
              <span className={styles.month}>{month}</span>
            </time>

            <p className={styles.title}>
              <Truncate lines={2}>{title}</Truncate>
            </p>

            <div className={styles.place}>
              <address className={styles.location}>{location || EMPTY_TEXT}</address>
              <address className={styles.address}>{address || EMPTY_TEXT}</address>
            </div>
          </div>
        );

      case 'venue':
        return (
          <div className={styles.info}>
            <div className={styles.style_container}>
              {danceStyles && danceStyles.map(styleItem => (
                <span key={styleItem} className={styles.style}>{styleItem}</span>
              ))}
            </div>
            <p className={styles.title}>
              <Truncate lines={2}>{title}</Truncate>
            </p>
            <div className={styles.place_venue}>
              <div className={styles.info_row}>
                {!isListViewActive && <CalendarIcon className={styles.info_icon} />}
                <p>
                  {WEEK.map(weekDay => (
                    <span
                      key={weekDay}
                      className={activeDays && activeDays.indexOf(weekDay) !== -1
                        ? styles.weekday_active
                        : styles.weekday}
                    >
                      {weekDay}
                    </span>
                  ))}
                </p>
              </div>
              <div className={styles.info_row}>
                {!isListViewActive && <PinIcon className={styles.info_icon} />}
                <div className={styles.address}>{address || EMPTY_TEXT}</div>
              </div>
            </div>
          </div>
        );

      case 'lesson':
        return (
          <div className={styles.info}>
            <time className={styles.date}>
              <span className={styles.day}>{day}</span>
              <span className={styles.month}>{month}</span>
            </time>
            <p className={styles.title}>{title || EMPTY_TEXT}</p>
            <div className={styles.place}>
              <address className={styles.location}>{location || EMPTY_TEXT}</address>
              <address className={styles.address}>{address || EMPTY_TEXT}</address>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <li>
      <Link
        className={linkClass}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        to={getUrlTo()}
      >
        <div className={styles.image} style={{ backgroundImage: `url(${image})` }} />
        {list === HOSTING_LIST ? hostingStatus && (
          <span className={hostingStatus === 'pending' ? styles.status_red : styles.hosting_status}>
            {hostingStatus === COHOST ? 'co-host' : hostingStatus}
          </span>
        ) : item.type !== LESSON && (
          <button type="button" onClick={handleToggleFavorite} className={styles.star_button}>
            <StarIcon
              className={styles.item_star}
              transparent={!item.favorite}
            />
          </button>
        )}
        {renderInfo(item.type)}
      </Link>
    </li>
  );
};

ListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    favorite: PropTypes.bool,
  }).isRequired,
  list: PropTypes.oneOf([
    'events',
    'teachers',
    'venues',
    'searchList',
    'hostingList',
    '',
  ]),
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  isListViewActive: PropTypes.bool.isRequired,
  toggleFavorite: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  serverTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  past: PropTypes.bool,
  setHoverId: PropTypes.func.isRequired,
};

ListItem.defaultProps = {
  history: null,
  serverTime: null,
  toggleFavorite: null,
  past: false,
  list: '',
};

export default ListItem;
