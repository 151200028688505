import React from 'react';
import PropTypes from 'prop-types';
import inputStyles from '../../../styles/inputs.module.scss';
import { INITIAL_STATE_FIELD } from '../../../config';

const TextInput = (props) => {
  const { onChange, inputName, placeholder, stateField, onBlur, autoComplete } = props;

  let fieldStyle;
  if (!stateField.isActive) fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
  else fieldStyle = stateField.isValid ? inputStyles.input_box_create : `${inputStyles.input_box_create} ${inputStyles.is_error}`;

  return (
    <div className={fieldStyle}>
      <input
        className={inputStyles.input}
        type={inputName === 'email' ? 'email' : 'text'}
        name={inputName}
        value={stateField.value || ''}
        onChange={onChange}
        autoComplete={autoComplete}
        onBlur={onBlur}
      />
      <label
        htmlFor={inputName}
        className={stateField.value ? inputStyles.label__with_value : inputStyles.label}
      >
        {stateField.error ? stateField.error : placeholder}
      </label>
    </div>
  );
};

TextInput.propTypes = {
  stateField: PropTypes.shape({
    isActive: PropTypes.bool,
    isValid: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  inputName: PropTypes.string,
  autoComplete: PropTypes.string,
};

TextInput.defaultProps = {
  stateField: { ...INITIAL_STATE_FIELD },
  onBlur: () => {},
  placeholder: '',
  onChange: () => {},
  inputName: '',
  autoComplete: null,
};

export default TextInput;
