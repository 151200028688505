import React from 'react';
import PropTypes from 'prop-types';

const FacebookOutline = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 792 792"
    xmlSpace="preserve"
  >
    <path d="M454.406,792H316.005c-10.454,0-18.938-8.484-18.938-18.938V448.005h-96.851c-10.454,0-18.938-8.484-18.938-18.938V295.021
    c0-10.454,8.484-18.938,18.938-18.938h96.851v-79.882C297.066,75.147,370.358,0,488.381,0c52.724,0,95.184,4.053,105.902,5.492
    c9.432,1.25,16.438,9.28,16.438,18.787v119.842c0,10.454-8.484,18.938-18.938,18.938l-70.905,0.076
    c-40.944,0-47.535,13.635-47.535,46.323v66.663h113.819c5.454,0,10.644,2.348,14.203,6.439c3.599,4.053,5.266,9.507,4.584,14.923
    l-17.272,134.045c-1.212,9.469-9.279,16.552-18.786,16.552h-96.548v325.058C473.344,783.554,464.86,792,454.406,792z
      M334.943,754.123h100.524V429.066c0-10.454,8.484-18.938,18.938-18.938h98.82l12.424-96.169H454.406
    c-10.454,0-18.938-8.484-18.938-18.938V209.42c0-36.134,8.863-84.2,85.412-84.2l52.005-0.038V41.361
    c-19.355-1.667-50.452-3.485-84.503-3.485c-96.093,0-153.438,59.164-153.438,158.324v98.82c0,10.454-8.484,18.938-18.938,18.938
    h-96.851v96.169h96.851c10.454,0,18.938,8.484,18.938,18.938V754.123z"
    />
  </svg>
);

FacebookOutline.propTypes = {
  className: PropTypes.string,
};

FacebookOutline.defaultProps = {
  className: '',
};

export default FacebookOutline;
