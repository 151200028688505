import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import qs from 'qs';
import classNames from 'classnames';

import SimpleItemPreview from '../shared/Simple_item_preview/Simple_item_preview';
import CommentsBlock from '../Comments_block/comments_container';
import TicketOrder from '../Ticket_order/ticket_order_container';
import ReadMore from '../shared/Read_more/Read_more';
import BottomBar from '../shared/Bottom_bar/Bottom_bar';
import DateInfo from '../shared/Date_info/Date_info';
import VenueInfo from '../shared/Venue_info/Venue_info';
import InactiveBanner from '../shared/Inactive_banner/Inactive_banner';
import PhotosBlock from '../shared/Photos_block/Photos_block';
import BreakLineText from '../shared/Break_line_text/BreakLineText';

import detectSafari from '../../utils/detectSafari';
import getNestedValue from '../../utils/getNestedValue';
import { getDollarsFromCents } from '../../utils/parse';
import scrollToItemTop, { scrollToElement } from '../../utils/scrollToItem';

import CalendarIcon from '../icons/Calendar_thin';
import PinIcon from '../icons/Pin';
import InfoIcon from '../icons/Info';
import TicketIcon from '../icons/Ticket';
import CancelIcon from '../icons/Cancel_icon';
import defaultAvatar from '../../images/default_avatar.jpg';

import styles from '../../styles/view_details.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import { ACTIVE, INACTIVE, LESSON } from '../../config';

const TEXT_EMPTY = '...';

const INITIAL_STATE = {
  isTicketsOptionsOpened: false,
  fixBuyButton: true,
  buyPermission: false,
};

class LessonDetails extends Component {
  static propTypes = {
    getLessonDetails: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.string,
      }),
    }),
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    lesson: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      favorite: PropTypes.bool,
      teacher: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        status: PropTypes.oneOf([ACTIVE, INACTIVE]),
      }),
    }).isRequired,
    previewFromForm: PropTypes.bool,
    lessonForPreview: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    userLogged: PropTypes.shape({
      id: PropTypes.string,
    }),
    token: PropTypes.string,
    setFSphotos: PropTypes.func.isRequired,
    resetMarker: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    previewFromForm: false,
    lessonForPreview: {},
    history: {},
    match: {},
    location: null,
    token: '',
    userLogged: null,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.wrapperRef = createRef();
    this.commentsRef = createRef();
    this.buttonBoxRef = createRef();
  }

  componentDidMount() {
    const { getLessonDetails, match, previewFromForm, history } = this.props;
    if (previewFromForm) return;

    getLessonDetails({
      id: match.params.id,
      moveMap: true,
      filter: history.location.search,
    })
      .then(() => {
        const { search } = history && history.location;
        const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
        if (queryObject.tickets_open) {
          this.setState({ isTicketsOptionsOpened: true });
        }

        // checking button fix for disabled button not fixing
        this.checkBuyPermission();
        if (process.env.NODE_ENV !== 'test') this.checkButtonFix();
        this.scrollToTop();

        const { hash } = history.location;
        if (window.innerWidth < 768) {
          if (hash) {
            scrollToElement(hash.substring(1));
          } else {
            this.scrollToTitle(1000);
          }
        } else if (hash) {
          this.scrollToComment();
        }
      });

    if (this.wrapperRef.current) {
      this.wrapperRef.current.addEventListener('scroll', this.checkButtonFix);
    }
    window.addEventListener('scroll', this.checkButtonFix);
  }

  componentDidUpdate(prevProps, prevState) {
    const { previewFromForm, getLessonDetails, history } = this.props;
    if (previewFromForm) return;
    if ((this.props.match.params.id !== prevProps.match.params.id)
      || (this.props.token !== prevProps.token)) {
      getLessonDetails({
        id: this.props.match.params.id,
        moveMap: true,
        filter: history.location.search,
      }).then(() => {
        // checking button fix for disabled button not fixing
        this.checkBuyPermission();
        this.checkButtonFix();
      });
      if (this.props.token === prevProps.token) {
        this.resetTicketOptionView();
      }
      this.scrollToTop();
    }

    const changedView = prevState.isTicketsOptionsOpened !== this.state.isTicketsOptionsOpened;

    if (changedView && this.wrapperRef.current) {
      this.checkButtonFix();
      this.wrapperRef.current.addEventListener('scroll', this.checkButtonFix);
    }
  }

  componentWillUnmount() {
    const { resetMarker } = this.props;
    resetMarker();
    this.resetTicketOptionView();

    if (this.wrapperRef.current) {
      this.wrapperRef.current.removeEventListener('scroll', this.checkButtonFix);
    }
    window.removeEventListener('scroll', this.checkButtonFix);
  }

  scrollToTop = () => {
    if (this.wrapperRef.current) this.wrapperRef.current.scrollTop = 0;
    window.scrollTo(0, 0);
  }

  scrollToComment = () => {
    if (this.commentsRef.current && this.wrapperRef.current) {
      this.wrapperRef.current.scrollTop = this.commentsRef.current.offsetTop;
      window.scrollTo(0, (this.commentsRef.current.offsetTop + window.innerHeight / 2));
    }
  }

  scrollToTitle = (delay) => {
    const wrapper = this.wrapperRef && this.wrapperRef.current;
    scrollToItemTop({ wrapper, delay });
  }

  checkButtonFix = () => {
    const { buyPermission } = this.state;
    if (!buyPermission) {
      this.setState({ fixBuyButton: false });
      return;
    }

    const buttonBox = this.buttonBoxRef && this.buttonBoxRef.current;
    const wrapper = this.wrapperRef && this.wrapperRef.current;
    if (!buttonBox || !wrapper) return;
    const { top: boxTop, height: boxHeight } = buttonBox.getBoundingClientRect();
    const buttonBoxOffset = buttonBox.offsetTop;

    if (window.innerWidth > 767) {
      const wrapperHeight = wrapper.clientHeight;
      const scrolled = wrapper.scrollTop;
      const delta = buttonBoxOffset + boxHeight - 10 - wrapperHeight - scrolled;

      if (delta <= 0) {
        this.setState({ fixBuyButton: false });
      } else {
        this.setState({ fixBuyButton: true });
      }
    } else {
      const delta = boxTop + boxHeight - 8 - window.innerHeight;

      if (delta <= 0) {
        this.setState({ fixBuyButton: false });
      } else {
        this.setState({ fixBuyButton: true });
      }
    }
  }

  checkBuyPermission = () => {
    const { lesson } = this.props;
    const buyPermission = getNestedValue(lesson, 'permissions', 'buy_tickets');

    this.setState({ buyPermission });
    return buyPermission;
  }

  resetTicketOptionView = () => {
    this.setState({ isTicketsOptionsOpened: false });

    const { history } = this.props;
    if (!history) return;

    const from = getNestedValue(history, 'location', 'state', 'from');
    const list = getNestedValue(history, 'location', 'state', 'list');
    const state = { from, list };
    history.push({ search: '', state });
  }

  closeLesson = () => {
    const { history, location } = this.props;
    const from = location.state && location.state.from;
    const query = location && location.state && location.state.query;
    if (from) {
      const list = location.state && location.state.list;
      history.push({
        pathname: from,
        state: {
          list,
          query,
        },
        search: location.search,
      });
    } else {
      history.push({
        pathname: '/teachers',
        search: location.search,
        state: { query },
      });
    }
  }

  handleToggleTickets = () => {
    const { getLessonDetails, lesson, history } = this.props;
    const { isTicketsOptionsOpened, buyPermission } = this.state;

    const from = getNestedValue(history, 'location', 'state', 'from');
    const list = getNestedValue(history, 'location', 'state', 'list');
    const state = { from, list };

    if (!buyPermission) {
      this.setState({ isTicketsOptionsOpened: false });
      history.push({ search: '', state });
      return;
    }

    if (isTicketsOptionsOpened) {
      getLessonDetails({
        id: lesson.id,
        moveMap: false,
        filter: history.location.search,
      });
      history.push({ search: '', state });
    } else {
      history.push({ search: '?tickets_open=true', state });
      this.scrollToTitle(0);
    }

    this.setState({ isTicketsOptionsOpened: !isTicketsOptionsOpened });
  }

  handleOpenFullScreen = () => {
    const { setFSphotos, lesson, openModal } = this.props;
    const photos = lesson && lesson.photos;

    setFSphotos(photos);
    openModal('FULL_SCREEN_PHOTOS_MODAL');
  }

  render() {
    const { lesson, error, previewFromForm, lessonForPreview, userLogged } = this.props;
    const { history } = this.props;

    if (error) {
      return <div className={`${gridStyles.side_bar} ${styles.container}`}>Sorry, class not found</div>;
    }
    const {
      title,
      description,
      dance_styles: danceStyles,
      location,
      start_date: startDate,
      end_date: endDate,
      address,
      tickets,
      comments,
      photos,
      favorite,
      venue_id: venueId,
      teacher,
    } = previewFromForm ? lessonForPreview : lesson;

    const teacherTitle = (teacher && teacher.title) || TEXT_EMPTY;
    const teacherLink = (teacher && teacher.id)
      ? {
        pathname: `/teachers/${teacher.id}`,
        state: { from: history.location.pathname },
      }
      : null;

    const teacherAvatar = (teacher && teacher.photos && teacher.photos[0])
      ? teacher.photos[0].full
      : defaultAvatar;

    let price = TEXT_EMPTY;
    let refunds = TEXT_EMPTY;
    if (tickets && tickets.length > 0) {
      const minPrice = Math.min.apply(null,
        tickets.map(ticket => parseFloat(ticket.price, 10)));
      const maxPrice = Math.max.apply(null,
        tickets.map(ticket => parseFloat(ticket.price, 10)));
      price = minPrice === maxPrice
        ? getDollarsFromCents(minPrice)
        : `${getDollarsFromCents(minPrice)} - ${getDollarsFromCents(maxPrice)}`;
      refunds = tickets.some(ticket => !ticket.refunds) ? 'No refund' : 'With refund';
    }

    const { isTicketsOptionsOpened, buyPermission, fixBuyButton } = this.state;

    const isViewedByOwner = userLogged && lesson.user && lesson.user.id
      && userLogged.id === lesson.user.id;
    const isItemInactive = teacher && teacher.status === INACTIVE;
    let containerStyles = previewFromForm
      ? gridStyles.scroll_container
      : gridStyles.scroll_container_big;
    if (isItemInactive) containerStyles = gridStyles.scroll_container_big_with_inactive_banner;

    const currentUserPayouts = getNestedValue(userLogged, 'connect', 'payouts_enabled');

    return (
      isTicketsOptionsOpened
        ? (
          <TicketOrder
            onClose={this.handleToggleTickets}
            tickets={tickets}
            eventStartDate={startDate}
            eventEndDate={endDate}
            forPasses
            isItemInactive={isItemInactive}
          />
        ) : (
          <div
            className={!previewFromForm
              ? gridStyles.wrapper_fixed_bottom
              : ''
            }
          >
            {isItemInactive && (
              <InactiveBanner type={LESSON} />
            )}
            <div
              className={containerStyles}
              ref={this.wrapperRef}
            >
              {!previewFromForm
                && (
                <button
                  type="button"
                  className={buttonStyles.cancel_button}
                  onClick={this.closeLesson}
                >
                  <CancelIcon />
                </button>
                )}
              <h1 className={detectSafari() ? styles.title_safari : styles.title}>{title}</h1>
              <div className={detectSafari()
                ? styles.styles_container_safari
                : styles.styles_container}
              >
                {danceStyles
              && danceStyles.map(style => <span key={style} className={styles.style}>{style}</span>)
            }
              </div>
              <PhotosBlock
                photos={photos}
                handleOpenFullScreen={this.handleOpenFullScreen}
                previewFromForm={previewFromForm}
                forLessons
              />
              <div className={styles.info_container}>
                <CalendarIcon className={styles.calendar_icon} />
                <DateInfo item={previewFromForm ? lessonForPreview : lesson} />
              </div>
              <div className={styles.info_container}>
                <PinIcon />
                <div className={styles.info}>
                  <VenueInfo venueId={venueId} location={location} address={address} />
                </div>
              </div>
              <div className={styles.info_container}>
                <TicketIcon />
                <div className={styles.info}>
                  <div className={styles.text_dark}>{price}</div>
                  <div className={styles.text_light}>{refunds}</div>
                </div>
              </div>
              <div className={styles.info_container}>
                <InfoIcon className={styles.info_icon} />
                <div className={styles.info}>
                  <p className={styles.text_dark}>Class info</p>
                  <ReadMore className={styles.text_light}>
                    <p className={styles.text_light}>
                      {description ? (
                        <BreakLineText>{description}</BreakLineText>
                      ) : TEXT_EMPTY}
                    </p>
                  </ReadMore>
                </div>
              </div>
              <div className={styles.author_container}>
                {teacherLink
                  ? <Link to={teacherLink} className={styles.avatar} style={{ backgroundImage: `url(${teacherAvatar})` }} />
                  : <div className={styles.avatar} style={{ backgroundImage: `url(${teacherAvatar})` }} />
                }
                <div className={styles.author}>
                  <p className={`${styles.text_light} ${styles.text_small}`}>Class by</p>
                  <div className={styles.text_dark}>
                    {teacherLink
                      ? <Link to={teacherLink}>{teacherTitle}</Link>
                      : <p>{teacherTitle}</p>
                    }
                  </div>
                </div>
              </div>
              {previewFromForm
                ? (tickets && tickets.length > 0 && (
                <>
                  <h4 className={styles.subtitle}>Passes</h4>
                  {tickets.map((ticket, index) => (
                    <SimpleItemPreview
                      item={ticket}
                      forPasses
                      detailsView
                      key={`${ticket.name}_${index.toString()}`}
                    />
                  ))}
                </>
                )
                ) : (
                  <>
                    <div className={styles.sticky_wrapper} ref={this.buttonBoxRef}>
                      <div className={classNames({ [styles.stick]: fixBuyButton })}>
                        <button
                          type="button"
                          className={classNames(
                            buttonStyles.btn_uppercase,
                            buttonStyles.fullwidth,
                            { [buttonStyles.btn_blue]: buyPermission },
                            { [buttonStyles.btn_disabled]: !buyPermission },
                          )}
                          onClick={this.handleToggleTickets}
                        >
                          {isItemInactive ? 'view pass' : 'buy pass'}
                        </button>
                      </div>
                    </div>
                    {isViewedByOwner && !buyPermission && !currentUserPayouts ? (
                      <div className={styles.text_small_grey}>
                        To sell tickets you need to
                        &nbsp;
                        <Link to={{ pathname: '/payout', state: { from: history.location.pathname } }} className={styles.grey_link}>
                          set up payout.
                        </Link>
                      </div>
                    ) : null}
                    <div ref={this.commentsRef}>
                      <CommentsBlock
                        comments={comments}
                        item={lesson}
                        scrollToComment={this.scrollToComment}
                      />
                    </div>
                  </>
                )
            }
            </div>
            {!previewFromForm && (
              <BottomBar
                favorite={favorite}
                item={lesson}
              />
            )}
          </div>
        )
    );
  }
}

export default LessonDetails;
