import { connect } from 'react-redux';
import {
  getUserInfo, getSavedItems, addLike, removeLike,
} from './public_profile_actions';
import { setAndOpenModal } from '../Modal/modal_actions';
import { setHoverId } from '../Map/map_actions';

import {
  inviteToFriends,
  acceptFriend,
  declineFriend,
  cancelRequest,
  openDeleteFriendModal,
} from '../Friends/friends_actions';
import PublicProfile from './Public_profile';

const mSTP = state => ({
  authenticated: state.session.authenticated,
  isModalOpen: state.modal.isModalOpen,
  token: state.session.token,
});

const mDTP = {
  getUserInfo,
  setAndOpenModal,
  inviteToFriends,
  acceptFriend,
  declineFriend,
  cancelRequest,
  openDeleteFriendModal,
  getSavedItems,
  setHoverId,
  addLike,
  removeLike,
};

export default connect(mSTP, mDTP)(PublicProfile);
