import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Dropdown from '../Dropdown/Dropdown';
import DotsSubmenu from '../Dots_submenu/Dots_submenu_container';
import ShareIcon from '../../icons/Share';
import StarIcon from '../../icons/Star';
import FacebookLogo from '../../icons/Facebook_outline';
import NavigationIcon from '../../icons/Navigation_icon';
import TwitterLogo from '../../icons/Twitter';
import ChainIcon from '../../icons/Chain';
import MailIcon from '../../icons/Mail_icon';
import MessengerIcon from '../../icons/Messenger';

import getGoogleDirectionLink from '../../../utils/getGoogleDirectionLink';
import detectMobile from '../../../utils/detectMobile';

import buttonStyles from '../../../styles/buttons.module.scss';
import styles from './bottom_bar.module.scss';

import {
  EVENT,
  TEACHER,
  VENUE,
  LESSON,
  FACEBOOK_APP_ID,
} from '../../../config';

class BottomBar extends Component {
  static propTypes = {
    favorite: PropTypes.bool,
    toggleFavorite: PropTypes.func,
    item: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.oneOf([EVENT, TEACHER, VENUE, LESSON]),
    }).isRequired,
  };

  static defaultProps = {
    favorite: false,
    toggleFavorite: () => {},
  };

  state = { copySuccess: false }

  hideSuccessMessage = () => { this.setState({ copySuccess: false }); }

  copyLinkToClipBoard = (fn) => {
    const currentUrl = window.location.href;
    const el = document.createElement('textarea');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    el.value = currentUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.setState({ copySuccess: true });
    setTimeout(() => {
      this.hideSuccessMessage();
      if (fn) fn();
    }, 500);
  }

  render() {
    const { favorite, toggleFavorite, item } = this.props;
    const { copySuccess } = this.state;
    const type = item && item.type;
    const currentUrl = window.location.href;
    const directionLink = getGoogleDirectionLink(item);
    const title = item && item.title;
    const description = item && item.description;
    const regex = /check-in\/|hosts\/|analytics\//;
    const shareUrl = currentUrl.replace(regex, '');

    const desktopMessengerLink = `https://www.facebook.com/dialog/send?app_id=${FACEBOOK_APP_ID}&link=${shareUrl}&redirect_uri=${shareUrl}`;
    const mobileMessengerLink = `fb-messenger://share/?link=${shareUrl}`;
    const messengerLink = detectMobile() ? mobileMessengerLink : desktopMessengerLink;

    return (
      <div className={buttonStyles.fixed_icons}>
        <Helmet>
          {title && <title>Dancity || {title}</title>}
          {description && <meta name="description" content={description} />}
        </Helmet>
        <div className={styles.row}>
          {type !== LESSON && (
            <button className={styles.icon} onClick={toggleFavorite} type="button">
              <StarIcon className={styles.star} transparent={!favorite} />
              <div className={styles.title}>Save</div>
            </button>
          )}
          <a href={directionLink} target="_blank" rel="noopener noreferrer" className={styles.icon} type="button">
            <NavigationIcon />
            <div className={styles.title}>Drive</div>
          </a>
          <Dropdown>
            {(isOpen, handleToggle, handleOpen, handleClose) => (
              <div className={styles.relative_container}>
                <button type="button" className={styles.icon} onClick={handleToggle}>
                  <ShareIcon />
                  <div className={styles.title}>Share</div>
                </button>

                {isOpen && (
                  <ul className={styles.share_menu}>
                    <li>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.share_link}
                      >
                        <FacebookLogo className={styles.fb_icon} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://twitter.com/intent/tweet?text=${title}&url=${shareUrl}&via=dancemobilellc`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.share_link}
                      >
                        <TwitterLogo className={styles.twitter_icon} />
                      </a>
                    </li>
                    <li className={styles.relative_container}>
                      <button
                        type="button"
                        className={styles.share_link}
                        onClick={() => { this.copyLinkToClipBoard(handleClose); }}
                      >
                        <ChainIcon className={styles.link_icon} />
                      </button>
                      {copySuccess && (
                        <div className={styles.tooltip}>Copied!</div>
                      )}
                    </li>
                    <li>
                      <a
                        href={`mailto:?subject=I wanted you to see this site&body=Checkout this site ${currentUrl}`}
                        className={styles.share_link}
                        onClick={handleClose}
                      >
                        <MailIcon className={styles.mail_icon} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={messengerLink}
                        className={styles.share_link}
                        onClick={handleClose}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MessengerIcon className={styles.mail_icon} />
                      </a>
                    </li>
                  </ul>

                )}
              </div>
            )}
          </Dropdown>

          <DotsSubmenu
            item={item}
            bottomView
          />
        </div>
      </div>
    );
  }
}

export default BottomBar;
