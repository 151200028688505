import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Select from 'react-select';
import moment from 'moment';

import getNestedValue from '../../../utils/getNestedValue';
import { formatDateForList } from '../../../utils/formatDate';
import checkTicketExpired from '../../../utils/checkTicketExpired';

import DateInfo from '../../shared/Date_info/Date_info';
import IncludesBlock from '../../shared/Includes_block/Includes_block';
import LastCheckin from './LastCheckin';

import CancelIcon from '../../icons/Cancel_icon';
import PrinterIcon from '../../icons/Printer';
import TicketIcon from '../../icons/Ticket';
import PinIcon from '../../icons/Pin';
import CalendarIcon from '../../icons/Calendar_thin';
import PersonIcon from '../../icons/Person';
import DancerIcon from '../../icons/Dancer';
import QRScan from '../../icons/QR_scan';
import Checkmark from '../../icons/Checkmark';

import modalStyles from '../modal.module.scss';
import styles from './u_ticket_modal.module.scss';
import inputStyles from '../../../styles/inputs.module.scss';
import btnStyles from '../../../styles/buttons.module.scss';
import customSelectStyles, { selectErrorStyles, selectActiveStyles } from '../../../styles/select_styles';
import { ONCE } from '../../../config';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

class UserTicketModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    order: PropTypes.shape({
      id: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.object),
      order: PropTypes.object,
      qr_code: PropTypes.string,
      display_id: PropTypes.string,
    }).isRequired,
    forPasses: PropTypes.bool,
    updateCurrentTicket: PropTypes.func,
    manualCheckin: PropTypes.func.isRequired,
  };

  static defaultProps = {
    forPasses: false,
    updateCurrentTicket: () => {},
  };

  state = {
    manualMode: false,
    hostCode: {
      isActive: false,
      isValid: false,
      value: '',
      error: null,
    },
    selectedItemToCheckin: {
      value: '',
      isActive: false,
      isValid: true,
    },
    multiItems: false,
    successfullCheckin: false,
    showAllLastCheckins: false,
  }

  componentDidMount() {
    this.updateInterval = setInterval(this.updateTicket, 1000);
    this.checkForMultiItems();
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  checkForMultiItems = () => {
    const { order } = this.props;
    const ticketItems = order && order.items;

    this.setState({ multiItems: ticketItems && ticketItems.length > 1 });
  }

  updateTicket = () => {
    const { order, updateCurrentTicket } = this.props;
    const ticketOrderId = order && order.id;

    if (ticketOrderId && updateCurrentTicket) {
      updateCurrentTicket(ticketOrderId);
    }
  }

  switchOnManualMode = () => {
    this.setState({ manualMode: true });
  }

  switchOffManualMode = () => {
    this.setState({ manualMode: false });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = true;
    this.setState({
      [name]: {
        isActive: true,
        isValid,
        value,
        error: null,
      },
    });
  };

  handleSelectItemToCheckin = (value) => {
    this.setState(prevState => ({ selectedItemToCheckin: {
      ...prevState.selectedItemToCheckin,
      value,
      isValid: true,
      isActive: true,
      error: null,
    } }));
  }

  handleManualCheckin = () => {
    const { hostCode, selectedItemToCheckin, multiItems } = this.state;
    const { manualCheckin, order } = this.props;
    const items = getNestedValue(order, 'items');
    const ticketId = getNestedValue(order, 'id');
    const hostCodeValue = getNestedValue(hostCode, 'value');
    let itemId = items && items[0] && items[0].id;
    const type = items && items[0] && items[0].type;

    if (multiItems) {
      itemId = getNestedValue(selectedItemToCheckin, 'value', 'value');
      if (!itemId) {
        this.setState(prevState => ({
          selectedItemToCheckin: {
            ...prevState.selectedItemToCheckin,
            isValid: false,
          },
        }));
      }
    }

    if (hostCodeValue && itemId && manualCheckin && ticketId && type) {
      manualCheckin({ ticketId, itemId, hostCode: hostCodeValue, type })
        .then((res) => {
          if (res && res.status === 200) {
            this.setState({
              successfullCheckin: true,
            });
          } else if (res && res.status === 403) {
            const error = res.data && res.data.error;
            this.setState(prevState => ({
              hostCode: {
                ...prevState.hostCode,
                isValid: false,
                error,
              },
            }));
          }
        });
    }
  }

  toggleLastCheckins = () => {
    this.setState(prevState => ({
      showAllLastCheckins: !prevState.showAllLastCheckins,
    }));
  }

  getSelectStyles = (isValid, isActive) => {
    let selectStyles = isValid ? customSelectStyles : selectErrorStyles;
    if (isActive) selectStyles = selectActiveStyles;
    return selectStyles;
  };

  renderManualInterface = () => {
    const { order } = this.props;
    const {
      hostCode, selectedItemToCheckin, multiItems,
      successfullCheckin,
    } = this.state;
    const { isValid, isActive } = selectedItemToCheckin;
    const selectItemStyles = this.getSelectStyles(isValid, isActive);

    const ticketItems = order && order.items;
    let selectOptions;
    if (multiItems) {
      selectOptions = ticketItems.map(item => ({
        value: item && item.id,
        label: item && item.title,
      }));
    }

    let fieldStyle;
    if (!hostCode.isActive) fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    else fieldStyle = hostCode.isValid ? inputStyles.input_box_create : `${inputStyles.input_box_create} ${inputStyles.is_error}`;

    return (
      <div className={styles.manual_container}>
        {successfullCheckin ? (
          <div>
            <h5 className={styles.title}>Well done!</h5>
            You have checked in successfully.
          </div>
        ) : (
          <>
            {multiItems ? (
              <Select
                value={selectedItemToCheckin.value}
                onChange={this.handleSelectItemToCheckin}
                options={selectOptions}
                className={inputStyles.safari_input}
                styles={selectItemStyles}
                placeholder="Check-in to class"
                isSearchable={false}
              />
            ) : null}
            <div className={fieldStyle}>
              <input
                className={inputStyles.input}
                type="text"
                name="hostCode"
                value={hostCode.value}
                onChange={this.handleInputChange}
              />
              <label
                htmlFor="hostCode"
                className={hostCode.value ? inputStyles.label__with_value : inputStyles.label}
              >
                {hostCode.error ? hostCode.error : 'Enter host code'}
              </label>
            </div>
            <div className={`${btnStyles.buttons_container} ${btnStyles.buttons_row}`}>
              <button
                type="button"
                className={`${btnStyles.btn_blue} ${btnStyles.btn_uppercase}`}
                onClick={this.handleManualCheckin}
              >
                Check in
              </button>
              <button
                type="button"
                className={`${btnStyles.btn_red} ${btnStyles.btn_uppercase}`}
                onClick={this.switchOffManualMode}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    );
  }

  render() {
    const { order, isModalOpen, closeModal, forPasses } = this.props;
    const { manualMode, showAllLastCheckins } = this.state;

    const event = order.items && order.items[0] ? order.items[0] : null;
    const teacher = getNestedValue(order, 'ticket', 'teacher');

    const { id: orderId } = order.order;
    const {
      qr_code: qrCode,
      buyer,
      name: ticketName,
      last_visits: lastVisits,
      display_id: displayId,
      consumed,
    } = order;

    const title = forPasses
      ? getNestedValue(teacher, 'title') || 'Teacher name'
      : getNestedValue(event, 'title') || 'Event name';
    const location = (event && event.location) || 'No name location';
    const address = (event && event.address) || 'Without address';
    const recurrent = getNestedValue(event, 'recurrent', 'freq');

    let userName = 'Anonymous';
    if (buyer && buyer.first_name && buyer.last_name) {
      userName = `${buyer.first_name} ${buyer.last_name}`;
    } else if (buyer && buyer.first_name) {
      userName = `${buyer.first_name}`;
    } else if (buyer && buyer.last_name) {
      userName = `${buyer.last_name}`;
    } else if (buyer && buyer.nickname) {
      userName = `${buyer.nickname}`;
    }

    const entriesCount = order.visits_count;
    const entriesTotal = getNestedValue(order, 'total_visits');
    const expiredDate = order.expired_date && formatDateForList(order.expired_date);
    let passValidity = entriesTotal === 0
      ? `${entriesCount} visits used`
      : `${entriesCount}/${entriesTotal} visits used`;
    if (expiredDate) passValidity = `${passValidity}, valid until ${expiredDate}`;

    const allItemsExpired = checkTicketExpired(order);
    const ticketExpired = order.expired_date && moment(order.expired_date).isBefore();

    let checkmarkStyle = styles.checkmark_round_red;
    if (consumed) checkmarkStyle = styles.checkmark_round_green;
    const showCheckmark = allItemsExpired || ticketExpired || consumed;

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className={modalStyles.user_ticket_content}
        overlayClassName={modalStyles.overlay}
      >
        <div className={modalStyles.top_row}>
          <button type="button" onClick={() => { window.print(); }}>
            <PrinterIcon className={styles.printer_icon} />
          </button>
          <button type="button" onClick={closeModal}>
            <CancelIcon className={modalStyles.close_icon} />
          </button>
        </div>
        <div className={modalStyles.content_box}>
          <p>Order#: {orderId}</p>
          <p>Ticket#: {displayId}</p>
          {manualMode
            ? this.renderManualInterface()
            : (
              <>
                <div className={styles.qr_box}>
                  <img className={styles.qr_code} src={qrCode} alt="qr_code" />
                  {showCheckmark && (
                    <div className={checkmarkStyle}>
                      {consumed
                        ? <Checkmark className={styles.checkmark} />
                        : <CancelIcon className={styles.checkmark} />
                      }
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className={styles.manual_link}
                  onClick={this.switchOnManualMode}
                >
                  Manual check-in
                </button>
              </>
            )
          }

          <p className={styles.title}>{title}</p>
          <p className={styles.sub_title}>
            {forPasses ? 'Teacher name' : 'Event name'}
          </p>
          <div className={styles.ticket_info}>
            <div className={styles.info_item}>
              <div className={styles.icon_box}>
                <TicketIcon className={styles.ticket_icon} />
              </div>
              {ticketName}
            </div>
            <div className={styles.info_item}>
              <div className={styles.icon_box}>
                <PersonIcon className={styles.person_icon} />
              </div>
              {userName}
            </div>
            {forPasses && (
              <>
                <div className={styles.info_item}>
                  <div className={styles.icon_box}>
                    <DancerIcon className={styles.person_icon} />
                  </div>
                  Pass includes:
                </div>
                <div className={styles.info_list}>
                  <IncludesBlock items={order.items} withoutLinks />
                </div>
              </>
            )}
            <div className={styles.info_item}>
              <div className={styles.icon_box}>
                <CalendarIcon className={styles.calendar_icon} />
              </div>

              {forPasses
                ? passValidity
                : <DateInfo item={event} ticketView />
              }

            </div>
            {!forPasses && (
              <div className={styles.info_item}>
                <div className={styles.icon_box}>
                  <PinIcon className={styles.pin_icon} />
                </div>
                <div>
                  <p className={styles.location}>{location}</p>
                  <p className={styles.address}>{address}</p>
                </div>
              </div>
            )}
            <div className={styles.info_item}>
              <div className={styles.icon_box}>
                <QRScan className={styles.pin_icon} />
              </div>
              Last check-in:
            </div>
            {lastVisits && lastVisits.length > 1 ? (
                <>
                  <ul className={styles.info_list}>
                    {lastVisits.map((item, i) => {
                      if (!showAllLastCheckins && i > 0) {
                        return null;
                      }
                      return (
                        <LastCheckin item={item} key={i.toString()} />
                      );
                    })}
                  </ul>
                  <div className={styles.center_container}>
                    <button
                      type="button"
                      className={showAllLastCheckins ? styles.less_button : styles.more_button}
                      onClick={this.toggleLastCheckins}
                    >
                      {showAllLastCheckins ? 'Less checkins' : 'More checkins'}
                    </button>
                  </div>
                </>
            ) : null}
            {lastVisits && lastVisits.length === 1 ? (
              <div className={styles.info_list}>
                {lastVisits.map(item => (
                  <LastCheckin item={item} key={item.id} />
                ))}
              </div>
            ) : null}
            {(lastVisits && lastVisits.length < 1) || !lastVisits ? (
              <div className={styles.info_list}>
                No checkins
              </div>
            ) : null}
            {!forPasses && recurrent !== ONCE ? (
              <div className={styles.info_list}>
                {passValidity}
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    );
  }
}

export default UserTicketModal;
