import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import Select from 'react-select';

import Eye from '../icons/Eye';
import EyeHidden from '../icons/Eye_hidden';
import AddressInput from '../Address_input/Address_input_container';

import { changeUserEmail, requestLanguages } from '../../api';
import { makeSelectOptions, getValueFromOptions } from '../../utils/selectOptions';
import { validateEmail, validateUserName, validateFeedback } from '../../utils/validation';
import { stringToDashDate, dashDateToString } from '../../utils/formatDate';

import customSelectStyles, { selectErrorStyles } from '../../styles/select_styles';
import styles from './profile.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import inputStyles from '../../styles/inputs.module.scss';
import getNestedValue from '../../utils/getNestedValue';

const PLACEHOLDER = {
  first_name: 'First name',
  last_name: 'Last name',
  birthday: 'Date of birth (MM.DD.YYYY)',
  about: 'About',
  address: 'Location',
  nickname: 'Username',
  profession: 'Profession',
  email: 'E-mail (Password retrieval)',
  currentPassword: 'Current password',
  height: 'Height (feet)',
  skill: 'Dance skill',
  goal: 'Goal',
  languages: 'Language(s)',
  gender: 'Gender',
  dancer_role: 'Dance role',
  dance_styles: 'Preferred Style(s) of Dance',
};

const GENDER_OPTIONS = [
  { value: 'male', label: 'male' },
  { value: 'female', label: 'female' },
  { value: '', label: 'unset' },
];

const ROLE_OPTIONS = [
  { value: 'lead', label: 'lead' },
  { value: 'follow', label: 'follow' },
  { value: 'lead_follow', label: 'lead & follow' },
  { value: 'solo', label: 'solo' },
  { value: '', label: 'unset' },
];

const SKILL_OPTIONS = [
  { value: 'beginner', label: 'beginner' },
  { value: 'intermediate', label: 'intermediate' },
  { value: 'advanced', label: 'advanced' },
  { value: 'professional', label: 'professional' },
  { value: '', label: 'unset' },
];

const GOAL_OPTIONS = [
  { value: 'social', label: 'social' },
  { value: 'training', label: 'training' },
  { value: 'performance', label: 'performance' },
  { value: 'competition', label: 'competition' },
  { value: '', label: 'unset' },
];

const OPTIONS = {
  gender: GENDER_OPTIONS,
  dancer_role: ROLE_OPTIONS,
  skill: SKILL_OPTIONS,
  goal: GOAL_OPTIONS,
  languages: [],
  dance_styles: [],
};

const SELECT_INPUTS = [
  'dance_styles',
  'gender',
  'dancer_role',
  'skill',
  'goal',
  'languages',
];

const INITIAL_STATE_FIELD = {
  value: '',
  isActive: false,
  isValid: true,
  error: null,
};

export default class ProfileForm extends Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      address: PropTypes.string,
      profession: PropTypes.string,
      nickname: PropTypes.string,
      prefered_dance_styles: PropTypes.arrayOf(PropTypes.string),
      email: PropTypes.string,
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
      birthday: PropTypes.string,
      about: PropTypes.string,
      dance_styles: PropTypes.array,
      gender: PropTypes.string,
      dancer_role: PropTypes.string,
    }).isRequired,
    editUser: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    startLoading: PropTypes.func.isRequired,
    finishLoading: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.string,
      }),
    }).isRequired,
    danceStyles: PropTypes.arrayOf(PropTypes.string).isRequired,
    getDanceStyles: PropTypes.func.isRequired,
    addMarker: PropTypes.func.isRequired,
    pinMode: PropTypes.bool.isRequired,
    hideFields: PropTypes.shape({
      email: PropTypes.bool,
      changePassword: PropTypes.bool,
    }),
  }

  static defaultProps = {
    hideFields: null,
  }

  state = {
    isEditing: false,
    isEmailEditing: false,
    currentPassword: {
      ...INITIAL_STATE_FIELD,
      showPassword: false,
    },
    user: {
      first_name: {
        ...INITIAL_STATE_FIELD,
      },
      last_name: {
        ...INITIAL_STATE_FIELD,
      },
      address: {
        ...INITIAL_STATE_FIELD,
      },
      nickname: {
        ...INITIAL_STATE_FIELD,
      },
      profession: {
        ...INITIAL_STATE_FIELD,
      },
      email: {
        ...INITIAL_STATE_FIELD,
      },
      birthday: {
        ...INITIAL_STATE_FIELD,
      },
      about: {
        ...INITIAL_STATE_FIELD,
      },
      dance_styles: {
        value: [],
        isValid: true,
      },
      gender: {
        value: '',
        isValid: true,
      },
      dancer_role: {
        value: '',
        isValid: true,
      },
      coordinates: null,
    },
    options: OPTIONS,
  }

  componentDidMount() {
    const { getDanceStyles } = this.props;
    this.setInitialState();
    getDanceStyles().then(this.createDanceStyleOptions);
    this.getLanguagesList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.danceStyles !== this.props.danceStyles) {
      this.createDanceStyleOptions();
    }
  }

  componentWillUnmount() {
    const { addMarker } = this.props;
    addMarker(null);
  }

  setInitialState = () => {
    const { user } = this.props;
    this.setState({
      user: {
        first_name: {
          ...INITIAL_STATE_FIELD,
          value: user.first_name || '',
        },
        last_name: {
          ...INITIAL_STATE_FIELD,
          value: user.last_name || '',
        },
        address: {
          ...INITIAL_STATE_FIELD,
          value: user.address || '',
          isAddressShown: true, // for AddressInput component working
        },
        nickname: {
          ...INITIAL_STATE_FIELD,
          value: user.nickname || '',
        },
        profession: {
          ...INITIAL_STATE_FIELD,
          value: user.profession || '',
        },
        email: {
          ...INITIAL_STATE_FIELD,
          value: user.email || '',
        },
        id: user.id,
        birthday: {
          ...INITIAL_STATE_FIELD,
          value: user.birthday ? dashDateToString(user.birthday) : '',
        },
        about: {
          ...INITIAL_STATE_FIELD,
          value: user.about ? user.about : '',
        },
        dance_styles: {
          value: (user.dance_styles && user.dance_styles.length !== 0)
            ? makeSelectOptions(user.dance_styles)
            : [],
          isValid: true,
        },
        gender: {
          value: user.gender ? makeSelectOptions(user.gender) : '',
          isValid: true,
        },
        dancer_role: {
          value: user.dancer_role ? makeSelectOptions(user.dancer_role) : '',
          isValid: true,
        },
        coordinates: user.coordinates,
        height: {
          ...INITIAL_STATE_FIELD,
          value: user.height || '',
        },
        skill: {
          value: user.skill ? makeSelectOptions(user.skill) : '',
          isValid: true,
        },
        goal: {
          value: user.goal ? makeSelectOptions(user.goal) : '',
          isValid: true,
        },
        languages: {
          value: user.languages ? makeSelectOptions(user.languages) : [],
          isValid: true,
        },
      },
    });
  }

  getLanguagesList = () => {
    const { startLoading, finishLoading, handleError } = this.props;

    startLoading();
    requestLanguages()
      .then((data) => {
        const list = data && data.data;

        if (list) {
          const languagesOptions = Object.values(list).map(lang => ({
            value: lang.name, label: lang.name,
          }));
          this.setState(prevState => ({
            options: {
              ...prevState.options,
              languages: languagesOptions,
            },
          }));
        }
      })
      .catch((err) => { handleError(err); })
      .finally(finishLoading);
  }

  createDanceStyleOptions = () => {
    const { danceStyles } = this.props;
    const danceStylesOptions = makeSelectOptions(danceStyles);
    this.setState(prevState => ({
      options: {
        ...prevState.options,
        dance_styles: danceStylesOptions,
      },
    }));
  }

  validateAddress = () => {
    const { user } = this.state;
    const address = getNestedValue(user, 'address', 'value');
    const coordinates = getNestedValue(user, 'coordinates');
    if (address && !coordinates) {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          address: {
            ...prevState.user.address,
            isValid: false,
            error: 'Select address from the list or on a map',
          },
        },
      }));

      return false;
    }
    return true;
  }

  setErrors = (errors) => {
    Object.keys(errors).forEach((item, i) => {
      if (this.state.user[item]) {
        this.setState(prevState => ({
          ...prevState,
          user: {
            ...prevState.user,
            [item]: {
              ...prevState.user[item],
              error: `${item}: ${errors[item].join(', ')}`,
            },
          },
        }));
        if (i === 0) {
          const elem = document.querySelector(`input[name=${item}], textarea[name=${item}], select[name=${item}]`);
          if (elem) {
            elem.focus();
          }
        }
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { editUser, token, handleEditStop } = this.props;
    const { user } = this.state;
    if (!this.validateAddress()) return;

    const userToSend = {};
    Object.keys(user).forEach((key) => {
      if (key === 'email') return;

      if (key === 'id') {
        userToSend[key] = user[key];
      } else if (key === 'coordinates') {
        if (user[key]) userToSend[key] = user[key];
      } else if (key === 'birthday') {
        userToSend[key] = stringToDashDate(user[key].value);
      } else if (SELECT_INPUTS.includes(key)) {
        userToSend[key] = getValueFromOptions(user[key].value);
      } else {
        userToSend[key] = user[key].value ? user[key].value.trim() : '';
      }
    });

    editUser(userToSend, token)
      .then((data) => {
        if (data.errors) {
          this.setErrors(data.errors);
        } else {
          handleEditStop();
        }
      });
  }

  handleInputBlur = (e) => {
    let { value } = e.target;
    const { name } = e.target;

    if (name === 'birthday') {
      value = e.target.rawValue;
    }

    if (value === '') {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          [name]: {
            ...prevState.user[name],
            isActive: false,
            value,
          },
        },
      }));
    }
  }

  handleInputChange = (e) => {
    let { value } = e.target;
    const { name } = e.target;
    let isValid = true;

    if (name === 'birthday' || name === 'height') {
      value = e.target.rawValue;
    }
    if (name === 'first_name' || name === 'last_name') {
      isValid = validateUserName(value);
    }
    if (name === 'nickname') {
      isValid = value === '' || validateUserName(value);
    }
    if (name === 'about') {
      isValid = value === '' || validateFeedback(value);
    }

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [name]: {
          value,
          isActive: true,
          isValid,
        },
      },
    }));
  }

  handleEmailChange = (e) => {
    const { value } = e.target;
    this.setState(prevState => ({
      isEmailEditing: value !== this.props.user.email,
      user: {
        ...prevState.user,
        email: {
          value,
          isActive: true,
        },
      },
    }));
  }

  handleEmailSubmit = () => {
    const {
      currentPassword: { value: password },
      user: { email: { value: email } },
    } = this.state;
    const {
      token,
      openModal,
      startLoading,
      finishLoading,
      handleError,
      user: userFromProps,
    } = this.props;

    const id = getNestedValue(userFromProps, 'id');

    if (password === '' || !validateEmail(email)) {
      return;
    }
    startLoading();
    changeUserEmail({ id, email, password }, token)
      .then(
        (res) => {
          if (res.status === 200) {
            openModal('CHANGE_EMAIL_REQUEST_SUCCES');
          }
        },
        (rej) => {
          const { status, data } = rej.response;

          if (status === 422) {
            if (data.errors && data.errors.current_password) {
              this.setState(prevState => ({
                ...prevState,
                currentPassword: {
                  ...prevState.currentPassword,
                  error: data.errors.current_password.join(', '),
                },
              }));
            }
            if (data.errors && data.errors.email) {
              this.setState(prevState => ({
                ...prevState,
                user: {
                  ...prevState.user,
                  email: {
                    ...prevState.user.email,
                    error: data.errors.email.join(', '),
                  },
                },
              }));
            }
          }
        },
      )
      .catch((err) => {
        handleError(err);
      })
      .finally(() => finishLoading());
  }

  handleShowPassword = (e, inputName) => {
    e.preventDefault();
    this.setState(prevState => ({
      ...prevState,
      [inputName]: {
        ...prevState[inputName],
        showPassword: !prevState[inputName].showPassword,
      },
    }));
  }

  handlePasswordChange = (e) => {
    const { value, name } = e.target;

    this.setState(prevState => ({
      [name]: {
        ...prevState[name],
        value,
        isActive: true,
        error: null,
      },
    }));
  }

  handleSelectChange = (value, action) => {
    const { name } = action;
    if (name) {
      this.setState(prevState => ({
        ...prevState,
        user: {
          ...prevState.user,
          [name]: {
            value,
            isValid: true,
            isActive: true,
            error: null,
          },
        },
      }));
    }
  }

  handleAddressChange = (address) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        address: {
          ...prevState.user.address,
          ...address,
        },
      },
    }));
  }

  handleCoordsChange = (coordinates) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        coordinates,
        address: {
          ...prevState.user.address,
          isValid: true,
          error: null,
        },
      },
    }));
  }

  renderInput = (inputName) => {
    const valueFromState = this.state.user[inputName];
    if (!valueFromState) return null;

    let fieldStyle = inputStyles.input_box_create;

    if (!valueFromState.isActive) {
      fieldStyle += ` ${inputStyles.not_active}`;
    }
    if (valueFromState.error || !valueFromState.isValid) {
      fieldStyle += ` ${inputStyles.is_error}`;
    }

    const labelStyle = (valueFromState.value || valueFromState.error)
      ? inputStyles.label__with_value : inputStyles.label;

    return (
      <div className={fieldStyle}>
        <input
          className={inputStyles.input}
          type="text"
          name={inputName}
          value={valueFromState.value}
          onChange={this.handleInputChange}
          onBlur={this.handleInputBlur}
        />
        <label
          htmlFor={inputName}
          className={labelStyle}
        >
          {valueFromState.error ? valueFromState.error : PLACEHOLDER[inputName]}
        </label>
      </div>
    );
  }

  renderEmailInput = () => {
    const { email } = this.state.user;
    let fieldStyle;
    let emailLabel = PLACEHOLDER.email;
    if (!email.isActive) {
      fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    } else if (!validateEmail(email.value)) {
      fieldStyle = `${inputStyles.input_box_create} ${inputStyles.is_error}`;
    } else if (email.error) {
      fieldStyle = `${inputStyles.input_box_create} ${inputStyles.is_error}`;
    } else {
      fieldStyle = inputStyles.input_box_create;
    }
    if (!validateEmail(email.value)) {
      emailLabel = 'Please enter valid email';
    }
    if (email.error) {
      emailLabel = email.error;
    }

    return (
      <div className={fieldStyle}>
        <input
          className={inputStyles.input}
          type="text"
          name="email"
          value={email.value}
          onChange={this.handleEmailChange}
        />
        <label
          htmlFor="email"
          className={email.value ? inputStyles.label__with_value : inputStyles.label}
        >
          {emailLabel}
        </label>
      </div>
    );
  }

  renderTextArea = () => {
    const { about } = this.state.user;

    let fieldStyle = inputStyles.textarea_box;

    if (!about.isActive) {
      fieldStyle += ` ${inputStyles.textarea_box_not_active}`;
    }

    if (about.error || !about.isValid) {
      fieldStyle = inputStyles.textarea_box_is_error;
    }

    const labelStyle = (about.value || about.error)
      ? inputStyles.label__with_value : inputStyles.label;

    return (
      <div className={fieldStyle}>
        <textarea
          className={inputStyles.textarea}
          type="text"
          name="about"
          value={about.value}
          onChange={this.handleInputChange}
          onBlur={this.handleInputBlur}
        />
        <label
          htmlFor="about"
          className={labelStyle}
        >
          {about.error ? about.error : PLACEHOLDER.about}
        </label>
      </div>
    );
  }

  renderPasswordInput = (inputName) => {
    const password = this.state[inputName];
    const { showPassword } = password;
    let fieldStyle;
    if (!password.isActive) {
      fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    } else {
      fieldStyle = password.error
        ? `${inputStyles.input_box_create} ${inputStyles.is_error}`
        : inputStyles.input_box_create;
    }

    return (
      <div className={fieldStyle}>
        <input
          className={inputStyles.input}
          type={showPassword ? 'text' : 'password'}
          name={inputName}
          value={password.value}
          onChange={this.handlePasswordChange}
          autoComplete="off"
        />
        <label
          htmlFor={inputName}
          className={password.value ? inputStyles.label__with_value : inputStyles.label}
        >
          {password.error ? password.error : PLACEHOLDER[inputName]}
        </label>
        <button
          className={inputStyles.eye_box}
          onClick={e => this.handleShowPassword(e, inputName)}
          type="button"
        >
          {showPassword ? <EyeHidden /> : <Eye />}
        </button>
      </div>
    );
  }

  renderCleaveInput = (name, options = {}) => {
    const { user } = this.state;
    const field = user[name];
    if (!field) return null;
    let fieldStyle;
    if (!field.isActive) {
      fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    } else {
      fieldStyle = field.error
        ? `${inputStyles.input_box_create} ${inputStyles.is_error}`
        : inputStyles.input_box_create;
    }

    return (
      <div className={fieldStyle}>
        <Cleave
          className={inputStyles.input}
          options={options}
          name={name}
          value={field.value}
          onChange={this.handleInputChange}
          onBlur={this.handleInputBlur}
        />
        <label
          htmlFor={name}
          className={field.value ? inputStyles.label__with_value : inputStyles.label}
        >
          {field.error ? field.error : PLACEHOLDER[name]}
        </label>
      </div>
    );
  }

  renderSelect = (name, selectProps = {}) => {
    const { user, options } = this.state;
    const value = user[name] && user[name].value;
    const error = user[name] && user[name].error;

    const selectStyles = error ? selectErrorStyles : customSelectStyles;

    return (
      <>
        {error && (
          <div className={inputStyles.error_text}>{error}</div>
        )}
        <Select
          value={value}
          onChange={this.handleSelectChange}
          options={options[name]}
          isSearchable={false}
          className={inputStyles.safari_input}
          styles={selectStyles}
          placeholder={PLACEHOLDER[name]}
          name={name}
          {...selectProps}
        />
      </>
    );
  }

  openChangePasswordModal = () => {
    const { openModal } = this.props;

    openModal('CHANGE_PASSWORD_MODAL');
  }

  render() {
    const { handleEditStop, hideFields } = this.props;
    const { isEmailEditing, user: userFromState } = this.state;

    const { address } = userFromState;

    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderInput('first_name')}
        {this.renderInput('last_name')}
        {this.renderCleaveInput(
          'birthday',
          {
            date: true,
            datePattern: ['m', 'd', 'Y'],
            delimiters: ['.'],
          },
        )}
        {this.renderSelect('gender')}
        {this.renderSelect('dancer_role')}
        {this.renderTextArea()}
        <AddressInput
          address={address}
          onAddressChange={this.handleAddressChange}
          onCoordsChange={this.handleCoordsChange}
        />
        {this.renderInput('nickname')}
        {this.renderSelect(
          'dance_styles',
          { isMulti: true, isSearchable: true, closeMenuOnSelect: false },
        )}
        {this.renderInput('profession')}
        {this.renderCleaveInput('height', { numeral: true })}
        {this.renderSelect('skill')}
        {this.renderSelect('goal')}
        {this.renderSelect(
          'languages',
          { isSearchable: true, isMulti: true, closeMenuOnSelect: false },
        )}
        {(hideFields && hideFields.email) ? null : this.renderEmailInput()}
        {isEmailEditing && (
          <>
            {this.renderPasswordInput('currentPassword')}
            <div className={styles.btns_center}>
              <button
                type="button"
                className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                onClick={this.handleEmailSubmit}
              >
                Change email
              </button>
            </div>
          </>
        )}

        {(hideFields && hideFields.changePassword) ? null : (
          <div className={styles.btns_center}>
            <button
              type="button"
              className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
              onClick={this.openChangePasswordModal}
            >
              Change password
            </button>
          </div>
        )}

        <div className={styles.btns_box}>
          <button
            type="submit"
            className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
            onClick={this.handleSubmit}
          >
            Save
          </button>
          <button
            type="button"
            className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
            onClick={handleEditStop}
          >
            Cancel
          </button>
        </div>
      </form>
    );
  }
}
