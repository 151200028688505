import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import gridStyles from '../../styles/main_grid.module.scss';
import getNestedValue from '../../utils/getNestedValue';
import QRIcon from '../icons/QR_done';
import DatePicker from '../shared/Date_picker/Date_picker_analytics';
import { getDollarsFromCents } from '../../utils/parse';
import CalendarIcon from '../icons/Calendar';
import CancelIcon from '../icons/Cancel_icon';

import Navigation from '../CheckinsHostsReportsNav/CheckinsHostSReportsNav';
import AnalyticsRow from './Analytics_row';
import styles from './analytics.module.scss';
import { HOST } from '../../config';

const INITIAL_STATE = {
  tickets: [],
  title: '',
  totalCheckins: 0,
  totalSold: 0,
  totalRevenue: 0,
  date: '',
  minDate: null,
  itemEndDate: null,
  period: 'unset',
};

export default class EventAnalytics extends Component {
  static propTypes = {
    getEventAnalytics: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      host_code: PropTypes.string,
    }).isRequired,
  };

  state = {
    ...INITIAL_STATE,
  };

  componentDidMount() {
    this.getAnalytics();
  }

  getAnalytics = () => {
    const { getEventAnalytics, match } = this.props;
    const id = match && match.params && match.params.id;
    const [start, end] = this.state.date;

    const params = start && end
      ? {
        start_date: moment(start).format('YYYY-MM-DD'),
        end_date: moment(end).format('YYYY-MM-DD'),
      } : null;
    if (id && getEventAnalytics) {
      getEventAnalytics(
        id,
        params,
      ).then((res) => {
        const data = getNestedValue(res, 'data', 'data');

        if (data) {
          const {
            tickets = [],
            item,
            totals,
            start_date: startDate,
            end_date: endDate,
          } = data;
          const totalCheckins = getNestedValue(totals, 'checkins') || 0;
          const totalSold = getNestedValue(totals, 'sold') || 0;
          const totalRevenue = getNestedValue(totals, 'revenue') || 0;
          const title = getNestedValue(item, 'title') || '';
          const permissions = getNestedValue(item, 'permissions');
          const itemEndDate = getNestedValue(item, 'end_date');
          let minDate = getNestedValue(item, 'created_at');
          minDate = minDate ? new Date(minDate) : null;

          if (startDate && endDate) {
            this.setState({
              date: [
                new Date(startDate),
                new Date(endDate),
              ],
            });
          }

          this.setState({
            tickets,
            title,
            totalCheckins,
            totalSold,
            totalRevenue,
            minDate,
            itemEndDate,
            permissions,
          });
        }
      });
    }
  }

  handleClose = () => {
    const { history, location } = this.props;
    const from = getNestedValue(location, 'state', 'from') || '/';
    history.push(from);
  }

  handleDateChange = (date) => {
    this.setState({ date }, () => {
      if (date[0] && date[1]) {
        this.getAnalytics();
      }
    });
  }

  handlePeriodSelect = (e) => {
    const { value } = e.target;
    let date;
    switch (value) {
      case 'day':
        date = [moment(), moment()];
        break;

      case 'week':
        date = [moment().subtract(7, 'days'), moment()];
        break;

      case 'month':
        date = [moment().subtract(1, 'months'), moment()];
        break;

      case 'year':
        date = [moment().subtract(1, 'years'), moment()];
        break;

      default:
        date = [];
    }

    this.setState({
      period: value,
      date,
    }, this.getAnalytics);
  }

  hideDateInputs = () => {
    this.setState({
      period: 'unset',
      date: [],
    }, this.getAnalytics);
  }

  render() {
    const {
      tickets,
      title,
      totalCheckins,
      totalSold,
      totalRevenue,
      date,
      minDate,
      itemEndDate,
      period,
      permissions,
    } = this.state;
    const { match, history, user } = this.props;
    const hostCode = user && user.host_code;

    const isItemPassed = itemEndDate && moment(itemEndDate).isBefore(moment().subtract(1, 'hours'));

    return (
      <div className={`${gridStyles.wrapper_fixed_bottom} ${gridStyles.mobile_on_top}`}>
        <div className={styles.scroll_container}>
          <Navigation
            history={history}
            match={match}
            hostCode={hostCode}
            title={title}
            isItemPassed={isItemPassed}
            hostingStatus={HOST}
            permissions={permissions}
          />

          <table className={styles.table_root}>
            <thead className={styles.table_head}>
              <tr>
                <td className={styles.table_title}>
                  Ticket list
                </td>
                <td className={`${styles.green_center}`}>
                  <QRIcon className={styles.qr_icon} />
                </td>
                <td className={`${styles.table_subtitle} ${styles.blue_center}`}>
                  Sold
                </td>
                <td className={`${styles.td_right} ${styles.table_subtitle}`}>
                  Revenue
                </td>
              </tr>
            </thead>

            <tbody className={styles.table_body}>
              {tickets.map(ticket => (
                <AnalyticsRow key={ticket.id} ticket={ticket} />
              ))}
              <tr className={styles.total_row}>
                <td className={styles.table_title}>
                  Total
                </td>
                <td className={styles.green_center}>
                  {totalCheckins}
                </td>
                <td className={styles.blue_center}>
                  {totalSold}
                </td>
                <td className={styles.td_right}>
                  {getDollarsFromCents(totalRevenue)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.date_picker_wrapper}>
          {period === 'custom' ? (
            <div className={styles.btns_row}>
              <div className={styles.rest_width}>
                <DatePicker
                  onDateChange={this.handleDateChange}
                  value={date}
                  renderOnLeftSide
                  minDate={minDate}
                />
              </div>
              <button type="button" onClick={this.hideDateInputs} className={styles.cancel_btn}>
                <CancelIcon className={styles.cancel_icon} />
              </button>
            </div>
          ) : (
            <ul className={styles.btns_row}>
              <li className={styles.period_item}>
                <label>
                  <input
                    type="radio"
                    value="day"
                    checked={period === 'day'}
                    name="period"
                    className={styles.radio}
                    onChange={this.handlePeriodSelect}
                  />
                  <div className={styles.period_btn}>Day</div>
                </label>
              </li>
              <li className={styles.period_item}>
                <label>
                  <input
                    type="radio"
                    value="week"
                    checked={period === 'week'}
                    name="period"
                    className={styles.radio}
                    onChange={this.handlePeriodSelect}
                  />
                  <div className={styles.period_btn}>Week</div>
                </label>
              </li>
              <li className={styles.period_item}>
                <label>
                  <input
                    type="radio"
                    value="month"
                    checked={period === 'month'}
                    name="period"
                    className={styles.radio}
                    onChange={this.handlePeriodSelect}
                  />
                  <div className={styles.period_btn}>Month</div>
                </label>
              </li>
              <li className={styles.period_item}>
                <label>
                  <input
                    type="radio"
                    value="year"
                    checked={period === 'year'}
                    name="period"
                    className={styles.radio}
                    onChange={this.handlePeriodSelect}
                  />
                  <div className={styles.period_btn}>Year</div>
                </label>
              </li>
              <li className={styles.period_item}>
                <label>
                  <input
                    type="radio"
                    value="unset"
                    checked={period === 'unset'}
                    name="period"
                    className={styles.radio}
                    onChange={this.handlePeriodSelect}
                  />
                  <div className={styles.period_btn}>&#8734;</div>
                </label>
              </li>
              <li className={styles.period_item}>
                <label>
                  <input
                    type="radio"
                    value="custom"
                    checked={period === 'custom'}
                    name="period"
                    className={styles.radio}
                    onChange={this.handlePeriodSelect}
                  />
                  <div className={styles.period_btn}>
                    <CalendarIcon className={styles.calendar_icon} />
                  </div>
                </label>
              </li>
            </ul>
          )}
        </div>
      </div>
    );
  }
}
