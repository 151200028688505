import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import getNestedValue from '../../utils/getNestedValue';
import { SET_FRIEND_TO_DELETE, RESET_FRIEND_TO_DELETE } from '../../redux/action_types';
import { setAndOpenModal } from '../Modal/modal_actions';

import {
  requestFriendsList,
  requestSearchFriends,
  requestFriendship,
  requestFriendsRequests,
  requestFriendsInvites,
  requestAcceptFriend,
  requestDeclineFriend,
  requestDeleteFriend,
  requestCancelRequest,
  requestFriendsInterested,
} from '../../api';

const getErrorMessage = (err) => {
  const status = getNestedValue(err, 'response', 'status');
  const messageArray = getNestedValue(err, 'response', 'data', 'errors', 'friend');
  let errMessage = 'Something went wrong...';
  if (status === 422 && Array.isArray(messageArray)) {
    errMessage = messageArray.join(',');
  }

  return errMessage;
};

export const getFriends = list => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());

  let request;
  switch (list) {
    case 'requests':
      request = requestFriendsRequests;
      break;
    case 'invites':
      request = requestFriendsInvites;
      break;
    default:
      request = requestFriendsList;
  }

  return request(token)
    .catch(err => dispatch(createError(err)))
    .finally(() => dispatch(finishLoading()));
};

export const searchUsers = query => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());

  return requestSearchFriends({ token, query })
    .catch(err => dispatch(createError(err)))
    .finally(() => dispatch(finishLoading()));
};

export const inviteToFriends = userId => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());

  return requestFriendship({ token, userId })
    .catch((err) => {
      const errMessage = getErrorMessage(err);
      dispatch(createError(errMessage));
    })
    .finally(() => dispatch(finishLoading()));
};


export const acceptFriend = userId => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());

  return requestAcceptFriend({ token, userId })
    .catch((err) => {
      const errMessage = getErrorMessage(err);

      dispatch(createError(errMessage));
    })
    .finally(() => dispatch(finishLoading()));
};

export const declineFriend = userId => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());

  return requestDeclineFriend({ token, userId })
    .catch((err) => {
      const errMessage = getErrorMessage(err);

      dispatch(createError(errMessage));
    })
    .finally(() => dispatch(finishLoading()));
};

export const deleteFriend = userId => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());

  return requestDeleteFriend({ token, userId })
    .catch((err) => {
      const errMessage = getErrorMessage(err);

      dispatch(createError(errMessage));
    })
    .finally(() => dispatch(finishLoading()));
};

export const cancelRequest = userId => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());

  return requestCancelRequest({ token, userId })
    .catch((err) => {
      const errMessage = getErrorMessage(err);

      dispatch(createError(errMessage));
    })
    .finally(() => dispatch(finishLoading()));
};

export const getFriendsInterested = ({ type, id, query }) => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) return null;

  dispatch(startLoading());

  return requestFriendsInterested({ token, type, id, query })
    .catch(err => dispatch(createError(err)))
    .finally(() => dispatch(finishLoading()));
};


export const setFriendToDelete = friend => ({
  type: SET_FRIEND_TO_DELETE,
  payload: friend,
});

export const resetFriendToDelete = () => ({
  type: RESET_FRIEND_TO_DELETE,
});

export const openDeleteFriendModal = friend => (dispatch) => {
  dispatch(setFriendToDelete(friend));
  dispatch(setAndOpenModal('DELETE_FRIEND_MODAL'));
};
