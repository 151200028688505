import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import defaultAvatar from '../../../images/default_avatar.jpg';
import styles from './user_link.module.scss';

const UserLink = ({ user, history, status }) => {
  if (!user) return null;

  const {
    first_name: firstName,
    last_name: lastName,
    nickname,
    id,
  } = user;
  let secondaryName = '';
  if (firstName) secondaryName += firstName;
  if (lastName) secondaryName += ` ${lastName}`;
  const mainName = nickname || secondaryName || 'Anonymous';

  const path = {
    pathname: `/users/${id}`,
    state: { from: history.location.pathname },
  };

  const avatarPath = user.avatar && user.avatar.thumb
    ? user.avatar.thumb : defaultAvatar;

  return (
    <Link to={path} className={styles.link_wrapper}>
      <div
        className={styles.avatar}
        style={{ backgroundImage: `url(${avatarPath})` }}
      />

      <div className={styles.name_box}>
        <p>{mainName}</p>
        {nickname ? (
          <p className={styles.secondary_name}>{secondaryName}</p>
        ) : null}

        {status && (
          <div className={styles.status}>
            {status}
          </div>
        )}
      </div>
    </Link>
  );
};

UserLink.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  status: PropTypes.string,
};

UserLink.defaultProps = {
  status: '',
};

export default withRouter(UserLink);
