import { connect } from 'react-redux';
import Feedback from './Feedback';
import { sendFeedback } from './feedback_actions';
import handleError from '../Fetch_error/fetch_error_action';

const mSTP = state => ({
  email: state.session.user && state.session.user.email,
});

const mDTP = { sendFeedback, handleError };

export default connect(mSTP, mDTP)(Feedback);
