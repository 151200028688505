import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="23" height="26">
    <path d="M22.33 22.486l-.002.007a3.58 3.58 0 0 1-1.075 2.477 3.529 3.529 0 0 1-2.492 1.025H4.234c-1.968 0-3.57-1.614-3.57-3.596V3.66c0-1.983 1.602-3.598 3.57-3.598h14.527c1.968 0 3.57 1.615 3.57 3.598v18.779l-.001.047zM20.144 3.66a1.39 1.39 0 0 0-1.383-1.394H4.234A1.39 1.39 0 0 0 2.852 3.66v18.739c0 .767.62 1.394 1.382 1.394h14.527a1.39 1.39 0 0 0 1.383-1.394V3.66zm-3.266 10.569H6.117a1.1 1.1 0 0 1-1.093-1.104 1.1 1.1 0 0 1 1.093-1.101h10.761a1.1 1.1 0 0 1 1.094 1.101 1.1 1.1 0 0 1-1.094 1.104zm-3.466-4.923H6.117a1.1 1.1 0 0 1-1.093-1.104c0-.607.49-1.101 1.093-1.101h7.295c.603 0 1.094.494 1.094 1.101a1.1 1.1 0 0 1-1.094 1.104zm-7.295 7.64h5.644a1.1 1.1 0 0 1 1.093 1.104c0 .607-.49 1.101-1.093 1.101H6.117a1.098 1.098 0 0 1-1.093-1.101 1.1 1.1 0 0 1 1.093-1.104z" />
  </svg>
);

InfoIcon.propTypes = {
  className: PropTypes.string,
};

InfoIcon.defaultProps = {
  className: '',
};

export default InfoIcon;
