import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import qs from 'qs';

import Marker from './Marker';
import LocationButton from './Location_button';
import SameCoordsMarker from './Same_coords_marker';
import UserPoint from './User_point';

import MarkerIcon from '../icons/Marker';
import CenterPin from '../icons/CenterPin';
import Checkmark from '../icons/Checkmark';
import getNestedValue from '../../utils/getNestedValue';

import mapStyles from './map_styles';
import markerStyles from './marker.module.scss';
import styles from './map.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

import {
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  DETAILED_ZOOM,
  MAPS_API_KEY,
} from '../../config';

const createMapOptions = maps => ({
  zoomControlOptions: {
    position: maps.ControlPosition.RIGHT_TOP,
    style: maps.ZoomControlStyle.SMALL,
  },
  mapTypeControl: false,
  fullscreenControl: false,
  styles: mapStyles,
});

class Map extends Component {
  static propTypes = {
    center: PropTypes.shape({
      lat: PropTypes.number,
      lan: PropTypes.number,
    }),
    moveMapCenterTo: PropTypes.func.isRequired,
    userLocation: PropTypes.shape({
      lat: PropTypes.number,
      lan: PropTypes.number,
    }),
    showUserPoint: PropTypes.func.isRequired,
    newMarker: PropTypes.shape({
      lat: PropTypes.number,
      lan: PropTypes.number,
    }),
    markers: PropTypes.arrayOf(PropTypes.shape({
      lat: PropTypes.number,
      lan: PropTypes.number,
    })),
    createError: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    startLoading: PropTypes.func.isRequired,
    finishLoading: PropTypes.func.isRequired,
    resetFitMapFlag: PropTypes.func.isRequired,
    pinMode: PropTypes.bool.isRequired,
    setMapCenterAddress: PropTypes.func.isRequired,
    mapFitFlag: PropTypes.bool,
    zoomFlag: PropTypes.bool,
    resetZoomMap: PropTypes.func.isRequired,
    setOffPinMode: PropTypes.func.isRequired,
    toggleMoveMapSearch: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    setOffMoveMapSearch: PropTypes.func.isRequired,
    searchOnMoveMap: PropTypes.bool.isRequired,
    setOnMoveMapSearch: PropTypes.func.isRequired,
    hoverId: PropTypes.string,
  };

  static defaultProps = {
    center: DEFAULT_CENTER,
    userLocation: null,
    newMarker: null,
    markers: null,
    mapFitFlag: false,
    zoomFlag: false,
    hoverId: null,
  };

  componentDidMount() {
    // for IOS webview app
    window.setUserLocation = this.setUserLocationFromWrapper;
    window.showError = this.showMapError;
    // end for IOS

    this.getUserLocation();

    const { history, setOnMoveMapSearch } = this.props;
    const search = getNestedValue(history, 'location', 'search');
    if (search && search.includes('geo_bounds') && setOnMoveMapSearch) setOnMoveMapSearch();
  }

  componentDidUpdate(prevProps) {
    const {
      markers, mapFitFlag, zoomFlag,
      setOffMoveMapSearch, searchOnMoveMap,
    } = this.props;

    if (mapFitFlag && markers && markers.length > 0) {
      this.fitMapToBounds();
    } else if (zoomFlag) this.zoomMap();

    const relevantMapMoveSearch = this.checkAbilityMoveMapSearch();

    if (!relevantMapMoveSearch && searchOnMoveMap) {
      setOffMoveMapSearch();
    } else if (relevantMapMoveSearch && !prevProps.searchOnMoveMap) {
      this.handleMapChange();
    }
  }

  apiIsLoaded = ({ map, maps }) => {
    this.map = map;
    this.maps = maps;
    this.fitMapToBounds();
    this.maps.event.addListener(this.map, 'idle', this.handleMapChange);
  }

  getMapBounds = ({ maps, markers }) => {
    const bounds = new maps.LatLngBounds();
    markers.forEach((place) => {
      bounds.extend(new maps.LatLng(
        place.coordinates.lat,
        place.coordinates.lng,
      ));
    });
    return bounds;
  };

  fitMapToBounds = () => {
    const { markers, resetFitMapFlag } = this.props;
    const { map, maps } = this;
    if (map && maps && markers && markers.length > 0) {
      const bounds = this.getMapBounds({ maps, markers });
      map.fitBounds(bounds);
      if (markers.length === 1) {
        map.setZoom(DETAILED_ZOOM);
      }
      resetFitMapFlag();
    }
  }

  zoomMap = () => {
    const { zoomFlag, resetZoomMap, center } = this.props;
    const { map } = this;
    if (map && zoomFlag && center) {
      map.setCenter(center);
      if (map.zoom < DEFAULT_ZOOM) map.setZoom(DETAILED_ZOOM);
      resetZoomMap();
    }
  }

  getUserLocation = () => {
    if (window.getUserLocation) {
      window.getUserLocation();
    } else {
      const {
        moveMapCenterTo,
        showUserPoint,
        createError,
        startLoading,
        finishLoading,
      } = this.props;

      try {
        if (navigator.geolocation) {
          startLoading();
          navigator.geolocation.getCurrentPosition(
            (position) => {
              finishLoading();
              const {
                coords: {
                  latitude,
                  longitude,
                },
              } = position;
              moveMapCenterTo({
                lat: latitude,
                lng: longitude,
              });
              showUserPoint({
                lat: latitude,
                lng: longitude,
              });
            },
            (err) => {
              finishLoading();
              if (err.message && err.code !== 3) {
                createError(err.message);
              } else if (err && err.code !== 3) {
                createError(err);
              }
            },
            { timeout: 10000, maximumAge: 60000 },
          );
        }
      } catch (err) {
        moveMapCenterTo(DEFAULT_CENTER);
      }
    }
  }

  setUserLocationFromWrapper = (lat, lng) => {
    const {
      moveMapCenterTo,
      showUserPoint,
    } = this.props;
    if ((lat || lat === 0) && (lng || lng === 0)) {
      moveMapCenterTo({ lat, lng });
      showUserPoint({ lat, lng });
    }
  }

  showMapError = (error) => {
    const { createError } = this.props;
    let errorText;

    switch (error) {
      case 'restricted':
        errorText = 'The app is not authorized to use location services';
        break;
      case 'denied':
        errorText = 'The user denied the use of location services for the app or they are disabled globally in Settings';
        break;
      case 'notDetermined':
        errorText = 'The user has not chosen whether the app can use location services';
        break;
      default:
        errorText = error;
    }
    createError(errorText || 'Problems with geolocation');
  }

  handleMapChange = () => {
    const { map } = this;
    const { history } = this.props;
    const { searchOnMoveMap } = this.props;
    if (!map || !history || !searchOnMoveMap) return;

    const mapBounds = map.getBounds();
    const northEast = mapBounds.getNorthEast();
    const southWest = mapBounds.getSouthWest();

    if (northEast && southWest) {
      const search = getNestedValue(history, 'location', 'search');
      const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
      queryObject.geo_bounds = `[${northEast.lng()},${northEast.lat()},${southWest.lng()},${southWest.lat()}]`;
      history.push({ search: qs.stringify(queryObject) });
    }
  }

  createAddress = () => {
    const { map, maps } = this;
    const { setMapCenterAddress, setOffPinMode } = this.props;
    if (map && maps) {
      const center = map.getCenter();
      const lat = center.lat();
      const lng = center.lng();

      if ((lat || lat === 0) && (lng || lng === 0)) {
        const latLng = new maps.LatLng(lat, lng);
        const geoCoder = new maps.Geocoder();

        geoCoder.geocode({ latLng }, (result, status) => {
          if (status === 'OK' && result[0]) {
            const { formatted_address: address } = result[0];
            setMapCenterAddress({ coordinates: { lat, lng }, address });
            setOffPinMode();
          }
        });
      }
    }
  }

  checkAbilityMoveMapSearch = () => {
    const { history } = this.props;

    const path = getNestedValue(
      history, 'location', 'pathname',
    );

    const relevantMapMoveSearch = /^\/events|\/venues|\/teachers/.test(path) || path === '/';

    return relevantMapMoveSearch;
  }

  renderAllMarkers = () => {
    const { markers, newMarker, history } = this.props;

    if (newMarker) {
      const sameCoordsItemsForNewMarker = [];
      const otherMarkers = [];
      markers.forEach((marker) => {
        const lat = marker.coordinates && marker.coordinates.lat;
        const lng = marker.coordinates && marker.coordinates.lng;
        if (lat === newMarker.lat && lng === newMarker.lng) {
          sameCoordsItemsForNewMarker.push(marker);
        } else otherMarkers.push(marker);
      });
      const otherMarkersMarkUp = this.renderMarkers(otherMarkers);
      const newMarkerMarkUp = sameCoordsItemsForNewMarker.length > 0
        ? (
          <SameCoordsMarker
            key={`${newMarker.lat}${newMarker.lng}`}
            items={sameCoordsItemsForNewMarker}
            lat={newMarker.lat}
            lng={newMarker.lng}
            isActive
            history={history}
          />
        )
        : (
          <MarkerIcon
            key={`${newMarker.lat}${newMarker.lng}`}
            className={markerStyles.marker_active}
            lat={newMarker.lat}
            lng={newMarker.lng}
          />
        );

      return otherMarkersMarkUp
        ? [...otherMarkersMarkUp, newMarkerMarkUp]
        : newMarkerMarkUp;
    }
    return this.renderMarkers(markers);
  }

  renderMarkers = (markers) => {
    const { location, history, hoverId } = this.props;

    if (markers && markers.length > 0) {
      const markersScope = {};
      markers.forEach((marker) => {
        if (markersScope[`${marker.coordinates.lat}_${marker.coordinates.lng}`]) {
          markersScope[`${marker.coordinates.lat}_${marker.coordinates.lng}`].push(marker);
        } else {
          markersScope[`${marker.coordinates.lat}_${marker.coordinates.lng}`] = [marker];
        }
      });
      return Object.keys(markersScope).map((newMarker) => {
        if (markersScope[newMarker].length === 1) {
          return (
            <Marker
              key={markersScope[newMarker][0].id}
              item={markersScope[newMarker][0]}
              lat={markersScope[newMarker][0].coordinates.lat}
              lng={markersScope[newMarker][0].coordinates.lng}
              pathname={location.pathname}
              history={history}
              hoverId={hoverId}
            />
          );
        }
        return (
          <SameCoordsMarker
            key={`${newMarker}`}
            items={markersScope[newMarker]}
            lat={markersScope[newMarker][0].coordinates.lat}
            lng={markersScope[newMarker][0].coordinates.lng}
            history={history}
            hoverId={hoverId}
          />
        );
      });
    }

    return null;
  }

  render() {
    const {
      userLocation,
      pinMode,
      setOffPinMode,
      toggleMoveMapSearch,
      searchOnMoveMap,
    } = this.props;

    const relevantMapMoveSearch = this.checkAbilityMoveMapSearch();

    return (
      // Important! Always set the container height explicitly
      <div className={styles.map_container}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAPS_API_KEY, libraries: 'places' }}
          defaultCenter={DEFAULT_CENTER}
          defaultZoom={DEFAULT_ZOOM}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.apiIsLoaded}
        >
          {this.renderAllMarkers()}
          {userLocation && (
            <UserPoint
              className={markerStyles.user_point}
              lat={userLocation.lat}
              lng={userLocation.lng}
            />
          )}
        </GoogleMapReact>

        {relevantMapMoveSearch ? (
          <label className={styles.checkbox_container}>
            <input type="checkbox" name="total_visits" checked={searchOnMoveMap} onChange={toggleMoveMapSearch} />
            <div className={styles.checkmark}>
              {searchOnMoveMap && <Checkmark className={styles.checkmark_icon} />}
            </div>
            <h5 className={styles.checkmark_text}>
              Search as I move map
            </h5>
          </label>
        ) : null}

        {pinMode && (
          <>
            <CenterPin className={markerStyles.center_pin} />
            <div className={styles.btn_row}>
              <button type="button" className={buttonStyles.btn_blue} onClick={this.createAddress}>Select</button>
              <button type="button" className={buttonStyles.btn_red} onClick={setOffPinMode}>Cancel</button>
            </div>
          </>
        )}

        <LocationButton onClick={this.getUserLocation} />
      </div>
    );
  }
}

export default Map;
