import { FETCH_ERROR, HIDE_ERROR } from '../../redux/action_types';

export const resetError = () => ({
  type: HIDE_ERROR,
});

const createError = err => (dispatch) => {
  dispatch({
    type: FETCH_ERROR,
    payload: err,
  });

  setTimeout(() => dispatch(resetError()), 6000);
};

export default createError;
