import {
  requestCreatePass,
  requestEditPass,
  requestTeacherClasses,
} from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { setAndOpenModal, setModalMessage } from '../Modal/modal_actions';
import { signOut } from '../login/login_actions';
import createError from '../Fetch_error/fetch_error_action';

import getNestedValue from '../../utils/getNestedValue';

export const createPass = pass => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestCreatePass(pass, token)
    .then(response => response.data && response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      dispatch(createError(rej));
      return rej.response;
    })
    .finally(() => dispatch(finishLoading()));
};

export const onPassSubmitSuccess = ({ action, isItemFree }) => (dispatch, getState) => {
  if (action === 'create') {
    dispatch(setModalMessage('Pass was created successfully.'));
  }

  if (action === 'edit') {
    dispatch(setModalMessage('Pass was edited successfully.'));
  }

  if (action === 'create' || action === 'edit') {
    const { user } = getState().session;
    const connectStatus = !!user.connect;
    const chargesStatus = getNestedValue(user, 'connect', 'charges_enabled');
    const payoutsStatus = getNestedValue(user, 'connect', 'payouts_enabled');
    const forwardUserToStripe = !connectStatus || !chargesStatus || !payoutsStatus;
    if (forwardUserToStripe && !isItemFree) {
      dispatch(setAndOpenModal('STRIPE_CONNECT_MODAL'));
    } else dispatch(setAndOpenModal('SUCCES_MODAL_MESSAGE'));
  }
};

export const editPass = pass => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestEditPass(pass, token)
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 403) {
        dispatch(createError('Sorry, editing pass is forbidden.'));
        return rej.response && rej.response.data;
      }
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      dispatch(createError(rej));
      return rej;
    })
    .finally(() => dispatch(finishLoading()));
};

export const getTeacherClasses = id => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestTeacherClasses(id, token)
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      dispatch(createError(rej));
      return null;
    })
    .finally(() => dispatch(finishLoading()));
};
