import React from 'react';
import PropTypes from 'prop-types';

const Filter = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="17">
    <path fillRule="evenodd" d="M17.132 3.594H9.835C9.463 4.72 8.371 5.511 7.131 5.511c-1.241 0-2.332-.791-2.704-1.917H.865a.853.853 0 0 1-.863-.84c0-.462.387-.839.863-.839h3.561C4.798.788 5.887-.002 7.127-.002c1.241 0 2.333.79 2.705 1.917h7.3c.477 0 .864.377.864.839 0 .463-.387.84-.864.84zM7.131 1.677c-.612 0-1.11.483-1.11 1.077 0 .595.498 1.078 1.11 1.078.612 0 1.11-.483 1.11-1.078 0-.594-.498-1.077-1.11-1.077zM.865 7.642h7.624c.371-1.127 1.462-1.917 2.704-1.917 1.242 0 2.333.79 2.704 1.917h3.232c.476 0 .864.376.864.839a.853.853 0 0 1-.864.839h-3.232c-.371 1.127-1.462 1.917-2.704 1.917-1.241 0-2.333-.79-2.704-1.917H.865a.852.852 0 0 1-.863-.839c0-.463.387-.839.863-.839zm10.328 1.917c.612 0 1.11-.484 1.11-1.078 0-.594-.498-1.078-1.11-1.078-.611 0-1.109.484-1.109 1.078 0 .594.498 1.078 1.109 1.078zM.865 13.403h3.562c.372-1.126 1.461-1.916 2.7-1.916 1.241 0 2.333.79 2.705 1.916h7.297c.476 0 .864.377.864.839a.853.853 0 0 1-.864.839H9.835c-.372 1.127-1.463 1.917-2.704 1.917s-2.332-.79-2.704-1.917H.865a.852.852 0 0 1-.863-.839c0-.462.387-.839.863-.839zm6.266 1.917c.612 0 1.11-.483 1.11-1.078 0-.594-.498-1.077-1.11-1.077-.612 0-1.11.483-1.11 1.077 0 .595.498 1.078 1.11 1.078z" />
  </svg>
);

Filter.propTypes = {
  className: PropTypes.string,
};

Filter.defaultProps = {
  className: '',
};

export default Filter;
