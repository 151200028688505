import React from 'react';
import PropTypes from 'prop-types';

const LocationIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43 61.64C53.2946 61.64 61.64 53.2946 61.64 43C61.64 32.7054 53.2946 24.36 43 24.36C32.7054 24.36 24.36 32.7054 24.36 43C24.36 53.2946 32.7054 61.64 43 61.64Z" fill="#0078F2" />
    <path d="M81.542 38.8H78.825C77.8785 30.74 74.2423 23.2345 68.5039 17.4961C62.7655 11.7577 55.26 8.12147 47.2 7.175V4.458C47.2 3.34409 46.7575 2.2758 45.9698 1.48815C45.1822 0.700494 44.1139 0.257996 43 0.257996C41.8861 0.257996 40.8178 0.700494 40.0301 1.48815C39.2425 2.2758 38.8 3.34409 38.8 4.458V7.175C30.74 8.12147 23.2345 11.7577 17.4961 17.4961C11.7577 23.2345 8.12147 30.74 7.175 38.8H4.458C3.34409 38.8 2.2758 39.2425 1.48815 40.0301C0.700494 40.8178 0.257996 41.8861 0.257996 43C0.257996 44.1139 0.700494 45.1822 1.48815 45.9698C2.2758 46.7575 3.34409 47.2 4.458 47.2H7.175C8.12147 55.26 11.7577 62.7655 17.4961 68.5039C23.2345 74.2423 30.74 77.8785 38.8 78.825V81.542C38.8 82.6559 39.2425 83.7242 40.0301 84.5118C40.8178 85.2995 41.8861 85.742 43 85.742C44.1139 85.742 45.1822 85.2995 45.9698 84.5118C46.7575 83.7242 47.2 82.6559 47.2 81.542V78.825C55.26 77.8785 62.7655 74.2423 68.5039 68.5039C74.2423 62.7655 77.8785 55.26 78.825 47.2H81.542C82.6559 47.2 83.7242 46.7575 84.5118 45.9698C85.2995 45.1822 85.742 44.1139 85.742 43C85.742 41.8861 85.2995 40.8178 84.5118 40.0301C83.7242 39.2425 82.6559 38.8 81.542 38.8ZM43 69.048C37.8482 69.048 32.8121 67.5203 28.5285 64.6581C24.2449 61.7959 20.9063 57.7278 18.9348 52.9681C16.9633 48.2085 16.4474 42.9711 17.4525 37.9183C18.4576 32.8655 20.9384 28.2242 24.5813 24.5813C28.2242 20.9384 32.8655 18.4576 37.9183 17.4525C42.9711 16.4474 48.2085 16.9633 52.9681 18.9348C57.7278 20.9063 61.7959 24.2449 64.6581 28.5285C67.5203 32.8121 69.048 37.8482 69.048 43C69.048 46.4207 68.3742 49.8078 67.0652 52.9681C65.7562 56.1284 63.8375 58.9999 61.4187 61.4187C58.9999 63.8375 56.1284 65.7562 52.9681 67.0652C49.8078 68.3742 46.4207 69.048 43 69.048V69.048Z" fill="#0078F2" />
  </svg>
);

LocationIcon.propTypes = {
  className: PropTypes.string,
};

LocationIcon.defaultProps = {
  className: '',
};

export default LocationIcon;
