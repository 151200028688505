import { connect } from 'react-redux';
import Map from './Map';
import {
  moveMapCenterTo, setUserLocation, resetFitMapFlag,
  resetZoomMap, setOnPinMode, setOffPinMode,
  setMapCenterAddress, toggleMoveMapSearch, setOffMoveMapSearch,
  setOnMoveMapSearch,
} from './map_actions';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';


const mSTP = state => ({
  center: state.map.center,
  zoomFlag: state.map.zoomFlag,
  markers: state.map.markers,
  newMarker: state.map.newMarker,
  userLocation: state.map.userLocation,
  clusters: state.map.clusters,
  mapFitFlag: state.map.mapFitFlag,
  pinMode: state.map.pinMode,
  searchOnMoveMap: state.map.searchOnMoveMap,
  hoverId: state.map.hoverId,
});

const mDTP = {
  moveMapCenterTo,
  showUserPoint: setUserLocation,
  createError,
  startLoading,
  finishLoading,
  resetFitMapFlag,
  resetZoomMap,
  setOnPinMode,
  setOffPinMode,
  setMapCenterAddress,
  toggleMoveMapSearch,
  setOffMoveMapSearch,
  setOnMoveMapSearch,
};

export default connect(mSTP, mDTP)(Map);
