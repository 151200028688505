import React from 'react';
import PropTypes from 'prop-types';
import styles from './tabs.module.scss';

const Tab = ({ label, activeTab, onClick }) => {
  let tabClassname = styles.tab_item;

  if (activeTab === label) {
    tabClassname += ` ${styles.active}`;
  }

  const handleClick = () => {
    onClick(label);
  };

  return (
    <li
      role="presentation"
      className={tabClassname}
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      {label}
    </li>
  );
};

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Tab;
