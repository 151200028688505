import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import CancelIcon from '../icons/Cancel_icon';
import StripeButton from '../shared/Stripe_info/Stripe_button';
import StripeText from '../shared/Stripe_info/Stripe_text';

import getNestedValue from '../../utils/getNestedValue';

import styles from './modal.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export default class StripeConnect extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      connect: PropTypes.shape({
        charges_enabled: PropTypes.bool.isRequired,
        payouts_enabled: PropTypes.bool.isRequired,
      }),
    }).isRequired,
    token: PropTypes.string,
    getUser: PropTypes.func.isRequired,
  }

  static defaultProps = {
    token: '',
  }

  componentDidMount() {
    const { user, token, getUser } = this.props;
    const id = user && user.id;
    if (id && token && getUser) getUser({ id, token });
  }

  render() {
    const { isModalOpen, closeModal, text, user } = this.props;
    const connectStatus = !!user.connect;
    const chargesStatus = getNestedValue(user, 'connect', 'charges_enabled');
    const payoutsStatus = getNestedValue(user, 'connect', 'payouts_enabled');
    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className={styles.content}
        overlayClassName={styles.overlay}
      >
        <div className={styles.content_box}>
          <button
            type="button"
            className={styles.close_button_right}
            onClick={closeModal}
          >
            <CancelIcon className={styles.close_icon} />
          </button>
          <h4 className={styles.title}>Well done!</h4>
          <div className={styles.message_box}>
            <p>{text}</p>
            <StripeText
              connectStatus={connectStatus}
              chargesStatus={chargesStatus}
              payoutsStatus={payoutsStatus}
            />
          </div>
          <div className={styles.close_button_box}>
            <StripeButton onClick={closeModal} isStripeConnected={connectStatus} />
          </div>
        </div>
      </Modal>
    );
  }
}
