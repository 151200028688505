import { connect } from 'react-redux';
import VenueForm from './Venue_Form';
import {
  createVenue,
  editVenue,
  addPhotos,
  onVenueSubmitSuccess,
} from './venue_form_actions';
import { getVenueDetails } from '../Venue_details/venue_details_actions';

import { getDanceStyles } from '../shared/Dance_styles/dance_styles_actions';
import {
  moveMapCenterTo, addMarker, setOnPinMode,
  resetMapCenterAddress, setOffPinMode,
} from '../Map/map_actions';
import createError from '../Fetch_error/fetch_error_action';
import { setAndOpenModal } from '../Modal/modal_actions';


const mSTP = state => ({
  token: state.session.token,
  user: state.session.user,
  danceStyles: state.danceStyles.styles,
  mapCenterAddress: state.map.mapCenterAddress,
  pinMode: state.map.pinMode,
});


const mDTP = dispatch => ({
  createVenue: event => (dispatch(createVenue(event))),
  addPhotos: (id, photos) => (dispatch(addPhotos(id, photos))),
  editVenue: venue => (dispatch(editVenue(venue))),
  getDanceStyles: () => { dispatch(getDanceStyles()); },
  showNewMarker: (coords) => {
    dispatch(moveMapCenterTo(coords));
    dispatch(addMarker(coords));
  },
  handleError: err => dispatch(createError(err)),
  onVenueSubmitSuccess: options => dispatch(onVenueSubmitSuccess(options)),
  getVenueDetails: ({ id }) => dispatch(getVenueDetails({ id, moveMap: true })),
  setOnPinMode: () => dispatch(setOnPinMode()),
  setOffPinMode: () => dispatch(setOffPinMode()),
  resetMapCenterAddress: () => dispatch(resetMapCenterAddress()),
  openModal: modalName => dispatch(setAndOpenModal(modalName)),
});

export default connect(mSTP, mDTP)(VenueForm);
