import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';
import { EVENT } from '../../config';
import buttonStyles from '../../styles/buttons.module.scss';

import styles from './modal.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const buttonStyle = `${buttonStyles.btn_ok} ${buttonStyles.btn_uppercase}`;

export const SuccessTicketModal = ({ isModalOpen, closeModal, history, title, text }) => {
  const handleClose = () => {
    closeModal();

    let to = '/my_tickets';
    const { pathname } = history && history.location;
    if (pathname && pathname.indexOf(EVENT) === -1) {
      to = '/my_passes';
    }

    history.push(to);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      className={styles.content}
      overlayClassName={styles.overlay}
    >
      <div className={styles.content_box}>
        <h4 className={styles.title}>
          {title}
        </h4>
        <div className={styles.message_box}>
          {text}
        </div>
        <div className={styles.close_button_box}>
          <button
            type="button"
            onClick={handleClose}
            className={buttonStyle}
          >
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};

SuccessTicketModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
};

SuccessTicketModal.defaultProps = {
  title: 'Thank you!',
  text: 'Your order was successful.',
};

export default withRouter(SuccessTicketModal);
