import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { DragDropContext } from 'react-beautiful-dnd';
import { getRecurrentString } from 'utils/getRecurrentString';
import {
  validateTitle, validateText, validateTicket, checkUserCreatAbilyti,
} from '../../utils/validation';
import { getTimeObject } from '../../utils/formatTime';
import { getCorrectDate } from '../../utils/formatDate';
import { capitalizeStr, errorsToString } from '../../utils/parse';
import { makeSelectOptions } from '../../utils/selectOptions';
import combineDateAndTime from '../../utils/combineDateAndTime';
import getNearestRecurrentDate from '../../utils/getNearestRecurrentDate';
import checkIfItemIsFree from '../../utils/checkIfItemIsFree';

import PhotoManager from '../shared/Photo_manager/Photo_manager_container';
import DateBlock from '../shared/Date_block/Date_block';
import EventDetails from '../Event_details/Event_details_container';
import EventDetailsCutted from '../Event_details/Event_details_cutted_container';
import VenueSelector from '../Venue_Selector/Venue_selector_container';
import TextArea from '../shared/Textarea/Textarea';
import SimpleItemPreview from '../shared/Simple_item_preview/Simple_item_preview';
import TickestList from './Tickets_list';
import inputStyles from '../../styles/inputs.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import customSelectStyles, { selectErrorStyles, selectActiveStyles } from '../../styles/select_styles';
import styles from '../../styles/create_form.module.scss';

import {
  ONCE, DAILY, WEEKLY, BI_WEEKLY, MONTHLY,
  EVENT_TYPE_OPTIONS,
} from '../../config';
import getNestedValue from '../../utils/getNestedValue';

const PLACEHOLDER = {
  title: 'Event name',
  type: 'Event type',
  location: 'Location',
  venue: 'Venue',
  address: 'Address',
};

const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

const INITIAL_STATE = {
  isLoading: false,
  allFieldsActive: false,
  title: { ...INITIAL_STATE_FIELD },
  photos: [],
  date: {
    ...INITIAL_STATE_FIELD,
    isValid: true,
  },
  time: {
    timeFrom: '',
    timeTo: '',
    isActive: false,
    isValid: true,
    isError: false,
  },
  type: {
    ...INITIAL_STATE_FIELD,
    isValid: true,
  },
  recurrent: {
    ...INITIAL_STATE_FIELD,
    isValid: true,
    weeklyOption: [],
    monthlyOption: '',
  },
  description: { ...INITIAL_STATE_FIELD },
  location: {
    ...INITIAL_STATE_FIELD,
    venueList: [],
    isVenueListShown: false,
    venueId: null,
  },
  address: {
    ...INITIAL_STATE_FIELD,
    isAddressShown: false,
  },
  selectedStyles: {
    value: [],
    isValid: true,
    isActive: false,
  },
  coordinates: null,
  tickets: [],
  ticketError: false,
  ticketDateError: false,
  ticketErrorsfromBE: [],
  previewView: false,
  event: null,
  photosError: false,
  photosToRemove: [],
  ticketsToRemove: [],
  onceOptionUnavailable: false,
  permissions: {},
  isViewedByOwner: true,
};

const INITIAL_TICKET = {
  name: '',
  description: '',
  quantity: 0,
  price: 0,
  date: [],
  refunds: false,
  isOpened: true,
  isValid: false,
  destroyable: true,
  validity: 0,
  total_visits: 0,
};

class EventForm extends Component {
  static propTypes = {
    createEvent: PropTypes.func.isRequired,
    getDanceStyles: PropTypes.func.isRequired,
    showNewMarker: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    danceStyles: PropTypes.arrayOf(PropTypes.string).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    editEvent: PropTypes.func.isRequired,
    addPhotos: PropTypes.func.isRequired,
    onEventSubmitSuccess: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
      nickname: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      profession: PropTypes.string,
    }).isRequired,
    getEventDetails: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  state = { ...INITIAL_STATE };

  async componentDidMount() {
    const {
      getDanceStyles,
      history,
      match,
      handleError,
      getEventDetails,
      user: userLogged,
    } = this.props;
    getDanceStyles();

    const idToEdit = match && match.params && match.params.id;
    let eventToEdit;


    if (idToEdit) {
      const eventResponse = await getEventDetails({ id: idToEdit })
        .catch((err) => {
          const errMessage = err && err.response && err.response.data.error;
          handleError(errMessage);
          history.push('/');
        });
      eventToEdit = eventResponse && eventResponse.data && eventResponse.data.data;
    }
    if (!eventToEdit && history.location.pathname.indexOf('/account/edit_event/') !== -1) history.push('/');
    if (eventToEdit && history.location.pathname !== '/create_event') {
      const permissions = getNestedValue(eventToEdit, 'permissions');
      const editItemPermission = getNestedValue(permissions, 'update');
      const editTicketsPermission = getNestedValue(permissions, 'update_tickets');
      const owner = getNestedValue(eventToEdit, 'user');
      const isViewedByOwner = userLogged && owner && owner.id
      && userLogged.id === owner.id;
      if (editItemPermission || editTicketsPermission || isViewedByOwner) {
        const startDate = eventToEdit.start_date && getCorrectDate(eventToEdit.start_date).toDate();
        const endDate = eventToEdit.end_date && getCorrectDate(eventToEdit.end_date).toDate();

        const onceOptionUnavailable = eventToEdit.recurrent
          && eventToEdit.recurrent.freq !== ONCE;

        this.setState({
          ...INITIAL_STATE,
          title: {
            isActive: true,
            isValid: true,
            value: eventToEdit.title,
          },
          description: {
            isActive: true,
            isValid: true,
            value: eventToEdit.description,
          },
          selectedStyles: {
            isActive: !!eventToEdit.dance_styles,
            isValid: !!eventToEdit.dance_styles,
            value: eventToEdit.dance_styles ? makeSelectOptions(eventToEdit.dance_styles) : [],
          },
          date: {
            isActive: true,
            isValid: true,
            value: [startDate, endDate],
          },
          time: {
            timeFrom: getTimeObject(startDate),
            timeTo: getTimeObject(endDate),
            isActive: true,
            isValid: true,
            isError: false,
          },
          address: {
            isActive: true,
            isValid: true,
            value: eventToEdit.address,
            isAddressShown: !eventToEdit.venue_id,
          },
          location: {
            isActive: true,
            isValid: true,
            value: eventToEdit.location,
            isVenueListShown: false,
            venueId: eventToEdit.venue_id,
            venueList: [],
          },
          coordinates: { ...eventToEdit.coordinates },
          tickets: eventToEdit.tickets.map(ticket => (
            {
              ...ticket,
              start_date: ticket.start_date && getCorrectDate(ticket.start_date).toDate(),
              end_date: ticket.end_date && getCorrectDate(ticket.end_date).toDate(),
              isValid: true,
            }
          )),
          ticketError: !eventToEdit.tickets,
          photos: eventToEdit.photos,
          type: {
            isActive: !!eventToEdit.item_type,
            isValid: !!eventToEdit.item_type,
            value: eventToEdit.item_type
              ? {
                value: capitalizeStr(eventToEdit.item_type),
                label: capitalizeStr(eventToEdit.item_type),
              }
              : '',
          },
          recurrent: {
            isActive: true,
            isValid: true,
            value: eventToEdit.recurrent
            && Object.keys(eventToEdit.recurrent).length > 0
              ? {
                value: eventToEdit.recurrent.freq,
                label: capitalizeStr(eventToEdit.recurrent.freq),
              }
              : {
                value: ONCE,
                label: capitalizeStr(ONCE),
              },
            weeklyOption: (eventToEdit.recurrent && eventToEdit.recurrent.byday
              && (eventToEdit.recurrent.freq === WEEKLY
              || eventToEdit.recurrent.freq === BI_WEEKLY))
              ? eventToEdit.recurrent.byday.map(day => capitalizeStr(day))
              : [],
            monthlyOption: (eventToEdit.recurrent && eventToEdit.recurrent.freq === MONTHLY)
              ? {
                value: {
                  bymonthday: eventToEdit.recurrent.bymonthday,
                  week: eventToEdit.recurrent.week,
                  byday: eventToEdit.recurrent.byday,
                },
                label: getRecurrentString(eventToEdit.recurrent),
              }
              : '',
          },
          onceOptionUnavailable,
          permissions,
          isViewedByOwner,
        }, this.composeEvent);
      } else { history.push('/'); }
    }
  }

  componentWillUnmount() {
    const { showNewMarker } = this.props;
    showNewMarker(null);
  }

  componentSetState= prevState => this.setState(prevState);

  setErrors = (errors) => {
    const { handleError } = this.props;
    const errorString = errorsToString(errors);
    Object.keys(errors).forEach((item) => {
      const errorText = errors[item].join(', ');

      if (item === 'tickets') {
        this.setState(prevState => ({
          ...prevState,
          ticketErrorsfromBE: errors.tickets,
        }));
      } else if (item === 'start_date' || item === 'end_date') {
        this.setState(prevState => ({
          ...prevState,
          date: {
            ...prevState.date,
            isValid: false,
            error: errorText,
          },
        }));
      } else if (item === 'dance_styles') {
        this.setState(prevState => ({
          ...prevState,
          selectedStyles: {
            ...prevState.selectedStyles,
            isValid: false,
            error: errorText,
          },
        }));
      } else if (item === 'coordinates') {
        this.setState(prevState => ({
          ...prevState,
          coordinates: null,
          address: {
            ...prevState.address,
            isValid: false,
            error: 'Select address from the list or map',
          },
        }));
      } else if (item.indexOf('photo') > 0) {
        this.setState({ photosError: true });
      } else if (this.state[item]) {
        this.setState(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isValid: false,
            error: errorText,
          },
        }));
      }
    });
    if (errorString.length > 0) handleError(errorString);
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = (name === 'title') ? validateTitle(value) : validateText(value);
    this.setState({
      [name]: {
        isActive: true,
        isValid,
        value,
        error: null,
      },
    });
  };

  handleSelectType = value => this.handleSelect(value, 'type');

  handleSelectStyles = value => this.handleSelect(value, 'selectedStyles');

  handleSelect = (value, field) => {
    this.setState(prevState => ({ [field]: {
      ...prevState[field],
      value,
      isValid: true,
      isActive: true,
      error: null,
    } }));
  }

  handlePhotoSelect = photos => this.setState({ photos });

  resetAllfielsdActive = () => {
    this.setState({ allFieldsActive: false });
  }

  activateAllFields = () => {
    this.activateField('title');
    this.activateField('description');
    this.activateField('location');
    this.activateField('address');
    this.setState({ allFieldsActive: true }, this.resetAllfielsdActive);
  }

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  validateDate = () => {
    const { date } = this.state;
    if (!date.value) {
      this.setState(prevState => ({
        date: {
          ...prevState.date,
          isValid: false,
          isActive: false,
        },
      }));
    }
  };

  validateTime = () => {
    const { time } = this.state;
    if (!time.timeFrom || !time.timeTo) {
      this.setState({ time: { isValid: false } });
    }
  };

  validateCoords = () => {
    const { coordinates } = this.state;
    if (!coordinates) {
      this.setState(prevState => ({
        address: {
          ...prevState.address,
          isValid: false,
          error: 'Select address from the list or map',
        },
      }));
      return false;
    }

    return true;
  }

  validateStyles = () => {
    const { selectedStyles } = this.state;
    const isValid = selectedStyles && selectedStyles.value && selectedStyles.value.length > 0;
    if (isValid) {
      this.setState(prevState => ({
        selectedStyles: {
          ...prevState.selectedStyles,
          isValid: true,
        },
      }));
    } else {
      this.setState(prevState => ({
        selectedStyles: {
          ...prevState.selectedStyles,
          isValid: false,
          isActive: false,
        },
      }));
    }
    return isValid;
  }

  validateType = () => {
    const { type } = this.state;
    const isValid = type && type.value;
    if (isValid) {
      this.setState(prevState => ({
        type: {
          ...prevState.type,
          isValid,
        },
      }));
    } else {
      this.setState(prevState => ({
        type: {
          ...prevState.type,
          isValid,
          isActive: false,
        },
      }));
    }
    return isValid;
  }

  validateRecurrent = () => {
    const { recurrent } = this.state;
    let options;
    let isValid = false;
    const freq = recurrent && recurrent.value && recurrent.value.value;
    if (freq === MONTHLY) {
      options = recurrent.monthlyOption.value;
      isValid = (options && options.byday && options.byday.length > 0 && options.week)
      || (options && options.bymonthday && options.bymonthday.length > 0);
    }
    if (freq === DAILY) {
      isValid = true;
    }
    if (freq === WEEKLY || freq === BI_WEEKLY) {
      options = recurrent.weeklyOption;
      isValid = options && options.length > 0;
    }
    if (freq === ONCE) isValid = true;
    if (isValid) {
      this.setState(prevState => ({
        recurrent: {
          ...prevState.recurrent,
          isValid: true,
        },
      }));
    } else {
      this.setState(prevState => ({
        recurrent: {
          ...prevState.recurrent,
          isValid: false,
          isActive: false,
        },
      }));
    }
    return isValid;
  }

  isValidationPassed = () => {
    const {
      title,
      date,
      description,
      location,
      address,
      time,
      tickets,
    } = this.state;
    const validDate = !!(date.isValid && date.value
      && time.isValid && time.timeFrom && time.timeTo);
    const validTextInputs = title.isValid && description.isValid
      && location.isValid && address.isValid;
    const validStyles = this.validateStyles();
    const validType = this.validateType();
    const validRecurrent = this.validateRecurrent();
    const validTickets = tickets.every(ticket => ticket.isValid === true);

    this.setState({
      ticketError: !validTickets,
    });
    const validCoords = this.validateCoords();
    return (validTextInputs && validDate && validStyles && validTickets
      && validCoords && validRecurrent && validType);
  };

  validateForm = () => {
    this.activateAllFields();
    this.validateDate();
    this.validateTime();
    if (!this.isValidationPassed()) return false;
    const { tickets } = this.state;
    if (tickets.length === 0) {
      this.setState({ ticketError: true });
      return false;
    }

    return true;
  }

  composeEvent = () => {
    const {
      title, date, time, description,
      location, address, selectedStyles, photos,
      tickets, coordinates, recurrent, type,
    } = this.state;
    const { timeFrom, timeTo } = time;

    const freq = recurrent && recurrent.value && recurrent.value.value;
    const oneDateAvailable = freq === WEEKLY || freq === BI_WEEKLY;

    if (oneDateAvailable) {
      const byDay = recurrent.weeklyOption.map(day => day.toLowerCase());
      const nearestDate = getNearestRecurrentDate(date.value[0], byDay);
      date.value[0] = nearestDate;
      date.value[1] = nearestDate;
    }

    const { dateTo, dateFrom } = combineDateAndTime({
      timeFrom, timeTo, date, oneDateAvailable,
    });

    if (dateTo < dateFrom) {
      this.setState(prevState => ({
        ...prevState,
        time: {
          ...prevState.time,
          isActive: true,
          isValid: false,
          isError: true,
        },
      }));
      return null;
    }

    const danceStyles = selectedStyles.value.map(style => style.value);

    const event = {
      title: title.value,
      start_date: moment(dateFrom).format(),
      end_date: moment(dateTo).format(),
      description: description.value,
      location: location.value,
      address: address.value,
      dance_styles: danceStyles,
      photos,
      tickets,
      coordinates,
      item_type: type.value.value.toLowerCase(),
      recurrent: { freq },
    };
    if (oneDateAvailable) {
      event.recurrent.byday = recurrent.weeklyOption.map(day => day.toLowerCase());
    }
    if (freq === MONTHLY) {
      event.recurrent = { ...event.recurrent, ...recurrent.monthlyOption.value };
    }

    this.setState({ event });
    return event;
  }

  prepareEventForRequest = (event) => {
    const eventForRequest = event;
    if (this.state.location.venueId) {
      delete eventForRequest.location;
      delete eventForRequest.coordinates;
      delete eventForRequest.address;
      eventForRequest.venue_id = this.state.location.venueId;
    } else eventForRequest.venue_id = '';

    delete eventForRequest.price;
    delete eventForRequest.refunds;
    delete eventForRequest.photos;

    eventForRequest.tickets = eventForRequest.tickets.map((element, index) => {
      const ticket = {
        description: element.description,
        end_date: element.end_date && moment(element.end_date)
          .hours(23)
          .minutes(59)
          .seconds(59)
          .format(),
        name: element.name,
        price: element.price,
        quantity: element.quantity,
        refunds: element.refunds,
        start_date: element.start_date && moment(element.start_date)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .format(),
        fee_include: element.fee_include,
        validity: element.validity || 0,
        total_visits: element.total_visits || 0,
        index,
      };
      if (element.id) ticket.id = element.id;

      return ticket;
    });

    return eventForRequest;
  }

  handleCreateEvent = (e) => {
    e.preventDefault();
    const { handleError, user, openModal } = this.props;

    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }

    if (!checkUserCreatAbilyti(user)) {
      openModal('PROFILE_MODAL');
      return;
    }

    if (this.state.isLoading) return;
    let eventForRequest = this.composeEvent();
    if (!eventForRequest) return;

    eventForRequest = this.prepareEventForRequest(eventForRequest);

    const { createEvent, onEventSubmitSuccess, history } = this.props;
    const { photos, tickets, isViewedByOwner } = this.state;
    const isItemFree = checkIfItemIsFree(tickets);
    this.setState({ isLoading: true });
    createEvent(eventForRequest)
      .then((data) => {
        if (data && data.id) {
          if (photos.length === 0) {
            onEventSubmitSuccess({
              id: data.id,
              uploadPhotos: true,
              action: 'create',
              isItemFree,
              isViewedByOwner,
            });
            history.push(`/events/${data.id}`);
          } else {
            const eventFormData = new FormData();
            photos.forEach((photo, index) => {
              eventFormData.append(`data[photos][${index}][file]`, photo.file, photo.file.name);
              eventFormData.append(`data[photos][${index}][sort]`, index);
            });
            this.handleUploadPhotos(data.id, eventFormData, 'create');
          }
        }
        if (data && data.errors) {
          this.setErrors(data.errors);
          this.setState({
            previewView: false,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => { this.setState({ isLoading: false }); });
  }

  handleEditEvent = (e) => {
    e.preventDefault();
    const { handleError } = this.props;

    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }
    if (this.state.isLoading) return;

    let event = this.composeEvent();
    if (!event) return;

    event = this.prepareEventForRequest(event);

    event.tickets = [...event.tickets, ...this.state.ticketsToRemove];

    const {
      editEvent,
      history,
      onEventSubmitSuccess,
      match,
    } = this.props;
    event.id = match && match.params && match.params.id;

    const { photos, photosToRemove, tickets, isViewedByOwner } = this.state;
    const isItemFree = checkIfItemIsFree(tickets);
    this.setState({ isLoading: true });

    editEvent(event)
      .then((data) => {
        if (data && data.id) {
          const eventFormData = new FormData();
          let photosWereModified;
          photos.forEach((photo, index) => {
            if (photo.file) {
              eventFormData.append(`data[photos][${index}][file]`, photo.file, photo.file.name);
              eventFormData.append(`data[photos][${index}][sort]`, index);
              photosWereModified = true;
            }
            if (photo.id && index !== photo.sort) {
              eventFormData.append(`data[photos][${index}][id]`, photo.id);
              eventFormData.append(`data[photos][${index}][sort]`, index);
              photosWereModified = true;
            }
          });
          photosToRemove.forEach((photoId, index) => {
            eventFormData.append(`data[photos][${photos.length + index}][id]`, photoId);
            eventFormData.append(`data[photos][${photos.length + index}][to_remove]`, true);
            photosWereModified = true;
          });
          if (photosWereModified) {
            this.handleUploadPhotos(data.id, eventFormData, 'edit');
          } else {
            history.push(`/events/${data.id}`);
            onEventSubmitSuccess(
              {
                uploadPhotos: true,
                action: 'edit',
                isItemFree,
                isViewedByOwner,
              },
            );
          }
          return;
        }

        if (data && data.errors) {
          this.setErrors(data.errors);
          this.setState({
            previewView: false,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleUploadPhotos = (id, eventFormData, action) => {
    const { addPhotos, history, onEventSubmitSuccess } = this.props;
    const { tickets, isViewedByOwner } = this.state;
    const isItemFree = checkIfItemIsFree(tickets);
    addPhotos(id, eventFormData)
      .then((photoData) => {
        if (photoData && photoData.id) {
          history.push(`/events/${id}`);
          onEventSubmitSuccess(
            {
              uploadPhotos: true,
              action,
              isItemFree,
              isViewedByOwner,
            },
          );
        } else if (photoData && (photoData.errors || photoData.error)) {
          this.setErrors(photoData.errors || photoData.error);
          this.setState({
            previewView: false,
            isLoading: false,
          });
          onEventSubmitSuccess({ action, isItemFree, isViewedByOwner });
        } else {
          history.push(`/events/${id}`);
          onEventSubmitSuccess({ action, isItemFree, isViewedByOwner });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleCloseForm = () => {
    if (this.state.isLoading) return;
    this.setState({ ...INITIAL_STATE });

    const { history, location } = this.props;
    const from = location.state && location.state.from;
    if ((from && from.indexOf('/events/') === -1 && from.indexOf('/account') === -1) || !from) {
      history.push('/');
      return;
    }
    const list = location.state && location.state.list;
    history.push({
      pathname: from,
      state: { list },
    });
  }

  handleOpenPreview = () => {
    const { handleError } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }
    if (this.state.isLoading) return;
    const event = this.composeEvent();
    if (!event) return;
    this.setState({ previewView: true });
  }

  handleClosePreview = () => {
    if (this.state.isLoading) return;
    this.setState({ previewView: false });
  }

  resetTicketsErrors = () => this.setState({
    ticketError: false,
    ticketDateError: false,
  });

  handleSaveTicket = (ticketToAdd, indexToAdd) => {
    this.setState(prevState => ({
      tickets: prevState.tickets.map((ticket, index) => {
        if (index === indexToAdd) {
          return { ...ticketToAdd, isValid: validateTicket(ticketToAdd) };
        }
        return ticket;
      }),
      ticketErrorsfromBE: prevState.ticketErrorsfromBE.map((ticket, index) => {
        if (index === indexToAdd) return null;
        return ticket;
      }),
    }));
    this.resetTicketsErrors();
  }

  handleRemoveTicket = (indexToDelete) => {
    this.setState(prevState => ({
      ...prevState,
      ticketsToRemove: prevState.tickets[indexToDelete].id
        ? [...prevState.ticketsToRemove, {
          id: prevState.tickets[indexToDelete].id,
          to_remove: true,
        }]
        : [...prevState.ticketsToRemove],
      tickets: prevState.tickets.filter((ticket, index) => indexToDelete !== index),
    }));
    this.resetTicketsErrors();
  }

  handleOpenTicketForm = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      tickets: [
        ...prevState.tickets,
        { ...INITIAL_TICKET, tempId: (new Date()).getTime() },
      ],
    }));
    this.resetTicketsErrors();
  }

  handleOpenTicket = (indexToOpen) => {
    this.setState(prevState => ({
      tickets: prevState.tickets.map((ticket, index) => {
        if (index === indexToOpen) { return { ...ticket, isOpened: !ticket.isOpened }; }
        return ticket;
      }),
    }));
    this.resetTicketsErrors();
  }

  handleDragEnd = (result) => {
    if (!result) return;
    const { destination, source } = result;
    const sourceIndex = getNestedValue(result, 'source', 'index');
    const destinationIndex = getNestedValue(result, 'destination', 'index');

    if (!destination || !source || sourceIndex === destinationIndex) return;

    this.setState((prevState) => {
      const tickets = [...prevState.tickets];
      const sourceTicket = tickets.splice(sourceIndex, 1)[0];
      tickets.splice(destinationIndex, 0, sourceTicket);
      return { tickets };
    });
  }

  handleRemovePhoto = (idToDelete) => {
    this.setState(prevState => ({
      ...prevState,
      photosToRemove: idToDelete
        ? [...prevState.photosToRemove, idToDelete]
        : [...prevState.photosToRemove],
    }));
  }

  preventSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  renderInput = (inputName) => {
    const valueFromState = this.state[inputName];
    let fieldStyle;
    if (!valueFromState.isActive) fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    else fieldStyle = valueFromState.isValid ? inputStyles.input_box_create : `${inputStyles.input_box_create} ${inputStyles.is_error}`;
    return (
      <div className={fieldStyle}>
        <input
          className={inputStyles.input}
          type="text"
          name={inputName}
          value={valueFromState.value}
          onChange={inputName === 'location' ? this.handleVenueChange : this.handleInputChange}
          autoComplete={inputName === 'location' ? 'off' : 'on'}
          onBlur={inputName === 'location' ? this.handleVenueBlur : null}
        />
        <label
          htmlFor={inputName}
          className={valueFromState.value ? inputStyles.label__with_value : inputStyles.label}
        >
          {valueFromState.error ? valueFromState.error : PLACEHOLDER[inputName]}
        </label>
      </div>
    );
  };

  getSelectStyles = (isValid, isActive) => {
    let selectStyles = isValid ? customSelectStyles : selectErrorStyles;
    if (isActive) selectStyles = selectActiveStyles;
    return selectStyles;
  };

  render() {
    const { danceStyles, match, handleError, history } = this.props;
    const danceStylesError = danceStyles.length === 0;

    const {
      description,
      type: {
        isActive: isActiveType,
        isValid: isValidType,
        value: valueType,
      },
      recurrent,
      date,
      selectedStyles: {
        value: valueStyles,
        isValid: isValidStyles,
        isActive: isActiveStyles,
        error: errorStyles,
      },
      photos,
      time,
      tickets,
      ticketError,
      ticketDateError,
      previewView,
      event,
      ticketErrorsfromBE,
      photosError,
      allFieldsActive,
      onceOptionUnavailable,
      permissions,
      isViewedByOwner,
    } = this.state;

    const options = makeSelectOptions(danceStyles);

    const selectDanceStyles = this.getSelectStyles(isValidStyles, isActiveStyles);
    const selectTypeStyles = this.getSelectStyles(isValidType, isActiveType);

    const isEditing = match.path.indexOf('edit_event') !== -1;
    const isEventRepeating = !!(recurrent
      && recurrent.value
      && recurrent.value.value !== ONCE);

    const ableToEditEventFields = !isEditing || (isEditing && !!permissions.update);
    const ableToEditTickets = !isEditing
    || (isEditing && !!(permissions && permissions.update_tickets));
    const ableToCreateTickets = !isEditing
    || (isEditing && !!(permissions && permissions.create_tickets));


    const sortedTickets = [...tickets].sort((a, b) => {
      if (a.index < b.index) return -1;
      if (a.index > b.index) return +1;
      return 0;
    });

    return (
      <div className={`${gridStyles.mobile_on_top} ${gridStyles.wrapper_fixed_bottom}`}>
        {!danceStylesError && (
          <form onSubmit={this.preventSubmit}>
            {previewView
              && <EventDetails eventForPreview={event} previewFromForm history={history} />
            }
            {!previewView && (
            <div className={gridStyles.scroll_container}>
                {ableToEditEventFields ? (
                  <>
                    <h3 className={styles.title}>{isEditing ? 'Edit Event' : 'Create Event'}</h3>
                    <PhotoManager
                      onPhotoUpdate={this.handlePhotoSelect}
                      onRemovePhoto={this.handleRemovePhoto}
                      photos={photos}
                    />
                    {photosError && (
                      <div className={styles.error}>
                        Event was updated, but something went wrong with photos ...
                      </div>
                    )}
                    {this.renderInput('title')}
                    <Select
                      value={valueType}
                      onChange={this.handleSelectType}
                      options={EVENT_TYPE_OPTIONS}
                      className={inputStyles.safari_input}
                      styles={selectTypeStyles}
                      placeholder={PLACEHOLDER.type}
                      isSearchable={false}
                    />
                    <Select
                      value={valueStyles}
                      onChange={this.handleSelectStyles}
                      options={options}
                      isMulti
                      closeMenuOnSelect={false}
                      className={inputStyles.safari_input}
                      styles={selectDanceStyles}
                      placeholder="Dance styles"
                    />
                    {errorStyles
                    && <div className={styles.error}>{errorStyles}</div>
                    }
                    <DateBlock
                      componentSetState={this.componentSetState}
                      resetTicketsErrors={this.resetTicketsErrors}
                      recurrent={recurrent}
                      date={date}
                      time={time}
                      onceOptionUnavailable={onceOptionUnavailable}
                    />
                    <TextArea
                      handleInputChange={this.handleInputChange}
                      stateObject={description}
                    />
                    <VenueSelector
                      {...this.props}
                      componentSetState={this.componentSetState}
                      location={this.state.location}
                      address={this.state.address}
                    />
                      </>
                ) : (
                  <EventDetailsCutted event={event} />
                )}
              <h4 className={styles.subtitle}>Tickets</h4>
              {ableToEditTickets ? (
                <DragDropContext onDragEnd={this.handleDragEnd}>
                  <TickestList
                    ableToEditTickets={ableToEditTickets}
                    isViewedByOwner={isViewedByOwner}
                    tickets={tickets}
                    ticketErrorsfromBE={ticketErrorsfromBE}
                    handleError={handleError}
                    allFieldsActive={allFieldsActive}
                    isEventRepeating={isEventRepeating}
                    handleSaveTicket={this.handleSaveTicket}
                    handleRemoveTicket={this.handleRemoveTicket}
                    handleOpenTicket={this.handleOpenTicket}
                  />
                </DragDropContext>
              ) : sortedTickets.map((ticket, index) => (
                <SimpleItemPreview
                  item={ticket}
                  key={`${ticket.name}_${index.toString()}`}
                  detailsView
                />
              ))}
              {ableToCreateTickets && (
                <button
                  type="button"
                  className={`${buttonStyles.btn_add} ${buttonStyles.btn_wide}`}
                  onClick={this.handleOpenTicketForm}
                >
                  + Add Ticket
                </button>
              )}
              {ticketError && (
                <div className={styles.error}>
                  Please complete correctly all tickets or remove unnecessary.
                </div>
              )}
              {ticketDateError && (
                <div className={styles.error}>
                  Date of tickets should not be later than date of event.
                </div>
              )}
            </div>)}
            <div className={buttonStyles.fixed_buttons}>
              {isEditing
                ? (
                  <button
                    type="button"
                    className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                    onClick={this.handleEditEvent}
                  >
                    save
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                    onClick={this.handleCreateEvent}
                    data-test="create_button"
                  >
                    create
                  </button>
                )
              }
              <div className={buttonStyles.buttons_row}>
                <button
                  type="button"
                  className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                  onClick={previewView
                    ? this.handleClosePreview
                    : this.handleOpenPreview}
                >
                  {previewView ? 'edit' : 'preview'}
                </button>
                <button
                  type="button"
                  className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                  onClick={this.handleCloseForm}
                >
                  cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default EventForm;
