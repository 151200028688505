import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Slider, { Handle } from 'rc-slider';
import Select from 'react-select';
import moment from 'moment';

import SelectedFilter from './Selected_filter';
import DatePicker from '../shared/Date_picker/Date_picker';
import WeekPicker from '../shared/Week_picker/Week_picker';

import { stringOfCoordsToObject } from '../../utils/parse';
import { makeSelectOptions, makeSelectOptionsWithHumanLabel } from '../../utils/selectOptions';
import getNestedValue from '../../utils/getNestedValue';

import FilterIcon from '../icons/Filter';
import CancelIcon from '../icons/Cancel_icon';
import MarkerIcon from '../icons/Marker';

import styles from './filter.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import typographyStyles from '../../styles/common.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import inputStyles from '../../styles/inputs.module.scss';
import customStyles, { selectActiveStyles } from '../../styles/select_styles';

import {
  EVENTS, EVENT_TYPE_OPTIONS, VENUES, TEACHERS,
} from '../../config';

const FILTER_MIN = 10;
const FILTER_MAX = 2000;
const FILTER_DEFAULT = 300;
const FILTER_STEP = 10;

const PLACEHOLDER = {
  location: 'Select location',
  styles: 'Select styles...',
  arrange: 'Select arrange',
  eventType: 'Select type',
};

const POPULARITY_OPTION = {
  value: 'popularity',
  label: 'By popularity',
};

const DATE_OPTION = {
  value: 'date',
  label: 'By date',
};

const TEACHER_DATE_OPTION = {
  value: 'date',
  label: 'By class time',
};

const INITIAL_STATE = {
  isFilterShown: false,
  isFilterActive: false,
  isRadiusShown: false,
  geoCentre: {},
  location: '',
  radius: FILTER_DEFAULT,
  selectedStyles: [],
  arrangeBy: '',
  selectedEventType: [],
  dateRange: {
    isActive: false,
    isValid: true,
    value: '',
  },
  weekDays: [],
};

const typeOptions = [...EVENT_TYPE_OPTIONS];

class Filter extends Component {
  static propTypes = {
    getDanceStyles: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.object,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }).isRequired,
    handleMoveMapCenterTo: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    danceStyles: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    danceStyles: [],
  }

  state = { ...INITIAL_STATE };

  componentDidMount() {
    const { getDanceStyles } = this.props;
    getDanceStyles();
    this.setFilterValues();
  }

  componentDidUpdate(prevProps) {
    const prevLocation = prevProps.location && prevProps.location.pathname;
    const nowLocation = this.props.location && this.props.location.pathname;
    const prevSearch = prevProps.location && prevProps.location.search;
    const nowSearch = this.props.location && this.props.location.search;

    if (prevLocation !== nowLocation) this.handleCloseFilter();

    if (nowSearch !== prevSearch) {
      this.setFilterValues();
    }
  }

  checkRouteFiltering = () => {
    const { history } = this.props;
    const pathname = getNestedValue(history, 'location', 'pathname');

    if (pathname === '/' || pathname.indexOf(EVENTS) !== -1) {
      return EVENTS;
    }

    if (pathname.indexOf(VENUES) !== -1) {
      return VENUES;
    }

    if (pathname.indexOf(TEACHERS) !== -1) {
      return TEACHERS;
    }

    return null;
  }

  setFilterValues = () => {
    const { location: browserLocation, handleMoveMapCenterTo } = this.props;
    const search = browserLocation && browserLocation.search;
    if (search) {
      const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
      const isFilterActive = !!(
        (queryObject.geo_centre && queryObject.radius && queryObject.location)
        || queryObject.style
        || queryObject.sort
        || queryObject.event_type
        || (queryObject.start_date && queryObject.end_date)
        || queryObject.weekday);
      this.setState({ isFilterActive });
      if (isFilterActive) {
        const route = this.checkRouteFiltering();

        if (queryObject.geo_centre && queryObject.radius && queryObject.location) {
          const { location, radius, geo_centre: geoCentre } = queryObject;
          const geoCentreObj = stringOfCoordsToObject(geoCentre);
          this.setState({
            location,
            radius: parseFloat(radius),
            geoCentre: geoCentreObj,
            isRadiusShown: true,
          });
          handleMoveMapCenterTo(geoCentreObj);
        }

        if (queryObject.style && queryObject.style.length > 0) {
          const selectOptions = makeSelectOptions(queryObject.style);
          this.setState({
            selectedStyles: Array.isArray(selectOptions) ? selectOptions : [{ ...selectOptions }],
          });
        }

        let arrangeBy;
        if (queryObject.sort) {
          if (queryObject.sort === DATE_OPTION.value) {
            if (route === TEACHERS) {
              arrangeBy = { ...TEACHER_DATE_OPTION };
            } else {
              arrangeBy = { ...DATE_OPTION };
            }
          }
          if (queryObject.sort === POPULARITY_OPTION.value) {
            arrangeBy = { ...POPULARITY_OPTION };
          }
        } else {
          arrangeBy = '';
        }
        this.setState({ arrangeBy });

        if (queryObject.event_type && queryObject.event_type.length > 0 && route === EVENTS) {
          const selectedEventType = makeSelectOptionsWithHumanLabel(queryObject.event_type);
          this.setState({
            selectedEventType: Array.isArray(selectedEventType)
              ? selectedEventType
              : [{ ...selectedEventType }],
          });
        } else {
          this.setState({ selectedEventType: [] });
        }

        if (route === EVENTS && queryObject.start_date && queryObject.end_date) {
          const startDate = new Date(queryObject.start_date);
          const endDate = new Date(queryObject.end_date);

          this.setState({
            dateRange: {
              isActive: true,
              isValid: true,
              value: [startDate, endDate],
            },
          });
        } else {
          this.setState({
            dateRange: INITIAL_STATE.dateRange,
          });
        }

        if (route !== EVENTS && queryObject.weekday) {
          const day = queryObject.weekday.charAt(0).toUpperCase() + queryObject.weekday.slice(1);
          this.setState({
            weekDays: [day],
          });
        } else {
          this.setState({
            weekDays: [],
          });
        }
      }
    } else {
      this.setState({ ...INITIAL_STATE });
    }
  }

  handleToggleFilter = () => {
    const { isFilterShown } = this.state;
    if (isFilterShown) this.setFilterValues();
    this.setState({ isFilterShown: !isFilterShown });
  };

  handleCloseFilter = () => {
    this.setState({ ...INITIAL_STATE });
  }

  resetFiltersForEventsOnly = () => {
    this.setState({
      selectedEventType: INITIAL_STATE.selectedEventType,
      dateRange: INITIAL_STATE.dateRange,
    });
  }

  handleLocationSelect = (selectedLocation) => {
    const { handleError, handleMoveMapCenterTo } = this.props;

    this.handleLocationChange(selectedLocation);

    geocodeByAddress(selectedLocation)
      .then(results => getLatLng(results[0]))
      .then((coordinates) => {
        this.setState({
          geoCentre: coordinates,
          isRadiusShown: true,
        });
        handleMoveMapCenterTo(coordinates);
      })
      .catch(error => handleError(error));
  }

  handleLocationChange = location => this.setState({ location })

  resetLocation = () => {
    this.setState({
      isRadiusShown: false,
      geoCentre: {},
      location: '',
      radius: FILTER_DEFAULT,
      isFilterShown: true,
    });
  }

  handleRadiusChange = radius => this.setState({ radius });

  handleSelectStyles = selectedStyles => this.setState({ selectedStyles });

  handleSelectArrange = arrangeBy => this.setState({ arrangeBy });

  handleSelectEventType = type => this.setState({ selectedEventType: type });

  handleResetFilter = () => {
    this.setState({
      ...INITIAL_STATE,
      isFilterShown: true,
    });
    const { history } = this.props;
    history.push(history.location.pathname);
  }

  handleDateChange = (date) => {
    this.setState({
      dateRange: {
        isActive: true,
        isValid: true,
        value: date,
      },
    });
  }

  handlePickDay = (name, checked) => {
    if (checked) {
      this.setState({ weekDays: [name] });
    } else {
      this.setState({ weekDays: [] });
    }
  }

  handleResetGeoFilter = () => {
    this.setState({
      isRadiusShown: false,
      geoCentre: {},
      location: '',
      radius: FILTER_DEFAULT,
    }, this.handleApplyFilter);
  }

  handleResetStyleFilter = (value) => {
    this.setState(prevState => ({
      selectedStyles: prevState.selectedStyles.filter(item => item.value !== value),
    }),
    this.handleApplyFilter);
  }

  handleResetArrangeBy = () => {
    this.setState({ arrangeBy: {} }, this.handleApplyFilter);
  }

  handleResetDateRange = () => {
    this.setState({ dateRange: INITIAL_STATE.dateRange }, this.handleApplyFilter);
  }

  handleResetEventType = (value) => {
    this.setState(prevState => ({
      selectedEventType: prevState.selectedEventType.filter(item => item.value !== value),
    }), this.handleApplyFilter);
  }

  handleResetWeekdays = () => {
    this.setState({ weekDays: [] }, this.handleApplyFilter);
  }

  handleApplyFilter = (e) => {
    if (e) e.preventDefault();
    const {
      geoCentre, radius, location, selectedStyles,
      arrangeBy, selectedEventType,
      dateRange, weekDays,
    } = this.state;
    const { history } = this.props;
    let path = history.location.pathname;
    if (geoCentre.lat && geoCentre.lng) {
      path = `geo_centre=[${geoCentre.lng},${geoCentre.lat}]&radius=${radius}&location=${location}`;
    }

    if (selectedStyles.length > 0) {
      const stylesQuery = selectedStyles.reduce((accQuery, style) => `${accQuery}&style=${style.value}`, '');
      path = (path !== history.location.pathname)
        ? `${path}${stylesQuery}`
        : stylesQuery.substring(1);
    }

    if (arrangeBy && arrangeBy.value) {
      const arrangeQuery = `sort=${arrangeBy.value}`;
      path = (path !== history.location.pathname)
        ? `${path}&${arrangeQuery}`
        : arrangeQuery;
    }

    if (selectedEventType.length > 0) {
      const eventTypeQuery = selectedEventType.reduce(
        (accQuery, type) => `${accQuery}&event_type=${type.value}`, '',
      );
      path = (path !== history.location.pathname)
        ? `${path}${eventTypeQuery}`
        : eventTypeQuery.substring(1);
    }

    if (this.checkRouteFiltering() === EVENTS
      && dateRange.value && dateRange.value[0] && dateRange.value[1]) {
      const startDate = moment(dateRange.value[0]).format('YYYY-MM-DD');
      const endDate = moment(dateRange.value[1]).format('YYYY-MM-DD');
      const dateRangeQuery = `start_date=${startDate}&end_date=${endDate}`;

      path = (path !== history.location.pathname)
        ? `${path}&${dateRangeQuery}`
        : dateRangeQuery;
    }

    if (weekDays && weekDays.length > 0) {
      const dayQuery = `weekday=${weekDays[0].toLowerCase()}`;
      path = (path !== history.location.pathname)
        ? `${path}&${dayQuery}`
        : dayQuery;
    }

    if (path !== history.location.pathname) {
      this.setState({
        isFilterActive: true,
        isFilterShown: false,
      });
      history.push(`?${path}`);
    } else {
      this.setState({
        isFilterActive: false,
        isFilterShown: false,
      });
      history.push(history.location.pathname);
    }
  }

  renderGeoCenterInput = () => {
    const { location } = this.state;
    const showResetButton = location.length > 0;

    return (
      <PlacesAutocomplete
        value={location}
        onChange={this.handleLocationChange}
        onSelect={this.handleLocationSelect}
        name="geoCentre"
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={inputStyles.location_wrapper}>
            <div className={inputStyles.location_container}>
              <MarkerIcon className={styles.marker_icon} />
              <input
                {...getInputProps({
                  className: inputStyles.location,
                  name: 'geoCentre',
                  placeholder: PLACEHOLDER.location,
                })}
              />
              {showResetButton ? (
                <button
                  type="button"
                  className={styles.close_round_wrapper}
                  onClick={this.resetLocation}
                >
                  <div className={buttonStyles.close_round}>
                    <CancelIcon className={buttonStyles.close_round_svg} />
                  </div>
                </button>
              ) : null}
            </div>
            <div className={inputStyles.autocomplete}>
              {loading && (
                <div className={inputStyles.autocomplete_loading}>Loading...</div>
              )}
              {suggestions.map(suggestion => (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: inputStyles.autocomplete_item,
                  })}
                >
                  <MarkerIcon className={inputStyles.autocomplete_icon} />
                  <span className={inputStyles.autocomplete_link}>{suggestion.description}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  };

  renderRadiusSlider = () => {
    const { radius } = this.state;
    const handle = (props) => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <div style={{ position: 'relative' }}>
          <div style={{
            position: 'absolute',
            top: -35,
            left: `${value / FILTER_MAX * 100}%`,
            transform: value < (FILTER_MAX * 0.75)
              ? 'translateX(-50%)'
              : 'translateX(-100%)',
            fontSize: 16,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
          >
            {`${value} miles`}
          </div>
          <div className={styles.slider_limiter}>
            <Handle value={value} {...restProps} />
          </div>
        </div>
      );
    };
    return (
      <div className={styles.slider_container}>
        <Slider
          min={FILTER_MIN}
          max={FILTER_MAX}
          value={radius}
          step={FILTER_STEP}
          className={styles.slider}
          railStyle={{
            backgroundColor: '#909bb1',
            height: 5,
            width: '100%',
            borderRadius: 2,
            cursor: 'pointer',
          }}
          handleStyle={{
            height: 24,
            width: 24,
            borderRadius: '50%',
            backgroundColor: '#0077ee',
            position: 'absolute',
            transform: 'translate(0%, -60%)',
            cursor: 'pointer',
            left: `${radius}%`,
          }}
          trackStyle={{
            backgroundColor: '#0077ee',
            height: 5,
            width: '100%',
            marginTop: -5,
            borderRadius: 2,
          }}
          onChange={this.handleRadiusChange}
          handle={handle}
        />
        <div className={styles.slider_range}>
          <span className={styles.slider_range_value}>{`${FILTER_MIN} mi`}</span>
          <span className={styles.slider_range_value}>{`${FILTER_MAX} mi`}</span>
        </div>
      </div>
    );
  }

  render() {
    const {
      isFilterShown,
      isFilterActive,
      isRadiusShown,
      location,
      radius,
      selectedStyles,
      arrangeBy,
      selectedEventType,
      dateRange,
      weekDays,
    } = this.state;
    const { danceStyles } = this.props;
    const optionsStyles = makeSelectOptions(danceStyles);

    const geoFilterValueString = (location && radius) ? `${location}, ${radius} miles` : '';
    const selectArrangeStyles = arrangeBy ? selectActiveStyles : customStyles;
    const selectStylesStyle = selectedStyles.length > 0 ? selectActiveStyles : customStyles;
    const selectEventTypeStyles = selectedEventType.length > 0 ? selectActiveStyles : customStyles;

    const isEventFiltering = this.checkRouteFiltering() === EVENTS;
    const arrangeOptions = this.checkRouteFiltering() === TEACHERS
      ? [POPULARITY_OPTION, TEACHER_DATE_OPTION]
      : [POPULARITY_OPTION, DATE_OPTION];

    const dateRangeString = dateRange && dateRange.value
    && dateRange.value[0] && dateRange.value[1]
      ? `${moment(dateRange.value[0]).format('MMMM DD')} - ${moment(dateRange.value[1]).format('MMMM DD')}`
      : null;

    return (
      <>
        {isFilterShown
          ? (
            <div className={`${gridStyles.side_bar} ${styles.wrapper}`}>
              <h3 className={typographyStyles.page_title}>Filter</h3>
              <button
                type="button"
                className={buttonStyles.cancel_button}
                onClick={this.handleToggleFilter}
              >
                <CancelIcon />
              </button>
              <div className={styles.search_wrapper}>
                <form onSubmit={this.handleApplyFilter}>
                  {this.renderGeoCenterInput()}
                  {isRadiusShown && this.renderRadiusSlider()}

                  <div className={styles.select_title}>Dance style</div>
                  <Select
                    value={selectedStyles}
                    onChange={this.handleSelectStyles}
                    options={optionsStyles}
                    isMulti
                    closeMenuOnSelect={false}
                    className={`${inputStyles.safari_input} ${styles.select}`}
                    styles={selectStylesStyle}
                    placeholder={PLACEHOLDER.styles}
                  />

                  {isEventFiltering ? (
                    <>
                      <div className={styles.select_title}>Event type</div>
                      <Select
                        value={selectedEventType}
                        onChange={this.handleSelectEventType}
                        options={typeOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        className={`${inputStyles.safari_input} ${styles.select}`}
                        styles={selectEventTypeStyles}
                        placeholder={PLACEHOLDER.eventType}
                        isSearchable={false}
                      />
                    </>
                  ) : null}

                  <div className={styles.select_title}>Arrange</div>
                  <Select
                    value={arrangeBy}
                    onChange={this.handleSelectArrange}
                    options={arrangeOptions}
                    className={`${inputStyles.safari_input} ${styles.select}`}
                    styles={selectArrangeStyles}
                    placeholder={PLACEHOLDER.arrange}
                    isSearchable={false}
                  />

                  {isEventFiltering ? (
                    <>
                      <div className={styles.select_title}>Days:</div>
                      <DatePicker
                        value={dateRange && dateRange.value}
                        isActive={dateRange && dateRange.isActive}
                        onDateChange={this.handleDateChange}
                        renderOnLeftSide
                      />
                    </>
                  ) : (
                    <WeekPicker selectedDays={weekDays} handlePickDay={this.handlePickDay} />
                  )}

                  <div className={buttonStyles.buttons_row}>
                    <button
                      type="button"
                      className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
                      onClick={this.handleApplyFilter}
                    >
                  Apply
                    </button>
                    <button
                      type="button"
                      className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                      onClick={this.handleResetFilter}
                    >
                  Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )
          : (
            <>
              <button
                type="button"
                className={styles.icon_container}
                onClick={this.handleToggleFilter}
              >
                <FilterIcon className={isFilterActive ? styles.icon_active : styles.icon} />
                <span className={isFilterActive ? styles.title_active : styles.title}>Filter</span>
              </button>
              {isFilterActive && (
                <div className={styles.selected_container}>
                  {geoFilterValueString && (
                    <SelectedFilter
                      value={geoFilterValueString}
                      onClick={this.handleResetGeoFilter}
                    />
                  )}

                  {selectedStyles.length > 0 && (
                    selectedStyles.map(item => (
                      <SelectedFilter
                        key={item.value}
                        value={item.value}
                        onClick={this.handleResetStyleFilter}
                      />
                    ))
                  )}

                  {selectedEventType.length > 0 && isEventFiltering && (
                    selectedEventType.map(item => (
                      <SelectedFilter
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        onClick={this.handleResetEventType}
                      />
                    ))
                  )}

                  {arrangeBy && arrangeBy.value && arrangeBy.label && (
                    <SelectedFilter value={arrangeBy.label} onClick={this.handleResetArrangeBy} />
                  )}

                  {isEventFiltering && dateRangeString && (
                    <SelectedFilter value={dateRangeString} onClick={this.handleResetDateRange} />
                  )}

                  {weekDays.length > 0 && (
                    <SelectedFilter value={weekDays[0]} onClick={this.handleResetWeekdays} />
                  )}
                </div>
              )}
            </>
          )}
      </>
    );
  }
}

export default Filter;
