import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { getUserTickets, getUserPasses } from '../../api';
import getNestedValue from '../../utils/getNestedValue';

import UserTicket from './User_ticket';

import CancelIcon from '../icons/Cancel_icon';

import navStyles from '../../styles/navigation.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import typographyStyles from '../../styles/common.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import styles from './user_ticket.module.scss';

const INITIAL_STATE = {
  tickets: [],
  page: 1,
  total: 0,
  isLoading: false,
};

export default class TicketsList extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    startLoading: PropTypes.func.isRequired,
    finishLoading: PropTypes.func.isRequired,
    openTicketModal: PropTypes.func.isRequired,
    openPassModal: PropTypes.func.isRequired,
    createError: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
  }

  constructor() {
    super();

    this.listRef = createRef();
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.getList();
    if (this.listRef && this.listRef.current) {
      this.listRef.current.addEventListener('scroll', this.handleScroll);
    }
    window.addEventListener('scroll', this.handleMobileScroll);
  }

  componentDidUpdate(prevProps) {
    if ((this.props.token !== prevProps.token)
      || (this.props.location && this.props.location.pathname !== prevProps.location.pathname)) {
      this.switchList();
    }
  }

  componentWillUnmount() {
    this.listRef.current.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.handleMobileScroll);
  }

  switchList = () => {
    this.setState({ ...INITIAL_STATE }, this.getList);
  }

  getList = (page) => {
    const {
      token, location, createError,
      startLoading, finishLoading,
    } = this.props;
    const { isLoading } = this.state;

    const isTicketsListActive = location.pathname === '/my_tickets';
    const requestUserOrders = isTicketsListActive
      ? getUserTickets
      : getUserPasses;

    if (token && !isLoading) {
      startLoading();
      this.setState({ isLoading: true });
      requestUserOrders(token, { page })
        .then(
          (res) => {
            const data = getNestedValue(res, 'data', 'data');
            const total = getNestedValue(res, 'headers', 'total');
            if (res.status === 200 && data) {
              this.setState((prevState) => {
                if (Array.isArray(data)) {
                  return {
                    total,
                    page: page || INITIAL_STATE.page,
                    isLoading: false,
                    tickets: page ? [...prevState.tickets, ...data] : data,
                  };
                }
                return {
                  total,
                  page: page || INITIAL_STATE.page,
                  isLoading: false,
                };
              });
            }
          },
        )
        .catch((err) => {
          if (err.response && err.response.data.error) createError(err.response.data.error);
          else createError(err);
        })
        .finally(() => { finishLoading(); });
    }
  }

  getNewPage = () => {
    const { tickets, total, page, isLoading } = this.state;
    const hasMoreTickets = tickets.length < total;

    if (!isLoading && hasMoreTickets) {
      this.getList(page + 1);
    }
  }

  handleClose = () => {
    const { history, location } = this.props;
    const from = getNestedValue(location, 'state', 'from') || '/';
    history.push(from);
  }

  handleScroll = (e) => {
    const visibleHeight = e.srcElement.clientHeight;
    const scrolled = e.srcElement.scrollTop;
    const totalHeight = e.srcElement.scrollHeight;
    const timeToFetch = (totalHeight - visibleHeight - scrolled) <= 400;
    if (timeToFetch) {
      this.getNewPage();
    }
  };

  handleMobileScroll = () => {
    const visibleHeight = window.innerHeight;
    const scrolled = window.scrollY;
    const totalHeight = this.listRef.current.clientHeight;
    const timeToFetch = (totalHeight - visibleHeight - scrolled) <= 400;

    if (timeToFetch) {
      this.getNewPage();
    }
  };

  renderNav = () => (
    <nav>
      <ul className={navStyles.container}>
        <li className={navStyles.block_double}>
          <NavLink
            className={navStyles.item}
            activeClassName={navStyles.item_active}
            exact
            to="/my_tickets"
          >
            Events
          </NavLink>
        </li>
        <li className={navStyles.block_double}>
          <NavLink
            className={navStyles.item}
            activeClassName={navStyles.item_active}
            exact
            to="/my_passes"
          >
            Classes
          </NavLink>
        </li>
      </ul>
    </nav>
  );

  render() {
    const { tickets } = this.state;
    const { openTicketModal, openPassModal, location } = this.props;

    const isTicketsListActive = location.pathname === '/my_tickets';

    return (
      <div className={`${gridStyles.side_bar} ${gridStyles.mobile_on_top}`} ref={this.listRef}>
        <h3 className={typographyStyles.page_title}>Tickets</h3>
        <button
          type="button"
          className={buttonStyles.cancel_button}
          onClick={this.handleClose}
        >
          <CancelIcon />
        </button>
        {this.renderNav()}
        {tickets && tickets.length > 0 ? (
          <div>
            <ul>
              {tickets.map((order, i) => {
                if (order && !order.usable && tickets[i - 1] && tickets[i - 1].usable) {
                  return (
                    <Fragment key={order.id}>
                      <li className={styles.past_separator}>Past</li>
                      <li key={order.id}>
                        <UserTicket
                          order={order}
                          openModal={isTicketsListActive ? openTicketModal : openPassModal}
                          forPasses={!isTicketsListActive}
                        />
                      </li>
                    </Fragment>
                  );
                }
                return (
                  <li key={order.id}>
                    <UserTicket
                      order={order}
                      openModal={isTicketsListActive ? openTicketModal : openPassModal}
                      forPasses={!isTicketsListActive}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ) : <div>Your purchased tickets will be here.</div>}
      </div>
    );
  }
}
