import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import Calendar from 'react-calendar';
import moment from 'moment';

import ItemCard from '../Item_card/Item_card';

import { capitalizeStr } from '../../../utils/parse';
import { formatDateToDashString } from '../../../utils/formatDate';

import CalendarIcon from '../../icons/Calendar_thin';
import Arrow from '../../icons/Arrow';

import styles from '../../../styles/view_details.module.scss';
import buttonStyles from '../../../styles/buttons.module.scss';
import calendarStyles from '../../../styles/calendar.module.scss';
import '../../../styles/calendar.scss';

import { WEEK, TEACHER, VENUE } from '../../../config';

const INITIAL_STATE = {
  filteredItems: null,
  isCalendarOpened: false,
};

export class ListWithDate extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
      location: PropTypes.shape({
        search: PropTypes.string,
      }),
    }).isRequired,
    item: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      favorite: PropTypes.bool,
      type: PropTypes.oneOf([VENUE, TEACHER]),
    }).isRequired,
    getVenueItems: PropTypes.func.isRequired,
    getTeacherLessons: PropTypes.func.isRequired,
    getVenueDetails: PropTypes.func.isRequired,
    getTeacherDetails: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
  };

  static defaultProps = {
    isActive: true,
  }

  constructor(props) {
    super(props);
    this.calendarRef = null;
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const queryObject = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
    if (queryObject.day) {
      this.getFilteredItems(queryObject.day);
    }
  }

  getFilteredItems = (day) => {
    const { getVenueItems, getTeacherLessons, match, history } = this.props;
    const shouldVenueBeRequested = history && history.location
    && history.location.pathname && history.location.pathname.indexOf('venues') !== -1;
    const shouldTeacherBeRequested = history && history.location
      && history.location.pathname && history.location.pathname.indexOf('teachers') !== -1;

    if (shouldVenueBeRequested) {
      getVenueItems({
        id: match.params.id,
        day,
      }).then((response) => {
        if (response && response.data && response.data.data) {
          this.setState({ filteredItems: response.data.data });
        }
      });
    }

    if (shouldTeacherBeRequested) {
      getTeacherLessons({
        id: match.params.id,
        day,
      }).then((response) => {
        if (response && response.data && response.data.data) {
          this.setState({ filteredItems: response.data.data });
        }
      });
    }
  }

  handleApplyWeekDayFilter = (e) => {
    const { history } = this.props;
    let day = '';
    if (e.currentTarget.textContent && typeof e.currentTarget.textContent === 'string') {
      day = e.currentTarget.textContent.toLowerCase();
    }
    const queryObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const nextQueryObject = {
      ...queryObject,
      day,
    };
    history.push(`?${qs.stringify(nextQueryObject)}`);
    this.getFilteredItems(day);
  }

  setCalendarRef = (element) => {
    this.calendarRef = element;
  };

  handleCloseCalendar = () => this.setState({ isCalendarOpened: false });

  handleOpenCalendar = () => {
    this.setState({ isCalendarOpened: true });
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (this.calendarRef && !this.calendarRef.contains(e.target)) {
      this.setState({ isCalendarOpened: false });
      document.removeEventListener('mousedown', this.handleOutsideClick);
    }
  }

  handleApplyDateFilter = (date) => {
    const { history } = this.props;
    const day = formatDateToDashString(date);
    this.handleCloseCalendar();
    const queryObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const nextQueryObject = {
      ...queryObject,
      day,
    };
    history.push(`?${qs.stringify(nextQueryObject)}`);
    this.getFilteredItems(day);
  };

  handleResetFilter = () => {
    const { history, match, getVenueDetails, getTeacherDetails, item } = this.props;
    if (item.type === TEACHER) {
      getTeacherDetails({ id: match.params.id });
    }
    if (item.type === VENUE) {
      getVenueDetails({ id: match.params.id });
    }
    history.push(history.location.pathname);
  }

  render() {
    const { filteredItems, isCalendarOpened } = this.state;
    const { item, history, isActive } = this.props;
    const { weekdays, type } = item;
    const items = item.type === VENUE ? item.items : item.lessons;

    const activeDays = weekdays
      ? weekdays.map(day => capitalizeStr(day))
      : null;

    const queryObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const filterByDay = queryObject.day ? queryObject.day : '';
    const itemsToSHow = queryObject.day ? filteredItems : items;
    const showDate = !WEEK.includes(capitalizeStr(queryObject.day)) && queryObject.day;

    const minDate = moment().toDate();
    const maxDate = moment().add(30, 'years').toDate();
    const valueDate = (queryObject.day && showDate) ? new Date(filterByDay) : minDate;
    const formatedDate = (valueDate && valueDate instanceof Date) ? moment(valueDate).format('MMMM DD, YYYY') : '';

    return (
      <>
        <div className={styles.info_container}>
          <CalendarIcon className={styles.calendar_icon} />
          <div className={styles.wrapper}>
            <div className={styles.info_one_row}>
              <div className={styles.text_dark}>
                {WEEK.map((day) => {
                  const isDayActive = activeDays && activeDays.indexOf(day) !== -1;
                  const isDaySelected = day.toLowerCase() === filterByDay;
                  return (
                    isDayActive
                      ? (
                        <button
                          key={day}
                          type="button"
                          className={isDaySelected ? styles.day_selected : styles.day}
                          onClick={this.handleApplyWeekDayFilter}
                        >
                          {day}
                        </button>
                      )
                      : (
                        <span
                          key={day}
                          className={styles.day_disabled}
                        >
                          {day}
                        </span>
                      )
                  );
                })
                }
                {showDate && <div>{formatedDate}</div>}
              </div>
              {items && items.length !== 0 && (
                <button
                  className={buttonStyles.btn_select_date}
                  type="button"
                  onClick={this.handleOpenCalendar}
                >
                  Select Date
                </button>
              )}
            </div>
            {filterByDay && (
            <button
              type="button"
              className={styles.day_all}
              onClick={this.handleResetFilter}
            >
              Show all
            </button>
            )}
            {filterByDay && itemsToSHow && itemsToSHow.length === 0 && (
            <div className={styles.without_items}>
              No activities for this day
            </div>
            )}
          </div>
        </div>
        {isCalendarOpened && (
        <div className={calendarStyles.calendar_container} ref={this.setCalendarRef}>
          <Calendar
            className={calendarStyles.calendar}
            tileClassName={({ view }) => (view === 'century' ? calendarStyles.tile__century : calendarStyles.tile)}
            nextLabel={<Arrow direction="right" />}
            next2Label={null}
            prevLabel={<Arrow direction="left" />}
            prev2Label={null}
            minDate={minDate}
            maxDate={maxDate}
            onChange={this.handleApplyDateFilter}
            value={valueDate}
          />
        </div>
        )}
        {itemsToSHow && itemsToSHow.length > 0 && (
        <ul>
          {itemsToSHow.map(itemToShow => (
            <ItemCard
              key={itemToShow.id}
              item={itemToShow}
              history={history}
              venueView={!!(type && type === VENUE)}
              isActive={isActive}
            />
          ))}
        </ul>
        )}
      </>
    );
  }
}

export default withRouter(ListWithDate);
