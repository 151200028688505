import { requestEventAnalytics } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';

// eslint-disable-next-line
export const getEventAnalytics = (id, query) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;

  return requestEventAnalytics({ id, query, token })
    .catch((err) => {
      dispatch(createError(err));
      return err;
    })
    .finally(() => dispatch(finishLoading()));
};
