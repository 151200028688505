import {
  requestCreateLesson,
  requestEditLesson,
  requestAddPhotos,
  requestTeacherPasses,
} from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { setAndOpenModal, setModalMessage } from '../Modal/modal_actions';
import { signOut } from '../login/login_actions';
import createError from '../Fetch_error/fetch_error_action';
import { addMarker } from '../Map/map_actions';

import getNestedValue from '../../utils/getNestedValue';

export const createLesson = lesson => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestCreateLesson(lesson, token)
    .then(response => response.data && response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      dispatch(createError(rej));
      return rej.response;
    })
    .finally(() => dispatch(finishLoading()));
};

export const onLessonSubmitSuccess = props => (dispatch, getState) => {
  const { uploadPhotos, action, isItemFree } = props;
  if (action === 'create' && uploadPhotos) {
    dispatch(setModalMessage('Class was created successfully.'));
  }

  if (action === 'create' && !uploadPhotos) {
    dispatch(setModalMessage('Class was created successfully, but we need more time to process the photos. Please check or try again later by editing class, if something went wrong...'));
  }

  if (action === 'edit' && uploadPhotos) {
    dispatch(setModalMessage('Class was edited successfully.'));
  }

  if (action === 'edit' && !uploadPhotos) {
    dispatch(setModalMessage('Class was edited successfully, but we need more time to process the photos. Please check or try again later by editing class, if something went wrong...'));
  }

  if (action === 'create' || action === 'edit') {
    const { user } = getState().session;
    const connectStatus = !!user.connect;
    const chargesStatus = getNestedValue(user, 'connect', 'charges_enabled');
    const payoutsStatus = getNestedValue(user, 'connect', 'payouts_enabled');
    const forwardUserToStripe = !connectStatus || !chargesStatus || !payoutsStatus;
    if (forwardUserToStripe && !isItemFree) {
      dispatch(setAndOpenModal('STRIPE_CONNECT_MODAL'));
    } else dispatch(setAndOpenModal('SUCCES_MODAL_MESSAGE'));
  }

  dispatch(addMarker(null));
};

export const addPhotos = (id, photos) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestAddPhotos({ id, photos, token, type: 'lessons' })
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      if (rej.response && rej.response.status === 413) dispatch(createError('Files are too big. Please try again by editing lesson ...'));
      else dispatch(createError(rej));
      return rej.response && rej.response.data;
    })
    .finally(() => dispatch(finishLoading()));
};

export const editLesson = lesson => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestEditLesson(lesson, token)
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 403) {
        dispatch(createError('Sorry, editing class is forbidden.'));
        return rej.response && rej.response.data;
      }
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      dispatch(createError(rej));
      return rej;
    })
    .finally(() => dispatch(finishLoading()));
};

export const getTeacherPasses = id => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestTeacherPasses(id, token)
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      dispatch(createError(rej));
      return null;
    })
    .finally(() => dispatch(finishLoading()));
};
