import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import defaultAvatar from '../../images/default_avatar.jpg';
import styles from './friends.module.scss';
import AddFriendIcon from '../icons/Add_friend';
import AddedFriendIcon from '../icons/Added_friend';
import PendingFriendIcon from '../icons/Pending_friend';
import { FRIEND, REQUEST_SENT, REQUEST_RECEIVED } from '../../config';

export const User = (props) => {
  const { user, inviteUser, history } = props;
  if (!user) return null;

  const {
    first_name: firstName,
    last_name: lastName,
    nickname,
    friend_status: friendStatus,
    id,
  } = user;

  const avatarPath = user.avatar && user.avatar.thumb
    ? user.avatar.thumb : defaultAvatar;

  let secondaryName = '';
  if (firstName) secondaryName += firstName;
  if (lastName) secondaryName += ` ${lastName}`;

  const mainName = nickname || secondaryName || 'Anonymous';

  const inviteCurrentUser = () => {
    inviteUser(user);
  };

  const renderButton = () => {
    switch (friendStatus) {
      case FRIEND:
        return (
          <AddedFriendIcon className={`${styles.icon_box} ${styles.added_icon}`} />
        );

      case REQUEST_RECEIVED:
        return (
          <PendingFriendIcon className={`${styles.icon_box} ${styles.add_icon}`} />
        );

      case REQUEST_SENT:
        return (
          <PendingFriendIcon className={`${styles.icon_box} ${styles.pending_icon}`} />
        );

      default:
        return (
          <button
            type="button"
            onClick={inviteCurrentUser}
            className={styles.icon_box}
          >
            <AddFriendIcon className={styles.add_icon} />
          </button>
        );
    }
  };

  const path = {
    pathname: `/users/${id}`,
    state: { from: history.location.pathname },
  };

  return (
    <div className={styles.user_wrapper}>
      <Link to={path}>
        <div
          className={styles.avatar}
          style={{ backgroundImage: `url(${avatarPath})` }}
        />
      </Link>
      <div>
        <Link to={path}>
          <p>{mainName}</p>
          {nickname ? (
            <p className={styles.secondary_name}>{secondaryName}</p>
          ) : null}
        </Link>
      </div>

      {renderButton()}
    </div>
  );
};

User.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    nickname: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  inviteUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(User);
