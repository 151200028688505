import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import base64url from 'base64url';

import buttonStyles from '../../styles/buttons.module.scss';
import { closeModal, setCurrentModal, openModal } from '../Modal/modal_actions';
import createError from '../Fetch_error/fetch_error_action';
import { loginUser, loginUserWithGoogle, loginUserWithFacebook } from './login_actions';
import styles from './forms.module.scss';
import Eye from '../icons/Eye';
import EyeHidden from '../icons/Eye_hidden';
import GoogleLogo from '../icons/Google_logo';
import FacebookLogo from '../icons/Facebook_logo';
import AppleLogo from '../icons/Apple_logo';
import { resendActivationEmail } from '../../api';
import getNestedValue from '../../utils/getNestedValue';
import { loadAppleScript } from '../../utils/appleScriptLoad';
import { GOOGLE_CLIENT_ID, FACEBOOK_CLIENT_ID, APPLE_CLIENT_ID } from '../../config';

const initialState = {
  email: '',
  password: '',
  showPassword: false,
  confirmationEmailSend: false,
};

const REDIRECT_LINKS = [
  '/login',
  '/password_reset',
  '/email_confirmation',
];

const checkIncludes = str => (
  REDIRECT_LINKS.filter(item => str.includes(item))
);

class LoginForm extends Component {
  static propTypes = {
    signUserIn: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isAuth: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    closeModal: PropTypes.func,
    openModal: PropTypes.func,
    setCurrentModal: PropTypes.func,
    handleError: PropTypes.func.isRequired,
    googleLogin: PropTypes.func.isRequired,
    facebookLogin: PropTypes.func.isRequired,
  }

  static defaultProps = {
    error: null,
    history: null,
    closeModal: () => {},
    openModal: () => {},
    setCurrentModal: () => {},
  }

  state = { ...initialState }

  componentDidMount() {
    // creating global variable for using in webview app
    const { googleLogin, facebookLogin, history } = this.props;
    window.loginUserWithGoogle = googleLogin;
    window.facebookLogin = facebookLogin;
    // end of webview variables

    const { origin } = window.location;
    const from = getNestedValue(history, 'location', 'pathname');
    const STATE = base64url(JSON.stringify({
      redirect_url: `${origin}/apple_id_verify`,
      from_url: from,
    }));

    loadAppleScript({
      CLIENT_ID: APPLE_CLIENT_ID,
      SCOPES: 'name email',
      // REDIRECT_URI: 'https://dancity.devopsready.tools/api/apple_id_verify',
      REDIRECT_URI: `${origin}/api/apple_id_verify`,
      STATE,
    });
  }

  componentDidUpdate() {
    const { isAuth, history, location } = this.props;
    if (isAuth && (checkIncludes(location.pathname).length !== 0)) {
      const from = location.state && location.state.from ? location.state.from : '/';
      history.push(from);
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  handleShowPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  }

  openForgotPasswordModal = (e) => {
    e.preventDefault();

    this.props.closeModal();
    this.props.setCurrentModal('FORGOT_PASSWORD_MODAL');
    this.props.openModal();
  }

  handleResendEmail = (e) => {
    e.preventDefault();
    const { error, handleError } = this.props;
    if (!error) return;
    const { email } = error.login_error;
    if (!email) return;

    resendActivationEmail(email).then(
      (res) => {
        if (res.status === 204) {
          this.setState({ confirmationEmailSend: true });
        }
      },
      (rej) => {
        handleError(rej.response.data.error);
      },
    );
  }

  resetState = () => {
    this.setState(initialState);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const { signUserIn } = this.props;
    if (email !== '' && password !== '') {
      signUserIn({ email, password });
    }
  }

  handleGoogleLogin = (res) => {
    const { googleLogin } = this.props;
    const googleToken = res && res.tokenId;
    if (googleToken) {
      googleLogin(googleToken);
    }
  }

  handleGoogleLoginFailure = () => {
    const { handleError } = this.props;
    handleError('Login with google failed.');
  }

  responseFacebook = (res) => {
    const { facebookLogin } = this.props;
    const facebookToken = res && res.accessToken;

    if (facebookToken) {
      facebookLogin(facebookToken);
    }
  }

  androidGoogleLogin = () => {
    const androidGLogin = window.android && window.android.googleLogin;
    if (androidGLogin) window.android.googleLogin();
  }

  androidFacebookLogin = () => {
    const androidFBLogin = window.android && window.android.facebookLogin;
    if (androidFBLogin) window.android.facebookLogin();
  }

  startAppleLogin = () => {
    if (window.AppleID) {
      window.AppleID.auth.signIn();
    }
  }

  render() {
    const { error } = this.props;
    const { email, password, showPassword, confirmationEmailSend } = this.state;

    const showError = error && error.login_error && error.login_error.email
      && error.login_error.email === email;

    const showResendButton = error && error.login_error && error.login_error.showResendButton
      && !confirmationEmailSend;
    const showConfirmation = confirmationEmailSend && showError;
    const webView = !!window.android;

    return (
      <>
        <h4 className={styles.title}>Login to your account</h4>
        <form onSubmit={this.handleSubmit} noValidate autoComplete="on">
          <div className={styles.input_box}>
            <input
              type="email"
              name="email"
              value={email}
              onChange={this.handleInputChange}
              autoComplete="email"
            />
            <label
              htmlFor="email"
              className={email !== '' ? styles.with_value : undefined}
            >
              Email or username
            </label>
          </div>
          <div className={styles.input_box}>
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={password}
              onChange={this.handleInputChange}
              autoComplete="current-password"
              className={styles.autocomlete_off}
            />
            <label
              htmlFor="password"
              className={password !== '' ? styles.with_value : undefined}
            >
              Password
            </label>
            <button className={styles.eye_box} onClick={this.handleShowPassword} type="button">
              {showPassword ? <EyeHidden /> : <Eye />}
            </button>
          </div>
          {showError ? (
            <p className={styles.error_message}>{error.login_error.message}</p>
          ) : null
          }
          {showResendButton && !confirmationEmailSend && (
            <div>
              <button
                className={styles.forgot_password}
                type="button"
                onClick={this.handleResendEmail}
              >
                Resend confirmation mail?
              </button>
            </div>
          )}
          {showConfirmation && (
            <p className={styles.resend_success}>Confirmation email was resent.</p>
          )}
          <button className={styles.forgot_password} onClick={this.openForgotPasswordModal} type="button">
            Forgot your password?
          </button>
          <button type="submit" className={`${buttonStyles.btn_blue} ${buttonStyles.btn_in_modal} ${buttonStyles.btn_uppercase}`}>
            Log in
          </button>
          <div className={styles.divider}>
            or
          </div>
          <div className={styles.btn_margin_box}>
            {webView ? (
              <button
                type="button"
                className={`${buttonStyles.btn_google} ${buttonStyles.btn_uppercase}`}
                onClick={this.androidGoogleLogin}
              >
                <GoogleLogo />
                Login with Google
              </button>
            ) : (
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                onSuccess={this.handleGoogleLogin}
                onFailure={this.handleGoogleLoginFailure}
                render={renderProps => (
                  <button
                    type="button"
                    className={`${buttonStyles.btn_google} ${buttonStyles.btn_uppercase}`}
                    onClick={renderProps.onClick}
                  >
                    <GoogleLogo />
                    Login with Google
                  </button>
                )}
              />
            )}
          </div>
          <div className={styles.btn_margin_box}>
            {webView ? (
              <button
                type="button"
                className={`${buttonStyles.btn_in_modal} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_fb}`}
                onClick={this.androidFacebookLogin}
              >
                <FacebookLogo />
              Login with facebook
              </button>
            ) : (
              <FacebookLogin
                appId={FACEBOOK_CLIENT_ID}
                callback={this.responseFacebook}
                textButton="Login with facebook"
                disableMobileRedirect
                cssClass={`${buttonStyles.btn_in_modal} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_fb}`}
                icon={<FacebookLogo />}
              />
            )}
          </div>
          {webView ? null : (
            <button
              className={`${buttonStyles.btn_uppercase} ${buttonStyles.btn_apple}`}
              onClick={this.startAppleLogin}
              type="button"
            >
              <AppleLogo className={buttonStyles.apple_logo} /> Login with Apple Id
            </button>
          )}
        </form>
      </>
    );
  }
}

const mSTP = state => ({
  error: state.session.error,
  isAuth: state.session.authenticated,
});

const mDTP = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  setCurrentModal: modalName => dispatch(setCurrentModal(modalName)),
  openModal: () => dispatch(openModal()),
  signUserIn: credentials => dispatch(loginUser(credentials)),
  handleError: err => dispatch(createError(err)),
  googleLogin: googleToken => dispatch(loginUserWithGoogle(googleToken)),
  facebookLogin: facebookToken => dispatch(loginUserWithFacebook(facebookToken)),
});

export default withRouter(connect(mSTP, mDTP)(LoginForm));
export { LoginForm as Login };
