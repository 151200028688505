import {
  GET_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATIONS_COUNT,
  SIGN_OUT,
} from '../../redux/action_types';

const INITIAL_STATE = [];

export const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return action.payload;

    case DELETE_NOTIFICATION:
      return state.filter(notification => notification.id !== action.payload);

    case UPDATE_NOTIFICATION: {
      const currentId = action.payload && action.payload.id;
      return state.map(notification => (notification.id === currentId
        ? { ...notification, ...action.payload } : notification));
    }

    case SIGN_OUT:
      return [];

    default:
      return state;
  }
};

export const counterReducer = (state = 0, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS_COUNT:
      return action.payload;

    default:
      return state;
  }
};
