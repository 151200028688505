import {
  GET_ITEMS_FAILURE,
  GET_ITEMS_SUCCESS,
  GET_SEARCH_SUCCESS,
  RESET_LIST,
  SAVE_SCROLL_POSITION,
  TOGGLE_LIST_VIEW,
  GET_HOSTINGS_LIST_SUCCESS,
} from '../../redux/action_types';
import { HOSTING_LIST, SEARCH_LIST } from '../../config';

const initialState = {
  list: null,
  isListViewActive: true,
  scrollPosition: 0,
  items: [],
};

const getItems = (state = initialState, action) => {
  switch (action.type) {
    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        list: action.payload.list,
      };

    case GET_SEARCH_SUCCESS:
      return {
        ...state,
        items: action.payload,
        list: SEARCH_LIST,
      };

    case GET_HOSTINGS_LIST_SUCCESS:
      return {
        ...state,
        items: action.payload,
        list: HOSTING_LIST,
      };

    case RESET_LIST:
      return {
        ...state,
        items: [],
        list: null,
      };

    case GET_ITEMS_FAILURE:
      return {
        ...state,
        items: [],
        list: null,
      };

    case SAVE_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.payload,
      };

    case TOGGLE_LIST_VIEW:
      return {
        ...state,
        isListViewActive: !state.isListViewActive,
      };

    default:
      return state;
  }
};

export default getItems;
