import React from 'react';
import PropTypes from 'prop-types';
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';

import { cardInputStyles, cardCVVStyles } from './card_section_styles';
import styles from './card_section.module.scss';
import './stripe.scss';

const cardNumberPlaceholder = {
  placeholder: '0000 - 0000 - 0000 - 0000',
};

const cardCVVPlaceholder = {
  placeholder: '\u00B7\u00B7\u00B7',
};

const CardSection = ({ onNameChange, nameStyle }) => (
  <div className={styles.card}>
    <div className={styles.card_row}>
      <p className={styles.title}>card number</p>
      <CardNumberElement
        {...cardInputStyles}
        {...cardNumberPlaceholder}
      />
    </div>
    <div className={styles.card_column_container}>
      <div className={styles.card_column}>
        <p className={styles.title}>exp date</p>
        <CardExpiryElement
          {...cardInputStyles}
        />
      </div>
      <div className={styles.card_column}>
        <p className={styles.title}>cvv/cvc</p>
        <CardCVCElement
          {...cardCVVStyles}
          {...cardCVVPlaceholder}
        />
      </div>
    </div>
    <div className={styles.card_row}>
      <p className={styles.title}>cardholder</p>
      <input
        name="name"
        type="text"
        placeholder="Name"
        className={nameStyle}
        onChange={onNameChange}
      />
    </div>
  </div>
);

CardSection.propTypes = {
  onNameChange: PropTypes.func.isRequired,
  nameStyle: PropTypes.string,
};

CardSection.defaultProps = {
  nameStyle: '',
};

export default CardSection;
