import { connect } from 'react-redux';
import { getItems } from './my_items_actions';

import MyItems from './My_items';

const mSTP = (state) => {
  const { events, venues, teachers } = state.myItems;
  const { token } = state.session;
  const { isModalOpen } = state.modal;

  return ({
    events,
    venues,
    teachers,
    token,
    isModalOpen,
  });
};

const mDTP = { getItems };

export default connect(mSTP, mDTP)(MyItems);
