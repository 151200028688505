import { requestСheckoutOrder, requestСheckoutSubscription } from '../../../api';
import { startLoading, finishLoading } from '../../Loader/loading_actions';
import { signOut } from '../../login/login_actions';
import { setAndOpenModal } from '../../Modal/modal_actions';
import createError from '../../Fetch_error/fetch_error_action';

export const checkoutOrder = (data, id) => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());
  return requestСheckoutOrder(data, id, token)
    .then((response) => {
      if (response.status === 201 && response.data) {
        dispatch(setAndOpenModal('TICKET_PAYMENT_SUCCESS'));
        return response;
      }
      return null;
    })
    .catch((error) => {
      const status = error.response && error.response.status;
      if (status === 401) dispatch(signOut());
      if (status === 422 || status === 403) {
        return error.response;
      }
      dispatch(createError(error));
      dispatch(setAndOpenModal('PAYMENT_FAILURE'));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};

export const checkoutSubscription = (data, id) => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());
  return requestСheckoutSubscription(data, id, token)
    .then((response) => {
      if (response.status === 201 && response.data) {
        dispatch(setAndOpenModal('PAYMENT_SUCCESS'));
        return response;
      }
      return null;
    })
    .catch((error) => {
      const status = error.response && error.response.status;
      if (status === 401) dispatch(signOut());
      if (status === 422 || status === 403) {
        return error.response;
      }
      dispatch(createError(error));
      dispatch(setAndOpenModal('PAYMENT_FAILURE'));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};
