import { connect } from 'react-redux';
import PassForm from './Pass_Form';
import {
  createPass,
  editPass,
  onPassSubmitSuccess,
  getTeacherClasses,
} from './pass_form_actions';
import { getTeacherDetails } from '../Teacher_details/teacher_details_actions';

import createError from '../Fetch_error/fetch_error_action';
import { setAndOpenModal } from '../Modal/modal_actions';


const mSTP = state => ({
  token: state.session.token,
  user: state.session.user,
});

const mDTP = dispatch => ({
  createPass: lesson => (dispatch(createPass(lesson))),
  editPass: lesson => (dispatch(editPass(lesson))),
  handleError: err => dispatch(createError(err)),
  onPassSubmitSuccess: options => dispatch(onPassSubmitSuccess(options)),
  getTeacherClasses: id => dispatch(getTeacherClasses(id)),
  getTeacherDetails: id => dispatch(getTeacherDetails({ id })),
  openModal: modalName => dispatch(setAndOpenModal(modalName)),
});

export default connect(mSTP, mDTP)(PassForm);
