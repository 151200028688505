import { requestVenueDetails } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import { moveMapCenterTo, addMarker } from '../Map/map_actions';
import {
  GET_VENUE_DETAILS_SUCCESS,
  GET_VENUE_DETAILS_FAILURE,
} from '../../redux/action_types';
import { getItemsList } from '../List/list_actions';

export const getVenueDetailsSuccess = venue => ({
  type: GET_VENUE_DETAILS_SUCCESS,
  payload: venue,
});

export const getVenueDetailsFailure = error => ({
  type: GET_VENUE_DETAILS_FAILURE,
  payload: { error },
});

export const getVenueDetails = ({ id, moveMap, filter }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestVenueDetails(id, token)
    .then((response) => {
      const venue = response.data.data;
      dispatch(getVenueDetailsSuccess(venue));
      const { coordinates } = venue;
      dispatch(getItemsList({
        list: 'venues',
        token,
        filter,
      }))
        .then(() => {
          if (coordinates && moveMap) {
            dispatch(moveMapCenterTo(coordinates));
            const { markers } = getState().map;
            const itemHasMarker = markers.some(item => item.id === id);
            if (!itemHasMarker) dispatch(addMarker(coordinates));
          }
        });
      return response;
    })
    .catch((error) => {
      dispatch(createError(error));
      dispatch(getVenueDetailsFailure(error));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};
