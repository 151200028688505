const fallBackStyles = [
  'Afro-house',
  'Animation',
  'Bachata',
  'Balboa',
  'Ballet',
  'Belly Dance',
  'Blues',
  'Bolero',
  'Bollywood',
  'Brazilian zouk',
  'Breaking',
  'Burlesque',
  'Cha-Cha',
  'Contemperary',
  'Country Cha-Cha',
  'Country Swing',
  'Cultural',
  'Cumbia',
  'Dance Hall',
  'East Coast Swing',
  'Flamenco',
  'Folklorico',
  'Forro',
  'Foxtrot',
  'Fusion',
  'Hip-Hop',
  'House',
  'Jazz',
  'Jitter-bug',
  'Jive',
  'Kizomba',
  'Krump',
  'Lindy-Hop',
  'Line Dance',
  'Locking',
  'Merengue',
  'Pachanga',
  'Pase Doble',
  'Popping',
  'QuickStep',
  'Reggae',
  'Reggaeton',
  'Rueda de Casino',
  'Rumba',
  'Salsa',
  'Samba',
  'Shag',
  'Square Dance',
  'Tango',
  'Tap-Dance',
  'Two Step',
  'Viennese Walts',
  'Waacking',
  'Waltz',
  'Wedding',
  'West Coast Swing',
  'Zouk',
  'Zumba',
];

export default fallBackStyles;
