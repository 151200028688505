export const getStateFromLS = () => {
  try {
    const localState = localStorage.getItem('DancityState');
    if (localState === null) {
      return undefined;
    }
    return ({ session: JSON.parse(localState) });
  } catch (error) {
    return undefined;
  }
};

export const saveStateToLS = (state) => {
  try {
    localStorage.setItem('DancityState', JSON.stringify(state));
  } catch (error) {
    // catch errors here
  }
};
