import axios from 'axios';

export const requestCreateComment = ({ comment, id, token, type }) => (
  axios.post(
    `/api/${type}s/${id}/comments`,
    { data: comment },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestDeleteComment = ({ id, token }) => (
  axios.delete(
    `/api/comments/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestEditComment = ({ comment, id, token }) => (
  axios.patch(
    `/api/comments/${id}`,
    { data: comment },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);
