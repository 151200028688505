import { connect } from 'react-redux';
import Checkout from './Checkout';
import createError from '../Fetch_error/fetch_error_action';

const mSTP = state => ({ token: state.session.token });

const mDTP = dispatch => ({
  handleError: err => dispatch(createError(err)),
});

export default connect(mSTP, mDTP)(Checkout);
