import { connect } from 'react-redux';
import TeacherForm from './Teacher_Form';
import {
  createTeacher,
  editTeacher,
  addPhotos,
  onTeacherSubmitSuccess,
} from './teacher_form_actions';
import { getTeacherDetails } from '../Teacher_details/teacher_details_actions';

import { getDanceStyles } from '../shared/Dance_styles/dance_styles_actions';
import createError from '../Fetch_error/fetch_error_action';
import { moveMapCenterTo, addMarker } from '../Map/map_actions';
import { setAndOpenModal } from '../Modal/modal_actions';

const mSTP = state => ({
  token: state.session.token,
  user: state.session.user,
  danceStyles: state.danceStyles.styles,
});

const mDTP = dispatch => ({
  createTeacher: (teacher, token) => (dispatch(createTeacher(teacher, token))),
  addPhotos: (id, photos, token) => (dispatch(addPhotos(id, photos, token))),
  editTeacher: (teacher, token) => (dispatch(editTeacher(teacher, token))),
  getDanceStyles: () => dispatch(getDanceStyles()),
  handleError: err => dispatch(createError(err)),
  onTeacherSubmitSuccess: options => dispatch(onTeacherSubmitSuccess(options)),
  showNewMarker: (coords) => {
    dispatch(moveMapCenterTo(coords));
    dispatch(addMarker(coords));
  },
  getTeacherDetails: ({ id }) => (dispatch(getTeacherDetails({ id, moveMap: true }))),
  openModal: modalName => dispatch(setAndOpenModal(modalName)),
});

export default connect(mSTP, mDTP)(TeacherForm);
