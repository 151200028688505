import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Carousel from 'nuka-carousel';
import MediaQuery from 'react-responsive';
import defaultPhoto from '../../../images/default_event_photo.jpg';
import CancelIcon from '../../icons/Cancel_icon';
import Arrow from '../../icons/Arrow';


import styles from '../modal.module.scss';
import sliderStyles from './full_screen.module.scss';


if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

class FullScreenModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    photos: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    photos: [],
  };

  state = {
    slideIndex: 0,
  }

  handleLoadImage = () => {
    this.carousel.setDimensions();
  }

  nextSlide = () => {
    this.setState(prevState => ({ slideIndex: prevState.slideIndex + 1 }));
  }

  prevSlide = () => {
    this.setState(prevState => ({ slideIndex: prevState.slideIndex - 1 }));
  }

  render() {
    const { isModalOpen, closeModal, photos } = this.props;

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className={styles.content_slider}
        overlayClassName={styles.slider_overlay}
      >
        <div className={sliderStyles.photos_container}>
          {photos && photos.length > 1 && (
            <>
              <Carousel
                wrapAround
                swiping
                cellAlign="center"
                renderCenterLeftControls={() => null}
                renderCenterRightControls={() => null}
                renderBottomCenterControls={() => null}
                slideIndex={this.state.slideIndex}
                afterSlide={slideIndex => this.setState({ slideIndex })}
                ref={(c) => { this.carousel = c; }}
                heightMode="max"
              >
                {photos.map(photo => (
                  <img
                    className={sliderStyles.slide}
                    src={photo.original || photo.full}
                    alt="slide"
                    key={photo.original || photo.full}
                    onLoad={this.handleLoadImage}
                  />
                ))}
              </Carousel>
              <MediaQuery minWidth={768}>
                <button type="button" onClick={this.prevSlide} className={sliderStyles.button_left}>
                  <Arrow className={sliderStyles.arrow_left} />
                </button>
                <button type="button" onClick={this.nextSlide} className={sliderStyles.button_right}>
                  <Arrow className={sliderStyles.arrow_right} />
                </button>
              </MediaQuery>
              <MediaQuery maxWidth={767}>
                <div className={sliderStyles.pager_container}>
                  {photos.map((photo, index) => (
                    <button
                      type="button"
                      className={this.state.slideIndex === index
                        ? sliderStyles.active_circle : sliderStyles.circle_button}
                      onClick={() => { this.setState({ slideIndex: index }); }}
                      key={photo.id}
                    />
                  ))}
                </div>
              </MediaQuery>
            </>
          )}
          {photos && photos.length === 1 && (
            <img
              className={sliderStyles.slide}
              src={photos[0].original || photos[0].full}
              alt="slide"
              key={photos[0].original || photos[0].full}
            />
          )}
          {photos && photos.length === 0 && (
          <div
            className={styles.slide_single}
            style={{ backgroundImage: `url(${defaultPhoto})` }}
          />
          )}
        </div>
        <button type="button" onClick={closeModal} className={styles.close_button_fixed}>
          <CancelIcon className={sliderStyles.cancel_icon} />
        </button>
      </Modal>
    );
  }
}

export default FullScreenModal;
