import {
  getPayMethods, requestDeletePayMethod,
  requestCreatePayMethod, requestSetDefaultMethod,
} from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';

import getNestedValue from '../../utils/getNestedValue';

import { GET_PAYMENT_METHODS } from '../../redux/action_types';


export const getPaymentMethods = discardLoader => (dispatch, getState) => {
  const { token } = getState().session;

  if (!discardLoader) dispatch(startLoading());

  return getPayMethods(token)
    .then((res) => {
      if (res.status === 200 && res.data && res.data.data) {
        dispatch({
          type: GET_PAYMENT_METHODS,
          payload: res.data.data,
        });
      }
      return res;
    })
    .catch((error) => {
      const errorText = getNestedValue(error, 'response', 'data', 'error');
      dispatch(createError(errorText));
    })
    .finally(() => dispatch(finishLoading()));
};

export const addPaymentMethod = cardToken => (dispatch, getState) => {
  const state = getState();
  const { token } = state.session;
  const { paymentMethods } = state;
  dispatch(startLoading());

  return requestCreatePayMethod({ cardToken, token })
    .then((res) => {
      if (res.status === 201 && res.data && res.data.data) {
        const incomingMethod = res.data.data;
        const alreadyExist = paymentMethods.some(item => item.id === incomingMethod.id);
        if (alreadyExist) {
          dispatch(createError('Card already exist'));
          return res;
        }

        dispatch(getPaymentMethods(true));
      }
      return res;
    })
    .catch(err => dispatch(createError(err && err.response && err.response.error)))
    .finally(() => dispatch(finishLoading()));
};


export const deletePaymentMethod = id => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());

  return requestDeletePayMethod({ id, token })
    .then((res) => {
      if (res.status === 204) {
        dispatch(getPaymentMethods(true));
      }
      return res;
    })
    .catch(err => dispatch(createError(err)))
    .finally(() => dispatch(finishLoading()));
};

export const setDefaultMethod = id => (dispatch, getState) => {
  const { token } = getState().session;
  dispatch(startLoading());

  return requestSetDefaultMethod({ id, token })
    .then((res) => {
      if (res.status === 200 && res.data && res.data.data) {
        dispatch(getPaymentMethods(true));
      }
      return res;
    })
    .catch(err => dispatch(createError(err)))
    .finally(() => dispatch(finishLoading()));
};
