import React from 'react';
import PropTypes from 'prop-types';

const StarIcon = ({ className, transparent }) => (
  transparent
    ? (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" className={className}>
        <path d="M22.875 8.603c-.281-.896-1.011-1.537-1.907-1.672l-5.083-.769-2.272-4.794c-.401-.845-1.212-1.37-2.118-1.37-.905 0-1.717.525-2.118 1.37L7.105 6.162l-5.082.768c-.897.136-1.627.777-1.908 1.673a2.515 2.515 0 0 0 .598 2.52l3.678 3.731-.869 5.268a2.493 2.493 0 0 0 .94 2.404c.414.313.898.472 1.386.472.375 0 .752-.094 1.101-.285l4.546-2.487 4.546 2.487c.34.186.72.284 1.1.284h.001c.5 0 .979-.163 1.386-.471a2.491 2.491 0 0 0 .939-2.404l-.868-5.268 3.678-3.731a2.518 2.518 0 0 0 .598-2.52zm-2.023.998l-3.844 3.9a1.709 1.709 0 0 0-.46 1.474l.907 5.506a.328.328 0 0 1-.127.326.303.303 0 0 1-.337.026l-4.751-2.6a1.544 1.544 0 0 0-1.49 0l-4.751 2.6a.303.303 0 0 1-.337-.026.327.327 0 0 1-.127-.326l.907-5.506a1.706 1.706 0 0 0-.461-1.474l-3.843-3.9a.335.335 0 0 1-.081-.342.318.318 0 0 1 .259-.226l5.311-.803a1.603 1.603 0 0 0 1.205-.912l2.376-5.009a.309.309 0 0 1 .287-.186.31.31 0 0 1 .287.186l2.375 5.009c.234.493.684.833 1.206.912l5.311.803a.32.32 0 0 1 .259.226.337.337 0 0 1-.081.342z" />
      </svg>
    )
    : (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.94 49.94" className={className}>
        <path d="M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757  c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996  L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438  c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701  c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0  l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904  l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z" />
      </svg>
    )
);

StarIcon.propTypes = {
  className: PropTypes.string,
  transparent: PropTypes.bool,
};

StarIcon.defaultProps = {
  className: '',
  transparent: true,
};

export default StarIcon;
