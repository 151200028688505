import { SET_FS_PHOTOS } from '../../../redux/action_types';

const fullScreenReducer = (state = [], action) => {
  switch (action.type) {
    case SET_FS_PHOTOS:
      return action.payload;
    default:
      return state;
  }
};

export default fullScreenReducer;
