import { connect } from 'react-redux';
import { getEventAnalytics } from './event_analytics_actions';
import EventAnalytics from './Event_analytics';

const mSTP = state => ({
  user: state.session.user,
});

const mDTP = { getEventAnalytics };

export default connect(mSTP, mDTP)(EventAnalytics);
