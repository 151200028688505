import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { getRecurrentOptions } from '../../../utils/getRecurrentOptions';
import { capitalizeStr } from '../../../utils/parse';

import DatePicker from '../Date_picker/Date_picker';
import TimePicker from '../Time_picker/Time_picker';
import WeekPicker from '../Week_picker/Week_picker';

import customSelectStyles, { selectErrorStyles, selectActiveStyles } from '../../../styles/select_styles';
import inputStyles from '../../../styles/inputs.module.scss';
import styles from '../../../styles/create_form.module.scss';

import {
  ONCE, DAILY, WEEKLY, BI_WEEKLY, MONTHLY, BI_WEEKLY_LABEL,
  MAX_DURATION_DAYS,
} from '../../../config';

const PLACEHOLDER = {
  recurrent: 'Repeat',
  recurrent_options: 'Select repeat options ...',
};

const RECURRENT_OPTIONS = [
  {
    value: ONCE,
    label: capitalizeStr(ONCE),
  },
  {
    value: WEEKLY,
    label: capitalizeStr(WEEKLY),
  },
  {
    value: BI_WEEKLY,
    label: BI_WEEKLY_LABEL,
  },
  {
    value: MONTHLY,
    label: capitalizeStr(MONTHLY),
  },
];

const getSelectStyles = (isValid, isActive) => {
  let selectStyles = isValid ? customSelectStyles : selectErrorStyles;
  if (isActive) selectStyles = selectActiveStyles;
  return selectStyles;
};

const checkIfTwoDatesAvailable = (recurrent) => {
  const { isActive, value } = recurrent;
  const freq = value && value.value;

  return freq === ONCE || freq === MONTHLY || !isActive;
};

class DateBlock extends Component {
  static propTypes = {
    componentSetState: PropTypes.func.isRequired,
    resetTicketsErrors: PropTypes.func,
    recurrent: PropTypes.shape({
      isActive: PropTypes.bool,
      isValid: PropTypes.bool,
    }).isRequired,
    date: PropTypes.shape({
      isActive: PropTypes.bool,
      isValid: PropTypes.bool,
    }).isRequired,
    time: PropTypes.shape({
      isActive: PropTypes.bool,
      isValid: PropTypes.bool,
      isError: PropTypes.bool,
    }).isRequired,
    onceOptionUnavailable: PropTypes.bool,
  };

  static defaultProps = {
    resetTicketsErrors: () => {},
    onceOptionUnavailable: false,
  }

  handleSelectMonthlyRecurrent = (value) => {
    const { componentSetState } = this.props;
    componentSetState(prevState => ({ recurrent: {
      ...prevState.recurrent,
      monthlyOption: value,
      isValid: true,
    } }));
  };

  handleSelectRecurrent = (value) => {
    const { componentSetState } = this.props;
    if (value.value === DAILY) {
      componentSetState(prevState => ({ recurrent:
      {
        ...prevState.recurrent,
        monthlyOption: '',
        weeklyOption: [],
      } }));
    }
    if (value.value === WEEKLY || value.value === BI_WEEKLY) {
      componentSetState(prevState => ({ recurrent:
        {
          ...prevState.recurrent,
          monthlyOption: '',
        } }));
    }
    if (value.value === MONTHLY) {
      componentSetState(prevState => ({ recurrent:
        {
          ...prevState.recurrent,
          weeklyOption: [],
        } }));
    }
    componentSetState(prevState => ({ recurrent:
      {
        ...prevState.recurrent,
        value,
        isValid: true,
        isActive: true,
        error: null,
      } }));
  }

  handlePickDay = (name, checked) => {
    const { componentSetState } = this.props;
    if (checked) {
      componentSetState(prevState => ({
        recurrent: {
          ...prevState.recurrent,
          weeklyOption: [...prevState.recurrent.weeklyOption, name],
          isValid: true,
        },
      }));
    } else {
      componentSetState(prevState => ({
        recurrent: {
          ...prevState.recurrent,
          weeklyOption: prevState.recurrent.weeklyOption.filter(day => day !== name),
        },
      }));
    }
  }

  handleInputDateChange = (date) => {
    const { componentSetState, resetTicketsErrors } = this.props;
    componentSetState(prevState => ({
      date: {
        isActive: true,
        isValid: true,
        value: date,
        error: null,
      },
      time: {
        ...prevState.time,
        isValid: true,
        isError: false,
      },
      recurrent: {
        ...prevState.recurrent,
        monthlyOption: (prevState.date.value[0]
          && prevState.date.value[0].getTime() === date[0].getTime())
          ? { ...prevState.recurrent.monthlyOption }
          : '',
      },
    }));
    resetTicketsErrors();
    this.checkDuration(date);
  }

  checkDuration = (date) => {
    const { componentSetState, recurrent } = this.props;
    if (checkIfTwoDatesAvailable(recurrent)) {
      const startDate = new Date(date[0]);
      const endDate = new Date(date[1]);
      const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (diffDays > MAX_DURATION_DAYS) {
        componentSetState(prevState => ({
          date: {
            ...prevState.date,
            isActive: true,
            isValid: false,
            error: 'Max duration is 14 days',
          },
        }));
      }
    }
  }

  handleTimeChange = ({ timeFrom, timeTo }) => {
    const { componentSetState } = this.props;
    componentSetState(prevState => ({
      ...prevState,
      time: {
        timeFrom,
        timeTo,
        isActive: true,
        isValid: true,
      },
    }));
  }

  render() {
    const {
      recurrent: {
        isActive: isActiveRecurrent,
        isValid: isValidRecurrent,
        value: valueRecurrent,
        weeklyOption,
        monthlyOption,
      },
      date: {
        isActive: isActiveDate,
        isValid: isValidDate,
        value: valueDate,
        error: errorDate,
      },
      time: {
        isActive: isActiveTime,
        isValid: isValidTime,
        isError: isErrorTime,
        timeFrom,
        timeTo,
      },
      onceOptionUnavailable,
    } = this.props;

    let recurrentOptions = RECURRENT_OPTIONS;
    if (onceOptionUnavailable) {
      recurrentOptions = RECURRENT_OPTIONS.filter(option => option.value !== ONCE);
    }

    const selectrecurrentStyles = getSelectStyles(isValidRecurrent, isActiveRecurrent);

    const freq = valueRecurrent && valueRecurrent.value;
    const isWeekPickerShown = freq === WEEKLY || freq === BI_WEEKLY;
    const isMonthlyOptionsShown = freq === MONTHLY;
    const isTwoDatesAvailbale = checkIfTwoDatesAvailable(this.props.recurrent);

    return (
      <>
        <Select
          value={valueRecurrent}
          onChange={this.handleSelectRecurrent}
          options={recurrentOptions}
          className={inputStyles.safari_input}
          styles={selectrecurrentStyles}
          placeholder={PLACEHOLDER.recurrent}
          isSearchable={false}
        />
        {!isWeekPickerShown && (
        <DatePicker
          onDateChange={this.handleInputDateChange}
          isActive={isActiveDate}
          isValid={isValidDate}
          value={valueDate}
          hasTwoDates={isTwoDatesAvailbale}
          maxDuration={MAX_DURATION_DAYS}
        />
        )}
        {isWeekPickerShown && (
        <WeekPicker selectedDays={weeklyOption} handlePickDay={this.handlePickDay} />
        )}
        {isMonthlyOptionsShown && (
        <Select
          value={monthlyOption}
          onChange={this.handleSelectMonthlyRecurrent}
          options={valueDate[0]
            ? getRecurrentOptions(valueDate[0])
            : getRecurrentOptions(new Date())}
          className={inputStyles.safari_input}
          styles={selectrecurrentStyles}
          placeholder={PLACEHOLDER.reccurent_options}
          isSearchable={false}
        />
        )}
        <TimePicker
          onTimeChange={this.handleTimeChange}
          isActive={isActiveTime}
          isValid={isValidTime}
          timeFrom={timeFrom}
          timeTo={timeTo}
        />
        {isErrorTime
        && <div className={styles.error}>Time from cannnot be later then time to</div>
        }
        {isWeekPickerShown && (
        <DatePicker
          onDateChange={this.handleInputDateChange}
          isActive={isActiveDate}
          isValid={isValidDate}
          value={valueDate}
          hasTwoDates={isTwoDatesAvailbale}
          maxDuration={MAX_DURATION_DAYS}
        />
        )}
        {errorDate
        && <div className={styles.error}>{errorDate}</div>
        }
      </>
    );
  }
}

export default DateBlock;
