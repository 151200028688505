import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import TicketForm from '../Ticket_form/Ticket_Form';
import SimpleItemPreview from '../shared/Simple_item_preview/Simple_item_preview';
import styles from '../../styles/create_form.module.scss';

const TickestList = (props) => {
  const {
    ableToEditTickets,
    isViewedByOwner,
    tickets,
    ticketErrorsfromBE,
    handleError,
    allFieldsActive,
    isEventRepeating,
    handleSaveTicket,
    handleRemoveTicket,
    handleOpenTicket,
  } = props;

  return (
    ableToEditTickets || isViewedByOwner ? (
      <Droppable droppableId="tickets_list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps} className={styles.droppable}>
            {tickets.map((ticket, index) => (
              <TicketForm
                onSaveTicket={handleSaveTicket}
                onRemoveTicket={handleRemoveTicket}
                onOpenTicket={handleOpenTicket}
                ticket={
                      (!ticketErrorsfromBE[index]
                        || Object.keys(ticketErrorsfromBE[index]).length === 0)
                        ? ticket
                        : { ...ticket, isOpened: true }
                    }
                key={ticket.id || String(ticket.tempId)}
                index={index}
                error={ticketErrorsfromBE[index]}
                handleError={handleError}
                allFieldsActive={allFieldsActive}
                isEventRepeating={isEventRepeating}
                isViewedByOwner={isViewedByOwner}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    ) : tickets.map((ticket, index) => (
      <SimpleItemPreview
        item={ticket}
        key={`${ticket.name}_${index.toString()}`}
        detailsView
      />
    ))
  );
};

TickestList.propTypes = {
  ableToEditTickets: PropTypes.bool.isRequired,
  isViewedByOwner: PropTypes.bool.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    date: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    refunds: PropTypes.bool,
    isOpened: PropTypes.bool,
    isValid: PropTypes.bool,
    destroyable: PropTypes.bool,
    validity: PropTypes.number,
    total_visits: PropTypes.number,
  })).isRequired,
  ticketErrorsfromBE: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleError: PropTypes.func.isRequired,
  allFieldsActive: PropTypes.bool.isRequired,
  isEventRepeating: PropTypes.bool.isRequired,
  handleSaveTicket: PropTypes.func.isRequired,
  handleRemoveTicket: PropTypes.func.isRequired,
  handleOpenTicket: PropTypes.func.isRequired,
};

export default TickestList;
