import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { getRecurrentString } from '../../utils/getRecurrentString';
import { validateTitle, validateText, checkUserCreatAbilyti } from '../../utils/validation';
import { getTimeObject } from '../../utils/formatTime';
import { getCorrectDate } from '../../utils/formatDate';
import { capitalizeStr } from '../../utils/parse';
import { makeSelectOptions } from '../../utils/selectOptions';
import combineDateAndTime from '../../utils/combineDateAndTime';
import getNearestRecurrentDate from '../../utils/getNearestRecurrentDate';
import checkIfItemIsFree from '../../utils/checkIfItemIsFree';

import PhotoManager from '../shared/Photo_manager/Photo_manager_container';
import DateBlock from '../shared/Date_block/Date_block';
import SimpleItemPreview from '../shared/Simple_item_preview/Simple_item_preview';
import LessonDetails from '../Lesson_details/lesson_details_container';
import VenueSelector from '../Venue_Selector/Venue_selector_container';
import LinkSelector from '../shared/Link_selector/Link_selector';
import TextArea from '../shared/Textarea/Textarea';
import getNestedValue from '../../utils/getNestedValue';

import inputStyles from '../../styles/inputs.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import customSelectStyles, { selectErrorStyles, selectActiveStyles } from '../../styles/select_styles';
import styles from '../../styles/create_form.module.scss';

import {
  ONCE, DAILY, WEEKLY, BI_WEEKLY, MONTHLY,
} from '../../config';

const PLACEHOLDER = {
  title: 'Class name',
  location: 'Location',
  venue: 'Venue',
  address: 'Address',
  recurrent: 'Repeat',
  recurrent_options: 'Select recurrent options ...',
};

const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

const INITIAL_STATE = {
  isLoading: false,
  title: { ...INITIAL_STATE_FIELD },
  photos: [],
  date: {
    ...INITIAL_STATE_FIELD,
    isValid: true,
  },
  time: {
    timeFrom: '',
    timeTo: '',
    isActive: false,
    isValid: true,
    isError: false,
  },
  type: {
    ...INITIAL_STATE_FIELD,
    isValid: true,
  },
  recurrent: {
    ...INITIAL_STATE_FIELD,
    isValid: true,
    weeklyOption: [],
    monthlyOption: '',
  },
  description: { ...INITIAL_STATE_FIELD },
  location: {
    ...INITIAL_STATE_FIELD,
    venueList: [],
    isVenueListShown: false,
    venueId: null,
  },
  address: {
    ...INITIAL_STATE_FIELD,
    isAddressShown: false,
  },
  selectedStyles: {
    value: [],
    isValid: true,
    isActive: false,
  },
  coordinates: null,
  tickets: [],
  previewView: false,
  lesson: null,
  photosError: false,
  photosToRemove: [],
  ticketsToRemove: [],
  teacher: null,
};

class LessonForm extends Component {
  static propTypes = {
    createLesson: PropTypes.func.isRequired,
    getDanceStyles: PropTypes.func.isRequired,
    showNewMarker: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    danceStyles: PropTypes.arrayOf(PropTypes.string).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    editLesson: PropTypes.func.isRequired,
    addPhotos: PropTypes.func.isRequired,
    onLessonSubmitSuccess: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    getTeacherPasses: PropTypes.func.isRequired,
    getTeacherDetails: PropTypes.func.isRequired,
    getLessonDetails: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
      nickname: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      profession: PropTypes.string,
    }).isRequired,
    openModal: PropTypes.func.isRequired,
  };

  state = { ...INITIAL_STATE };

  async componentDidMount() {
    const {
      getDanceStyles, history,
      match, handleError, getTeacherDetails,
      getLessonDetails,
    } = this.props;
    getDanceStyles();

    const idToEdit = match && match.params && match.params.lesson_id;
    let lessonToEdit;

    if (idToEdit) {
      const lessonResponse = await getLessonDetails(idToEdit)
        .catch((err) => {
          const errMessage = err && err.response && err.response.data.error;
          handleError(errMessage);
          history.push('/');
        });
      lessonToEdit = lessonResponse && lessonResponse.data && lessonResponse.data.data;
    } else {
      const lessonTeacherId = match && match.params && match.params.id;
      if (lessonTeacherId) {
        getTeacherDetails(lessonTeacherId)
          .then(data => this.setState({ teacher: data && data.data && data.data.data }));
      }
    }
    if (!lessonToEdit && history.location.pathname.indexOf('/account/edit_lesson/') !== -1) history.push('/');
    if (lessonToEdit && history.location.pathname !== '/create_lesson') {
      const editItemPermission = getNestedValue(lessonToEdit, 'permissions', 'update');

      if (editItemPermission) {
        const startDate = getCorrectDate(lessonToEdit.start_date).toDate();
        const endDate = getCorrectDate(lessonToEdit.end_date).toDate();
        this.setState({
          ...INITIAL_STATE,
          title: {
            isActive: true,
            isValid: true,
            value: lessonToEdit.title,
          },
          description: {
            isActive: true,
            isValid: true,
            value: lessonToEdit.description,
          },
          selectedStyles: {
            isActive: !!lessonToEdit.dance_styles,
            isValid: !!lessonToEdit.dance_styles,
            value: lessonToEdit.dance_styles ? makeSelectOptions(lessonToEdit.dance_styles) : [],
          },
          date: {
            isActive: true,
            isValid: true,
            value: [startDate, endDate],
          },
          time: {
            timeFrom: getTimeObject(startDate),
            timeTo: getTimeObject(endDate),
            isActive: true,
            isValid: true,
            isError: false,
          },
          address: {
            isActive: true,
            isValid: true,
            value: lessonToEdit.address,
            isAddressShown: !lessonToEdit.venue_id,
          },
          location: {
            isActive: true,
            isValid: true,
            value: lessonToEdit.location,
            isVenueListShown: false,
            venueId: lessonToEdit.venue_id,
            venueList: [],
          },
          coordinates: { ...lessonToEdit.coordinates },
          tickets: lessonToEdit.tickets || [],
          photos: lessonToEdit.photos,
          type: {
            isActive: !!lessonToEdit.item_type,
            isValid: !!lessonToEdit.item_type,
            value: lessonToEdit.item_type
              ? {
                value: capitalizeStr(lessonToEdit.item_type),
                label: capitalizeStr(lessonToEdit.item_type),
              }
              : '',
          },
          recurrent: {
            isActive: true,
            isValid: true,
            value: (lessonToEdit.recurrent && Object.keys(lessonToEdit.recurrent).length > 0)
              ? {
                value: lessonToEdit.recurrent.freq,
                label: capitalizeStr(lessonToEdit.recurrent.freq),
              }
              : {
                value: ONCE,
                label: capitalizeStr(ONCE),
              },
            weeklyOption: (lessonToEdit.recurrent && lessonToEdit.recurrent.byday
              && (lessonToEdit.recurrent.freq === WEEKLY
              || lessonToEdit.recurrent.freq === BI_WEEKLY))
              ? lessonToEdit.recurrent.byday.map(day => capitalizeStr(day))
              : [],
            monthlyOption: (lessonToEdit.recurrent && lessonToEdit.recurrent.freq === MONTHLY)
              ? {
                value: {
                  bymonthday: lessonToEdit.recurrent.bymonthday,
                  week: lessonToEdit.recurrent.week,
                  byday: lessonToEdit.recurrent.byday,
                },
                label: getRecurrentString(lessonToEdit.recurrent),
              }
              : '',
          },
          teacherId: lessonToEdit.teacher_id,
          teacher: lessonToEdit.teacher,
        });
      } else { history.push('/'); }
    }
  }

  componentWillUnmount() {
    const { showNewMarker } = this.props;
    showNewMarker(null);

    document.removeEventListener('click', this.handleClickOutsideList);
  }

  componentSetState= prevState => this.setState(prevState);

  setErrors = (errors) => {
    const { handleError } = this.props;
    let errorString = '';
    Object.keys(errors).forEach((item) => {
      const errorText = errors[item].join(', ');
      errorString = errorString.length > 0
        ? `${errorString}; ${item}: ${errorText}`
        : `${item}: ${errorText}`;
      if (item === 'start_date' || item === 'end_date') {
        this.setState(prevState => ({
          ...prevState,
          date: {
            ...prevState.date,
            isValid: false,
            error: errorText,
          },
        }));
      } else if (item === 'dance_styles') {
        this.setState(prevState => ({
          ...prevState,
          selectedStyles: {
            ...prevState.selectedStyles,
            isValid: false,
            error: errorText,
          },
        }));
      } else if (item === 'coordinates') {
        this.setState(prevState => ({
          ...prevState,
          coordinates: null,
          address: {
            ...prevState.address,
            isValid: false,
            error: 'Select address from the list or map',
          },
        }));
      } else if (item.indexOf('photo') > 0) {
        this.setState({ photosError: true });
      } else if (this.state[item]) {
        this.setState(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isValid: false,
            error: errorText,
          },
        }));
      }
    });
    if (errorString.length > 0) handleError(errorString);
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = (name === 'title') ? validateTitle(value) : validateText(value);
    this.setState({
      [name]: {
        isActive: true,
        isValid,
        value,
        error: null,
      },
    });
  };

  handleSelectStyles = (value) => {
    this.setState(prevState => ({ selectedStyles: {
      ...prevState.selectedStyles,
      value,
      isValid: true,
      isActive: true,
      error: null,
    } }));
  }

  handlePhotoSelect = photos => this.setState({ photos });

  activateAllFields = () => {
    this.activateField('title');
    this.activateField('description');
    this.activateField('location');
    this.activateField('address');
  }

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  validateDate = () => {
    const { date } = this.state;
    if (!date.value) {
      this.setState(prevState => ({
        date: {
          ...prevState.date,
          isValid: false,
          isActive: false,
        },
      }));
    }
  };

  validateTime = () => {
    const { time } = this.state;
    if (!time.timeFrom || !time.timeTo) {
      this.setState({ time: { isValid: false } });
    }
  };

  validateCoords = () => {
    const { coordinates } = this.state;
    if (!coordinates) {
      this.setState(prevState => ({
        address: {
          ...prevState.address,
          isValid: false,
          error: 'Select address from the list or map',
        },
      }));
      return false;
    }

    return true;
  }

  validateStyles = () => {
    const { selectedStyles } = this.state;
    const isValid = selectedStyles && selectedStyles.value && selectedStyles.value.length > 0;
    if (isValid) {
      this.setState(prevState => ({
        selectedStyles: {
          ...prevState.selectedStyles,
          isValid: true,
        },
      }));
    } else {
      this.setState(prevState => ({
        selectedStyles: {
          ...prevState.selectedStyles,
          isValid: false,
          isActive: false,
        },
      }));
    }
    return isValid;
  }

  validateRecurrent = () => {
    const { recurrent } = this.state;
    let options;
    let isValid = false;
    const freq = recurrent && recurrent.value && recurrent.value.value;
    if (freq === MONTHLY) {
      options = recurrent.monthlyOption.value;
      isValid = (options && options.byday && options.byday.length > 0 && options.week)
      || (options && options.bymonthday && options.bymonthday.length > 0);
    }
    if (freq === DAILY) {
      isValid = true;
    }
    if (freq === WEEKLY || freq === BI_WEEKLY) {
      options = recurrent.weeklyOption;
      isValid = options && options.length > 0;
    }
    if (freq === ONCE) isValid = true;
    if (isValid) {
      this.setState(prevState => ({
        recurrent: {
          ...prevState.recurrent,
          isValid: true,
        },
      }));
    } else {
      this.setState(prevState => ({
        recurrent: {
          ...prevState.recurrent,
          isValid: false,
          isActive: false,
        },
      }));
    }
    return isValid;
  }

  isValidationPassed = () => {
    const {
      title,
      date,
      description,
      location,
      address,
      time,
    } = this.state;
    const validDate = !!(date.isValid && date.value
      && time.isValid && time.timeFrom && time.timeTo);
    const validTextInputs = title.isValid && description.isValid
      && location.isValid && address.isValid;
    const validStyles = this.validateStyles();
    const validRecurrent = this.validateRecurrent();
    const validCoords = this.validateCoords();
    return (validTextInputs && validDate && validStyles && validCoords && validRecurrent);
  };

  validateForm = () => {
    this.activateAllFields();
    this.validateDate();
    this.validateTime();
    if (!this.isValidationPassed()) return false;
    return true;
  }

  composeLesson = () => {
    const {
      title, date, time, description,
      location, address, selectedStyles, photos,
      tickets, coordinates, recurrent, teacher,
    } = this.state;
    const { timeFrom, timeTo } = time;

    const freq = recurrent && recurrent.value && recurrent.value.value;
    const oneDateAvailable = freq === WEEKLY || freq === BI_WEEKLY;

    if (oneDateAvailable) {
      const byDay = recurrent.weeklyOption.map(day => day.toLowerCase());
      date.value[0] = getNearestRecurrentDate(date.value[0], byDay);
      date.value[1] = getNearestRecurrentDate(date.value[1], byDay);
    }

    const { dateTo, dateFrom } = combineDateAndTime({
      timeFrom, timeTo, date, oneDateAvailable,
    });
    if (dateTo < dateFrom) {
      this.setState(prevState => ({
        ...prevState,
        time: {
          ...prevState.time,
          isActive: true,
          isValid: false,
          isError: true,
        },
      }));
      return null;
    }

    const danceStyles = selectedStyles.value.map(style => style.value);

    const lesson = {
      title: title.value,
      start_date: moment(dateFrom).format(),
      end_date: moment(dateTo).format(),
      description: description.value,
      location: location.value,
      address: address.value,
      dance_styles: danceStyles,
      photos,
      tickets,
      coordinates,
      recurrent: { freq },
      teacher,
    };
    if (oneDateAvailable) {
      lesson.recurrent.byday = recurrent.weeklyOption.map(day => day.toLowerCase());
    }
    if (freq === MONTHLY) {
      lesson.recurrent = { ...lesson.recurrent, ...recurrent.monthlyOption.value };
    }
    this.setState({ lesson });
    return { ...lesson };
  }

  checkVenueId = (lesson) => {
    const lessonForRequest = lesson;
    if (this.state.location.venueId) {
      delete lessonForRequest.location;
      delete lessonForRequest.coordinates;
      delete lessonForRequest.address;
      lessonForRequest.venue_id = this.state.location.venueId;
    } else lessonForRequest.venue_id = '';

    return lessonForRequest;
  }

  handleCreateLesson = (e) => {
    e.preventDefault();
    const { handleError, user, openModal } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }

    if (!checkUserCreatAbilyti(user)) {
      openModal('PROFILE_MODAL');
      return;
    }

    if (this.state.isLoading) return;
    let lessonForRequest = this.composeLesson();
    if (!lessonForRequest) return;
    delete lessonForRequest.photos;
    delete lessonForRequest.teacher;

    lessonForRequest.tickets = lessonForRequest.tickets.map(
      (element, index) => ({ id: element.id, index }),
    );

    lessonForRequest = this.checkVenueId(lessonForRequest);

    const {
      createLesson,
      onLessonSubmitSuccess,
      history,
      match,
    } = this.props;
    const { photos, tickets } = this.state;
    const isItemFree = checkIfItemIsFree(tickets);
    const teacherId = match.params.id;
    lessonForRequest.teacher = { id: teacherId };

    this.setState({ isLoading: true });

    createLesson(lessonForRequest)
      .then((data) => {
        if (data && data.id) {
          if (photos.length === 0) {
            onLessonSubmitSuccess({
              id: data.id,
              uploadPhotos: true,
              action: 'create',
              isItemFree,
            });
            history.push(`/classes/${data.id}`);
          } else {
            const lessonFormData = new FormData();
            photos.forEach((photo, index) => {
              lessonFormData.append(`data[photos][${index}][file]`, photo.file, photo.file.name);
              lessonFormData.append(`data[photos][${index}][sort]`, index);
            });
            this.handleUploadPhotos(data.id, lessonFormData, 'create');
          }
        }
        if (data && data.errors) {
          this.setErrors(data.errors);
          this.setState({
            previewView: false,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleEditLesson = (e) => {
    e.preventDefault();
    const { handleError } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }
    if (this.state.isLoading) return;

    let lesson = this.composeLesson();
    if (!lesson) return;
    delete lesson.price;
    delete lesson.refunds;
    delete lesson.photos;
    delete lesson.teacher;
    lesson.tickets = lesson.tickets.map(
      (element, index) => ({ id: element.id, index }),
    );
    lesson.tickets = [...lesson.tickets, ...this.state.ticketsToRemove];

    lesson = this.checkVenueId(lesson);

    const {
      editLesson,
      history,
      onLessonSubmitSuccess,
      match,
    } = this.props;
    lesson.id = match && match.params && match.params.lesson_id;

    const { photos, photosToRemove, tickets } = this.state;
    const isItemFree = checkIfItemIsFree(tickets);
    this.setState({ isLoading: true });

    editLesson(lesson)
      .then((data) => {
        if (data && data.id) {
          const lessonFormData = new FormData();
          let photosWereModified;
          photos.forEach((photo, index) => {
            if (photo.file) {
              lessonFormData.append(`data[photos][${index}][file]`, photo.file, photo.file.name);
              lessonFormData.append(`data[photos][${index}][sort]`, index);
              photosWereModified = true;
            }
            if (photo.id && index !== photo.sort) {
              lessonFormData.append(`data[photos][${index}][id]`, photo.id);
              lessonFormData.append(`data[photos][${index}][sort]`, index);
              photosWereModified = true;
            }
          });
          photosToRemove.forEach((photoId, index) => {
            lessonFormData.append(`data[photos][${photos.length + index}][id]`, photoId);
            lessonFormData.append(`data[photos][${photos.length + index}][to_remove]`, true);
            photosWereModified = true;
          });
          if (photosWereModified) {
            this.handleUploadPhotos(data.id, lessonFormData, 'edit');
          } else {
            history.push(`/classes/${data.id}`);
            onLessonSubmitSuccess(
              {
                uploadPhotos: true,
                action: 'edit',
                isItemFree,
              },
            );
          }
          return;
        }
        if (data && data.errors) {
          this.setErrors(data.errors);
          this.setState({
            previewView: false,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleUploadPhotos = (id, lessonFormData, action) => {
    const { addPhotos, history, onLessonSubmitSuccess } = this.props;
    const { tickets } = this.state;
    const isItemFree = checkIfItemIsFree(tickets);
    addPhotos(id, lessonFormData)
      .then((photoData) => {
        if (photoData && photoData.id) {
          history.push(`/classes/${id}`);
          onLessonSubmitSuccess(
            {
              uploadPhotos: true,
              action,
              isItemFree,
            },
          );
        } else if (photoData && (photoData.errors || photoData.error)) {
          this.setErrors(photoData.errors || photoData.error);
          this.setState({
            previewView: false,
            isLoading: false,
          });
          onLessonSubmitSuccess({ action, isItemFree });
        } else {
          history.push(`/classes/${id}`);
          onLessonSubmitSuccess({ action, isItemFree });
        }
      });
  }

  handleCloseForm = () => {
    if (this.state.isLoading) return;
    this.setState({ ...INITIAL_STATE });
    const { history, location } = this.props;
    const from = location.state && location.state.from;
    if ((from && from.indexOf('/teachers/') === -1
      && from.indexOf('/classes') === -1 && from.indexOf('/account') === -1) || !from) {
      history.push('/');
      return;
    }
    const list = location.state && location.state.list;
    history.push({
      pathname: from,
      state: { list },
    });
  }

  handleOpenPreview = () => {
    const { handleError } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }
    if (this.state.isLoading) return;
    const lesson = this.composeLesson();
    if (!lesson) return;
    this.setState({ previewView: true });
  }

  handleClosePreview = () => {
    if (this.state.isLoading) return;
    this.setState({ previewView: false });
  }

  handleLinkPass = (passToAdd) => {
    // if (!passToAdd.destroyable) {
    //   // open modal with attention message here
    // }

    this.setState(prevState => ({
      tickets: [
        ...prevState.tickets,
        {
          ...passToAdd,
          justLinkedItem: true,
        }],
    }));
  }

  handleUnlinkTicket = (ticketToDelete) => {
    this.setState(prevState => ({
      ...prevState,
      ticketsToRemove: ticketToDelete.justLinkedItem
        ? [...prevState.ticketsToRemove]
        : [...prevState.ticketsToRemove, {
          id: ticketToDelete.id,
          to_remove: true,
        }],
      tickets: prevState.tickets.filter(ticket => ticket.id !== ticketToDelete.id),
    }));
  }

  handleOpenTicket = (indexToOpen) => {
    this.setState(prevState => ({
      tickets: prevState.tickets.map((ticket, index) => {
        if (index === indexToOpen) { return { ...ticket, isOpened: true }; }
        return ticket;
      }),
    }));
  }

  handleRemovePhoto = (idToDelete) => {
    this.setState(prevState => ({
      ...prevState,
      photosToRemove: idToDelete
        ? [...prevState.photosToRemove, idToDelete]
        : [...prevState.photosToRemove],
    }));
  }

  handleDragEnd = (result) => {
    if (!result) return;
    const { destination, source } = result;
    const sourceIndex = getNestedValue(result, 'source', 'index');
    const destinationIndex = getNestedValue(result, 'destination', 'index');

    if (!destination || !source || sourceIndex === destinationIndex) return;

    this.setState((prevState) => {
      const tickets = [...prevState.tickets];
      const sourceTicket = tickets.splice(sourceIndex, 1)[0];
      tickets.splice(destinationIndex, 0, sourceTicket);
      return { tickets };
    });
  }

  preventSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  renderInput = (inputName) => {
    const valueFromState = this.state[inputName];
    let fieldStyle;
    if (!valueFromState.isActive) fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    else fieldStyle = valueFromState.isValid ? inputStyles.input_box_create : `${inputStyles.input_box_create} ${inputStyles.is_error}`;
    return (
      <div className={fieldStyle}>
        <input
          className={inputStyles.input}
          type="text"
          name={inputName}
          value={valueFromState.value}
          onChange={inputName === 'location' ? this.handleVenueChange : this.handleInputChange}
          autoComplete={inputName === 'location' ? 'off' : 'on'}
          onBlur={inputName === 'location' ? this.handleVenueBlur : null}
        />
        <label
          htmlFor={inputName}
          className={valueFromState.value ? inputStyles.label__with_value : inputStyles.label}
        >
          {valueFromState.error ? valueFromState.error : PLACEHOLDER[inputName]}
        </label>
      </div>
    );
  };

  render() {
    const {
      danceStyles,
      match,
      history,
      getTeacherPasses,
      handleError,
    } = this.props;
    const danceStylesError = danceStyles.length === 0;

    const {
      description,
      recurrent,
      date,
      selectedStyles: {
        value: valueStyles,
        isValid: isValidStyles,
        isActive: isActiveStyles,
        error: errorStyles,
      },
      photos,
      time,
      tickets,
      previewView,
      lesson,
      photosError,
      teacher,
    } = this.state;

    const options = makeSelectOptions(danceStyles);

    const getSelectStyles = (isValid, isActive) => {
      let selectStyles = isValid ? customSelectStyles : selectErrorStyles;
      if (isActive) selectStyles = selectActiveStyles;
      return selectStyles;
    };

    const selectDanceStyles = getSelectStyles(isValidStyles, isActiveStyles);

    const isEditing = match.path.indexOf('edit_class') !== -1;

    return (
      <div className={`${gridStyles.mobile_on_top} ${gridStyles.wrapper_fixed_bottom}`}>
        {!danceStylesError && (
          <form onSubmit={this.preventSubmit}>
            {previewView
              && <LessonDetails lessonForPreview={lesson} previewFromForm history={history} />
            }
            {!previewView
              && (
                <div className={gridStyles.scroll_container}>
                  <h3 className={styles.title}>{isEditing ? 'Edit Class' : 'Create Class'}</h3>
                  <PhotoManager
                    onPhotoUpdate={this.handlePhotoSelect}
                    onRemovePhoto={this.handleRemovePhoto}
                    photos={photos}
                  />
                  {photosError && (
                  <div className={styles.error}>
                      Lesson was updated, but something went wrong with photos ...
                  </div>
                  )}
                  {this.renderInput('title')}
                  <Select
                    value={valueStyles}
                    onChange={this.handleSelectStyles}
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    className={inputStyles.safari_input}
                    styles={selectDanceStyles}
                    placeholder="Dance styles"
                  />
                  {errorStyles
                  && <div className={styles.error}>{errorStyles}</div>
                  }
                  <DateBlock
                    componentSetState={this.componentSetState}
                    recurrent={recurrent}
                    date={date}
                    time={time}
                  />
                  <TextArea
                    handleInputChange={this.handleInputChange}
                    stateObject={description}
                  />
                  <VenueSelector
                    componentSetState={this.componentSetState}
                    location={this.state.location}
                    address={this.state.address}
                  />
                  <h4 className={styles.subtitle}>Passes</h4>
                  <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable droppableId="passes_list">
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={styles.droppable}
                        >
                          {tickets.map((ticket, index) => (
                            <SimpleItemPreview
                              item={ticket}
                              forPasses
                              key={ticket.id || `${ticket.name}_${index.toString()}`}
                              onUnlinkItem={this.handleUnlinkTicket}
                              match={match}
                              handleError={handleError}
                              draggable
                              index={index}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <LinkSelector
                    onLinkItem={this.handleLinkPass}
                    getTeacherPasses={getTeacherPasses}
                    teacher={teacher}
                    items={tickets}
                  />
                </div>
              )
            }
            <div className={buttonStyles.fixed_buttons}>
              {isEditing
                ? (
                  <button
                    type="button"
                    className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                    onClick={this.handleEditLesson}
                  >
                    save
                  </button>
                )
                : (
                  <button
                    type="button"
                    className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                    onClick={this.handleCreateLesson}
                    data-test="create_button"
                  >
                    create
                  </button>
                )
              }
              <div className={buttonStyles.buttons_row}>
                <button
                  type="button"
                  className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                  onClick={previewView
                    ? this.handleClosePreview
                    : this.handleOpenPreview}
                >
                  {previewView ? 'edit' : 'preview'}
                </button>
                <button
                  type="button"
                  className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                  onClick={this.handleCloseForm}
                >
                  cancel
                </button>
              </div>
            </div>
          </form>
        )
        }
      </div>
    );
  }
}

export default LessonForm;
