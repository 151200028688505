import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import ProfileForm from './Profile_form';
import AvatarEditor from '../Avatar_editor/avatar_editor_container';
import CancelIcon from '../icons/Cancel_icon';
import Pencil from '../icons/Pencil';
import ProgressBar from '../shared/Progress_bar/Progres_bar';
import { dashDateOrder } from '../../utils/formatDate';

import styles from './profile.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import sideBarStyles from '../../styles/main_grid.module.scss';
import { USER_FIELDS } from '../../config';
import getNestedValue from '../../utils/getNestedValue';

const LABELS = {
  first_name: 'First name',
  last_name: 'Last name',
  birthday: 'Date of birth',
  about: 'About',
  address: 'Location',
  nickname: 'Username',
  profession: 'Profession',
  gender: 'Gender',
  dancer_role: 'Dance role',
  dance_styles: 'Preferred Style(s) of Dance',
  email: 'E-mail',
  height: 'Height (feet)',
  skill: 'Dance skill',
  goal: 'Goal',
  languages: 'Language(s)',
};

const Profile = (props) => {
  const { user: userInProps, pinMode, history, location } = props;

  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    const { getUser, token } = props;
    const id = getNestedValue(userInProps, 'id');
    getUser({ id, token });
  }, []);

  const closeProfile = () => {
    const from = (location.state && location.state.from) ? location.state.from : '/';
    history.push(from);
  };

  const getProfileCompletedCount = () => {
    const { user } = props;
    const fullfilledCount = Object.keys(user).filter(
      key => !!user[key] && USER_FIELDS.includes(key),
    ).length;
    const completedPercent = Math.round(fullfilledCount / USER_FIELDS.length * 100);
    return completedPercent;
  };

  const handleEditStart = () => { setEditing(true); };
  const handleEditStop = () => { setEditing(false); };

  const wrapperClass = classNames(
    sideBarStyles.side_bar_wide,
    styles.profile_wrapper,
    {
      [sideBarStyles.mobile_on_top]: !pinMode,
    },
  );

  const completed = getProfileCompletedCount();
  const toPublicProfile = {
    pathname: `/users/${userInProps.id}`,
    state: { from: history.location.pathname },
  };

  if (!userInProps) return null;

  return (
    <div className={wrapperClass}>
      <button
        type="button"
        className={buttonStyles.cancel_button}
        onClick={closeProfile}
      >
        <CancelIcon />
      </button>

      <div className={styles.title_row}>
        <h2 className={styles.title}>Profile</h2>
      </div>

      <div className={styles.avatar_row}>
        <Link to={toPublicProfile} className={styles.view_link}>
          View
        </Link>
        <AvatarEditor />
        {isEditing ? null : (
          <button
            className={styles.edit_button}
            type="button"
            onClick={handleEditStart}
          >
            <Pencil className={styles.pencil_icon} />
          </button>
        )}
      </div>

      <div className={styles.progress_bar_row}>
        <ProgressBar completed={completed} />
      </div>
      {isEditing ? (
        <ProfileForm {...props} handleEditStop={handleEditStop} />
      ) : (
        USER_FIELDS.map((field) => {
          if (field === 'avatar') return null;
          if (field === 'birthday') {
            return (
              <Fragment key={field}>
                <p className={styles.label}>{LABELS.birthday}</p>
                <p className={
                  classNames(styles.text, { [styles.empty_value]: !userInProps.birthday })}
                >
                  {userInProps.birthday
                    ? dashDateOrder(userInProps.birthday) : ''}
                </p>
              </Fragment>
            );
          }
          if (field === 'dance_styles' || field === 'languages') {
            return (
              <Fragment key={field}>
                <p className={styles.label}>{LABELS[field]}</p>
                <p className={
                  classNames(
                    styles.text,
                    { [styles.empty_value]: !(userInProps && userInProps[field]) },
                  )}
                >
                  {(userInProps[field] && Array.isArray(userInProps[field]))
                    ? userInProps[field].join(', ')
                    : ''}
                </p>
              </Fragment>
            );
          }
          return (
            <Fragment key={field}>
              <p className={styles.label}>{LABELS[field]}</p>
              <p className={
                classNames(styles.text, { [styles.empty_value]: !userInProps[field] })}
              >
                {userInProps[field] || ''}
              </p>
            </Fragment>
          );
        })
      )}
    </div>
  );
};

Profile.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    address: PropTypes.string,
    profession: PropTypes.string,
    nickname: PropTypes.string,
    prefered_dance_styles: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.string,
    avatar: PropTypes.shape({
      full: PropTypes.string,
      thumb: PropTypes.string,
    }),
    birthday: PropTypes.string,
    about: PropTypes.string,
    dance_styles: PropTypes.array,
    gender: PropTypes.string,
    dancer_role: PropTypes.string,
  }).isRequired,
  editUser: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.string,
    }),
  }).isRequired,
  danceStyles: PropTypes.arrayOf(PropTypes.string).isRequired,
  getDanceStyles: PropTypes.func.isRequired,
  addMarker: PropTypes.func.isRequired,
  pinMode: PropTypes.bool.isRequired,
};


export default Profile;
