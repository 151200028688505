import axios from 'axios';

export const requestVerifyTicket = ({ ticketId, token, itemId }) => (
  axios.patch(
    `/api/events/${itemId}/verify_ticket`,
    { data: { password: ticketId } },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestEventManualCheckin = ({ ticketId, token, itemId, hostCode }) => (
  axios.patch(
    `/api/events/${itemId}/verify_ticket_by_code`,
    {
      data: {
        id: ticketId,
        host_code: hostCode,
      },
    },
    { headers: { 'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json' } },
  )
);

export const requestLessonManualCheckin = ({ ticketId, token, itemId, hostCode }) => (
  axios.patch(
    `/api/lessons/${itemId}/verify_ticket_by_code`,
    {
      data: {
        id: ticketId,
        host_code: hostCode,
      },
    },
    { headers: { 'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json' } },
  )
);
