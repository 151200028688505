import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15px"
    height="15px"
    className={className}
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      d="M14.759,13.608 L11.440,10.288 C12.310,9.165 12.770,7.820 12.770,6.385 C12.770,4.679 12.106,3.075 10.900,1.869 C9.693,0.663 8.090,-0.001 6.384,-0.001 C4.678,-0.001 3.075,0.663 1.869,1.869 C0.662,3.075 -0.002,4.679 -0.002,6.385 C-0.002,8.090 0.662,9.694 1.869,10.900 C3.075,12.106 4.678,12.770 6.384,12.770 C7.820,12.770 9.165,12.311 10.287,11.440 L13.607,14.760 C13.761,14.914 13.966,14.999 14.183,14.999 C14.401,14.999 14.606,14.914 14.759,14.760 C15.077,14.443 15.077,13.925 14.759,13.608 ZM11.140,6.385 C11.140,7.644 10.651,8.832 9.762,9.729 C9.756,9.734 9.751,9.739 9.744,9.746 C9.739,9.752 9.734,9.757 9.729,9.762 C8.831,10.651 7.644,11.141 6.384,11.141 C3.762,11.141 1.628,9.007 1.628,6.385 C1.628,3.762 3.762,1.628 6.384,1.628 C9.006,1.628 11.140,3.762 11.140,6.385 Z"
    />
  </svg>
);

SearchIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

SearchIcon.defaultProps = {
  className: '',
  onClick: () => {},
};

export default SearchIcon;
