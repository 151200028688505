import { requestTeacherDetails } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import { moveMapCenterTo, addMarker } from '../Map/map_actions';
import {
  GET_TEACHER_DETAILS_SUCCESS,
  GET_TEACHER_DETAILS_FAILURE,
} from '../../redux/action_types';
import { getItemsList } from '../List/list_actions';

export const getTeacherDetailsSuccess = teacher => ({
  type: GET_TEACHER_DETAILS_SUCCESS,
  payload: teacher,
});

export const getTeacherDetailsFailure = error => ({
  type: GET_TEACHER_DETAILS_FAILURE,
  payload: { error },
});

export const getTeacherDetails = ({ id, moveMap, filter }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestTeacherDetails(id, token)
    .then((response) => {
      const teacher = response.data.data;
      dispatch(getTeacherDetailsSuccess(teacher));
      const { coordinates } = teacher;
      dispatch(getItemsList({
        list: 'teachers',
        token,
        filter,
      }))
        .then(() => {
          if (coordinates && moveMap) {
            dispatch(moveMapCenterTo(coordinates));
            const { markers } = getState().map;
            const itemHasMarker = markers.some(item => item.id === id);
            if (!itemHasMarker) dispatch(addMarker(coordinates));
          }
        });
      return response;
    })
    .catch((error) => {
      dispatch(createError(error));
      dispatch(getTeacherDetailsFailure(error));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};
