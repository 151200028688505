import { connect } from 'react-redux';

import AddressInput from './Address_input';
import {
  showNewMarker, setOnPinMode,
  resetMapCenterAddress, setOffPinMode,
} from '../Map/map_actions';
import createError from '../Fetch_error/fetch_error_action';

const mSTP = state => ({
  mapCenterAddress: state.map.mapCenterAddress,
  pinMode: state.map.pinMode,
});

const mDTP = {
  showNewMarker,
  setOnPinMode,
  setOffPinMode,
  resetMapCenterAddress,
  createError,
};

export default connect(mSTP, mDTP)(AddressInput);
