import {
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAILURE,
  SET_COMMENT_TO_DELETE,
} from '../../redux/action_types';

const initialState = {
  error: null,
  commentToDelete: null,
};

const comments = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COMMENT_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case CREATE_COMMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    case SET_COMMENT_TO_DELETE:
      return {
        ...state,
        commentToDelete: action.payload,
      };

    default:
      return state;
  }
};

export default comments;
