import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import './crop.scss';
import buttonStyles from '../../../styles/buttons.module.scss';
import styles from './photo_crop.module.scss';

const INITIAL_STATE = {
  crop: {
    aspect: 16 / 9,
    width: 50,
    x: 10,
    y: 10,
  },
  croppedImage: null,
};

class PhotoCrop extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    onCancelCrop: PropTypes.func.isRequired,
    onApplyCrop: PropTypes.func.isRequired,
    index: PropTypes.string,
    aspect: PropTypes.number,
    onPreviewCrop: PropTypes.func.isRequired,
  };

  static defaultProps = {
    index: null,
    aspect: null,
  };

  state= { ...INITIAL_STATE };

  componentDidMount() {
    const { aspect } = this.props;
    if (aspect) {
      this.setState(prevState => ({
        crop: {
          ...prevState.crop,
          aspect,
        },
      }));
    }
  }


  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = crop => this.setState({ crop });

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) return;
        const newBlob = blob;
        newBlob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(newBlob);
        resolve({
          url: this.fileUrl,
          file: newBlob,
        }, 'image/jpeg');
      });
    });
  }

  handleApplyCrop = () => {
    const { index, onApplyCrop } = this.props;
    const { croppedImage } = this.state;
    onApplyCrop({ index, croppedImage });
    this.setState({ ...INITIAL_STATE });
  }

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const { src } = this.imageRef;
      const extension = src.indexOf(';base64') === -1
        ? src.substr(src.lastIndexOf('.') + 1)
        : src.substring('data:image/'.length, src.indexOf(';base64'));
      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        `newFile_${new Date().getTime()}.${extension}`,
      );
      this.setState({ croppedImage });
      const { onPreviewCrop } = this.props;
      onPreviewCrop(croppedImage.url);
    }
  }

  render() {
    const { crop } = this.state;
    const { src, onCancelCrop } = this.props;

    return ReactDOM.createPortal((
      <div className={styles.container}>
        {src && (
          <div className={styles.wrapper}>
            <div className={styles.photo}>
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                crossorigin="anonymous"
              />
            </div>
            <div className={`${buttonStyles.buttons_row} ${styles.buttons}`}>
              <button
                type="button"
                className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
                onClick={this.handleApplyCrop}
              >
                Crop
              </button>
              <button
                type="button"
                className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                onClick={onCancelCrop}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    ), document.getElementById('root') || document.createElement('div'));
    // document.createElement('div') - for tests work
  }
}

export default PhotoCrop;
