import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

export const requestEventAnalytics = ({ id, token, query }) => (
  axios.get(
    `/api/events/${id}/analytics`,
    {
      headers: {
        'Content-Type': 'application/json',
        'JWT-Authorization': `Bearer ${token}`,
        Accept: 'application/json',
      },
      params: query,
    },
  )
);

export const requestLessonAnalytics = ({ id, token, query }) => (
  axios.get(
    `/api/lesson/${id}/analytics`,
    {
      headers: {
        'Content-Type': 'application/json',
        'JWT-Authorization': `Bearer ${token}`,
        Accept: 'application/json',
      },
      params: query,
    },
  )
);
