export const stringOfCoordsToObject = string => ({
  lng: parseFloat(string.substring(1, string.indexOf(','))),
  lat: parseFloat(string.substring(string.indexOf(',') + 1, string.length - 1)),
});

export const strToCamelCase = str => (
  str.replace(/([_-]\w)/g, g => g[1].toUpperCase())
);

export const capitalizeStr = (str) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const parseErrors = errors => (
  Object.keys(errors).map((item) => { // eslint-disable-line
    if (Array.isArray(errors[item]) && typeof errors[item][0] !== 'object') {
      return `${item}: ${errors[item].join(', ')}`;
    } else if (typeof errors[item] === 'object') { // eslint-disable-line
      return `${item}: ${parseErrors(errors[item])}`;
    }
  })
);

export const errorsToString = (errors) => {
  const arr = parseErrors(errors);
  return arr.join(', ');
};

export const setItemUrl = (item) => {
  switch (item.type) {
    case 'event':
    case 'venue':
    case 'teacher':
      return `/${item.type}s/${item.id}`;

    case 'lesson':
      return `/classes/${item.id}`;

    default:
      return '/';
  }
};

export const getDollarsFromCents = (price) => {
  if (Number.isNaN(price / 100)) return 'invalid price';

  let dollarString = `$${String(price / 100).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;

  if (price % 100 === 0) {
    dollarString += '.00';
  } else if (price % 10 === 0) {
    dollarString += '0';
  } else if (price === 0) {
    dollarString = '$0.00';
  }

  return dollarString;
};

export const getPriceFromCents = (price) => {
  if (Number.isNaN(price / 100)) return 'invalid price';

  let dollarString = `${price / 100}`;
  if (price % 100 === 0) {
    dollarString = `${price / 100}.00`;
  } else if (price % 10 === 0) {
    dollarString = `${price / 100}0`;
  } else if (price === 0) {
    dollarString = '0.00';
  }

  return dollarString;
};
