import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { validateFeedbackTitle, validateFeedback, validateEmail } from '../../utils/validation';
import inputStyles from '../../styles/inputs.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import CancelIcon from '../icons/Cancel_icon';

import styles from './feedback.module.scss';
import navStyles from '../../styles/navigation.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';

const CAREERS = 'careers';
const FEEDBACK = 'feedback';
const CONTACT = 'contact';

const getActiveTab = (props) => {
  let activeTab;
  if (props && props.location && props.location.pathname) {
    activeTab = props.location.pathname.substring(1);
  }
  if (activeTab === CAREERS || activeTab === FEEDBACK || activeTab === CONTACT) return activeTab;
  return null;
};

const PLACEHOLDER = {
  title: 'Subject',
  email: 'Return Email',
  text: {
    [CAREERS]: 'Type an application here...',
    [FEEDBACK]: 'Type your feedback here...',
    [CONTACT]: 'Type your query here...',
  },
  submit: {
    [CAREERS]: 'application',
    [FEEDBACK]: 'feedback',
    [CONTACT]: 'query',
  },
};

const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

const INITIAL_STATE = {
  title: { ...INITIAL_STATE_FIELD },
  email: { ...INITIAL_STATE_FIELD },
  text: { ...INITIAL_STATE_FIELD },
};

class Feedback extends Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    sendFeedback: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    email: PropTypes.string,
  };

  static defaultProps = {
    email: null,
  }

  state = { ...INITIAL_STATE };

  componentDidMount() {
    this.setEmailState();
    this.setReportState();
  }

  componentDidUpdate = (prevProps) => {
    const thisTab = getActiveTab(this.props);
    const prevTab = getActiveTab(prevProps);
    if (thisTab !== prevTab) {
      this.setState({ ...INITIAL_STATE });
      this.setEmailState();
    }
    if (this.props.email !== prevProps.email) {
      this.setEmailState();
      this.setReportState();
    }
  }

  setEmailState = () => {
    const { email } = this.props;
    if (email) {
      this.setState({
        email: {
          value: email,
          isActive: true,
          isValid: true,
          error: null,
        },
      });
    }
  }

  setReportState = () => {
    const { history } = this.props;
    if (history && history.location && history.location.state
      && history.location.state.report && history.location.state.report.title
      && history.location.state.reportLink) {
      const { title, type } = history.location.state.report;
      const { reportLink } = history.location.state;

      this.setState({
        title: {
          value: `Complain about "${title}".`,
          isActive: true,
          isValid: true,
          error: null,
        },
        text: {
          value: `I am writing to complain about ${type} "${title}"(${reportLink}).`,
          isActive: true,
          isValid: true,
          error: null,
        },
      });
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let isValid = false;
    switch (name) {
      case 'title': {
        isValid = validateFeedbackTitle(value);
        break;
      }
      case 'email': {
        isValid = validateEmail(value);
        break;
      }
      case 'text': {
        isValid = validateFeedback(value);
        break;
      }
      default: return;
    }
    this.setState({
      [name]: {
        isActive: true,
        isValid,
        value,
        error: null,
      },
    });
  };

  activateAllFields = () => {
    this.activateField('title');
    this.activateField('email');
    this.activateField('text');
  }

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  setErrors = (errors) => {
    const { handleError } = this.props;
    let errorString = '';
    Object.keys(errors).forEach((item) => {
      const errorText = errors[item].join(', ');
      errorString = errorString.length > 0
        ? `${errorString}; ${item}: ${errorText}`
        : `${item}: ${errorText}`;
      if (this.state[item]) {
        this.setState(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isValid: false,
            error: errors[item].join(', '),
          },
        }));
      }
    });
    if (errorString.length > 0) handleError(errorString);
  };

  handleClose = () => {
    const { history } = this.props;
    const path = history && history.location && history.location.state
      && history.location.state.from
      ? history.location.state.from
      : '/';
    history.push(path);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.activateAllFields();
    const { title, text, email } = this.state;
    const { history, sendFeedback } = this.props;
    const isValidForm = title.isValid && text.isValid && email.isValid;
    if (!isValidForm) return;

    const feedback = {
      title: title.value,
      email: email.value,
      text: text.value,
      feedback_type: getActiveTab(history),
    };
    sendFeedback(feedback)
      .then((data) => {
        if (data && data.errors) {
          this.setErrors(data.errors);
          return;
        }
        this.setState({ ...INITIAL_STATE });
        this.setEmailState();
      });
  }

  renderTextArea = () => {
    const { text } = this.state;
    let fieldStyle;
    if (!text.isActive) fieldStyle = inputStyles.textarea_box_resizable_not_active;
    else {
      fieldStyle = text.isValid
        ? inputStyles.textarea_box_resizable
        : inputStyles.textarea_box_resizable_is_error;
    }
    const { history } = this.props;
    const activeTab = getActiveTab(history);
    const placeholder = activeTab ? PLACEHOLDER.text[activeTab] : '';
    return (
      <div className={fieldStyle}>
        <textarea
          ref={this.textRef}
          className={inputStyles.textarea_resizable}
          type="text"
          name="text"
          value={text.value}
          onChange={this.handleInputChange}
        />
        <label
          htmlFor="text"
          className={text.value ? inputStyles.label__with_value : inputStyles.label}
        >
          {text.error ? text.error : placeholder}
        </label>
      </div>
    );
  };

  renderInput = (inputName) => {
    const valueFromState = this.state[inputName];
    let fieldStyle;
    if (!valueFromState.isActive) fieldStyle = `${inputStyles.input_box} ${inputStyles.not_active}`;
    else fieldStyle = valueFromState.isValid ? inputStyles.input_box : `${inputStyles.input_box} ${inputStyles.is_error}`;
    return (
      <div className={fieldStyle}>
        <input
          className={inputStyles.input}
          type="text"
          name={inputName}
          value={valueFromState.value}
          onChange={this.handleInputChange}
        />
        <label
          htmlFor={inputName}
          className={valueFromState.value ? inputStyles.label__with_value : inputStyles.label}
        >
          {valueFromState.error ? valueFromState.error : PLACEHOLDER[inputName]}
        </label>
      </div>
    );
  };

  render() {
    const { history } = this.props;
    const activeTab = getActiveTab(history);

    return (
      <div className={`${gridStyles.side_bar_wide} ${gridStyles.mobile_on_top}`}>
        <div className={styles.close_btn_box}>
          <button type="button" onClick={this.handleClose}>
            <CancelIcon />
          </button>
        </div>
        <nav>
          <ul className={navStyles.container}>
            <li className={navStyles.block}>
              <NavLink
                className={navStyles.item}
                activeClassName={navStyles.item_active}
                exact
                to="/careers"
              >
                  Careers
              </NavLink>
            </li>
            <li className={navStyles.block}>
              <NavLink
                className={navStyles.item}
                activeClassName={navStyles.item_active}
                exact
                to="/feedback"
              >
                  Feedback
              </NavLink>
            </li>
            <li className={navStyles.block}>
              <NavLink
                className={navStyles.item}
                activeClassName={navStyles.item_active}
                exact
                to="/contact"
              >
                  Contact
              </NavLink>
            </li>
          </ul>
        </nav>
        <form>
          {activeTab === CAREERS && (
            <>
              <p className={styles.text_bold}>We are looking for:</p>
              <ul className={styles.list}>
                <li className={styles.list_item}>App/Web developers</li>
                <li className={styles.list_item}>Ux/Ui designers</li>
                <li className={styles.list_item}>Sales/Marketing accounting</li>
              </ul>
              <p className={styles.text_bold}>
                Who would like to create cross-platform applications
              </p>
            </>
          )}
          {activeTab === FEEDBACK && (
            <>
              <p className={styles.text_bold}>We are working to:</p>
              <ul>
                <li className={styles.list_item}>Foster the Dance Community</li>
                <li className={styles.list_item}>Support Venues</li>
                <li className={styles.list_item}>Bolster Events</li>
              </ul>
              <p className={styles.text_bold}>How can we do that better?</p>
            </>
          )}
          {activeTab === CONTACT && (
            <>
              <p className={styles.text_bold}>If you want to:</p>
              <ul>
                <li className={styles.list_item}>Do business</li>
                <li className={styles.list_item}>Have questions</li>
                <li className={styles.list_item}>Miscellaneous</li>
              </ul>
              <p className={styles.text_bold}>Feel free to contact us here:</p>
            </>
          )}
          {this.renderInput('title')}
          {this.renderInput('email')}
          {activeTab === CAREERS && (
            <>
              <p className={styles.text_bold}>
                Include links to portfolio, linkedin, social media, github, etc.
              </p>
              <p className={styles.text_bold}>
                Why do you want to be a part of this project?
              </p>
            </>
          )}
          {this.renderTextArea()}
          {activeTab === FEEDBACK && (
            <>
              <p className={styles.text}>Thank you for your feedback!</p>
              <p className={styles.text}>We will get back as soon as possible.</p>
            </>
          )}
          {activeTab === CONTACT && (
            <>
              <p className={styles.text}>Thank you for your interest!</p>
              <p className={styles.text}>We will get back as soon as possible.</p>
            </>
          )}
          <button
            type="submit"
            className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
            onClick={this.handleSubmit}
          >
            {`Submit ${PLACEHOLDER.submit[activeTab]}`}
          </button>
        </form>
      </div>
    );
  }
}

export default Feedback;
