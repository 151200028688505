import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

export const getPayMethods = token => (
  axios.get(
    '/api/payment_methods',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);


export const requestCreatePayMethod = ({ cardToken, token }) => (
  axios.post(
    '/api/payment_methods/',
    { data: { card_token: cardToken } },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestDeletePayMethod = ({ id, token }) => (
  axios.delete(
    `/api/payment_methods/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestSetDefaultMethod = ({ id, token }) => (
  axios.patch(
    `/api/payment_methods/${id}`,
    { data: { default_method: true } },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestOrderTickets = (order, token) => (
  axios.post(
    '/api/orders',
    { data: { tickets: order } },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestСheckoutOrder = (data, id, token) => (
  axios.post(
    `/api/orders/${id}/checkout`,
    { data },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestSubscription = (id, token) => (
  axios.get(
    `/api/subscriptions/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestСheckoutSubscription = (data, id, token) => (
  axios.post(
    `/api/subscriptions/${id}/checkout`,
    { data },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestUpdateSubscription = (id, token, data) => (
  axios.patch(
    `/api/subscriptions/${id}`,
    { data },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const connectStripe = (code, token) => (
  axios.get(
    `/api/users/connect?code=${code}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);
