import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as loadImage from 'blueimp-load-image';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import PhotoCrop from '../Photo_crop/Photo_crop';
import Camera from '../../icons/Camera';
import CancelIcon from '../../icons/Cancel_icon';
import CropIcon from '../../icons/Crop_icon';
import styles from './photo_manager.module.scss';
import { MAX_FILE_SIZE } from '../../../config';
import getNestedValue from '../../../utils/getNestedValue';

const ACCEPTED_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];
const PHOTOS_LIMIT = 5;

const INITIAL_STATE = {
  isCropOpened: false,
  srcToCrop: null,
  indexToCrop: null,
  previewImageUrl: null,
};

class PhotoManager extends Component {
  static propTypes = {
    onPhotoUpdate: PropTypes.func.isRequired,
    onRemovePhoto: PropTypes.func.isRequired,
    photos: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        original: PropTypes.string,
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
    ])),
    setModalMessage: PropTypes.func.isRequired,
    setModalTitle: PropTypes.func.isRequired,
    setAndOpenModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    photos: [],
  }

  state = { ...INITIAL_STATE };


  handleAddFile = (e) => {
    const { files } = e.target;
    if (files.length === 0) return;
    const { photos } = this.props;
    const existingPhotosQuantity = photos.length;
    let photosToAddQuantity = 1;

    for (let i = 0; i < files.length; i += 1) {
      if (ACCEPTED_TYPES.includes(files[i].type)) {
        if (files[i].size > MAX_FILE_SIZE) {
          const { setModalMessage, setModalTitle, setAndOpenModal } = this.props;
          setModalTitle('Ups!');
          setModalMessage('Max file size 10Mb');
          setAndOpenModal('SUCCES_MODAL_MESSAGE');
        } else if (existingPhotosQuantity + photosToAddQuantity <= PHOTOS_LIMIT) {
          photosToAddQuantity += 1;
          const loadImageOptions = { canvas: true };

          loadImage.parseMetaData(files[i], (data) => {
            if (data.exif && data.exif.get('Orientation')) {
              loadImageOptions.orientation = data.exif.get('Orientation');
            }
            loadImage(files[i], (canvas) => {
              const preview = canvas.toDataURL(files[i].type);
              this.props.onPhotoUpdate([
                ...this.props.photos,
                {
                  url: preview,
                  file: files[i],
                },
              ]);
            }, loadImageOptions);
          });
        } else {
          const { setModalMessage, setModalTitle, setAndOpenModal } = this.props;
          setModalTitle('Oops!');
          setModalMessage('You have exceeded maximum files quantity');
          setAndOpenModal('SUCCES_MODAL_MESSAGE');
        }
      }
    }
  }

  handleResetFile = (e) => {
    const { index } = e.currentTarget.dataset;
    const { photos } = this.props;
    let idToDelete;
    const updatedPhotos = photos.filter(
      (photo, photoIndex) => {
        if (photoIndex === parseInt(index, 10)) {
          idToDelete = photo.id ? photo.id : null;
          return false;
        }
        return true;
      },
    );
    const { onRemovePhoto, onPhotoUpdate } = this.props;
    onPhotoUpdate(updatedPhotos);
    onRemovePhoto(idToDelete);
  }

  handleOpenCrop = (e) => {
    const { index } = e.currentTarget.dataset;
    const { photos } = this.props;
    const url = photos[index]
      ? (photos[index].url || photos[index].original || photos[index].full)
      : null;
    this.setState({
      isCropOpened: true,
      srcToCrop: url,
      indexToCrop: index,
    });
  }

  handleCancelCrop = () => this.setState({ ...INITIAL_STATE });

  handlePreviewCrop = previewImage => this.setState({ previewImageUrl: previewImage });

  handleApplyCrop = ({ index, croppedImage }) => {
    const { photos, onPhotoUpdate, onRemovePhoto } = this.props;
    let idToDelete;
    const updatedPhotos = photos.map(
      (photo, photoIndex) => {
        if (photoIndex === parseInt(index, 10)) {
          idToDelete = photo.id ? photo.id : null;
          return ({
            url: croppedImage.url,
            file: croppedImage.file,
          });
        }
        return photo;
      },
    );
    onPhotoUpdate(updatedPhotos);
    if (idToDelete) onRemovePhoto(idToDelete);
    this.setState({ ...INITIAL_STATE });
  }

  handleDragEnd = (result) => {
    if (!result) return;
    const { destination, source } = result;
    const sourceIndex = getNestedValue(result, 'source', 'index');
    let destinationIndex = getNestedValue(result, 'destination', 'index');

    if (!destination || !source || sourceIndex === destinationIndex) return;

    const { photos, onPhotoUpdate } = this.props;
    const updatedPhotos = [...photos];

    if (destinationIndex > updatedPhotos.length + 1) {
      destinationIndex = updatedPhotos.length - 1;
    }
    const elementToDrop = updatedPhotos.splice(sourceIndex, 1)[0];
    updatedPhotos.splice(destinationIndex, 0, elementToDrop);

    onPhotoUpdate(updatedPhotos);
  }

  renderPhotoInput = (index) => {
    const { photos } = this.props;
    const { previewImageUrl } = this.state;
    const url = photos[index]
      ? (photos[index].url || photos[index].original || photos[index].full)
      : null;
    const style = index === 0 ? styles.photo_promo : styles.photo;
    return (
      <Draggable draggableId={String(index)} index={index}>
        {provided => (
          previewImageUrl && index === 0 && window.innerWidth > 767
            ? (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <div
                  className={styles.photo_promo}
                  style={{ backgroundImage: `url(${previewImageUrl})` }}
                />
              </div>
            ) : (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                <div
                  className={style}
                  style={url && { backgroundImage: `url(${url})` }}
                >
                  {url ? (
                    <>
                      {photos[index].url && (
                        <div
                          className={styles.icon_wrapper_left}
                          onClick={this.handleOpenCrop}
                          onKeyPress={this.handleOpenCrop}
                          role="button"
                          data-index={index}
                          tabIndex={0}
                        >
                          <CropIcon className={styles.icon} />
                        </div>
                      )}
                      <div
                        className={styles.icon_wrapper}
                        onClick={this.handleResetFile}
                        onKeyPress={this.handleResetFile}
                        role="button"
                        data-index={index}
                        tabIndex={0}
                      >
                        <CancelIcon className={styles.icon} />
                      </div>
                    </>
                  ) : (
                    <>
                      <Camera customClass={styles.camera} />
                      <input
                        type="file"
                        name={`photo${index}`}
                        className={styles.input}
                        onChange={this.handleAddFile}
                        multiple
                        accept="image/*"
                      />
                    </>
                  )}
                </div>
              </div>
            )
        )}
      </Draggable>
    );
  };

  render() {
    const { isCropOpened, srcToCrop, indexToCrop } = this.state;
    return (
      <div>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="photos_container">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {this.renderPhotoInput(0)}
                <div className={styles.container}>
                  {this.renderPhotoInput(1)}
                  {this.renderPhotoInput(2)}
                  {this.renderPhotoInput(3)}
                  {this.renderPhotoInput(4)}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isCropOpened && (
          <PhotoCrop
            src={srcToCrop}
            index={indexToCrop}
            onPreviewCrop={this.handlePreviewCrop}
            onCancelCrop={this.handleCancelCrop}
            onApplyCrop={this.handleApplyCrop}
          />
        )}
      </div>
    );
  }
}

export default PhotoManager;
