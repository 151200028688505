import React from 'react';

const GoogleLogo = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4487 10.4308C20.4487 9.59182 20.3806 8.97958 20.2333 8.34467H10.6528V12.1315H16.2763C16.163 13.0725 15.5508 14.4898 14.1902 15.4421L14.1711 15.5689L17.2003 17.9156L17.4102 17.9365C19.3376 16.1564 20.4487 13.5374 20.4487 10.4308Z" fill="#4285F4" />
    <path d="M10.6528 20.4082C13.4079 20.4082 15.7208 19.5011 17.4102 17.9365L14.1902 15.4421C13.3285 16.043 12.1721 16.4625 10.6528 16.4625C7.95442 16.4625 5.66419 14.6825 4.84778 12.2222L4.72811 12.2324L1.57833 14.67L1.53714 14.7845C3.21513 18.1179 6.66185 20.4082 10.6528 20.4082Z" fill="#34A853" />
    <path d="M4.84778 12.2222C4.63236 11.5873 4.5077 10.907 4.5077 10.2041C4.5077 9.5011 4.63236 8.82085 4.83645 8.18594L4.83074 8.05072L1.64149 5.57391L1.53714 5.62355C0.84556 7.00678 0.44873 8.5601 0.44873 10.2041C0.44873 11.8481 0.84556 13.4013 1.53714 14.7846L4.84778 12.2222Z" fill="#FBBC05" />
    <path d="M10.6528 3.94555C12.5689 3.94555 13.8614 4.77321 14.5984 5.46487L17.4782 2.65306C15.7095 1.00907 13.4079 0 10.6528 0C6.66185 0 3.21513 2.29023 1.53714 5.62354L4.83645 8.18593C5.66419 5.72562 7.95442 3.94555 10.6528 3.94555Z" fill="#EB4335" />
  </svg>
);

export default GoogleLogo;
