import { SET_SUBSCRIPTION_TO_CHECKOUT, RESET_SUBSCRIPTION_TO_CHECKOUT, SIGN_OUT } from '../../redux/action_types';

const INITIAL_STATE = {
  id: '',
  price: 0,
  recurrency: 'monthly',
};

const checkoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_TO_CHECKOUT:
      return {
        id: action.payload.id,
        price: action.payload.price,
        recurrency: action.payload.recurrent && action.payload.recurrent.freq,
      };

    case RESET_SUBSCRIPTION_TO_CHECKOUT:
      return INITIAL_STATE;

    case SIGN_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default checkoutReducer;
