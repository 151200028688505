import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styles from './modal.module.scss';
import ForgotPasswordForm from '../login/Forgot_password_form';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const ForgotPasswordModal = ({ isModalOpen, closeModal }) => (
  <Modal
    isOpen={isModalOpen}
    onRequestClose={closeModal}
    className={styles.content}
    overlayClassName={styles.overlay}
  >
    <div className={styles.content_box}>
      <h4 className={styles.title}>Forgot your password?</h4>
      <ForgotPasswordForm />
    </div>
  </Modal>
);

ForgotPasswordModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ForgotPasswordModal;
