import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Ticket from './Ticket';
import Checkout from '../Checkout/checkout_container';
import InactiveBanner from '../shared/Inactive_banner/Inactive_banner';

import CancelIcon from '../icons/Cancel_icon';

import getNestedValue from '../../utils/getNestedValue';
import { getDollarsFromCents } from '../../utils/parse';

import buttonStyles from '../../styles/buttons.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import styles from './ticket_order.module.scss';

import { EVENT, PASS } from '../../config';

const INITIAL_STATE = {
  total: 0,
  tickets: [],
  isLoading: false,
  isCheckingOut: false,
  order: null,
};

class TicketOrder extends Component {
  static propTypes = {
    tickets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })).isRequired,
    onClose: PropTypes.func.isRequired,
    eventStartDate: PropTypes.string.isRequired,
    eventEndDate: PropTypes.string.isRequired,
    token: PropTypes.string,
    orderTickets: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    forPasses: PropTypes.bool,
    isItemInactive: PropTypes.bool,
  };

  static defaultProps = {
    token: '',
    forPasses: false,
    isItemInactive: false,
  }

  state = { ...INITIAL_STATE };

  componentDidMount() {
    const { tickets } = this.props;
    const currentDate = new Date();

    if (tickets) {
      const ticketsForState = tickets.map((ticket) => {
        let isAvailable = !ticket.end_date && !ticket.start_date;

        if (ticket.end_date) {
          if (new Date(ticket.end_date) > currentDate) {
            if (ticket.start_date) {
              if (new Date(ticket.start_date) < currentDate) isAvailable = true;
              else isAvailable = false;
            } else {
              isAvailable = true;
            }
          } else {
            isAvailable = false;
          }
        } else if (ticket.start_date) {
          if (new Date(ticket.start_date) < currentDate) isAvailable = true;
          else isAvailable = false;
        } else {
          isAvailable = true;
        }

        return {
          id: ticket.id,
          price: ticket.price,
          isAvailable,
          count: 0,
        };
      });
      this.setState({ tickets: ticketsForState });
    }
  }

  handleAddTicket = (id) => {
    const { isItemInactive } = this.props;
    if (isItemInactive) return;
    this.setState(prevState => ({
      tickets: prevState.tickets.map((ticket) => {
        if (ticket.id === id) {
          return {
            ...ticket,
            count: ticket.count ? ticket.count + 1 : 1,
          };
        } return ticket;
      }),
    }), this.getTotal);
  }

  handleSubstractTicket = (id) => {
    const { isItemInactive } = this.props;
    if (isItemInactive) return;
    this.setState(prevState => ({
      tickets: prevState.tickets.map((ticket) => {
        if (ticket.id === id) {
          return {
            ...ticket,
            count: ticket.count > 0 ? ticket.count - 1 : ticket.count,
          };
        } return ticket;
      }),
    }), this.getTotal);
  }

  getTotal = () => {
    this.setState((prevState) => {
      let total = 0;
      prevState.tickets.map((ticket) => {
        if (ticket.price && ticket.count) {
          total += ticket.price * ticket.count;
        }
        return total;
      });
      return { total };
    });
  }

  handleGoToCheckout = (e) => {
    e.preventDefault();
    const { token, openModal, orderTickets, onClose } = this.props;
    const { tickets, isLoading } = this.state;

    if (isLoading) return;

    if (tickets.length === 0) return;
    const order = [];
    tickets.forEach((ticket) => {
      if (ticket.count && ticket.count > 0) order.push({ id: ticket.id, count: ticket.count });
    });
    if (order.length === 0) return;
    if (token) {
      this.setState({ isLoading: true });
      orderTickets(order, token)
        .then((data) => {
          const orderStatus = getNestedValue(data, 'data', 'status');
          const orderFromApi = getNestedValue(data, 'data');
          if (orderStatus === 'unpaid' && orderFromApi) {
            this.setState({
              isCheckingOut: true,
              order: orderFromApi,
            });
          } else {
            this.setState({ ...INITIAL_STATE });
            onClose();
          }
        });
    } else {
      openModal('SIGN_IN_MODAL');
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { eventStartDate, eventEndDate, forPasses, onClose, isItemInactive } = this.props;
    const { tickets, total, isCheckingOut, order } = this.state;

    const eventHasTickets = !!(tickets.length > 0);
    const buttonStyle = eventHasTickets
      ? `${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.fullwidth}`
      : `${buttonStyles.btn_disabled} ${buttonStyles.btn_uppercase} ${buttonStyles.fullwidth}`;

    const title = forPasses ? 'Pass Options' : 'Ticket Options';
    const totalToString = getDollarsFromCents(total);

    return (
      <>
        {isCheckingOut
          ? (<Checkout order={order} price={total} onClose={onClose} />)
          : (
            <div className={isItemInactive
              ? gridStyles.side_bar_with_inactive_banner
              : gridStyles.wrapper_fixed_bottom}
            >
              {isItemInactive && (
                <InactiveBanner type={forPasses ? PASS : EVENT} />
              )}
              <div className={isItemInactive
                ? gridStyles.scroll_container_wo_buttons_with_inactive_banner
                : gridStyles.scroll_container}
              >
                <h3 className={styles.title}>{title}</h3>
                <button
                  type="button"
                  className={buttonStyles.cancel_button}
                  onClick={onClose}
                >
                  <CancelIcon />
                </button>
                <ul>
                  {tickets.map((ticketFromState, index) => {
                    const ticketFromProps = this.props.tickets.find(
                      element => element.id === ticketFromState.id,
                    );
                    return (
                      <Ticket
                        ticket={ticketFromProps}
                        index={index}
                        eventStartDate={eventStartDate}
                        eventEndDate={eventEndDate}
                        ticketFromState={ticketFromState}
                        onAddTicket={this.handleAddTicket}
                        onSubstractTicket={this.handleSubstractTicket}
                        key={ticketFromState.id}
                      />
                    );
                  })}
                </ul>
                {!eventHasTickets
                  && (
                  <div>{forPasses
                    ? 'Sorry, there are no passes for this class.'
                    : 'Sorry, there are no tickets for this event.'}
                  </div>
                  )}
              </div>
              {!isItemInactive && (
                <>
                  <div className={buttonStyles.fixed_buttons_xs}>
                    <div className={styles.total_container}>
                      <p className={styles.total}>Total:</p>
                      <p className={styles.sum}>{totalToString}</p>
                    </div>
                    <div className={styles.checkout_btn_box}>
                      <button
                        type="button"
                        className={buttonStyle}
                        onClick={this.handleGoToCheckout}
                      >
                        Checkout
                      </button>
                    </div>
                  </div>
                  <div className={styles.spacer_xs} />
                </>
              )}
            </div>
          )
        }
      </>
    );
  }
}

export default TicketOrder;
