import {
  GET_PASS_DETAILS_SUCCESS,
  GET_PASS_DETAILS_FAILURE,
} from '../../redux/action_types';

const initialState = {
  pass: {},
  error: null,
};

const passDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PASS_DETAILS_SUCCESS:
      return {
        ...initialState,
        pass: action.payload,
      };

    case GET_PASS_DETAILS_FAILURE:
      return {
        ...initialState,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default passDetailsReducer;
