import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestPasswordReset } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import { closeModal } from '../Modal/modal_actions';
import styles from './forms.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';


const initialState = {
  email: '',
  emailWasSent: false,
};

export class ForgotPasswordFormComp extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    beginLoading: PropTypes.func.isRequired,
    endLoading: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
  }

  state = { ...initialState }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  resetState = () => {
    this.setState(initialState);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;
    const { beginLoading, endLoading, handleError } = this.props;

    if (email !== '') {
      beginLoading();

      requestPasswordReset(email)
        .then(
          (res) => {
            if (res.status === 204) {
              this.setState({
                emailWasSent: true,
              });
            }
          },
        )
        .catch((err) => { handleError(err); })
        .finally(() => { endLoading(); });
    }
  }

  render() {
    const { email, emailWasSent } = this.state;
    const { closeModal: handleCloseModal } = this.props;

    return (
      <>
        { emailWasSent ? (
          <>
            <p className={styles.message_box}>
              Check your email to reset password!
            </p>
            <button
              type="button"
              className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
              onClick={handleCloseModal}
            >
              Ok
            </button>
          </>
        ) : (
          <>
            <div className={styles.message_box}>
              Please provide the email address you used when you signed up for your Dancity account
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className={styles.input_box}>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleInputChange}
                />
                <label
                  htmlFor="email"
                  className={email !== '' ? styles.with_value : undefined}
                >
                  Email
                </label>
              </div>
              <button
                type="submit"
                className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
              >
                Send
              </button>
            </form>
          </>
        )}
      </>
    );
  }
}

const mDTP = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  beginLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(finishLoading()),
  handleError: err => dispatch(createError(err)),
});

export default connect(null, mDTP)(ForgotPasswordFormComp);
