export const makeSelectOptions = (arr) => {
  if (Array.isArray(arr)) {
    return arr.map(item => ({ value: item, label: item }));
  }
  if (arr === '') return arr;
  return ({ value: arr, label: arr });
};

export const getValueFromOptions = (options) => {
  if (Array.isArray(options)) {
    return options.map(item => (item.value));
  }
  if (options === '') return options;

  return options.value;
};

const formatLabel = (text) => {
  try {
    let label = text.replace('_', ' ');
    label = label.charAt(0).toUpperCase() + label.slice(1);
    return label;
  } catch {
    return text;
  }
};

export const makeSelectOptionsWithHumanLabel = (arr) => {
  if (Array.isArray(arr)) {
    return arr.map(item => ({ value: item, label: formatLabel(item) }));
  }
  if (arr === '') return arr;
  return ({ value: arr, label: formatLabel(arr) });
};
