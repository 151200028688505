import React, { Component } from 'react';
import PropTypes from 'prop-types';
import typography from '../../styles/common.module.scss';
import HistoryItem from './History_item';

class PaymentHistory extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    getPaymentHistory: PropTypes.func.isRequired,
  }

  state = {
    orders: [],
  }

  componentDidMount() {
    this.getPaymentHistory();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) this.getPaymentHistory();
  }

  getPaymentHistory = async () => {
    const { getPaymentHistory } = this.props;

    try {
      const orders = await getPaymentHistory();
      this.setState({ orders });
    } catch (err) {
      this.setState({ orders: [] });
    }
  }

  render() {
    const { orders } = this.state;

    return (
      <div>
        <h5 className={typography.secondary_title}>Payment history</h5>
        <ul>
          {orders && Array.isArray(orders) && orders.map(order => (
            <li key={order.id}>
              <HistoryItem order={order} />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default PaymentHistory;
