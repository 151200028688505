import { connect } from 'react-redux';
import List from './List';
import {
  getItemsList,
  saveScrollPosition,
  handleToggleListView,
  addLike,
  removeLike,
} from './list_actions';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import { setHoverId } from '../Map/map_actions';

const mSTP = state => ({
  token: state.session.token,
  items: state.itemsList.items,
  list: state.itemsList.list,
  isListViewActive: state.itemsList.isListViewActive,
  scrollPosition: state.itemsList.scrollPosition,
});

const mDTP = dispatch => ({
  getItemsList: ({ list, token, filter, viewSaved, fitMapForMarkers }) => dispatch(getItemsList({
    list, token, filter, viewSaved, fitMapForMarkers,
  })),
  saveScrollPosition: position => dispatch(saveScrollPosition(position)),
  openModal: (modalName) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  handleToggleListView: () => dispatch(handleToggleListView()),
  toggleFavorite: ({ id, token, willbeFavorite, filter, type }) => (willbeFavorite
    ? dispatch(addLike({ id, token, filter, type }))
    : dispatch(removeLike({ id, token, filter, type }))),
  setHoverId: id => dispatch(setHoverId(id)),
});

export default connect(mSTP, mDTP)(List);
