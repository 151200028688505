import { animateScroll, scroller } from 'react-scroll';

const scrollToItemTop = ({ wrapper, delay }) => {
  if (!wrapper || typeof wrapper.getBoundingClientRect !== 'function') return;
  const { top } = wrapper.getBoundingClientRect();
  const scrolled = window.scrollY;
  const destination = top + scrolled - 80;

  animateScroll.scrollTo(
    destination,
    {
      duration: 1000,
      smooth: true,
      delay: delay || 0,
    },
  );
};

export const scrollToElement = (element) => {
  if (!element) return;
  scroller.scrollTo(element, {
    duration: 1000,
    delay: 100,
    smooth: true,
    offset: -80,
  });
};

export default scrollToItemTop;
