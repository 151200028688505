/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from '../styles/common.module.scss';

const TermsPage = () => (
  <div className={styles.page_wrapper}>
    <h1 className={styles.page_title_centered}>Privacy Policy</h1>

    <h2 className={styles.secondary_title}>
      Agreement between User and dancity.com
    </h2>
    <p className={styles.paragraph}>
      Protecting your private information is our priority. This Statement of Privacy applies to
      dancity.com and Dance Mobile LLC and governs data collection and usage. For the purposes of
      this Privacy Policy, unless otherwise noted, all references to Dance Mobile LLC include
      dancity.com and Dancity. The Dancity website is a e-commerce and social-networking site. By
      using the Dancity website, you consent to the data practices described in this statement.
    </p>

    <h2 className={styles.secondary_title}>
      Collection of your Personal Information
    </h2>
    <p className={styles.paragraph}>
      In order to better provide you with products and services offered on our Site,
      Dancity may collect personally identifiable information, such as your:
    </p>
    <ul className={styles.list_circle}>
      <li>First and Last Name</li>
      <li>Mailing Address</li>
      <li>E-mail Address</li>
      <li>Phone Number</li>
      <li>Job Title</li>
      <li>Lead/Follow and Style of Dancing</li>
    </ul>
    <p className={styles.paragraph}>
      If you purchase Dancity's products and services, we collect billing and credit card
      information.
      This information is used to complete the purchase transaction.
    </p>
    <p className={styles.paragraph}>
      Dancity may also collect anonymous demographic information, which is not unique to you,
      such as your:
    </p>
    <ul className={styles.list_circle}>
      <li>Age</li>
      <li>Gender</li>
    </ul>
    <p className={styles.paragraph}>
      Please keep in mind that if you directly disclose personally identifiable information or
      personally sensitive data through Dancity's public message boards, this information may
      be collected and used by others.
    </p>
    <p className={styles.paragraph}>
      We do not collect any personal information about you unless you voluntarily provide it to us.
      However, you may be required to provide certain personal information to us when you elect to
      use
      certain products or services available on the Site. These may include: (a) registering for an
      account
      on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c)
      signing up for special offers from selected third parties; (d) sending us an email message;
      (e)
      submitting your credit card or other payment information when ordering and purchasing products
      and services on our Site. To wit, we will use your information for, but not limited to,
      communicating with you in relation to services and/or products you have requested from us. We
      also may gather additional personal or non-personal information in the future.
    </p>

    <h2 className={styles.secondary_title}>
      Use of your Personal Information
    </h2>
    <p className={styles.paragraph}>
      Dancity collects and uses your personal information to operate its website(s) and deliver the
      services you have requested.
    </p>
    <p className={styles.paragraph}>
      Dancity may also use your personally identifiable information to inform you of other products
      or services available from Dancity and its affiliates.
    </p>

    <h3 className={styles.secondary_title}>
      Sharing Information with Third Parties
    </h3>
    <p className={styles.paragraph}>
      Dancity does not sell, rent or lease its customer lists to third parties.
    </p>
    <p className={styles.paragraph}>
      Dancity may, from time to time, contact you on behalf of external business
      partners about a particular offering that may be of interest to you. In those
      cases, your unique personally identifiable information (e-mail, name, address,
      telephone number) is transferred to the third party. Dancity may share data
      with trusted partners to help perform statistical analysis, send you email or
      postal mail, provide customer support, or arrange for deliveries. All such
      third parties are prohibited from using your personal information except to
      provide these services to Dancity, and they are required to maintain the
      confidentiality of your information.
    </p>
    <p className={styles.paragraph}>
      Dancity may disclose your personal information, without notice, if required to
      do so by law or in the good faith belief that such action is necessary to: (a)
      conform to the edicts of the law or comply with legal process served on
      Dancity or the site; (b) protect and defend the rights or property of Dancity;
      and/or (c) act under exigent circumstances to protect the personal safety of
      users of Dancity, or the public.
    </p>

    <h3 className={styles.secondary_title}>
      Tracking User Behavior
    </h3>
    <p className={styles.paragraph}>
      Dancity may keep track of the websites and pages our users visit within Dancity, in order to
      determine what Dancity services are the most popular. This data is used to deliver customized
      content and advertising within Dancity to customers whose behavior indicates that they are
      interested in a particular subject area.
    </p>

    <h3 className={styles.secondary_title}>
      Automatically Collected Information
    </h3>
    <p className={styles.paragraph}>
      Information about your computer hardware and software may be automatically
      collected by Dancity. This information can include: your IP address, browser
      type, domain names, access times and referring website addresses. This
      information is used for the operation of the service, to maintain quality of
      the service, and to provide general statistics regarding use of the Dancity
      website.
    </p>

    <h3 className={styles.secondary_title}>Use of Cookies</h3>
    <p className={styles.paragraph}>
      The Dancity website may use "cookies" to help you personalize your online
      experience. A cookie is a text file that is placed on your hard disk by a web
      page server. Cookies cannot be used to run programs or deliver viruses to your
      computer. Cookies are uniquely assigned to you, and can only be read by a web
      server in the domain that issued the cookie to you.
    </p>
    <p className={styles.paragraph}>
      One of the primary purposes of cookies is to provide a convenience feature to
      save you time. The purpose of a cookie is to tell the Web server that you have
      returned to a specific page. For example, if you personalize Dancity pages, or
      register with Dancity site or services, a cookie helpsDancity to recall your
      specific information on subsequent visits. This simplifies the process of
      recording your personal information, such as billing addresses, shipping
      addresses, and so on. When you return to the same Dancity website, the
      information you previously provided can be retrieved, so you can easily use
      the Dancity features that you customized.
    </p>
    <p className={styles.paragraph}>
      You have the ability to accept or decline cookies. Most Web browsers
      automatically accept cookies, but you can usually modify your browser setting
      to decline cookies if you prefer. If you choose to decline cookies, you may
      not be able to fully experience the interactive features of the Dancity
      services or websites you visit.
    </p>

    <h3 className={styles.secondary_title}>Links</h3>
    <p className={styles.paragraph}>
      This website contains links to other sites. Please be aware that we are not
      responsible for the content or privacy practices of such other sites. We
      encourage our users to be aware when they leave our site and to read the
      privacy statements of any other site that collects personally identifiable
      information.
    </p>

    <h3 className={styles.secondary_title}>Security of your Personal Information</h3>
    <p className={styles.paragraph}>
      Dancity secures your personal information from unauthorized access, use, or disclosure.
      Dancity uses the following methods for this purpose:
    </p>
    <ul className={styles.list_circle}>
      <li>SSL Protocol</li>
    </ul>
    <p className={styles.paragraph}>
      When personal information (such as a credit card number) is transmitted to other websites,
      it is protected through the use of encryption, such as the
      Secure Sockets Layer (SSL) protocol.
    </p>
    <p className={styles.paragraph}>
      We strive to take appropriate security measures to protect against
      unauthorized access to or alteration of your personal information.
      Unfortunately, no data transmission over the Internet or any wireless network
      can be guaranteed to be 100% secure. As a result, while we strive to protect
      your personal information, you acknowledge that: (a) there are security and
      privacy limitations inherent to the Internet which are beyond our control; and
      (b) security, integrity, and privacy of any and all information and data
      exchanged between you and us through this Site cannot be guaranteed.
    </p>

    <h3 className={styles.secondary_title}>Children Under Thirteen</h3>
    <p className={styles.paragraph}>
      Dancity does not knowingly collect personally identifiable information from
      children under the age of thirteen. If you are under the age of thirteen, you
      must ask your parent or guardian for permission to use this website.
    </p>

    <h3 className={styles.secondary_title}>
      Disconnecting your Dancity Account from Third Party Websites
    </h3>
    <p className={styles.paragraph}>
      You will be able to connect your Dancity account to third party accounts. BY
      CONNECTING YOUR DANCITY ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE
      AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION
      ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD
      PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY -
      SSL Protocol IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE
      THIS FEATURE. You may disconnect your account from a third party account at
      any time. Users who would like to monetize and dance event, dance class, and
      or dance venue can connect their dancity.com account to our third party
      merchant "Stripe". You can disconnect this third party account within the
      Dancity.com user account interface under the payout section or by
      disconnecting your Stripe account directly by visiting Stripe.com and
      deactivating your merchant account.
    </p>

    <h3 className={styles.secondary_title}>
      Opt-Out & Unsubscribe from Third Party Communications
    </h3>
    <p className={styles.paragraph}>
      We respect your privacy and give you an opportunity to opt-out of receiving
      announcements of certain information. Users may opt-out of receiving any or
      all communications from third-party partners of Dancity by contacting us here:
    </p>
    <ul className={styles.list_circle}>
      <li>Web page: dancity.com </li>
      <li>Email: support@dancity.com </li>
    </ul>

    <h3 className={styles.secondary_title}>
      E-mail Communications
    </h3>
    <p className={styles.paragraph}>
      From time to time, Dancity may contact you via email for the purpose of
      providing announcements, promotional offers, alerts, confirmations, surveys,
      and/or other general communication. In order to improve our Services, we may
      receive a notification when you open an email from Dancity or click on a link
      therein.
    </p>

    <h3 className={styles.secondary_title}>
      External Data Storage Sites
    </h3>
    <p className={styles.paragraph}>
      We may store your data on servers provided by third party hosting vendors with whom we have
      contracted.
    </p>

    <h3 className={styles.secondary_title}>
      Changes to this Statement
    </h3>
    <p className={styles.paragraph}>
      Dancity reserves the right to change this Privacy Policy from time to time. We
      will notify you about significant changes in the way we treat personal
      information by sending a notice to the primary email address specified in your
      account, by placing a prominent notice on our site, and/or by updating any
      privacy information on this page. Your continued use of the Site and/or
      Services available through this Site after such modifications will constitute
      your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to
      abide and be bound by that Policy.
    </p>

    <h3 className={styles.secondary_title}>
      Contact Information
    </h3>
    <p className={styles.paragraph}>
      Dancity welcomes your questions or comments regarding this Statement of
      Privacy. If you believe that Dancity has not adhered to this Statement, please
      contact Dancity at:
    </p>
    <p className={styles.paragraph}>
      Dance Mobile LLC <br />
      3616 Kirkwood HWY STE A <br />
      Wilmington, Delaware 19808 <br />
    </p>
    <p className={styles.paragraph}>
      Email Address:  <br />
      support@dancity.com  <br />
    </p>

    <p className={styles.paragraph}>
      Effective as of February 17, 2017
    </p>
  </div>
);

export default TermsPage;
