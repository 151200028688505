import { connect } from 'react-redux';
import Search from './Search';
import { getAutoComplete, getSearch, resetSearchList } from './search_actions';

const mSTP = state => ({
  items: state.itemsList.items,
});

const mDTP = dispatch => ({
  getAutoComplete: query => dispatch(getAutoComplete(query)),
  getSearch: query => dispatch(getSearch(query)),
  resetSearchList: () => dispatch(resetSearchList()),
});

export default connect(mSTP, mDTP)(Search);
