import React from 'react';
import PropTypes from 'prop-types';

const ArrowUp = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 19" className={className}>
    <path fillRule="evenodd" d="M2.111 7.671L5.74 4.004v13.804c0 .657.555 1.192 1.237 1.192.683 0 1.238-.535 1.238-1.192V4.072l3.681 3.598a1.272 1.272 0 0 0 1.747-.004 1.164 1.164 0 0 0 0-1.686L7.862.36 7.845.344a1.948 1.948 0 0 0-.083-.072L7.69.221l-.028-.02-.03-.017-.052-.03a.352.352 0 0 0-.053-.027L7.476.104 7.45.092l-.029-.01-.052-.017-.032-.012a.305.305 0 0 1-.029-.007L7.249.032l-.03-.007-.042-.006-.08-.012a1.305 1.305 0 0 0-.242.001l-.081.011-.039.006-.03.007-.06.015-.028.006-.03.011-.056.019-.028.01a.272.272 0 0 0-.025.011l-.05.023-.034.015-.023.014-.05.029-.031.017-.028.021-.071.051c-.029.023-.06.049-.087.076l-.013.012L.369 5.976a1.161 1.161 0 0 0-.003 1.69 1.27 1.27 0 0 0 1.745.005z" />
  </svg>
);

ArrowUp.propTypes = {
  className: PropTypes.string,
};

ArrowUp.defaultProps = {
  className: '',
};

export default ArrowUp;
