import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import store from './redux/store';
import './index.css';
import App from './components/App/App';
import history from './utils/history';
import { connectGoogleAnalytics, sendAnalytics } from './utils/googleAnalytics';
import './styles/transition.scss';
import * as serviceWorker from './serviceWorker';

// google analytics code
connectGoogleAnalytics();
history.listen(sendAnalytics);
// google analytics code end

// creating global variable for using in webview app
window.browserHistory = history;
window.isRootPage = () => {
  const path = history && history.location && history.location.pathname;
  const isRoot = path === '/';

  if (window.android && window.android.isRootPage) {
    window.android.isRootPage(isRoot);
  }

  return isRoot;
};
// end of webview variables

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={App} />
    </Router>
  </Provider>, document.getElementById('root'),
);

serviceWorker.unregister();
