import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './loader.module.scss';

export const LoaderComponent = ({ isLoading }) => (
  isLoading && (
    <div className={styles.loader_wrapper}>
      <div className={styles.loader}>Loading...</div>
    </div>
  )
);

LoaderComponent.propTypes = {
  isLoading: PropTypes.bool,
};

LoaderComponent.defaultProps = {
  isLoading: false,
};

const mSTP = state => ({
  isLoading: state.isLoading,
});

export default connect(mSTP)(LoaderComponent);
