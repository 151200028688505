import React from 'react';
import PropTypes from 'prop-types';

const CancelIcon = ({ className }) => (
  className ? (
    <svg className={className} viewBox="0 0 136 136" xmlns="http://www.w3.org/2000/svg">
      <path d="M87.5616 70.1735C86.3196 68.9315 86.3196 67.0685 87.5616 65.8265L134.137 19.2511C135.379 18.0091 136 16.1461 136 14.9041C136 13.6621 135.379 11.7991 134.137 10.5571L125.443 1.86301C124.201 0.621005 122.338 0 121.096 0C119.233 0 117.991 0.621005 116.749 1.86301L70.1735 48.4384C68.9315 49.6804 67.0685 49.6804 65.8265 48.4384L19.2511 1.86301C18.0091 0.621005 16.1461 0 14.9041 0C13.6621 0 11.7991 0.621005 10.5571 1.86301L1.86301 10.5571C0.621005 11.7991 0 13.6621 0 14.9041C0 16.1461 0.621005 18.0091 1.86301 19.2511L48.4384 65.8265C49.6804 67.0685 49.6804 68.9315 48.4384 70.1735L1.86301 116.749C0.621005 117.991 0 119.854 0 121.096C0 122.338 0.621005 124.201 1.86301 125.443L10.5571 134.137C11.7991 135.379 13.6621 136 14.9041 136C16.1461 136 18.0091 135.379 19.2511 134.137L65.8265 87.5616C67.0685 86.3196 68.9315 86.3196 70.1735 87.5616L116.749 134.137C117.991 135.379 119.854 136 121.096 136C122.338 136 124.201 135.379 125.443 134.137L134.137 125.443C135.379 124.201 136 122.338 136 121.096C136 119.854 135.379 117.991 134.137 116.749L87.5616 70.1735Z" />
    </svg>
  ) : (
    <svg width="18" height="18" viewBox="0 0 136 136" xmlns="http://www.w3.org/2000/svg">
      <path d="M87.5616 70.1735C86.3196 68.9315 86.3196 67.0685 87.5616 65.8265L134.137 19.2511C135.379 18.0091 136 16.1461 136 14.9041C136 13.6621 135.379 11.7991 134.137 10.5571L125.443 1.86301C124.201 0.621005 122.338 0 121.096 0C119.233 0 117.991 0.621005 116.749 1.86301L70.1735 48.4384C68.9315 49.6804 67.0685 49.6804 65.8265 48.4384L19.2511 1.86301C18.0091 0.621005 16.1461 0 14.9041 0C13.6621 0 11.7991 0.621005 10.5571 1.86301L1.86301 10.5571C0.621005 11.7991 0 13.6621 0 14.9041C0 16.1461 0.621005 18.0091 1.86301 19.2511L48.4384 65.8265C49.6804 67.0685 49.6804 68.9315 48.4384 70.1735L1.86301 116.749C0.621005 117.991 0 119.854 0 121.096C0 122.338 0.621005 124.201 1.86301 125.443L10.5571 134.137C11.7991 135.379 13.6621 136 14.9041 136C16.1461 136 18.0091 135.379 19.2511 134.137L65.8265 87.5616C67.0685 86.3196 68.9315 86.3196 70.1735 87.5616L116.749 134.137C117.991 135.379 119.854 136 121.096 136C122.338 136 124.201 135.379 125.443 134.137L134.137 125.443C135.379 124.201 136 122.338 136 121.096C136 119.854 135.379 117.991 134.137 116.749L87.5616 70.1735Z" fill="#8f949e" />
    </svg>
  )
);

CancelIcon.propTypes = {
  className: PropTypes.string,
};

CancelIcon.defaultProps = {
  className: '',
};

export default CancelIcon;
