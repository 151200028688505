export const cardInputStyles = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: '300',
      color: '#c1daff',
      letterSpacing: '0.4px',
      fontFamily: "'SFUIDisplay-Regular', Arial, Helvetica, sans-serif",
      '::placeholder': {
        color: '#c1daff',
      },
    },
    invalid: {
      color: '#ff4839',
    },
  },
};

export const cardCVVStyles = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: '300',
      color: '#c1daff',
      letterSpacing: '0.4px',
      fontFamily: "'SFUIDisplay-Regular', Arial, Helvetica, sans-serif",
      '::placeholder': {
        color: '#c1daff',
      },
    },
    invalid: {
      color: '#ff4839',
      fontSize: '21px',
    },
  },
};
