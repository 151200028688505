import { connect } from 'react-redux';
import LessonForm from './Lesson_Form';
import {
  createLesson,
  editLesson,
  addPhotos,
  onLessonSubmitSuccess,
  getTeacherPasses,
} from './lesson_form_actions';
import { getLessonDetails } from '../Lesson_details/lesson_details_actions';
import { getTeacherDetails } from '../Teacher_details/teacher_details_actions';
import { getDanceStyles } from '../shared/Dance_styles/dance_styles_actions';
import { moveMapCenterTo, addMarker } from '../Map/map_actions';
import createError from '../Fetch_error/fetch_error_action';
import { setAndOpenModal } from '../Modal/modal_actions';


const mSTP = state => ({
  token: state.session.token,
  user: state.session.user,
  danceStyles: state.danceStyles.styles,
});

const mDTP = dispatch => ({
  createLesson: lesson => (dispatch(createLesson(lesson))),
  addPhotos: (id, photos, token) => (dispatch(addPhotos(id, photos, token))),
  editLesson: lesson => (dispatch(editLesson(lesson))),
  getDanceStyles: () => { dispatch(getDanceStyles()); },
  showNewMarker: (coords) => {
    dispatch(moveMapCenterTo(coords));
    dispatch(addMarker(coords));
  },
  handleError: err => dispatch(createError(err)),
  onLessonSubmitSuccess: options => dispatch(onLessonSubmitSuccess(options)),
  getTeacherPasses: id => dispatch(getTeacherPasses(id)),
  getTeacherDetails: id => dispatch(getTeacherDetails({ id })),
  getLessonDetails: id => dispatch(getLessonDetails({ id, moveMap: true })),
  openModal: modalName => dispatch(setAndOpenModal(modalName)),
});

export default connect(mSTP, mDTP)(LessonForm);
