const customStyles = {
  container: provided => ({
    ...provided,
    marginBottom: 15,
  }),

  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: state.isFocused ? '1px solid #0077ee' : '1px solid #909bb1',
    borderRadius: '10px',
    boxSizing: 'border-box',
    userSelect: 'none',
    width: '100%',
    display: 'flex',
    boxShadow: 'none',
  }),

  input: provided => ({
    ...provided,
    webkitCredentialsAutoFillButton: {
      backgroundColor: 'transparent',
    },
    webkitTextfieldDecorationContainer: {
      backgroundColor: 'transparent',
    },
    webkitCapsLockIndicator: {
      backgroundColor: 'transparent',
    },
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  valueContainer: provided => ({
    ...provided,
    padding: '9px 8px 10px 20px',
    minHeight: '50px',
    minWidth: '70px',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },

  multiValue: provided => ({
    ...provided,
    backgroundColor: '#0077ee',
    borderRadius: 5,
    padding: '2px 4px 2px 5px',
  }),

  multiValueLabel: provided => ({
    ...provided,
    color: '#fff',
    fontSize: '14px',
  }),

  multiValueRemove: provided => ({
    ...provided,
    color: '#fff',
    ':hover': {
      color: '#000',
    },
  }),

  menu: provided => ({
    ...provided,
    maxWidth: '100%',
    borde: 'none',
    borderRadius: '10px',
    zIndex: 10,
    boxShadow: '0px 0px 20px -6px rgba(0,0,0,0.5)',
    marginTop: 0,
  }),

  dropdownIndicator: provided => ({
    ...provided,
    padding: '4px 14px 4px 4px',
    color: '#909bb1',
    ':hover': {
      color: '#0077ee',
    },
  }),

  clearIndicator: provided => ({
    ...provided,
    padding: '2px',
    color: '#909bb1',
    ':hover': {
      color: '#0077ee',
    },
  }),

  placeholder: provided => ({
    ...provided,
    color: '#909bb1',
    fontSize: 18,
    margin: 0,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#E1ECFC' : 'transparent',
    borderRadius: '5px',
    fontWeight: (state.isFocused || state.isSelected) ? 'bold' : 'normal',
    boxShadow: state.isFocused ? '0 0 10px 0 rgba(59, 60, 75, 0.1)' : 'none',
    color: state.isSelected && '#000',
    textAlign: 'left',
  }),

  menuList: provided => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
};

export const selectErrorStyles = {
  ...customStyles,

  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    border: state.isFocused ? '1px solid #0077ee' : '1px solid #ff4839',
    borderRadius: '10px',
    boxSizing: 'border-box',
    userSelect: 'none',
    width: '100%',
    display: 'flex',
    boxShadow: 'none',
  }),

  placeholder: provided => ({
    ...provided,
    color: '#ff4839',
    fontSize: 18,
    margin: 0,
  }),
};

export const selectActiveStyles = {
  ...customStyles,

  control: provided => ({
    ...provided,
    background: 'transparent',
    border: '1px solid #0077ee',
    borderRadius: '10px',
    boxSizing: 'border-box',
    userSelect: 'none',
    width: '100%',
    display: 'flex',
    boxShadow: 'none',
  }),
};

export default customStyles;
