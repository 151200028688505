import {
  OPEN_MODAL,
  CLOSE_MODAL,
  CURRENT_MODAL,
  MODAL_TEXT,
  MODAL_TITLE,
} from '../../redux/action_types';

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const openModal = () => ({
  type: OPEN_MODAL,
});

export const setCurrentModal = current => ({
  type: CURRENT_MODAL,
  payload: current,
});

export const setModalMessage = message => ({
  type: MODAL_TEXT,
  payload: message,
});

export const setModalTitle = title => ({
  type: MODAL_TITLE,
  payload: title,
});

export const setAndOpenModal = modalName => (dispatch) => {
  dispatch(setCurrentModal(modalName));
  dispatch(openModal());
};
