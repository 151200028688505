import React from 'react';
import PropTypes from 'prop-types';

import WarningIcon from '../../icons/Warning';

import styles from '../../../styles/banner.module.scss';

import {
  EVENT, TEACHER, LESSON, PASS, VENUE,
} from '../../../config';

const InactiveBanner = ({ type }) => {
  let text = `This ${type} is inactive as it was deleted by the owner. Sorry, but you can't buy tickets to this ${type}.`;
  if (type === PASS) {
    text = "This teacher is inactive as it was deleted. Sorry, but you can't edit passes and lessons of this teacher.";
  }
  if (type === VENUE) {
    text = 'This venue is inactive as it was deleted.';
  }
  return (
    <div
      className={type === VENUE
        ? styles.container
        : styles.inactive_container
      }
    >
      <div>
        <WarningIcon className={styles.warning} />
        <span className={styles.text}>{text}</span>
      </div>
    </div>
  );
};

InactiveBanner.propTypes = {
  type: PropTypes.oneOf([EVENT, TEACHER, LESSON, PASS, VENUE]).isRequired,
};

export default InactiveBanner;
