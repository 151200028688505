import React from 'react';
import PropTypes from 'prop-types';

const CalendarIcon = ({ className }) => (
  className ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 75 75"
    >
      <path fillOpacity="0" d="M.721 0h74v75h-74z" />
      <path fillOpacity="0" d="M.721 0h74v75h-74z" />
      <path fillOpacity="0" d="M.721 0h74v75h-74z" />
      <path d="M27.455 41.377c0-.94-.77-1.7-1.718-1.7h-5.994c-.948 0-1.717.76-1.717 1.7v5.94c0 .94.77 1.702 1.717 1.702h5.994a1.71 1.71 0 0 0 1.718-1.702v-5.94zM42.435 41.377c0-.94-.77-1.7-1.716-1.7h-5.995c-.948 0-1.717.76-1.717 1.7v5.94c0 .94.77 1.702 1.717 1.702h5.995a1.71 1.71 0 0 0 1.716-1.702v-5.94zM57.416 41.377c0-.94-.77-1.7-1.717-1.7h-5.994c-.949 0-1.718.76-1.718 1.7v5.94c0 .94.77 1.702 1.718 1.702H55.7a1.71 1.71 0 0 0 1.717-1.702v-5.94zM27.455 56.225c0-.941-.77-1.702-1.718-1.702h-5.994c-.948 0-1.717.76-1.717 1.702v5.938c0 .94.77 1.702 1.717 1.702h5.994a1.71 1.71 0 0 0 1.718-1.702v-5.938zM42.435 56.225c0-.941-.77-1.702-1.716-1.702h-5.995c-.948 0-1.717.76-1.717 1.702v5.938c0 .94.77 1.702 1.717 1.702h5.995a1.71 1.71 0 0 0 1.716-1.702v-5.938zM57.416 56.225c0-.941-.77-1.702-1.716-1.702h-5.995c-.949 0-1.718.76-1.718 1.702v5.938c0 .94.77 1.702 1.718 1.702H55.7a1.71 1.71 0 0 0 1.716-1.702v-5.938z" />
      <path d="M68.134 8.351v9.072c0 4.101-3.356 7.406-7.493 7.406h-4.728c-4.137 0-7.538-3.305-7.538-7.406V8.318H27.067v9.105c0 4.101-3.4 7.406-7.537 7.406H14.8c-4.137 0-7.493-3.305-7.493-7.406V8.351C3.692 8.459.72 11.424.72 15.069v53.168C.721 71.951 3.758 75 7.504 75h60.434c3.74 0 6.783-3.056 6.783-6.763V15.07c0-3.645-2.971-6.61-6.587-6.718zM65.94 64.927c0 1.604-1.313 2.906-2.933 2.906h-50.7c-1.619 0-2.932-1.302-2.932-2.906V37.463c0-1.605 1.313-2.907 2.933-2.907h50.699c1.62 0 2.933 1.302 2.933 2.907v27.464z" />
      <path d="M14.785 19.965h4.676c1.42 0 2.57-1.139 2.57-2.546V2.546C22.03 1.14 20.88 0 19.46 0h-4.676c-1.42 0-2.57 1.14-2.57 2.546V17.42c0 1.407 1.15 2.546 2.57 2.546zM55.853 19.965h4.676c1.418 0 2.57-1.139 2.57-2.546V2.546C63.098 1.14 61.947 0 60.528 0h-4.676c-1.42 0-2.57 1.14-2.57 2.546V17.42c0 1.407 1.15 2.546 2.57 2.546z" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 75 75"
    >
      <path fillOpacity="0" d="M.721 0h74v75h-74z" />
      <path fillOpacity="0" d="M.721 0h74v75h-74z" />
      <path fillOpacity="0" d="M.721 0h74v75h-74z" />
      <path d="M27.455 41.377c0-.94-.77-1.7-1.718-1.7h-5.994c-.948 0-1.717.76-1.717 1.7v5.94c0 .94.77 1.702 1.717 1.702h5.994a1.71 1.71 0 0 0 1.718-1.702v-5.94zM42.435 41.377c0-.94-.77-1.7-1.716-1.7h-5.995c-.948 0-1.717.76-1.717 1.7v5.94c0 .94.77 1.702 1.717 1.702h5.995a1.71 1.71 0 0 0 1.716-1.702v-5.94zM57.416 41.377c0-.94-.77-1.7-1.717-1.7h-5.994c-.949 0-1.718.76-1.718 1.7v5.94c0 .94.77 1.702 1.718 1.702H55.7a1.71 1.71 0 0 0 1.717-1.702v-5.94zM27.455 56.225c0-.941-.77-1.702-1.718-1.702h-5.994c-.948 0-1.717.76-1.717 1.702v5.938c0 .94.77 1.702 1.717 1.702h5.994a1.71 1.71 0 0 0 1.718-1.702v-5.938zM42.435 56.225c0-.941-.77-1.702-1.716-1.702h-5.995c-.948 0-1.717.76-1.717 1.702v5.938c0 .94.77 1.702 1.717 1.702h5.995a1.71 1.71 0 0 0 1.716-1.702v-5.938zM57.416 56.225c0-.941-.77-1.702-1.716-1.702h-5.995c-.949 0-1.718.76-1.718 1.702v5.938c0 .94.77 1.702 1.718 1.702H55.7a1.71 1.71 0 0 0 1.716-1.702v-5.938z" />
      <path d="M68.134 8.351v9.072c0 4.101-3.356 7.406-7.493 7.406h-4.728c-4.137 0-7.538-3.305-7.538-7.406V8.318H27.067v9.105c0 4.101-3.4 7.406-7.537 7.406H14.8c-4.137 0-7.493-3.305-7.493-7.406V8.351C3.692 8.459.72 11.424.72 15.069v53.168C.721 71.951 3.758 75 7.504 75h60.434c3.74 0 6.783-3.056 6.783-6.763V15.07c0-3.645-2.971-6.61-6.587-6.718zM65.94 64.927c0 1.604-1.313 2.906-2.933 2.906h-50.7c-1.619 0-2.932-1.302-2.932-2.906V37.463c0-1.605 1.313-2.907 2.933-2.907h50.699c1.62 0 2.933 1.302 2.933 2.907v27.464z" />
      <path d="M14.785 19.965h4.676c1.42 0 2.57-1.139 2.57-2.546V2.546C22.03 1.14 20.88 0 19.46 0h-4.676c-1.42 0-2.57 1.14-2.57 2.546V17.42c0 1.407 1.15 2.546 2.57 2.546zM55.853 19.965h4.676c1.418 0 2.57-1.139 2.57-2.546V2.546C63.098 1.14 61.947 0 60.528 0h-4.676c-1.42 0-2.57 1.14-2.57 2.546V17.42c0 1.407 1.15 2.546 2.57 2.546z" />
    </svg>
  )
);

CalendarIcon.propTypes = {
  className: PropTypes.string,
};

CalendarIcon.defaultProps = {
  className: '',
};

export default CalendarIcon;
