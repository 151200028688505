export const getTimeObject = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let amPm = hours > 12 ? 'pm' : 'am';
  if (hours !== 0 && hours !== 12) hours = String(hours % 12);
  if (hours === 12) {
    hours = '12';
    amPm = 'pm';
  }
  if (hours === 0) {
    hours = '12';
    amPm = 'am';
  }
  minutes = minutes < 10 ? `0${minutes}` : String(minutes);

  return ({
    hours: { value: hours, label: hours },
    minutes: { value: minutes, label: minutes },
    amPm: { value: amPm, label: amPm },
  });
};

export const timeObjectToString = ({ hours, minutes, amPm }) => (
  `${hours.value}:${minutes.value} ${amPm.value}`
);
