import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { validateTitle, validateText, checkUserCreatAbilyti } from '../../utils/validation';
import { makeSelectOptions } from '../../utils/selectOptions';

import PhotoManager from '../shared/Photo_manager/Photo_manager_container';
import VenueDetails from '../Venue_details/venue_details_container';
import TextArea from '../shared/Textarea/Textarea';
import CenterPin from '../icons/CenterPin';
import CancelIcon from '../icons/Cancel_icon';

import inputStyles from '../../styles/inputs.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import customSelectStyles, { selectErrorStyles, selectActiveStyles } from '../../styles/select_styles';
import styles from '../../styles/create_form.module.scss';
import getNestedValue from '../../utils/getNestedValue';


const PLACEHOLDER = {
  title: 'Venue name',
  address: 'Address',
};

const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

const INITIAL_STATE = {
  title: { ...INITIAL_STATE_FIELD },
  photos: [],
  description: { ...INITIAL_STATE_FIELD },
  address: { ...INITIAL_STATE_FIELD },
  selectedStyles: {
    value: [],
    isValid: true,
    isActive: false,
  },
  coordinates: null,
  previewView: false,
  venue: null,
  photosError: false,
  photosToRemove: [],
  isLoading: false,
  isDropDownOpen: false,
};

class VenueForm extends Component {
  static propTypes = {
    createVenue: PropTypes.func.isRequired,
    getDanceStyles: PropTypes.func.isRequired,
    showNewMarker: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    danceStyles: PropTypes.arrayOf(PropTypes.string).isRequired,
    setOnPinMode: PropTypes.func.isRequired,
    mapCenterAddress: PropTypes.shape({
      center: PropTypes.object,
      address: PropTypes.string,
    }),
    resetMapCenterAddress: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
      nickname: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      profession: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
    getVenueDetails: PropTypes.func.isRequired,
    setOffPinMode: PropTypes.func.isRequired,
    pinMode: PropTypes.bool.isRequired,
    editVenue: PropTypes.func.isRequired,
    onVenueSubmitSuccess: PropTypes.func.isRequired,
    addPhotos: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    mapCenterAddress: null,
  };

  state = { ...INITIAL_STATE };

  async componentDidMount() {
    const {
      getDanceStyles, history, match,
      handleError, getVenueDetails,
    } = this.props;
    getDanceStyles();

    const idToEdit = match && match.params && match.params.id;
    let venueToEdit;


    if (idToEdit) {
      const venueResponse = await getVenueDetails({ id: idToEdit })
        .catch((err) => {
          const errMessage = err && err.response && err.response.data.error;
          handleError(errMessage);
          history.push('/');
        });
      venueToEdit = venueResponse && venueResponse.data && venueResponse.data.data;
    }
    if (!venueToEdit && history.location.pathname.indexOf('/account/edit_venue/') !== -1) history.push('/');
    if (venueToEdit && history.location.pathname !== '/create_venue') {
      const editPermission = getNestedValue(venueToEdit, 'permissions', 'update');
      if (editPermission) {
        this.setState({
          ...INITIAL_STATE,
          title: {
            isActive: true,
            isValid: true,
            value: venueToEdit.title,
          },
          description: {
            isActive: true,
            isValid: true,
            value: venueToEdit.description,
          },
          selectedStyles: {
            isActive: !!venueToEdit.dance_styles,
            isValid: !!venueToEdit.dance_styles,
            value: venueToEdit.dance_styles ? makeSelectOptions(venueToEdit.dance_styles) : [],
          },
          address: {
            isActive: true,
            isValid: true,
            value: venueToEdit.address,
          },
          coordinates: { ...venueToEdit.coordinates },
          photos: venueToEdit.photos,
        });
      } else { history.push('/'); }
    }
  }

  componentDidUpdate() {
    const { mapCenterAddress, resetMapCenterAddress } = this.props;
    if (mapCenterAddress) {
      this.handleSetAddressFromMap();
      resetMapCenterAddress();
    }
  }

  componentWillUnmount() {
    const { showNewMarker, setOffPinMode } = this.props;
    showNewMarker(null);
    setOffPinMode();
  }

  componentSetState= prevState => this.setState(prevState);

  setErrors = (errors) => {
    const { handleError } = this.props;
    let errorString = '';
    Object.keys(errors).forEach((item) => {
      const errorText = errors[item].join(', ');
      errorString = errorString.length > 0
        ? `${errorString}; ${item}: ${errorText}`
        : `${item}: ${errorText}`;
      if (item === 'dance_styles') {
        this.setState(prevState => ({
          ...prevState,
          selectedStyles: {
            ...prevState.selectedStyles,
            isValid: false,
            error: errorText,
          },
        }));
      } else if (item === 'coordinates') {
        this.setState(prevState => ({
          ...prevState,
          coordinates: null,
          address: {
            ...prevState.address,
            isValid: false,
            error: 'Select address from the list or map',
          },
        }));
      } else if (item.indexOf('photo') > 0) {
        this.setState({ photosError: true });
      } else if (this.state[item]) {
        this.setState(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isValid: false,
            error: errorText,
          },
        }));
      }
    });
    if (errorString.length > 0) handleError(errorString);
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = name === 'title' ? validateTitle(value) : validateText(value);
    this.setState({
      [name]: {
        isActive: true,
        isValid,
        value,
        error: null,
      },
    });
  };

  handleAdressChange = (address) => {
    const { pinMode, setOffPinMode } = this.props;
    const isValid = validateText(address);
    this.setState({
      address: {
        isActive: true,
        value: address,
        isValid,
      },
      coordinates: null,
    });

    if (pinMode) setOffPinMode();
  }

  handleAddressSelect = (address) => {
    const { showNewMarker, handleError } = this.props;

    this.handleAdressChange(address);

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then((coordinates) => {
        this.setState({ coordinates });
        showNewMarker(coordinates);
      })
      .catch(error => handleError(error));

    this.handleHidePinButton();
  }

  handleSetAddressFromMap = () => {
    const { mapCenterAddress, showNewMarker } = this.props;
    const { coordinates, address } = mapCenterAddress;
    if (coordinates && address) {
      this.handleAdressChange(address);
      this.setState({ coordinates });
      showNewMarker(coordinates);
    }
  }

  handleShowPinButton = () => {
    this.setState({ isDropDownOpen: true }, () => {
    });
  }

  handleHidePinButton = () => {
    this.setState({ isDropDownOpen: false });
  }

  handleAddressCancel = () => {
    const { showNewMarker } = this.props;

    this.setState(prevState => ({
      address: {
        ...prevState.address,
        value: '',
        isValid: false,
      },
    }));

    showNewMarker(null);
  }

  startGettingAddresFromMap = () => {
    const { setOnPinMode } = this.props;
    setOnPinMode();
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        value: '',
      },
      coordinates: null,
    }));
  }

  handleSelectStyles = (selectedStyles) => {
    this.setState({
      selectedStyles: {
        value: selectedStyles,
        isValid: true,
        isActive: true,
        error: null,
      },
    });
  }

  handlePhotoSelect = photos => this.setState({ photos });

  activateAllFields = () => {
    this.activateField('title');
    this.activateField('description');
    this.activateField('address');
  }

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  validateCoords = () => {
    const { coordinates } = this.state;
    if (!coordinates) {
      this.setState(prevState => ({
        ...prevState,
        address: {
          ...prevState.address,
          isValid: false,
          error: 'Select address from the list or map',
        },
      }));
      return false;
    }

    return true;
  }

  validateStyles = () => {
    const { selectedStyles } = this.state;
    if (selectedStyles.value.length === 0) {
      this.setState(prevState => ({
        selectedStyles: {
          ...prevState.selectedStyles,
          isValid: false,
          isActive: false,
        },
      }));
    } else {
      this.setState(prevState => ({
        selectedStyles: {
          ...prevState.selectedStyles,
          isValid: true,
        },
      }));
    }
  }

  isValidationPassed = () => {
    const {
      title,
      description,
      address,
      selectedStyles,
    } = this.state;

    const validTextInputs = title.isValid && description.isValid && address.isValid;
    const validStyles = selectedStyles.value.length > 0 && selectedStyles.isValid;
    const validCoords = this.validateCoords();
    return (validTextInputs && validStyles && validCoords);
  };

  validateForm = () => {
    this.activateAllFields();
    this.validateStyles();
    this.validateCoords();
    if (!this.isValidationPassed()) return false;

    return true;
  }

  composeVenue = () => {
    const {
      title,
      description,
      address,
      selectedStyles,
      photos,
      coordinates,
    } = this.state;

    const danceStyles = selectedStyles.value.map(style => style.value);
    const venue = {
      title: title.value,
      description: description.value,
      address: address.value,
      dance_styles: danceStyles,
      photos,
      coordinates,
    };
    this.setState({ venue });

    return { ...venue };
  }

  handleCreateVenue = (e) => {
    e.preventDefault();
    const { handleError, user, openModal } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }

    if (!checkUserCreatAbilyti(user)) {
      openModal('PROFILE_MODAL');
      return;
    }

    if (this.state.isLoading) return;
    const venueForRequest = this.composeVenue();
    delete venueForRequest.photos;

    const { createVenue, onVenueSubmitSuccess, history } = this.props;
    const { photos } = this.state;
    this.setState({ isLoading: true });
    createVenue(venueForRequest)
      .then((data) => {
        if (data && data.id) {
          if (photos.length === 0) {
            onVenueSubmitSuccess({
              uploadPhotos: true,
              action: 'create',
              subscription: data.subscription,
            });
            history.push(`/venues/${data.id}`);
          } else {
            const venueFormData = new FormData();
            photos.forEach((photo, index) => {
              venueFormData.append(`data[photos][${index}][file]`, photo.file, photo.file.name);
              venueFormData.append(`data[photos][${index}][sort]`, index);
            });
            this.handleUploadPhotos({
              id: data.id,
              venueFormData,
              action: 'create',
              subscription: data.subscription,
            });
          }
        }
        if (data && data.errors) {
          this.setErrors(data.errors);
          this.setState({
            previewView: false,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleEditVenue = (e) => {
    e.preventDefault();
    const { handleError } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }
    if (this.state.isLoading) return;

    const venue = this.composeVenue();
    delete venue.photos;

    const {
      editVenue,
      onVenueSubmitSuccess,
      match,
      history,
    } = this.props;
    const { photos, photosToRemove } = this.state;
    venue.id = match && match.params && match.params.id;
    this.setState({ isLoading: true });

    editVenue(venue)
      .then((data) => {
        if (data && data.id) {
          const venueFormData = new FormData();
          let photosWereModified;
          photos.forEach((photo, index) => {
            if (photo.file) {
              venueFormData.append(`data[photos][${index}][file]`, photo.file, photo.file.name);
              venueFormData.append(`data[photos][${index}][sort]`, index);
              photosWereModified = true;
            }
            if (photo.id && index !== photo.sort) {
              venueFormData.append(`data[photos][${index}][id]`, photo.id);
              venueFormData.append(`data[photos][${index}][sort]`, index);
              photosWereModified = true;
            }
          });
          photosToRemove.forEach((photoId, index) => {
            venueFormData.append(`data[photos][${photos.length + index}][id]`, photoId);
            venueFormData.append(`data[photos][${photos.length + index}][to_remove]`, true);
            photosWereModified = true;
          });
          if (photosWereModified) {
            this.handleUploadPhotos({
              id: data.id,
              venueFormData,
              action: 'edit',
              subscription: data.subscription,
            });
          } else {
            history.push(`/venues/${data.id}`);
            onVenueSubmitSuccess(
              {
                uploadPhotos: true,
                action: 'edit',
                subscription: data.subscription,
              },
            );
          }
          return;
        }

        if (data && data.errors) {
          this.setErrors(data.errors);
          this.setState({
            previewView: false,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleUploadPhotos = ({ id, venueFormData, action, subscription }) => {
    const { addPhotos, history, onVenueSubmitSuccess } = this.props;
    addPhotos(id, venueFormData)
      .then((photoData) => {
        if (photoData && photoData.id) {
          history.push(`/venues/${id}`);
          onVenueSubmitSuccess(
            {
              uploadPhotos: true,
              action,
              subscription,
            },
          );
        } else if (photoData && (photoData.errors || photoData.error)) {
          this.setErrors(photoData.errors || photoData.error);
          this.setState({
            previewView: false,
            isLoading: false,
          });
          onVenueSubmitSuccess({ action, subscription });
        } else {
          history.push(`/venues/${id}`);
          onVenueSubmitSuccess({ action, subscription });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleCloseForm = () => {
    if (this.state.isLoading) return;
    this.setState({ ...INITIAL_STATE });

    const { history, location } = this.props;
    const from = location.state && location.state.from;
    if ((from && from.indexOf('/venues/') === -1 && from.indexOf('/account') === -1) || !from) {
      history.push('/');
      return;
    }
    const list = location.state && location.state.list;
    history.push({
      pathname: from,
      state: { list },
    });
  }

  handleOpenPreview = () => {
    const { handleError } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }
    if (this.state.isLoading) return;
    const venue = this.composeVenue();
    if (!venue) return;
    this.setState({ previewView: true });
  }

  handleClosePreview = () => {
    if (this.state.isLoading) return;
    this.setState({ previewView: false });
  };

  handleRemovePhoto = (idToDelete) => {
    this.setState(prevState => ({
      ...prevState,
      photosToRemove: idToDelete
        ? [...prevState.photosToRemove, idToDelete]
        : [...prevState.photosToRemove],
    }));
  }

  preventSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handleSetAddressFromMap = () => {
    const { mapCenterAddress, showNewMarker } = this.props;
    const { coordinates, address } = mapCenterAddress;
    if (coordinates && address) {
      this.handleAdressChange(address);
      this.setState({ coordinates });
      showNewMarker(coordinates);
    }
  }

  startGettingAddresFromMap = () => {
    const { setOnPinMode } = this.props;
    setOnPinMode();
    window.scrollTo(0, 0);
  }

  renderInput = (inputName) => {
    const valueFromState = this.state[inputName];
    let fieldStyle;
    if (!valueFromState.isActive) fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    else fieldStyle = valueFromState.isValid ? inputStyles.input_box_create : `${inputStyles.input_box_create} ${inputStyles.is_error}`;
    return (
      <div className={fieldStyle}>
        <input
          className={inputStyles.input}
          type="text"
          name={inputName}
          value={valueFromState.value}
          onChange={this.handleInputChange}
        />
        <label
          htmlFor={inputName}
          className={valueFromState.value ? inputStyles.label__with_value : inputStyles.label}
        >
          {valueFromState.error ? valueFromState.error : PLACEHOLDER[inputName]}
        </label>
      </div>
    );
  };

  renderAdressInput = () => {
    const { address: valueFromState, isDropDownOpen } = this.state;
    let fieldStyle;
    if (!valueFromState.isActive) fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    else {
      fieldStyle = valueFromState.isValid
        ? inputStyles.input_box_create
        : `${inputStyles.input_box_create} ${inputStyles.is_error}`;
    }
    fieldStyle += ` ${inputStyles.z_top}`;

    return (
      <PlacesAutocomplete
        value={valueFromState.value}
        onChange={this.handleAdressChange}
        onSelect={this.handleAddressSelect}
        name="address"
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={fieldStyle}>
            <input
              {...getInputProps({
                className: inputStyles.input,
                name: 'address',
              })}
              onFocus={this.handleShowPinButton}
              onBlur={this.handleHidePinButton}
            />
            <label
              htmlFor="address"
              className={valueFromState.value ? inputStyles.label__with_value : inputStyles.label}
            >
              {valueFromState.error ? valueFromState.error : PLACEHOLDER.address}
            </label>
            {valueFromState.isActive && (
              <button
                className={`${buttonStyles.close_round} ${styles.cancel}`}
                type="button"
                onClick={this.handleAddressCancel}
                onKeyPress={this.handleAddressCancel}
              >
                <CancelIcon className={buttonStyles.close_round_svg} />
              </button>
            )}
            <div className={inputStyles.google_dropdown}>
              {isDropDownOpen && (
                <button
                  type="button"
                  className={inputStyles.pin_button}
                  onMouseDown={this.startGettingAddresFromMap}
                >
                  <CenterPin className={styles.pin_icon} /> Drop a pin on the map
                </button>
              )}
              {loading && <div className={inputStyles.autocomplete_loading}>Loading...</div>}
              {isDropDownOpen && suggestions.map(suggestion => (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: inputStyles.autocomplete_item,
                  })}
                >
                  <span className={inputStyles.autocomplete_link}>{suggestion.description}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  };

  render() {
    const { danceStyles, match } = this.props;
    const danceStylesError = danceStyles.length === 0;

    const {
      description,
      selectedStyles: {
        value: selectedStyles,
        isValid: isValidStyles,
        isActive: isActiveStyles,
        error: errorStyles,
      },
      photos,
      previewView,
      venue,
      photosError,
    } = this.state;

    const options = makeSelectOptions(danceStyles);

    let selectStyles = isValidStyles ? customSelectStyles : selectErrorStyles;
    if (isActiveStyles) selectStyles = selectActiveStyles;
    const isEditing = match.path.indexOf('edit_venue') !== -1;


    return (
      <div className={`${gridStyles.mobile_on_top} ${gridStyles.wrapper_fixed_bottom}`}>
        {!danceStylesError && (
          <form onSubmit={this.preventSubmit}>
            {previewView ? <VenueDetails venueForPreview={venue} previewFromForm /> : (
              <div className={gridStyles.scroll_container}>
                <h3 className={styles.title}>{isEditing ? 'Edit Venue' : 'Create Venue'}</h3>
                <PhotoManager
                  onPhotoUpdate={this.handlePhotoSelect}
                  onRemovePhoto={this.handleRemovePhoto}
                  photos={photos}
                />
                {photosError && (
                  <div className={styles.error}>
                      Event was updated, but something went wrong with photos ...
                  </div>
                )}
                {this.renderInput('title')}
                {this.renderAdressInput()}
                <TextArea
                  handleInputChange={this.handleInputChange}
                  stateObject={description}
                />
                <Select
                  value={selectedStyles}
                  onChange={this.handleSelectStyles}
                  options={options}
                  isMulti
                  closeMenuOnSelect={false}
                  className={inputStyles.safari_input}
                  styles={selectStyles}
                  placeholder="Dance styles"
                />
                {errorStyles && <div className={styles.error}>{errorStyles}</div>}
              </div>
            )}
            <div className={buttonStyles.fixed_buttons}>
              {isEditing
                ? (
                  <button
                    type="button"
                    className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                    onClick={this.handleEditVenue}
                  >
                    save
                  </button>
                )
                : (
                  <button
                    type="button"
                    className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                    onClick={this.handleCreateVenue}
                    data-test="create_button"
                  >
                    create
                  </button>
                )
              }
              <div className={buttonStyles.buttons_row}>
                <button
                  type="button"
                  className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                  onClick={previewView
                    ? this.handleClosePreview
                    : this.handleOpenPreview}
                >
                  {previewView ? 'edit' : 'preview'}
                </button>
                <button
                  type="button"
                  className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                  onClick={this.handleCloseForm}
                >
                  cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default VenueForm;
