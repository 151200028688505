import React from 'react';
import PropTypes from 'prop-types';

const Feedback = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.2 34.3">
    <path d="M30.5 1.2H8c-3.7 0-6.8 3-6.8 6.8v24c0 .5.3.9.8 1.1.2.1.3.1.5.1.3 0 .6-.1.9-.4l5.2-4.9h22c3.7 0 6.8-3 6.8-6.8V7.9c-.1-3.7-3.1-6.7-6.9-6.7zm4.3 19.9c0 2.4-1.9 4.3-4.3 4.3H8c-.3 0-.6.1-.9.3L3.7 29V7.9c0-2.4 1.9-4.3 4.3-4.3h22.5c2.4 0 4.3 1.9 4.3 4.3v13.2zm-7-10.8H11.4c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h16.4c.6 0 1.1-.5 1.1-1.1 0-.6-.4-1.1-1.1-1.1zm-7.3 6.5h-9c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h9c.4 0 .8-.2 1-.6.2-.4.2-.8 0-1.1-.2-.3-.6-.5-1-.5z" />
  </svg>
);

Feedback.propTypes = {
  className: PropTypes.string,
};

Feedback.defaultProps = {
  className: '',
};

export default Feedback;
