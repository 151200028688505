const getNestedValue = (object, ...args) => {
  let obj = object;
  const levels = args;
  let result;
  levels.some((level) => {
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, level)) {
      result = undefined;
      return true;
    }
    result = obj[level];
    obj = obj[level];
    return false;
  });
  return result;
};

export default getNestedValue;
