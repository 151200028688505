import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectStripe } from 'react-stripe-elements';
import CardSection from '../shared/Card_section/Card_section';

import { validateTitle, validateEmail } from '../../utils/validation';

import cardStyles from '../shared/Card_section/card_section.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';


const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

class AddCardForm extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    stripe: PropTypes.shape({
      createToken: PropTypes.func,
    }).isRequired,
    token: PropTypes.string,
    createError: PropTypes.func.isRequired,
    addPaymentMethod: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onClose: () => {},
    token: '',
  }

  state = {
    name: { ...INITIAL_STATE_FIELD },
    isLoading: false,
  }

  handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    let isValid = false;
    switch (name) {
      case 'name': {
        isValid = validateTitle(value);
        break;
      }
      case 'email': {
        isValid = validateEmail(value) || value === '';
        break;
      }
      case 'confirmation': {
        isValid = !!checked;
        break;
      }
      default: return;
    }
    this.setState({
      [name]: {
        isActive: true,
        isValid,
        value: name === 'confirmation' ? checked : value,
        error: null,
      },
    });
  };

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      stripe, token, createError,
      onClose, addPaymentMethod,
    } = this.props;

    this.activateField('name');
    this.setState({ cardError: '' });
    const { name, isLoading } = this.state;
    if (!name.isValid || isLoading) return;
    if (token) {
      this.setState({ isLoading: true });
      const stripeData = await stripe.createToken({ name: name.value });
      if (!stripeData) return;
      const { token: stripeToken } = stripeData;
      if (stripeToken && stripeToken.id) {
        const cardToken = stripeToken.id;
        addPaymentMethod(cardToken).then((res) => {
          if (res && res.status === 201) {
            onClose();
          }
        });
      } else {
        this.setState({ isLoading: false });
        createError();
      }
    }
  }

  render() {
    const { name } = this.state;
    const { onClose } = this.props;

    const nameStyle = name.isValid || !name.isActive
      ? cardStyles.card_input
      : cardStyles.card_input_invalid;
    return (
      <form onSubmit={this.handleSubmit}>
        <CardSection onNameChange={this.handleInputChange} nameStyle={nameStyle} />
        <div className={`${buttonStyles.buttons_row} ${buttonStyles.buttons_container}`}>
          <button
            type="submit"
            className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
          >
            Add card
          </button>
          <button
            type="button"
            className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    );
  }
}

export default injectStripe(AddCardForm);
