import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

import { getPriceFromCents } from '../../utils/parse';

import CancelIcon from '../icons/Cancel_icon';

import buttonStyles from '../../styles/buttons.module.scss';

import styles from './modal.module.scss';

import { DAILY, MONTHLY } from '../../config';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const CheckoutModal = (props) => {
  const { isModalOpen, closeModal, text, subscriptionId, price, recurrency } = props;
  const recurrencyLabel = recurrency === DAILY
    ? 'day'
    : 'month';
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className={styles.content}
      overlayClassName={styles.overlay}
    >
      <div className={styles.content_box}>
        <button
          type="button"
          className={styles.close_button_right}
          onClick={closeModal}
        >
          <CancelIcon className={styles.close_icon} />
        </button>
        <h4 className={styles.title}>{text}</h4>
        <div className={styles.message_box}>
          <p className={styles.light_grey_text}>To get listed on Dancity, please, subscribe.</p>
          <p className={styles.light_grey_text}>It will cost you just</p>
          <p className={styles.big_price}><sup>$</sup> {getPriceFromCents(price)}</p>
          <p className={styles.light_grey_text_small}>per {recurrencyLabel}</p>
        </div>
        <Link
          className={buttonStyles.btn_ok}
          to={`/checkout?subscription_id=${subscriptionId}`}
          onClick={closeModal}
        >
          Subscribe
        </Link>
        <button
          type="button"
          className={`${buttonStyles.btn_border} ${buttonStyles.btn_wide}`}
          onClick={closeModal}
        >
          Maybe Later
        </button>
      </div>
    </Modal>
  );
};

CheckoutModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  subscriptionId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  recurrency: PropTypes.oneOf([DAILY, MONTHLY]).isRequired,
};

export default CheckoutModal;
