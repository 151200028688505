import axios from 'axios';
import qs from 'qs';
import { stringOfCoordsToObject } from '../utils/parse';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

export const requestCreateEvent = (event, token) => (
  axios.post(
    '/api/events',
    { data: event },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestAddPhotos = ({ id, photos, token, type }) => (
  axios.patch(
    `/api/${type}/${id}`,
    photos,
    { headers: {
      'Content-Type': 'multipart/form-data',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'multipart/form-data',
    } },
  )
);

export const requestDanceStyles = () => (
  axios.get(
    '/api/dancestyles',
  )
);

export const requestDanceCategories = () => (
  axios.get(
    '/api/dancestyles/categories',
  )
);

export const requestDanceStylesByCategory = category => (
  axios.get(
    `/api/dancestyles/${category}`,
  )
);

export const confirmEmail = token => (
  axios.get(`/api/users/${token}/activate`)
);

export const resendActivationEmail = email => (
  axios.post(
    '/api/users/resend_activation',
    { data: { email } },
  )
);

export const requestItemsList = ({ list, token, filter }) => {
  let queryString = '';
  if (filter) {
    const queryObject = qs.parse(filter, { ignoreQueryPrefix: true });
    if (queryObject.saved === 'true' && token) {
      return axios.get(
        `/api/${list}/favorites`,
        { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
      );
    }
    const geoCentre = queryObject.geo_centre
      ? stringOfCoordsToObject(queryObject.geo_centre)
      : null;
    if (geoCentre && queryObject.location) {
      queryString = `geo_centre=[${geoCentre.lng},${geoCentre.lat}]&radius=${queryObject.radius}`;
    }
    if (queryObject.category) {
      const categoryQuery = queryObject.category === 'All' ? '' : queryObject.category;
      queryString = (queryString !== '')
        ? `${queryString}&category=${categoryQuery}`
        : `category=${categoryQuery}`;
    }
    if (queryObject.style && queryObject.style.length > 0) {
      const stylesQuery = Array.isArray(queryObject.style)
        ? `&style[]=${queryObject.style.join('&style[]=')}`
        : `&style=${queryObject.style}`;
      queryString = (queryString !== '')
        ? `${queryString}${stylesQuery}`
        : stylesQuery.substring(1);
    }
    if (queryObject.event_type && queryObject.event_type.length > 0) {
      const typeQuery = Array.isArray(queryObject.event_type)
        ? `&item_type[]=${queryObject.event_type.join('&item_type[]=')}`
        : `&item_type=${queryObject.event_type}`;
      queryString = (queryString !== '')
        ? `${queryString}${typeQuery}`
        : typeQuery.substring(1);
    }
    if (queryObject.sort) {
      queryString = (queryString !== '')
        ? `${queryString}&sort=${queryObject.sort}`
        : `sort=${queryObject.sort}`;
    }

    if (queryObject.start_date && queryObject.end_date) {
      queryString = (queryString !== '')
        ? `${queryString}&start_date=${queryObject.start_date}&end_date=${queryObject.end_date}`
        : `start_date=${queryObject.start_date}&end_date=${queryObject.end_date}`;
    }

    if (queryObject.weekday) {
      queryString = (queryString !== '')
        ? `${queryString}&weekday=${queryObject.weekday}`
        : `weekday=${queryObject.weekday}`;
    }

    if (queryObject.geo_bounds) {
      queryString = (queryString !== '')
        ? `${queryString}&geo_bounds=${queryObject.geo_bounds}`
        : `geo_bounds=${queryObject.geo_bounds}`;
    }
  }
  const endPoint = queryString ? `/api/${list}?${queryString}` : `/api/${list}`;
  return axios.get(
    endPoint,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  );
};

export const requestEventDetails = ({ id, token, startDate }) => {
  const url = startDate
    ? `/api/events/${id}?start_date=${startDate}`
    : `/api/events/${id}`;

  return (
    axios.get(
      url,
      { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
    )
  );
};

export const requestMyEvents = token => (
  axios.get(
    '/api/events/my',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestMyVenues = token => (
  axios.get(
    '/api/venues/my',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestMyTeachers = token => (
  axios.get(
    '/api/teachers/my',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestMySubscriptions = token => (
  axios.get(
    '/api/subscriptions/',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestDeleteEvent = (id, token) => (
  axios.delete(
    `/api/events/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestEditEvent = (event, token) => {
  const { id } = event;
  const eventforBackEnd = { ...event };
  delete eventforBackEnd.id;

  return (
    axios.patch(
      `/api/events/${id}`,
      { data: eventforBackEnd },
      { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
    )
  );
};

export const editUserAvatar = ({ id, avatar, token }) => (
  axios.patch(
    `/api/users/${id}`,
    avatar,
    { headers: {
      'Content-Type': 'multipart/form-data',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'multipart/form-data',
    } },
  )
);

export const requestAutoComplete = (query, token) => (
  axios.get(
    `/api/search?query=${query}&quick`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
  )
);

export const requestSearch = (query, token) => (
  axios.get(
    `/api/search?query=${query}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
  )
);

export const requestAddLike = ({ id, token, type }) => (
  axios.post(
    `/api/${type}s/${id}/favorites`,
    {},
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestRemoveLike = ({ id, token, type }) => (
  axios.delete(
    `/api/${type}s/${id}/favorites`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const getUserTickets = (token, query) => (
  axios.get(
    '/api/order_tickets',
    {
      headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` },
      params: query,
    },
  )
);

export const getUserPasses = (token, query = {}) => (
  axios.get(
    '/api/order_tickets',
    {
      headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` },
      params: { type: 'passes', ...query },
    },
  )
);

export const requestFeedback = (feedback, token) => (
  axios.post(
    '/api/feedback',
    { data: feedback },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestCreateVenue = (venue, token) => (
  axios.post(
    '/api/venues',
    { data: venue },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestEditVenue = (venue, token) => {
  const { id } = venue;
  const venueForBackEnd = { ...venue };
  delete venueForBackEnd.id;

  return (
    axios.patch(
      `/api/venues/${id}`,
      { data: venueForBackEnd },
      { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
    )
  );
};

export const requestVenueDetails = (id, token) => (
  axios.get(
    `/api/venues/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestVenueItems = ({ id, token, day }) => (
  axios.get(
    `/api/venues/${id}/items/${day}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestDeleteVenue = (id, token) => (
  axios.delete(
    `/api/venues/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const loginWithGoogle = googleToken => (
  axios.post(
    '/api/google_login',
    {
      data: {
        token: googleToken,
      },
    },
  )
);

export const loginWithFacebook = googleToken => (
  axios.post(
    '/api/facebook_login',
    {
      data: {
        token: googleToken,
      },
    },
  )
);

export const requestCreateTeacher = (teacher, token) => (
  axios.post(
    '/api/teachers',
    { data: teacher },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestEditTeacher = (teacher, token) => {
  const { id } = teacher;
  const teacherForBackEnd = { ...teacher };
  delete teacherForBackEnd.id;

  return (
    axios.patch(
      `/api/teachers/${id}`,
      { data: teacherForBackEnd },
      { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
    )
  );
};

export const requestTeacherDetails = (id, token) => (
  axios.get(
    `/api/teachers/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestTeacherItems = ({ id, token, day }) => (
  axios.get(
    `/api/teachers/${id}/lessons/${day}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestDeleteTeacher = (id, token) => (
  axios.delete(
    `/api/teachers/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestSoldTickets = ({ id, token, type, startDate }) => {
  const url = startDate
    ? `/api/${type}/${id}/order_tickets?start_date=${startDate}`
    : `/api/${type}/${id}/order_tickets`;

  return axios.get(
    url,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  );
};

export const requestVenueAutoComplete = (query, token) => (
  axios.get(
    `/api/search?query=${query}&type=venue&quick`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
  )
);

export const requestCreateLesson = (lesson, token) => (
  axios.post(
    '/api/lessons',
    { data: lesson },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestEditLesson = (lesson, token) => {
  const { id } = lesson;
  const eventforBackEnd = { ...lesson };
  delete eventforBackEnd.id;

  return (
    axios.patch(
      `/api/lessons/${id}`,
      { data: eventforBackEnd },
      { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
    )
  );
};

export const requestLessonDetails = ({ id, token, startDate }) => {
  const url = startDate
    ? `/api/lessons/${id}?start_date=${startDate}`
    : `/api/lessons/${id}`;
  return (
    axios.get(
      url,
      { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
    )
  );
};

export const requestDeleteLesson = (id, token) => (
  axios.delete(
    `/api/lessons/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestTeacherPasses = (id, token) => (
  axios.get(
    `/api/teachers/${id}/tickets`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestCreatePass = (pass, token) => (
  axios.post(
    '/api/passes',
    { data: pass },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestEditPass = (pass, token) => {
  const { id } = pass;
  const eventforBackEnd = { ...pass };
  delete eventforBackEnd.id;

  return (
    axios.patch(
      `/api/passes/${id}`,
      { data: eventforBackEnd },
      { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}` } },
    )
  );
};

export const requestPassDetails = (id, token) => (
  axios.get(
    `/api/passes/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestDeletePass = (id, token) => (
  axios.delete(
    `/api/passes/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestTeacherClasses = (id, token) => (
  axios.get(
    `/api/teachers/${id}/lessons`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const getOrdersHistory = token => (
  axios.get(
    '/api/checkouts',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestPassVisits = ({ ticketId, token }) => (
  axios.get(
    `/api/order_tickets/${ticketId}/visits`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestReport = (report, token) => (
  axios.post(
    '/api/report',
    { data: report },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);
