import { connect } from 'react-redux';
import {
  searchUsers,
  inviteToFriends,
  getFriends,
  acceptFriend,
  declineFriend,
  openDeleteFriendModal,
  cancelRequest,
} from './friends_actions';

import Friends from './Friends';

const mSTP = state => ({
  isModalOpen: state.modal.isModalOpen,
});

const mDTP = {
  searchUsers,
  inviteToFriends,
  getFriends,
  acceptFriend,
  declineFriend,
  openDeleteFriendModal,
  cancelRequest,
};

export default connect(mSTP, mDTP)(Friends);
