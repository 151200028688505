import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import MethodItem from './Method_item';
import AddCardForm from './Add_card_container';

import typography from '../../styles/common.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import styles from '../../styles/payment_methods.module.scss';


class PaymentMethods extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    getPaymentMethods: PropTypes.func.isRequired,
    deletePaymentMethod: PropTypes.func.isRequired,
    setDefaultMethod: PropTypes.func.isRequired,
    methods: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }

  state = {
    isFormOpen: false,
    isLoading: false,
  }

  componentDidMount() {
    this.getPaymentMethods();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) this.getPaymentMethods();
  }

  getPaymentMethods = async () => {
    const { getPaymentMethods } = this.props;

    try {
      this.setState({ isLoading: true });
      await getPaymentMethods();
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleOpenCardForm = () => {
    this.setState({ isFormOpen: true });
  }

  handleCloseCardForm = () => {
    this.setState({ isFormOpen: false });
  }

  render() {
    const { isFormOpen, isLoading } = this.state;
    const { methods, deletePaymentMethod, setDefaultMethod } = this.props;

    return (
      <div>
        <h5 className={typography.secondary_title}>Payment methods</h5>
        {isLoading ? <p>Loading...</p> : (
          <ul>
            {methods && Array.isArray(methods) && methods.length > 0
              ? methods.map(card => (
                <li key={card.id}>
                  <MethodItem
                    method={card}
                    onDelete={deletePaymentMethod}
                    onSet={setDefaultMethod}
                  />
                </li>
              ))
              : <p>You have no saved cards yet.</p>
            }
          </ul>
        )}
        <div className={styles.add_method_wrapper}>
          {isFormOpen ? (
            <Elements>
              <AddCardForm onClose={this.handleCloseCardForm} />
            </Elements>
          ) : (
            <div className={buttonStyles.extra_wide_container}>
              <button
                type="button"
                onClick={this.handleOpenCardForm}
                className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
              >
                Add new card
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PaymentMethods;
