import { connect } from 'react-redux';
import PaymentHistory from './Payment_history';
import getPaymentHistory from './payment_history_actions';

const mSTP = state => ({
  token: state.session.token,
});

const mDTP = { getPaymentHistory };

export default connect(mSTP, mDTP)(PaymentHistory);
