import { getDayName } from './formatDate';
import { getNumberEnding } from './getRecurrentString';

export const getGeneralDayOption = (date) => {
  const day = date.getDate();
  let option = '';

  if (day <= 28) {
    option = {
      value: {
        bymonthday: [day],
      },
      label: `every ${getNumberEnding(day)} day of month`,
    };
  }
  return option;
};

export const getLastDayOption = (date) => {
  const day = date.getDate();
  const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  let option = '';

  if (day >= 28 && day === daysInMonth) {
    option = {
      value: {
        bymonthday: [-1],
      },
      label: 'every last day of month',
    };
  }
  return option;
};

export const getWeekDayOption = (date) => {
  const day = date.getDate();
  const dayName = getDayName(date);
  const weekNumer = Math.ceil(day / 7);
  let weekNumberWithEnding;

  switch (weekNumer) {
    case 1: {
      weekNumberWithEnding = '1st';
      break;
    }
    case 2: {
      weekNumberWithEnding = '2nd';
      break;
    }
    case 3: {
      weekNumberWithEnding = '3rd';
      break;
    }
    case 4: {
      weekNumberWithEnding = '4th';
      break;
    }
    default: {
      break;
    }
  }
  let option = '';
  if (weekNumberWithEnding) {
    option = {
      value: {
        byday: [`${dayName.charAt(0).toLowerCase()}${dayName.charAt(1)}`],
        week: weekNumer,
      },
      label: `every ${weekNumberWithEnding} ${dayName}`,
    };
  }
  return option;
};

export const getLastWeekDayOption = (date) => {
  const day = date.getDate();
  const dayName = getDayName(date);
  const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const isWeekLast = (day + 7) > daysInMonth;
  let option = '';
  if (isWeekLast) {
    option = {
      value: {
        byday: [`${dayName.charAt(0).toLowerCase()}${dayName.charAt(1)}`],
        week: -1,
      },
      label: `every last ${dayName}`,
    };
  }
  return option;
};

export const getRecurrentOptions = (date) => {
  const generalDayOption = getGeneralDayOption(date);
  const lastDayOption = getLastDayOption(date);
  const weekDayOption = getWeekDayOption(date);
  const lastWeekDayOption = getLastWeekDayOption(date);
  const options = [];
  if (generalDayOption !== '') options.push(generalDayOption);
  if (lastDayOption !== '') options.push(lastDayOption);
  if (weekDayOption !== '') options.push(weekDayOption);
  if (lastWeekDayOption !== '') options.push(lastWeekDayOption);
  return options;
};

export default getRecurrentOptions;
