import { connect } from 'react-redux';

import { closeModal } from './modal_actions';
import { getPaymentMethods } from '../Payment_methods/payment_methods_actions';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { signOut, getUser, editUser } from '../login/login_actions';
import createError from '../Fetch_error/fetch_error_action';
import { resetSubscriptionToUpdate, getMySubscriptions } from '../My_subscriptions/my_subscriptions_actions';

import ModalDispatcher from './Modal_dispatcher';

const mSTP = state => ({
  currentModal: state.modal.currentModal,
  isModalOpen: state.modal.isModalOpen,
  text: state.modal.text,
  title: state.modal.title,
  subscriptionId: state.checkout.id,
  price: state.checkout.price,
  recurrency: state.checkout.recurrency,
  methods: state.paymentMethods,
  subscriptionToUpdate: state.subscriptions.subscriptionToUpdate,
  token: state.session.token,
  user: state.session.user,
});

const mDTP = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  getPaymentMethods: () => dispatch(getPaymentMethods()),
  startLoading: () => dispatch(startLoading()),
  finishLoading: () => dispatch(finishLoading()),
  signOut: () => dispatch(signOut()),
  createError: message => dispatch(createError(message)),
  resetSubscriptionToUpdate: () => dispatch(resetSubscriptionToUpdate()),
  getMySubscriptions: () => dispatch(getMySubscriptions()),
  getUser: ({ id, token }) => dispatch(getUser({ id, token })),
  editUser: (id, token) => dispatch(editUser(id, token)),
});

export default connect(mSTP, mDTP)(ModalDispatcher);
