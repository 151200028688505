import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import Select from 'react-select';
import moment from 'moment';
import { Draggable } from 'react-beautiful-dnd';

import { validateTitle, validateText, validateDescription } from 'utils/validation';

import DatePicker from '../shared/Date_picker/Date_picker';
import TextArea from '../shared/Textarea/Textarea';
import Checkmark from '../icons/Checkmark';
import Burger from '../icons/Burger';

import inputStyles from '../../styles/inputs.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import customSelectStyles, { selectErrorStyles, selectActiveStyles } from '../../styles/select_styles';
import styles from '../../styles/create_ticket_form.module.scss';
import formStyles from '../../styles/create_form.module.scss';

const PLACEHOLDER = {
  name: 'Ticket name',
  description: 'Ticket description',
  quantity: 'Ticket limit #',
  price: 'Price, USD',
  refunds: 'Refunds',
  feeInclude: 'Fees',
  validity: 'Days',
  total_visits: '# of Entries / Ticket',
};

const REFUNDS_OPTIONS = [
  {
    value: false,
    label: 'No refund',
  },
  {
    value: true,
    label: 'With refund',
  },
];

const FEE_OPTIONS = [
  {
    value: true,
    label: 'Retain',
  },
  {
    value: false,
    label: 'Pass on to buyer',
  },
];

const NUMBER_INPUTS = ['quantity', 'price', 'total_visits', 'validity'];

const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

class TicketForm extends Component {
  static propTypes = {
    ticket: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      start_date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      end_date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      refunds: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      isOpened: PropTypes.bool,
      isValid: PropTypes.bool,
      destroyable: PropTypes.bool,
      fee_include: PropTypes.bool,
      validity: PropTypes.number,
      total_visits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      index: PropTypes.number,
    }),
    onSaveTicket: PropTypes.func.isRequired,
    onRemoveTicket: PropTypes.func.isRequired,
    onOpenTicket: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    error: PropTypes.shape({ start_date: PropTypes.arrayOf(PropTypes.string) }),
    handleError: PropTypes.func.isRequired,
    allFieldsActive: PropTypes.bool,
    isEventRepeating: PropTypes.bool,
    isViewedByOwner: PropTypes.bool,
  };

  static defaultProps = {
    ticket: {
      name: '',
      description: '',
      quantity: '',
      price: '',
      date: [],
      refunds: null,
      destroyable: true,
      fee_include: true,
      validity: 0,
      total_visits: 0,
    },
    error: {},
    allFieldsActive: false,
    isEventRepeating: false,
    isViewedByOwner: true,
  };

  constructor(props) {
    super(props);
    const {
      name,
      description,
      quantity,
      price,
      start_date: startDate,
      end_date: endDate,
      refunds,
      fee_include: feeInclude,
      validity,
      total_visits: totalVisits,
    } = this.props.ticket;
    const { error } = this.props;

    let dateError = null;
    if (error && (error.start_date || error.end_date)) {
      if (error.start_date) {
        dateError = `Start date ${error.start_date.join(', ')}`;
      } else if (error.end_date) {
        dateError = `End date ${error.end_date.join(', ')}`;
      }
    }
    this.state = {
      name: {
        isActive: true,
        isValid: !(error && error.name),
        value: name,
        error: error && error.name ? error.name : null,
      },
      description: {
        isActive: true,
        isValid: !(error && error.description),
        value: description,
        error: error && error.description ? error.description : null,
      },
      quantity: {
        isActive: quantity !== 0 || (error && error.quantity),
        isValid: !(error && error.quantity),
        value: quantity === 0 ? '' : quantity,
        error: error && error.quantity ? error.quantity : null,
        isQuantityShow: quantity !== '' && quantity !== 0,
      },
      price: {
        isActive: true,
        isValid: !(error && error.price),
        value: (!price && price !== 0) ? '' : price / 100,
        error: error && error.price ? error.price : null,
      },
      refunds: {
        isActive: true,
        isValid: !(error && error.refunds),
        value: REFUNDS_OPTIONS.find(option => option.value === refunds),
        error: error && error.refunds ? error.refunds : null,
      },
      feeInclude: {
        isActive: true,
        isValid: !(error && error.refunds),
        value: FEE_OPTIONS.find(option => option.value === feeInclude),
        error: error && error.fee_include ? error.fee_include : null,
      },
      date: {
        isActive: true,
        isValid: !(error && (error.start_date || error.end_date)),
        value: [startDate, endDate],
        error: dateError,
        isDateSelecting: !!startDate || !!endDate,
      },
      showDaysInput: !!validity,
      validity: { ...INITIAL_STATE_FIELD, value: validity },
      total_visits: {
        ...INITIAL_STATE_FIELD,
        value: totalVisits,
        isTotalVistsShow: !!totalVisits,
      },
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.error !== prevProps.error) {
      this.setErrors();
    }

    if (this.props.allFieldsActive) {
      this.activateAllFields();
      this.validateDate();
      this.validatePrice({ setInputState: true });

      const { ticket } = this.props;

      if (ticket.refunds === '') {
        this.setState(prevState => ({
          refunds: { ...prevState.refunds, isValid: false },
        }));
      }

      if (typeof ticket.fee_include !== 'boolean') {
        this.setState(prevState => ({
          feeInclude: { ...prevState.feeInclude, isValid: false },
        }));
      }
    }
  }

  setErrors = () => {
    const { error, handleError } = this.props;
    if (error === null) return;
    Object.keys(error).forEach((item) => {
      handleError(`${item}: ${error[item].join(', ')}`);
      if (item === 'start_date' || item === 'end_date') {
        this.setState(prevState => ({
          ...prevState,
          date: {
            ...prevState.date,
            isValid: false,
            error: `${item} ${error[item].join(', ')}`,
          },
        }));
      } else if (this.state[item]) {
        this.setState(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isValid: false,
            error: error[item].join(', '),
          },
        }));
      }
    });
  };

  validatePrice = ({ price, setInputState, callBack }) => {
    const { feeInclude } = this.state;
    const priceValue = price
      ? parseFloat(price, 10)
      : parseFloat(this.state.price.value, 10);
    let isPriceValid = priceValue >= 0 && priceValue !== '';

    const feeIncludeValue = feeInclude && feeInclude.value && feeInclude.value.value;

    if (feeIncludeValue === true) {
      isPriceValid = priceValue === 0 || priceValue >= 0.5;
    }

    if (setInputState) {
      if (callBack) {
        this.setState(prevState => ({
          price: {
            ...prevState.price,
            isValid: isPriceValid,
          },
        }), callBack);
      } else {
        this.setState(prevState => ({
          price: {
            ...prevState.price,
            isValid: isPriceValid,
          },
        }));
      }
    }

    return isPriceValid;
  }

  handleInputChange = (e) => {
    let { value } = e.target;
    const { name } = e.target;

    const isInputNumber = NUMBER_INPUTS.indexOf(name) !== -1;
    if (isInputNumber) {
      value = e.target.rawValue;
    }

    let isValid;
    switch (name) {
      case 'name': {
        isValid = validateTitle(value);
        break;
      }
      case 'description': {
        isValid = validateDescription(value);
        break;
      }
      case 'price': {
        isValid = this.validatePrice({ price: value });
        break;
      }
      case 'quantity': {
        isValid = value >= 0 || value === '';
        break;
      }
      case 'total_visits':
      case 'validity': {
        isValid = true;
        break;
      }

      default: isValid = validateText(value);
    }

    this.setState(prevState => ({
      [name]: {
        ...prevState[name],
        isActive: true,
        isValid,
        value,
      },
    }), this.handlePassTicketToParent);
  };

  handleInputDateChange = date => this.setState({
    date: {
      isActive: true,
      isValid: true,
      value: date,
      isDateSelecting: true,
    },
  }, this.handlePassTicketToParent);

  handleSelectRefund = (selectedRefund) => {
    this.setState({
      refunds: {
        value: selectedRefund,
        isValid: true,
      },
    }, this.handlePassTicketToParent);
  }

  handleSelectFeeIncludes = (selectedFee) => {
    this.setState({
      feeInclude: {
        value: selectedFee,
        isValid: true,
      },
    }, () => {
      this.validatePrice({ setInputState: true, callBack: this.handlePassTicketToParent });
    });
  }

  handleShowDateSelecting = () => {
    this.setState((prevState) => {
      if (prevState.date.isDateSelecting) {
        return {
          date: {
            ...INITIAL_STATE_FIELD,
            isValid: true,
            value: '',
            isDateSelecting: false,
          },
        };
      }

      return {
        date: {
          ...prevState.date,
          isDateSelecting: true,
        },
      };
    }, this.handlePassTicketToParent);
  }

  toggleShowQuantity = () => {
    this.setState((prevState) => {
      if (prevState.quantity.isQuantityShow) {
        return {
          quantity: {
            ...INITIAL_STATE_FIELD,
            isValid: true,
            value: '',
            isQuantityShow: false,
          },
        };
      }

      return {
        quantity: {
          ...prevState.quantity,
          isQuantityShow: true,
        },
      };
    }, this.handlePassTicketToParent);
  }

  handlePassTicketToParent = (e) => {
    if (e && e.preventDefault) e.preventDefault();

    const {
      name,
      date,
      description,
      quantity,
      price,
      refunds,
      feeInclude,
      validity,
      total_visits: totalVisits,
    } = this.state;

    let startDate;
    let endDate;

    if (date && date.value) {
      startDate = date.value[0] && new Date(date.value[0].setHours(0, 0, 0, 0));
      endDate = date.value[1] && new Date(date.value[1].setHours(23, 59, 0, 0));
    }

    const YESTERDAY = moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .subtract(1, 'days')
      .toDate();

    if (startDate && startDate < YESTERDAY) {
      startDate = YESTERDAY;
    }
    const refundValue = refunds && refunds.value && refunds.value.value;

    let feeIncludeValue;
    if (parseFloat(price.value, 10) === 0) {
      feeIncludeValue = true;
    } else if (feeInclude.value && feeInclude.value.value === true) {
      feeIncludeValue = true;
    } else if (feeInclude.value && feeInclude.value.value === false) {
      feeIncludeValue = false;
    }

    const { onSaveTicket, index, ticket: ticketFromProps } = this.props;

    const ticket = {
      name: name.value,
      start_date: startDate || '',
      end_date: endDate || '',
      description: description.value,
      price: price.value === '' ? '' : parseFloat(price.value, 10) * 100,
      refunds: refundValue,
      fee_include: feeIncludeValue,
      isOpened: true,
      destroyable: Object.prototype.hasOwnProperty.call(ticketFromProps, 'destroyable')
        ? ticketFromProps.destroyable
        : true,
      validity: validity.value === '' ? '' : parseFloat(validity.value, 10),
      total_visits: totalVisits.value === '' ? '' : parseFloat(totalVisits.value, 10),
    };

    if (quantity.value) {
      ticket.quantity = parseInt(quantity.value, 10);
    } else {
      ticket.quantity = 0;
    }

    if (ticketFromProps.id) {
      ticket.id = ticketFromProps.id;
    }
    if (ticketFromProps.tempId) {
      ticket.tempId = ticketFromProps.tempId;
    }

    onSaveTicket(ticket, index);
  };

  handleRemoveTicket = (e) => {
    e.preventDefault();
    const { onRemoveTicket, index, isViewedByOwner } = this.props;
    const isTicketDestroyable = this.checkRemoveOportunity();
    if (isTicketDestroyable && isViewedByOwner) {
      onRemoveTicket(index);
    }
  };

  handleDragStart = () => {
    const { ticket, onDragStart } = this.props;
    const { index } = ticket;
    onDragStart(index);
  }

  handleDragEnter = () => {
    const { ticket, onDragEnter } = this.props;
    const { index } = ticket;
    onDragEnter(index);
  }

  handleOpenTicket = (e) => {
    e.preventDefault();
    const { onOpenTicket, index } = this.props;
    onOpenTicket(index);
  }

  handleShowDaysInput = () => {
    this.setState(prevState => ({
      showDaysInput: !prevState.showDaysInput,
      validity: {
        ...prevState.validity,
        value: prevState.showDaysInput ? 0 : prevState.validity.value,
        isValid: true,
      },
    }), this.handlePassTicketToParent);
  }

  toggleShowTotalVisits = () => {
    this.setState((prevState) => {
      if (prevState.total_visits.isTotalVistsShow) {
        return {
          total_visits: {
            ...INITIAL_STATE_FIELD,
            isValid: true,
            value: '',
            isTotalVistsShow: false,
          },
        };
      }

      return {
        total_visits: {
          ...prevState.total_visits,
          isTotalVistsShow: true,
        },
      };
    }, this.handlePassTicketToParent);
  }

  activateAllFields = () => {
    this.activateField('name');
    this.activateField('description');
    this.activateField('quantity');
    this.activateField('price');
    this.activateField('refunds');
    this.activateField('feeInclude');
  }

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  validateDate = () => {
    const { date } = this.state;
    const { isDateSelecting } = date;

    if (isDateSelecting && date.value.length === 0) {
      this.setState(prevState => ({
        date: {
          ...prevState.date,
          isValid: false,
          isActive: false,
        },
      }));
    }
  };

  validateRefunds = () => {
    const { refunds } = this.state;
    if (refunds.value.length === 0) {
      this.setState(prevState => ({
        refunds: {
          ...prevState.refunds,
          isValid: false,
        },
      }));
    } else {
      this.setState(prevState => ({
        refunds: {
          ...prevState.refunds,
          isValid: true,
        },
      }));
    }
  }

  validateFeeIncludes = () => {
    const { feeInclude } = this.state;

    const feeIncludeValue = feeInclude && feeInclude.value && feeInclude.value.value;

    if (typeof feeIncludeValue === 'boolean') {
      this.setState(prevState => ({
        feeInclude: {
          ...prevState.feeInclude,
          isValid: true,
        },
      }));
    } else {
      this.setState(prevState => ({
        feeInclude: {
          ...prevState.feeInclude,
          isValid: false,
        },
      }));
    }
  }

  checkRemoveOportunity = () => {
    const { ticket } = this.props;
    const { destroyable } = ticket;
    return (destroyable && destroyable === true);
  }

  isValidationPassed = () => {
    const { name, date, description, price, refunds } = this.state;
    const validDate = date.isValid;
    const validTextInputs = name.isValid && description.isValid;
    const validPrice = this.validatePrice({ price, setInputState: true });
    const validRefund = refunds.value !== '' && refunds.isValid;
    const validFeeIncludes = this.validateFeeIncludes();
    return validTextInputs && validDate && validRefund && validFeeIncludes && validPrice;
  };

  renderInput = (inputName) => {
    const valueFromState = this.state[inputName];
    const baseStyle = inputStyles.input_box_create;
    let customStyle;
    if (!valueFromState.isActive) customStyle = inputStyles.not_active;
    else if (!valueFromState.isValid) customStyle = inputStyles.is_error;

    const isInputNumber = NUMBER_INPUTS.indexOf(inputName) !== -1;
    return (
      <div className={`${baseStyle} ${customStyle}`}>
        {isInputNumber
          ? (
            <Cleave
              className={inputStyles.input}
              options={inputName === 'price'
                ? {
                  numeral: true,
                  numeralDecimalScale: 2,
                  numeralPositiveOnly: true,
                }
                : {
                  numeral: true,
                  numeralDecimalScale: 0,
                  numeralPositiveOnly: true,
                }
              }
              name={inputName}
              value={valueFromState.value}
              onChange={this.handleInputChange}
            />
          )
          : (
            <input
              className={inputStyles.input}
              type="text"
              name={inputName}
              value={valueFromState.value}
              onChange={this.handleInputChange}
            />
          )
      }
        <label
          htmlFor={inputName}
          className={(valueFromState.value || valueFromState.value === 0)
            ? inputStyles.label__with_value
            : inputStyles.label
          }
        >
          {valueFromState.error ? valueFromState.error : PLACEHOLDER[inputName]}
        </label>
      </div>
    );
  };

  render() {
    const {
      description,
      name: nameFromState,
      date,
      refunds,
      feeInclude,
      quantity,
      showDaysInput,
      total_visits: totalVisits,
    } = this.state;
    const {
      ticket,
      isEventRepeating,
      isViewedByOwner,
      index,
    } = this.props;
    const { name: nameFromProps, isOpened } = ticket;

    const ticketId = ticket.id || String(ticket.tempId);

    let selectStyles = refunds.isActive ? selectActiveStyles : customSelectStyles;
    if (!refunds.isValid) selectStyles = selectErrorStyles;

    let feeSelectStyles = feeInclude.isActive ? selectActiveStyles : customSelectStyles;
    if (!feeInclude.isValid) feeSelectStyles = selectErrorStyles;

    const isTicketDestroyable = this.checkRemoveOportunity();
    const showPriceMessage = feeInclude.value && feeInclude.value.value === true;
    const isDateSelecting = date && date.isDateSelecting;
    const isQuantityShow = quantity && quantity.isQuantityShow;

    const isTotalVistsShow = totalVisits && totalVisits.isTotalVistsShow;

    return (
      <div className={isOpened ? styles.container : styles.container_closed}>
        {isOpened
          ? (
            <>
              <div className={styles.title_row}>
                <Burger className={styles.burger} />
                {nameFromProps === ''
                  ? <h5 className={styles.title}>Add ticket</h5>
                  : <h5 className={styles.title_withArrowUp} onClick={this.handleOpenTicket} role="presentation">{nameFromProps}</h5>
                }
              </div>
              {this.renderInput('name')}
              <TextArea
                handleInputChange={this.handleInputChange}
                stateObject={description}
                placeholder={PLACEHOLDER.description}
              />

              <Select
                value={feeInclude.value}
                onChange={this.handleSelectFeeIncludes}
                options={FEE_OPTIONS}
                className={inputStyles.safari_input}
                styles={feeSelectStyles}
                placeholder={PLACEHOLDER.feeInclude}
                isSearchable={false}
              />
              {feeInclude.error
                && <div className={styles.error}>{feeInclude.error}</div>
              }

              {showPriceMessage && (
                <p className={styles.price_message}>
                  If “Retain” is selected, the ticket price can be $0.00 (free) or more than $0.50
                </p>
              )}
              {this.renderInput('price')}

              <Select
                value={refunds.value}
                onChange={this.handleSelectRefund}
                options={REFUNDS_OPTIONS}
                className={inputStyles.safari_input}
                styles={selectStyles}
                placeholder={PLACEHOLDER.refunds}
                isSearchable={false}
              />
              {refunds.error
                && <div className={styles.error}>{refunds.error}</div>
              }

              <div>
                <label className={styles.checkbox_container}>
                  <input type="checkbox" name="quantity" checked={isDateSelecting} onChange={this.toggleShowQuantity} />
                  <div className={formStyles.checkmark_valid}>
                    {isQuantityShow && <Checkmark className={formStyles.checkmark_icon} />}
                  </div>
                  <h5>Ticket limit</h5>
                </label>
                {isQuantityShow ? this.renderInput('quantity') : null}
              </div>

              <div>
                <label className={styles.checkbox_container}>
                  <input type="checkbox" name="validity" checked={isDateSelecting} onChange={this.handleShowDateSelecting} />
                  <div className={formStyles.checkmark_valid}>
                    {isDateSelecting && <Checkmark className={formStyles.checkmark_icon} />}
                  </div>
                  <h5>Start Sale / End Sale</h5>
                </label>
                {isDateSelecting ? (
                  <DatePicker
                    onDateChange={this.handleInputDateChange}
                    isActive={date.isActive}
                    isValid={date.isValid}
                    value={date.value}
                  />
                ) : null}
              </div>
              {date.error
                && <div className={styles.error}>{date.error}</div>
              }

              {isEventRepeating && (
                <>
                  <div>
                    <label className={styles.checkbox_container}>
                      <input type="checkbox" name="validity" checked={showDaysInput} onChange={this.handleShowDaysInput} />
                      <div className={formStyles.checkmark_valid}>
                        {showDaysInput && <Checkmark className={formStyles.checkmark_icon} />}
                      </div>
                      Ticket Expires
                    </label>
                    {showDaysInput && this.renderInput('validity')}
                  </div>

                  <div>
                    <label className={styles.checkbox_container}>
                      <input type="checkbox" name="total_visits" checked={isDateSelecting} onChange={this.toggleShowTotalVisits} />
                      <div className={formStyles.checkmark_valid}>
                        {isTotalVistsShow && <Checkmark className={formStyles.checkmark_icon} />}
                      </div>
                      <h5>
                        Multiple Entires
                      </h5>
                    </label>
                    {isTotalVistsShow ? this.renderInput('total_visits') : null}
                  </div>
                </>
              )}

              {isViewedByOwner && (
                <button
                  type="button"
                  className={isTicketDestroyable
                    ? `${buttonStyles.btn_remove} ${buttonStyles.btn_wide}`
                    : `${buttonStyles.btn_remove} ${buttonStyles.disabled} ${buttonStyles.btn_wide}`}
                  onClick={this.handleRemoveTicket}
                >
                  Remove Ticket
                </button>
              )}
              {!isTicketDestroyable
                && (
                <div className={styles.text_small}>
                  The ticket cannot be removed as it has been purchased.
                </div>
                )
              }
            </>
          ) : (
            <Draggable draggableId={ticketId} index={index}>
              {provided => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  className={styles.title_row}
                >
                  <Burger className={styles.burger} />
                  <h5 className={styles.title_withArrowDown} onClick={this.handleOpenTicket} role="presentation">
                    {nameFromState.value}
                  </h5>
                </div>
              )}
            </Draggable>
          )
        }
      </div>
    );
  }
}

export default TicketForm;
