import {
  GET_LESSON_DETAILS_SUCCESS,
  GET_LESSON_DETAILS_FAILURE,
} from '../../redux/action_types';

const initialState = {
  lesson: {},
  error: null,
};

const lessonDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LESSON_DETAILS_SUCCESS:
      return {
        ...initialState,
        lesson: action.payload,
      };

    case GET_LESSON_DETAILS_FAILURE:
      return {
        ...initialState,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default lessonDetailsReducer;
