import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Cohoster from './Cohoster';
import UserItem from './User_item';
import SearchUserForm from '../Search_user_form/Search_user_form';

import { requestEventHostingList, requestClassHostingList } from '../../api';
import { EVENT, LESSON, HOST } from '../../config';

import gridStyles from '../../styles/main_grid.module.scss';
import typographyStyles from '../../styles/common.module.scss';
import Navigation from '../CheckinsHostsReportsNav/CheckinsHostSReportsNav';

const INITIAL_STATE = {
  searchText: '',
  searchResult: [],
  hostingItem: null,
  isMainHosterView: false,
};

export default class HostingDetails extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    token: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      host_code: PropTypes.string,
    }).isRequired,
    startLoading: PropTypes.func.isRequired,
    finishLoading: PropTypes.func.isRequired,
    createError: PropTypes.func.isRequired,
    searchUsers: PropTypes.func.isRequired,
    acceptUserCohost: PropTypes.func.isRequired,
    cancelUserCohost: PropTypes.func.isRequired,
    removeUserFromCohost: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    setCohostingData: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    getEventDetails: PropTypes.func.isRequired,
  }

  state = { ...INITIAL_STATE }

  componentDidMount() {
    this.getHostingList();
  }

  componentDidUpdate(prevProps) {
    const wasOpen = prevProps.isModalOpen;
    const isOpen = this.props.isModalOpen;
    if (wasOpen !== isOpen && isOpen === false) {
      this.getHostingList();
      this.searchUsers();
    }
  }

  getHostingList = () => {
    const {
      token, match, startLoading,
      finishLoading, createError,
    } = this.props;
    const id = match && match.params && match.params.id;

    const isEventHosting = match && match.url && match.url.includes('/events');
    const isClassHosting = match && match.url && match.url.includes('/classes');
    let requestHostingList;
    if (isEventHosting) requestHostingList = requestEventHostingList;
    if (isClassHosting) requestHostingList = requestClassHostingList;

    if (token && id && requestHostingList) {
      startLoading();
      requestHostingList(token, id)
        .then((res) => {
          const hostingItem = res.data && res.data.data;

          const hostingStatus = hostingItem && hostingItem.hosting_status;

          if (res.status === 200 && hostingItem) {
            this.setState({ hostingItem });
          }

          if (hostingStatus === HOST) {
            this.setState({ isMainHosterView: true });
          }
        })
        .catch(err => createError(err))
        .finally(finishLoading);
    }
  }

  handleClose = () => {
    this.setState({ ...INITIAL_STATE });
    const { history } = this.props;
    const path = history && history.location && history.location.state
      && history.location.state.from
      ? history.location.state.from
      : '/';
    history.push(path);
  }

  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({ searchText: value }, this.searchUsers);
  };

  searchUsers = async () => {
    const { searchText, hostingItem } = this.state;
    const itemType = hostingItem && hostingItem.type;
    const itemId = hostingItem && hostingItem.id;
    const { searchUsers } = this.props;

    if (searchText === '') {
      this.setState({ searchResult: [] });
    } else {
      const searchResult = await searchUsers(itemType, itemId, searchText);

      if (searchResult && searchResult.length > 0) {
        this.setState({ searchResult });
      } else {
        this.setState({ searchResult: [] });
      }
    }
  }

  openPermissionsModal = (userToCohost) => {
    const { hostingItem } = this.state;
    const { setCohostingData, openModal } = this.props;
    const data = {
      hostingItem,
      userToCohost,
    };
    setCohostingData(data);
    openModal('COHOST_PERMISSIONS_MODAL');
  }

  acceptCohosting = () => {
    const { hostingItem } = this.state;
    const itemType = hostingItem && hostingItem.type;
    const itemId = hostingItem && hostingItem.id;
    const { acceptUserCohost, getEventDetails } = this.props;
    if (itemType && itemId && acceptUserCohost) {
      acceptUserCohost({ type: itemType, itemId })
        .then((res) => {
          if (res.status === 204) {
            this.getHostingList();
            getEventDetails({ id: itemId });
          }
        });
    }
  }

  cancelCohosting = () => {
    const { hostingItem } = this.state;
    const itemType = hostingItem && hostingItem.type;
    const itemId = hostingItem && hostingItem.id;
    const { cancelUserCohost, history } = this.props;
    if (itemType && itemId && cancelUserCohost) {
      cancelUserCohost({ type: itemType, itemId })
        .then((res) => {
          if (res.status === 204 && itemType === EVENT) {
            history.push('/my_hostings/events');
          } else if (res.status === 204 && itemType === LESSON) {
            history.push('/my_hostings/classes');
          } else {
            history.push('/');
          }
        });
    }
  }

  removeCohoster = (userId) => {
    const { hostingItem } = this.state;
    const itemType = hostingItem && hostingItem.type;
    const itemId = hostingItem && hostingItem.id;
    const { removeUserFromCohost } = this.props;
    if (itemType && itemId && removeUserFromCohost && userId) {
      removeUserFromCohost({ type: itemType, itemId, userId })
        .then((res) => {
          if (res.status === 204) {
            this.getHostingList();
            this.searchUsers();
          }
        });
    }
  }

  resetSearch = () => this.setState({
    searchText: '',
    searchResult: [],
  });

  render() {
    const {
      hostingItem, isMainHosterView,
      searchText, searchResult,
    } = this.state;
    const title = hostingItem && hostingItem.title;
    const hostings = hostingItem && hostingItem.hostings;
    const { match, user, history } = this.props;
    const showSearchResult = searchResult && searchResult.length > 0;
    const hostingStatus = hostingItem && hostingItem.hosting_status;
    const permissions = hostingItem && hostingItem.permissions;
    const hostCode = user && user.host_code;

    return (
      <div className={`${gridStyles.side_bar} ${gridStyles.mobile_on_top}`}>
        <Navigation
          history={history}
          match={match}
          hostCode={hostCode}
          title={title}
          hostingStatus={hostingStatus}
          permissions={permissions}
        />

        {isMainHosterView && (
          <SearchUserForm
            searchText={searchText}
            handleInputChange={this.handleInputChange}
            resetSearch={this.resetSearch}
          />
        )}
        {showSearchResult && (
          <>
            <h3 className={typographyStyles.secondary_title}>
              Search results:
            </h3>
            <ul>
              {searchResult.map(item => (
                <UserItem
                  key={item.id}
                  user={item}
                  inviteUser={this.openPermissionsModal}
                />
              ))}
            </ul>
          </>
        )}
        <h3 className={typographyStyles.secondary_title}>
          Hosts:
        </h3>
        {hostings && hostings.length > 0 ? (
          <ul>
            {hostings.map(cohoster => (
              <Cohoster
                key={cohoster.id}
                cohoster={cohoster}
                currentUser={user}
                isMainHosterView={isMainHosterView}
                acceptInvitation={this.acceptCohosting}
                cancelCohosting={this.cancelCohosting}
                removeCohoster={this.removeCohoster}
                openPermissionsModal={this.openPermissionsModal}
              />))}
          </ul>
        ) : (
          <div>Nobody found</div>
        )}
      </div>
    );
  }
}
