import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import getUserName from '../../utils/getUserName';

import defaultAvatar from '../../images/default_avatar.jpg';

import buttonStyles from '../../styles/buttons.module.scss';
import styles from './modal.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export const CheckedInModal = ({ isModalOpen, closeModal, error, buyer }) => {
  const userAvatar = buyer && buyer.avatar && buyer.avatar.thumb
    ? buyer.avatar.thumb
    : defaultAvatar;
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className={styles.content}
      overlayClassName={styles.overlay}
    >
      <div className={styles.content_box}>
        {error ? (
          <>
            <h4 className={styles.title}>Sorry...</h4>
            <div className={styles.message_box}>{typeof error === 'string' && error}</div>
            <div className={styles.message_box}>Guest check-in failed.</div>
          </>
        ) : (
          <>
            <h4 className={styles.title}>Well done!</h4>
            {buyer && (
              <div className={styles.user}>
                <div className={styles.avatar} style={{ backgroundImage: `url(${userAvatar}` }} />
                <div className={styles.message_box}>{`${getUserName(buyer)} was checked-in successfully.`}</div>
              </div>
            )}
          </>
        )}
        <div className={styles.close_button_box}>
          <button
            type="button"
            onClick={closeModal}
            className={`${buttonStyles.btn_ok} ${buttonStyles.btn_uppercase}`}
          >
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};

CheckedInModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  buyer: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  error: PropTypes.string,
};

CheckedInModal.defaultProps = {
  buyer: null,
  error: '',
};

const mSTP = state => ({
  buyer: state.checkIn.buyer,
  error: state.checkIn.error,
});

export default connect(mSTP)(CheckedInModal);
