import qs from 'qs';

import { requestItemsList, requestAddLike, requestRemoveLike } from '../../api';

import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import { showMarkers, fitMapBoundsToMarkers } from '../Map/map_actions';
import { getEventDetails } from '../Event_details/event_details_actions';
import { getVenueDetails } from '../Venue_details/venue_details_actions';
import { getTeacherDetails } from '../Teacher_details/teacher_details_actions';
import { getSearch } from '../Search/search_actions';
import { signOut } from '../login/login_actions';
import { setAndOpenModal } from '../Modal/modal_actions';

import {
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILURE,
  SAVE_SCROLL_POSITION,
  TOGGLE_LIST_VIEW,
} from '../../redux/action_types';

import {
  EVENTS, VENUES, SEARCH_LIST, TEACHERS,
  HOSTING_LIST,
} from '../../config';

export const getItemsListSuccess = (receivedItems, list) => ({
  type: GET_ITEMS_SUCCESS,
  payload: {
    items: receivedItems,
    list,
  },
});

export const getItemsListFailure = () => ({
  type: GET_ITEMS_FAILURE,
});

export const getItemsList = ({ list, filter, fitMapForMarkers }) => (dispatch, getState) => {
  if (list === SEARCH_LIST || list === HOSTING_LIST) return new Promise((res) => { res(); });
  dispatch(startLoading());
  const { token } = getState().session;

  return requestItemsList({ list, token, filter })
    .then((response) => {
      const receivedItems = response.data.data;
      dispatch(getItemsListSuccess(receivedItems, list));
      dispatch(showMarkers(receivedItems));
      if (fitMapForMarkers) dispatch(fitMapBoundsToMarkers());
      return response;
    })
    .catch((error) => {
      dispatch(createError(error));
      dispatch(getItemsListFailure());
    })
    .finally(dispatch(finishLoading()));
};

export const saveScrollPosition = position => ({
  type: SAVE_SCROLL_POSITION,
  payload: position,
});

export const handleToggleListView = () => ({
  type: TOGGLE_LIST_VIEW,
});

const processReject = (rej, dispatch) => {
  if (rej.response && rej.response.status === 401) {
    dispatch(signOut());
    dispatch(setAndOpenModal('SIGN_IN_MODAL'));
  }
  if (rej.response && rej.response.status === 422) {
    return rej.response.data;
  }
  dispatch(createError(rej));
  return rej.response.data;
};

const updateListAndItem = ({ id, dispatch, getState, filter }) => {
  const { list } = getState().itemsList;
  const { token } = getState().session;
  const updateItem = () => {
    switch (list) {
      case EVENTS:
        dispatch(getEventDetails({
          id,
          token,
          filter,
          moveMap: false,
        }));
        break;
      case VENUES:
        dispatch(getVenueDetails({
          id,
          token,
          filter,
          moveMap: false,
        }));
        break;
      case TEACHERS:
        dispatch(getTeacherDetails({
          id,
          token,
          filter,
          moveMap: false,
        }));
        break;
      default:
        break;
    }
  };
  if (list === SEARCH_LIST) {
    const queryObject = qs.parse(filter, { ignoreQueryPrefix: true });
    const query = queryObject.s ? queryObject.s : '';
    dispatch(getSearch(query));
  } else dispatch(getItemsList({ list, filter }));
  updateItem();
};

export const addLike = ({ id, filter, type }) => (dispatch, getState) => {
  const { token } = getState().session;
  if (!token) {
    dispatch(setAndOpenModal('SIGN_IN_MODAL'));
    return new Promise(res => res(null));
  }

  dispatch(startLoading());
  return requestAddLike({ id, token, type })
    .then((response) => {
      if (response.status === 201) {
        updateListAndItem({ id, dispatch, filter, getState });
      }
      return response.data;
    })
    .catch(rej => processReject(rej, dispatch))
    .finally(() => dispatch(finishLoading()));
};

export const removeLike = ({ id, filter, type }) => (dispatch, getState) => {
  const { token } = getState().session;

  if (!token) {
    dispatch(setAndOpenModal('SIGN_IN_MODAL'));
    return new Promise(res => res(null));
  }

  dispatch(startLoading());
  return requestRemoveLike({ id, token, type })
    .then((response) => {
      if (response.status === 204) {
        updateListAndItem({ id, dispatch, getState, filter });
      }
      return response.data;
    })
    .catch(rej => processReject(rej, dispatch))
    .finally(() => dispatch(finishLoading()));
};
