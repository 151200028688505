import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { setItemUrl } from '../../../utils/parse';

import Arrow from '../../icons/Arrow';

import styles from './includes_block.module.scss';

const MAX_ITEMS_TO_SHOW = 3;

export class BlockIncludes extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    withoutLinks: PropTypes.bool,
  }

  static defaultProps = {
    withoutLinks: false,
  }

  state = { showAllItems: false }

  toggleItemsShow = () => this.setState(prevState => ({ showAllItems: !prevState.showAllItems }));

  render() {
    const { history, items, withoutLinks } = this.props;
    const { showAllItems } = this.state;

    const isShowAllButtonRendered = items.length > MAX_ITEMS_TO_SHOW + 1;

    return (
      <>
        <ul className={styles.list}>
          {items.map((item, i) => {
            if (i >= MAX_ITEMS_TO_SHOW && isShowAllButtonRendered && !showAllItems) {
              return null;
            }
            return (
              <li key={item.id} className={styles.item}>
                {withoutLinks
                  ? (
                    <p className={styles.item_text}>
                      {item.title ? item.title : 'One more activity'}
                    </p>
                  )
                  : (
                    <Link
                      className={styles.item_link}
                      to={{
                        pathname: setItemUrl(item),
                        state: { from: history.location.pathname },
                      }}
                    >
                      {item.title ? item.title : 'One more activity'}
                    </Link>
                  )}
              </li>
            );
          })
          }
        </ul>
        {isShowAllButtonRendered && (
          <button
            type="button"
            onClick={this.toggleItemsShow}
            className={styles.show_button}
          >
            {showAllItems ? 'Hide ' : 'Show all classes '}
            <Arrow
              className={showAllItems ? styles.button_expanded : styles.button_arrow}
            />
          </button>
        )}
      </>
    );
  }
}

export default withRouter(BlockIncludes);
