import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import throttle from 'lodash/throttle';
import LazyLoad from 'react-lazyload';

import CancelIcon from '../icons/Cancel_icon';
import ViewThumbnails from '../icons/View_thumbnails';
import ViewList from '../icons/View_list';
import ListItem from '../List/List_item';
import ArrowUp from '../icons/Arrow_up';

import { HOSTING_LIST } from '../../config';

import gridStyles from '../../styles/main_grid.module.scss';
import typographyStyles from '../../styles/common.module.scss';
import navStyles from '../../styles/navigation.module.scss';
import styles from '../Hosting_details/hosting.module.scss';
import listStyles from '../List/list.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

const INITIAL_STATE = {
  showArrowUp: false,
  serverTime: null,
  events: {
    hostingList: [],
    pastHostingList: [],
    isHostingListLoading: false,
    isPastListLoading: false,
  },
  lessons: {
    hostingList: [],
    pastHostingList: [],
    isHostingListLoading: false,
    isPastListLoading: false,
  },
};


export default class Hosting extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    getHostingEvents: PropTypes.func.isRequired,
    getHostingClasses: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      host_code: PropTypes.string,
    }).isRequired,
    isListViewActive: PropTypes.bool.isRequired,
    handleToggleListView: PropTypes.func.isRequired,
    scrollPosition: PropTypes.number.isRequired,
    saveScrollPosition: PropTypes.func.isRequired,
    getPastHostEvents: PropTypes.func.isRequired,
    getPastHostClasses: PropTypes.func.isRequired,
    setHoverId: PropTypes.func.isRequired,
  }

  static defaultProps = {
    history: null,
    location: null,
  }

  checkArrowShow = throttle(() => {
    const showArrowUp = (this.wrapperRef.current && this.wrapperRef.current.scrollTop > 20)
    || window.scrollY > 20;

    this.setState({ showArrowUp });
  }, 500);

  constructor() {
    super();

    this.wrapperRef = createRef();
  }

  state = { ...INITIAL_STATE };

  componentDidMount() {
    this.getHostingItems();
    const { scrollPosition } = this.props;
    window.scrollTo(0, scrollPosition);
    window.addEventListener('scroll', this.checkArrowShow);
    if (this.wrapperRef && this.wrapperRef.current) {
      this.wrapperRef.current.addEventListener('scroll', this.checkArrowShow);
    }
  }

  componentDidUpdate(prevProps) {
    const prevLocation = prevProps.location && prevProps.location.pathname;
    const nowLocation = this.props.location && this.props.location.pathname;

    if (prevLocation !== nowLocation) {
      this.getHostingItems();
    }
  }

  componentWillUnmount() {
    const { saveScrollPosition } = this.props;
    saveScrollPosition(window.pageYOffset);

    window.removeEventListener('scroll', this.checkArrowShow);
    if (this.wrapperRef && this.wrapperRef.current) {
      this.wrapperRef.current.removeEventListener('scroll', this.checkArrowShow);
    }
  }

  getHostingItems = async () => {
    const {
      location,
      getHostingEvents,
      getHostingClasses,
      getPastHostEvents,
      getPastHostClasses,
    } = this.props;
    const pathname = location && location.pathname;

    let name;
    let request;
    let requestPast;
    if (pathname.includes('/my_hostings/events')) {
      name = 'events';
      request = getHostingEvents;
      requestPast = getPastHostEvents;
    } else if (pathname.includes('/my_hostings/classes')) {
      name = 'lessons';
      request = getHostingClasses;
      requestPast = getPastHostClasses;
    } else {
      return;
    }

    this.setState(prevState => ({
      [name]: {
        ...prevState[name],
        isHostingListLoading: true,
        hostingList: [],
        pastHostingList: [],
      },
    }));
    const items = await request()
      .finally(() => {
        this.setState(prevState => ({
          [name]: { ...prevState[name], isHostingListLoading: false },
        }));
      });
    if (items) {
      this.setState(prevState => ({
        [name]: { ...prevState[name], hostingList: items },
      }));
    }
    // past items
    this.setState(prevState => ({
      [name]: { ...prevState[name], isPastListLoading: true },
    }));
    const pastItems = await requestPast()
      .finally(() => {
        this.setState(prevState => ({
          [name]: { ...prevState[name], isPastListLoading: false },
        }));
      });
    if (pastItems) {
      this.setState(prevState => ({
        [name]: { ...prevState[name], pastHostingList: pastItems },
      }));
    }
  }

  handleClose = () => {
    const { history } = this.props;
    const path = history && history.location && history.location.state
      && history.location.state.from
      ? history.location.state.from
      : '/';
    history.push(path);
  }

  scrollToTop = () => {
    if (this.wrapperRef.current) this.wrapperRef.current.scrollTop = 0;
    window.scrollTo(0, 0);
  }

  render() {
    const {
      history,
      user,
      handleToggleListView,
      isListViewActive,
      setHoverId,
      location,
    } = this.props;

    const {
      events,
      lessons,
      showArrowUp,
      serverTime,
    } = this.state;

    const pathname = location && location.pathname;

    let items;
    if (pathname.includes('/my_hostings/events')) items = events;
    else if (pathname.includes('/my_hostings/classes')) items = lessons;
    if (!items) return null;
    const {
      isHostingListLoading,
      hostingList,
      isPastListLoading,
      pastHostingList,
    } = items;

    const hostCode = user && user.host_code;

    const listIconClass = isListViewActive ? styles.light_grey : styles.dark_grey;
    const thumbnailsIconClass = isListViewActive ? styles.dark_grey : styles.light_grey;

    return (
      <div className={`${gridStyles.side_bar} ${gridStyles.mobile_on_top}`} ref={this.wrapperRef} id="scroll_container">
        <h3 className={typographyStyles.title}>Hosting</h3>
        <button type="button" className={styles.close} onClick={this.handleClose}>
          <CancelIcon />
        </button>

        {hostCode ? (
          <h4 className={styles.grey_text}>Host code: {hostCode}</h4>
        ) : null}

        <nav className={navStyles.item_double_wrapper}>
          <ul className={navStyles.container}>
            <li className={navStyles.block_double}>
              <NavLink className={navStyles.item} to="/my_hostings/events" activeClassName={navStyles.item_active}>
                Events
              </NavLink>
            </li>
            <li className={navStyles.block_double}>
              <NavLink className={navStyles.item} to="/my_hostings/classes" activeClassName={navStyles.item_active}>
                Classes
              </NavLink>
            </li>
          </ul>
        </nav>
        <div>
          <div className={listStyles.controllers_container_without_filter}>
            <div className={listStyles.controllers_view}>
              <button
                type="button"
                onClick={handleToggleListView}
                className={listStyles.controllers_item}
              >
                <ViewThumbnails className={listIconClass} />
              </button>
              <button
                type="button"
                onClick={handleToggleListView}
                className={listStyles.controllers_item}
              >
                <ViewList className={thumbnailsIconClass} />
              </button>
            </div>
          </div>
          {isHostingListLoading ? null : (
            <ul>
              {hostingList && hostingList.length > 0
                ? hostingList.map(item => (
                  <ListItem
                    item={item}
                    isListViewActive={isListViewActive}
                    history={history}
                    list={HOSTING_LIST}
                    key={item.id}
                    serverTime={serverTime}
                    setHoverId={setHoverId}
                  />
                ))
                : <li>List is empty</li>
              }
            </ul>
          )}

          <h4 className={typographyStyles.secondary_title}>
            Past:
          </h4>

          {isPastListLoading ? null : (
            <ul>
              {pastHostingList && pastHostingList.length > 0
                ? pastHostingList.map(item => (
                  <LazyLoad
                    key={`${item.id}_${item.start_date}`}
                    scrollContainer={window.innerWidth > 767 ? '#scroll_container' : undefined}
                    offset={400}
                    resize
                  >
                    <ListItem
                      item={item}
                      isListViewActive={isListViewActive}
                      history={history}
                      list={HOSTING_LIST}
                      serverTime={serverTime}
                      setHoverId={setHoverId}
                      past
                    />
                  </LazyLoad>
                ))
                : <li>List is empty</li>
              }
            </ul>
          )}
          {showArrowUp ? (
            <button type="button" className={buttonStyles.arrow_up_btn} onClick={this.scrollToTop}>
              <ArrowUp className={buttonStyles.arrow_up} />
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}
