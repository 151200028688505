import { connect } from 'react-redux';
import StripeRedirect from './stripe_redirect_page';
import { startLoading, finishLoading } from '../../components/Loader/loading_actions';
import { signOut, getUser } from '../../components/login/login_actions';
import { setAndOpenModal } from '../../components/Modal/modal_actions';
import createError from '../../components/Fetch_error/fetch_error_action';

const mSTP = state => ({
  token: state.session.token,
  user: state.session.user,
});

const mDTP = dispatch => ({
  startLoading: () => dispatch(startLoading()),
  finishLoading: () => dispatch(finishLoading()),
  signOut: () => dispatch(signOut()),
  setAndOpenModal: modalName => dispatch(setAndOpenModal(modalName)),
  createError: error => dispatch(createError(error)),
  getUser: ({ id, token }) => dispatch(getUser({ id, token })),
});

export default connect(mSTP, mDTP)(StripeRedirect);
