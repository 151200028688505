import { connect } from 'react-redux';
import {
  getSoldTickets, verifyTicket, getEventDetails,
  getLessonDetails,
} from './check-in_actions';
import createError from '../Fetch_error/fetch_error_action';

import CheckIn from './Check-in';

const mSTP = state => ({
  buyer: state.checkIn.buyer,
  error: state.checkIn.error,
  user: state.session && state.session.user,
});

const mDTP = {
  getSoldTickets,
  verifyTicket,
  createError,
  getEventDetails,
  getLessonDetails,
};

export default connect(mSTP, mDTP)(CheckIn);
