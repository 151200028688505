import { SET_COHOSTING_DATA } from '../../redux/action_types';

const cohostingReducer = (state = null, action) => {
  if (action.type === SET_COHOSTING_DATA) {
    return action.payload;
  }

  return state;
};

export default cohostingReducer;
