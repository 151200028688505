import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import DotsSubmenu from '../shared/Dots_submenu/Dots_submenu_container';

import makeItemLink from '../../utils/makeItemLink';

import WarningIcon from '../icons/Warning';

import styles from './my_items.module.scss';

import {
  TEACHER, LESSON, PASS,
  PAID, FREE,
} from '../../config';

const Item = ({ item, history }) => {
  const id = item && item.id;
  const type = (item && item.type) || PASS;
  const title = item && (item.title || item.name);
  const pathname = makeItemLink({ type, id });
  const subscription = item && item.subscription;
  let subscriptionStatus = '';
  if (subscription) {
    const { status } = subscription;
    if (status && status !== FREE && status !== PAID) subscriptionStatus = `(${status})`;
  }

  return (
    <>
      <Link
        to={{
          pathname,
          state: {
            from: history.location.pathname,
            list: (type === LESSON || type === PASS) ? TEACHER : type,
          },
        }}
        className={styles.link}
      >
        {subscriptionStatus && (
        <WarningIcon className={styles.warning} />
        )}
        {`${title} ${subscriptionStatus}`}
      </Link>
      <DotsSubmenu item={item} />
    </>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

Item.defaultProps = {
  history: null,
};

export default withRouter(Item);
