import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import moment from 'moment';
import { formatDate } from '../../../utils/formatDate';
import CalendarIcon from '../../icons/Calendar';
import Arrow from '../../icons/Arrow';
import styles from './date_picker.module.scss';
import calendarStyles from '../../../styles/calendar.module.scss';
import '../../../styles/calendar.scss';

const TODAY_END = moment()
  .hours(23)
  .minutes(59)
  .seconds(59)
  .toDate();
const START_DATE = 'start date';
const END_DATE = 'end date';

const INITIAL_STATE = {
  isCalendarOpened: false,
  dateSelected: null,
};

class DatePicker extends Component {
  static propTypes = {
    onDateChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    ]).isRequired,
    hasTwoDates: PropTypes.bool,
    renderOnLeftSide: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    hasTwoDates: true,
    renderOnLeftSide: false,
    minDate: null,
  }

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.calendarRef = null;
  }

  setCalendarRef = (element) => {
    this.calendarRef = element;
  };

  handleDateChange = (date) => {
    const { dateSelected } = this.state;
    const { onDateChange, value, hasTwoDates } = this.props;
    const startDate = value[0];
    let endDate = value[1] ? value[1] : TODAY_END;
    if (dateSelected === START_DATE) {
      if (hasTwoDates) {
        endDate = date > endDate ? date : endDate;
      }
      onDateChange([date, endDate]);
    } else {
      onDateChange([startDate, date]);
    }
    this.handleCloseCalendar();
  };

  handleCloseCalendar = () => this.setState({
    isCalendarOpened: false,
    dateSelected: null,
  });

  handleOpenCalendar = (e) => {
    const { name } = e.target;
    this.setState({
      isCalendarOpened: true,
      dateSelected: name,
    });
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (this.calendarRef && !this.calendarRef.contains(e.target)) {
      this.setState({
        isCalendarOpened: false,
        dateSelected: null,
      });
      document.removeEventListener('mousedown', this.handleOutsideClick);
    }
  }

  render() {
    const { isCalendarOpened, dateSelected } = this.state;
    const { value, hasTwoDates, renderOnLeftSide } = this.props;
    let { minDate } = this.props;
    const startDate = value[0];
    const endDate = value[1];

    const isActive = startDate || endDate;

    const maxDate = new Date();

    if (dateSelected === END_DATE && startDate) {
      minDate = startDate;
    }

    const valueDate = dateSelected === START_DATE ? startDate : endDate;
    let inputStyles = isActive ? styles.input_active : styles.input;
    if (!hasTwoDates) inputStyles = `${inputStyles} ${styles.input_wide}`;

    const calendarClass = renderOnLeftSide
      ? calendarStyles.calendar_on_left : calendarStyles.calendar;

    return (
      <>
        <div className={styles.container}>
          <div className={hasTwoDates ? styles.date_box : styles.date_box_wide}>
            <label
              htmlFor={START_DATE}
              className={dateSelected === START_DATE ? styles.label__active : styles.label}
            >
              {START_DATE}
            </label>
            <input
              readOnly
              className={inputStyles}
              name={START_DATE}
              value={startDate ? formatDate(startDate) : ''}
              onClick={this.handleOpenCalendar}
            />
            <div className={styles.icon}><CalendarIcon /></div>
          </div>
          {hasTwoDates && (
            <>
              <div className={isActive ? styles.dash_active : styles.dash} />
              <div className={styles.date_box}>
                <label
                  htmlFor={END_DATE}
                  className={dateSelected === END_DATE ? styles.label__active : styles.label}
                >
                  {END_DATE}
                </label>
                <input
                  readOnly
                  className={isActive ? styles.input_active : styles.input}
                  name={END_DATE}
                  value={endDate ? formatDate(endDate) : ''}
                  onClick={this.handleOpenCalendar}
                />
                <div className={styles.icon}><CalendarIcon /></div>
              </div>
            </>
          )}
          {isCalendarOpened && (
          <div className={calendarStyles.calendar_container} ref={this.setCalendarRef}>
            <Calendar
              className={calendarClass}
              tileClassName={({ view }) => (view === 'century'
                ? calendarStyles.tile__century
                : calendarStyles.tile)}
              nextLabel={<Arrow direction="right" />}
              next2Label={null}
              prevLabel={<Arrow direction="left" />}
              prev2Label={null}
              maxDate={maxDate}
              minDate={minDate}
              onChange={this.handleDateChange}
              value={valueDate}
            />
          </div>
          )
        }
        </div>
      </>
    );
  }
}

export default DatePicker;
