import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { validateTitle, validateText, checkUserCreatAbilyti } from '../../utils/validation';
import { makeSelectOptions, getValueFromOptions } from '../../utils/selectOptions';

import PhotoManager from '../shared/Photo_manager/Photo_manager_container';
import TeacherDetails from '../Teacher_details/teacher_details_container';
import VenueSelector from '../Venue_Selector/Venue_selector_container';
import TextArea from '../shared/Textarea/Textarea';

import inputStyles from '../../styles/inputs.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import customSelectStyles, { selectErrorStyles, selectActiveStyles } from '../../styles/select_styles';
import styles from '../../styles/create_form.module.scss';
import getNestedValue from '../../utils/getNestedValue';

const PLACEHOLDER = {
  title: 'Teacher name',
  location: 'Main location',
  address: 'Address',
};

const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

const INITIAL_STATE = {
  title: { ...INITIAL_STATE_FIELD },
  photos: [],
  description: { ...INITIAL_STATE_FIELD },
  selectedStyles: {
    value: [],
    isValid: true,
    isActive: false,
  },
  location: {
    ...INITIAL_STATE_FIELD,
    venueList: [],
    isVenueListShown: false,
    venueId: null,
  },
  address: {
    ...INITIAL_STATE_FIELD,
    isAddressShown: false,
  },
  coordinates: null,
  previewView: false,
  teacher: null,
  photosError: false,
  photosToRemove: [],
  isLoading: false,
};

class TeacherForm extends Component {
  static propTypes = {
    createTeacher: PropTypes.func.isRequired,
    getDanceStyles: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    danceStyles: PropTypes.arrayOf(PropTypes.string),
    addPhotos: PropTypes.func.isRequired,
    onTeacherSubmitSuccess: PropTypes.func.isRequired,
    editTeacher: PropTypes.func.isRequired,
    match: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
      nickname: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      profession: PropTypes.string,
    }).isRequired,
    getTeacherDetails: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    danceStyles: [],
  };

  state = { ...INITIAL_STATE };

  async componentDidMount() {
    const {
      getDanceStyles, history, match,
      handleError, getTeacherDetails,
    } = this.props;
    getDanceStyles();

    const idToEdit = match && match.params && match.params.id;
    let teacherToEdit;

    if (idToEdit) {
      const teacherResponse = await getTeacherDetails({ id: idToEdit })
        .catch((err) => {
          const errMessage = err && err.response && err.response.data.error;
          handleError(errMessage);
          history.push('/');
        });
      teacherToEdit = teacherResponse && teacherResponse.data && teacherResponse.data.data;
    }
    if (!teacherToEdit && history.location.pathname.indexOf('/account/edit_teacher/') !== -1) history.push('/');
    if (teacherToEdit && history.location.pathname !== '/create_teacher') {
      const editPermission = getNestedValue(teacherToEdit, 'permissions', 'update');

      if (editPermission) {
        this.setState({
          ...INITIAL_STATE,
          title: {
            isActive: true,
            isValid: true,
            value: teacherToEdit.title,
          },
          description: {
            isActive: true,
            isValid: true,
            value: teacherToEdit.description,
          },
          selectedStyles: {
            isActive: !!teacherToEdit.dance_styles,
            isValid: !!teacherToEdit.dance_styles,
            value: teacherToEdit.dance_styles
              ? makeSelectOptions(teacherToEdit.dance_styles) : [],
          },
          address: {
            isActive: true,
            isValid: true,
            value: teacherToEdit.address,
            isAddressShown: !teacherToEdit.venue_id,
          },
          location: {
            isActive: true,
            isValid: true,
            value: teacherToEdit.location,
            isVenueListShown: false,
            venueId: teacherToEdit.venue_id,
            venueList: [],
          },
          coordinates: { ...teacherToEdit.coordinates },
          photos: teacherToEdit.photos,
        });
      } else { history.push('/'); }
    }
  }

  setErrors = (errors) => {
    const { handleError } = this.props;
    let errorString = '';
    Object.keys(errors).forEach((item) => {
      const errorText = errors[item].join(', ');
      errorString = errorString.length > 0
        ? `${errorString}; ${item}: ${errorText}`
        : `${item}: ${errorText}`;
      if (item === 'dance_styles') {
        this.setState(prevState => ({
          ...prevState,
          selectedStyles: {
            ...prevState.selectedStyles,
            isValid: false,
            error: errorText,
          },
        }));
      } else if (item === 'coordinates') {
        this.setState(prevState => ({
          ...prevState,
          coordinates: null,
          address: {
            ...prevState.address,
            isValid: false,
            error: 'Select address from the list or map',
          },
        }));
      } else if (item.indexOf('photo') > 0) {
        this.setState({ photosError: true });
      } else if (this.state[item]) {
        this.setState(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isValid: false,
            error: errorText,
          },
        }));
      }
    });
    if (errorString.length > 0) handleError(errorString);
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = (name === 'title') ? validateTitle(value) : validateText(value);
    this.setState({
      [name]: {
        isActive: true,
        isValid,
        value,
        error: null,
      },
    });
  };

  handleSelect = (value, field) => {
    this.setState(prevState => ({
      [field]: {
        ...prevState[field],
        value,
        isValid: true,
        isActive: true,
        error: null,
      },
    }));
  }

  handleSelectStyles = value => this.handleSelect(value, 'selectedStyles');

  handlePhotoSelect = photos => this.setState({ photos });

  activateAllFields = () => {
    this.activateField('title');
    this.activateField('description');
    this.activateField('location');
    this.activateField('address');
  }

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  validateStyles = () => {
    const { selectedStyles } = this.state;
    if (selectedStyles.value.length === 0) {
      this.setState(prevState => ({
        selectedStyles: {
          ...prevState.selectedStyles,
          isValid: false,
          isActive: false,
        },
      }));
    } else {
      this.setState(prevState => ({
        selectedStyles: {
          ...prevState.selectedStyles,
          isValid: true,
        },
      }));
    }
  }

  validateCoords = () => {
    const { coordinates } = this.state;
    if (!coordinates) {
      this.setState(prevState => ({
        address: {
          ...prevState.address,
          isValid: false,
          error: 'Select address from the list or map',
        },
      }));
      return false;
    }

    return true;
  }

  isValidationPassed = () => {
    const {
      title,
      description,
      selectedStyles,
      location,
      address,
    } = this.state;

    const validTextInputs = title.isValid && description.isValid
      && location.isValid && address.isValid;
    const validStyles = selectedStyles.value.length > 0 && selectedStyles.isValid;
    const validCoords = this.validateCoords();
    return (validTextInputs && validStyles && validCoords);
  };

  validateForm = () => {
    this.activateAllFields();
    this.validateStyles();
    if (!this.isValidationPassed()) return false;

    return true;
  }

  composeTeacher = () => {
    const {
      title,
      description,
      selectedStyles,
      photos,
      location,
      address,
      coordinates,
    } = this.state;

    const danceStyles = getValueFromOptions(selectedStyles.value);
    const teacher = {
      title: title.value,
      description: description.value,
      dance_styles: danceStyles,
      photos,
      location: location.value,
      address: address.value,
      coordinates,
    };
    this.setState({ teacher });

    return { ...teacher };
  }


  checkVenueId = (teacher) => {
    const teacherForRequest = teacher;
    if (this.state.location.venueId) {
      delete teacherForRequest.location;
      delete teacherForRequest.coordinates;
      delete teacherForRequest.address;
      teacherForRequest.venue_id = this.state.location.venueId;
    } else teacherForRequest.venue_id = '';

    return teacherForRequest;
  }

  handleCreateTeacher = (e) => {
    e.preventDefault();
    const { handleError, user, openModal } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }

    if (!checkUserCreatAbilyti(user)) {
      openModal('PROFILE_MODAL');
      return;
    }

    if (this.state.isLoading) return;
    let teacherForRequest = this.composeTeacher();
    delete teacherForRequest.photos;
    const { createTeacher, onTeacherSubmitSuccess, history } = this.props;
    const { photos } = this.state;

    teacherForRequest = this.checkVenueId(teacherForRequest);

    this.setState({ isLoading: true });

    createTeacher(teacherForRequest)
      .then((data) => {
        if (data && data.id) {
          if (photos.length === 0) {
            onTeacherSubmitSuccess({
              uploadPhotos: true,
              action: 'create',
              subscription: data.subscription,
            });
            history.push(`/teachers/${data.id}`);
          } else {
            const teacherFormData = new FormData();
            photos.forEach((photo, index) => {
              teacherFormData.append(`data[photos][${index}][file]`, photo.file, photo.file.name);
              teacherFormData.append(`data[photos][${index}][sort]`, index);
            });
            this.handleUploadPhotos({
              id: data.id,
              teacherFormData,
              action: 'create',
              subscription: data.subscription,
            });
          }
        } else if (data && data.errors) {
          this.setErrors(data.errors);
          this.setState({
            previewView: false,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleEditTeacher = (e) => {
    e.preventDefault();
    const { handleError } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }
    if (this.state.isLoading) return;
    let teacher = this.composeTeacher();
    delete teacher.photos;

    const {
      token,
      editTeacher,
      onTeacherSubmitSuccess,
      match,
      history,
    } = this.props;
    const { photos, photosToRemove } = this.state;
    teacher.id = match && match.params && match.params.id;

    teacher = this.checkVenueId(teacher);

    this.setState({ isLoading: true });
    editTeacher(teacher, token)
      .then((data) => {
        if (data && data.id) {
          const teacherFormData = new FormData();
          let photosWereModified;
          photos.forEach((photo, index) => {
            if (photo.file) {
              teacherFormData.append(`data[photos][${index}][file]`, photo.file, photo.file.name);
              teacherFormData.append(`data[photos][${index}][sort]`, index);
              photosWereModified = true;
            }
            if (photo.id && index !== photo.sort) {
              teacherFormData.append(`data[photos][${index}][id]`, photo.id);
              teacherFormData.append(`data[photos][${index}][sort]`, index);
              photosWereModified = true;
            }
          });
          photosToRemove.forEach((photoId, index) => {
            teacherFormData.append(`data[photos][${photos.length + index}][id]`, photoId);
            teacherFormData.append(`data[photos][${photos.length + index}][to_remove]`, true);
            photosWereModified = true;
          });
          if (photosWereModified) {
            this.handleUploadPhotos({
              id: data.id,
              teacherFormData,
              action: 'edit',
              subscription: data.subscription,
            });
          } else {
            history.push(`/teachers/${data.id}`);
            onTeacherSubmitSuccess(
              {
                uploadPhotos: true,
                action: 'edit',
                id: data.id,
                subscription: data.subscription,
              },
            );
          }
          return;
        }
        if (data && data.errors) {
          this.setErrors(data.errors);
          this.setState({
            previewView: false,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleUploadPhotos = ({ id, teacherFormData, action, subscription }) => {
    const { addPhotos, history, onTeacherSubmitSuccess } = this.props;
    addPhotos(id, teacherFormData)
      .then((photoData) => {
        if (photoData && photoData.id) {
          history.push(`/teachers/${id}`);
          onTeacherSubmitSuccess(
            {
              uploadPhotos: true,
              action,
              subscription,
            },
          );
        } else if (photoData && (photoData.errors || photoData.error)) {
          this.setErrors(photoData.errors || photoData.error);
          this.setState({
            previewView: false,
            isLoading: false,
          });
          onTeacherSubmitSuccess({ action, subscription });
        } else {
          history.push(`/teachers/${id}`);
          onTeacherSubmitSuccess({ action, subscription });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleCloseForm = () => {
    if (this.state.isLoading) return;
    this.setState({ ...INITIAL_STATE });

    const { history, location } = this.props;
    const from = location.state && location.state.from;
    if ((from && from.indexOf('/teachers/') === -1 && from.indexOf('/account') === -1) || !from) {
      history.push('/');
      return;
    }
    const list = location.state && location.state.list;
    history.push({
      pathname: from,
      state: { list },
    });
  }

  handleOpenPreview = () => {
    const { handleError } = this.props;
    if (!this.validateForm()) {
      handleError('Please complete all fields');
      return;
    }
    if (this.state.isLoading) return;
    const teacher = this.composeTeacher();
    if (!teacher) return;
    this.setState({ previewView: true });
  }

  handleClosePreview = () => {
    if (this.state.isLoading) return;
    this.setState({ previewView: false });
  }

  handleRemovePhoto = (idToDelete) => {
    this.setState(prevState => ({
      ...prevState,
      photosToRemove: idToDelete
        ? [...prevState.photosToRemove, idToDelete]
        : [...prevState.photosToRemove],
    }));
  }

  preventSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  renderInput = (inputName) => {
    const valueFromState = this.state[inputName];
    let fieldStyle;
    if (!valueFromState.isActive) fieldStyle = `${inputStyles.input_box_create} ${inputStyles.not_active}`;
    else fieldStyle = valueFromState.isValid ? inputStyles.input_box_create : `${inputStyles.input_box_create} ${inputStyles.is_error}`;
    return (
      <div className={fieldStyle}>
        <input
          className={inputStyles.input}
          type="text"
          name={inputName}
          value={valueFromState.value}
          onChange={this.handleInputChange}
        />
        <label
          htmlFor={inputName}
          className={valueFromState.value ? inputStyles.label__with_value : inputStyles.label}
        >
          {valueFromState.error ? valueFromState.error : PLACEHOLDER[inputName]}
        </label>
      </div>
    );
  };

  render() {
    const { danceStyles, match } = this.props;
    const danceStylesError = danceStyles.length === 0;

    const {
      description,
      selectedStyles: {
        value: selectedStyles,
        isValid: isValidStyles,
        isActive: isActiveStyles,
        error: errorStyles,
      },
      photos,
      previewView,
      teacher,
      photosError,
    } = this.state;
    const options = makeSelectOptions(danceStyles);

    let selectStyles = isValidStyles ? customSelectStyles : selectErrorStyles;
    if (isActiveStyles) selectStyles = selectActiveStyles;
    const isEditing = match.path.indexOf('edit_teacher') !== -1;

    return (
      <div className={`${gridStyles.mobile_on_top} ${gridStyles.wrapper_fixed_bottom}`}>
        {!danceStylesError && (
          <form onSubmit={this.preventSubmit}>
            {previewView ? <TeacherDetails teacherForPreview={teacher} previewFromForm /> : (
              <div className={gridStyles.scroll_container}>
                <h3 className={styles.title}>{isEditing ? 'Edit Teacher' : 'Create Teacher'}</h3>
                <PhotoManager
                  onPhotoUpdate={this.handlePhotoSelect}
                  onRemovePhoto={this.handleRemovePhoto}
                  photos={photos}
                />
                {photosError && (
                  <div className={styles.error}>
                      Teacher was updated, but something went wrong with photos ...
                  </div>
                )}
                {this.renderInput('title')}
                <TextArea
                  handleInputChange={this.handleInputChange}
                  stateObject={description}
                />
                <Select
                  value={selectedStyles}
                  onChange={this.handleSelectStyles}
                  options={options}
                  isMulti
                  closeMenuOnSelect={false}
                  className={inputStyles.safari_input}
                  styles={selectStyles}
                  placeholder="Dance styles"
                />
                <VenueSelector
                  componentSetState={(prevState) => { this.setState(prevState); }}
                  location={this.state.location}
                  address={this.state.address}
                />
                {errorStyles && <div className={styles.error}>{errorStyles}</div>}
              </div>
            )}
            <div className={buttonStyles.fixed_buttons}>
              {isEditing
                ? (
                  <button
                    type="button"
                    className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                    onClick={this.handleEditTeacher}
                  >
                    save
                  </button>
                )
                : (
                  <button
                    type="button"
                    className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase} ${buttonStyles.btn_wide}`}
                    onClick={this.handleCreateTeacher}
                    data-test="create_button"
                  >
                    create
                  </button>
                )
              }
              <div className={buttonStyles.buttons_row}>
                <button
                  type="button"
                  className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                  onClick={previewView
                    ? this.handleClosePreview
                    : this.handleOpenPreview}
                >
                  {previewView ? 'edit' : 'preview'}
                </button>
                <button
                  type="button"
                  className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                  onClick={this.handleCloseForm}
                >
                  cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default TeacherForm;
