import React from 'react';
import PropTypes from 'prop-types';

const Calendar = ({ className }) => (
  className
    ? (
      <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 27 28">
        <path fillRule="evenodd" d="M24.345 27.247H2.429a2.425 2.425 0 0 1-2.426-2.425V5.833a2.425 2.425 0 0 1 2.426-2.425h.002a.965.965 0 1 1 0 1.93h-.498v19.979H24.84V5.338h-.495a.963.963 0 0 1-.963-.962v-.005c0-.532.431-.963.963-.963a2.426 2.426 0 0 1 2.426 2.425v18.989a2.426 2.426 0 0 1-2.426 2.425zm-4.384-9.995a1.193 1.193 0 1 1 .003-2.385 1.193 1.193 0 0 1-.003 2.385zm0-4.383a1.192 1.192 0 0 1 0-2.386c.659 0 1.196.536 1.196 1.195 0 .657-.537 1.191-1.196 1.191zm0-7.531a.964.964 0 0 1-.963-.962V1.455a.966.966 0 1 1 1.931 0v2.921c0 .53-.435.962-.968.962zm-4.38 11.914a1.195 1.195 0 0 1-1.195-1.191 1.194 1.194 0 1 1 1.195 1.191zm0-4.383a1.194 1.194 0 1 1 0-2.386 1.194 1.194 0 0 1 0 2.386zm-.002-7.531h-4.384a.966.966 0 1 1 0-1.93h4.384a.965.965 0 1 1 0 1.93zm-4.382 5.145a1.194 1.194 0 0 1 0 2.386 1.194 1.194 0 1 1 0-2.386zm0 8.766a1.192 1.192 0 1 1-.002 2.384 1.192 1.192 0 0 1 .002-2.384zm0-4.383a1.194 1.194 0 0 1 0 2.386 1.195 1.195 0 0 1-1.195-1.191c0-.659.536-1.195 1.195-1.195zm-4.384 6.768a1.196 1.196 0 0 1-1.195-1.195c0-.656.536-1.19 1.195-1.19s1.195.534 1.195 1.19c0 .659-.536 1.195-1.195 1.195zm0-4.382a1.195 1.195 0 0 1-1.195-1.191c0-.659.536-1.195 1.195-1.195s1.195.536 1.195 1.195c0 .656-.536 1.191-1.195 1.191zm0-4.383a1.194 1.194 0 1 1-.002-2.388 1.194 1.194 0 0 1 .002 2.388zm0-7.531a.966.966 0 0 1-.968-.962V1.455a.969.969 0 0 1 1.935 0v2.921c0 .53-.434.962-.967.962z" />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28">
        <path fill="#A1ADC4" fillRule="evenodd" d="M24.345 27.247H2.429a2.425 2.425 0 0 1-2.426-2.425V5.833a2.425 2.425 0 0 1 2.426-2.425h.002a.965.965 0 1 1 0 1.93h-.498v19.979H24.84V5.338h-.495a.963.963 0 0 1-.963-.962v-.005c0-.532.431-.963.963-.963a2.426 2.426 0 0 1 2.426 2.425v18.989a2.426 2.426 0 0 1-2.426 2.425zm-4.384-9.995a1.193 1.193 0 1 1 .003-2.385 1.193 1.193 0 0 1-.003 2.385zm0-4.383a1.192 1.192 0 0 1 0-2.386c.659 0 1.196.536 1.196 1.195 0 .657-.537 1.191-1.196 1.191zm0-7.531a.964.964 0 0 1-.963-.962V1.455a.966.966 0 1 1 1.931 0v2.921c0 .53-.435.962-.968.962zm-4.38 11.914a1.195 1.195 0 0 1-1.195-1.191 1.194 1.194 0 1 1 1.195 1.191zm0-4.383a1.194 1.194 0 1 1 0-2.386 1.194 1.194 0 0 1 0 2.386zm-.002-7.531h-4.384a.966.966 0 1 1 0-1.93h4.384a.965.965 0 1 1 0 1.93zm-4.382 5.145a1.194 1.194 0 0 1 0 2.386 1.194 1.194 0 1 1 0-2.386zm0 8.766a1.192 1.192 0 1 1-.002 2.384 1.192 1.192 0 0 1 .002-2.384zm0-4.383a1.194 1.194 0 0 1 0 2.386 1.195 1.195 0 0 1-1.195-1.191c0-.659.536-1.195 1.195-1.195zm-4.384 6.768a1.196 1.196 0 0 1-1.195-1.195c0-.656.536-1.19 1.195-1.19s1.195.534 1.195 1.19c0 .659-.536 1.195-1.195 1.195zm0-4.382a1.195 1.195 0 0 1-1.195-1.191c0-.659.536-1.195 1.195-1.195s1.195.536 1.195 1.195c0 .656-.536 1.191-1.195 1.191zm0-4.383a1.194 1.194 0 1 1-.002-2.388 1.194 1.194 0 0 1 .002 2.388zm0-7.531a.966.966 0 0 1-.968-.962V1.455a.969.969 0 0 1 1.935 0v2.921c0 .53-.434.962-.967.962z" />
      </svg>
    )
);

Calendar.propTypes = {
  className: PropTypes.string,
};

Calendar.defaultProps = {
  className: '',
};

export default Calendar;
