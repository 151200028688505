import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import { formatDateForItemCard, getCorrectDate } from '../../../utils/formatDate';
import { capitalizeStr, setItemUrl } from '../../../utils/parse';
import { getRecurrentString } from '../../../utils/getRecurrentString';
import ClockIcon from '../../icons/Clock';
import CalendarIcon from '../../icons/Calendar_thin';
import PinIcon from '../../icons/Pin';
import styles from './item_card.module.scss';
import buttonStyles from '../../../styles/buttons.module.scss';

import {
  EVENT,
  DAILY, WEEKLY, BI_WEEKLY, MONTHLY, BI_WEEKLY_LABEL,
  WEEK,
} from '../../../config';

const TEXT_EMPTY = '...';

const ItemCard = ({ item, history, venueView, isActive }) => {
  const {
    title,
    location,
    address,
    start_date: startDate,
    end_date: endDate,
    type,
    recurrent,
    permissions,
  } = item;

  const isSellable = permissions && !!permissions.buy_tickets;


  const handleItemClick = () => {
    const pathname = setItemUrl(item);
    const queryObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const search = qs.stringify({ ...queryObject, day: undefined });
    const to = {
      pathname,
      state: { from: history.location.pathname },
      search,
    };
    history.push(to);
  };

  const handleBook = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isSellable) return;

    const pathname = setItemUrl(item);

    const to = {
      pathname,
      state: { from: history.location.pathname },
      search: '?tickets_open=true',
    };
    history.push(to);
  };

  const time = (startDate && endDate)
    ? `${getCorrectDate(startDate).format('h:mmA')} - ${getCorrectDate(endDate).format('h:mmA')}`
    : TEXT_EMPTY;

  const date = (startDate && endDate)
    ? formatDateForItemCard(startDate, endDate)
    : TEXT_EMPTY;

  const freq = recurrent && recurrent.freq;
  const isWeekViewShown = freq
    && (freq === WEEKLY || freq === BI_WEEKLY || freq === DAILY);
  const selectedDays = (isWeekViewShown && freq !== DAILY && recurrent.byday)
    ? recurrent.byday.map(day => capitalizeStr(day))
    : null;
  const isMonthlyViewShowm = freq && freq === MONTHLY;
  const monthlyOption = isMonthlyViewShowm ? getRecurrentString(recurrent) : TEXT_EMPTY;

  let bookButtonStyle = `${buttonStyles.card_btn} ${buttonStyles.btn_uppercase}`;
  if (!isSellable) bookButtonStyle += ` ${buttonStyles.btn_disabled}`;

  let buttonText = 'Book';
  if (type && type === EVENT) {
    buttonText = 'Ticket';
  } else if (!isActive) {
    buttonText = 'View';
  }

  return (
    <li>
      <div
        className={styles.card}
        role="button"
        tabIndex={0}
        onClick={handleItemClick}
        onKeyDown={handleItemClick}
      >
        <div className={styles.card_header}>
          <p className={styles.address}>{date}</p>
          <h6 className={styles.title}>{title}</h6>
        </div>
        {venueView ? (
          <div>

            <div className={styles.recurrent_column}>
              <div className={styles.btn_box}>
                <button
                  type="button"
                  className={bookButtonStyle}
                  onClick={handleBook}
                >
                  {buttonText}
                </button>
              </div>
              <div className={`${styles.info} ${styles.no_wrap}`}>
                <ClockIcon className={styles.info_icon} />
                <time className={styles.time}>{time}</time>
              </div>
              {isWeekViewShown && (
                <div className={styles.info}>
                  <CalendarIcon className={styles.info_icon} />
                  <span className={styles.recurrent_label}>
                    {freq === BI_WEEKLY ? `${BI_WEEKLY_LABEL}` : `${capitalizeStr(freq)}`}
                  </span>
                  &nbsp;
                  <div>
                    {WEEK.map((day) => {
                      const isDaySelected = freq === DAILY
                        || (selectedDays && selectedDays.indexOf(day) !== -1);
                      return (
                        <span
                          key={day}
                          className={isDaySelected
                            ? styles.day_selected
                            : styles.day}
                        >
                          {day}
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              {isMonthlyViewShowm && (
                <div className={styles.info}>
                  <CalendarIcon className={styles.info_icon} />
                  <span className={styles.recurrent_label}>
                    {capitalizeStr(freq)}
                  </span>
                  &nbsp;
                  <span className={styles.grey_text}>
                    {monthlyOption}
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.btn_box}>
              <button
                type="button"
                className={bookButtonStyle}
                onClick={handleBook}
              >
                {buttonText}
              </button>
            </div>
            <div className={styles.info}>
              <PinIcon className={styles.info_icon} />
              <div className={styles.location_box}>
                <p className={styles.location}>{location}</p>
                <p className={styles.address}>{address}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

ItemCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    time: PropTypes.object,
    location: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  venueView: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
};

ItemCard.defaultProps = {
  history: null,
  venueView: false,
};

export default ItemCard;
