import React from 'react';
import PropTypes from 'prop-types';

const Contact = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xmlSpace="preserve" viewBox="0 0 62 61">
    <path d="M60.657 24.758l.001-.001-.003-.004a7.834 7.834 0 0 0-2.207-3.294l-1.872-1.656a7.538 7.538 0 0 0-.322-.27L32.264.507a2.33 2.33 0 0 0-2.916.009L5.578 19.632l-2.05 1.829a7.836 7.836 0 0 0-2.19 3.293l-.005.007.002.001a7.836 7.836 0 0 0-.428 2.548v26.675a6.682 6.682 0 0 0 6.682 6.682h46.822a6.682 6.682 0 0 0 6.682-6.682V27.33a7.84 7.84 0 0 0-.436-2.572zm-4.329 3.481c.059.387.092.781.092 1.178v23.686L42.598 39.281l13.73-11.042zM29.736 47.287a2.33 2.33 0 0 0 2.916-.009l6.282-5.052 13.769 13.768H9.359l13.869-13.869 6.508 5.162zM8.507 23.271L30.821 5.328l22.626 17.944.005.004c.266.211.515.439.748.679l-.706.569-22.315 17.943L8.553 24.523l-.758-.601a7.73 7.73 0 0 1 .712-.651zm-2.836 4.932l13.871 11L5.58 53.166V29.38c0-.397.032-.79.091-1.177z" />
    <path d="M24.431 34.04c-1.643-1.569-2.464-3.905-2.464-6.632 0-2.815.929-5.201 2.788-7.157 1.859-1.957 4.19-2.935 6.996-2.935 2.619 0 4.659.733 6.12 2.199 1.461 1.466 2.192 3.347 2.192 5.642 0 1.677-.255 3.036-.765 4.075-.51 1.04-1.157 1.559-1.942 1.559-.746 0-1.118-.686-1.118-2.06 0-1.285.221-4.016.662-8.195h-3.31l-.147 1.456h-.059c-.373-1.148-1.29-1.721-2.751-1.721-1.618 0-3.001.704-4.149 2.111-1.148 1.408-1.721 3.234-1.721 5.48 0 1.736.414 3.095 1.243 4.075.829.981 1.92 1.471 3.273 1.471 1.805 0 3.05-.937 3.737-2.81h.088c.049.834.377 1.511.986 2.03.608.52 1.398.78 2.369.78 1.971 0 3.624-.767 4.958-2.302 1.334-1.535 2.001-3.573 2.001-6.113 0-3.09-1.091-5.578-3.274-7.466-2.182-1.888-5.039-2.832-8.57-2.832-3.815 0-6.934 1.216-9.357 3.649-2.423 2.433-3.634 5.434-3.634 9.004 0 .844.068 1.645.199 2.406l5.649 4.286zm7.937-4.705c-.53.991-1.256 1.486-2.177 1.486-.598 0-1.086-.262-1.464-.787-.378-.525-.566-1.258-.566-2.199 0-1.324.277-2.489.831-3.494.554-1.005 1.273-1.508 2.155-1.508.608 0 1.096.226 1.464.677.368.451.552 1.069.552 1.854 0 1.656-.265 2.98-.795 3.971z" />
  </svg>
);

Contact.propTypes = {
  className: PropTypes.string,
};

Contact.defaultProps = {
  className: '',
};

export default Contact;
