import React from 'react';
import PropTypes from 'prop-types';

import QRIcon from '../icons/QR';
import QRIconDone from '../icons/QR_done';
import getUserName from '../../utils/getUserName';
import defaultAvatar from '../../images/default_avatar.jpg';

import styles from './check-in.module.scss';

const CheckinItem = ({ item }) => {
  const userAvatar = item && item.buyer && item.buyer.avatar && item.buyer.avatar.thumb
    ? item.buyer.avatar.thumb
    : defaultAvatar;

  return (
    <li className={styles.item}>
      <div className={styles.avatar} style={{ backgroundImage: `url(${userAvatar})` }} />
      <div className={styles.name}>{getUserName(item && item.buyer)}</div>
      {item.consumed
        ? <QRIconDone className={styles.qr_done} />
        : <QRIcon className={styles.qr} />
        }
    </li>
  );
};

CheckinItem.propTypes = {
  item: PropTypes.shape({
    avatar: PropTypes.object,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    nickname: PropTypes.string,
  }).isRequired,
};

export default CheckinItem;
