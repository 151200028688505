import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import Logo from '../icons/Logo';
import BurgerMenu from '../Burger_menu/burger_menu';
import LoginIcon from '../icons/Login_icon';
import UserMenu from '../User_menu/User_menu';
import SearchIcon from '../icons/Search_icon';
import LensIcon from '../icons/Lens_icon';
import CreateMenu from '../Create_menu/Create_menu';
import styles from './header.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

class Header extends Component {
  static propTypes = {
    isAuth: PropTypes.bool,
    openModal: PropTypes.func,
    signOut: PropTypes.func,
    user: PropTypes.shape({
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
    }),
    getUser: PropTypes.func.isRequired,
    token: PropTypes.string,
    getNotificationsCount: PropTypes.func.isRequired,
    notificationsCount: PropTypes.number,
  }

  static defaultProps = {
    isAuth: false,
    openModal: () => {},
    signOut: () => {},
    user: null,
    token: '',
    notificationsCount: 0,
  };

  componentDidMount() {
    const {
      getUser,
      user,
      token,
      isAuth,
    } = this.props;
    if (isAuth && user && user.id && token) getUser({ id: user.id, token });
  }

  render() {
    const {
      isAuth,
      openModal,
    } = this.props;

    return (
      <header className={styles.header}>
        <div className={styles.left_part}>
          <BurgerMenu />
          <div className={styles.logo_box}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <MediaQuery minWidth={768}>
            <Link
              to="/search"
              className={styles.search}
            >
              Search
              <figure className={styles.search_icon_box}>
                <SearchIcon className={styles.search_icon} />
              </figure>
            </Link>
          </MediaQuery>
        </div>
        <div className={styles.right_part}>
          <MediaQuery maxWidth={767}>
            <Link
              to="/search"
              className={styles.search_mobile}
            >
              <LensIcon />
            </Link>
          </MediaQuery>
          {/* temporary hide apparel button */}
          {/* <MediaQuery minWidth={1400}>
            <a
              href="https://dancity.shop/"
              rel="noopener noreferrer"
              className={styles.header_link}
              target="_blank"
            >
              Apparel
            </a>
            <Link to="/my_tickets" className={styles.header_link}>My Tickets</Link>
          </MediaQuery> */}
          <CreateMenu />
          {isAuth ? (
            <UserMenu {...this.props} />
          ) : (
            <button
              className={`${buttonStyles.button} ${buttonStyles.sign_in_button}`}
              type="button"
              onClick={() => openModal('SIGN_IN_MODAL')}
            >
              <MediaQuery minWidth={1025}>
                <span>Sign in </span>
              </MediaQuery>
              <LoginIcon />
            </button>
          )}
        </div>
      </header>
    );
  }
}

export default Header;
