import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import CommentsBlock from '../Comments_block/comments_container';
import ReadMore from '../shared/Read_more/Read_more';
import BottomBar from '../shared/Bottom_bar/Bottom_bar';
import ListWithDateFilter from '../shared/List_with_date_filter/list_with_date_filter_container';
import VenueInfo from '../shared/Venue_info/Venue_info';
import SubscriptionReminder from '../shared/Subscription_reminder/Subscription_reminder';
import InactiveBanner from '../shared/Inactive_banner/Inactive_banner';
import PhotosBlock from '../shared/Photos_block/Photos_block';
import BreakLineText from '../shared/Break_line_text/BreakLineText';
import FriendsInterestedRow from '../Friends/Friends_interested_row/Friends_interested_row';
import FriendsInterestedSection from '../Friends/Friends_interested_section/Friends_interested_container';

import detectSafari from '../../utils/detectSafari';
import getNestedValue from '../../utils/getNestedValue';
import scrollToItemTop, { scrollToElement } from '../../utils/scrollToItem';

import PinIcon from '../icons/Pin';
import InfoIcon from '../icons/Info';
import CancelIcon from '../icons/Cancel_icon';

import styles from '../../styles/view_details.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

import {
  TEACHERS, TEACHER, ACTIVE, INACTIVE,
  PAID, FREE, UNPAID, CANCELED, EXPIRED,
} from '../../config';

const TEXT_EMPTY = '...';

class TeacherDetails extends Component {
  static propTypes = {
    getTeacherDetails: PropTypes.func.isRequired,
    toggleFavorite: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.string,
      }),
    }),
    error: PropTypes.string,
    teacher: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      favorite: PropTypes.bool,
      status: PropTypes.oneOf([ACTIVE, INACTIVE]),
      subscription: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.oneOf([PAID, FREE, UNPAID, CANCELED, EXPIRED, INACTIVE]),
      }),
    }).isRequired,
    previewFromForm: PropTypes.bool,
    teacherForPreview: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    token: PropTypes.string,
    setFSphotos: PropTypes.func.isRequired,
    userLogged: PropTypes.shape({
      id: PropTypes.string,
    }),
    resetMarker: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    error: null,
    previewFromForm: false,
    teacherForPreview: {},
    history: {},
    match: {},
    location: null,
    token: '',
    userLogged: null,
  };

  constructor(props) {
    super(props);
    this.wrapperRef = createRef();
    this.commentsRef = createRef();
    this.state = {
      isFriendsInterestedOpen: false,
    };
  }

  componentDidMount() {
    const { getTeacherDetails, match, previewFromForm, history } = this.props;
    if (previewFromForm) return;
    getTeacherDetails({
      id: match.params.id,
      moveMap: true,
      filter: history.location.search,
    }).then(() => {
      this.scrollToTop();

      const { hash } = history.location;
      if (window.innerWidth < 768) {
        if (hash) {
          scrollToElement(hash.substring(1));
        } else {
          this.scrollToTitle(1000);
        }
      } else if (hash) {
        this.scrollToComment();
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { previewFromForm, getTeacherDetails, history, isModalOpen } = this.props;
    if (previewFromForm) return;

    const shouldRequest = (this.props.match.params.id !== prevProps.match.params.id)
      || (this.props.token !== prevProps.token)
      || (prevProps.isModalOpen === true && isModalOpen === false);

    if (shouldRequest) {
      getTeacherDetails({
        id: this.props.match.params.id,
        moveMap: true,
        filter: history.location.search,
      });
      this.scrollToTop();
    }
  }

  componentWillUnmount() {
    const { resetMarker } = this.props;
    resetMarker();
  }

  scrollToTop = () => {
    if (this.wrapperRef.current) this.wrapperRef.current.scrollTop = 0;
    window.scrollTo(0, 0);
  }

  scrollToComment = () => {
    if (this.commentsRef.current && this.wrapperRef.current) {
      this.wrapperRef.current.scrollTop = this.commentsRef.current.offsetTop;
      window.scrollTo(0, (this.commentsRef.current.offsetTop + window.innerHeight / 2));
    }
  }

  scrollToTitle = (delay) => {
    const wrapper = this.wrapperRef && this.wrapperRef.current;
    scrollToItemTop({ wrapper, delay });
  }

  close = () => {
    const { history, location } = this.props;
    const from = location.state && location.state.from;
    const query = location && location.state && location.state.query;
    if ((from && from.indexOf('/teachers/') !== -1) || !from) {
      history.push({
        pathname: '/teachers',
        search: location.search,
        state: { query },
      });
      return;
    }
    const list = location.state && location.state.list;
    history.push({
      pathname: from,
      state: {
        list,
        query,
      },
      search: location.search,
    });
  }

  handleToggleFavorite = (e) => {
    e.stopPropagation();
    const wasLiked = this.props.teacher.favorite;
    const { toggleFavorite, teacher, history } = this.props;
    const { id, type } = teacher;

    toggleFavorite({
      id,
      willbeLiked: !wasLiked,
      list: TEACHERS,
      filter: history.location.search,
      type,
    });
  }

  handleOpenFullScreen = () => {
    const { setFSphotos, teacher, openModal } = this.props;
    const photos = teacher && teacher.photos;

    setFSphotos(photos);
    openModal('FULL_SCREEN_PHOTOS_MODAL');
  }

  handleOpenFriends = () => {
    this.setState({ isFriendsInterestedOpen: true });
  }

  handleCloseFriends = () => {
    this.setState({ isFriendsInterestedOpen: false });
  }

  pushToCreatePass = () => {
    const { history, teacher } = this.props;
    const to = {
      pathname: `/teachers/${teacher.id}/create_pass`,
      state: { from: history.location.pathname },
    };
    history.push(to);
  }

  render() {
    const {
      error,
      teacher,
      previewFromForm,
      teacherForPreview,
      userLogged,
      history,
    } = this.props;
    const { isFriendsInterestedOpen } = this.state;

    const {
      title,
      description,
      dance_styles: danceStyles,
      location,
      address,
      comments,
      photos,
      favorite,
      id: teacherId,
      lessons,
      venue_id: venueId,
      subscription,
      status,
      interested_friends: interestedFriends,
      interested_friends_count: totalFriendsInterested,
      permissions,
    } = previewFromForm ? teacherForPreview : teacher;
    let fanciersNumber = teacher.fanciers_number ? teacher.fanciers_number : 0;
    if (error) {
      return <div className={`${gridStyles.side_bar} ${styles.container}`}>Sorry, teacher not found</div>;
    }

    fanciersNumber = fanciersNumber < 1000
      ? fanciersNumber
      : `${Math.floor(fanciersNumber / 100) / 10}k`;

    const isViewedByOwner = userLogged && teacher.user && teacher.user.id
      && userLogged.id === teacher.user.id;
    const isTeacherInactive = status === INACTIVE;
    const isTeacherEditable = !previewFromForm && permissions && permissions.update
      && teacherId && !isTeacherInactive;
    const isInactiveBannerShown = isTeacherInactive && !isViewedByOwner;

    const teacherHasLessons = lessons && lessons.length > 0;

    const subscriptionStatus = getNestedValue(subscription, 'status');
    const subscriptionId = getNestedValue(subscription, 'id');
    const isReminderShown = isViewedByOwner && subscription && subscriptionId && subscriptionStatus
      && subscriptionStatus !== PAID && subscriptionStatus !== FREE;

    let containerStyles = previewFromForm
      ? gridStyles.scroll_container
      : gridStyles.scroll_container_big;
    if (isReminderShown) containerStyles = gridStyles.scroll_container_big_with_banner;
    if (isInactiveBannerShown) {
      containerStyles = gridStyles.scroll_container_big_with_inactive_banner;
    }

    if (isFriendsInterestedOpen) {
      return (
        <FriendsInterestedSection
          itemInterested={teacher}
          handleClose={this.handleCloseFriends}
        />
      );
    }

    return (
      <div
        className={!previewFromForm ? gridStyles.wrapper_fixed_bottom : ''}
      >
        {isReminderShown && (
          <SubscriptionReminder
            id={subscriptionId}
            status={subscriptionStatus}
          />
        )}
        {isInactiveBannerShown && (
          <InactiveBanner type={TEACHER} />
        )}
        <div
          className={containerStyles}
          ref={this.wrapperRef}
        >
          {!previewFromForm
            && (
            <button
              type="button"
              className={buttonStyles.cancel_button}
              onClick={this.close}
            >
              <CancelIcon />
            </button>
            )}
          <h1 className={detectSafari() ? styles.title_safari : styles.title}>{title}</h1>
          <div className={detectSafari()
            ? styles.styles_container_safari
            : styles.styles_container}
          >
            {danceStyles
          && danceStyles.map(style => <span key={style} className={styles.style}>{style}</span>)
        }
          </div>
          <PhotosBlock
            photos={photos}
            handleOpenFullScreen={this.handleOpenFullScreen}
            fanciersNumber={fanciersNumber}
            previewFromForm={previewFromForm}
          />
          <div className={styles.info_container}>
            <PinIcon />
            <div className={styles.info}>
              <VenueInfo venueId={venueId} location={location} address={address} />
            </div>
          </div>
          <div className={styles.info_container}>
            <InfoIcon className={styles.info_icon} />
            <div className={styles.info}>
              <p className={styles.text_dark}>Teacher info</p>
              <ReadMore className={styles.text_light}>
                <p className={styles.text_light}>
                  {description ? (
                    <BreakLineText>{description}</BreakLineText>
                  ) : TEXT_EMPTY}
                </p>
              </ReadMore>
            </div>
          </div>
          {isTeacherEditable && (
            <div className={buttonStyles.buttons_row_with_margin}>
              <Link
                to={{
                  pathname: `/teachers/${teacherId}/create_class`,
                  state: { from: history.location.pathname },
                }}
                className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
              >
                Add class
              </Link>
              <button
                type="button"
                className={teacherHasLessons
                  ? `${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`
                  : `${buttonStyles.btn_disabled} ${buttonStyles.btn_uppercase}`}
                onClick={teacherHasLessons
                  ? this.pushToCreatePass
                  : null}
              >
                Add pass
              </button>
            </div>
          )}
          {!previewFromForm && (
            <FriendsInterestedRow
              friends={interestedFriends}
              totalFriendsCount={totalFriendsInterested}
              openAllFriends={this.handleOpenFriends}
            />
          )}
          {!previewFromForm && (
            <>
              <ListWithDateFilter item={teacher} isActive={!isTeacherInactive} />
              <div ref={this.commentsRef}>
                <CommentsBlock
                  comments={comments}
                  item={teacher}
                  scrollToComment={this.scrollToComment}
                />
              </div>
            </>
          )}
        </div>
        {!previewFromForm && (
          <BottomBar
            favorite={favorite}
            toggleFavorite={this.handleToggleFavorite}
            item={teacher}
          />
        )}
      </div>
    );
  }
}

export default TeacherDetails;
