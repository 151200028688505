
import React from 'react';
import PropTypes from 'prop-types';

import styles from './stripe_info.module.scss';

const StripeText = ({ chargesStatus, payoutsStatus, connectStatus }) => {
  if (!connectStatus) {
    return (
      <>
        <p className={styles.text}>
          To receive payments you need to set up a payout.
        </p>
        <p className={styles.text}>
          Please create Stripe account or connect with an existing one to Dancity platform.
        </p>
      </>
    );
  }

  let text;
  if (!chargesStatus && payoutsStatus) text = "Unfortunatly, you can't receive payments as your charges are not available.";
  if (!payoutsStatus && chargesStatus) text = 'You can receive payments, but your payouts are not available.';
  if (!chargesStatus && !payoutsStatus) text = "Unfortunatly, you can't receive payments as your payouts and charges are not available.";

  return (
    <div className={styles.text_wrapper}>
      <p className={styles.text}>
        Your Stripe account is already connected to Dancity platform.
      </p>
      {text ? (
        <p className={styles.text}>
          {text}
        </p>
      ) : null}
      <p className={styles.text}>
        Please proceed to Stripe Dashboard for more information.
      </p>
    </div>
  );
};

StripeText.propTypes = {
  chargesStatus: PropTypes.bool,
  payoutsStatus: PropTypes.bool,
  connectStatus: PropTypes.bool,
};

StripeText.defaultProps = {
  chargesStatus: false,
  payoutsStatus: false,
  connectStatus: false,
};

export default StripeText;
