import { connect } from 'react-redux';
import { resetTransferInfo, transferItem } from './transfer_actions';
import createError from '../../Fetch_error/fetch_error_action';

import ConfirmTransferModal from './Confirm_transfer_modal';
import getNestedValue from '../../../utils/getNestedValue';

const mSTP = state => ({
  itemForTransfer: getNestedValue(state, 'transferData', 'transferItem'),
  transferUser: getNestedValue(state, 'transferData', 'transferUser'),
});

const mDTP = {
  resetTransferInfo,
  createError,
  transferItem,
};

export default connect(mSTP, mDTP)(ConfirmTransferModal);
