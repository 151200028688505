import { requestPassDetails } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import {
  GET_PASS_DETAILS_SUCCESS,
  GET_PASS_DETAILS_FAILURE,
} from '../../redux/action_types';
import { getItemsList } from '../List/list_actions';


export const getPassDetailsSuccess = pass => ({
  type: GET_PASS_DETAILS_SUCCESS,
  payload: pass,
});

export const getPassDetailsFailure = error => ({
  type: GET_PASS_DETAILS_FAILURE,
  payload: { error },
});

export const getPassDetails = ({ id, filter }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestPassDetails(id, token)
    .then((response) => {
      const pass = response.data.data;
      dispatch(getPassDetailsSuccess(pass));
      dispatch(getItemsList({
        list: 'teachers',
        token,
        filter,
      }));

      return response;
    })
    .catch((error) => {
      dispatch(createError(error));
      dispatch(getPassDetailsFailure(error));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};
