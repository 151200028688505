import { requestVenueAutoComplete } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';

const getVenueAutoComplete = query => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestVenueAutoComplete(query, token)
    .then(response => response.data)
    .catch((error) => {
      dispatch(createError(error));
    })
    .finally(() => dispatch(finishLoading()));
};

export default getVenueAutoComplete;
