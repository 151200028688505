import React from 'react';
import PropTypes from 'prop-types';

const Clock = ({ className }) => (
  <svg width="16" height="16" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M4.80814 4.808C2.35696 7.25919 1.00146 10.5214 1 13.9912C0.998701 17.469 2.349 20.7327 4.80814 23.192C7.26744 25.6512 10.5311 27.0013 14.0089 27C17.4787 26.9987 20.7368 25.6471 23.1919 23.192C25.6471 20.7367 26.9987 17.4786 27 14.0088C27.0013 10.531 25.6512 7.26731 23.1919 4.808C20.7327 2.34885 17.4691 0.998701 13.9913 1C10.5215 1.0013 7.25931 2.35697 4.80814 4.808ZM21.5899 21.5898C17.3952 25.7846 10.5749 25.7959 6.38966 21.6105C2.20422 17.4252 2.2156 10.6049 6.4103 6.41017C10.6048 2.21544 17.4251 2.20406 21.6105 6.38953C25.7959 10.5748 25.7846 17.3951 21.5899 21.5898Z" />
    <path d="M19.3874 16.8922L14.6175 14.6004L12.9189 7.8303C12.7717 7.24394 12.2279 6.88539 11.7104 7.03334C11.1931 7.18146 10.89 7.78246 11.0372 8.36882L12.8669 15.6612C12.9498 15.9919 13.1558 16.2701 13.4418 16.4052L18.6636 18.9104C18.8704 19.0112 19.09 19.0228 19.2876 18.9661C19.5699 18.8854 19.8161 18.6606 19.9313 18.3363C20.1287 17.777 19.8864 17.1322 19.3874 16.8922Z" />
  </svg>
);

Clock.propTypes = {
  className: PropTypes.string,
};

Clock.defaultProps = {
  className: '',
};

export default Clock;
