import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import defaultAvatar from '../../../images/default_avatar.jpg';
import styles from './friends_interested.module.scss';
import getUserName from '../../../utils/getUserName';


const FriendItem = ({ friend, history }) => {
  if (!friend) return null;

  const { id, avatar } = friend;
  const userName = getUserName(friend);
  const userAvatar = (avatar && avatar.thumb) || defaultAvatar;
  return (
    <Link
      to={{ pathname: `/users/${id}`, state: { from: history.location.pathname } }}
      className={styles.friend_link}
      style={{ backgroundImage: `url(${userAvatar})` }}
    >
      <span className={styles.tooltip}>
        {userName}
      </span>
    </Link>
  );
};

FriendItem.propTypes = {
  friend: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(FriendItem);
