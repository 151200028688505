import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import CancelIcon from '../icons/Cancel_icon';

import navStyles from '../../styles/navigation.module.scss';
import typographyStyles from '../../styles/common.module.scss';
import styles from './navstyles.module.scss';
import { getItemUrl, isClassesActive } from '../../utils/checkinHelpers';
import { HOST } from '../../config';

const Navigation = (props) => {
  const {
    history,
    hostCode,
    match,
    hostingStatus,
    title,
    isItemPassed,
    permissions,
  } = props;

  const handleClose = () => {
    const path = history && history.location && history.location.state
      && history.location.state.from
      ? history.location.state.from
      : '/';
    history.push(path);
  };

  const ableToCheckin = permissions && permissions.checkin;
  const itemUrl = getItemUrl(match);

  // temporary code until no lessons analytics
  const isClassesView = isClassesActive(match);

  return (
    <>
      <MediaQuery maxWidth={1024}>
        {matches => (matches
          ? (
            <Link to={itemUrl}>
              <h3 className={`${typographyStyles.secondary_title} ${styles.title_row}`}>
                {title}
              </h3>
            </Link>
          )
          : (
            <h3 className={`${typographyStyles.secondary_title} ${styles.title_row}`}>
              {title}
            </h3>
          )
        )}
      </MediaQuery>
      <button type="button" className={styles.close} onClick={handleClose}>
        <CancelIcon />
      </button>

      {hostCode ? (
        <h4 className={styles.grey_text}>Host code: {hostCode}</h4>
      ) : null}
      <nav>
        <ul className={navStyles.container}>
          {ableToCheckin ? (
            <li className={navStyles.block}>
              <NavLink
                to={`/check-in${itemUrl}`}
                className={navStyles.item}
                activeClassName={navStyles.item_active}
              >
                Guests
              </NavLink>
            </li>
          ) : (
            <li className={navStyles.block}>
              <div className={navStyles.item_disabled}>
                Guests
              </div>
            </li>
          )}

          {isItemPassed ? (
            <li className={navStyles.block}>
              <div className={navStyles.item_disabled}>
                Hosts
              </div>
            </li>
          ) : (
            <li className={navStyles.block}>
              <NavLink
                to={`/hosts${itemUrl}`}
                className={navStyles.item}
                activeClassName={navStyles.item_active}
              >
                Hosts
              </NavLink>
            </li>
          )}

          {(isClassesView || hostingStatus !== HOST) ? null : (
            <li className={navStyles.block}>
              <NavLink
                to={`/analytics${itemUrl}`}
                className={navStyles.item}
                activeClassName={navStyles.item_active}
              >
                Reports
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
};

Navigation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  hostCode: PropTypes.string,
  title: PropTypes.string,
  isItemPassed: PropTypes.bool,
  hostingStatus: PropTypes.string,
  permissions: PropTypes.shape({
    checkin: PropTypes.bool,
    update: PropTypes.bool,
    update_tickets: PropTypes.bool,
  }),
};

Navigation.defaultProps = {
  hostCode: '',
  title: '',
  isItemPassed: false,
  hostingStatus: '',
};

export default Navigation;
