import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { confirmEmail } from '../../api';
import styles from '../../styles/common.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import getNestedValue from '../../utils/getNestedValue';

export default class ConfirmEmailPage extends Component {
  state = {
    isEmailConfirm: false,
    error: null,
  }

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    openModal: PropTypes.func.isRequired,
    startRequest: PropTypes.func.isRequired,
    finishRequest: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { startRequest, finishRequest, history, match } = this.props;
    const token = getNestedValue(match, 'params', 'token');

    if (!token) {
      this.setState({
        error: 'Sorry you have no token.',
      });
      return;
    }

    startRequest();
    confirmEmail(token)
      .then(
        (res) => {
          finishRequest();
          if (res.status === 204) {
            this.setState({
              isEmailConfirm: true,
              error: null,
            });
            history.push({ state: {
              from: '/profile',
            } });
          }
        },
        (rej) => {
          finishRequest();
          let error = 'Something went wrong';
          if (rej.response.status === 422) {
            error = getNestedValue(rej, 'response', 'data', 'error');
          }
          this.setState({ error });
        },
      )
      .catch(() => {
        finishRequest();
      });
  }

  handleOpenLogin = () => {
    const { openModal } = this.props;
    openModal('SIGN_IN_MODAL');
  }

  render() {
    const { isEmailConfirm, error } = this.state;

    return (
      <main className={styles.page}>
        <h1 className={styles.page_title_centered}>Email confirmation</h1>
        {isEmailConfirm && (
          <>
            <p>Congratulations! Your email has been confirmed</p>
            <div className={buttonStyles.btn_box}>
              <button
                type="button"
                onClick={this.handleOpenLogin}
                className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
              >
                Login
              </button>
            </div>
          </>
        )}

        {error && (
          <p>{error}</p>
        )}
      </main>
    );
  }
}
