import { requestVenueItems, requestTeacherItems } from '../../../api';
import { startLoading, finishLoading } from '../../Loader/loading_actions';
import createError from '../../Fetch_error/fetch_error_action';

export const getVenueItems = ({ id, day }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestVenueItems({ id, token, day })
    .then(response => response)
    .catch((error) => {
      dispatch(createError(error));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};

export const getTeacherLessons = ({ id, day }) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestTeacherItems({ id, token, day })
    .then(response => response)
    .catch((error) => {
      dispatch(createError(error));
      return error;
    })
    .finally(() => dispatch(finishLoading()));
};
