import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Item from './Item';
import Arrow from '../icons/Arrow';
import styles from './my_items.module.scss';

import { TEACHER } from '../../config';

class ItemsList extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.string,
        type: PropTypes.string,
      }),
    ).isRequired,
    isOpened: PropTypes.bool,
  };

  static defaultProps = {
    isOpened: false,
  }

  state = {
    isOpened: this.props.isOpened,
  }


  handleToggleList = () => this.setState(prevState => (
    { isOpened: !prevState.isOpened }))

  render() {
    const { items, title } = this.props;
    const { isOpened } = this.state;

    return (
      <div className={styles.list_container}>
        <button
          className={styles.list_title}
          type="button"
          onClick={this.handleToggleList}
        >
          {`${title} (${items.length})`}
        </button>
        <Arrow direction={isOpened ? 'up' : 'down'} className={styles.arrow} />
        {isOpened && (
          <ul>
            {items.map((item) => {
              const hasLessons = item.lessons && item.lessons.length > 0;
              const hasClasses = item.tickets && item.tickets.length > 0;
              if (item.type === TEACHER && (hasLessons || hasClasses)) {
                return (
                  <li key={item.id}>
                    <div className={styles.item}>
                      <Item item={item} />
                    </div>
                    {hasLessons && (
                      <>
                        <p className={styles.sublist}>Classes</p>
                        <ul className={styles.sublist}>
                          {item.lessons.map(lesson => (
                            <li key={lesson.id} className={styles.item}>
                              <Item item={lesson} />
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    {hasClasses && (
                      <>
                        <p className={styles.sublist}>Passes</p>
                        <ul className={styles.sublist}>
                          {item.tickets.map(ticket => (
                            <li key={ticket.id} className={styles.item}>
                              <Item item={ticket} />
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </li>
                );
              } return (
                <li key={item.id} className={styles.item}>
                  <Item item={item} />
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default ItemsList;
