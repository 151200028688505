import React from 'react';
import PropTypes from 'prop-types';
import styles from './week_picker.module.scss';

import { WEEK } from '../../../config';

const WeekPicker = ({ selectedDays, handlePickDay }) => {
  const handleDayToggle = (e) => {
    const { name, checked } = e.target;
    handlePickDay(name, checked);
  };

  return (
    <>
      <p className={styles.title}>Days</p>
      <ul className={styles.container}>
        {WEEK.map(day => (
          <li key={day} className={styles.wrapper}>
            <input
              type="checkbox"
              name={day}
              className={styles.item}
              id={day}
              onChange={handleDayToggle}
              checked={selectedDays.indexOf(day) !== -1}
            />
            <label htmlFor={day} className={styles.label}>{day}</label>
          </li>
        ))}
      </ul>
    </>
  );
};

WeekPicker.propTypes = {
  handlePickDay: PropTypes.func.isRequired,
  selectedDays: PropTypes.arrayOf(PropTypes.oneOf(WEEK)).isRequired,
};

export default WeekPicker;
