import React from 'react';
import PropTypes from 'prop-types';

const CropIcon = ({ className }) => (
  <svg className={className} x="0px" y="0px" viewBox="0 0 172.094 172.094">
    <path d="M172.094,17.186L154.908,0l-39.942,39.942H43.902V20.344H19.598v19.598H0v24.304h19.598v88.25h88.25v19.598h24.304v-19.598  h19.598v-24.304h-19.598V57.127L172.094,17.186z M43.902,64.246h46.761l-46.761,46.761V64.246z M107.848,128.192H61.087  l46.761-46.761V128.192z" fill="#FFFFFF" />
  </svg>
);

CropIcon.propTypes = {
  className: PropTypes.string,
};

CropIcon.defaultProps = {
  className: '',
};

export default CropIcon;
