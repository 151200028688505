import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

export const requestSearchUsers = ({ token, query }) => (
  axios.get(
    '/api/transfers/search',
    {
      headers: {
        'Content-Type': 'application/json',
        'JWT-Authorization': `Bearer ${token}`,
        Accept: 'application/json',
      },
      params: query,
    },
  )
);

export const requestTransferItem = ({ token, transferData }) => (
  axios.post(
    '/api/transfers',
    { data: transferData },
    {
      headers: {
        'Content-Type': 'application/json',
        'JWT-Authorization': `Bearer ${token}`,
        Accept: 'application/json',
      },
    },
  )
);
