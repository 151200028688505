import React from 'react';
import PropTypes from 'prop-types';
import inputStyles from '../../../styles/inputs.module.scss';

const TextArea = ({ stateObject, handleInputChange, placeholder, name, resizable }) => {
  let fieldStyle;
  const { isActive, isValid, value, error } = stateObject;
  if (!isActive) fieldStyle = inputStyles.textarea_box_not_active;

  else if (resizable) {
    fieldStyle = isValid
      ? inputStyles.textarea_box_resizable
      : inputStyles.textarea_box_resizable_is_error;
  } else {
    fieldStyle = isValid
      ? inputStyles.textarea_box
      : inputStyles.textarea_box_is_error;
  }

  return (
    <div className={fieldStyle}>
      <textarea
        className={resizable ? inputStyles.textarea_resizable : inputStyles.textarea}
        type="text"
        name={name}
        value={value}
        onChange={handleInputChange}
      />
      <label
        htmlFor={name}
        className={value ? inputStyles.label__with_value : inputStyles.label}
      >
        {error || placeholder}
      </label>
    </div>
  );
};

TextArea.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  stateObject: PropTypes.shape({
    value: PropTypes.string,
    isActive: PropTypes.bool,
    isValid: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  resizable: PropTypes.bool,
};

TextArea.defaultProps = {
  stateObject: {
    value: '',
    isActive: false,
    isValid: false,
    error: '',
  },
  placeholder: 'Description...',
  name: 'description',
  resizable: false,
};

export default TextArea;
