import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';


export const requestNotificationsCount = token => (
  axios.get(
    '/api/notifications/amount_of_new',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestUserNotifications = token => (
  axios.get(
    '/api/notifications',
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestDeleteNotification = ({ token, id }) => (
  axios.delete(
    `/api/notifications/${id}`,
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestUpdateAllNotifications = ({ token, status }) => (
  axios.patch(
    '/api/notifications/mark',
    { data: {
      to_status: status,
      mark_all: true,
    } },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);

export const requestMarkAsRead = ({ token, id }) => (
  axios.patch(
    '/api/notifications/mark',
    { data: {
      to_status: 'read',
      ids: [id],
    } },
    { headers: { 'Content-Type': 'application/json', 'JWT-Authorization': `Bearer ${token}`, Accept: 'application/json' } },
  )
);
