import { connect } from 'react-redux';

import AddCardForm from './Add_card_form';
import { addPaymentMethod } from './payment_methods_actions';
import createError from '../Fetch_error/fetch_error_action';


const mSTP = state => ({
  token: state.session.token,
});

const mDTP = { addPaymentMethod, createError };

export default connect(mSTP, mDTP)(AddCardForm);
