import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

import ProtectedRoute from '../components/shared/Protected_route';
import Profile from '../components/Profile/profile_container';
import EventForm from '../components/Event_Form/event_form_container';
import List from '../components/List/list_container';
import EventDetails from '../components/Event_details/Event_details_container';
import Map from '../components/Map/map_container';
import Account from '../components/Account/Account';
import Search from '../components/Search/search_container';
import TicketsList from '../components/User_tickets/Tickets_list_container';
import Feedback from '../components/Feedback/feedback_container';
import VenueForm from '../components/Venue_Form/venue_form_container';
import VenueDetails from '../components/Venue_details/venue_details_container';
import TeacherForm from '../components/Teacher_Form/teacher_form_container';
import TeacherDetails from '../components/Teacher_details/teacher_details_container';
import LessonForm from '../components/Lesson_Form/Lesson_form_container';
import LessonDetails from '../components/Lesson_details/lesson_details_container';
import PassForm from '../components/Pass_Form/pass_form_container';
import PassDetails from '../components/Pass_details/pass_details_container';
import CheckIn from '../components/Check-in/check-in_container';
import Checkout from '../components/Checkout/checkout_container';
import Payments from '../components/Payments/Payments';
import Hosting from '../components/Hosting/Hosting_container';
import HostingDetails from '../components/Hosting_details/Hosting_details_container';
import NotificationsList from '../components/Notifications/Notifications_container';
import EventAnalytics from '../components/Event_analytics/Event_analytics_container';
import Friends from '../components/Friends/Friends_container';
import PublicProfile from '../components/Public_profile/Public_profile_container';
import gridStyles from '../styles/main_grid.module.scss';

const LIST_PATHS = [
  '/',
  '/events',
  '/venues',
  '/teachers',
  '/create_event',
  '/create_venue',
  '/create_teacher',
  '/events/:id',
  '/venues/:id',
  '/teachers/:id',
  '/teachers/:id/create_class',
  '/teachers/:id/create_pass',
  '/account/edit_event/:id',
  '/account/edit_venue/:id',
  '/account/edit_teacher/:id',
  '/classes/:id',
  '/passes/:id',
  '/account/edit_class/:lesson_id',
  '/account/edit_pass/:pass_id',
];

const MainPage = ({ isAuth }) => (
  <main className={gridStyles.grid}>
    <MediaQuery minWidth={1025} values={window.testMediaQueryValues}>
      <Route
        exact
        path={LIST_PATHS}
        component={List}
      />
    </MediaQuery>
    <MediaQuery maxWidth={1024}>
      <Route
        exact
        path={['/', '/events', '/venues', '/teachers']}
        component={List}
      />
    </MediaQuery>
    <Route path="/search" component={Search} />
    <ProtectedRoute
      authenticated={isAuth}
      path={['/my_tickets', '/my_passes']}
      redirectTo="/login"
      component={TicketsList}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path={['/my_hostings/events', '/my_hostings/classes']}
      redirectTo="/login"
      component={Hosting}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/check-in/events/:id"
      redirectTo="/login"
      component={CheckIn}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/analytics/events/:id"
      redirectTo="/login"
      component={EventAnalytics}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/hosts/events/:id"
      redirectTo="/login"
      component={HostingDetails}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/check-in/classes/:id"
      redirectTo="/login"
      component={CheckIn}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/hosts/classes/:id"
      redirectTo="/login"
      component={HostingDetails}
    />
    <Route
      exact
      path={['/careers', '/feedback', '/contact']}
      render={props => <Feedback {...props} />}
    />
    <ProtectedRoute
      authenticated={isAuth}
      exact
      path={['/friends', '/friends/:list']}
      redirectTo="/login"
      component={Friends}
    />
    <Route path="/" component={Map} />
    <ProtectedRoute
      authenticated={isAuth}
      path="/profile"
      redirectTo="/login"
      component={Profile}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/create_event"
      redirectTo="/login"
      component={EventForm}
    />
    <ProtectedRoute
      authenticated={isAuth}
      exact
      path={['/account', '/subscriptions', '/payout']}
      redirectTo="/login"
      component={Account}
    />
    <ProtectedRoute
      authenticated={isAuth}
      exact
      path={['/payment_history', '/payment_methods']}
      redirectTo="/login"
      component={Payments}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/account/edit_event/:id"
      redirectTo="/login"
      component={EventForm}
    />
    {/* start right column on wide screens */}
    <MediaQuery minWidth={1025}>
      <Route
        exact
        path="/check-in/events/:id"
        component={EventDetails}
      />
      <Route
        exact
        path="/hosts/events/:id"
        component={EventDetails}
      />
      <Route
        exact
        path="/analytics/events/:id"
        component={EventDetails}
      />
      <Route
        exact
        path="/check-in/classes/:id"
        component={LessonDetails}
      />
      <Route
        exact
        path="/hosts/classes/:id"
        component={LessonDetails}
      />
    </MediaQuery>
    {/* finish right column on wide screens */}

    <Route
      exact
      path="/events/:id"
      component={EventDetails}
    />
    <Route
      exact
      path="/venues/:id"
      component={VenueDetails}
    />
    <Route
      exact
      path="/teachers/:id"
      component={TeacherDetails}
    />
    <Route
      exact
      path="/classes/:id"
      component={LessonDetails}
    />
    <Route
      exact
      path="/passes/:id"
      component={PassDetails}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/create_venue"
      redirectTo="/login"
      component={VenueForm}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/account/edit_venue/:id"
      redirectTo="/login"
      component={VenueForm}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/create_teacher"
      redirectTo="/login"
      component={TeacherForm}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/account/edit_teacher/:id"
      redirectTo="/login"
      component={TeacherForm}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/teachers/:id/create_class"
      redirectTo="/login"
      component={LessonForm}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/account/edit_class/:lesson_id"
      redirectTo="/login"
      component={LessonForm}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/teachers/:id/create_pass"
      redirectTo="/login"
      component={PassForm}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/account/edit_pass/:pass_id"
      redirectTo="/login"
      component={PassForm}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/checkout"
      redirectTo="/login"
      component={Checkout}
    />
    <ProtectedRoute
      authenticated={isAuth}
      path="/notifications"
      redirectTo="/login"
      component={NotificationsList}
    />
    <Route
      path="/users/:id"
      component={PublicProfile}
    />
  </main>
);

MainPage.propTypes = {
  isAuth: PropTypes.bool.isRequired,
};

const mSTP = state => ({
  isAuth: state.session.authenticated,
});

export default connect(mSTP)(MainPage);
