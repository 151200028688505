import React from 'react';
import PropTypes from 'prop-types';

const Person = ({ className }) => (
  className
    ? (
      <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 26 28">
        <path fillRule="evenodd" d="M22.249 19.703l-5.175-1.127v-1.63l.02-.029c.258-.377.499-.847.765-1.377.258-.511.498-.979.618-1.115.82-.807 1.687-1.799 1.951-3.096.259-1.251-.044-1.906-.248-2.184l-.022-.03v-.037c0-.916 0-2.62-.321-3.856l-.004-.025c-.038-1.479-.32-2.348-1.007-3.1-.505-.546-1.23-.669-1.812-.768-.279-.047-.515-.089-.61-.137-1.012-.564-2.019-.829-3.266-.861-2.528.096-5.64 1.71-6.683 4.609-.295.819-.294 2.058-.264 3.474v.596l-.012.024c-.18.353-.513 1.009-.247 2.294.266 1.307 1.128 2.292 1.936 3.084.137.151.393.644.642 1.137.232.458.495.977.766 1.368l.02.029v1.632L4.12 19.705c-2.062.448-3.558 2.31-3.558 4.427v2.785c0 .579.469 1.048 1.045 1.048h23.155c.577 0 1.046-.469 1.046-1.048v-2.785c0-2.116-1.497-3.979-3.559-4.429zm1.468 6.166H2.653v-1.737c0-1.138.804-2.138 1.913-2.379l5.999-1.304c.478-.105.824-.536.824-1.026v-2.848c0-.257-.12-.543-.312-.747-.146-.154-.425-.684-.703-1.234-.361-.707-.673-1.318-1.035-1.672-.588-.577-1.209-1.269-1.363-2.017-.111-.54-.052-.694.064-.919.094-.183.251-.489.251-.908l-.007-.707c-.017-.765-.05-2.193.142-2.719.719-2.003 2.971-3.156 4.725-3.224.858.021 1.525.199 2.235.594.398.222.854.3 1.255.37l.06.01c.16.027.459.078.501.08l.046.002.036.034c.197.212.442.476.461 1.856.002.093.017.188.044.28.278.957.278 2.623.278 3.424 0 .414.151.711.252.908.115.228.174.382.061.922-.149.74-.742 1.405-1.361 2.014-.36.353-.668.961-1.024 1.665-.261.521-.548 1.081-.699 1.237-.2.196-.313.469-.313.751v2.848c0 .49.346.921.824 1.026l5.999 1.304a2.446 2.446 0 0 1 1.911 2.379v1.737z" />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28">
        <path fill="#A1ADC4" fillRule="evenodd" d="M22.249 19.703l-5.175-1.127v-1.63l.02-.029c.258-.377.499-.847.765-1.377.258-.511.498-.979.618-1.115.82-.807 1.687-1.799 1.951-3.096.259-1.251-.044-1.906-.248-2.184l-.022-.03v-.037c0-.916 0-2.62-.321-3.856l-.004-.025c-.038-1.479-.32-2.348-1.007-3.1-.505-.546-1.23-.669-1.812-.768-.279-.047-.515-.089-.61-.137-1.012-.564-2.019-.829-3.266-.861-2.528.096-5.64 1.71-6.683 4.609-.295.819-.294 2.058-.264 3.474v.596l-.012.024c-.18.353-.513 1.009-.247 2.294.266 1.307 1.128 2.292 1.936 3.084.137.151.393.644.642 1.137.232.458.495.977.766 1.368l.02.029v1.632L4.12 19.705c-2.062.448-3.558 2.31-3.558 4.427v2.785c0 .579.469 1.048 1.045 1.048h23.155c.577 0 1.046-.469 1.046-1.048v-2.785c0-2.116-1.497-3.979-3.559-4.429zm1.468 6.166H2.653v-1.737c0-1.138.804-2.138 1.913-2.379l5.999-1.304c.478-.105.824-.536.824-1.026v-2.848c0-.257-.12-.543-.312-.747-.146-.154-.425-.684-.703-1.234-.361-.707-.673-1.318-1.035-1.672-.588-.577-1.209-1.269-1.363-2.017-.111-.54-.052-.694.064-.919.094-.183.251-.489.251-.908l-.007-.707c-.017-.765-.05-2.193.142-2.719.719-2.003 2.971-3.156 4.725-3.224.858.021 1.525.199 2.235.594.398.222.854.3 1.255.37l.06.01c.16.027.459.078.501.08l.046.002.036.034c.197.212.442.476.461 1.856.002.093.017.188.044.28.278.957.278 2.623.278 3.424 0 .414.151.711.252.908.115.228.174.382.061.922-.149.74-.742 1.405-1.361 2.014-.36.353-.668.961-1.024 1.665-.261.521-.548 1.081-.699 1.237-.2.196-.313.469-.313.751v2.848c0 .49.346.921.824 1.026l5.999 1.304a2.446 2.446 0 0 1 1.911 2.379v1.737z" />
      </svg>
    )
);

Person.propTypes = {
  className: PropTypes.string,
};

Person.defaultProps = {
  className: '',
};

export default Person;
