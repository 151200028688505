import { connect } from 'react-redux';
import { deleteFriend } from '../../Friends/friends_actions';
import DeleteFriendModal from './Delete_friend_modal';

const mSTP = state => ({
  friendToDelete: state && state.friends && state.friends.friendToDelete,
});

const mDTP = { deleteFriend };

export default connect(mSTP, mDTP)(DeleteFriendModal);
