import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeModal, openModal, setCurrentModal } from '../Modal/modal_actions';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import createError from '../Fetch_error/fetch_error_action';
import styles from './forms.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import { changePassword } from '../../api';
import Eye from '../icons/Eye';
import EyeHidden from '../icons/Eye_hidden';
import { validatePassword } from '../../utils/validation';

const INITIAL_STATE_FIELD = {
  value: '',
  isActive: false,
  error: null,
};

const INITIAL_STATE = {
  currentPassword: {
    ...INITIAL_STATE_FIELD,
    showPassword: false,
  },
  newPassword: {
    ...INITIAL_STATE_FIELD,
    showPassword: false,
  },
  successPasswordCange: false,
};

export class ChangePasswordFormComp extends Component {
  static propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    beginLoading: PropTypes.func.isRequired,
    endLoading: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
  }

  state = { ...INITIAL_STATE }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value,
        error: null,
      },
    }));
  }

  handleShowPassword = (e, name) => {
    e.preventDefault();
    this.setState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        showPassword: !prevState[name].showPassword,
      },
    }));
  }

  resetState = () => {
    this.setState(INITIAL_STATE);
  }

  openForgotPasswordModal = (e) => {
    e.preventDefault();

    this.props.openModal('FORGOT_PASSWORD_MODAL');
  }

  handleCancel = (e) => {
    e.preventDefault();
    const { handleCloseModal } = this.props;

    this.resetState();
    handleCloseModal();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      ...prevState,
      successPasswordCange: false,
    }));

    const {
      currentPassword: { value: currentPassword },
      newPassword: { value: newPassword },
    } = this.state;
    const {
      id,
      token,
      beginLoading,
      endLoading,
      handleError,
    } = this.props;

    if (currentPassword !== '' && newPassword.length >= 6) {
      beginLoading();
      changePassword({ id, newPassword, currentPassword }, token)
        .then(
          (res) => {
            if (res.status === 200) {
              this.setState({
                ...INITIAL_STATE,
                successPasswordCange: true,
              });
            }
          },
          (rej) => {
            const { status, data } = rej.response;
            if (status === 422) {
              Object.keys(data.errors).forEach((item) => {
                if (item === 'password') {
                  this.setState(prevState => ({
                    ...prevState,
                    newPassword: {
                      ...prevState.newPassword,
                      error: data.errors[item].join(', '),
                    },
                  }));
                }
                if (item === 'current_password') {
                  this.setState(prevState => ({
                    ...prevState,
                    currentPassword: {
                      ...prevState.currentPassword,
                      error: data.errors[item].join(', '),
                    },
                  }));
                }
              });
            }
          },
        )
        .catch((err) => {
          handleError(err);
        })
        .finally(() => { endLoading(); });
    }
  }

  render() {
    const {
      currentPassword: {
        value: currentPassword,
        showPassword: showCurrentPassword,
        error: curentPassErr,
      },
      newPassword: {
        value: newPassword,
        showPassword: showNewPassword,
      },
      successPasswordCange,
    } = this.state;

    let passwordClassList = styles.input_box;
    let labelPassword = 'New password';
    let currentPassClassList = styles.input_box;
    let currentPassLabel = 'Current password';

    if (newPassword !== '' && !validatePassword(newPassword)) {
      passwordClassList = `${styles.input_box} ${styles.is_error}`;
      labelPassword = 'Password must be from 6 to 72 characters';
    }

    if (curentPassErr) {
      currentPassClassList = `${styles.input_box} ${styles.is_error}`;
      currentPassLabel = curentPassErr;
    }

    return (
      successPasswordCange ? (
        <>
          <div className={styles.message}>
            Your password was changed succesfully!
          </div>
          <button
            type="button"
            className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
            onClick={this.handleCancel}
          >
            Ok
          </button>
        </>
      ) : (
        <>
          <div className={styles.message}>
            It’s a good idea to use a strong password that
            you’re not using elsewhere.
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className={currentPassClassList}>
              <input
                type={showCurrentPassword ? 'text' : 'password'}
                name="currentPassword"
                value={currentPassword}
                onChange={this.handleInputChange}
                autoComplete="off"
                className={styles.autocomlete_off}
              />
              <label
                htmlFor="currentPassword"
                className={currentPassword !== '' ? styles.with_value : undefined}
              >
                {currentPassLabel}
              </label>
              <button
                className={styles.eye_box}
                onClick={(e) => { this.handleShowPassword(e, 'currentPassword'); }}
                type="button"
              >
                {showCurrentPassword ? <EyeHidden /> : <Eye />}
              </button>
            </div>

            <div className={passwordClassList}>
              <input
                type={showNewPassword ? 'text' : 'password'}
                name="newPassword"
                value={newPassword}
                onChange={this.handleInputChange}
                autoComplete="off"
                className={styles.autocomlete_off}
              />
              <label
                htmlFor="newPassword"
                className={newPassword !== '' ? styles.with_value : undefined}
              >
                {labelPassword}
              </label>
              <button
                className={styles.eye_box}
                onClick={(e) => { this.handleShowPassword(e, 'newPassword'); }}
                type="button"
              >
                {showNewPassword ? <EyeHidden /> : <Eye />}
              </button>
            </div>
            <button className={styles.forgot_password} onClick={this.openForgotPasswordModal} type="button">
              Forgot your password?
            </button>
            <div className={styles.btns_row}>
              <button
                type="submit"
                className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
              >
                Save changes
              </button>
              <button
                type="button"
                className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                onClick={this.handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </>
      )
    );
  }
}

const mSTP = state => ({
  id: state.session.user.id,
  token: state.session.token,
});

const mDTP = dispatch => ({
  handleCloseModal: () => dispatch(closeModal()),
  beginLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(finishLoading()),
  openModal: (modalName) => {
    dispatch(closeModal());
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  handleError: err => dispatch(createError(err)),
});

export default connect(mSTP, mDTP)(ChangePasswordFormComp);
