import { GET_PAYMENT_METHODS, SIGN_OUT } from '../../redux/action_types';

const INITIAL_STATE = [];

const methodsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS:
      return action.payload;

    case SIGN_OUT:
      return [];

    default:
      return state;
  }
};

export default methodsReducer;
