import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Switch, Route } from 'react-router-dom';

import MyItems from '../My_items/my_items_container';
// import MySubscriptions from '../My_subscriptions/my_subscriptions_container';
import MyPayout from '../My_payout/my_payout_container';

import CancelIcon from '../icons/Cancel_icon';

import styles from '../../styles/common.module.scss';
import navStyles from '../../styles/navigation.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';

const Account = (props) => {
  const closeAccount = () => {
    const { history, location } = props;
    const from = location.state && location.state.from ? location.state.from : '/';
    history.push(from);
  };

  return (
    <div className={`${gridStyles.side_bar_wide} ${gridStyles.mobile_on_top}`}>
      <h3 className={styles.title}>Account</h3>
      <nav className={navStyles.item_double_wrapper}>
        <ul className={navStyles.container}>
          <li className={navStyles.block_double}>
            <NavLink className={navStyles.item} to="/account" activeClassName={navStyles.item_active}>
              Account
            </NavLink>
          </li>
          {/* <li className={navStyles.block}>
  <NavLink className={navStyles.item} activeClassName={navStyles.item_active} to="/subscriptions">
              Subscriptions
            </NavLink>
          </li> */}
          <li className={navStyles.block_double}>
            <NavLink className={navStyles.item} activeClassName={navStyles.item_active} to="/payout">
              Payout
            </NavLink>
          </li>
        </ul>
      </nav>
      <button type="button" className={styles.close} onClick={closeAccount}><CancelIcon /></button>
      <Switch>
        <Route
          exact
          path="/account"
          component={MyItems}
        />
        {/* <Route
          exact
          path="/subscriptions"
          component={MySubscriptions}
        /> */}
        <Route
          exact
          path="/payout"
          component={MyPayout}
        />
      </Switch>
    </div>
  );
};

Account.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.string,
    }),
  }),
};

Account.defaultProps = {
  history: null,
  location: null,
};

export default Account;
