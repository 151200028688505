import { connect } from 'react-redux';
import { editAvatar } from '../login/login_actions';
import { setModalMessage, setModalTitle, setAndOpenModal } from '../Modal/modal_actions';

import AvatarEditor from './Avatar_editor';

const mDTP = {
  editAvatar,
  setModalMessage,
  setModalTitle,
  setAndOpenModal,
};

export default connect(null, mDTP)(AvatarEditor);
