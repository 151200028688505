import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReadMore from '../shared/Read_more/Read_more';
import IncludesBlock from '../shared/Includes_block/Includes_block';

import { formatDateForEventDetails, formatDateForListReverse } from '../../utils/formatDate';

import bg1 from '../../images/bg1.jpg';
import bg2 from '../../images/bg2.jpg';
import bg3 from '../../images/bg3.jpg';

import PlusIcon from '../icons/Plus';
import MinusIcon from '../icons/Minus';

import styles from './ticket_order.module.scss';

const bgImages = [bg1, bg2, bg3];
const ITEMS_TO_SHOW = 2;
const MAX_TICKETS_FOR_CHECKOUT = 10;

class Ticket extends Component {
  static propTypes = {
    ticket: PropTypes.shape({
      id: PropTypes.string,
      sellable: PropTypes.bool,
    }).isRequired,
    index: PropTypes.number.isRequired,
    ticketFromState: PropTypes.shape({
      count: PropTypes.number,
    }).isRequired,
    onAddTicket: PropTypes.func.isRequired,
    onSubstractTicket: PropTypes.func.isRequired,
    eventStartDate: PropTypes.string.isRequired,
    eventEndDate: PropTypes.string.isRequired,
  }

  state = { showAllItems: false }

  handleAddTicket = () => {
    const { onAddTicket, ticket, ticketFromState } = this.props;
    const isAvailable = ticketFromState && ticketFromState.isAvailable;
    const { id, permissions } = ticket;
    const availableQuantity = (
      Object.prototype.hasOwnProperty.call(ticket, 'available') && ticket.available !== null
    ) ? ticket.available : Infinity;
    const quantityToBuy = ticketFromState.count;
    const sellable = permissions && permissions.buy;

    if (
      isAvailable
      && id
      && sellable
      && quantityToBuy < availableQuantity
      && quantityToBuy < MAX_TICKETS_FOR_CHECKOUT
    ) onAddTicket(id);
  }

  handleSubstractTicket = () => {
    const { ticket, onSubstractTicket, ticketFromState } = this.props;
    const { id } = ticket;
    const quantityToBuy = ticketFromState.count;

    if (quantityToBuy - 1 >= 0) onSubstractTicket(id);
  }

  toggleItemsShow = () => {
    const { ticket } = this.props;
    if (ticket.items && ticket.items.length > ITEMS_TO_SHOW) {
      this.setState(prevState => ({ showAllItems: !prevState.showAllItems }));
    }
  }

  render() {
    const {
      ticket,
      index,
      eventStartDate,
      eventEndDate,
      ticketFromState,
    } = this.props;
    const {
      name,
      description,
      refunds,
      price,
      end_date: ticketEndDate,
      validity,
      total_visits: visits,
      items,
      quantity,
      permissions,
    } = ticket;

    const isAvailable = ticketFromState && ticketFromState.isAvailable;
    const sellable = permissions && permissions.buy;
    const available = ticket.available || 0;
    const quantityToBuy = ticketFromState && ticketFromState.count;
    const availableQuantity = available - quantityToBuy;

    const refundsText = refunds ? 'With\u00A0refund' : 'No\u00A0refund';
    let visitsLabel = '';
    if (visits === 1) {
      visitsLabel = '1 entry';
    } else if (visits > 1) {
      visitsLabel = `${visits} entries`;
    } else if (visits === 0) {
      visitsLabel = '\u221E entries';
    }

    const ticketStyle = (isAvailable && sellable) ? styles.ticket : styles.ticket_disabled;

    return (
      <li>
        {(isAvailable && !sellable) && (
          <div className={styles.message}>
            Ticket will become available soon!
          </div>
        )}
        <div className={ticketStyle}>
          <div
            className={styles.wrapper_top}
            style={{ backgroundImage: `url(${bgImages[index % 3]})` }}
          >
            <div>
              <p className={styles.name}>{name}</p>
              <time className={styles.date}>
                {formatDateForEventDetails(eventStartDate, eventEndDate)}
              </time>
            </div>
            <div className={styles.side_info}>
              <div className={styles.price}>{`$${price / 100}`}</div>
              {(quantity && quantity !== 0)
                ? <div className={styles.quantity}>{`${availableQuantity} left`}</div>
                : null}
            </div>
          </div>
          <div className={styles.wrapper_bottom}>
            <div>
              <ReadMore className={styles.description}>
                <p className={styles.description}>{description}</p>
              </ReadMore>
              {items && items.length > 1 && (
                <>
                  <div className={styles.sub_title}>Pass includes: </div>
                  <IncludesBlock items={items} />
                </>
              )}
              <div>
                {validity ? (
                  <span className={styles.grey}>
                    Valid for {validity} days
                  </span>
                ) : null}
                {visitsLabel && (
                  <span className={styles.grey}>{visitsLabel}</span>
                )}
                {ticketEndDate ? (
                  <span className={styles.grey}>
                      Sale ends {formatDateForListReverse(ticketEndDate)}
                  </span>
                ) : null}
                <span className={styles.grey}>{refundsText}</span>
              </div>
            </div>
            <div className={styles.count_container}>
              <button
                onClick={this.handleSubstractTicket}
                type="button"
                className={`${styles.count_button} ${styles.minus_count}`}
              >
                <MinusIcon className={styles.count_icon} />
              </button>
              <span className={styles.ticket_count}>
                {quantityToBuy}
              </span>
              <button
                onClick={this.handleAddTicket}
                type="button"
                className={styles.count_button}
              >
                <PlusIcon className={styles.count_icon} />
              </button>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default Ticket;
