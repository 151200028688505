import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import defaultAvatar from '../../../images/default_avatar.jpg';
import styles from '../../../styles/view_details.module.scss';
import getUserName from '../../../utils/getUserName';

const AuthorSection = ({ author, label, history }) => {
  if (!author) return null;

  const userName = getUserName(author);
  const profession = author && author.profession ? author.profession : '';
  const userAvatar = author && author.avatar && author.avatar.thumb
    ? author.avatar.thumb
    : defaultAvatar;
  const userId = author && author.id;
  const userUrl = {
    pathname: `/users/${userId}`,
    state: { from: history.location.pathname },
  };

  return (
    <div className={styles.author_container}>
      <Link to={userUrl} className={styles.avatar} style={{ backgroundImage: `url(${userAvatar})` }} />
      <div className={styles.author}>
        <p className={`${styles.text_light} ${styles.text_small}`}>{label}</p>
        <Link to={userUrl} className={styles.text_dark}>{userName}</Link>
        <p className={`${styles.text_dark} ${styles.text_extrasmall}`}>{profession}</p>
      </div>
    </div>
  );
};

AuthorSection.propTypes = {
  author: PropTypes.shape({
    id: PropTypes.string,
  }),
  label: PropTypes.string,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

AuthorSection.defaultProps = {
  label: '',
  author: null,
};

export default withRouter(AuthorSection);
