import moment from 'moment';

const createMarkers = (arr) => {
  let markers;

  if (Array.isArray(arr)) {
    markers = arr.filter(item => (item.id && item.type && item.coordinates));
  }

  return markers;
};

export const coordinatesOnTop = markers => (
  markers.map(item => ({
    ...item,
    lat: item.coordinates.lat,
    lng: item.coordinates.lng,
  }))
);

export const checkMarkerDateHot = ({ serverTime, item }) => {
  if (!item) return false;
  const { start_date: startDate, end_date: endDate } = item;
  if (!startDate) return false;
  let isHot = false;
  const now = serverTime ? moment(serverTime) : moment();
  const tomorrow = now
    .clone()
    .add(1, 'days')
    .hours(0)
    .minutes(0)
    .seconds(0);

  const start = moment(startDate);
  const end = moment(endDate);
  if ((end > now || !endDate) && start < tomorrow) {
    isHot = true;
  }
  return isHot;
};

export default createMarkers;
