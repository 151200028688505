import React from 'react';
import PropTypes from 'prop-types';

import styles from './stripe_info.module.scss';

import { STRIPE_LINK } from '../../../config';

const StripeButton = ({ onClick, isStripeConnected }) => {
  let stripeLink;

  if (isStripeConnected) {
    stripeLink = 'https://dashboard.stripe.com/';
  } else {
    stripeLink = STRIPE_LINK;
  }

  return (
    <a
      href={stripeLink}
      rel="noopener noreferrer"
      target="_blank"
      className={styles.stripe_button}
      onClick={onClick}
    >
      <span className={styles.stripe_text}>
        {isStripeConnected ? 'Stripe Dashboard' : 'Connect with Stripe'}
      </span>
    </a>
  );
};

StripeButton.propTypes = {
  onClick: PropTypes.func,
  isStripeConnected: PropTypes.bool.isRequired,
};

StripeButton.defaultProps = {
  onClick: () => {},
};

export default StripeButton;
