import { getShortDayName, isValidDate } from './formatDate';
import { WEEK } from '../config';
import checkArrayInArray from './checkArrayInArray';

const lowWeek = WEEK.map(day => day.toLowerCase());

const getNearestRecurrentDate = (startDate, byday) => {
  if (!startDate || !byday || byday.length === 0) return null;
  const lowByDay = byday.map(day => day.toLowerCase());
  if (!checkArrayInArray({ mainArr: lowWeek, Arr: lowByDay })) return null;
  if (!isValidDate(new Date(startDate))) return null;

  const nextDate = new Date(startDate);
  let count = 0;
  while ((byday.indexOf(getShortDayName(nextDate)) === -1) && count < 40) {
    nextDate.setDate(nextDate.getDate() + 1);
    count += 1;
  }

  return nextDate;
};

export default getNearestRecurrentDate;
