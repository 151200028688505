import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Switch, Route } from 'react-router-dom';

import CancelIcon from '../icons/Cancel_icon';
import styles from '../../styles/common.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import navStyles from '../../styles/navigation.module.scss';

import PaymentHistory from '../Payment_history/Payment_history_container';
import PaymentMethods from '../Payment_methods/Payment_methods_container';

const Payments = (props) => {
  const closeComponent = () => {
    const { history, location } = props;
    const from = location.state && location.state.from ? location.state.from : '/';
    history.push(from);
  };

  return (
    <div className={`${gridStyles.side_bar_wide} ${gridStyles.mobile_on_top}`}>
      <h3 className={styles.title}>Payments</h3>
      <nav className={navStyles.item_double_wrapper}>
        <ul className={navStyles.container}>
          <li className={navStyles.block_double}>
            <NavLink className={navStyles.item} to="/payment_history" activeClassName={navStyles.item_active}>
            History
            </NavLink>
          </li>
          <li className={navStyles.block_double}>
            <NavLink className={navStyles.item} activeClassName={navStyles.item_active} to="/payment_methods">
              Methods
            </NavLink>
          </li>
        </ul>
      </nav>
      <button type="button" className={styles.close} onClick={closeComponent}><CancelIcon /></button>
      <Switch>
        <Route
          exact
          path="/payment_history"
          component={PaymentHistory}
        />
        <Route
          exact
          path="/payment_methods"
          component={PaymentMethods}
        />
      </Switch>
    </div>
  );
};

Payments.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.string,
    }),
  }),
};

Payments.defaultProps = {
  history: null,
  location: null,
};

export default Payments;
