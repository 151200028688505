import { connect } from 'react-redux';
import { updateCurrentTicket } from '../../User_tickets/user_tickets_actions';
import { manualCheckin } from '../../Check-in/check-in_actions';
import UserTicketModal from './UserTicketModal';

const mSTP = state => ({
  order: state.currentTicket,
});

const mDTP = { updateCurrentTicket, manualCheckin };

export default connect(mSTP, mDTP)(UserTicketModal);
