import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import getNestedValue from '../../../utils/getNestedValue';
import SearchUserForm from '../../Search_user_form/Search_user_form';
import UserItem from './UserItem';

import styles from '../modal.module.scss';
import buttonStyles from '../../../styles/buttons.module.scss';
import transferStyles from './transfer.module.scss';

const TransferModal = (props) => {
  const {
    isModalOpen,
    searchUsers,
    itemForTransfer,
    location,
    closeModal,
  } = props;
  const [searchText, setText] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [selectedUser, selectUser] = useState(null);
  const initialMount = useRef(true);

  useEffect(() => {
    const getUsers = async () => {
      if (searchText === '') {
        setSearchResult([]);
      } else {
        const res = await searchUsers({ q: searchText });
        const result = getNestedValue(res, 'data', 'data');

        if (result && Array.isArray(result) && result.length > 0) {
          setSearchResult(result);
        } else {
          setSearchResult([]);
        }
      }
    };

    getUsers();
  }, [searchText]);

  // close modal when click on link to user public profile
  const pathname = location && location.pathname;
  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      closeModal();
    }
  }, [pathname]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setText(value);
  };

  const resetSearch = () => {
    setText('');
    selectUser(null);
  };

  const goToTransferConfirm = () => {
    const { createError, setTransferUser, setAndOpenModal } = props;
    if (!selectedUser) {
      createError('Please find and select user for transfer');
    } else {
      setTransferUser(selectedUser);
      setAndOpenModal('CONFIRM_TRANSFER_MODAL');
    }
  };

  const handleClose = () => {
    const { resetTransferInfo } = props;
    closeModal();
    resetTransferInfo();
  };

  const showSearchResult = searchText && searchText.length > 0;
  const title = getNestedValue(itemForTransfer, 'title');

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      className={styles.content}
      overlayClassName={styles.overlay}
    >
      <div className={styles.content_box_left}>
        <div className={styles.title}>
          Transfer {title && `"${title}"`} ownership
        </div>
        <SearchUserForm
          searchText={searchText}
          handleInputChange={handleInputChange}
          resetSearch={resetSearch}
        />
        {showSearchResult && (
          <>
            {searchResult && searchResult.length > 0 ? (
              <ul className={transferStyles.search_list}>
                {searchResult.map(item => (
                  <li key={item.id}>
                    <UserItem
                      user={item}
                      selectUser={selectUser}
                      checked={!!(selectedUser && selectedUser.id === item.id)}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <div className={transferStyles.search_list}>users...</div>
            )}
          </>
        )}
        <div className={buttonStyles.buttons_row}>
          <button
            type="button"
            className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
            onClick={goToTransferConfirm}
          >
            Transfer
          </button>
          <button
            type="button"
            className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

TransferModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  itemForTransfer: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  createError: PropTypes.func.isRequired,
  setTransferUser: PropTypes.func.isRequired,
  setAndOpenModal: PropTypes.func.isRequired,
  resetTransferInfo: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default TransferModal;
