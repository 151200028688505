import { connect } from 'react-redux';
import EventForm from './Event_Form';
import {
  createEvent,
  editEvent,
  addPhotos,
  onEventSubmitSuccess,
} from './event_form_actions';
import { getEventDetails } from '../Event_details/event_details_actions';

import { getDanceStyles } from '../shared/Dance_styles/dance_styles_actions';
import { moveMapCenterTo, addMarker } from '../Map/map_actions';
import createError from '../Fetch_error/fetch_error_action';
import { setAndOpenModal } from '../Modal/modal_actions';


const mSTP = state => ({
  token: state.session.token,
  user: state.session.user,
  danceStyles: state.danceStyles.styles,
});

const mDTP = dispatch => ({
  createEvent: (event, token) => (dispatch(createEvent(event, token))),
  addPhotos: (id, photos, token) => (dispatch(addPhotos(id, photos, token))),
  editEvent: (event, token) => (dispatch(editEvent(event, token))),
  getDanceStyles: () => { dispatch(getDanceStyles()); },
  showNewMarker: (coords) => {
    dispatch(moveMapCenterTo(coords));
    dispatch(addMarker(coords));
  },
  handleError: err => dispatch(createError(err)),
  onEventSubmitSuccess: options => dispatch(onEventSubmitSuccess(options)),
  getEventDetails: ({ id }) => (dispatch(getEventDetails({ id, moveMap: true }))),
  openModal: modalName => dispatch(setAndOpenModal(modalName)),
});

export default connect(mSTP, mDTP)(EventForm);
