import React from 'react';
import PropTypes from 'prop-types';
import Checkmark from '../../icons/Checkmark';

import styles from './checkbox.module.scss';

const Checkbox = (props) => {
  const { value, label, onChange, name } = props;
  return (
    <label className={styles.checkbox_container}>
      <input type="checkbox" name={name} checked={value} onChange={onChange} />
      <div className={styles.checkmark}>
        {value && <Checkmark className={styles.checkmark_icon} />}
      </div>
      {label}
    </label>
  );
};

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  label: '',
};

export default Checkbox;
