import { EVENTS, LESSONS_LABEL } from '../config';

export const getItemUrl = (match) => {
  let type;
  let url = match && match.url;
  const id = match && match.params && match.params.id;
  if (url.indexOf(`/${LESSONS_LABEL}/`) !== -1) type = LESSONS_LABEL;
  if (url.indexOf(`/${EVENTS}/`) !== -1) type = EVENTS;

  if (type && id) {
    url = `/${type}/${id}`;
  }

  return url;
};

export const isCheckinActive = match => (
  match && match.path && match.path.includes('/check-in')
);

export const isCheckedInActive = match => (
  match && match.path && match.path.includes('/checked_in')
);

export const isHostActive = match => (
  match && match.path && match.path.includes('/hosts')
);

export const isClassesActive = (match) => {
  const url = match && match.url;
  return url.indexOf(`/${LESSONS_LABEL}/`) !== -1;
};
