import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import qs from 'qs';

import styles from './marker.module.scss';
import MarkerIcon from '../icons/Marker';
import { checkMarkerDateHot } from '../../utils/createMarkers';

class SameCoordsMarker extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })).isRequired,
    pathname: PropTypes.string,
    isActive: PropTypes.bool,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    hoverId: PropTypes.string,
  }

  static defaultProps = {
    pathname: '',
    isActive: false,
    hoverId: null,
  }

  state = {
    isOpen: false,
  }

  handleClickOutside = () => {
    this.setState({
      isOpen: false,
    });
  }

  toogleOpen = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const { items, pathname, isActive, history, hoverId } = this.props;
    const { isOpen } = this.state;
    const isMarkerActive = isActive
      || items.some(item => pathname === `/${item.type}s/${item.id}` || item.id === hoverId);

    const shouldPulsate = items.some(item => checkMarkerDateHot({ item }));

    const queryObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const search = qs.stringify({ ...queryObject, day: undefined });

    return (
      <div className={styles.multi_marker} onClick={this.toogleOpen} role="button" tabIndex={0} onKeyPress={this.toogleOpen}>
        <MarkerIcon className={isMarkerActive ? styles.marker_active : styles.marker} />
        {shouldPulsate && <div className={styles.pulsation} />}
        <div className={styles.counter}>
          {items.length}
        </div>
        {isOpen && (
          <ul className={styles.marker_menu}>
            { items.map(item => (
              <li key={item.id}>
                <Link
                  to={{
                    pathname: `/${item.type}s/${item.id}`,
                    search,
                  }}
                  className={styles.menu_link}
                >
                  {item.title}
                </Link>
              </li>
            ))
            }
          </ul>
        )
        }
      </div>
    );
  }
}

export default onClickOutside(SameCoordsMarker);
