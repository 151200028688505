import { connect } from 'react-redux';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import {
  searchUsers, acceptUserCohost,
  cancelUserCohost, removeUserFromCohost, setCohostingData,
} from '../Hosting/hosting_actions';
import createError from '../Fetch_error/fetch_error_action';
import { getEventDetails } from '../Event_details/event_details_actions';
import { setAndOpenModal } from '../Modal/modal_actions';

import HostingDetails from './Hosting_details';

const mSTP = state => ({
  token: state.session.token,
  user: state.session.user,
  isModalOpen: state.modal.isModalOpen,
});

const mDTP = {
  startLoading,
  finishLoading,
  createError,
  searchUsers,
  acceptUserCohost,
  cancelUserCohost,
  removeUserFromCohost,
  setCohostingData,
  openModal: setAndOpenModal,
  getEventDetails,
};

export default connect(mSTP, mDTP)(HostingDetails);
