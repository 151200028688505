import { CURRENT_TICKET_INFO, UPDATE_CURRENT_TICKET_INFO } from '../../redux/action_types';
import { requestPassVisits } from '../../api';

export const setCurrentTicket = order => ({
  type: CURRENT_TICKET_INFO,
  payload: order,
});

const updateTicketSuccess = ticketInfo => ({
  type: UPDATE_CURRENT_TICKET_INFO,
  payload: ticketInfo,
});

export const updateCurrentTicket = ticketId => (dispatch, getState) => {
  const { session, currentTicket } = getState();
  const token = session && session.token;
  const totalVisits = currentTicket && currentTicket.total_visits;
  const visitsLeft = currentTicket && currentTicket.visits_left;
  const lastVisits = (currentTicket && currentTicket.last_visits) || [];
  const visitsIds = lastVisits.map(visit => visit && visit.id);

  if (token) {
    requestPassVisits({ ticketId, token })
      .then((response) => {
        const { data } = response;
        const ticketInfo = data && data.data;
        if (!ticketInfo) return;

        const newTotalVisits = ticketInfo && ticketInfo.total_visits;
        const newVisitsLeft = ticketInfo && ticketInfo.visits_left;
        const newLastVisits = ticketInfo && ticketInfo.last_visits;
        const visitsDiffernce = newLastVisits
          && newLastVisits.some(visit => visit && !visitsIds.includes(visit.id));
        const shouldUpdateVisits = totalVisits !== newTotalVisits
          || visitsLeft !== newVisitsLeft
          || visitsDiffernce;

        if (shouldUpdateVisits) {
          dispatch(updateTicketSuccess(ticketInfo));
        }
      });
  }
};
