import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import qs from 'qs';

import CheckoutForm from './Checkout_form/checkout_form_container';

import { getDollarsFromCents } from '../../utils/parse';
import getNestedValue from '../../utils/getNestedValue';
import { requestSubscription } from '../../api';
import makeItemLink from '../../utils/makeItemLink';

import CancelIcon from '../icons/Cancel_icon';

import buttonStyles from '../../styles/buttons.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import orderStyles from '../Ticket_order/ticket_order.module.scss';
import commonStyles from '../../styles/common.module.scss';
import styles from './checkout.module.scss';

import { FREE, PAID } from '../../config';

const INITIAL_STATE = {
  isSubscription: false,
  price: 1000,
  owner: null,
};

class Checkout extends Component {
  static propTypes = {
    token: PropTypes.string,
    order: PropTypes.shape({
      order_id: PropTypes.number,
      fee_include: PropTypes.number,
    }),
    price: PropTypes.number,
    onClose: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    handleError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    token: null,
    order: null,
    price: null,
    history: null,
    match: null,
    onClose: () => {},
  };

  state = { ...INITIAL_STATE };

  componentDidMount() {
    this.getSubscriptionInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) this.getSubscriptionInfo();
  }

  getSubscriptionInfo = async () => {
    const { handleError, history, token } = this.props;
    const search = getNestedValue(history, 'location', 'search');
    let subscriptionData;
    let subscriptionID;
    if (search) {
      const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
      subscriptionID = parseInt(queryObject.subscription_id, 10);
    }
    if (subscriptionID) {
      const response = await requestSubscription(subscriptionID, token)
        .catch((err) => {
          const errMessage = err && err.response && err.response.data.error;
          handleError(errMessage);
          history.push('/');
        });
      subscriptionData = getNestedValue(response, 'data', 'data');
      if (subscriptionData) {
        const { price, owner, status, recurrent } = subscriptionData;
        this.setState({
          isSubscription: true,
          price,
          owner,
          subscriptionID,
          status,
          recurrency: recurrent && recurrent.freq,
        });
      }
    }
  }

  onSubscriptionClose = () => {
    const { history } = this.props;
    const { owner } = this.state;
    const path = makeItemLink(owner);
    if (path) history.push(path);
    else history.push('/');
  }

  render() {
    const { order, price: ticketPrice, onClose } = this.props;
    const {
      isSubscription, subscriptionID, status, recurrency,
      price: subscriptionPrice,
    } = this.state;
    const price = isSubscription ? subscriptionPrice : ticketPrice;
    let statusToShow = status === FREE && `Your subscription is ${status}.`;
    if (status === PAID) statusToShow = `Your subscription is already ${status}.`;

    const additionalFee = order && order.additional_fee;

    return (
      <div className={`${gridStyles.wrapper_fixed_bottom} ${gridStyles.mobile_on_top}`}>
        {statusToShow && <p className={styles.status}>{statusToShow}</p>}
        <div className={statusToShow
          ? gridStyles.scroll_container_wo_buttons_with_banner
          : gridStyles.scroll_container_wo_buttons}
        >
          <h3 className={commonStyles.title}>Checkout</h3>
          <button
            type="button"
            className={buttonStyles.cancel_button}
            onClick={isSubscription ? this.onSubscriptionClose : onClose}
          >
            <CancelIcon />
          </button>
          <div className={orderStyles.total_container_up}>
            <div className={orderStyles.total_container}>
              <p className={orderStyles.total}>Total:</p>
              <p className={orderStyles.sum}>
                {additionalFee
                  ? getDollarsFromCents(price + additionalFee)
                  : getDollarsFromCents(price)
                }
              </p>
            </div>
            {additionalFee ? (
              <p className={orderStyles.side_info}>
                Including fee: {getDollarsFromCents(additionalFee)}
              </p>
            ) : null}
          </div>

          <Elements>
            <CheckoutForm
              order={isSubscription ? { id: subscriptionID } : order}
              price={price}
              recurrency={recurrency}
              onClose={isSubscription ? this.onSubscriptionClose : onClose}
              isSubscription={!!isSubscription}
            />
          </Elements>
        </div>
      </div>
    );
  }
}

export default Checkout;
