import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import styles from './tabs.module.scss';


export default class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    initialActiveTab: PropTypes.number,
  }

  static defaultProps = {
    initialActiveTab: 0,
  }

  state = {
    activeTab: this.props.children[this.props.initialActiveTab].props.label,
  }

  selectActiveTab = tab => this.setState({ activeTab: tab })

  render() {
    const { children } = this.props;
    const { activeTab } = this.state;

    return (
      <div>
        <ul className={styles.tab_wrapper}>
          {
            React.Children.map(children, (child) => {
              const { label } = child.props;

              return (
                <Tab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={this.selectActiveTab}
                />
              );
            })
          }
        </ul>
        <div>
          {React.Children.map(children, (child) => {
            if (child.props.label !== activeTab) return undefined;

            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}
