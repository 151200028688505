import React from 'react';
import PropTypes from 'prop-types';

const MinusIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 491.858 491.858"
    style={{ enableBackground: 'new 0 0 52 52' }}
    xmlSpace="preserve"
  >
    <path d="M465.167,211.613H240.21H26.69c-8.424,0-26.69,11.439-26.69,34.316s18.267,34.316,26.69,34.316h213.52h224.959
    c8.421,0,26.689-11.439,26.689-34.316S473.59,211.613,465.167,211.613z"
    />
  </svg>
);

MinusIcon.propTypes = {
  className: PropTypes.string,
};

MinusIcon.defaultProps = {
  className: '',
};

export default MinusIcon;
