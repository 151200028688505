import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SimpleItemPreview from '../shared/Simple_item_preview/Simple_item_preview';
import ReadMore from '../shared/Read_more/Read_more';
import DateInfo from '../shared/Date_info/Date_info';
import InactiveBanner from '../shared/Inactive_banner/Inactive_banner';
import BreakLineText from '../shared/Break_line_text/BreakLineText';

import detectSafari from '../../utils/detectSafari';
import getNestedValue from '../../utils/getNestedValue';

import CalendarIcon from '../icons/Calendar_thin';
import InfoIcon from '../icons/Info';
import TicketIcon from '../icons/Ticket';
import CancelIcon from '../icons/Cancel_icon';

import defaultAvatar from '../../images/default_avatar.jpg';

import styles from '../../styles/view_details.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import { INACTIVE, PASS } from '../../config';

const TEXT_EMPTY = '...';

const INITIAL_STATE = {
  isDestroyableErrorShown: false,
};

class PassDetails extends Component {
  static propTypes = {
    getPassDetails: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.string,
      }),
    }),
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    pass: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    }).isRequired,
    previewFromForm: PropTypes.bool,
    passForPreview: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    token: PropTypes.string,
    deletePass: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    previewFromForm: false,
    passForPreview: {},
    history: {},
    match: {},
    location: null,
    token: '',
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const { getPassDetails, match, previewFromForm, history } = this.props;
    if (previewFromForm) return;

    getPassDetails({
      id: match.params.id,
      moveMap: true,
      filter: history.location.search,
    });

    this.scrollToTop();
  }

  componentDidUpdate(nextProps) {
    const { previewFromForm, getPassDetails, history } = this.props;
    if (previewFromForm) return;
    if ((this.props.match.params.id !== nextProps.match.params.id)
      || (this.props.token !== nextProps.token)) {
      getPassDetails({
        id: this.props.match.params.id,
        moveMap: true,
        filter: history.location.search,
      });
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    if (this.wrapperRef.current) this.wrapperRef.current.scrollTop = 0;
    window.scrollTo(0, 0);
  }

  closePass = () => {
    const { history, location } = this.props;
    const from = location.state && location.state.from;
    const query = location && location.state && location.state.query;
    if (from) {
      const list = location.state && location.state.list;
      history.push({
        pathname: from,
        state: {
          list,
          query,
        },
        search: location.search,
      });
    } else {
      history.push({
        pathname: '/teachers',
        search: location.search,
        state: { query },
      });
    }
  }

  handleOpenEdit = () => {
    const { pass, history } = this.props;
    const { id } = pass;
    history.push({
      pathname: `/account/edit_pass/${id}`,
      state: {
        from: history.location.pathname,
      },
    });
  }

  handleOpenDelete = () => {
    const { deletePass, pass } = this.props;
    const { destroyable } = pass;
    if (destroyable && destroyable === true) {
      deletePass(pass);
    } else this.setState({ isDestroyableErrorShown: true });
  }

  render() {
    const { pass, error, previewFromForm, passForPreview, history } = this.props;
    const { isDestroyableErrorShown } = this.state;

    if (error) {
      let errorMessage = getNestedValue(error, 'response', 'data', 'error');

      if (error.response.status === 401) {
        history.push({
          pathname: '/login',
          state: { from: history.location.pathname },
        });
      }

      if (error.response.status === 403) errorMessage = 'you are not allowed to view this pass.';
      return <div className={`${gridStyles.side_bar} ${styles.container}`}>Sorry, {errorMessage}</div>;
    }
    const {
      name,
      description,
      price,
      refunds,
      items,
      teacher,
      destroyable,
      fee_include: feeInclude,
    } = previewFromForm ? passForPreview : pass;

    const refundsLabel = refunds ? 'No refund' : 'With refund';
    const teacherTitle = (teacher && teacher.title) || TEXT_EMPTY;
    const teacherLink = (teacher && teacher.id)
      ? {
        pathname: `/teachers/${teacher.id}`,
        state: { from: history.location && history.location.pathname },
      }
      : null;

    const teacherAvatar = (teacher && teacher.photos && teacher.photos[0])
      ? teacher.photos[0].full
      : defaultAvatar;

    const isItemInactive = teacher && teacher.status === INACTIVE;
    let containerStyles = previewFromForm
      ? gridStyles.scroll_container
      : gridStyles.scroll_container_pass;
    if (isItemInactive) {
      containerStyles = gridStyles.scroll_container_wo_buttons_with_inactive_banner;
    }

    return (
      <div
        className={!previewFromForm
          ? gridStyles.wrapper_fixed_bottom
          : ''
            }
      >
        {isItemInactive && (
          <InactiveBanner type={PASS} />
        )}
        <div
          className={containerStyles}
          ref={this.wrapperRef}
        >
          {!previewFromForm
            && (
            <button
              type="button"
              className={buttonStyles.cancel_button}
              onClick={this.closePass}
            >
              <CancelIcon />
            </button>
            )}
          <h1 className={detectSafari() ? styles.name_safari : styles.name}>{name}</h1>
          <div className={styles.info_container}>
            <CalendarIcon className={styles.calendar_icon} />
            <DateInfo item={previewFromForm ? passForPreview : pass} forPasses />
          </div>
          <div className={styles.info_container}>
            <TicketIcon />
            <div className={styles.info}>
              <div className={styles.text_dark}>{
                (price || price === 0)
                  ? `$${parseFloat(price, 10) / 100}`
                  : TEXT_EMPTY}
              </div>
              <div className={styles.text_light}>{refundsLabel}</div>
            </div>
          </div>
          <div className={styles.info_container}>
            <span className={styles.fee_icon}>%</span>
            <div className={styles.info}>
              <div className={styles.text_dark}>Fee: </div>
              <div className={styles.text_light}>
                {feeInclude ? 'Retain' : 'Pass on to buyer'}
              </div>
            </div>
          </div>
          <div className={styles.info_container}>
            <InfoIcon className={styles.info_icon} />
            <div className={styles.info}>
              <p className={styles.text_dark}>Pass info</p>
              <ReadMore className={styles.text_light}>
                <p className={styles.text_light}>
                  {description ? (
                    <BreakLineText>{description}</BreakLineText>
                  ) : TEXT_EMPTY}
                </p>
              </ReadMore>
            </div>
          </div>
          <div className={styles.author_container}>
            <div className={styles.avatar} style={{ backgroundImage: `url(${teacherAvatar})` }} />
            <div className={styles.author}>
              <p className={`${styles.text_light} ${styles.text_small}`}>Pass by</p>
              <div className={styles.text_dark}>
                {teacherLink
                  ? <Link to={teacherLink}>{teacherTitle}</Link>
                  : <p>{teacherTitle}</p>
                    }
              </div>
            </div>
          </div>
          {items && items.length > 0
          && (
            <>
              <h4 className={styles.subtitle}>Classes</h4>
              {items.map((ticket, index) => (
                <SimpleItemPreview
                  item={ticket}
                  detailsView
                  key={`${ticket.name}_${index.toString()}`}
                />
              ))}
            </>
          )}
          {isDestroyableErrorShown
                && (
                <div className={styles.remove_error}>
                  The pass cannot be removed as it has been purchased.
                </div>
                )
              }
        </div>
        {!previewFromForm && !isItemInactive
            && (
              <div className={buttonStyles.fixed_buttons}>
                <div className={buttonStyles.buttons_row}>
                  <button
                    type="button"
                    className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                    onClick={this.handleOpenEdit}
                  >
                    edit
                  </button>
                  <button
                    type="button"
                    className={destroyable
                      ? `${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`
                      : `${buttonStyles.btn_disabled} ${buttonStyles.btn_uppercase}`}
                    onClick={this.handleOpenDelete}
                  >
                    remove
                  </button>
                </div>
              </div>
            )}
      </div>
    );
  }
}

export default PassDetails;
