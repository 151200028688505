import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';

import SignInModal from './SignInModal';
import SuccessModal from './SuccessModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import EmailChangeRequestSucces from './EmailChangeModal';
import ChangePasswordModal from './ChangePasswordModal';
import RemoveItemModal from './RemoveItemModal';
import CheckInModal from './CheckInModal';
import UserTicketModal from './User_ticket_modal/UserTicketModal_container';
import FullScreenModal from './Full_screen_photos_modal/full_screen_container';
import CheckoutModal from './CheckoutModal';
import StripeConnectModal from './StripeConnectModal';
import CancelSubscriptionModal from './CancelSubsciptionModal';
import ChangeSubscriptionModal from './ChangeSubscriptionModal';
import RemoveCommentModal from './Remove_comment_modal/RemoveComment_container';
import SuccesTicketOrderModal from './SuccessTicketModal';
import ProfileModal from './ProfileModal';
import CohostPermissionsModal from './Cohost_permissions_modal/Cohost_permissions_container';
import ReportModal from './Report_modal/Report_modal_container';
import DeleteFriendModal from './Delete_friend_modal/Delete_friend_modal_container';
import TransferItemModal from './Transfer_modal/Transfer_modal_container';
import ConfirmTransferModal from './Transfer_modal/Confirm_transfer_modal_container';


import { TEACHER, VENUE } from '../../config';

const ModalDispatcher = ({ currentModal, ...props }) => {
  // this global variables are necessary for webview app
  const { isModalOpen, closeModal } = props;
  window.isModalOpen = isModalOpen;
  window.closeModal = closeModal;

  window.getIsModalOpen = () => {
    if (window.android && window.android.getIsModalOpen) {
      window.android.getIsModalOpen(isModalOpen);
    }

    return isModalOpen;
  };
  // end of webview variables

  switch (currentModal) {
    case 'SIGN_IN_MODAL':
      return <SignInModal {...props} />;

    case 'SUCCES_REGISTRATION_MODAL':
      return <SuccessModal {...props} text="Please, check your email to complete registration." />;

    case 'FORGOT_PASSWORD_MODAL':
      return <ForgotPasswordModal {...props} />;

    case 'CHANGE_EMAIL_REQUEST_SUCCES':
      return <EmailChangeRequestSucces {...props} />;

    case 'CHANGE_PASSWORD_MODAL':
      return <ChangePasswordModal {...props} />;

    case 'REMOVE_ITEM_MODAL':
      return <RemoveItemModal {...props} />;

    case 'TICKET_ORDER_SUCCESS':
      return <SuccesTicketOrderModal {...props} title="Thank you!" text="Your order was successful." />;

    case 'PAYMENT_SUCCESS':
      return <SuccessModal {...props} title="Thank you!" text="Your payment was successful." />;

    case 'TICKET_PAYMENT_SUCCESS':
      return <SuccesTicketOrderModal {...props} title="Thank you!" text="Your payment was successful." />;

    case 'PAYMENT_FAILURE':
      return <SuccessModal {...props} text="There was a problem with the payment service. Please select a different payment method or try again later." title="We’re sorry…" />;

    case 'USER_TICKET_MODAL':
      return <UserTicketModal {...props} />;

    case 'USER_PASS_MODAL':
      return <UserTicketModal {...props} forPasses />;

    case 'FEEDBACK_SUCCESS_MODAL':
      return <SuccessModal {...props} />;

    case 'VENUE_CHECKOUT_MODAL':
      return <CheckoutModal {...props} type={VENUE} />;

    case 'TEACHER_CHECKOUT_MODAL':
      return <CheckoutModal {...props} type={TEACHER} />;

    case 'FULL_SCREEN_PHOTOS_MODAL':
      return <FullScreenModal {...props} />;

    case 'SUCCES_MODAL_MESSAGE':
      return <SuccessModal {...props} />;

    case 'CHECK-IN_SUCCESS_MODAL':
      return <CheckInModal {...props} />;

    case 'CHECK-IN_FAILURE_MODAL':
      return <CheckInModal {...props} />;

    case 'STRIPE_CONNECT_MODAL':
      return <StripeConnectModal {...props} />;

    case 'CANCEL_SUBSCRIPTION_MODAL':
      return <CancelSubscriptionModal {...props} />;

    case 'CHANGE_SUBSCRIPTION_MODAL':
      return (
        <Elements>
          <ChangeSubscriptionModal {...props} />
        </Elements>
      );

    case 'REMOVE_COMMENT_MODAL':
      return <RemoveCommentModal {...props} />;

    case 'PROFILE_MODAL':
      return <ProfileModal {...props} />;

    case 'COHOST_PERMISSIONS_MODAL':
      return <CohostPermissionsModal {...props} />;

    case 'REPORT_MODAL':
      return <ReportModal {...props} />;

    case 'DELETE_FRIEND_MODAL':
      return <DeleteFriendModal {...props} />;

    case 'TRANSFER_ITEM_MODAL':
      return <TransferItemModal {...props} />;

    case 'CONFIRM_TRANSFER_MODAL':
      return <ConfirmTransferModal {...props} />;

    default:
      return null;
  }
};

ModalDispatcher.propTypes = {
  currentModal: PropTypes.string,
};

ModalDispatcher.defaultProps = {
  currentModal: '',
};


export default ModalDispatcher;
