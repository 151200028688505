import { connect } from 'react-redux';
import { getVenueItems, getTeacherLessons } from './list_with_date_filter_actions';
import { getVenueDetails } from '../../Venue_details/venue_details_actions';
import { getTeacherDetails } from '../../Teacher_details/teacher_details_actions';
import ListWithDateFilter from './List_with_date_filter';

const mDTP = dispatch => ({
  getVenueItems: ({ id, day }) => dispatch(getVenueItems({ id, day })),
  getTeacherLessons: ({ id, day }) => dispatch(getTeacherLessons({ id, day })),
  getVenueDetails: ({ id, moveMap, filter }) => dispatch(getVenueDetails({ id, moveMap, filter })),
  getTeacherDetails:
  ({ id, moveMap, filter }) => dispatch(getTeacherDetails({ id, moveMap, filter })),
});

export default connect(null, mDTP)(ListWithDateFilter);
