import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Select from 'react-select';
import getNestedValue from '../../../utils/getNestedValue';
import { validateFeedback, validateEmail } from '../../../utils/validation';

import TextArea from '../../shared/Textarea/Textarea';
import TextInput from '../../shared/Text_input/Text_input';

import buttonStyles from '../../../styles/buttons.module.scss';
import styles from '../modal.module.scss';
import customSelectStyles, { selectErrorStyles, selectActiveStyles } from '../../../styles/select_styles';
import inputStyles from '../../../styles/inputs.module.scss';
import { LESSON, LESSON_LABEL } from '../../../config';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

const INITIAL_STATE = {
  title: { ...INITIAL_STATE_FIELD, isValid: true },
  email: { ...INITIAL_STATE_FIELD },
  text: { ...INITIAL_STATE_FIELD },
  subjectOptions: [],
};


export default class ReportModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    resetReportData: PropTypes.func.isRequired,
    reportData: PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
      link: PropTypes.string,
    }).isRequired,
    sendReport: PropTypes.func.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string,
    }),
    createError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: null,
  }

  state = { ...INITIAL_STATE }

  componentDidMount() {
    this.createSubjectOptions();
    this.setInitialValue();
  }

  componentWillUnmount() {
    const { resetReportData } = this.props;
    resetReportData();
  }

  createSubjectOptions = () => {
    const { reportData } = this.props;
    const { type = '', title = '' } = reportData;
    const typeToShow = type === LESSON ? LESSON_LABEL : type;
    const options = [
      {
        value: `Duplicate ${typeToShow} «${title}»`,
        label: `Duplicate ${typeToShow}`,
      },
      {
        value: `Details inaccurate in «${title}» ${typeToShow}`,
        label: 'Details inaccurate',
      },
      {
        value: `Inappropriate content in «${title}» ${typeToShow}`,
        label: 'Inappropriate content',
      },
      {
        value: `Report ${typeToShow} «${title}»`,
        label: 'Other',
      },
    ];

    this.setState({ subjectOptions: options });

    return options;
  }

  handleSelect = (value) => {
    this.setState({
      title: {
        value,
        isValid: true,
        isActive: true,
        error: null,
      },
    });
  }

  setInitialValue = () => {
    const { reportData, user } = this.props;
    const link = getNestedValue(reportData, 'link');
    const title = getNestedValue(reportData, 'title');
    const type = getNestedValue(reportData, 'type');
    const email = getNestedValue(user, 'email');

    this.setState({
      text: {
        isActive: true,
        isValid: true,
        value: `I am writing to complain about ${type} «${title}». (${link})`,
        error: null,
      },
    });

    if (email) {
      this.setState({
        email: {
          value: email,
          isValid: validateEmail(email),
          isActive: true,
          error: null,
        },
      });
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let isValid = false;
    switch (name) {
      case 'email': {
        isValid = validateEmail(value);
        break;
      }
      case 'text': {
        isValid = validateFeedback(value);
        break;
      }
      default: return;
    }
    this.setState({
      [name]: {
        isActive: true,
        isValid,
        value,
        error: null,
      },
    });
  };

  activateAllFields = () => {
    this.activateField('subject');
    this.activateField('email');
    this.activateField('text');
  }

  activateField = field => this.setState(prevState => ({
    [field]: {
      ...prevState[field],
      isActive: true,
    },
  }));

  setErrors = (errors) => {
    const { createError } = this.props;
    let errorString = '';
    Object.keys(errors).forEach((item) => {
      const errorText = errors[item].join(', ');
      errorString = errorString.length > 0
        ? `${errorString}; ${item}: ${errorText}`
        : `${item}: ${errorText}`;
      if (this.state[item]) {
        this.setState(prevState => ({
          ...prevState,
          [item]: {
            ...prevState[item],
            isValid: false,
            error: errors[item].join(', '),
          },
        }));
      }
    });
    if (errorString.length > 0) createError(errorString);
  };

  validateTitle = () => {
    const { title } = this.state;
    const isValid = title && title.value;
    if (isValid) {
      this.setState(prevState => ({
        title: {
          ...prevState.type,
          isValid,
        },
      }));
    } else {
      this.setState(prevState => ({
        title: {
          ...prevState.type,
          isValid,
          isActive: false,
        },
      }));
    }
    return isValid;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.activateAllFields();
    const { title, text, email } = this.state;
    const { sendReport } = this.props;
    const isValidForm = title.isValid && text.isValid && email.isValid && this.validateTitle();
    if (!isValidForm) return;

    const report = {
      title: title.value && title.value.value,
      email: email.value,
      text: text.value,
      feedback_type: 'report',
    };

    sendReport(report)
      .then((data) => {
        if (data && data.errors) {
          this.setErrors(data.errors);
        }
      });
  }

  render() {
    const { reportData, isModalOpen, closeModal } = this.props;
    const { email, title, text, subjectOptions } = this.state;
    const { value: subjectValue, isValid, isActive } = title;
    let selectStyles = isValid ? customSelectStyles : selectErrorStyles;
    if (isActive) selectStyles = selectActiveStyles;

    const itemTitle = getNestedValue(reportData, 'title');
    const type = getNestedValue(reportData, 'type');
    const typeToShow = type === LESSON ? LESSON_LABEL : type;

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className={styles.content}
        overlayClassName={styles.overlay}
      >
        <div className={styles.content_box}>
          <div className={styles.title}>
            What are you reporting?
          </div>
          <div className={styles.text_grey}>
            <span className={styles.label}>{typeToShow}: </span>
            <span className={styles.subtitle}>&quot;{itemTitle}&quot;</span>
          </div>
          <form onSubmit={this.handleSubmit}>
            <Select
              value={subjectValue}
              onChange={this.handleSelect}
              options={subjectOptions}
              className={inputStyles.safari_input}
              styles={selectStyles}
              placeholder="Subject"
              isSearchable={false}
            />
            <TextInput
              inputName="email"
              stateField={email}
              onChange={this.handleInputChange}
              placeholder="Return Email"
            />
            <TextArea
              handleInputChange={this.handleInputChange}
              stateObject={text}
              name="text"
              resizable
            />
            <div className={buttonStyles.buttons_row}>
              <button
                type="submit"
                className={`${buttonStyles.btn_blue} ${buttonStyles.btn_uppercase}`}
                onClick={this.inviteUser}
              >
                Submit
              </button>
              <button
                type="button"
                className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}
