import React from 'react';
import PropTypes from 'prop-types';

const AddedFriend = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 37 37">
    <path fillRule="evenodd" d="M27.173 31.908H16.602l1.722-1.73a5.114 5.114 0 0 0 0-7.194 5.034 5.034 0 0 0-1.749-1.138c.011-.02.032-.039.041-.06.182-.463.333-.922.477-1.396.113-.356.178-.704.636-.803.041-.008.084-.056.126-.1a.47.47 0 0 0 .096-.25c-.018-1.146.251-2.357-.595-3.352-.034-.033-.038-.079-.059-.119-.062-.135-.104-.277-.155-.417.029-.005.058-.007.087-.011a5.229 5.229 0 0 1-.254-.947c-.065-.449-.314-.864-.446-1.304a33.225 33.225 0 0 1-.444-1.785c-.047-.217-.02-.449-.054-.671-.051-.324-.045-.573.341-.698.106-.034.197-.291.204-.448.013-.957 0-1.918.005-2.878.008-.587 0-1.179.048-1.765.078-1.119.733-1.917 1.558-2.594 1.2-.995 2.642-1.462 4.124-1.812.771-.185 1.565-.297 2.352-.428.209-.034.433 0 .648 0 .033.052.073.104.104.164-.164.119-.353.224-.491.362-.321.324-.295.548.052.843.715.607 1.422 1.212 2.124 1.837.608.554.845 1.279.845 2.088 0 1.397.007 2.794 0 4.183 0 .289-.02.573.334.697.072.027.15.184.145.27-.059.718-.138 1.43-.211 2.148 0 .025-.005.059-.02.077-.642.936-.72 2.089-1.179 3.089-.02.04-.025.086-.059.119-.356.421-.51.88-.575 1.357h.007c-.09.65-.011 1.333-.021 1.995 0 .079.043.175.096.25.042.044.086.092.127.1.457.099.523.447.635.803.144.474.295.933.478 1.396.052.138.197.29.334.349.807.355 1.632.677 2.444 1.02.204.087.405.177.609.263.087.036.175.069.263.106a.059.059 0 0 0-.014.002c.306.128.609.264.914.394l-.004.002c.272.117.547.226.814.353.866.42 1.712.89 2.564 1.35.504.269.976.573 1.369.942v5.341h-9.822zM15.95 27.794l-8.661 8.705a1.695 1.695 0 0 1-1.208.503c-.456 0-.885-.179-1.207-.503l-4.378-4.4a1.724 1.724 0 0 1 0-2.427 1.704 1.704 0 0 1 2.415 0l3.17 3.187 7.454-7.492a1.704 1.704 0 0 1 2.415 0c.666.67.666 1.758 0 2.427z" />
  </svg>
);

AddedFriend.propTypes = {
  className: PropTypes.string,
};

AddedFriend.defaultProps = {
  className: '',
};

export default AddedFriend;
