import React from 'react';
import PropTypes from 'prop-types';

const PinIcon = ({ className }) => (
  className ? (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 25 34">
      <path d="M17.227 27.559c-1.099 1.448-2.345 3.09-3.776 4.996a1.198 1.198 0 0 1-.955.477c-.373 0-.73-.178-.955-.477a1238.239 1238.239 0 0 0-3.78-5l-.015-.017C1.331 19.089-.005 17.331-.005 12.891-.005 6.006 5.603.405 12.496.405c6.892 0 12.5 5.601 12.5 12.486 0 4.433-1.338 6.197-7.769 14.668zM12.496 2.79C6.92 2.79 2.383 7.321 2.383 12.891c0 3.637 1.108 5.099 7.266 13.205.845 1.113 1.796 2.366 2.847 3.759 1.031-1.367 1.969-2.603 2.803-3.702l.026-.034c6.172-8.131 7.285-9.597 7.285-13.228 0-5.57-4.537-10.101-10.114-10.101zm0 16.94c-3.775 0-6.847-3.067-6.847-6.839 0-3.771 3.072-6.839 6.847-6.839 3.776 0 6.847 3.068 6.847 6.839 0 3.772-3.071 6.839-6.847 6.839zm0-11.293a4.462 4.462 0 0 0-4.46 4.454 4.462 4.462 0 0 0 4.46 4.455 4.463 4.463 0 0 0 4.461-4.455 4.462 4.462 0 0 0-4.461-4.454z" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="34">
      <path fill="#8F9AB0" d="M17.227 27.559c-1.099 1.448-2.345 3.09-3.776 4.996a1.198 1.198 0 0 1-.955.477c-.373 0-.73-.178-.955-.477a1238.239 1238.239 0 0 0-3.78-5l-.015-.017C1.331 19.089-.005 17.331-.005 12.891-.005 6.006 5.603.405 12.496.405c6.892 0 12.5 5.601 12.5 12.486 0 4.433-1.338 6.197-7.769 14.668zM12.496 2.79C6.92 2.79 2.383 7.321 2.383 12.891c0 3.637 1.108 5.099 7.266 13.205.845 1.113 1.796 2.366 2.847 3.759 1.031-1.367 1.969-2.603 2.803-3.702l.026-.034c6.172-8.131 7.285-9.597 7.285-13.228 0-5.57-4.537-10.101-10.114-10.101zm0 16.94c-3.775 0-6.847-3.067-6.847-6.839 0-3.771 3.072-6.839 6.847-6.839 3.776 0 6.847 3.068 6.847 6.839 0 3.772-3.071 6.839-6.847 6.839zm0-11.293a4.462 4.462 0 0 0-4.46 4.454 4.462 4.462 0 0 0 4.46 4.455 4.463 4.463 0 0 0 4.461-4.455 4.462 4.462 0 0 0-4.461-4.454z" />
    </svg>
  )
);

PinIcon.propTypes = {
  className: PropTypes.string,
};

PinIcon.defaultProps = {
  className: '',
};

export default PinIcon;
