import React from 'react';
import PropTypes from 'prop-types';
import { getDateStringWithDots } from '../../utils/formatDate';
import { getDollarsFromCents } from '../../utils/parse';
import styles from './payment_history.module.scss';

const HistoryItem = ({ order }) => (
  <div className={styles.history_item}>
    <div>
      <p className={styles.title}>{order.title}</p>
      <p className={styles.date}>
        {order.owner_type === 'subscription' ? 'Subscription#:' : 'Order#:'} {order.owner_id}
      </p>
      <p className={styles.date}>{getDateStringWithDots(order.created_at)}</p>
    </div>
    <div>
      <div className={order.status === 'paid' ? styles.price : styles.price__declined}>
        {getDollarsFromCents(order.amount)}
      </div>
      {order.status && order.status !== 'completed' ? (
        <div className={styles.status}>
          {order.status}
        </div>
      ) : null}
    </div>
  </div>
);

HistoryItem.propTypes = {
  order: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.number,
    owner_id: PropTypes.number,
    created_at: PropTypes.string,
    amount: PropTypes.number,
    status: PropTypes.string,
    owner_type: PropTypes.string,
  }).isRequired,
};

export default HistoryItem;
