import React from 'react';
import PropTypes from 'prop-types';

const ShareIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 76" width="28" height="25">
    <path fill="#000" fillOpacity="0" d="M0 0h80v76H0z" />
    <path fill="#07E" d="M59.5 7c3 0 5.5 2.5 5.5 5.5S62.5 18 59.5 18c-1.5 0-2.9-.6-3.9-1.7l-1.1-1.2-.3-1.6c-.2-.2-.2-.6-.2-1 0-3 2.5-5.5 5.5-5.5zM18 26c2.9 0 5.7 1.1 7.8 3.2l1.5 1.5.8 2c.6 1.3.8 2.8.8 4.2 0 1.2-.2 2.4-.6 3.6l-.7 2.1-1.5 1.6c-2 2.5-5 3.8-8.1 3.8-6.1 0-11-4.9-11-11s4.9-11 11-11zm47.5 28c4.1 0 7.5 3.4 7.5 7.5S69.6 69 65.5 69 58 65.6 58 61.5c0-.5 0-1 .1-1.5l.4-1.8 1.2-1.4c1.4-1.8 3.5-2.8 5.8-2.8zm-6-54C52.6 0 47 5.6 47 12.5c0 .9.1 1.7.3 2.6l-16.5 9.2C27.5 21 23 19 18 19 8.1 19 0 27.1 0 37s8.1 18 18 18c5.3 0 10.1-2.3 13.4-6l19.8 9.7c-.2.9-.3 1.9-.3 2.8 0 8 6.5 14.5 14.5 14.5S80 69.5 80 61.5 73.5 47 65.5 47c-4.5 0-8.6 2.1-11.2 5.3L35 42.9c.6-1.9 1-3.8 1-5.9 0-2.4-.5-4.8-1.4-6.9l15.9-8.9c2.3 2.3 5.4 3.8 8.9 3.8 7 0 12.6-5.6 12.6-12.5S66.4 0 59.5 0z" />
  </svg>
);

ShareIcon.propTypes = {
  className: PropTypes.string,
};

ShareIcon.defaultProps = {
  className: '',
};

export default ShareIcon;
