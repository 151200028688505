import { connect } from 'react-redux';
import CommentsBlock from './Comments_block';
import { setAndOpenModal } from '../Modal/modal_actions';
import { createComment, setCommentToDelete, editComment } from './comments_actions';

const mSTP = (state) => {
  const { token } = state.session;
  const { error } = state.comments;

  return ({
    token,
    error,
  });
};

const mDTP = dispatch => ({
  createComment: ({ comment, id, type }) => dispatch(
    createComment({ comment, id, type }),
  ),
  openModal: (modalName) => { dispatch(setAndOpenModal(modalName)); },
  setCommentToDelete: (settings) => { dispatch(setCommentToDelete(settings)); },
  editComment: settings => dispatch(editComment(settings)),
});

export default connect(mSTP, mDTP)(CommentsBlock);
