import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CancelIcon from '../icons/Cancel_icon';
import getNestedValue from '../../utils/getNestedValue';
import NotificationItem from './Notification';
import { DISPLAYED, READ } from '../../config';

import gridStyles from '../../styles/main_grid.module.scss';
import commonStyles from '../../styles/common.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import styles from './notifications.module.scss';

class NotificationsList extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    notifications: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      image: PropTypes.string,
      createdAt: PropTypes.string,
      type: PropTypes.string,
    })),
    updateAllNotifications: PropTypes.func.isRequired,
    notificationsCount: PropTypes.number,
    getNotifications: PropTypes.func.isRequired,
  };

  static defaultProps = {
    notifications: [],
    notificationsCount: 0,
  };

  state = {
    serverTime: null,
  }

  componentDidMount() {
    this.markAllNotificationsShowen();
  }

  componentDidUpdate(prevProps) {
    const prevCount = prevProps.notificationsCount;
    const count = this.props.notificationsCount;
    if (prevCount !== count) {
      this.getNotificationsList();
    }
  }

  componentWillUnmount() {
    this.markAllNotificationsShowen();
  }

  getNotificationsList = () => {
    const { getNotifications } = this.props;
    if (getNotifications) getNotifications().then(this.setServerTime);
  }

  setServerTime = (res) => {
    const serverTime = getNestedValue(res, 'headers', 'date');
    if (serverTime) this.setState({ serverTime });
  }

  markAllNotificationsShowen = () => {
    const { updateAllNotifications } = this.props;
    if (updateAllNotifications) {
      updateAllNotifications(DISPLAYED).then(this.setServerTime);
    }
  }

  markAllNotificationsRead = () => {
    const { updateAllNotifications } = this.props;
    if (updateAllNotifications) {
      updateAllNotifications(READ).then(this.setServerTime);
    }
  }

  handleClose = () => {
    const { history, location } = this.props;
    const from = getNestedValue(location, 'state', 'from') || '/';
    history.push(from);
  };

  render() {
    const { notifications } = this.props;
    const { serverTime } = this.state;

    return (
      <div className={`${gridStyles.side_bar_wide} ${gridStyles.mobile_on_top}`}>
        <h3 className={commonStyles.page_title}>Notifications</h3>
        <button
          type="button"
          className={buttonStyles.cancel_button}
          onClick={this.handleClose}
        >
          <CancelIcon />
        </button>
        <div className={styles.text_right_container}>
          <button
            type="button"
            className={styles.mark_all_btn}
            onClick={this.markAllNotificationsRead}
          >
            Mark all as read
          </button>
        </div>
        { notifications && notifications.length > 0 ? (
          <ul>
            {notifications.map(notification => (
              <li key={notification.id}>
                <NotificationItem
                  notification={notification}
                  serverTime={serverTime}
                  {...this.props}
                />
              </li>
            ))}
          </ul>
        ) : 'You have no notifications right now.'}
      </div>
    );
  }
}

export default NotificationsList;
