import {
  requestCreateEvent,
  requestEditEvent,
  requestAddPhotos,
} from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { getItemsList } from '../List/list_actions';
import { setAndOpenModal, setModalMessage } from '../Modal/modal_actions';
import { signOut } from '../login/login_actions';
import createError from '../Fetch_error/fetch_error_action';
import { addMarker } from '../Map/map_actions';

import getNestedValue from '../../utils/getNestedValue';

export const createEvent = event => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestCreateEvent(event, token)
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      dispatch(createError(rej));
      return rej.response;
    })
    .finally(() => dispatch(finishLoading()));
};

export const onEventSubmitSuccess = options => (dispatch, getState) => {
  const { uploadPhotos, action, isItemFree, isViewedByOwner } = options;
  if (action === 'create' && uploadPhotos) {
    dispatch(setModalMessage('Event was created successfully.'));
  }

  if (action === 'create' && !uploadPhotos) {
    dispatch(setModalMessage('Event was created successfully, but we need more time to process the photos. Please check or try again later by editing event, if something went wrong...'));
  }

  if (action === 'edit' && uploadPhotos) {
    dispatch(setModalMessage('Event was edited successfully.'));
  }

  if (action === 'edit' && !uploadPhotos) {
    dispatch(setModalMessage('Event was edited successfully, but we need more time to process the photos. Please check or try again later by editing event, if something went wrong...'));
  }

  if ((action === 'create' || action === 'edit') && isViewedByOwner) {
    const { user } = getState().session;
    const connectStatus = !!user.connect;
    const chargesStatus = getNestedValue(user, 'connect', 'charges_enabled');
    const payoutsStatus = getNestedValue(user, 'connect', 'payouts_enabled');
    const forwardUserToStripe = !connectStatus || !chargesStatus || !payoutsStatus;
    if (forwardUserToStripe && !isItemFree) {
      dispatch(setAndOpenModal('STRIPE_CONNECT_MODAL'));
    } else dispatch(setAndOpenModal('SUCCES_MODAL_MESSAGE'));
    dispatch(getItemsList({ list: 'events' }));
  }

  dispatch(addMarker(null));
};

export const addPhotos = (id, photos) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestAddPhotos({ id, photos, token, type: 'events' })
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      if (rej.response && rej.response.status === 413) dispatch(createError('Files are too big. Please try again by editing event ...'));
      return rej.response && rej.response.data;
    })
    .finally(() => dispatch(finishLoading()));
};

export const editEvent = event => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestEditEvent(event, token)
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 403) {
        dispatch(createError('Sorry, editing event is forbidden.'));
        return rej.response && rej.response.data;
      }
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      dispatch(createError(rej));
      return rej;
    })
    .finally(() => dispatch(finishLoading()));
};
