export const validateEmail = (email) => {
  const re = /^([\w-+]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

  return re.test(email);
};

export const validatePassword = password => (
  password && password.length > 5 && password.length < 73
);

export const validateText = value => !!value && value.trim().length > 0;

export const validateUserName = value => value.trim().length > 1;

export const validateTitle = value => validateText(value) && value.length > 2 && value.length < 72;

export const validateDescription = value => validateText(value) && value.length > 5;

export const validateFeedbackTitle = value => validateText(value)
  && value.length >= 6 && value.length <= 72;

export const validateFeedback = value => validateText(value)
  && value.length >= 6 && value.length <= 4000;

export const validateTicket = (ticket) => {
  const {
    name,
    description,
    price,
    refunds,
    fee_include: feeInclude,
  } = ticket;

  const validName = validateTitle(name);
  const validDescription = validateText(description);
  let validPrice = !Number.isNaN(parseFloat(price)) && Number.isFinite(price);
  if (feeInclude) {
    validPrice = price === 0 || price >= 50;
  }
  const validRefunds = refunds === false || refunds === true;
  const validFeeInclude = feeInclude === false || feeInclude === true;

  return !!(
    validName && validDescription
    && validPrice && validRefunds && validFeeInclude
  );
};

export const checkUserCreatAbilyti = user => (
  user && !!user.first_name
  && !!user.last_name
  && !!user.nickname
  && !!user.profession
  && !!user.avatar
);
