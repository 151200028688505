import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAndOpenModal } from '../../Modal/modal_actions';
import { setReportData } from '../../Modal/Report_modal/report_actions';
import { setItemToDelete } from '../../My_items/my_items_actions';
import { setTransferItem } from '../../Modal/Transfer_modal/transfer_actions';

import DotsSubmenu from './Dots_submenu';

const mDTP = {
  setAndOpenModal,
  setReportData,
  setItemToDelete,
  setTransferItem,
};

export default withRouter(connect(null, mDTP)(DotsSubmenu));
