import { requestOrderTickets } from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { signOut } from '../login/login_actions';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import createError from '../Fetch_error/fetch_error_action';

import getNestedValue from '../../utils/getNestedValue';

const orderTickets = (tickets, token) => (dispatch) => {
  dispatch(startLoading());
  return requestOrderTickets(tickets, token)
    .then((response) => {
      if (response.status === 201 && response.data) {
        const orderStatus = getNestedValue(response, 'data', 'data', 'status');
        if (orderStatus === 'paid') {
          dispatch(setCurrentModal('TICKET_ORDER_SUCCESS'));
          dispatch(openModal());
        }
        return response.data;
      } return null;
    })
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) {
        dispatch(signOut());
        dispatch(setCurrentModal('SIGN_IN_MODAL'));
        dispatch(openModal());
      }
      dispatch(createError(rej));
      return null;
    })
    .finally(() => dispatch(finishLoading()));
};

export default orderTickets;
