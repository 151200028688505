import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styles from './modal.module.scss';
import Tabs from '../shared/Tabs/Tabs';
import LoginForm from '../login/Login_form';
import SignInForm from '../login/Sign_in_form';
import CancelIcon from '../icons/Cancel_icon';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const SignInModal = ({ isModalOpen, closeModal }) => (
  <Modal
    isOpen={isModalOpen}
    onRequestClose={closeModal}
    className={styles.content_mobile_full_width}
    overlayClassName={styles.overlay}
  >
    <Tabs initialActiveTab={1}>
      <div label="Sign Up">
        <div className={styles.content_box}>
          <SignInForm />
        </div>
      </div>
      <div label="Log In">
        <div className={styles.content_box}>
          <LoginForm />
        </div>
      </div>
    </Tabs>
    <div className={styles.close_button_box}>
      <button type="button" onClick={closeModal}>
        <CancelIcon className={styles.close_icon} />
      </button>
    </div>
  </Modal>
);

SignInModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SignInModal;
