import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { injectStripe } from 'react-stripe-elements';

import SelectPaymentMethod from '../shared/Select_payment_method/Select_payment_method_container';

import { requestUpdateSubscription, requestSubscription } from '../../api';
import getNestedValue from '../../utils/getNestedValue';
import { validateTitle } from '../../utils/validation';

import styles from './modal.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';
import cardStyles from '../shared/Card_section/card_section.module.scss';


if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const INITIAL_STATE_FIELD = {
  isActive: false,
  isValid: false,
  value: '',
  error: null,
};

const INITIAL_STATE = {
  updateIsSuccessful: false,
  isLoading: false,
  cardError: '',
  cardId: '',
  name: { ...INITIAL_STATE_FIELD },
};

class ChangeSubscriptionModal extends Component {
  static propTypes = {
    stripe: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    token: PropTypes.string,
    subscriptionToUpdate: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    resetSubscriptionToUpdate: PropTypes.func.isRequired,
    startLoading: PropTypes.func.isRequired,
    finishLoading: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    createError: PropTypes.func.isRequired,
    getMySubscriptions: PropTypes.func.isRequired,
  };

  static defaultProps = {
    token: null,
  }

  state = { ...INITIAL_STATE };

  componentDidMount() {
    this.getSubscriptionPaymentMethod();
  }

  getSubscriptionPaymentMethod = async () => {
    const { subscriptionToUpdate, token, createError, closeModal } = this.props;

    if (subscriptionToUpdate && subscriptionToUpdate.id) {
      const response = await requestSubscription(subscriptionToUpdate.id, token)
        .catch((err) => {
          const errMessage = err && err.response && err.response.data.error;
          createError(errMessage);
          closeModal();
        });
      const subscriptionData = getNestedValue(response, 'data', 'data');
      const cardId = getNestedValue(subscriptionData, 'payment_method', 'id');
      this.setState({ cardId });
    } else {
      closeModal();
      createError('Subscription updating failed. Please try again later...');
    }
  }

  handleUpdateSubscription = () => {
    const { isLoading, cardId } = this.state;
    const { token, startLoading, signOut } = this.props;
    if (isLoading) return;
    if (!token) signOut();
    this.setState({ isLoading: true });
    startLoading();
    if (cardId === 'new' || !cardId) this.handleUpdateByNewCard();
    else this.handleUpdateByCardId();
  }

  handleUpdateByNewCard = () => {
    const { stripe, createError } = this.props;
    const { name } = this.state;
    if (!name.isValid) {
      this.resetLoader();
      this.setState(prevState => ({
        name: {
          ...prevState.name,
          isActive: true,
        },
      }));
      return;
    }
    stripe.createToken({ name: name.value })
      .then((stripeData) => {
        const { token: stripeToken } = stripeData;
        if (stripeToken && stripeToken.id) {
          const data = { new_card_token: stripeToken.id };
          this.sendRequest(data);
          return;
        }
        this.resetLoader();
        createError('Something went wrong. Please try again later...');
      });
  }

  handleUpdateByCardId = () => {
    const { cardId } = this.state;
    const data = { new_card_id: cardId };
    this.sendRequest(data);
  }

  sendRequest = (data) => {
    const { token, subscriptionToUpdate, signOut, createError, getMySubscriptions } = this.props;
    const id = getNestedValue(subscriptionToUpdate, 'id');
    if (!id) {
      createError();
      this.resetLoader();
    }
    requestUpdateSubscription(id, token, data)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            updateIsSuccessful: true,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        const status = getNestedValue(error, 'response', 'status');
        if (status === 401) {
          this.setState({ updateIsSuccessful: false });
          signOut();
        }
        const errorMessage = getNestedValue(error, 'response', 'data', 'error') || 'Subscription updating failed. Please try again later...';
        createError(errorMessage);
      })
      .finally(() => {
        getMySubscriptions();
        this.resetLoader();
      });
  }

  resetLoader = () => {
    const { finishLoading } = this.props;
    this.setState({ isLoading: false });
    finishLoading();
  }

  handleSelectMethod = (e) => {
    const { value } = e.target;
    this.setState({ cardId: value });
  }

  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({
      name: {
        isActive: true,
        isValid: validateTitle(value),
        value,
        error: null,
      },
    });
  };

  handleClose = () => {
    const { closeModal, resetSubscriptionToUpdate } = this.props;
    resetSubscriptionToUpdate();
    closeModal();
  }

  render() {
    const { updateIsSuccessful, name, cardId } = this.state;
    const { isModalOpen, subscriptionToUpdate } = this.props;
    const type = getNestedValue(subscriptionToUpdate, 'owner', 'type');
    const title = getNestedValue(subscriptionToUpdate, 'owner', 'title');
    const nameStyle = name.isValid || !name.isActive
      ? cardStyles.card_input
      : cardStyles.card_input_invalid;

    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={this.handleClose}
        className={styles.content}
        overlayClassName={styles.overlay}
      >
        <div className={styles.content_box}>
          {updateIsSuccessful
            ? (
              <>
                {title && type
                  ? (
                    <>
                      <div className={styles.title}>
                        Payment method for {type.charAt(0).toUpperCase()}{type.slice(1)}
                        <br />
                        {`"${title}"`}
                        <br />
                        was updated succesfully.
                      </div>
                    </>
                  ) : (
                    <div className={styles.title_with_text}>
                      Payment method was updated succesfully.
                    </div>
                  )
                }
                <button
                  type="button"
                  className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                  onClick={this.handleClose}
                >
                  close
                </button>
              </>
            ) : (
              <>
                {title && type
                  ? (
                    <>
                      <div className={styles.title_with_text}>
                        {`Please change payment method for your subscription to ${type}`}
                        <br />
                        {`"${title}"?`}
                      </div>
                    </>
                  )
                  : (
                    <div className={styles.title_with_text}>
                      Please change payment method for your subscription
                    </div>
                  )
                }
                <SelectPaymentMethod
                  nameStyle={nameStyle}
                  handleInputChange={this.handleInputChange}
                  handleSelectMethod={this.handleSelectMethod}
                  cardId={cardId}
                />
                <div className={`${buttonStyles.buttons_row} ${styles.wrapper}`}>
                  <button
                    type="button"
                    className={`${buttonStyles.btn_red} ${buttonStyles.btn_uppercase}`}
                    onClick={this.handleUpdateSubscription}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className={`${buttonStyles.btn_black} ${buttonStyles.btn_uppercase}`}
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
        </div>
      </Modal>
    );
  }
}

export default injectStripe(ChangeSubscriptionModal);
