import { connect } from 'react-redux';
import { getEventDetails } from './event_details_actions';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import { addLike, removeLike } from '../List/list_actions';
import { setFSphotos } from '../Modal/Full_screen_photos_modal/full_screen_actions';
import { addMarker } from '../Map/map_actions';

import EventDetails from './Event_details';

const mSTP = (state) => {
  const { event, error } = state.eventDetails;
  const { user: userLogged, token } = state.session;
  const isModalOpen = state.modal && state.modal.isModalOpen;

  return ({
    event,
    error,
    userLogged,
    token,
    isModalOpen,
  });
};

const mDTP = dispatch => ({
  getEventDetails: ({ id, moveMap, filter }) => dispatch(getEventDetails({ id, moveMap, filter })),
  toggleFavorite: ({ id, willbeLiked, filter, type }) => (willbeLiked
    ? dispatch(addLike({ id, filter, type }))
    : dispatch(removeLike({ id, filter, type }))),
  openModal: (modalName) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  setFSphotos: photos => dispatch(setFSphotos(photos)),
  resetMarker: () => dispatch(addMarker(null)),
});

export default connect(mSTP, mDTP)(EventDetails);
