import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import Dots from '../icons/Dots';
import defaultAvatar from '../../images/default_avatar.jpg';
import styles from './friends.module.scss';
import submenuStyles from '../shared/Dots_submenu/dots_submenu.module.scss';
import { FRIEND, REQUEST_SENT, REQUEST_RECEIVED } from '../../config';

export class Friend extends Component {
  static propTypes = {
    friend: PropTypes.shape({
      id: PropTypes.string,
      avatar: PropTypes.shape({
        full: PropTypes.string,
        thumb: PropTypes.string,
      }),
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      nickname: PropTypes.string,
    }).isRequired,
    acceptFriend: PropTypes.func.isRequired,
    openDeleteFriendModal: PropTypes.func.isRequired,
    declineFriend: PropTypes.func.isRequired,
    cancelRequest: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  constructor() {
    super();
    this.menuRef = createRef();
  }

  state = {
    isSubMenuOpen: false,
  }

  toggleSubmenu = () => this.setState(prevState => ({
    isSubMenuOpen: !prevState.isSubMenuOpen,
  }), this.switchListener);

  handleSubMenuClose = () => this.setState({
    isSubMenuOpen: false,
  });

  openDeleteFriendModal = () => {
    const { friend, openDeleteFriendModal } = this.props;

    openDeleteFriendModal(friend);
  };

  declineFriend = () => {
    const { friend, declineFriend } = this.props;

    declineFriend(friend);
  };

  acceptInvitation = () => {
    const { friend, acceptFriend } = this.props;

    acceptFriend(friend);
  }

  cancelRequest = () => {
    const { friend, cancelRequest } = this.props;

    cancelRequest(friend);
  };

  switchListener = () => {
    const { isSubMenuOpen } = this.state;
    if (isSubMenuOpen) {
      document.addEventListener('click', this.clickOutside);
    } else {
      document.removeEventListener('click', this.clickOutside);
    }
  }

  clickOutside = (e) => {
    if (e.target !== this.menuRef.current) {
      this.handleSubMenuClose();
      document.removeEventListener('click', this.clickOutside);
    }
  }

  renderSubMenu = () => {
    const { friend } = this.props;
    const friendshipStatus = friend && friend.friend_status;

    switch (friendshipStatus) {
      case REQUEST_RECEIVED:
        return (
          <ul className={submenuStyles.submenu} ref={this.menuRef}>
            <li className={submenuStyles.submenu_item}>
              <button
                type="button"
                className={submenuStyles.button}
                onClick={this.acceptInvitation}
              >
                Accept
              </button>
            </li>
            <li className={submenuStyles.submenu_item}>
              <button
                type="button"
                className={submenuStyles.button}
                onClick={this.declineFriend}
              >
                Decline
              </button>
            </li>
          </ul>
        );

      case FRIEND:
        return (
          <ul className={submenuStyles.submenu} ref={this.menuRef}>
            <li className={submenuStyles.submenu_item}>
              <button
                type="button"
                className={submenuStyles.button}
                onClick={this.openDeleteFriendModal}
              >
                Unfriend
              </button>
            </li>
          </ul>
        );

      case REQUEST_SENT:
        return (
          <ul className={submenuStyles.submenu} ref={this.menuRef}>
            <li className={submenuStyles.submenu_item}>
              <button
                type="button"
                className={submenuStyles.button}
                onClick={this.cancelRequest}
              >
                Cancel
              </button>
            </li>
          </ul>
        );
      default:
        return null;
    }
  }

  render() {
    const { isSubMenuOpen } = this.state;
    const { friend, history } = this.props;

    if (!friend) return null;

    const {
      first_name: firstName,
      last_name: lastName,
      nickname,
      id,
    } = friend;
    let secondaryName = '';
    if (firstName) secondaryName += firstName;
    if (lastName) secondaryName += ` ${lastName}`;
    const mainName = nickname || secondaryName || 'Anonymous';

    const path = {
      pathname: `/users/${id}`,
      state: { from: history.location && history.location.pathname },
    };

    const avatarPath = friend.avatar && friend.avatar.thumb
      ? friend.avatar.thumb : defaultAvatar;

    return (
      <div className={styles.user_wrapper}>
        <Link to={path}>
          <div
            className={styles.avatar}
            style={{ backgroundImage: `url(${avatarPath})` }}
          />
        </Link>
        <Link to={path}>
          <div className={styles.name_box}>
            <p>{mainName}</p>
            {nickname ? (
              <p className={styles.secondary_name}>{secondaryName}</p>
            ) : null}
          </div>
        </Link>

        <button
          type="button"
          onClick={this.toggleSubmenu}
          className={styles.dots_button}
        >
          <Dots className={styles.dots} />
        </button>
        {isSubMenuOpen && this.renderSubMenu()}
      </div>
    );
  }
}

export default withRouter(Friend);
