import { SET_FRIEND_TO_DELETE, RESET_FRIEND_TO_DELETE } from '../../redux/action_types';

const friendsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_FRIEND_TO_DELETE:
      return {
        ...state,
        friendToDelete: action.payload,
      };

    case RESET_FRIEND_TO_DELETE:
      return {
        ...state,
        friendToDelete: null,
      };

    default:
      return state;
  }
};

export default friendsReducer;
