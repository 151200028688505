import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

export const sendError = error => (
  axios.post(
    '/send_error',
    {
      data: {
        error: error.toString(),
        stack: error.stack,
      },
    },
  )
);

export default sendError;
