import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import WarningIcon from '../../icons/Warning';

import styles from '../../../styles/banner.module.scss';

import {
  UNPAID, CANCELED, EXPIRED, INACTIVE,
} from '../../../config';

const SubscriptionReminder = ({ id, status }) => (
  <div className={styles.container}>
    <div>
      <WarningIcon className={styles.warning} />
      <span className={styles.text}>Your subscription is {status}.</span>
    </div>
    {status === UNPAID && (
      <Link className={styles.link} to={`/checkout?subscription_id=${id}`}>
        Checkout
      </Link>
    )}
  </div>
);

SubscriptionReminder.propTypes = {
  status: PropTypes.oneOf([UNPAID, CANCELED, EXPIRED, INACTIVE]).isRequired,
  id: PropTypes.number.isRequired,
};

export default SubscriptionReminder;
