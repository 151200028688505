import { connect } from 'react-redux';
import { inviteUserToCohost } from '../../Hosting/hosting_actions';
import CohostPermissionsModal from './Cohost_permissions_modal';

const mSTP = state => ({
  cohostingData: state.cohostingData,
});

const mDTP = {
  inviteUserToCohost,
};

export default connect(mSTP, mDTP)(CohostPermissionsModal);
