import {
  requestCreateVenue,
  requestEditVenue,
  requestAddPhotos,
} from '../../api';
import { startLoading, finishLoading } from '../Loader/loading_actions';
import { getItemsList } from '../List/list_actions';
import { setAndOpenModal, setModalMessage } from '../Modal/modal_actions';
import { signOut } from '../login/login_actions';
import createError from '../Fetch_error/fetch_error_action';
import { addMarker } from '../Map/map_actions';
import { setSubscriptionToCheckout } from '../Checkout/checkout_actions';
import { UNPAID } from '../../config';


export const createVenue = venue => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestCreateVenue(venue, token)
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      dispatch(createError(rej));
      return rej.response;
    })
    .finally(() => dispatch(finishLoading()));
};

export const onVenueSubmitSuccess = options => (dispatch) => {
  const { uploadPhotos, action, subscription } = options;
  const status = subscription && subscription.status;
  const subscriptionId = subscription && subscription.id;

  if (action === 'create' && uploadPhotos) {
    dispatch(setModalMessage('Your venue account has been created'));
  }

  if (action === 'create' && !uploadPhotos) {
    dispatch(setModalMessage('Your venue has been created, but we need more time to process the photos. Please check or try again later by editing venue, if something went wrong...'));
  }

  if (action === 'edit' && uploadPhotos) {
    dispatch(setModalMessage('Your venue has been edited'));
  }

  if (action === 'edit' && !uploadPhotos) {
    dispatch(setModalMessage('Your venue has been edited, but we need more time to process the photos. Please check or try again later by editing venue, if something went wrong...'));
  }

  if (status === UNPAID && subscriptionId) {
    dispatch(setSubscriptionToCheckout(subscription));
    dispatch(setAndOpenModal('VENUE_CHECKOUT_MODAL'));
  } else {
    dispatch(setAndOpenModal('SUCCES_MODAL_MESSAGE'));
  }
  dispatch(getItemsList({ list: 'venues' }));
  dispatch(addMarker(null));
};

export const addPhotos = (id, photos) => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestAddPhotos({ id, photos, token, type: 'venues' })
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      if (rej.response && rej.response.status === 413) dispatch(createError('Files are too big. Please try again by editing venue ...'));
      return rej.response && rej.response.data;
    })
    .finally(() => dispatch(finishLoading()));
};

export const editVenue = venue => (dispatch, getState) => {
  dispatch(startLoading());
  const { token } = getState().session;
  return requestEditVenue(venue, token)
    .then(response => response.data.data)
    .catch((rej) => {
      if (rej.response && rej.response.status === 401) dispatch(signOut());
      if (rej.response && rej.response.status === 403) {
        dispatch(createError('Sorry, editing venue is forbidden.'));
        return rej.response && rej.response.data;
      }
      if (rej.response && rej.response.status === 422) {
        return rej.response && rej.response.data;
      }
      dispatch(createError(rej));
      return rej;
    })
    .finally(() => dispatch(finishLoading()));
};
