import axios from 'axios';

export const requestSearchFriends = ({ token, query }) => (
  axios.get(
    '/api/friends/search',
    {
      headers: {
        'Content-Type': 'application/json',
        'JWT-Authorization': `Bearer ${token}`,
        Accept: 'application/json',
      },
      params: query,
    },
  )
);

export const requestFriendsList = token => (
  axios.get(
    '/api/friends',
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestFriendsRequests = token => (
  axios.get(
    '/api/friends/requests',
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestFriendsInvites = token => (
  axios.get(
    '/api/friends/invites',
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestFriendship = ({ userId, token }) => (
  axios.post(
    '/api/friends/create',
    { data: { id: userId } },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestUserInfo = ({ userId, token }) => (
  axios.get(
    `/api/users/${userId}`,
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestAcceptFriend = ({ userId, token }) => (
  axios.post(
    '/api/friends/accept',
    { data: { id: userId } },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestDeclineFriend = ({ userId, token }) => (
  axios.post(
    '/api/friends/decline',
    { data: { id: userId } },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestDeleteFriend = ({ userId, token }) => (
  axios.post(
    '/api/friends/delete',
    { data: { id: userId } },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestCancelRequest = ({ userId, token }) => (
  axios.post(
    '/api/friends/cancel',
    { data: { id: userId } },
    { headers: {
      'Content-Type': 'application/json',
      'JWT-Authorization': `Bearer ${token}`,
      Accept: 'application/json',
    } },
  )
);

export const requestFriendsInterested = ({ token, type, id, query }) => (
  axios.get(
    `/api/${type}s/${id}/interested`,
    {
      headers: {
        'Content-Type': 'application/json',
        'JWT-Authorization': `Bearer ${token}`,
        Accept: 'application/json',
      },
      params: query,
    },
  )
);

export const requestSavedItems = ({ userId, token, params }) => (
  axios.get(
    `/api/friends/${userId}/favorites`,
    {
      headers: {
        'Content-Type': 'application/json',
        'JWT-Authorization': `Bearer ${token}`,
        Accept: 'application/json',
      },
      params,
    },
  )
);
