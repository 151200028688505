import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import SimpleItemPreview from '../shared/Simple_item_preview/Simple_item_preview';
import CommentsBlock from '../Comments_block/comments_container';
import TicketOrder from '../Ticket_order/ticket_order_container';
import ReadMore from '../shared/Read_more/Read_more';
import BottomBar from '../shared/Bottom_bar/Bottom_bar';
import DateInfo from '../shared/Date_info/Date_info';
import VenueInfo from '../shared/Venue_info/Venue_info';
import InactiveBanner from '../shared/Inactive_banner/Inactive_banner';
import PhotosBlock from '../shared/Photos_block/Photos_block';
import BreakLineText from '../shared/Break_line_text/BreakLineText';

import { capitalizeStr, getDollarsFromCents } from '../../utils/parse';
import detectSafari from '../../utils/detectSafari';
import getNestedValue from '../../utils/getNestedValue';
import scrollToItemTop, { scrollToElement } from '../../utils/scrollToItem';

import AuthorSection from '../shared/Author_section/Author_section';
import CalendarIcon from '../icons/Calendar_thin';
import PinIcon from '../icons/Pin';
import InfoIcon from '../icons/Info';
import TicketIcon from '../icons/Ticket';
import CancelIcon from '../icons/Cancel_icon';
import FriendsInterestedRow from '../Friends/Friends_interested_row/Friends_interested_row';
import FriendsInterestedSection from '../Friends/Friends_interested_section/Friends_interested_container';

import styles from '../../styles/view_details.module.scss';
import gridStyles from '../../styles/main_grid.module.scss';
import buttonStyles from '../../styles/buttons.module.scss';

import {
  EVENTS, EVENT, ACTIVE, INACTIVE,
  HOUSE_PARTY, HOUSE_PARTY_LABEL, FESTIVAL, FESTIVAL_LABEL,
  SOCIAL_WORKSHOP, SOCIAL_WORKSHOP_LABEL, PROGRESSIVE_SERIES,
  PROGRESSIVE_SERIES_LABEL,
} from '../../config';

const TEXT_EMPTY = '...';

class EventDetails extends Component {
  static propTypes = {
    getEventDetails: PropTypes.func.isRequired,
    toggleFavorite: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.string,
      }),
    }),
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    event: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      favorite: PropTypes.bool,
      status: PropTypes.oneOf([ACTIVE, INACTIVE]),
    }).isRequired,
    previewFromForm: PropTypes.bool,
    eventForPreview: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    userLogged: PropTypes.shape({
      id: PropTypes.string,
    }),
    token: PropTypes.string,
    setFSphotos: PropTypes.func.isRequired,
    resetMarker: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    error: null,
    previewFromForm: false,
    eventForPreview: {},
    history: {},
    match: {},
    location: null,
    token: '',
    userLogged: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isTicketsOptionsOpened: false,
      serverTime: null,
      fixBuyButton: true,
      buyPermission: false,
      isFriendsInterestedOpen: false,
    };
    this.wrapperRef = createRef();
    this.commentsRef = createRef();
    this.buttonBoxRef = createRef();
  }

  componentDidMount() {
    const { getEventDetails, match, previewFromForm, history } = this.props;
    if (previewFromForm) return;
    getEventDetails({
      id: match.params.id,
      moveMap: true,
      filter: history.location.search,
    })
      .then((res) => {
        const { search } = history && history.location;
        const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
        if (queryObject.tickets_open) {
          this.setState({ isTicketsOptionsOpened: true });
        }
        if (res && res.headers && res.headers.date) {
          this.setState({ serverTime: res.headers.date });
        }
        // checking button fix for disabled button not fixing
        this.checkBuyPermission();
        if (process.env.NODE_ENV !== 'test') this.checkButtonFix();
        this.scrollToTop();
        const { hash } = history.location;
        if (window.innerWidth < 768) {
          if (hash) {
            scrollToElement(hash.substring(1));
          } else {
            this.scrollToTitle(1000);
          }
        } else if (hash) {
          this.scrollToComment();
        }
      });


    if (this.wrapperRef.current) {
      this.wrapperRef.current.addEventListener('scroll', this.checkButtonFix);
    }
    window.addEventListener('scroll', this.checkButtonFix);
  }

  componentDidUpdate(prevProps, prevState) {
    const { previewFromForm, getEventDetails, history, isModalOpen } = this.props;
    if (previewFromForm) return;

    const shouldRequest = (this.props.match.params.id !== prevProps.match.params.id)
      || (this.props.token !== prevProps.token)
      || (prevProps.isModalOpen === true && isModalOpen === false);

    if (shouldRequest) {
      this.handleCloseFriends();
      getEventDetails({
        id: this.props.match.params.id,
        moveMap: true,
        filter: history.location.search,
      }).then(() => {
        // checking button fix for disabled button not fixing
        this.checkBuyPermission();
        this.checkButtonFix();
      });
      if (this.props.token === prevProps.token) {
        this.resetTicketOptionView();
      }
      this.scrollToTop();
    }

    const changedView = prevState.isFriendsInterestedOpen !== this.state.isFriendsInterestedOpen
    || prevState.isTicketsOptionsOpened !== this.state.isTicketsOptionsOpened;

    if (changedView && this.wrapperRef.current) {
      this.checkButtonFix();
      this.wrapperRef.current.addEventListener('scroll', this.checkButtonFix);
    }
  }

  componentWillUnmount() {
    const { resetMarker } = this.props;
    resetMarker();
    this.resetTicketOptionView();

    if (this.wrapperRef.current) {
      this.wrapperRef.current.removeEventListener('scroll', this.checkButtonFix);
    }
    window.removeEventListener('scroll', this.checkButtonFix);
  }

  scrollToTop = () => {
    if (this.wrapperRef.current) this.wrapperRef.current.scrollTop = 0;
    window.scrollTo(0, 0);
  }

  scrollToComment = () => {
    if (this.commentsRef.current && this.wrapperRef.current) {
      this.wrapperRef.current.scrollTop = this.commentsRef.current.offsetTop;
      window.scrollTo(0, (this.commentsRef.current.offsetTop + window.innerHeight / 2));
    }
  }

  scrollToTitle = (delay) => {
    const wrapper = this.wrapperRef && this.wrapperRef.current;
    scrollToItemTop({ wrapper, delay });
  }

  checkButtonFix = () => {
    const { buyPermission } = this.state;
    if (!buyPermission) {
      this.setState({ fixBuyButton: false });
      return;
    }

    const buttonBox = this.buttonBoxRef && this.buttonBoxRef.current;
    const wrapper = this.wrapperRef && this.wrapperRef.current;
    if (!buttonBox || !wrapper) return;
    const { top: boxTop, height: boxHeight } = buttonBox.getBoundingClientRect();
    const buttonBoxOffset = buttonBox.offsetTop;

    if (window.innerWidth > 767) {
      const wrapperHeight = wrapper.clientHeight;
      const scrolled = wrapper.scrollTop;
      const delta = buttonBoxOffset + boxHeight - 10 - wrapperHeight - scrolled;

      if (delta <= 0) {
        this.setState({ fixBuyButton: false });
      } else {
        this.setState({ fixBuyButton: true });
      }
    } else {
      const delta = boxTop + boxHeight - 8 - window.innerHeight;

      if (delta <= 0) {
        this.setState({ fixBuyButton: false });
      } else {
        this.setState({ fixBuyButton: true });
      }
    }
  }

  checkBuyPermission = () => {
    const { event } = this.props;
    const buyPermission = getNestedValue(event, 'permissions', 'buy_tickets');

    this.setState({ buyPermission });
    return buyPermission;
  }

  resetTicketOptionView = () => {
    this.setState({ isTicketsOptionsOpened: false });

    const { history } = this.props;
    if (!history) return;

    const from = getNestedValue(history, 'location', 'state', 'from');
    const list = getNestedValue(history, 'location', 'state', 'list');
    const search = getNestedValue(history, 'location', 'search');
    const queryObject = qs.parse(search, { ignoreQueryPrefix: true });
    if (queryObject.tickets_open) {
      delete queryObject.tickets_open;
      const state = { from, list };
      history.push({ search: qs.stringify(queryObject), state });
    }
  }

  closeEvent = () => {
    const { history, location } = this.props;
    const from = location.state && location.state.from;
    const query = location && location.state && location.state.query;
    if ((from && from.indexOf('/events/') !== -1) || !from) {
      history.push({
        pathname: '/',
        search: location.search,
        state: { query },
      });
      return;
    }
    const list = location.state && location.state.list;
    history.push({
      pathname: from,
      state: {
        list,
        query,
      },
      search: location.search,
    });
  }

  handleToggleTickets = () => {
    const { getEventDetails, event, history } = this.props;
    const { isTicketsOptionsOpened, buyPermission } = this.state;

    const from = getNestedValue(history, 'location', 'state', 'from');
    const list = getNestedValue(history, 'location', 'state', 'list');
    const state = { from, list };

    if (!buyPermission) {
      this.setState({ isTicketsOptionsOpened: false });
      history.push({ search: '', state });
      return;
    }

    if (isTicketsOptionsOpened) {
      getEventDetails({
        id: event.id,
        moveMap: false,
        filter: history.location.search,
      });
      history.push({ search: '', state });
    } else {
      history.push({ search: '?tickets_open=true', state });
      this.scrollToTitle(0);
    }

    this.setState({ isTicketsOptionsOpened: !isTicketsOptionsOpened });
  }

  handleToggleFavorite = (e) => {
    e.stopPropagation();
    const wasLiked = this.props.event.favorite;
    const { toggleFavorite, event, history } = this.props;
    const { id, type } = event;

    toggleFavorite({
      id,
      willbeLiked: !wasLiked,
      list: EVENTS,
      filter: history.location.search,
      type,
    });
  }

  handleOpenFullScreen = () => {
    const { setFSphotos, event, openModal } = this.props;
    const photos = event && event.photos;

    setFSphotos(photos);
    openModal('FULL_SCREEN_PHOTOS_MODAL');
  }

  handleOpenFriends = () => {
    this.setState({ isFriendsInterestedOpen: true });
  }

  handleCloseFriends = () => {
    this.setState({ isFriendsInterestedOpen: false });
  }

  render() {
    const { error, event, previewFromForm, eventForPreview, userLogged, history } = this.props;
    const {
      title,
      description,
      dance_styles: danceStyles,
      start_date: startDate,
      end_date: endDate,
      location,
      address,
      tickets,
      comments,
      photos,
      favorite,
      item_type: itemType,
      venue_id: venueId,
      status,
      interested_friends: interestedFriends,
      interested_friends_count: totalFriendsInterested,
    } = previewFromForm ? eventForPreview : event;

    const {
      fixBuyButton,
      buyPermission,
      isTicketsOptionsOpened,
      serverTime,
      isFriendsInterestedOpen,
    } = this.state;

    if (error) {
      return <div className={`${gridStyles.side_bar} ${styles.container}`}>Sorry, event not found</div>;
    }

    let fanciersNumber = event.fanciers_number ? event.fanciers_number : 0;
    const user = previewFromForm ? userLogged : event.user;

    let price = TEXT_EMPTY;
    let refunds = TEXT_EMPTY;
    if (tickets && tickets.length > 0) {
      const minPrice = Math.min.apply(null,
        tickets.map(ticket => parseFloat(ticket.price, 10)));
      const maxPrice = Math.max.apply(null,
        tickets.map(ticket => parseFloat(ticket.price, 10)));
      price = minPrice === maxPrice
        ? getDollarsFromCents(minPrice)
        : `${getDollarsFromCents(minPrice)} - ${getDollarsFromCents(maxPrice)}`;
      refunds = tickets.some(ticket => !ticket.refunds) ? 'No refund' : 'With refund';
    }

    fanciersNumber = fanciersNumber < 1000
      ? fanciersNumber
      : `${Math.floor(fanciersNumber / 100) / 10}k`;

    let itemTypeLabel = 'Event info';
    switch (itemType) {
      case HOUSE_PARTY: {
        itemTypeLabel = HOUSE_PARTY_LABEL;
        break;
      }
      case FESTIVAL: {
        itemTypeLabel = FESTIVAL_LABEL;
        break;
      }
      case SOCIAL_WORKSHOP: {
        itemTypeLabel = SOCIAL_WORKSHOP_LABEL;
        break;
      }
      case PROGRESSIVE_SERIES: {
        itemTypeLabel = PROGRESSIVE_SERIES_LABEL;
        break;
      }
      default: {
        if (itemType) itemTypeLabel = capitalizeStr(itemType);
        break;
      }
    }

    const isViewedByOwner = userLogged && event.user && event.user.id
      && userLogged.id === event.user.id;
    const isItemInactive = status === INACTIVE;
    let containerStyles = previewFromForm
      ? gridStyles.scroll_container
      : gridStyles.scroll_container_big;
    if (isItemInactive) containerStyles = gridStyles.scroll_container_big_with_inactive_banner;

    const currentUserPayouts = getNestedValue(userLogged, 'connect', 'payouts_enabled');

    if (isTicketsOptionsOpened) {
      return (
        <TicketOrder
          onClose={this.handleToggleTickets}
          tickets={tickets}
          eventStartDate={startDate}
          eventEndDate={endDate}
          isItemInactive={isItemInactive}
        />
      );
    }

    if (isFriendsInterestedOpen) {
      return (
        <FriendsInterestedSection
          itemInterested={event}
          handleClose={this.handleCloseFriends}
        />
      );
    }

    return (
      <div
        className={!previewFromForm
          ? gridStyles.wrapper_fixed_bottom
          : ''
        }
      >
        {isItemInactive && (
          <InactiveBanner type={EVENT} />
        )}
        <div
          className={containerStyles}
          ref={this.wrapperRef}
        >
          {!previewFromForm
            && (
            <button
              type="button"
              className={buttonStyles.cancel_button}
              onClick={this.closeEvent}
            >
              <CancelIcon />
            </button>
            )}
          <h1 className={detectSafari() ? styles.title_safari : styles.title}>{title}</h1>
          <div className={detectSafari()
            ? styles.styles_container_safari
            : styles.styles_container}
          >
            {danceStyles
          && danceStyles.map(style => <span key={style} className={styles.style}>{style}</span>)
        }
          </div>
          <PhotosBlock
            photos={photos}
            handleOpenFullScreen={this.handleOpenFullScreen}
            fanciersNumber={fanciersNumber}
            previewFromForm={previewFromForm}
          />
          <div className={styles.info_container}>
            <CalendarIcon className={styles.calendar_icon} />
            <DateInfo
              item={previewFromForm ? eventForPreview : event}
              serverTime={serverTime}
            />
          </div>
          <div className={styles.info_container}>
            <PinIcon />
            <div className={styles.info}>
              <VenueInfo venueId={venueId} location={location} address={address} />
            </div>
          </div>
          <div className={styles.info_container}>
            <TicketIcon />
            <div className={styles.info}>
              <div className={styles.text_dark}>{price}</div>
              <div className={styles.text_light}>{refunds}</div>
            </div>
          </div>
          <div className={styles.info_container}>
            <InfoIcon className={styles.info_icon} />
            <div className={styles.info}>
              <p className={styles.text_dark}>{itemTypeLabel}</p>
              <ReadMore className={styles.text_light}>
                {description ? (
                  <BreakLineText>{description}</BreakLineText>
                ) : TEXT_EMPTY}
              </ReadMore>
            </div>
          </div>
          <AuthorSection author={user} label="Event by" />
          {previewFromForm
            ? (
              <>
                <h4 className={styles.subtitle}>Tickets</h4>
                {tickets.map((ticket, index) => (
                  <SimpleItemPreview
                    item={ticket}
                    key={`${ticket.name}_${index.toString()}`}
                    detailsView
                  />
                ))}
              </>
            ) : (
              <>
                <div className={styles.sticky_wrapper} ref={this.buttonBoxRef}>
                  <div className={classNames({ [styles.stick]: fixBuyButton })}>
                    <button
                      type="button"
                      className={classNames(
                        buttonStyles.btn_uppercase,
                        buttonStyles.fullwidth,
                        { [buttonStyles.btn_blue]: buyPermission },
                        { [buttonStyles.btn_disabled]: !buyPermission },
                      )}
                      onClick={this.handleToggleTickets}
                    >
                      {isItemInactive ? 'view tickets' : 'buy ticket'}
                    </button>
                  </div>
                </div>

                {isViewedByOwner && !buyPermission && !currentUserPayouts ? (
                  <div className={styles.text_small_grey}>
                    To sell tickets you need to
                    &nbsp;
                    <Link to={{ pathname: '/payout', state: { from: history.location.pathname } }} className={styles.grey_link}>
                      set up payout.
                    </Link>
                  </div>
                ) : null}
                {!previewFromForm && (
                  <FriendsInterestedRow
                    friends={interestedFriends}
                    totalFriendsCount={totalFriendsInterested}
                    openAllFriends={this.handleOpenFriends}
                  />
                )}
                <div ref={this.commentsRef}>
                  <CommentsBlock
                    comments={comments}
                    item={event}
                    scrollToComment={this.scrollToComment}
                  />
                </div>
              </>
            )
          }
        </div>
        {!previewFromForm && (
          <BottomBar
            favorite={favorite}
            toggleFavorite={this.handleToggleFavorite}
            item={event}
          />
        )}
      </div>
    );
  }
}

export default EventDetails;
