import React from 'react';
import PropTypes from 'prop-types';

import CancelIcon from '../icons/Cancel_icon';
import SearchIcon from '../icons/Search_icon';
import buttonStyles from '../../styles/buttons.module.scss';
import styles from './search_user_form.module.scss';


const SearchUserForm = ({ searchText, handleInputChange, resetSearch }) => (
  <div className={styles.search_form}>
    <SearchIcon className={styles.search_icon} />
    <input
      className={styles.search_input}
      name="searchText"
      value={searchText}
      onChange={handleInputChange}
      type="text"
      autoComplete="off"
      placeholder="Search User Name"
    />
    <button
      type="button"
      className={buttonStyles.close_round_wrapper_checkin}
      onClick={resetSearch}
    >
      <div className={buttonStyles.close_round}>
        <CancelIcon className={buttonStyles.close_round_svg} />
      </div>
    </button>
  </div>
);

SearchUserForm.propTypes = {
  searchText: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
};

export default SearchUserForm;
