import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import classNames from 'classnames';
import styles from './marker.module.scss';
import MarkerIcon from '../icons/Marker';
import { checkMarkerDateHot } from '../../utils/createMarkers';

const Marker = (props) => {
  const { item, pathname, history, hoverId } = props;
  const { id, type } = item;
  let category;
  switch (type) {
    case 'event':
      category = 'events';
      break;
    case 'venue':
      category = 'venues';
      break;
    case 'teacher':
      category = 'teachers';
      break;
    default:
      category = null;
      break;
  }

  const queryObject = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const search = qs.stringify({ ...queryObject, day: undefined });

  const checkIsMarkerActive = () => pathname === `/${category}/${id}` || id === hoverId;
  const shouldPulsate = checkMarkerDateHot({ item });

  const handleClick = () => {
    if (category && id) {
      const to = {
        pathname: `/${category}/${id}`,
        search,
      };

      history.push(to);
    }
  };

  return (
    (category && id) ? (
      <button
        type="button"
        className={classNames({ [styles.active_link]: checkIsMarkerActive() })}
        onClick={handleClick}
      >
        <MarkerIcon className={styles.marker} />
        {shouldPulsate && <div className={styles.pulsation} />}
      </button>
    ) : (
      <MarkerIcon className={styles.marker} />
    )
  );
};

Marker.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  hoverId: PropTypes.string,
};

Marker.defaultProps = {
  hoverId: null,
};

export default Marker;
