const getHours = (time) => {
  let result = time.amPm.value === 'am' ? time.hours.value : parseInt(time.hours.value, 10) + 12;
  if (time.amPm.value === 'pm' && time.hours.value === '12') result = 12;
  if (time.amPm.value === 'am' && time.hours.value === '12') result = 0;
  return result;
};

const combineDateAndTime = ({ timeFrom, timeTo, date, oneDateAvailable }) => {
  const hoursFrom = getHours(timeFrom);

  const dateFrom = new Date(date.value[0]);
  dateFrom.setHours(hoursFrom, timeFrom.minutes.value);

  const hoursTo = getHours(timeTo);
  let dateTo = new Date(date.value[1]);
  dateTo.setHours(hoursTo, timeTo.minutes.value);

  if (oneDateAvailable) {
    dateTo = new Date(date.value[0]);
    dateTo.setHours(hoursTo, timeTo.minutes.value);
  }

  if (dateTo < dateFrom) {
    dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
  }

  return ({ dateFrom, dateTo });
};

export default combineDateAndTime;
