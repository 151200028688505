import React from 'react';
import PropTypes from 'prop-types';

const Eye = ({ className }) => (
  className ? (
    <svg className={className} viewBox="0 0 93 50" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M46 36.7095C41.6891 36.7095 38.1821 33.2023 38.1821 28.8916C38.1821 24.5809 41.6891 21.0737 46 21.0737C50.3109 21.0737 53.8179 24.5809 53.8179 28.8916C53.8179 33.2023 50.3109 36.7095 46 36.7095ZM89 20C88.1804 19.2565 70.6445 3 46 3C21.3555 3 -0.5 22 2.50002 21L9.00002 28C9.00002 28.5 19.8104 17.9821 35.6136 14.3203C31.0668 17.5705 28.0945 22.889 28.0945 28.8916C28.0945 38.7646 36.127 46.7971 46 46.7971C55.873 46.7971 63.9055 38.7646 63.9055 28.8916C63.9055 22.889 60.9332 17.5705 56.3865 14.3203C72.1897 17.9818 83 28 83 28L89 20Z" />
        <circle cx="86.0711" cy="24.0711" r="5" transform="rotate(45 86.0711 24.0711)" />
        <path d="M9.60661 27.6066C7.65399 29.5592 4.48816 29.5592 2.53554 27.6066C0.58292 25.654 0.58292 22.4882 2.53554 20.5355C4.48816 18.5829 7.65399 18.5829 9.60661 20.5355C11.5592 22.4882 11.5592 25.654 9.60661 27.6066Z" />
      </g>
    </svg>
  ) : (
    <svg width="25" height="20" viewBox="0 0 93 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M46 36.7095C41.6891 36.7095 38.1821 33.2023 38.1821 28.8916C38.1821 24.5809 41.6891 21.0737 46 21.0737C50.3109 21.0737 53.8179 24.5809 53.8179 28.8916C53.8179 33.2023 50.3109 36.7095 46 36.7095ZM89 20C88.1804 19.2565 70.6445 3 46 3C21.3555 3 -0.5 22 2.50002 21L9.00002 28C9.00002 28.5 19.8104 17.9821 35.6136 14.3203C31.0668 17.5705 28.0945 22.889 28.0945 28.8916C28.0945 38.7646 36.127 46.7971 46 46.7971C55.873 46.7971 63.9055 38.7646 63.9055 28.8916C63.9055 22.889 60.9332 17.5705 56.3865 14.3203C72.1897 17.9818 83 28 83 28L89 20Z" fill="#909bb1" />
        <circle cx="86.0711" cy="24.0711" r="5" transform="rotate(45 86.0711 24.0711)" fill="#909bb1" />
        <path d="M9.60661 27.6066C7.65399 29.5592 4.48816 29.5592 2.53554 27.6066C0.58292 25.654 0.58292 22.4882 2.53554 20.5355C4.48816 18.5829 7.65399 18.5829 9.60661 20.5355C11.5592 22.4882 11.5592 25.654 9.60661 27.6066Z" fill="#909bb1" />
      </g>
    </svg>
  )
);

Eye.propTypes = {
  className: PropTypes.string,
};

Eye.defaultProps = {
  className: '',
};

export default Eye;
