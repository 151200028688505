import { connect } from 'react-redux';
import { getTeacherDetails } from './teacher_details_actions';
import { openModal, setCurrentModal } from '../Modal/modal_actions';
import { addLike, removeLike } from '../List/list_actions';
import { setFSphotos } from '../Modal/Full_screen_photos_modal/full_screen_actions';
import { addMarker } from '../Map/map_actions';

import TeacherDetails from './Teacher_details';

const mSTP = (state) => {
  const { teacher, error } = state.teacherDetails;
  const { user: userLogged, token } = state.session;
  const isModalOpen = state.modal && state.modal.isModalOpen;

  return ({
    teacher,
    error,
    token,
    userLogged,
    isModalOpen,
  });
};

const mDTP = dispatch => ({
  getTeacherDetails:
    ({ id, filter, moveMap }) => dispatch(getTeacherDetails({ id, filter, moveMap })),
  toggleFavorite: ({ id, willbeLiked, filter, type }) => (willbeLiked
    ? dispatch(addLike({ id, filter, type }))
    : dispatch(removeLike({ id, filter, type }))),
  openModal: (modalName) => {
    dispatch(setCurrentModal(modalName));
    dispatch(openModal());
  },
  setFSphotos: photos => dispatch(setFSphotos(photos)),
  resetMarker: () => dispatch(addMarker(null)),
});

export default connect(mSTP, mDTP)(TeacherDetails);
