import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { Helmet } from 'react-helmet';

import Header from '../header/header_container';
import MainPage from '../../pages/main_page';
import ModalDispatcher from '../Modal/modal_container';
import NotFoundPage from '../../pages/not_found_page';
import LoginPage from '../../pages/login_page';
import TermsPage from '../../pages/terms_page';
import PolicyPage from '../../pages/privacy_policy_page';
import ConfirmEmail from '../../pages/confirm_email_page/confirm_email_container';
import Loader from '../Loader/Loader';
import FetchError from '../Fetch_error/Fetch_error_container';
import ResetPassword from '../../pages/reset_password_page/reset_password_page';
import StripeRedirect from '../../pages/stripe_redirect_page/stripe_redirect_container';
import AppleIdVerify from '../../pages/apple_id_verify/Apple_id_verify_container';

import { sendError } from '../../api';

import { STRIPE_KEY } from '../../config';

class App extends Component {
  state = {
    error: null,
  }

  componentDidCatch(error) {
    this.setState({
      error,
    });
    // eslint-disable-next-line
    console.log('error', error);
    if (process.env.NODE_ENV === 'production') sendError(error);
  }

  render() {
    const { error } = this.state;

    return error ? (
      <div>Something went wrong. Try reload page later.</div>
    ) : (
      <StripeProvider apiKey={STRIPE_KEY}>
        <>
          <Helmet>
            <title>Dancity || Find Dance in Your City ||</title>
            <meta name="description" content="Dancity is the world’s 1st online social-dancing network. Create events, sell tickets, and promote your dance business on Dancity.com" />
          </Helmet>
          <Header />
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/terms_of_use" component={TermsPage} />
            <Route path="/privacy_policy" component={PolicyPage} />
            <Route path="/email_confirmation/:token" component={ConfirmEmail} />
            <Route path="/password_reset/:token" component={ResetPassword} />
            <Route path="/connect" component={StripeRedirect} />
            <Route path="/apple_id_verify" component={AppleIdVerify} />
            <Route path="/" component={MainPage} />
            <Route component={NotFoundPage} />
          </Switch>
          <ModalDispatcher />
          <Loader />
          <FetchError />
        </>
      </StripeProvider>
    );
  }
}
export default App;
