import { FETCH_ERROR, HIDE_ERROR } from '../../redux/action_types';

const fetchErrorReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_ERROR:
      return action.payload || 'Sorry, something went wrong. Please try again later.';

    case HIDE_ERROR:
      return null;

    default:
      return state;
  }
};

export default fetchErrorReducer;
